import { useState } from 'react';
import toast from 'react-hot-toast';
import { CalendarIcon, InformationCircleIcon, RectangleStackIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import { Checkbox, DatePicker } from '@/components/Form';
import { Typography } from '@/components/Typography';
import useOpportunityUpdate from '@/hooks/useAdNetwork/publisher/useOpportunityUpdate';
import {
  AdNetworkOpportunity,
  AdNetworkOpportunityAdvertisement,
  AdNetworkOpportunityStatus,
} from '@/interfaces/ad_network/publisher/opportunity';
import { Badge, BadgeSize, BadgeType } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import analytics from '@/utils/analytics';

import AdPreviews from '../AdPreviews';
import { ExitIntent } from '../ExitIntent';
import { RejectionWorkflow } from '../RejectionWorkflow';

interface Props {
  opportunity: AdNetworkOpportunity;
  advertisements: AdNetworkOpportunityAdvertisement[];
  setEditMode: (editMode: boolean) => void;
  setReadyModalOpen: (readyModalOpen: boolean) => void;
}

const OpportunityForm = ({ opportunity, advertisements, setEditMode, setReadyModalOpen }: Props) => {
  const [selectedDate, setSelectedDate] = useState<moment.Moment | undefined>(
    opportunity.selected_date ? moment(opportunity.selected_date) : undefined
  );
  const [advertisementId, setAdvertisementId] = useState(opportunity.advertisement_id);
  const [isReviewing, setIsReviewing] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [acknowledge, setAcknowledge] = useState(false);
  const { mutateAsync, isLoading: isAccepting } = useOpportunityUpdate({ id: opportunity.id });
  const selectedAdvertisement = advertisements.find((advertisement) => advertisement.id === advertisementId);
  const handleSelectAdvertisement = (advertisement: AdNetworkOpportunityAdvertisement | null) => {
    setAdvertisementId(advertisement?.id || null);
  };
  const handleSelectDate = (date: moment.Moment) => {
    setSelectedDate(
      opportunity.campaign.window_end_date
        ? moment.min(moment(opportunity.campaign.window_end_date), date.endOf('day'))
        : date.endOf('day')
    );
  };
  const validateFields = !!selectedDate && !!selectedAdvertisement;
  const handleReview = () => {
    if (!validateFields) {
      toast.error('Please select a date and version.');
      return;
    }

    setIsReviewing(true);
  };
  const handleAccept = () => {
    if (!validateFields) {
      toast.error('Please select a date and version.');
      return;
    }
    mutateAsync({
      selected_date: selectedDate.format(),
      advertisement_id: selectedAdvertisement.id,
      status: AdNetworkOpportunityStatus.ACCEPTED,
    })
      .then(() => {
        setEditMode(false);
        setIsReviewing(false);
        setReadyModalOpen(true);
        analytics.track('Accepted an Ad Opportunity');
      })
      .catch(() => {
        setIsReviewing(false);
      });
  };
  const handleReject = () => {
    setIsRejecting(true);
  };

  return (
    <>
      <Card density="none" className="overflow-clip">
        <div className="flex flex-col">
          <div className="p-6 space-y-6 border-b border-surface-200">
            <div className="space-y-2">
              <div className="flex space-x-3 items-center">
                <div className="bg-surface-100 p-1 rounded">
                  <CalendarIcon className="w-4 h-4 text-gray-900" />
                </div>
                <Typography as="p" token="font-medium/text/base">
                  {moment(opportunity.campaign.window_start_date).format('MMM Do')} -{' '}
                  {opportunity.campaign.window_always_on
                    ? 'Indefinitely'
                    : moment(opportunity.campaign.window_end_date).format('MMM Do')}
                </Typography>
              </div>
              <div>
                <Typography as="p" token="font-normal/text/sm" colorWeight="500">
                  Select a date that works best for you. Any unique clicks that occur within{' '}
                  <Badge text="72 hours" size={BadgeSize.SM} type={BadgeType.DEFAULT_FILLED} /> of the selected date
                  will be counted.
                </Typography>
              </div>
            </div>
            <DatePicker
              enableTime={false}
              value={selectedDate?.toDate()}
              onChange={(date) => {
                if (!date) {
                  setSelectedDate(undefined);
                } else {
                  handleSelectDate(moment(date));
                }
              }}
              minDate={moment.max(moment(opportunity.campaign.window_start_date), moment()).toDate()}
              maxDate={(opportunity.campaign.window_always_on
                ? moment('2999-12-31')
                : moment(opportunity.campaign.window_end_date)
              ).toDate()}
            />
          </div>
          <div className="p-6 space-y-6 max-h-[calc(50vh)] overflow-auto">
            <div className="space-y-2">
              <div className="flex space-x-3 items-center">
                <div className="bg-surface-100 p-1 rounded">
                  <RectangleStackIcon className="w-4 h-4 text-gray-900" />
                </div>
                <Typography as="p" token="font-medium/text/base">
                  {advertisements.length} creative {advertisements.length === 1 ? 'version' : 'versions'} available
                </Typography>
              </div>
              <div>
                <Typography as="p" token="font-normal/text/sm" colorWeight="500">
                  Select an ad version that suits your audience. Primary ads include a logo, detailed content, and
                  visuals. Secondary ads are shorter and often text-only, without a logo.
                </Typography>
              </div>
            </div>

            <AdPreviews
              previewSize="large"
              advertisements={advertisements}
              selectedAdvertisement={selectedAdvertisement}
              handleSelectAdvertisement={handleSelectAdvertisement}
            />
          </div>
        </div>
        <div className="bg-surface-50 p-3 flex justify-end space-x-3 sticky bottom-0">
          {opportunity.status === AdNetworkOpportunityStatus.PENDING && (
            <Button type="button" variant="primary-inverse" onClick={handleReject}>
              Not the right fit for me
            </Button>
          )}
          {opportunity.status === AdNetworkOpportunityStatus.ACCEPTED && (
            <Button type="button" variant="primary-inverse" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
          )}

          <Button
            type="button"
            variant="primary"
            onClick={handleReview}
            loading={isAccepting}
            disabled={!validateFields}
          >
            {opportunity.status === AdNetworkOpportunityStatus.PENDING ? 'Review and accept' : 'Review and update'}
          </Button>
        </div>
      </Card>
      <RejectionWorkflow
        isOpen={isRejecting}
        onClose={() => {
          setIsRejecting(false);
        }}
        opportunity={opportunity}
      />
      {opportunity.status === AdNetworkOpportunityStatus.PENDING && <ExitIntent opportunity={opportunity} />}
      {validateFields && (
        <ActionModal
          isOpen={isReviewing}
          isWorking={isAccepting}
          onClose={() => setIsReviewing(false)}
          onProceed={handleAccept}
          resourceId={opportunity.id}
          headerText={
            opportunity.status === AdNetworkOpportunityStatus.PENDING ? 'Review and accept' : 'Review and update'
          }
          modalSize="lg"
          actionText="Accept"
          disabled={!validateFields || !acknowledge}
        >
          <div className="py-6 space-y-6">
            <div className="p-4 bg-feedback-info-50 rounded-md border border-feedback-info-200 flex space-x-3">
              <InformationCircleIcon className="w-6 h-6 text-feedback-info-600" />
              <Typography as="p" token="font-medium/text/sm" colorWeight="700" color="info">
                Ads can only be sent one time and only on the selected date.
              </Typography>
            </div>

            <div className="space-y-2">
              <Typography as="p" token="font-normal/text/sm" colorWeight="700">
                Selected to send <Badge text={selectedAdvertisement.name} size={BadgeSize.SM} /> on{' '}
                <Badge text={moment(selectedDate).format('LL')} size={BadgeSize.SM} />.
              </Typography>
              <Typography as="p" token="font-normal/text/sm" colorWeight="700">
                This campaign will pay <Badge text={opportunity.payout_per_click} size={BadgeSize.SM} /> for each unique
                click within <Badge text="72 hours" size={BadgeSize.SM} /> of the selected date.
              </Typography>
            </div>

            <Checkbox
              color="primary"
              name="acknowledge"
              checked={acknowledge}
              onChange={() => setAcknowledge(!acknowledge)}
              labelText="I confirm that I will schedule the campaign on the selected date and understand that payout estimates
                  may change based on actual unique clicks."
            />

            <Typography as="p" token="font-normal/text/xs" colorWeight="700">
              * All Ad Network payments are made through Stripe, directly into your beehiiv wallet. Please ensure your
              Stripe account is set up in order to receive earnings.
            </Typography>
          </div>
        </ActionModal>
      )}
    </>
  );
};

export default OpportunityForm;
