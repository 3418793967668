import { useThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';

const TitleTooltipMenu = () => {
  const { openComposer } = useThreadComposer();

  const handleOpenComposer = () => {
    openComposer();
  };

  return (
    <div className="flex h-[3rem] bg-gray-800 rounded-lg p-2">
      <Button $variant="quaternary" $leftSlot={<Icon name="Comment" />} onClick={handleOpenComposer} />
    </div>
  );
};

export default TitleTooltipMenu;
