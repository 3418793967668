import { DnsRecord } from '../../../../interfaces/custom_domain';
import Text from '../../../Text';

interface DnsRecordProps {
  record: DnsRecord;
  className?: string;
}

const DnsRecordDisplay: React.FC<DnsRecordProps> = ({ record, className }: DnsRecordProps) => (
  <div className={`table ${className}`}>
    <div className="table-row-group">
      <div className="table-row">
        <div className="table-cell">
          <Text size="sm" className="text-gray-500">
            <b>Type:</b>
          </Text>
        </div>
        <div className="table-cell pl-2">
          <Text size="sm">
            <code className="bg-gray-100 px-2 py-1 rounded-sm text-gray-500">{record.type}</code>
          </Text>
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell">
          <Text size="sm" className="text-gray-500 mt-2">
            <b>Name:</b>
          </Text>
        </div>
        <div className="table-cell pl-2">
          <Text size="sm">
            <code className="bg-gray-100 px-2 py-1 rounded-sm text-gray-500">{record.name}</code>
          </Text>
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell">
          <Text size="sm" className="text-gray-500 mt-2">
            <b>Value:</b>
          </Text>
        </div>
        <div className="table-cell pl-2">
          <Text size="sm">
            <code className="bg-gray-100 px-2 py-1 rounded-sm text-gray-500">{record.value}</code>
          </Text>
        </div>
      </div>
    </div>
  </div>
);

export default DnsRecordDisplay;
