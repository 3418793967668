import { toast } from 'react-hot-toast';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import { Extension } from '@tiptap/core';
import { Node as PMNode } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { BlockThread, Comments, InlineThread } from '@tiptap-pro/extension-comments';
import throttle from 'lodash.throttle';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    commentsKit: {
      scrollToThread: (threadId: string) => ReturnType;
    };
  }
}

interface ICommentsKitOptions {
  provider: TiptapCollabProvider | null;
  openThreadsSidebar: () => void;
}

interface ICommentsKitStorage {
  threadIds: string[];
}

export const CommentsKit = Extension.create<ICommentsKitOptions, ICommentsKitStorage>({
  name: 'commentsKit',

  addOptions() {
    return {
      provider: null,
      openThreadsSidebar: () => {},
    };
  },

  addStorage() {
    return {
      threadIds: [],
    };
  },

  onCreate() {
    const { editor } = this;
    const resolveUnreferencedThreads = () => {
      const oldThreadIds = this.storage.threadIds;

      let newThreadIds: string[] = [];

      this.editor.state.doc.descendants((node) => {
        if (node.type.name === 'blockThread') {
          newThreadIds.push(node.attrs['data-thread-id']);

          return;
        }

        node.marks.forEach((mark) => {
          if (mark.type.name !== 'inlineThread') return;
          const threadId = mark.attrs['data-thread-id'];
          newThreadIds.push(threadId);
        });
      });

      newThreadIds = Array.from(new Set(newThreadIds));

      const deletedThreadIds = oldThreadIds.filter((id) => !newThreadIds.includes(id));

      if (deletedThreadIds.length) {
        deletedThreadIds.forEach((id) => editor.commands.resolveThread({ id }));

        toast('Threads referenced by deleted content have been resolved.');
      }

      this.storage.threadIds = newThreadIds;
    };

    const throttledRemoveUnreferencedThreads = throttle(resolveUnreferencedThreads, 3000);

    resolveUnreferencedThreads();

    editor.on('update', throttledRemoveUnreferencedThreads);
    editor.on('destroy', () => editor.off('update', throttledRemoveUnreferencedThreads));
  },

  addExtensions() {
    const { provider } = this.options;

    return [
      InlineThread.configure({
        provider,
      }),
      BlockThread.extend({
        content: '(block|columns|section|tableBlock|blockThread)+',
      }).configure({
        provider,
      }),
      Comments.configure({
        provider,
      }),
    ];
  },

  addCommands() {
    return {
      scrollToThread:
        (id) =>
        ({ tr, view }) => {
          interface INodeData {
            node: PMNode | null;
            from: number;
            to: number;
          }

          let inlineThreadNodeData: INodeData = { node: null, from: 0, to: 0 };
          let blockThreadNodeData: INodeData = { node: null, from: 0, to: 0 };

          tr.doc.descendants((node, pos) => {
            if (blockThreadNodeData.node || inlineThreadNodeData.node) return;

            const foundBlockThreadNode = node.type.name === 'blockThread' && node.attrs['data-thread-id'] === id;

            if (foundBlockThreadNode) {
              blockThreadNodeData = { node, from: pos, to: pos + node.nodeSize };
            }

            const inlineThreadFoundInNode = node.marks.some(
              (t) => t.type.name === 'inlineThread' && t.attrs['data-thread-id'] === id
            );

            if (inlineThreadFoundInNode) {
              inlineThreadNodeData = { node, from: pos, to: pos + node.nodeSize };
            }
          });

          if (blockThreadNodeData.node) {
            const domAtPos = view.nodeDOM(blockThreadNodeData.from);

            (domAtPos as HTMLDivElement)?.scrollIntoView({ block: 'start' });

            return true;
          }

          if (inlineThreadNodeData.node) {
            const domAtPos = view.domAtPos(inlineThreadNodeData.from).node;

            (domAtPos as HTMLDivElement)?.scrollIntoView({ block: 'start' });

            return true;
          }

          return false;
        },
    };
  },

  addProseMirrorPlugins() {
    const {
      editor,
      options: { openThreadsSidebar },
    } = this;

    const threadIdNumberMap = new Map<string, NodeJS.Timeout>();

    const mouseLastLocation = { x: 0, y: 0 };

    const delayedFocusThreadInSidebar = (threadId: string) => {
      if (threadIdNumberMap.get(threadId)) return;

      const timeoutId = setTimeout(() => {
        openThreadsSidebar?.();

        const { x: left, y: top } = mouseLastLocation;
        const posAtCoords = editor.view.posAtCoords({ left, top })?.pos;

        if (posAtCoords && !editor.storage.comments.focusedThreads.includes(threadId)) {
          editor.commands.focus(posAtCoords);
        }
      }, 3000);

      threadIdNumberMap.set(threadId, timeoutId);
    };

    return [
      new Plugin({
        key: new PluginKey('commentsKit'),
        props: {
          handleDOMEvents: {
            mouseover(_view, event) {
              if (!event.target) {
                return;
              }

              const threadId = (event.target as HTMLDivElement)
                .closest('[data-thread-id]')
                ?.getAttribute('data-thread-id');

              if (threadId) {
                mouseLastLocation.x = event.clientX;
                mouseLastLocation.y = event.clientY;

                delayedFocusThreadInSidebar(threadId);
              }
            },
            mouseout(_view, event) {
              if (!event.target) {
                return;
              }

              const threadId = (event.target as HTMLDivElement)
                .closest('[data-thread-id]')
                ?.getAttribute('data-thread-id');

              if (threadId) {
                const timeoutId = threadIdNumberMap.get(threadId);

                if (timeoutId) {
                  clearTimeout(timeoutId);
                  threadIdNumberMap.delete(threadId);
                }
              }
            },
          },
        },
      }),
    ];
  },
});
