import React, { useState } from 'react';

import { Asset } from '../../interfaces/asset';
import MediaLibrary from '../MediaLibrary/MediaLibrary';

interface Props {
  asset: Asset | null;
  labelText: string;
  publicationId: string;
  onSelect: (asset: Asset) => void;
  onClear: () => void;
  className?: string;
  dimensionSuggestion?: string;
}

const AssetSelect = (props: Props) => {
  const { asset, labelText, className, publicationId, dimensionSuggestion, onSelect, onClear } = props;

  const [active, setActive] = useState(false);
  const [currentAsset, setCurrentAsset] = useState<Asset | null>(asset);

  const openPicker = () => {
    setActive(true);
  };

  const clearField = () => {
    setCurrentAsset({} as Asset);
    if (onClear) {
      onClear();
    }
  };

  const onAssetSelect = (obj: Asset) => {
    setActive(false);
    setCurrentAsset(obj);
    if (onSelect) {
      onSelect(obj);
    }
  };

  return (
    <div className={className}>
      <div className="mb-2">
        <div className="mb-2 text-sm font-medium text-gray-700">{labelText}</div>
      </div>
      <div className="flex flex-col">
        {currentAsset && (
          <div className="pt-2 rounded-md mb-4" style={{ width: 'fit-content' }}>
            {currentAsset.url && <img src={currentAsset.url} alt={currentAsset.alt} />}
            {!currentAsset.url && (
              <div className="text-sm font-light text-gray-500">**You haven&apos;t selected an image yet**</div>
            )}
          </div>
        )}

        {dimensionSuggestion && (
          <div className="flex mb-4">
            <p className="text-xs text-gray-500 text-center">
              <i>{dimensionSuggestion}</i>
            </p>
          </div>
        )}
      </div>
      <div className="flex">
        <button
          type="button"
          className="hover:cursor-pointer justify-center relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-800 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
          onClick={openPicker}
        >
          {currentAsset?.url ? 'Change' : 'Select'}
        </button>
        <button
          type="button"
          className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm justify-center font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
          onClick={clearField}
        >
          Clear
        </button>
      </div>
      <MediaLibrary
        isOpen={active}
        onClose={() => setActive(false)}
        onMediaSelect={(payload) => {
          onAssetSelect(payload.media);
        }}
        publicationId={publicationId}
      />
    </div>
  );
};

AssetSelect.defaultProps = {
  className: undefined,
  dimensionSuggestion: undefined,
};

export default AssetSelect;
