import Badge, { BadgeProps } from '@/components/Badge';
import useBoostSendStatus from '@/hooks/boosts/useBoostSendStatus';
import { BoostSendStatus } from '@/interfaces/boosts/monetize/boost_send';

const badgeAttributesByType: {
  [key in BoostSendStatus]: {
    type: BadgeProps['type'];
    label: string;
  };
} = {
  [BoostSendStatus.ACCEPTED]: {
    type: 'success',
    label: 'Live',
  },
  [BoostSendStatus.PENDING]: {
    type: 'warning',
    label: 'Pending',
  },
  [BoostSendStatus.REJECTED]: {
    type: 'information',
    label: 'Rejected',
  },
  [BoostSendStatus.CANCELED]: {
    type: 'information',
    label: 'Cancelled',
  },
  [BoostSendStatus.ARCHIVED]: {
    type: 'information',
    label: 'Archived',
  },
};

interface Props {
  status: BoostSendStatus;
  sendBy: string | Date;
}

const BoostSendStatusBadge = ({ status, sendBy }: Props) => {
  const { type, label } = useBoostSendStatus(
    status,
    badgeAttributesByType[status].type,
    badgeAttributesByType[status].label,
    sendBy
  );
  return (
    <Badge className="capitalize" type={type}>
      {label}
    </Badge>
  );
};

export default BoostSendStatusBadge;
