import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useDisconnectIntegration = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<{ message: string }, { error: string }, { integrationId: string }>(({ integrationId }) =>
    api.delete(`/integrations/${integrationId}`, {
      params: {
        publication_id: currentPublicationId,
      },
    })
  );
};

export default useDisconnectIntegration;
