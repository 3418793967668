import { useInfiniteQuery } from 'react-query';

import { AdNetworkPublication } from '../../../interfaces/ad_network/internal/publication';
import { Pagination } from '../../../interfaces/general';
import api from '../../../services/swarm';

interface ApiResponse {
  publications: AdNetworkPublication[];
  pagination: Pagination;
}

export default function useCampaignOpportunityGroupPublications({
  id,
  query = '*',
  conditions,
}: {
  id: string;
  query?: string;
  conditions?: string;
}) {
  const fetchPublications = ({ pageParam = 1 }) =>
    api
      .get(`/ad_network/internal/campaign_opportunity_groups/${id}/publications`, {
        params: {
          page: pageParam,
          per_page: 25,
          query,
          conditions,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['ad_network', 'internal', 'campaign_opportunity_groups', id, 'publications', query, conditions],
    fetchPublications,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
      onError: () => {
        localStorage.removeItem(`ad_network_publication_filters_group_${id}`);
      },
    }
  );
}
