import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import TabNavigation from '@/components/TabNavigation';
import Text from '@/components/Text';

import { useSubscriberContext } from '../_context/subscriberContext';

const SubscriberOverview = () => {
  const { pathname } = useLocation();
  const { subscriber } = useSubscriberContext();
  const navigate = useNavigate();

  const [search, setSearch] = useQueryParam('search', StringParam);
  const [eventType, setEventType] = useQueryParam('event_type', StringParam);

  const tabs = useMemo(
    () => [
      {
        name: 'posts',
        label: 'Posts',
        selected: pathname === `/subscribers/${subscriber.id}`,
        onSelect: () => navigate(`/subscribers/${subscriber.id}`),
      },
      {
        name: 'automations',
        label: 'Automations',
        selected: pathname === `/subscribers/${subscriber.id}/automations`,
        onSelect: () => navigate(`/subscribers/${subscriber.id}/automations`),
      },
      {
        name: 'polls',
        label: 'Polls',
        selected: pathname === `/subscribers/${subscriber.id}/polls`,
        onSelect: () => navigate(`/subscribers/${subscriber.id}/polls`),
      },
      {
        name: 'referrals',
        label: 'Referrals',
        selected: pathname === `/subscribers/${subscriber.id}/referrals`,
        onSelect: () => navigate(`/subscribers/${subscriber.id}/referrals`),
      },
      {
        name: 'other_emails',
        label: 'Other Emails',
        selected: pathname === `/subscribers/${subscriber.id}/other_emails`,
        onSelect: () => navigate(`/subscribers/${subscriber.id}/other_emails`),
      },
    ],
    [navigate, pathname, subscriber.id]
  );

  return (
    <div className="mt-6">
      <Text type="bold" className="text-gray-900">
        Subscriber Overview
      </Text>
      <TabNavigation className="mt-3 border-b border-gray-200" tabs={tabs} />
      <Outlet context={{ search, setSearch, eventType, setEventType }} />
    </div>
  );
};

export default SubscriberOverview;
