export enum AdNetworkReadiness {
  NOT_READY = 'not_ready',
  READY = 'ready',
  RUNNING = 'running',
}

export enum BadAppleReason {
  INFLATED_CLICKS = 'inflated_clicks',
  POOR_DELIVERY = 'poor_delivery',
  BAD_CONTENT = 'bad_content',
  DISINTERMEDIATION = 'disintermediation',
  GAMING_THE_SYSTEM = 'gaming_the_system',
  NOT_BRAND_SAFE = 'not_brand_safe',
}

export enum SalesTeamType {
  NONE = 'none',
  SELF = 'self',
  TEAM = 'team',
}

export enum Tier {
  ZERO = 'zero',
  ONE = 'one',
  TWO = 'two',
  THREE = 'three',
  FOUR = 'four',
}

export interface AdNetworkPublicationProfile {
  id: string;
  publication_id: string;
  accepts_ad_type_dedicated: boolean;
  accepts_ad_type_poll: boolean;
  accepts_ad_type_primary: boolean;
  accepts_ad_type_secondary: boolean;
  accepts_ad_type_tertiary: boolean;
  accepts_content_cannabis: boolean;
  accepts_pricing_model_affiliate: boolean;
  accepts_pricing_model_cpc: boolean;
  accepts_pricing_model_cpm: boolean;
  accepts_pricing_model_flat_rate: boolean;
  ad_network_readiness: AdNetworkReadiness;
  bad_apple_reason: BadAppleReason | null;
  bad_apple: boolean;
  content_tags: string[];
  minimum_success_monthly_payout_cents: number;
  minimum_success_monthly_payout: string;
  minimum_success_send_payout_cents: number;
  minimum_success_send_payout: string;
  sales_team_type: SalesTeamType;
  tier: Tier;
  us_based: boolean;
  created_at: string;
  updated_at: string;
}
