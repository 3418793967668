import React, { useState } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import Modal from '../../../../components/Modal';
import { PostWarnings } from '../../../../interfaces/post';

interface Props {
  warnings?: PostWarnings;
}

const WarningNotifier: React.FunctionComponent<Props> = (props: Props) => {
  const { warnings = {} } = props;
  const [active, setActive] = useState(false);

  const warningCount = Object.keys(warnings).length;

  if (warningCount === 0) {
    return null;
  }

  return (
    <>
      <Modal isOpen={active} onClose={() => setActive(false)}>
        <div className="px-12 py-16 max-w-3xl">
          <p className="text-2xl text-gray-800 font-medium">Warnings</p>
          <p className="text-sm text-gray-500 mt-2 max-w-xl">
            We auto-detect potential issues with your post. These warnings will not keep you from publishing your post.
          </p>
          <div className="mt-8 flex-col space-y-8">
            {warnings.invalid_links && (
              <div className="p-6 rounded-md bg-white border-2 border-yellow-500 bg-yellow-50 text-gray-800">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500" />
                    </div>
                    <div className="ml-3 text-sm font-medium">
                      You have pasted a tracking link from another beehiiv post into your content. We recommend that you
                      use the tracking link&apos;s target url instead. To avoid this issue in the future, you can
                      duplicate posts instead of copying and pasting from them.
                    </div>
                  </div>
                </div>
              </div>
            )}
            {warnings.post_size && (
              <div className="p-6 rounded-md bg-white border-2 border-yellow-500 bg-yellow-50 text-gray-800">
                <div className="flex justify-between items-center space-x-8">
                  <div className="flex max-w-md items-center">
                    <div className="flex-shrink-0">
                      <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500" />
                    </div>
                    <div className="ml-3 text-sm font-medium">
                      <p>Your post is large and may get clipped by Gmail</p>
                    </div>
                  </div>
                  <div className="text-sm font-medium text-gray-800 text-right">
                    <b>Size Estimate</b>
                    <p>{warnings.post_size} KB</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <span className="relative">
        <button
          type="button"
          className="flex rounded-md bg-yellow-100 items-center py-1 px-2 space-x-2 text-xs text-center text-yellow-700 hover:bg-yellow-200 hover:text-yellow-800"
          onClick={() => setActive(true)}
        >
          {`${warningCount} ${warningCount === 1 ? 'Warning' : 'Warnings'}`}
        </button>
      </span>
    </>
  );
};

export default WarningNotifier;
