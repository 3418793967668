import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import TabNavigation from '@/components/TabNavigation';

const Nav = () => {
  const { pathname } = useLocation();

  const growthPath = '/monetize/subscriptions/growth';
  const attributionPath = '/monetize/subscriptions/attribution';
  const breakdownPath = '/monetize/subscriptions/breakdown';

  const tabs = useMemo(
    () => [
      {
        name: 'growth',
        label: 'Growth',
        selected: pathname === growthPath,
        targetRoute: growthPath,
      },
      {
        name: 'attribution',
        label: 'Attribution',
        selected: pathname === attributionPath,
        targetRoute: attributionPath,
      },
      {
        name: 'breakdown',
        label: 'Breakdown',
        selected: pathname === breakdownPath,
        targetRoute: breakdownPath,
      },
    ],
    [pathname]
  );

  return <TabNavigation variant="tertiary" tabs={tabs} />;
};

export default Nav;
