import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Page } from '@/interfaces/dream_builder/page';
import { Pagination } from '@/interfaces/general';

import api from '../../services/swarm';


interface ApiResponse {
  pages: Page[];
  pagination: Pagination;
}

export default function usePages() {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPages = () =>
    api
      .get(`/pages`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<ApiResponse>({
    queryKey: ['publications', currentPublicationId, 'pages'],
    queryFn: fetchPages
  });
}
