import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { UpsellPage } from '@/components/UpsellPage';
import { useSettings } from '@/context/settings-context';
import useUpgradeIntent from '@/hooks/useUpgradeIntent';
import { IntentAction } from '@/interfaces/upgrades';
import { PLAN } from '@/utils/plans';

const MonetizeAdsTeaser = () => {
  const { pushDataLayerEvent } = useUpgradeIntent({ intentAction: IntentAction.USE_AD_NETWORK });
  const { settings } = useSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (settings?.ad_network) {
      toast.success('Ads are now enabled.');
      navigate('/monetize/ads/pending');
    }
  }, [settings, navigate]);

  return (
    <UpsellPage
      plan={PLAN.SCALE}
      title="beehiiv Ad Network"
      description="Monetize your newsletter with premium sponsors."
      cards={[
        {
          heading: 'Inbound opportunities.*',
          description: "We'll routinely send you ad opportunities from premium brands that align with your newsletter.",
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/ads/inbound.jpg',
        },
        {
          heading: 'Seamless process.',
          description: 'Review. Accept. Schedule. Send. All within a single integrated platform.',
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/ads/schedule.jpg',
        },
        {
          heading: 'Monetize like the pros.',
          description: 'The beehiiv ad network is used by all the top newsletters.',
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/ads/monetize.jpg',
        },
      ]}
      statNumber="$500,000+"
      statDescription="Earned and counting."
      statHeading="Quit leaving money on the table with your newsletter."
      learnMoreUrl="https://www.beehiiv.com/monetize/ad-network/publishers"
      disclaimer="* By upgrading to Scale you will be eligible to receive ad placements, but are not guaranteed to receive ad placements."
      onUpsell={() => {
        pushDataLayerEvent();
      }}
    />
  );
};

export default MonetizeAdsTeaser;
