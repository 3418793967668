import { ConditionLabel, ConditionOptions, needsConditionInput } from '@/components/ConditionsEditor';
import { SimpleSelect } from '@/components/Form';
import { AutomationConditionName, AutomationConditionOperator } from '@/interfaces/automations/automation_condition';

import ConditionInput from './ConditionInput';
import { ConditionOption, OPERATOR_OPTIONS_BY_CONDITION_NAME } from './constants';
import CustomFieldSelect from './CustomFieldSelect';
import FormSelect from './FormSelect';

interface ConditionBlockProps {
  conditionOptions: ConditionOption[];
  name: AutomationConditionName;
  value: string;
  resourceId?: string;
  operator: AutomationConditionOperator;
  hasErrors: boolean;
  onChange: (name: string, value: string | string[], resource_id?: string) => void;
  onRemove: () => void;
  onDuplicate: () => void;
}

const ConditionBlock = ({
  conditionOptions,
  name,
  value,
  resourceId,
  operator,
  hasErrors,
  onChange,
  onRemove,
  onDuplicate,
}: ConditionBlockProps) => (
  <div className="group">
    <div className="flex flex-col">
      <div className="flex flex-col flex-grow">
        <ConditionLabel conditionType="attribute" />
        <div className="flex">
          <div className="flex flex-grow flex-row gap-x-2">
            <SimpleSelect className="w-full" name="name" value={name} onSelect={onChange} options={conditionOptions} />

            {name === 'custom_field_id' && (
              <CustomFieldSelect
                className="w-full"
                resourceId={resourceId}
                onChange={(newResourceId) => {
                  onChange(name, value, newResourceId);
                }}
              />
            )}

            {name === 'form_id' && (
              <FormSelect
                className="w-full"
                resourceId={resourceId}
                onChange={(_newName, newResourceId) => {
                  onChange(name, value, newResourceId);
                }}
              />
            )}

            <SimpleSelect
              className="w-full"
              name="operator"
              value={operator}
              onSelect={onChange}
              options={OPERATOR_OPTIONS_BY_CONDITION_NAME[name]}
            />
            {needsConditionInput(operator) && (
              <div className="w-full">
                <ConditionInput name={name} value={value} hasErrors={hasErrors} onChange={onChange} />
              </div>
            )}
          </div>
          <div className="flex opacity-0 group-hover:opacity-100 align-middle items-center space-x-2 px-1">
            <ConditionOptions handleDelete={onRemove} handleDuplicate={onDuplicate} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ConditionBlock;
