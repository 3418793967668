import { InformationCircleIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { Typography, TypographyStack } from '@/components/Typography';
import { useStripeBillingSession } from '@/hooks/useBilling';
import { BillingActions } from '@/interfaces/billing';
import { Button } from '@/ui/Button';
import { currencyFormatter } from '@/utils';

import { useLegacyPlanPriceUpgrade } from '../../_hooks';

const LegacyLockInPrompt: React.FC<{ organizationId: string }> = ({ organizationId }) => {
  const { data, isError } = useLegacyPlanPriceUpgrade({ organizationId });

  const stripeSession = useStripeBillingSession({
    organizationId,
    action: BillingActions.LEGACY_LOCK_IN,
  });

  if (!data || isError) {
    return null;
  }

  const currentPrice = currencyFormatter(data.current_amount / 100, 'USD');
  const offerPrice = currencyFormatter(data.offer_amount / 100, 'USD');
  const tieredPrice = currencyFormatter(data.tiered_amount / 100, 'USD');
  const offerExp = moment(data.offer_expiration_date).format('LL');

  return (
    <div className="w-full max-w-3xl p-4 rounded bg-surface-50 border border-surface-200">
      <div className="flex space-x-3">
        <InformationCircleIcon className="w-5 h-5 text-surface-400 flex-none" />
        <TypographyStack className="grow" gap="4">
          <Typography token="font-medium/text/sm">
            You are currently on legacy monthly pricing at {currentPrice}/{data.current_interval}. You have until{' '}
            {offerExp} to lock in our legacy {data.offer_interval}ly pricing of just <b>{offerPrice}</b> for one full
            year.
          </Typography>
          <Typography token="font-medium/text/sm">
            If you choose to not take advantage of this offer, on {offerExp} your account will be moved to the new
            tiered pricing structure at{' '}
            <b>
              {tieredPrice}/{data.tiered_interval}
            </b>
            .
          </Typography>
        </TypographyStack>
        <div className="flex-none my-auto">
          <Button
            type="button"
            className="underline"
            onClick={() => stripeSession.mutate({})}
            size="xs"
            variant="flush"
            loading={stripeSession.isLoading}
          >
            Lock In Prices &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LegacyLockInPrompt;
