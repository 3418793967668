import { useCallback, useEffect, useState } from 'react';

import { Button } from '@/ui/Button';

import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { useAsyncExport } from '../../../../hooks';
import api from '../../../../services/swarm';
import ConfigureContainer from '../ConfigureContainer';

export default function ConfigureExportData() {
  const [isLoading, setIsLoading] = useState(false);
  const [referralProgramId, setReferralProgramId] = useState('');
  const [currentPublicationId] = useCurrentPublicationState();
  const { isExporting, startExport } = useAsyncExport({
    exportType: 'referral_program_subscriber',
    resourceType: 'referral_program',
    resourceId: referralProgramId,
  });

  const loadReferralProgram = useCallback(() => {
    const params = {
      publication_id: currentPublicationId,
    };

    setIsLoading(true);
    api
      .get(`/referral_program`, { params })
      .then((res) => {
        setReferralProgramId(res?.data?.id);
      })
      .finally(() => setIsLoading(false));
  }, [currentPublicationId]);

  useEffect(() => {
    if (currentPublicationId) {
      loadReferralProgram();
    }
  }, [currentPublicationId, loadReferralProgram]);

  return (
    <ConfigureContainer selectedTab="export_data">
      <div className="sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900 inline-flex">
                Export Data {isLoading && <LoadingSpinner className="ml-2" />}
              </h3>
              <p className="mt-1 text-sm text-gray-500">Download your referral program data</p>
            </div>
          </div>
          <div>
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <Button onClick={startExport} loading={isExporting}>
                    Download Referrals
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfigureContainer>
  );
}
