import { useSearchParams } from 'react-router-dom';

import TabNavigation from '@/components/TabNavigation';
import { useSettings } from '@/context/settings-context';
import { useBoostSends } from '@/hooks/boosts/monetize/useBoostSends';
import { Tab } from '@/interfaces/general';

import BoostAgreementsList from './BoostAgreementsList';
import EmailChannelBoosts from './EmailChannelBoosts';
import Insights from './Insights';
import Overview from './Overview';

const Tabs = () => {
  const { settings } = useSettings();
  const [searchParams] = useSearchParams();
  const activeTabParam = searchParams.get('tab');
  const hasBoostsPlus = settings?.boosts_plus === true;
  const { data: sendableBoostSendsData } = useBoostSends({
    allResults: true,
    sendable: true,
    isEnabled: hasBoostsPlus,
  });

  const totalSendableBoostSends = sendableBoostSendsData?.pages[0]?.pagination?.total || 0;
  const sendableBoostSends =
    totalSendableBoostSends > 0 ? sendableBoostSendsData?.pages.flatMap((page) => page.boost_sends) || [] : [];

  const tabs = [
    {
      name: 'overview',
      label: 'Overview',
      targetRoute: '/monetize/boosts?tab=overview',
      selected: !activeTabParam || activeTabParam === 'overview',
    },
    {
      name: 'web_channel_boosts',
      label: 'Web Boosts',
      targetRoute: '/monetize/boosts?tab=web_channel_boosts',
      selected: activeTabParam === 'web_channel_boosts',
    },
    {
      name: 'direct_links_channel_boosts',
      label: 'Direct Links',
      targetRoute: '/monetize/boosts?tab=direct_links_channel_boosts',
      selected: activeTabParam === 'direct_links_channel_boosts',
    },
    {
      name: 'insights',
      label: 'Insights',
      targetRoute: '/monetize/boosts?tab=insights',
      selected: activeTabParam === 'insights',
    },
  ] as Tab[];

  if (hasBoostsPlus) {
    tabs.splice(2, 0, {
      name: 'email_channel_boosts',
      label: 'Email Boosts',
      targetRoute: '/monetize/boosts?tab=email_channel_boosts',
      selected: activeTabParam === 'email_channel_boosts',
      count: totalSendableBoostSends,
    });
  }

  return (
    <>
      <TabNavigation tabs={tabs} />
      {(!activeTabParam || activeTabParam === 'overview') && <Overview />}
      {activeTabParam === 'web_channel_boosts' && <BoostAgreementsList isBoostAffiliate={false} />}
      {activeTabParam === 'email_channel_boosts' && hasBoostsPlus && (
        <EmailChannelBoosts sendableBoostSends={sendableBoostSends} />
      )}
      {activeTabParam === 'direct_links_channel_boosts' && <BoostAgreementsList isBoostAffiliate />}
      {activeTabParam === 'insights' && <Insights />}
    </>
  );
};

export default Tabs;
