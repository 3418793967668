export enum TutorialType {
  AUTOMATIONS = 'automations',
  BOOSTS_GROW = 'boosts_grow',
  BOOSTS_MONETIZE = 'boosts_monetize',
  CONTENT_TAGS = 'content_tags',
  CUSTOM_DOMAINS = 'custom_domains',
  CUSTOM_FIELDS = 'custom_fields',
  CUSTOM_NAVBAR = 'custom_navbar',
  CUSTOM_PAGES = 'custom_pages',
  FORMS = 'forms',
  IMPORT_SUBSCRIBERS = 'import_subscribers',
  MAGIC_LINKS = 'magic_links',
  MULTIPLE_PUBLICATIONS = 'multiple_publications',
  POLLS = 'polls',
  POSTS = 'posts',
  POSTS_REPORT = 'posts_report',
  PREMIUM_SUBSCRIPTIONS = 'premium_subscriptions',
  RECOMMENDATIONS = 'recommendations',
  REFERRAL_PROGRAM = 'referral_program',
  SEGMENTATION = 'segmentation',
  SUBSCRIBERS = 'subscribers',
  SUBSCRIBERS_REPORT = 'subscribers_report',
  SUBSCRIBER_TAGS = 'subscriber_tags',
  SUBSCRIBER_FLOW = 'subscriber_flow',
  THEME_EDITOR = 'theme_editor',
  WIDGET_RECOMMEND = 'embed_recommendations_widget',
  WEBHOOKS = 'webhooks',
}

export interface Tutorial {
  id: number;
  title: TutorialType;
  cta: string;
  url: string;
}
