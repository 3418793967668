import { useParams } from 'react-router-dom';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import LoadingBox from '@/components/LoadingBox';
import useSvixEndpoint from '@/hooks/webhooks/useSvixEndpoint';

import List from './Messages/Index/List';
import Details from './Details';

const Show = () => {
  const { endpointId } = useParams();

  const {
    data: endpoint,
    isFetching: isFetchingEndpoint,
    isError: isErrorEndpoint,
  } = useSvixEndpoint(endpointId as string);

  return (
    <div className="m-6">
      <div className="px-10 space-y-6">
        <Breadcrumbs>
          <Breadcrumbs.Item to="/settings/integrations/webhooks">All Endpoints</Breadcrumbs.Item>
          <Breadcrumbs.Item to=".">{endpoint?.description || endpointId}</Breadcrumbs.Item>
        </Breadcrumbs>
        <LoadingBox isLoading={isFetchingEndpoint} isError={isErrorEndpoint}>
          {endpoint && <Details endpoint={endpoint} />}
          {endpoint && <List endpointId={endpoint.id} />}
        </LoadingBox>
      </div>
    </div>
  );
};

export default Show;
