import { MediaLibraryId, MediaLibraryTabState } from '../MediaLibrary.types';

import NavigationButton from './NavigationButton/NavigationButton';
import Styled from './Navigation.styles';

const Navigation = () => {
  return (
    <Styled.Navigation>
      <Styled.Category>
        <Styled.CategoryTitle>beehiiv library</Styled.CategoryTitle>
        <Styled.CategoryContent>
          <NavigationButton
            label="Current publication"
            tabId={MediaLibraryTabState.BEEHIIV}
            libraryId={MediaLibraryId.CURRENTPOST}
          />
        </Styled.CategoryContent>
      </Styled.Category>
      <Styled.Category>
        <Styled.CategoryTitle>Stock libraries</Styled.CategoryTitle>
        <Styled.CategoryContent>
          <NavigationButton label="Giphy" icon="Giphy" tabId={MediaLibraryTabState.GIPHY} />
          <NavigationButton label="Unsplash" icon="Unsplash" tabId={MediaLibraryTabState.UNSPLASH} />
        </Styled.CategoryContent>
      </Styled.Category>
    </Styled.Navigation>
  );
};

export default Navigation;
