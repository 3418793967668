import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import { ArrowsPointingInIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { CogIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Input, Switch } from '@/components/Form';
import SlideOver from '@/components/SlideOver';
import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { useHomePage } from '@/hooks/useHomePages';
import { useLandingPage } from '@/hooks/useLandingPages';
import { useUpgradePage } from '@/hooks/useUpgradePages';
import { useUpdateWebTemplate, useUpdateWebTemplatePages, useWebTemplate } from '@/hooks/useWebTemplates';
import { Endpoints } from '@/hooks/useWebTemplates/useUpdateWebTemplatePages';
import { ScreenSizes } from '@/pages/DesignLab/components/ScreenSize';
import { Button } from '@/ui/Button';

import useBuilderHighlighter from '../../_hooks/useBuilderHighlighter';
import { usePageContext } from '../Context/PageContext';

interface SiteIframeProps {
  isShowing: boolean;
  className: string;
  isMobile: boolean;
  imageUrl?: string;
  onExampleSiteClick?: () => void;
  hideUrlBar?: boolean;
  exampleSiteShowing?: boolean;
  overrideIframeChildren?: React.ReactNode;
  url: string;
  iframeRef: any;
  title: string;
  enter: string;
  enterFrom: string;
  enterTo: string;
  leave: string;
  leaveFrom: string;
  leaveTo: string;
  onClose?: () => void;
  currentPage: 'home' | 'signup' | 'upgrade';
}

const SiteIframe = ({
  isShowing,
  className,
  isMobile,
  imageUrl,
  url,
  iframeRef,
  hideUrlBar,
  title,
  exampleSiteShowing = false,
  overrideIframeChildren,
  onExampleSiteClick,
  enter,
  enterFrom,
  enterTo,
  leave,
  leaveFrom,
  leaveTo,
  onClose,
  currentPage,
}: SiteIframeProps) => {
  const [metadataOpen, setMetadataOpen] = useState<boolean>(false);
  const strippedUrl = url.split('?')[0];

  const webTemplateQuery = useWebTemplate();
  const { data: webTemplate, isLoading: isLoadingWebTemplate } = webTemplateQuery;
  const webTemplateMutation = useUpdateWebTemplate();

  const homePageQuery = useHomePage({ status: 'draft' });
  const { data: homePage, isLoading: isLoadingHomePage } = homePageQuery;
  const homePagesMutation = useUpdateWebTemplatePages({
    endpoint: Endpoints.UPDATE_HOME_PAGES,
  });

  const landingPageQuery = useLandingPage({ slug: '/subscribe', status: 'draft' });
  const { data: landingPage, isLoading: isLoadingLandingPage } = landingPageQuery;
  const landingPagesMutation = useUpdateWebTemplatePages({
    endpoint: Endpoints.UPDATE_LANDING_PAGES,
  });

  const upgradePageQuery = useUpgradePage({ status: 'draft' });
  const { data: upgradePage, isLoading: isLoadingUpgradePage } = upgradePageQuery;
  const upgradePagesMutation = useUpdateWebTemplatePages({
    endpoint: Endpoints.UPDATE_UPGRADE_PAGES,
  });

  const updateWebTemplate = async (key: string, value: any) => {
    webTemplateMutation.mutateAsync({
      [key]: value,
    });
  };

  const updateUpgradePages = async (updateValue: any) => {
    upgradePagesMutation.mutateAsync({
      upgrade_page: {
        seo_section: {
          ...upgradePage?.seo_section,
          ...updateValue,
        },
      },
    });
  };

  const updateLandingPages = async (updateValue: any) => {
    landingPagesMutation.mutateAsync({
      landing_page: {
        seo_section: {
          ...landingPage?.seo_section,
          ...updateValue,
        },
      },
    });
  };

  const updateHomePages = async (updateValue: any) => {
    homePagesMutation.mutateAsync({
      home_page: {
        seo_section: {
          ...homePage?.seo_section,
          ...updateValue,
        },
      },
    });
  };

  const pagesData: any = {
    home: {
      titlePlaceholder: 'Homepage Page',
      titleOnSave: (updateTitle: string) => updateHomePages({ title: updateTitle }),
      titleValue: homePage?.seo_section?.title || '',
      descriptionPlaceholder: 'My Homepage Page Description',
      descriptionOnSave: (updateDescription: string) => updateHomePages({ description: updateDescription }),
      descriptionValue: homePage?.seo_section?.description || '',
    },
    upgrade: {
      titlePlaceholder: 'Upgrade Page',
      titleOnSave: (updateTitle: string) => updateUpgradePages({ title: updateTitle }),
      titleValue: upgradePage?.seo_section?.title || '',
      descriptionPlaceholder: 'My Upgrade Page Description',
      descriptionOnSave: (updateDescription: string) => updateUpgradePages({ description: updateDescription }),
      descriptionValue: upgradePage?.seo_section?.description || '',
      externalPageHrefValue: webTemplate?.external_upgrade_page_href || '',
      externalPageHrefOnSave: (newValue: string) => updateWebTemplate('external_upgrade_page_href', newValue),
      externalPageHrefPlaceholder: 'https://www.my-pricing-page.com/',
      externalPageHrefHelperText:
        'If this is set, users will be redirected to an external upgrade page from your site instead of the beehiiv-supported page.',
    },
    signup: {
      titlePlaceholder: 'Signup Page',
      titleOnSave: (updateTitle: string) => updateLandingPages({ title: updateTitle }),
      titleValue: landingPage?.seo_section?.title || '',
      descriptionPlaceholder: 'My Signup Page Description',
      descriptionOnSave: (updateDescription: string) => updateLandingPages({ description: updateDescription }),
      descriptionValue: landingPage?.seo_section?.description || '',
    },
  };

  const pageData = pagesData[currentPage];
  const [metaTitle, setMetaTitle] = useState<string>(pageData?.titleValue);
  const [metaDescription, setMetaDescription] = useState<string>(pageData?.descriptionValue);
  const [metaExternalPageHref, setMetaExternalPageHref] = useState<string | undefined>(pageData?.externalPageHrefValue);

  const handleSave = async () => {
    setMetadataOpen(false);
    try {
      await Promise.all([
        metaTitle !== pageData.titleValue ? pageData.titleOnSave(metaTitle) : Promise.resolve(),
        metaDescription !== pageData.descriptionValue ? pageData.descriptionOnSave(metaDescription) : Promise.resolve(),
        metaExternalPageHref !== pageData.externalPageHrefValue
          ? pageData.externalPageHrefOnSave(metaExternalPageHref)
          : Promise.resolve(),
      ]);
      toast.success('Saved!');
    } catch (error) {
      toast.error('Please try again');
    }
  };

  const handleClose = () => {
    setMetadataOpen(false);
  };

  useEffect(() => {
    if (currentPage === 'home' && !isLoadingHomePage) {
      setMetaTitle(homePage?.seo_section?.title || '');
      setMetaDescription(homePage?.seo_section?.description || '');
    } else if (currentPage === 'signup' && !isLoadingLandingPage) {
      setMetaTitle(landingPage?.seo_section?.title || '');
      setMetaDescription(landingPage?.seo_section?.description || '');
    } else if (currentPage === 'upgrade' && !isLoadingUpgradePage && !isLoadingWebTemplate) {
      setMetaTitle(upgradePage?.seo_section?.title || '');
      setMetaDescription(upgradePage?.seo_section?.description || '');
      setMetaExternalPageHref(webTemplate?.external_upgrade_page_href);
    }
  }, [
    isLoadingHomePage,
    isLoadingLandingPage,
    isLoadingUpgradePage,
    isLoadingWebTemplate,
    currentPage,
    setMetaTitle,
    setMetaDescription,
    homePage?.seo_section?.title,
    homePage?.seo_section?.description,
    landingPage?.seo_section?.title,
    landingPage?.seo_section?.description,
    upgradePage?.seo_section?.title,
    upgradePage?.seo_section?.description,
    webTemplate?.external_upgrade_page_href,
  ]);

  return (
    <>
      <SlideOver
        bodyId="page_metadata"
        isOpen={metadataOpen}
        onClose={handleClose}
        headerText="Page Specific Settings"
        headerDescription="Include meta details to inform and interest users with short, relevant summaries of what your web pages are about."
        className="h-full"
      >
        <div className="flex flex-col h-full justify-between">
          <div className="flex flex-col space-y-4">
            <Input
              name="meta_title"
              labelText="Meta Title"
              value={metaTitle}
              onChange={(e) => setMetaTitle(e.target.value)}
              placeholderText={pageData.titlePlaceholder}
            />
            <Input
              name="meta_description"
              labelText="Meta Description"
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
              placeholderText={pageData.descriptionPlaceholder}
            />
            {currentPage === 'upgrade' ? (
              <Input
                name="meta_external_page_href"
                labelText="External Page"
                value={metaExternalPageHref}
                onChange={(e) => setMetaExternalPageHref(e.target.value)}
                placeholderText={pageData.externalPageHrefPlaceholder}
                helperText={pageData.externalPageHrefHelperText}
              />
            ) : null}
          </div>
          <div className="flex flex-row justify-end items-end space-x-2">
            <Button type="button" variant="primary-inverse" onClick={() => setMetadataOpen(false)}>
              Close
            </Button>
            <Button type="button" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </SlideOver>
      <Transition
        show={isShowing}
        className={cx(
          'w-full h-full drop-shadow-lg rounded-md border border-surface-200 bg-white overflow-auto',
          className
        )}
        enter={enter}
        enterFrom={enterFrom}
        enterTo={enterTo}
        leave={leave}
        leaveFrom={leaveFrom}
        leaveTo={leaveTo}
      >
        {!hideUrlBar && (
          <div className="rounded-md bg-white sticky top-0">
            <div className="w-full flex space-x-2 items-center px-2 py-2">
              <a href={strippedUrl} target="_blank" rel="noopener noreferrer">
                <div className="w-5 h-5">
                  <img src={imageUrl} alt="logo" className="w-5 h-5 rounded" />
                </div>
              </a>
              <div className="w-full py-1 px-2 bg-surface-100 rounded-full flex space-x-1 items-center overflow-hidden">
                <LockClosedIcon className="w-3.5 h-3.5 text-surface-400" />
                <Typography token="font-light/text/xs" as="span" className="text-center truncate">
                  {strippedUrl}
                </Typography>
              </div>
              {onClose ? (
                <Button variant="primary-inverse" size="xs" onClick={onClose} Icon={ArrowsPointingInIcon}>
                  Close Preview
                </Button>
              ) : (
                <div className="flex space-x-2 items-center">
                  {onExampleSiteClick && (
                    <Tooltip
                      id="example_site_tooltip"
                      text="Toggle on for an example of a more complete site while you are designing!"
                      showIcon={false}
                      isEnabled={!exampleSiteShowing}
                    >
                      <Switch
                        name="example_site"
                        size="small"
                        variant="primary"
                        labelText="Example Site"
                        checked={exampleSiteShowing}
                        onChange={() => onExampleSiteClick?.()}
                        className="!px-2 border whitespace-nowrap bg-white py-1 border-surface-200 rounded-md"
                      />
                    </Tooltip>
                  )}
                  <Button
                    variant="primary-inverse"
                    size="sm"
                    onClick={() => setMetadataOpen(true)}
                    Icon={CogIcon}
                    className="!px-1 !py-1"
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {overrideIframeChildren && overrideIframeChildren}
        {isShowing && !overrideIframeChildren && (
          <iframe
            ref={iframeRef}
            title={title}
            src={url}
            width=""
            height=""
            className={cx('w-full h-full overflow-auto', isMobile ? ' max-w-[480px] mx-auto' : '')}
          />
        )}
      </Transition>
    </>
  );
};

interface Props {
  url: string;
  title: string;
  overrideIframeChildren?: React.ReactNode;
  hideUrlBar?: boolean;
  isHomePage: boolean;
  isSignupPage: boolean;
  isUpgradePage: boolean;
}

const IframePreviewer = ({
  url,
  title,
  overrideIframeChildren,
  hideUrlBar = false,
  isHomePage,
  isSignupPage,
  isUpgradePage,
}: Props) => {
  const { currentPublication, status, isPreviewMode, isMobile, setScreenSize } = usePageContext();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [testWebsiteEnabled, setTestWebsiteEnabled] = useState(false);
  const iframeUrl = testWebsiteEnabled ? `${url}&example_site=true` : url;
  const [exampleSiteBannerShowing, setExampleSiteBannerShowing] = useState(false);

  const { highlightedNodeId, highlightedSectionId, handleStartHighlight } = useBuilderHighlighter({});

  const handleOrchidRefresh = () => {
    if (iframeRef?.current && currentPublication) {
      iframeRef.current.contentWindow?.postMessage(
        { publicationId: currentPublication?.id, status: 'refresh' },
        iframeUrl
      );
    }
  };

  const handleExampleSiteClick = () => {
    setTestWebsiteEnabled(!testWebsiteEnabled);
    handleOrchidRefresh();
    setExampleSiteBannerShowing(!testWebsiteEnabled);
  };

  // Communicate to orchid to revalidate the child route on saves.
  // See hook useRefetchPreview in orchid.
  useEffect(() => {
    handleOrchidRefresh();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, iframeRef]);

  // Communicate to orchid to highight the section or node.
  useEffect(() => {
    if (currentPublication && iframeRef?.current?.contentWindow) {
      iframeRef?.current?.contentWindow?.postMessage(
        { publicationId: currentPublication?.id, sectionId: highlightedSectionId, nodeId: highlightedNodeId },
        iframeUrl
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedNodeId, highlightedSectionId, iframeRef, currentPublication]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.type === 'message') {
        if (event.data.event_type === 'node-selected') {
          handleStartHighlight({
            sectionId: event.data.sectionId,
            nodeId: event.data.nodeId,
          });
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleStartHighlight]);

  // eslint-disable-next-line no-nested-ternary
  const currentPage = isHomePage ? 'home' : isSignupPage ? 'signup' : isUpgradePage ? 'upgrade' : 'unknown';

  if (currentPage === 'unknown') {
    return null;
  }

  return (
    <div className="h-full overflow-auto px-14 relative">
      <SiteIframe
        currentPage={currentPage}
        isShowing={!isPreviewMode}
        className={cx(isMobile ? ' max-w-[480px] mx-auto' : '')}
        url={iframeUrl}
        iframeRef={iframeRef}
        title={title}
        isMobile={isMobile}
        hideUrlBar={hideUrlBar}
        exampleSiteShowing={exampleSiteBannerShowing}
        onExampleSiteClick={handleExampleSiteClick}
        overrideIframeChildren={overrideIframeChildren}
        imageUrl={currentPublication?.logo?.url}
        enter="transition ease-out duration-300"
        enterFrom="transform opacity-0 scale-100"
        enterTo="transform opacity-100 scale-95"
        leave="transition ease-in duration-200"
        leaveFrom="transform opacity-100 scale-95"
        leaveTo="transform opacity-0 scale-100"
      />
      <SiteIframe
        currentPage={currentPage}
        isShowing={isPreviewMode}
        className={cx(isPreviewMode ? 'fixed top-0 left-0 z-50' : 'relative', isMobile ? ' w-full mx-auto' : '')}
        hideUrlBar={hideUrlBar}
        url={iframeUrl}
        iframeRef={iframeRef}
        title={title}
        isMobile={isMobile}
        imageUrl={currentPublication?.logo?.url}
        enter="transition ease-out duration-300"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-200"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        onClose={() => setScreenSize(ScreenSizes.DESKTOP)}
      />
    </div>
  );
};

export default IframePreviewer;
