import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { UpgradeIntentPlaceholder } from '../../components/ui/UpgradeIntentPlaceholder';

import { PaywallBreakView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    paywallBreak: {
      setPaywallBreak: () => ReturnType;
    };
  }
}

export const PaywallBreak = Node.create({
  name: 'paywallBreak',

  draggable: true,

  addOptions() {
    return {
      enabled: false,
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setPaywallBreak:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
            })
            .run(),
    };
  },

  addNodeView() {
    if (!this.options.enabled) {
      return ReactNodeViewRenderer(
        () => (
          <UpgradeIntentPlaceholder
            description="You need to upgrade to access premium subscription features."
            url="https://www.beehiiv.com/newsletter-solutions/monetize"
            iconName="PaywallBreak"
          />
        ),
        { className: 'upgrade-intent' }
      );
    }

    return ReactNodeViewRenderer(PaywallBreakView);
  },
});

export default PaywallBreak;
