export enum PLAN {
  LAUNCH = 'launch',
  GROW = 'grow',
  SCALE = 'scale',
  MAX = 'max',
  CUSTOM = 'custom',
}

export enum FEATURE {
  BOOSTS = 'Boosts',
  WEB_HOSTING = 'Web Hosting (SEO Optimized)',
  CUSTOM_NEWSLETTERS = 'Custom Newsletters',
  CMS = 'Best-in-class CMS',
  CAMPAIGN_AND_SUBSCRIBER_ANALYTICS = 'Campaign & Subscriber Analytics',
  NEWSLETTER_RECOMMENDATIONS = 'Newsletter Recommendations',
  MAGIC_LINKS = 'Magic Links',
  AUDIENCE_SEGMENTATION_LIMITED = 'Audience Segmentation (Limit 3)',
  CUSTOM_FIELDS = 'Custom Fields',
  EMAIL_CAPTURE = 'Popups & Advanced Email Capture',
  EXTERNAL_SUBSCRIBE_FORMS = 'External Subscribe Forms',
  GRANULAR_ATTRIBUTION_DATA = 'Granular Attribution Data',
  REAL_TIME_EMAIL_VERIFICATION = 'Real-time Email Verification',
  OPTIMIZED_INBOX_DELIVERABILITY = 'Optimized Inbox Deliverability',
  DYNAMIC_RSS_FEED = 'Dynamic RSS Feed',
  CUSTOM_DOMAINS = 'Custom Domains (Email + Web)',
  PREMIUM_SUBSCRIPTIONS = 'Offer Premium Subscriptions',
  AUDIENCE_POLLS = 'Unlimited Audience Polls',
  AD_NETWORK = 'beehiiv Ad Network Access',
  AUDIENCE_SEGMENTATION_UNLIMITED = 'Audience Segmentation (Unlimited)',
  MULTIPLE_TEAM_MEMBERS = 'Multiple Team Members',
  PROMOTIONAL_OFFERS_AND_TRIALS = 'Promotional Offers & Trials',
  AB_TESTING = 'A/B Testing Subject Lines',
  API_ACCESS = 'API Access',
  CUSTOM_HTML = 'Custom HTML',
  SUBSCRIBER_FORMS = 'Subscriber Forms',
  INTEGRATED_REFERRAL_PROGRAM = 'Integrated Referral Program',
  AUTOMATED_JOURNEYS = 'Automated Journeys',
  SEGMENTATION_SENDING = 'Segmentation Sending',
  MULTIPLE_PUBLICATIONS_LIMITED = 'Multiple Publications (Limit 3)',
  PRIORITY_SUPPORT = 'Priority Support',
  UNLIMITED_SUBSCRIBERS = 'Unlimited Subscribers',
  MULTIPLE_PUBLICATIONS_UNLIMITED = 'Unlimited Publications',
  EARLY_FEATURE_ACCESS = 'Early Feature Access',
  NO_CONTRACT_LOCK_IN = 'No Contract Lock-In',
  DEDICATED_IP_ADDRESSES = 'Dedicated IP Addresses',
  ADVANCED_REPORTS = '3D Analytics',
  EMAIL_BLASTS = 'Dedicated Email Blasts',
  SEND_SEGMENT_EMAILS = 'Send emails to segments',
  SPLIT_TEST_CUSTOMIZATION = 'Full A/B Test Customization',
  FORMS = 'Subscriber Forms',
  PAGES = 'Webpages & Navigation',
  LANDING_PAGES = 'Landing Pages',
  UPGRADE_PAGE = 'Upgrade Page',
  COMMENTS = 'Comments',
  AI_WRITING_TOOLS = 'AI Assisted Writing Tools',
}

type Plan = {
  name: string;
  description: string;
  priceMonthly: number;
  priceAnnually: number;
  featuresHeading: string;
  sends: number;
  features: FEATURE[];
  highlightedFeatures: FEATURE[];
};

const launch: Plan = {
  name: PLAN.LAUNCH,
  description: 'For those just getting started',
  priceMonthly: 0,
  priceAnnually: 0,
  featuresHeading: 'Key features',
  sends: 2500,

  features: [
    FEATURE.WEB_HOSTING,
    FEATURE.CUSTOM_NEWSLETTERS,
    FEATURE.CMS,
    FEATURE.CAMPAIGN_AND_SUBSCRIBER_ANALYTICS,
    FEATURE.NEWSLETTER_RECOMMENDATIONS,
    FEATURE.MAGIC_LINKS,
    FEATURE.AUDIENCE_SEGMENTATION_LIMITED,
    FEATURE.CUSTOM_FIELDS,
    FEATURE.EMAIL_CAPTURE,
    FEATURE.EXTERNAL_SUBSCRIBE_FORMS,
    FEATURE.GRANULAR_ATTRIBUTION_DATA,
    FEATURE.REAL_TIME_EMAIL_VERIFICATION,
    FEATURE.OPTIMIZED_INBOX_DELIVERABILITY,
    FEATURE.DYNAMIC_RSS_FEED,
    FEATURE.COMMENTS,
  ],

  highlightedFeatures: [],
};

const grow: Plan = {
  name: PLAN.GROW,
  description: 'For those serious about growth',
  priceMonthly: 49,
  priceAnnually: 499,
  featuresHeading: 'All in Launch, plus',
  sends: 10000,

  features: [...launch.features.filter((feature) => feature !== FEATURE.AUDIENCE_SEGMENTATION_LIMITED)],

  highlightedFeatures: [
    FEATURE.CUSTOM_DOMAINS,
    FEATURE.PREMIUM_SUBSCRIPTIONS,
    FEATURE.AUDIENCE_POLLS,
    FEATURE.PROMOTIONAL_OFFERS_AND_TRIALS,
    FEATURE.FORMS,
    FEATURE.AUDIENCE_SEGMENTATION_UNLIMITED,
    FEATURE.MULTIPLE_TEAM_MEMBERS,
    FEATURE.AB_TESTING,
    FEATURE.API_ACCESS,
    FEATURE.CUSTOM_HTML,
    FEATURE.SUBSCRIBER_FORMS,
  ],
};

const scale: Plan = {
  name: PLAN.SCALE,
  description: "For the world's top newsletters",
  priceMonthly: 99,
  priceAnnually: 999,
  sends: 100000,
  featuresHeading: 'All in Grow, plus',

  features: [...grow?.highlightedFeatures, ...grow.features, FEATURE.PRIORITY_SUPPORT],

  highlightedFeatures: [
    FEATURE.AD_NETWORK,
    FEATURE.BOOSTS,
    FEATURE.AI_WRITING_TOOLS,
    FEATURE.INTEGRATED_REFERRAL_PROGRAM,
    FEATURE.AUTOMATED_JOURNEYS,
    FEATURE.ADVANCED_REPORTS,
    FEATURE.EMAIL_BLASTS,
    FEATURE.MULTIPLE_PUBLICATIONS_LIMITED,
    FEATURE.SEND_SEGMENT_EMAILS,
    FEATURE.SPLIT_TEST_CUSTOMIZATION,
    FEATURE.PAGES,
    FEATURE.LANDING_PAGES,
    FEATURE.PRIORITY_SUPPORT,
  ],
};

export { grow as GROW_PLAN, launch as LAUNCH_PLAN, scale as SCALE_PLAN };
export const PLANS = [launch, grow, scale];
export const PREMIUM_PLANS = { grow, scale };
