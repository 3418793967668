import { forwardRef, HTMLProps } from 'react';
import { createPortal } from 'react-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

import { cn } from '@/utils/cn';

import IconButton from '../IconHelpers/IconButton';

export type DialogModalProps = {
  children: React.ReactNode;
  open?: boolean;
  onClose: () => void;
} & HTMLProps<HTMLDivElement>;

export const Wrapper = forwardRef<HTMLDivElement, DialogModalProps>(
  ({ children, open, onClose, className, ...rest }, ref) => {
    const wrapperClassName = classNames(
      'fixed inset-0 z-top transition-all',
      open ? 'opacity-100 visible' : 'opacity-0 invisible'
    );

    const contentWrapperClassName = classNames(
      'w-full flex flex-col max-w-6xl overflow-hidden bg-white rounded-xl pointer-events-auto h-5/6 transform transition-all',
      open ? 'opacity-100 translate-y-0 scale-100 visible' : 'opacity-0 translate-y-8 scale-95 invisible'
    );

    return createPortal(
      <div ref={ref} className={wrapperClassName}>
        <button
          type="button"
          className="absolute inset-0 z-10 text-transparent bg-gray-300 select-none bg-opacity-80"
          onClick={onClose}
        >
          Close
        </button>
        <div className="absolute inset-0 z-20 flex items-center justify-center px-6 pointer-events-none">
          <div className={cn(contentWrapperClassName, className)} {...rest}>
            {children}
          </div>
        </div>
      </div>,
      document.body
    );
  }
);

Wrapper.displayName = 'DialogModal.Wrapper';

export type DialogModalHeaderProps = {
  children?: React.ReactNode;
  onClose: () => void;
  title: string;
} & HTMLProps<HTMLDivElement>;

export const Header = forwardRef<HTMLDivElement, DialogModalHeaderProps>(
  ({ children, onClose, title, className, ...rest }, ref) => {
    return (
      <div className={cn('flex items-center justify-between gap-2.5', className)} ref={ref} {...rest}>
        <div className="flex items-center gap-2.5">
          {children}
          <h3 className="font-semibold leading-none">{title}</h3>
        </div>
        <div className="flex items-center gap-2.5">
          <IconButton onClick={onClose}>
            <XMarkIcon className="block w-5 h-5" />
          </IconButton>
        </div>
      </div>
    );
  }
);

Header.displayName = 'DialogModal.Header';

export const DialogModal = {
  Wrapper,
  Header,
};
