import { useQueryClient } from 'react-query';
import { ArrowPathIcon } from '@heroicons/react/20/solid';

import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { useSubscriptionMilestones } from '@/hooks/useSubscriptionMilestones';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { useResourceListFilters } from '@/ui/ResourceListFilters';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import FulfillmentTableHeader from './FulfillmenTableHeader';
import FulfillmentRow from './FulfillmentRow';

const FulfillmentTable = () => {
  const queryClient = useQueryClient();

  const { search, setSearch, filter, setFilter, hasFilter, resetAllFilters, orderBy, setOrderBy } =
    useResourceListFilters<string, string>('all', 'pending');

  const { data, refetch, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching, isLoading } =
    useSubscriptionMilestones({
      search,
      statusFilter: filter,
      orderBy,
    });

  const subscriptionMilestones = data?.pages.flatMap((page) => page.subscription_milestones) || [];
  const noResults = !isRefetching && !isLoading && subscriptionMilestones.length === 0;
  const totalCount = data?.pages[0]?.pagination?.total || 0;
  const showingCount = subscriptionMilestones?.length || 0;

  const handleActionTaken = () => {
    refetch();
    queryClient.invalidateQueries('referral_program_nav_kpis');
  };

  const title = hasFilter ? `No results found for current filters` : 'There are no Fulfillments yet';
  const primaryActionLabel = hasFilter ? 'Reset Filters' : 'New Offer';
  const onPrimaryActionClick = hasFilter ? resetAllFilters : undefined;
  const primaryActionIcon = hasFilter ? ArrowPathIcon : undefined;

  return (
    <>
      <FulfillmentTableHeader
        searchQuery={search}
        onSetSearchQuery={setSearch}
        filter={filter}
        onSetFilter={setFilter}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <ResourceListSearchText showingCount={showingCount} totalCount={totalCount} />
      {noResults ? (
        <EmptyCard
          title={title}
          primaryActionIcon={primaryActionIcon}
          primaryActionLabel={primaryActionLabel}
          onPrimaryActionClick={onPrimaryActionClick}
        />
      ) : (
        <Items>
          <ItemHeaders isSticky>
            <ItemHeader align={AlignType.LEFT}>Subscriber Email</ItemHeader>
            <ItemHeader align={AlignType.LEFT}>Status</ItemHeader>
            <ItemHeader align={AlignType.LEFT}>Reward Name</ItemHeader>
            <ItemHeader align={AlignType.LEFT}>Required Referrals</ItemHeader>
            <ItemHeader align={AlignType.LEFT}>Promo Code</ItemHeader>
            <ItemHeader align={AlignType.LEFT}>Milestone Redeemed on</ItemHeader>
            <ItemHeader>&nbsp;</ItemHeader>
          </ItemHeaders>

          <ItemsBody>
            {subscriptionMilestones.map((subscriptionMilestone) => (
              <FulfillmentRow
                key={subscriptionMilestone.id}
                subscriptionMilestone={subscriptionMilestone}
                onActionTaken={handleActionTaken}
              />
            ))}
          </ItemsBody>
        </Items>
      )}
      {hasNextPage && (
        <div className="flex justify-start mt-8 w-fit">
          <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </div>
      )}
    </>
  );
};

export default FulfillmentTable;
