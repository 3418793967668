import Flatpickr from 'react-flatpickr';
import Tippy from '@tippyjs/react';

import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import { useThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelDivider, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { Post } from '@/interfaces/post';

interface Props {
  post: Post;
  updateDisplayDate: (date: Date | null) => void;
}

const DisplayDateActionsMenu = ({ post, updateDisplayDate }: Props) => {
  const { openComposer } = useThreadComposer();

  const handleOpenComposer = () => {
    openComposer();
  };

  const resetToPublishDate = () => {
    updateDisplayDate(null);
  };

  return (
    <Styled.ContentWrapper>
      <Panel tabIndex={-1}>
        <PanelSection>
          <Styled.MenuButtonWrapper>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={handleOpenComposer}
              $fullWidth
              $leftSlot={<Icon name="Comment" />}
              $muted
            >
              Comment
            </Button>
            <PanelDivider />
            <Tippy
              offset={[0, 8]}
              placement="top"
              popperOptions={{
                modifiers: [{ name: 'flip' }],
              }}
              trigger="click"
              interactive
              content={
                <Flatpickr
                  className="text-gray-900 appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:cursor-not-allowed border-gray-300 opacity-100"
                  options={{
                    enableTime: false,
                    altInput: true,
                    inline: true,
                  }}
                  defaultValue={post.override_scheduled_at}
                  placeholder="Click to select a date"
                  onChange={([date]) => {
                    updateDisplayDate(date);
                  }}
                />
              }
            >
              <Button
                $variant="quaternary"
                $size="small"
                $fullWidth
                $leftSlot={<Icon name="Calendar" className="mr-2" />}
              >
                Change display date
              </Button>
            </Tippy>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={resetToPublishDate}
              $fullWidth
              $leftSlot={<Icon name="Reset" />}
              $muted
            >
              Reset to publish date
            </Button>
          </Styled.MenuButtonWrapper>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default DisplayDateActionsMenu;
