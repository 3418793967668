import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import RouteAdapter from '@/components/RouteAdapter';
import ErrorPage from '@/pages/Error';
import InviteAccept from '@/pages/InviteAccept';
import Login from '@/pages/Login';
import OauthCallback from '@/pages/OauthCallback';
import PasswordReset from '@/pages/PasswordReset';
import RequestPassword from '@/pages/RequestPassword';
import ConfirmSubscriptionChanges from '@/routes/mailer_actions/confirm_subscription_changes/$token';
import Signup from '@/routes/signup';
import EmailConfirmation from '@/routes/signup/email_confirmation';
import PhoneCollection from '@/routes/signup/phone_collection';
import PhoneVerification from '@/routes/signup/phone_verification';

import { OAuthProvider } from './context/oauth-context';
import AdvertiserAccessApp from './AdvertiserAccessApp';

export default function App() {
  const { pathname } = useLocation();

  return (
    <OAuthProvider>
      <QueryParamProvider ReactRouterRoute={RouteAdapter}>
        <Routes>
          <Route path="/404" element={<ErrorPage statusCode={404} />} />
          <Route path="/500" element={<ErrorPage statusCode={500} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/request_password_reset" element={<RequestPassword />} />
          <Route path="/password_reset" element={<PasswordReset />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signup/email_confirmation" element={<EmailConfirmation />} />
          <Route path="/signup/phone_collection" element={<PhoneCollection />} />
          <Route path="/signup/phone_verification" element={<PhoneVerification />} />
          <Route path="/invites/:inviteId/accept" element={<InviteAccept />} />
          <Route path="/oauth_callback" element={<OauthCallback />} />
          <Route path="advertiser_access/*" element={<AdvertiserAccessApp />} />
          <Route path="subscription_change/confirm/:token" element={<ConfirmSubscriptionChanges />} />
          <Route path="*" element={<Navigate replace to={`/login?redirect=${pathname}`} />} />
        </Routes>
      </QueryParamProvider>
    </OAuthProvider>
  );
}
