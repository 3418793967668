import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { CustomDomainTypes } from '../../interfaces/custom_domain';
import api from '../../services/swarm';

type CustomDomainPayload = {
  id: string;
  type: CustomDomainTypes;
  enabled: boolean;
};

const useCustomDomainToggleUsage = (onSuccess?: Function) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (payload: CustomDomainPayload) =>
      api
        .patch(`/custom_domains/${payload.id}/toggle_useage`, {
          publication_id: currentPublicationId,
          custom_domain: { type: payload.type, enabled: payload.enabled },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['custom_domains', currentPublicationId]);
      },
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'There was a problem setting up your custom domain');
      },
    }
  );
};

export default useCustomDomainToggleUsage;
