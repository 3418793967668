/* eslint-disable */

import { FC, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  GlobeAmericasIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/outline';
import Modal from '../Modal';
import cx from 'classnames';
import SubscriberSelect from '../SubscriberSelect';

// Interfaces
import { IData } from '../../interfaces/general';
import SwitchSlider from '../Form/SwitchSlider';

const Container = styled.div.attrs({
  className: 'flex border rounded-lg',
})`
  height: 90vh;
  width: 90vw;
`;

const Sidebar = styled.div.attrs({
  className: 'w-1/4 bg-white justify-center rounded-l-lg',
})``;

interface TabProps {
  active: boolean;
}

const Tab = styled.div.attrs<TabProps>(({ active }) => ({
  className: `${
    active ? 'bg-white' : 'text-black hover:bg-gray-800'
  } w-11/12 py-4 my-2 ml-auto text-right pr-6 rounded-l font-medium cursor-pointer`,
}))<TabProps>``;

interface PreviewContainerProps {
  screenSize: string;
}

const PreviewContainer = styled.iframe<PreviewContainerProps>`
  ${({ screenSize }) =>
    screenSize === 'mobile'
      ? 'width: 460px; height: 100%; margin: 0 auto; border-left: 1px dashed gray; border-right: 1px dashed gray;'
      : 'width: 100%; height: 100%;'}
`;

interface ScreenSizeButtonProps {
  active: boolean;
}

const ScreenSizeButton = styled.div.attrs<ScreenSizeButtonProps>(({ active }) => ({
  className: `px-4 py-2 cursor-pointer ${active ? 'text-primary-600 bg-gray-50' : 'text-gray-400 bg-gray-100'}`,
}))<ScreenSizeButtonProps>``;

interface Props {
  active: boolean;
  fetchPreview: (platform: string, advancedParams: IData, onFetch: (html: string) => void) => void;
  tabs?: string[] | undefined;
  onClose: () => void;
  showSubscriberSelect?: boolean;
}

const PreviewModal: FC<Props> = (props: Props) => {
  const { fetchPreview, active, onClose, tabs, showSubscriberSelect } = props;
  const previewContainer = useRef(null);

  const [activeTab, setActiveTab] = useState<string>(tabs && tabs.length > 0 ? tabs[0] : '');

  const [emailPreview, setEmailPreview] = useState('');
  const [webPreview, setWebPreview] = useState('');

  const [screenSize, setScreenSize] = useState('desktop');
  const [currentSubscriberId, setCurrentSubscriberId] = useState('');

  useEffect(() => {
    if (active && activeTab === 'Web') {
      if (window && window.hasOwnProperty('twttr')) {
        // @ts-ignore
        window.twttr.widgets.load(previewContainer.current);
      }
    }

    const functions: { [key: string]: (html: string) => void } = {
      email: (html: string) => setEmailPreview(html),
      web: (html: string) => setWebPreview(html),
    };

    const advancedParams = {
      current_subscriber_id: currentSubscriberId,
    };

    fetchPreview(activeTab.toLowerCase(), advancedParams, functions[activeTab.toLowerCase()]);
  }, [active, activeTab, fetchPreview, currentSubscriberId]);

  const handleClose = () => {
    setCurrentSubscriberId('');
    onClose();
  };

  const handleScreenSizeChange = (newSize: string) => {
    if (screenSize !== newSize) {
      setScreenSize(newSize);
    }
  };

  const handleCurrentSubscriberSelect = (subscriberId: string) => {
    setCurrentSubscriberId(subscriberId);
  };

  const handlecurrentSubscriberClear = () => {
    setCurrentSubscriberId('');
  };

  const currentPreview = activeTab === 'Email' ? emailPreview : webPreview;

  return (
    <Modal isOpen={active} onClose={handleClose}>
      <Container ref={previewContainer}>
        <Sidebar>
          <div className="h-full p-2 xl:p-4 border-r border-gray">
            {/* Only show Email tab */}
            {tabs?.length === 1 && (
              <div
                className={cx(
                  'mt-6 px-2 flex items-center justify-center h-14 w-full rounded-md bg-white border border-solid border-gray-200 select-none'
                )}
              >
                <EnvelopeIcon className={cx('mr-1 xl:mr-2 h-6 w-6', 'text-primary-600')} />
                <span className="hidden lg:block">Email</span>
              </div>
            )}
            {tabs?.length === 2 && (
              <SwitchSlider
                name="emailWeb"
                className="pt-6"
                checked={activeTab === 'Web'}
                onChange={function (name: string, checked: boolean): void {
                  const newTab = checked ? 'Web' : 'Email';
                  return setActiveTab(newTab);
                }}
                child1={
                  <>
                    <EnvelopeIcon
                      className={cx('mr-1 xl:mr-2 h-6 w-6', activeTab === 'Email' ? 'text-primary-600' : 'text-black')}
                    />
                    <span className="hidden lg:block">Email</span>
                  </>
                }
                child2={
                  <>
                    <GlobeAmericasIcon
                      className={cx('mr-1 xl:mr-2 h-6 w-6', activeTab === 'Web' ? 'text-primary-600' : 'text-black')}
                    />
                    <span className="hidden lg:block">Web</span>
                  </>
                }
              />
            )}

            <SwitchSlider
              name="desktopMobile"
              className="pt-4"
              checked={screenSize === 'mobile'}
              onChange={function (name: string, checked: boolean): void {
                const newTab = checked ? 'mobile' : 'desktop';
                return handleScreenSizeChange(newTab);
              }}
              child1={
                <>
                  <ComputerDesktopIcon
                    className={cx('mr-1 xl:mr-2 h-6 w-6', screenSize === 'desktop' ? 'text-primary-600' : 'text-black')}
                  />
                  <span className="hidden lg:block">Desktop</span>
                </>
              }
              child2={
                <>
                  <DevicePhoneMobileIcon
                    className={cx('mr-1 xl:mr-2 h-6 w-6', screenSize === 'mobile' ? 'text-primary-600' : 'text-black')}
                  />
                  <span className="hidden lg:block">Mobile</span>
                </>
              }
            />
            {showSubscriberSelect && (
              <div className="mt-12">
                <SubscriberSelect
                  labelClassName="text-black"
                  className="mt-4 max-w-xs"
                  onSelectSubscriber={handleCurrentSubscriberSelect}
                  onClearSubscriber={handlecurrentSubscriberClear}
                  labelText="Select a subscriber"
                />
              </div>
            )}
          </div>
        </Sidebar>
        <div className="h-full w-full rounded-r-md overflow-auto">
          <PreviewContainer srcDoc={currentPreview} screenSize={screenSize} />
        </div>
      </Container>
    </Modal>
  );
};

PreviewModal.defaultProps = {
  tabs: ['Email'],
  showSubscriberSelect: false,
};

export default PreviewModal;
