import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';

import useIsMobile from './hooks/useIsMobile';

type Props = PropsWithChildren<{
  reason?: string;
}>;

const DeviceGate = ({ children, reason = 'Unsupported Device' }: Props) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <>
        <Modal isOpen onClose={() => {}} includeCloseButton={false}>
          <div className="flex flex-col gap-y-2 justify-center items-center h-96 text-center p-4">
            <InformationCircleIcon className="w-8 h-8 text-gray-400" />
            <Typography token="font-bold/text/sm">{reason}</Typography>
            <Button type="button" onClick={() => navigate('/')} className="mt-2">
              Return to Homepage
            </Button>
          </div>
        </Modal>
        <div className="blur-sm">{children}</div>
      </>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default DeviceGate;
