import { Textarea } from '@/components/Form';

import VerifyStripeIdentityForm from '../../Shared/VerifyStripeIdentityForm';

interface Props {
  whyMeLabel: string;
  whyMeValue: string;
  setWhyMe: (value: string) => void;
}

const ApplyForm = ({ whyMeLabel, whyMeValue, setWhyMe }: Props) => {
  return (
    <div className="mt-8 mb-6 w-full relative">
      <VerifyStripeIdentityForm titleText="Before you can submit an application, we need to verify your identity">
        <div className="flex flex-col gap-y-6">
          <Textarea
            name="why_me"
            labelText={whyMeLabel}
            value={whyMeValue}
            onChange={(e) => setWhyMe(e.target.value)}
            rows={4}
            placeholderText="I think my newsletter is a good fit because…"
            helperText="Historically, newsletters that provide context are more likely to have their application accepted."
          />
          <hr />
          <p className="text-xs text-gray-500">
            We will also share some approximate information about your newsletter, such as size, engagement, and growth
            rate (we won&apos;t share any actual numbers). This is to help the publisher make a more informed decision
            about whether to accept your application.
          </p>
        </div>
      </VerifyStripeIdentityForm>
    </div>
  );
};

export default ApplyForm;
