import { useState } from 'react';
import toast from 'react-hot-toast';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

import { Input, RadioSelect } from '../../../components/Form';
import SlideOver from '../../../components/SlideOver';
import { useSendInvite } from '../../../hooks/useInvites';
import { RoleOption } from '../../../interfaces/organization';
import { capitalize } from '../../../utils';

const InviteMemberForm = ({ isOpen, close, organization }: any) => {
  const [selection, setSelection] = useState<any>(null);
  const [inviteEmail, setInviteEmail] = useState<string>('');

  const sendInviteMutation = useSendInvite();

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();

    if (selection && inviteEmail) {
      const match = organization?.role_options.find((option: RoleOption) => {
        return option.name === selection;
      });

      try {
        await sendInviteMutation.mutateAsync({
          invited_email: inviteEmail,
          role_id: match.role_id,
          invitable_type: match.object_type,
          invitable_id: match.object_id,
        });
        setInviteEmail('');
        setSelection(null);
        close();
      } catch (err) {
        toast.error('Oops! Something went wrong!');
      }
    } else {
      toast.error('Please select a role and enter an email address');
    }
  };

  return (
    <SlideOver
      bodyId={organization?.id}
      isOpen={isOpen}
      onClose={() => {
        setInviteEmail('');
        setSelection(null);
        close();
      }}
      headerText="Invite Team Member"
    >
      <form onSubmit={handleUpdate}>
        <div className="space-y-4">
          <Input
            className="w-full"
            name="email"
            labelText="Email"
            placeholder="new.member@email.com"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
          {organization?.role_options && (
            <RadioSelect
              labelText="Select Permission Level"
              value={selection}
              onSelect={(val) => setSelection(val)}
              options={organization?.role_options.map((option: RoleOption) => ({
                name: capitalize(option.name),
                value: option.name,
                description: option.description,
              }))}
            />
          )}
          <div className="w-full flex justify-end space-x-4 pt-4">
            <Button type="submit" variant="primary" onClick={() => {}} loading={sendInviteMutation.isLoading}>
              <div className="flex">
                <EnvelopeIcon className="h-5 w-5 mr-2" />
                {sendInviteMutation.isLoading ? 'Sending...' : 'Send Invite'}
              </div>
            </Button>
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default InviteMemberForm;
