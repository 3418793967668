import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon, ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Skeleton } from '../SkeletonLoader';

type Crumb = {
  to: string;
  children: React.ReactNode;
  isLoading?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  backLanguage?: string;
};

interface Props {
  children: React.ReactNode[] | React.ReactNode;
}

export default function Breadcrumbs({ children }: Props) {
  const chldrn = React.Children.toArray(children) as React.ReactElement<Crumb>[];

  const previousCrumb = chldrn[chldrn.length - 2];
  const backLink = previousCrumb ? previousCrumb.props.to : '/';
  const backLanguage = previousCrumb?.props.backLanguage ? previousCrumb.props.backLanguage : 'Back';

  return (
    <div className="mb-6">
      <ul className="md:flex items-center hidden py-1">
        {React.Children.map(chldrn, (child, index) => (
          <React.Fragment key={child.props.to}>
            {child}

            {index !== chldrn.length - 1 && (
              <ChevronRightIcon className="flex-shrink-0 inline w-5 h-5 mx-1 text-gray-500" />
            )}
          </React.Fragment>
        ))}
      </ul>
      <Link className="text-sm text-gray-500 hover:text-primary-500 flex md:hidden" to={backLink}>
        <div className="flex items-center">
          <ArrowLeftIcon className="h-4 w-4 mr-2" />
          <span>{backLanguage}</span>
        </div>
      </Link>
    </div>
  );
}

const Item = ({ to, children, isLoading = false }: Crumb) => (
  <li className="max-w-xs truncate inline-flex items-center">
    {isLoading ? (
      <div className={cx('w-36 animate-pulse')}>
        <Skeleton className="h-4" />
      </div>
    ) : (
      <Link className="text-sm font-medium text-gray-500 hover:text-primary-500" to={to}>
        {children}
      </Link>
    )}
  </li>
);

const Home = () => (
  <Item to="/">
    <HomeIcon className="w-4 h-4 text-gray-500 hover:text-primary-500" />
  </Item>
);

Breadcrumbs.Item = Item;
Breadcrumbs.Home = Home;
