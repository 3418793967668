import { Fragment, ReactNode } from 'react';

import { useSettings } from '@/context/settings-context';
import usePostEmailClickStats from '@/hooks/usePostEmailClickStats';
import usePostSendAndOpenStats from '@/hooks/usePostSendAndOpenStats';
import usePostWebStats from '@/hooks/usePostWebStats';
import { PostStatsOverview } from '@/interfaces/post';

interface Props {
  postId: string;
  postStatsOverview: PostStatsOverview;
  isPremiumEnabled: boolean;
  staleTime?: number;
  children: (stats: PostStatsOverview) => ReactNode;
}

const clickRate = (totalUniqueEmailClicked?: number, totalUniqueOpened?: number) => {
  if (!totalUniqueOpened) {
    return 0;
  }

  return Number((((totalUniqueEmailClicked || 0) / totalUniqueOpened) * 100).toFixed(2));
};

const openRate = (totalDelivered?: number, totalOpened?: number) => {
  if (!totalDelivered) {
    return 0;
  }

  return Number((((totalOpened || 0) / totalDelivered) * 100).toFixed(2));
};

const PostStatsOverviewContainer = ({
  postId,
  postStatsOverview,
  isPremiumEnabled,
  staleTime = 1000 * 60,
  children,
}: Props) => {
  const { settings } = useSettings();
  const canUseApiary = settings?.use_apiary === true;

  // Apiary calls
  const {
    data: postWebStatsData,
    isLoading: isLoadingPostWebStats,
    isError: isErrorPostWebStats,
  } = usePostWebStats({ id: postId, isEnabled: canUseApiary, staleTime });
  const {
    data: postEmailClickStatsData,
    isLoading: isLoadingPostEmailClickStats,
    isError: isErrorPostEmailClickStats,
  } = usePostEmailClickStats({ id: postId, isEnabled: canUseApiary, staleTime });
  const {
    data: postSendAndOpenStatsData,
    isLoading: isLoadingPostSendAndOpenStats,
    isError: isErrorPostSendAndOpenStats,
  } = usePostSendAndOpenStats({ id: postId, isEnabled: canUseApiary, staleTime });

  if (canUseApiary) {
    const isLoadingAllData = isLoadingPostWebStats || isLoadingPostEmailClickStats || isLoadingPostSendAndOpenStats;
    const hasErrorLoadingData = isErrorPostWebStats || isErrorPostEmailClickStats || isErrorPostSendAndOpenStats;

    if (isLoadingAllData || hasErrorLoadingData) {
      return (
        <Fragment key={`post-stats-overview-container-${postId}`}>
          {children({
            click_rate: 0,
            open_rate: 0,
            total_sent: 0,
            total_upgrades: undefined,
            total_web_viewed: 0,
            total_unique_web_clicked: 0,
          })}
        </Fragment>
      );
    }

    return (
      <Fragment key={`post-stats-overview-container-${postId}`}>
        {children({
          click_rate: clickRate(
            postEmailClickStatsData?.total_unique_verified_clicked,
            postSendAndOpenStatsData?.total_unique_opened
          ),
          open_rate: openRate(postSendAndOpenStatsData?.total_sent, postSendAndOpenStatsData?.total_unique_opened),
          total_sent: postSendAndOpenStatsData?.total_sent || 0,
          total_upgrades: isPremiumEnabled ? postWebStatsData?.total_upgrades || 0 : undefined,
          total_web_viewed: postWebStatsData?.total_web_viewed || 0,
          total_unique_web_clicked: postWebStatsData?.total_unique_web_clicked || 0,
        })}
      </Fragment>
    );
  }

  return (
    <Fragment key={`post-stats-overview-container-${postId}`}>
      {children({
        click_rate: postStatsOverview?.click_rate || 0,
        open_rate: postStatsOverview?.open_rate || 0,
        total_sent: postStatsOverview?.total_sent || 0,
        total_upgrades: isPremiumEnabled ? postStatsOverview?.total_upgrades || 0 : undefined,
        total_web_viewed: postStatsOverview?.total_web_viewed || 0,
        total_unique_web_clicked: postStatsOverview?.total_unique_web_clicked || 0,
      })}
    </Fragment>
  );
};

export default PostStatsOverviewContainer;
