import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';

export enum StripeAccountStatus {
  MISSING = 'missing', // The account has not been created yet
  ACTIVE = 'active', // The account can receieve payouts
  INACTIVE = 'inactive', // The account information is invalid or incomplete
  PENDING = 'pending', // The account information is being verified
}

export enum StripeAccountType {
  STANDARD = 'standard', // A standard Stripe account
  EXPRESS = 'express', // An express Stripe account
}

interface ApiResponse {
  boosts_account_status: StripeAccountStatus;
  boosts_account_type: StripeAccountType | null;
}

const useStripeConnectAccount = (props?: { enabled?: boolean }) => {
  const { enabled = true } = props || {};
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchStripeConnectAccount = (): Promise<ApiResponse> =>
    api
      .get(`/boosts/monetize/stripe_connect_account`, { params: { publication_id: currentPublicationId } })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'boosts', 'monetize', 'stripe_connect_account'], fetchStripeConnectAccount, {
    enabled,
  });
};

export default useStripeConnectAccount;
