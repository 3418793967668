import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import TabNavigation from '@/components/TabNavigation';
import { useSettings } from '@/context/settings-context';
import useEmailApplications from '@/hooks/boosts/grow/useEmailApplications';
import useWebApplications from '@/hooks/boosts/grow/useWebApplications';
import useDismissable, { DismissableKey } from '@/hooks/useDismissable';
import { BoostOffer } from '@/interfaces/boosts/grow/boost_offer';
import { Tab } from '@/interfaces/general';

import ApplicationsHelperBlock from './ApplicationsHelperBlock';
import EmailApplications from './EmailApplications';
import WebApplications from './WebApplications';

interface Props {
  boostOffer?: BoostOffer;
}

const Applications = ({ boostOffer }: Props) => {
  const [searchParams] = useSearchParams();
  const isViewEmailApplication = !!searchParams.get('boost_send_id'); // When a user lands from a notification
  const { settings } = useSettings();
  const { wasDismissed, onDismiss } = useDismissable(DismissableKey.HELPER_BLOCK_BOOSTS_APPLICATIONS);
  const [selectedTab, setSelectedTab] = useState(isViewEmailApplication ? 'emailApplications' : 'webApplications');

  const { count: webApplicationsCount } = useWebApplications(boostOffer?.id || '', { perPage: 1 });
  const { count: emailApplicationsCount } = useEmailApplications(boostOffer?.id, {
    maxSpendCents: boostOffer?.max_spend_cents,
    perPage: 1,
  });

  const tabs = [
    {
      name: 'webApplications',
      label: 'Web Applications',
      count: webApplicationsCount,
      onSelect: () => setSelectedTab('webApplications'),
      selected: selectedTab === 'webApplications',
    },
    {
      name: 'emailApplications',
      label: 'Email  Applications',
      count: emailApplicationsCount,
      onSelect: () => setSelectedTab('emailApplications'),
      selected: selectedTab === 'emailApplications',
    },
  ] as Tab[];

  const canViewEmailApplications = settings?.boosts_plus === true;

  return (
    <>
      {!wasDismissed && <ApplicationsHelperBlock onDismiss={onDismiss} />}
      {canViewEmailApplications && <TabNavigation variant="secondary" tabs={tabs} />}

      {selectedTab === 'webApplications' && <WebApplications boostOfferId={boostOffer?.id} />}
      {selectedTab === 'emailApplications' && (
        <EmailApplications boostOfferId={boostOffer?.id} maxSpendCents={boostOffer?.max_spend_cents} />
      )}
    </>
  );
};

export default Applications;
