import Tippy from '@tippyjs/react';

import { useThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Post } from '@/interfaces/post';

import ThumbnailActionsMenu from '../ThumbnailActionsMenu';

interface Props {
  updatePost: (data: Post) => void;
  post: Post;
  fileRef: React.RefObject<HTMLInputElement>;
  setShowImageLibrary: (value: boolean) => void;
}

const ThumbnailTooltipMenu = ({ post, updatePost, fileRef, setShowImageLibrary }: Props) => {
  const { openComposer } = useThreadComposer();

  const handleOpenComposer = () => {
    openComposer();
  };

  const deleteThumbnail = () => {
    updatePost({ ...post, ...{ thumbnail_id: 'delete', thumbnail: null } });
  };

  return (
    <div className="flex h-[3rem] bg-gray-800 rounded-lg p-2">
      <Tippy
        offset={[0, 8]}
        placement="bottom-start"
        popperOptions={{
          modifiers: [{ name: 'flip' }],
        }}
        trigger="click"
        interactive
        content={
          <div className="absolute z-50">
            <ThumbnailActionsMenu
              post={post}
              fileRef={fileRef}
              setShowImageLibrary={setShowImageLibrary}
              updatePost={updatePost}
            />
          </div>
        }
      >
        <Button $variant="quaternary" $leftSlot={<Icon name="Refresh" className="mr-2" />} />
      </Tippy>
      <Button $variant="quaternary" $leftSlot={<Icon name="Comment" />} onClick={handleOpenComposer} />
      <Button $variant="quaternary" $leftSlot={<Icon name="Trash" />} onClick={deleteThumbnail} />
    </div>
  );
};

export default ThumbnailTooltipMenu;
