import { useCallback, useRef } from 'react';
import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import { Instance, sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';

import { MenuProps } from '../../../components/menus/types';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';
import { OptionsPanel } from '../panels/Options/OptionsPanel';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const AdvertisementOpportunityMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);

  const tippyInstance = useRef<Instance | null>(null);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-advertisementOpportunity');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('advertisementOpportunity');

    return isActive;
  }, [editor]);

  const removeNode = useCallback(() => {
    editor.chain().focus().deleteSelection().run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`advertisementOpportunityMenu-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar shouldShowContent={shouldShow()} ref={menuRef}>
        <>
          <OptionsPanel editor={editor} parentRef={menuRef} />
          <Divider />
          <Tooltip title="Remove ad">
            <Button
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...buttonProps}
              $leftSlot={<Icon name="Trash" />}
              onClick={removeNode}
            />
          </Tooltip>
        </>
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default AdvertisementOpportunityMenu;
