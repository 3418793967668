import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowsPointingOutIcon, BoltIcon, ComputerDesktopIcon, DevicePhoneMobileIcon } from '@heroicons/react/20/solid';

import { SimpleSelect } from '@/components/Form';
import { PlanTag } from '@/components/Plan';
import { useSettings } from '@/context/settings-context';
import usePlan from '@/hooks/usePlan';
import { Option } from '@/interfaces/general';
import { ScreenSizes } from '@/pages/DesignLab/components/ScreenSize';
import usePageNavigator, { Pages } from '@/routes/website_builder/_hooks/usePageNavigator';
import { Button } from '@/ui/Button';

import { usePageContext } from '../../Context/PageContext';

interface Props {
  currentPath?: string;
  disablePublishActions?: boolean;
}

const PageSelect = ({ currentPath, disablePublishActions }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { plan } = usePlan();
  const { settings } = useSettings();
  const isLaunchPlan = plan === 'launch';
  const isFeatureFlagged = settings?.landing_pages;
  const showUpgradeTag = isLaunchPlan && !isFeatureFlagged;
  const isCustomPages = location.pathname.includes('/website_builder/custom_pages');

  const { pageType, handlePageChange } = usePageNavigator();
  const { setScreenSize, screenSize } = usePageContext();

  const options = [
    {
      onClick: () => setScreenSize(ScreenSizes.DESKTOP),
      size: ScreenSizes.DESKTOP,
      disabled: disablePublishActions,
      text: 'Desktop',
      Icon: ComputerDesktopIcon,
    },
    {
      onClick: () => setScreenSize(ScreenSizes.MOBILE),
      size: ScreenSizes.MOBILE,
      disabled: disablePublishActions,
      text: 'Mobile',
      Icon: DevicePhoneMobileIcon,
    },
    {
      onClick: () => setScreenSize(ScreenSizes.FULL_SCREEN),
      size: ScreenSizes.FULL_SCREEN,
      disabled: disablePublishActions,
      text: 'Full Screen',
      Icon: ArrowsPointingOutIcon,
    },
  ];

  const PAGE_OPTIONS: Option[] = [
    { label: 'Core', value: '', isOptGroup: true },
    { label: 'Home Page', value: '/home_page' },
    { label: 'Signup Page', value: '/signup_page' },
    {
      label: 'Upgrade Page',
      value: '/upgrade_page',
      optionSuffix: showUpgradeTag && <PlanTag text="Upgrade" variant="secondary" Icon={BoltIcon} />,
    },
    { label: 'Custom', value: '', isOptGroup: true },
    {
      label: 'Custom Pages',
      value: '/custom_pages',
      optionSuffix: showUpgradeTag && <PlanTag text="Upgrade" variant="secondary" Icon={BoltIcon} />,
    },
  ];

  const selectedPageValue =
    currentPath || PAGE_OPTIONS.find((option) => option.value === `/${pageType}`)?.value || PAGE_OPTIONS[0].value;

  const handleNavigate = (path: Pages) => {
    handlePageChange({ path: '/website_builder', pageType: path });
  };

  const handleSelect = (_name: string, value: string) => {
    if (value === '/custom_pages') {
      navigate('/website_builder/custom_pages/view');
    } else {
      const page = value.split('/')[1];
      handleNavigate(page as Pages);
    }
  };

  return (
    <div className="flex justify-center space-x-3">
      <div className="flex flex-row space-x-1">
        {!isCustomPages &&
          options.map((option: any) => {
            return (
              <Button
                style={{
                  ...(option.size === screenSize && { backgroundColor: '#E5E7EB' }),
                }}
                type="button"
                disabled={option.disabled}
                onClick={option.onClick}
                Icon={option.Icon}
                key={option.text}
                variant="flush"
              />
            );
          })}
      </div>
      <div className="w-64">
        <SimpleSelect
          name="page_options"
          value={selectedPageValue}
          options={PAGE_OPTIONS}
          onSelect={(_name: string, value: string) => {
            handleSelect(_name, value);
          }}
        />
      </div>
    </div>
  );
};

export default PageSelect;
