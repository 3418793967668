import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Navigate, useLocation } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import { ScrollOnMount } from '@/ui/ScrollOnMount';

import AdvancedOptionsSection from './_components/AdvancedOptionsSection';
import CancellationSection from './_components/CancellationSection';
import EnsurePremiumAccess from './_components/EnsurePremiumAccess';
import EnsureStripeConnection from './_components/EnsureStripeConnection';
import PremiumGiftsSection from './_components/PremiumGiftsSection';
import PremiumOffersSection from './_components/PremiumOffersSection';
import TierConfigurationSection from './_components/TierConfigurationSection';
import UpsellEmailSection from './_components/UpsellEmailSection';

const UpgradeRedirect = () => {
  useEffect(() => {
    toast.error('You need to upgrade your account before you can access our premium features!');
  }, []);

  return <Navigate to="/settings/billing" />;
};

const PublicationPremiumSettings: React.FC = () => {
  const { data: currentPublication, isLoading, isError } = useCurrentPublication();
  const { settings, isLoading: loadingSettings } = useSettings();

  if (settings && !settings.premium_subscriptions) {
    <UpgradeRedirect />;
  }

  const { hash: scrollToElementId } = useLocation();

  return (
    <LoadingBox isLoading={isLoading || loadingSettings} isError={isError} backgroundClassName="bg-transparent">
      {currentPublication && (
        <div className="w-full max-w-4xl py-8 mx-auto">
          <EnsurePremiumAccess>
            <EnsureStripeConnection>
              <ScrollOnMount elementId={scrollToElementId?.replace('#', '')} scrollIntoView>
                <div className="flex flex-col gap-y-10 items-start px-4">
                  <TierConfigurationSection />
                  <PremiumOffersSection />
                  <UpsellEmailSection />
                  {settings?.gift_premium_subscriptions && <PremiumGiftsSection />}
                  <AdvancedOptionsSection publication={currentPublication} />
                  <CancellationSection />
                </div>
              </ScrollOnMount>
            </EnsureStripeConnection>
          </EnsurePremiumAccess>
        </div>
      )}
    </LoadingBox>
  );
};

export default PublicationPremiumSettings;
