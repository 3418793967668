import { useState } from 'react';

import { Button } from '@/ui/Button';

import ActionModal from '../../../../../components/ActionModal';
import { useCancelInvite } from '../../../../../hooks/useInvites';
import NotEditing from '../../../Components/NotEditing';

const CancelInvite = ({ invite }: any) => {
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const cancelMutation = useCancelInvite(invite.id);

  const handleCancelInvite = async () => {
    // eslint-disable-next-line no-alert

    const today = new Date();
    await cancelMutation.mutateAsync({
      invitable_type: invite.invitable_type,
      invitable_id: invite.invitable_id,
      declined_at: today.toISOString(),
    });
  };

  return (
    <>
      <div className="absolute">
        <ActionModal
          isOpen={isConfirming}
          onClose={() => setIsConfirming(false)}
          onProceed={() => handleCancelInvite()}
          resourceId={invite.id}
          isWorking={cancelMutation.isLoading}
          headerText="Cancel Invite"
          actionText="Cancel Invite"
          buttonType="danger"
        >
          Are you sure you want to cancel this invite? While this action cannot be undone, you can always send a new
          invite.
        </ActionModal>
      </div>
      <div className="flex justify-between items-center border-b border-gray-100 pb-4">
        <NotEditing label={invite.invited_email} value={`${invite.role.name} — ${invite.invitable_name}`} />

        <div>
          <Button variant="danger" onClick={() => setIsConfirming(true)}>
            Cancel Invite
          </Button>
        </div>
      </div>
    </>
  );
};

export default CancelInvite;
