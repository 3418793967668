import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { SortableList, SortableListItem, useSortableList } from '@/components/SortableList';

import { Input, Switch } from '../../../../components/Form';
import { useWebTemplate } from '../../../../hooks';
import { useUpdateHomePage } from '../../../../hooks/useHomePages';
import useOptions from '../../../../hooks/useOptions';
import { HomePage } from '../../../../interfaces/home_page';
import FeaturedPosts from '../../../Settings/Pages/Website/Advanced/FeaturedPosts';
import ThemeEditorDropdown from '../../../ThemeEditor/components/ThemeEditorDropdown';
import ThemeFormDrawer from '../../../ThemeEditor/components/ThemeFormDrawer';
import { FormContainer, FormInput, FormSection } from '../../../ThemeEditor/helpers';
import { usePageContext } from '../../components/PageContext';

type CategoryItem = {
  id: string;
  value: string;
};

const FeaturedPostsSection = () => {
  const queryClient = useQueryClient();
  const webTemplateRequest = useWebTemplate();
  const { pageData, setIsSaving, handleSaved, currentPublication } = usePageContext<HomePage>();

  const tags = useOptions('content_tags');
  const tagOptions = tags?.data?.options
    ?.filter((tag: any) => !pageData?.posts_section?.featured_sections?.category_posts.includes(tag.display))
    ?.map((tag: any) => {
      return { label: tag?.display, value: tag?.display };
    });

  const currentFeaturedSections = pageData?.posts_section?.featured_sections || [];
  const categoryPosts = currentFeaturedSections?.category_posts || [];

  const { sortableList, setSortableList, reStringifyList } = useSortableList<CategoryItem>({
    list: categoryPosts,
    addIdToListOfStrings: true,
  });

  const [featuredSections, setFeaturedSections] = useState<any>(currentFeaturedSections);

  const handleSuccess = () => {
    queryClient.invalidateQueries(['home_page', currentPublication?.id, 'draft']);
    handleSaved();
  };

  useEffect(() => {
    setFeaturedSections(currentFeaturedSections);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);

  const updateHomePage = useUpdateHomePage({
    pageId: pageData.id,
    onSuccess: () => handleSuccess(),
  });

  const handleSavePostsSection = (payload: any) => {
    setIsSaving(true);
    updateHomePage.mutate({ home_page: { posts_section: JSON.stringify(payload) } });
  };

  return (
    <ThemeFormDrawer active={false} title="Featured Posts" onClickHandler={() => {}}>
      <FormContainer>
        <FormSection
          title="Highlighted Categories"
          protip="Please note: Featured posts will be highlighted above-the-fold with the News/Headliner headers."
        >
          <FormInput
            title="Featured Posts"
            helperText="You may add up to 6 featured posts."
            tooltipText="Featured posts may also be highlighted on your Landing Page. Updates here may have unintended side-effects to that page."
          >
            <div className="space-y-2">
              <Input
                name="featured_posts_label"
                helperText="Update or self-translate the title of this section."
                value={featuredSections?.featured_posts_label || ''}
                maxLength={150}
                onChange={(e) => setFeaturedSections({ ...featuredSections, featured_posts_label: e.target.value })}
                onBlur={() => {
                  handleSavePostsSection({
                    ...pageData?.posts_section,
                    featured_sections: featuredSections,
                  });
                }}
              />
              <FeaturedPosts webTemplateRequest={webTemplateRequest} autoSave onSuccess={() => handleSuccess()} />
            </div>
          </FormInput>
          <FormInput title="Latest Posts" helperText="Update self-translate the title of this section.">
            <div className="space-y-2">
              <Switch
                variant="primary"
                name="latest_posts_enabled"
                labelText="Show Latest Posts?"
                checked={featuredSections.latest_posts_enabled}
                onChange={(_name: string, updatedValue: boolean) => {
                  handleSavePostsSection({
                    ...pageData?.posts_section,
                    featured_sections: {
                      ...featuredSections,
                      latest_posts_enabled: updatedValue,
                    },
                  });
                }}
              />
              <Input
                name="latest_posts_label"
                value={featuredSections?.latest_posts_label || ''}
                maxLength={150}
                onChange={(e) => setFeaturedSections({ ...featuredSections, latest_posts_label: e.target.value })}
                onBlur={() => {
                  handleSavePostsSection({
                    ...pageData?.posts_section,
                    featured_sections: featuredSections,
                  });
                }}
              />
            </div>
          </FormInput>
          <FormInput title="Categories" helperText="Add category sections to your home page.">
            <div className="space-y-2">
              <SortableList
                listItems={sortableList}
                onItemsSorted={(sortedList: any[]) => {
                  setSortableList(sortedList);
                  const reStringifiedList = reStringifyList(sortedList);
                  return handleSavePostsSection({
                    ...pageData?.posts_section,
                    featured_sections: {
                      ...featuredSections,
                      category_posts: reStringifiedList,
                    },
                  });
                }}
              >
                {(list) => {
                  return list.map((item: CategoryItem) => {
                    return (
                      <SortableListItem
                        key={item.id}
                        listItem={item}
                        onRemoveItem={(categoryItem: CategoryItem) => {
                          const newList = list.filter((category: CategoryItem) => category.id !== categoryItem.id);
                          setSortableList(newList);
                          const reStringifiedList = reStringifyList(newList);
                          return handleSavePostsSection({
                            ...pageData?.posts_section,
                            featured_sections: {
                              ...featuredSections,
                              category_posts: reStringifiedList,
                            },
                          });
                        }}
                        text={item.value}
                      />
                    );
                  });
                }}
              </SortableList>

              {tagOptions?.length > 0 ? (
                <ThemeEditorDropdown
                  currentValue=""
                  onUpdate={(val: string) => {
                    const newVal: CategoryItem = { id: String(sortableList.length + 1), value: val };
                    setSortableList([...sortableList, newVal]);
                    return handleSavePostsSection({
                      ...pageData?.posts_section,
                      featured_sections: {
                        ...featuredSections,
                        category_posts: [...(featuredSections?.category_posts || []), val],
                      },
                    });
                  }}
                  options={tagOptions}
                />
              ) : null}
            </div>
          </FormInput>
        </FormSection>
      </FormContainer>
    </ThemeFormDrawer>
  );
};

export default FeaturedPostsSection;
