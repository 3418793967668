import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Pagination } from '../interfaces/general';
import { PostPreview } from '../interfaces/post';
import PostSearch from '../models/postSearch';
import api from '../services/swarm';

import useCurrentTimeZone from './useCurrentTimeZone';

interface ApiResponse {
  posts: PostPreview[];
  pagination: Pagination;
}

export default function usePosts(query: PostSearch, reRun: boolean = false) {
  const [currentPublicationId] = useCurrentPublicationState();
  const currentTimeZone = useCurrentTimeZone();

  const fetchPosts = ({ pageParam = 1 }) =>
    api
      .get(`/posts`, {
        params: {
          page: pageParam,
          per_page: 10,
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
          ...query.params(),
        },
      })
      .then((res) => res.data);

  const queryKeys = [currentPublicationId, currentTimeZone, 'posts', reRun, Object.values(query.params())].flat();

  return useInfiniteQuery<ApiResponse>(queryKeys, fetchPosts, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
