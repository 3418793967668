import { useMemo, useState } from 'react';
import { GlobeAltIcon, PlusIcon, TrashIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import Badge from '@/components/Badge';
import { EmptyBlock } from '@/components/EmptyBlock';
import { Input, Label, SimpleSelect, Textarea } from '@/components/Form';
import IconButton from '@/components/IconHelpers/IconButton';
import SubscriberSelect from '@/components/SubscriberSelect';
import { Typography } from '@/components/Typography';
import useTestCreate from '@/hooks/useWebhookDestinations/useTestCreate';
import { WebhookDestination, WebhookDestinationContentType, WebhookDestinationRequestMethod, WebhookDestinationTestResponse } from '@/interfaces/webhook_destination';
import { Button } from '@/ui/Button';
import CodeBlock from '@/ui/CodeBlock';

const REQUEST_METHOD_OPTIONS = [
  { label: 'GET', value: 'get' },
  { label: 'POST', value: 'post' },
  { label: 'PUT', value: 'put' },
  { label: 'PATCH', value: 'patch' },
  { label: 'DELETE', value: 'delete' },
];

const CONTENT_TYPE_OPTIONS = [
  { label: 'JSON (application/json)', value: 'json' },
  { label: 'XML (application/xml)', value: 'xml' },
  { label: 'Form (application/x-www-form-urlencoded)', value: 'form' }
];

// Generate a preview of the payload that will be sent to the webhook destination. This will be
// displayed in the sidebar of the workflow builder and can be copied to the clipboard.
const createPayloadPreview = (contentType: string, automationId: string, subscriberEmail?: string, subscriberId?: string) => {
  switch (contentType) {
    case 'json':
      return `{
  "automation_id": "aut_${automationId}",
  "automation_journey_id": "aj_00000000-0000-0000-0000-000000000000",
  "automation_journey_step_started_at": ${new Date().toISOString()},
  "subscriber_email": "${subscriberEmail || 'email@example.com'}",
  "subscriber_id": "sub_${subscriberId || '00000000-0000-0000-0000-000000000000'}",
  "test": true
}`
    case 'xml':
      return `<?xml version="1.0" encoding="UTF-8"?>
<hash>
  <automation_id>aut_${automationId}</automation_id>
  <automation_journey_id>aj_00000000-0000-0000-0000-000000000000</automation_journey_id>
  <automation_journey_step_started_at>${new Date().toISOString()}</automation_journey_step_started_at>
  <subscriber_email>${subscriberEmail || 'email@example.com'}</subscriber_email>
  <subscriber_id>sub_${subscriberId || '00000000-0000-0000-0000-000000000000'}</subscriber_id>
  <test>true</test>
</hash>`
    case 'form':
      return [
        `automation_id=aut_${automationId}&automation_journey_id=<string>`,
        `automation_journey_step_started_at=${new Date().toISOString()}`,
        `subscriber_email=${subscriberEmail || 'email@example.com'}`,
        `subscriber_id=sub_${subscriberId || '00000000-0000-0000-0000-000000000000'}`,
        `test=true`
      ].join('&');
    default:
      return ``
  }
};

interface Props {
  automationId: string;
  webhookDestination: WebhookDestination;
  onChange: (value: WebhookDestination) => void;
}

const ConfigureWebhook = ({ automationId, webhookDestination, onChange }: Props) => {
  const [currentSubscriberId, setCurrentSubscriberId] = useState<string | undefined>(undefined);
  const [currentSubscriberEmail, setCurrentSubscriberEmail] = useState<string | undefined>(undefined);
  const [webhookDestinationTestResponse, setWebhookDestinationTestResponse] = useState<WebhookDestinationTestResponse | undefined>(undefined);
  const canPreviewPayload = webhookDestination.request_method && webhookDestination.content_type;
  const canTestWebhook = canPreviewPayload && webhookDestination.url;

  const { mutateAsync, isLoading } = useTestCreate({
    webhookDestinationId: webhookDestination.id,
    onSuccess: (response) => {
      setWebhookDestinationTestResponse(response);
    },
  });

  const payloadPreview = useMemo(() => {
    if (!webhookDestination) {
      return '';
    }

    return createPayloadPreview(
      webhookDestination.content_type,
      automationId,
      currentSubscriberEmail,
      currentSubscriberId
    );
  }, [webhookDestination, automationId, currentSubscriberEmail, currentSubscriberId]);

  const { root_domain: rootDomain, ...updatedWebhookDestination } = webhookDestination;

  const handleChange = (key: string, value: any) => {
    onChange({ ...updatedWebhookDestination, [key]: value });
  };

  const handleHeaderChange = (index: number, key: string, value: any) => {
    const newHeaders = [...webhookDestination.headers];
    newHeaders[index] = { ...newHeaders[index], [key]: value };
    onChange({ ...updatedWebhookDestination, headers: newHeaders });
  };

  const handleHeaderRemove = (index: number) => {
    const newHeaders = [...webhookDestination.headers];
    newHeaders.splice(index, 1);
    onChange({ ...updatedWebhookDestination, headers: newHeaders });
  };

  const handleHeaderAdd = () => {
    const newHeaders = [...webhookDestination.headers];
    newHeaders.push({ key: '', value: '' });
    onChange({ ...updatedWebhookDestination, headers: newHeaders });
  };

  const handleTest = () => {
    mutateAsync({
      automationId,
      subscriberId: currentSubscriberId,
      webhookDestinationTestParams: {
        url: webhookDestination.url,
        request_method: webhookDestination.request_method,
        content_type: webhookDestination.content_type,
        headers: webhookDestination.headers,
      },
    });
  };

  return (
    <div className="space-y-4">
      <Textarea
        name="description"
        labelText="Description"
        value={webhookDestination.description}
        rows={2}
        onChange={({ target: { value } }: any) => handleChange('description', value)}
      />

      <Input
        name="url"
        labelText="Destination URL"
        value={webhookDestination.url}
        onChange={({ target: { value } }: any) => handleChange('url', value)}
        required
      />

      <SimpleSelect
        name="request_method"
        labelText="Request Method"
        value={webhookDestination.request_method}
        options={REQUEST_METHOD_OPTIONS}
        onSelect={(_name: string, value: WebhookDestinationRequestMethod) => {
          onChange({ ...webhookDestination, request_method: value });
        }}
        required
      />

      <SimpleSelect
        name="content_type"
        labelText="Content Type"
        value={webhookDestination.content_type}
        options={CONTENT_TYPE_OPTIONS}
        onSelect={(_name: string, value: WebhookDestinationContentType) => {
          onChange({ ...webhookDestination, content_type: value });
        }}
        required
      />

      <div className="space-y-1">
        <Label htmlFor="" value="Headers" />
        {webhookDestination.headers.map((header, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="group/item flex items-center gap-x-2">
            <Input
              name={`header-${index}-key`}
              value={header.key}
              className="flex-auto"
              placeholderText="Key"
              onChange={({ target: { value } }: any) => handleHeaderChange(index, 'key', value)}
            />
            <Input
              name={`header-${index}-value`}
              value={header.value}
              className="flex-auto"
              placeholderText="Value"
              onChange={({ target: { value } }: any) => handleHeaderChange(index, 'value', value)}
            />
            <IconButton onClick={() => handleHeaderRemove(index)} className="group/button opacity-0 group-hover/item:opacity-100">
              <TrashIcon className="text-gray-400  group-hover/button:text-gray-800" width="15" height="15" />
            </IconButton>
          </div>
        ))}
        <button
          type="button"
          onClick={handleHeaderAdd}
          className="flex items-center rounded-md hover:bg-gray-100 hover:text-gray-800 text-gray-400 font-semibold text-sm py-1 px-2"
        >
          <PlusIcon width="15" height="15" className="mr-1" />
          Header
        </button>
      </div>


      <div className="py-6 space-y-4">
        <Typography token="font-medium/text/base">Webhook Payload</Typography>
        {canPreviewPayload ? (
          <CodeBlock
            helperText='The data beehiiv will send to your webhook endpoint. The key "test" with a value "true" will only be added when testing.'
            code={payloadPreview}
            canCopy
            shouldOverflow
            codeTypographyToken="font-light/text/xs"
          />
        ) : (
          <EmptyBlock paddingClassName="p-4" heightClassName="">
            Specify the request method and content type to preview the webhook payload.
          </EmptyBlock>
        )}
      </div>


      <div className="py-6 space-y-2">
        <Typography token="font-medium/text/base">Test Webhook</Typography>

        {canTestWebhook ? (
          <div className="space-y-6">
            <SubscriberSelect
              labelText="Simulated Subscriber"
              onSelectSubscriber={(subscriberId: string, subscriberEmail: string) => {
                setCurrentSubscriberId(subscriberId)
                setCurrentSubscriberEmail(subscriberEmail)
              }}
              onClearSubscriber={() => {
                setCurrentSubscriberId('')
                setCurrentSubscriberEmail('')
              }}
            />

            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <Button
                  type="button"
                  onClick={handleTest}
                  color="primary"
                  size="xs"
                  variant="primary"
                  Icon={GlobeAltIcon}
                  loading={isLoading}
                >
                  Test Webhook
                </Button>

                {webhookDestinationTestResponse && (
                  <Badge
                    type={webhookDestinationTestResponse.success ? 'success' : 'alert'}
                    className=""
                  >
                    {webhookDestinationTestResponse.status_code}
                    {' '}
                    {webhookDestinationTestResponse.status_message}
                  </Badge>
                )}
              </div>

              {webhookDestinationTestResponse && (
                <div className="space-y-2">
                  <CodeBlock
                    code={webhookDestinationTestResponse.body}
                    shouldOverflow={false}
                    codeTypographyToken="font-light/text/xs"
                  />
                  <Typography token="font-light/text/xs" colorWeight="700" className="block text-right">
                    {moment(webhookDestinationTestResponse.timestamp).format('LLL')}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        ) : (
          <EmptyBlock paddingClassName="p-4" heightClassName="">
            Specify the destination URL, request method, and content type to test the webhook.
          </EmptyBlock>
        )}
      </div>
    </div>
  );
};

export default ConfigureWebhook;
