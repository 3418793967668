import { FC } from 'react';

import Badge from '@/components/Badge';

interface Props {
  status: string;
}

const badgeTypeFromStatus = (status: string): 'information' | 'success' | 'warning' => {
  if (status === 'pending') {
    return 'information';
  }

  if (status === 'completed') {
    return 'success';
  }

  return 'warning';
};

const StatusBadge: FC<Props> = (props: Props) => {
  const { status } = props;

  return <Badge type={badgeTypeFromStatus(status)}>{status}</Badge>;
};

export default StatusBadge;
