import { useQuery } from 'react-query';

import { useCurrentPublication } from '@/hooks';
import api from '@/services/swarm';

interface ApiResponse {
  offer_expiration_date: string;
  offer_amount: number;
  offer_interval: string;
  tiered_amount: number;
  tiered_interval: string;
  current_amount: number;
  current_interval: string;
}

const useLegacyPlanPriceUpgrade = ({ organizationId }: { organizationId: string }) => {
  const { data: currentPublication } = useCurrentPublication();

  return useQuery<ApiResponse>(
    ['organization', organizationId, 'legacy_plan_price_upgrade'],
    () =>
      api
        .get(`/organizations/${organizationId}/legacy_plan_price_upgrade`, {
          params: {
            publication_id: currentPublication?.id,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!organizationId,
      retry: false,
      staleTime: 60_000, // 1 minute
    }
  );
};

export default useLegacyPlanPriceUpgrade;
