import { useCallback, useEffect, useRef, useState } from 'react';
import { Menu } from '@headlessui/react';
import { TCollabThread } from '@hocuspocus/provider';
import Tippy from '@tippyjs/react';
import { JSONContent } from '@tiptap/core';
import { sticky } from 'tippy.js';

import { CommentEditor } from '@/components/CommentEditor';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import Tooltip from '@/components/TiptapEditor/components/ui/Tooltip';
import { useCurrentUser } from '@/context/current-user-context';
import { useClickOutside } from '@/hooks/useUsers/useClickOutside';
import { useEsc } from '@/hooks/useUsers/useEsc';

import { useEditorContext } from '../EditorContext';

import { Comment } from './components/Comment';

export type ThreadPopoverProps = {
  id: string;
  onClose: () => void;
};

export const ThreadPopover = ({ id, onClose }: ThreadPopoverProps) => {
  const { editor, provider } = useEditorContext();
  const { currentUser } = useCurrentUser();
  const scrollList = useRef<HTMLDivElement>(null);
  const [thread, setThread] = useState<TCollabThread | null>(null);
  const [rect, setRect] = useState<DOMRect>(new DOMRect(0, 0, 0, 0));
  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutside(onClose, wrapperRef);
  useEsc(onClose, wrapperRef);

  const handleDeleteClick = useCallback(() => {
    editor?.commands.removeThread({ id, deleteThread: true });
  }, [id, editor]);

  const handleCommentDelete = useCallback(
    (commentId: string) => {
      editor?.commands.removeComment({ threadId: id, id: commentId });
    },
    [id, editor]
  );

  useEffect(() => {
    const updater = () => {
      if (!provider) return;

      setThread(provider.getThread(id));
    };

    updater();

    provider?.watchThreads(updater);
    return () => {
      provider?.unwatchThreads(updater);
    };
  }, [provider, id]);

  useEffect(() => {
    if (!editor) {
      return () => {};
    }

    const updater = () => {
      let hasPosition = false;
      editor.state.doc.descendants((node, pos) => {
        const inlineThreadMark = node.marks.find((m) => m.type.name === 'inlineThread');
        if (!hasPosition && inlineThreadMark && inlineThreadMark.attrs['data-thread-id'] === id) {
          const coords = editor.view.coordsAtPos(pos);
          const dom = editor.view.domAtPos(pos + 1);
          hasPosition = true;

          if (dom.node.nodeType === 3) {
            const parentRect = dom.node.parentElement?.getBoundingClientRect();
            if (parentRect) {
              setRect(parentRect);
              return;
            }
          }

          setRect(new DOMRect(coords.left, coords.top, 0, 0));
        }

        if (hasPosition) {
          return;
        }

        if (node.type.name === 'blockThread' && node.attrs['data-thread-id'] === id) {
          const dom = editor.view.domAtPos(pos + 1);
          const domNode = dom.node as HTMLElement;
          if (!node) return;

          setRect(domNode.getBoundingClientRect());
          hasPosition = true;
        }
      });

      if (!hasPosition) {
        const docPos = editor.view.coordsAtPos(0);
        setRect(new DOMRect(docPos.left, docPos.top, 0, 0));
      }
    };

    updater();

    window.addEventListener('resize', updater);

    return () => {
      window.removeEventListener('resize', updater);
    };
  }, [id, editor]);

  const addComment = useCallback(
    (content: JSONContent) => {
      if (!editor || !thread || !currentUser) return;

      editor.commands.createComment({ threadId: id, content, data: { authorId: currentUser.id } });
    },
    [id, currentUser, editor, thread]
  );

  const onResolveClick = useCallback(() => {
    if (!editor || !thread) return;
    if (thread.resolvedAt) {
      editor.commands.unresolveThread({ id });
    } else {
      editor.commands.resolveThread({ id });
    }
  }, [editor, thread, id]);

  const onSubmitComment = useCallback(
    ({ json }: { json: JSONContent }) => {
      addComment(json);
      window.setTimeout(() => {
        window.requestAnimationFrame(() => {
          if (scrollList.current) {
            scrollList.current.scrollTop = scrollList.current.scrollHeight + 999999;
          }
        });
      }, 50);
    },
    [addComment]
  );

  const getRect = useCallback(() => rect, [rect]);

  if (!provider || !editor || !thread) {
    return null;
  }

  return (
    <Tippy
      interactive
      appendTo={document.body}
      getReferenceClientRect={getRect}
      visible
      placement="auto"
      sticky
      plugins={[sticky]}
      popperOptions={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: document.body,
              mainAxis: true,
              altAxis: true,
              padding: 4,
            },
          },
          {
            name: 'flip',
            options: {
              allowedAutoPlacements: ['top', 'bottom'],
            },
          },
        ],
      }}
      content={
        <div
          ref={wrapperRef}
          className="w-[20rem] bg-white shadow rounded flex flex-col min-h-[21rem] max-h-[25rem] border border-neutral-200"
        >
          <div className="h-[2.375rem] bg-white border-b border-neutral-200 flex items-center p-1 justify-between flex-none">
            <div className="flex items-center flex-none ml-auto">
              <Tooltip enabled title={thread.resolvedAt ? 'Unresolve' : 'Resolve'}>
                <button
                  className="w-[1.875rem] h-[1.875rem] flex items-center justify-center hover:bg-neutral-50"
                  type="button"
                  onClick={onResolveClick}
                >
                  <Icon name={thread.resolvedAt ? 'Undo' : 'Check'} className="w-3.5 h-3.5" />
                </button>
              </Tooltip>
              {currentUser?.id === thread.data.authorId && (
                <Menu as="div" className="relative">
                  <Menu.Button className="w-[1.875rem] h-[1.875rem] flex items-center justify-center hover:bg-neutral-50">
                    <Icon name="DotsVertical" className="w-3.5 h-3.5" />
                  </Menu.Button>
                  <Menu.Items className="absolute right-0 w-48 p-2 text-white rounded-lg shadow-sm bg-surface-800 top-full">
                    <Menu.Item>
                      <button
                        className="flex items-center w-full gap-2 p-1 text-sm font-medium bg-transparent rounded hover:bg-white hover:bg-opacity-10 text-neutral-200 hover:text-white"
                        type="button"
                        onClick={handleDeleteClick}
                      >
                        <Icon name="Trash" className="w-4 h-4 text-white" />
                        Delete thread
                      </button>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              )}
            </div>
          </div>
          <div ref={scrollList} className="flex flex-col flex-1 p-3 overflow-auto divide-y divide-neutral-200">
            {thread.comments.map((comment, i) => (
              <div className="py-2 first:pt-0 last:pb-0">
                <Comment
                  authorId={comment.data.authorId}
                  content={comment.content}
                  createdAt={comment.createdAt}
                  id={comment.id}
                  onDelete={handleCommentDelete}
                  key={comment.id}
                  isFirst={i === 0}
                />
              </div>
            ))}
          </div>
          <div className="flex-none p-2 border-t border-neutral-200">
            <CommentEditor onSubmit={onSubmitComment} />
          </div>
        </div>
      }
    />
  );
};
