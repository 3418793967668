import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { Typography, TypographyStack } from '@/components/Typography';
import { useStripeApi } from '@/hooks/useBilling';
import { BillingActions } from '@/interfaces/billing';
import { PlanPrice } from '@/interfaces/plan_price';
import { Button } from '@/ui/Button';
import { capitalize } from '@/utils';

interface Props {
  organizationId: string;
  currentPlanPrice: PlanPrice;
  upcomingPlanPrice: PlanPrice;
}

const redirectToBillingHome = () => {
  window.location.href = '/settings/billing';
};

const CancelDowngradeModal: React.FC<Props> = ({ organizationId, currentPlanPrice, upcomingPlanPrice }) => {
  const [isOpen, setIsOpen] = useState(false);

  const cancelDowngrade = useStripeApi({
    organizationId,
    action: BillingActions.CANCEL_DOWNGRADE,
    redirectToBillingHome,
    plan: upcomingPlanPrice.plan_name,
    planInterval: upcomingPlanPrice.interval,
  });

  // "monthly" or "yearly"
  const intervalText = (interval: string) => `${interval}ly`;

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onProceed={() => cancelDowngrade.mutate()}
        resourceId={organizationId}
        isWorking={cancelDowngrade.isLoading}
        headerText="Cancel your downgrade"
        actionText="Proceed"
        buttonType="primary"
      >
        <TypographyStack gap="4">
          <Typography colorWeight="700">
            Click proceed to cancel your downgrade to the{' '}
            <b>
              {intervalText(upcomingPlanPrice.interval)} {capitalize(upcomingPlanPrice.plan_name)}
            </b>{' '}
            plan. You will resume being billed for the{' '}
            <b>
              {intervalText(currentPlanPrice.interval)} {capitalize(currentPlanPrice.plan_name)}
            </b>{' '}
            plan at the end of your current billing period.
          </Typography>
        </TypographyStack>
      </ActionModal>
      <Button type="button" onClick={() => setIsOpen(true)}>
        Cancel Downgrade
      </Button>
    </>
  );
};

export default CancelDowngradeModal;
