import { useMemo } from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/20/solid';

import { AreaChart, ChartCard } from '@/components/Chartsv2';
import { Typography } from '@/components/Typography';
import TypographyRow from '@/components/Typography/TypographyRow';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { currencyFormatter, TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

import { usePremiumDataContext } from '../../_context/premiumDataContext';
import useSubscriberRevenueByPeriod from '../_hooks/useSubscriberRevenueByPeriod';

const RevenueByPeriodChart = () => {
  // Revenue can only be broken down montly at the moment so filter out the unneeded options
  const excludeOptions = [TimePeriod.LAST_24_HOURS, TimePeriod.LAST_4_WEEKS, TimePeriod.LAST_7_DAYS];
  const { period, TimePeriodSelect } = useTimePeriodSelect({ defaultValue: TimePeriod.ALL_TIME, excludeOptions });
  const { data, isLoading, isError } = useSubscriberRevenueByPeriod(period as keyof typeof timePeriodLabels);
  const { currency } = usePremiumDataContext();

  const valueFormatter = (num: number) => currencyFormatter(num, currency, 2, 2);

  const parsedData = useMemo(
    () => (data?.chart_data ? data.chart_data.map((o) => ({ ...o, Revenue: parseFloat(o.Revenue) })) : []),
    [data?.chart_data]
  );

  const totalRevenue = useMemo(() => parsedData.reduce((curr, chartData) => curr + chartData.Revenue, 0), [parsedData]);

  return (
    <ChartCard
      title="Premium Subscriber Revenue"
      description="Shows premium subscribe revenue over time"
      isLoading={isLoading}
      noResultsText="No revenue within the chosen time range."
      noResults={isError || (!isLoading && parsedData.length === 0)}
      actionChildren={<TimePeriodSelect />}
    >
      {data && (
        <>
          <TypographyRow gap="2">
            <CurrencyDollarIcon className="w-6 h-6 text-feedback-success-500" />
            <Typography token="font-bold/text/3xl" color="success" colorWeight="600">
              {valueFormatter(totalRevenue)}
            </Typography>
            <Typography token="font-normal/text/base" colorWeight="700">
              Revenue from premium subscriptions
            </Typography>
          </TypographyRow>

          <AreaChart
            data={parsedData}
            index="name"
            categories={data.categories}
            valueFormatter={valueFormatter}
            colors={['green']}
            yAxisWidth={85}
          />
        </>
      )}
    </ChartCard>
  );
};

export default RevenueByPeriodChart;
