import { useQuery } from 'react-query';

import api from '../../services/swarm';

interface Props {
  organizationId: string | undefined;
}

const useOrganizationPublications = ({ organizationId }: Props) =>
  useQuery(['organization', organizationId, 'publications'], () =>
    api
      .get(`/publications`, {
        params: { organization_id: organizationId },
      })
      .then((res) => res.data)
  );

export default useOrganizationPublications;
