import { useQuery } from 'react-query';

import { useCurrentPublication } from '@/hooks';
import api from '@/services/swarm';

interface ApiResponse {
  amount_due: number;
  new_unit_amount: number;
  new_max_subscriptions: number;
  new_interval: string;
  next_billing_date: string | null;
}

const usePlanPriceUpgrade = ({ organizationId }: { organizationId: string }) => {
  const { data: currentPublication } = useCurrentPublication();

  return useQuery<ApiResponse>(
    ['organization', organizationId, 'plan_price_upgrade'],
    () =>
      api
        .get(`/organizations/${organizationId}/plan_price_upgrade`, {
          params: {
            publication_id: currentPublication?.id,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!organizationId,
      staleTime: 60_000, // 1 minute
    }
  );
};

export default usePlanPriceUpgrade;
