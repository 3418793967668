import Tooltip from '@/components/Tooltip';
import { SwitchForm } from '@/pages/Settings/Components';

export type TurnstilePayload = {
  enable_recaptcha: boolean;
};

interface Props {
  toggleable: boolean;
  enabled: boolean;
  onChange: (payload: TurnstilePayload) => Promise<any>;
}

const DescriptionContent = () => {
  return (
    <div className="space-y-2">
      <p>
        To prevent spam submissions on your website and Embed Forms, you can enable stricter security via Turnstile,
        Cloudflare&apos;s CAPTCHA.
      </p>
      <p>It will be invisible on the page so user experience will not be impacted.</p>
    </div>
  );
};

const TurnstileCaptchaSetting = ({ toggleable, enabled, onChange }: Props) => {
  if (toggleable) {
    return (
      <SwitchForm
        name="recaptcha_enabled"
        label="Stricter Security on Signups"
        description={<DescriptionContent />}
        value={enabled}
        onSave={(val: boolean) => onChange({ enable_recaptcha: val })}
        toastSuccessMessage="Saved!"
      />
    );
  }

  return (
    <Tooltip
      tooltipClass="text-center w-60"
      id="captcha-update-disabled"
      text="For security reasons, this setting is currently disabled until we have been able to properly vet the credibility of this account. Please reach out to support if you would like to expedite this process."
      showIcon={false}
    >
      <div className="opacity-50 pointer-events-none">
        <SwitchForm
          name="recaptcha_enabled"
          label="Enable Stricter Site Security?"
          description={<DescriptionContent />}
          value={enabled}
          onSave={(val: boolean) => onChange({ enable_recaptcha: val })}
          toastSuccessMessage="Saved!"
        />
      </div>
    </Tooltip>
  );
};

export default TurnstileCaptchaSetting;
