import { useCurrentPublicationState } from '../../context/current-publication-context';

import usePublication from './usePublication';

const useCurrentPublication = () => {
  const [publicationId] = useCurrentPublicationState();

  return usePublication(publicationId);
};

export default useCurrentPublication;
