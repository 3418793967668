import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { useEmailMessagePreview } from '../../../hooks';
import { useWelcomeEmailContext } from '../_layout';

const WelcomeEmailOverview = () => {
  const { welcomeEmail } = useWelcomeEmailContext();
  const { data } = useEmailMessagePreview(welcomeEmail?.id);
  const { html } = data || {};

  return (
    <div className="mt-5 mb-12 grid grid-cols-4 gap-4">
      <div className="col-span-3">
        {html ? (
          <iframe
            title="preview"
            className="w-full h-full rounded overflow-hidden"
            srcDoc={html}
            style={{
              minHeight: '750px',
            }}
          />
        ) : (
          <div className="rounded flex items-center justify-center animate-pulse bg-gray-200 h-full">
            <LoadingSpinner />
          </div>
        )}
      </div>
      <div className="col-span-1">
        <div className="grid grid-cols-1 gap-6">
          <div>
            <p className="text-xs uppercase font-semibold tracking-tight text-gray-500">Subject Line</p>
            <p className="text-gray-900 font-semibold capitalize">{welcomeEmail?.subject_line}</p>
          </div>
          <div>
            <p className="text-xs uppercase font-semibold tracking-tight text-gray-500">Preview Text</p>
            <p className="text-gray-900 font-semibold capitalize">{welcomeEmail?.preview_text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeEmailOverview;
