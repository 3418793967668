import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { UpsellPage } from '../../../components/UpsellPage';
import { useSettings } from '../../../context/settings-context';
import useUpgradeIntent from '../../../hooks/useUpgradeIntent';
import { IntentAction } from '../../../interfaces/upgrades';
import { PLAN } from '../../../utils/plans';

const Teaser = () => {
  const { pushDataLayerEvent } = useUpgradeIntent({ intentAction: IntentAction.USE_AUTOMATIONS });
  const { settings } = useSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (settings?.automations) {
      toast.success('Automations are now enabled.');
      navigate('/automations');
    }
  }, [settings, navigate]);

  const count = 1000000;
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  });
  const formattedCount = `${formatter.format(count)}+`;

  return (
    <UpsellPage
      plan={PLAN.SCALE}
      title="Automations"
      description="Unleash the power of automation with bespoke email journey."
      youtubeUrl="https://www.youtube.com/embed/bNAEsXZndbI"
      cards={[
        {
          heading: 'Add Triggers.',
          description:
            'Sign-Up, Upgrade, and more. Triggers are actions taken by subscribers that will initiate the automation journey.',
          imageUrl:
            'https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,width=640,quality=75/www/automations-feature-page/Trigger.png',
        },
        {
          heading: 'Customize Your Automation Flow.',
          description:
            'Design your automation journey using our grid builder, by adding steps, time delays, and conditional branches. Create a tailored experience for your subscribers based on their actions and preferences.',
          imageUrl:
            'https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,width=640,quality=75/www/automations-feature-page/Customize.png',
        },
        {
          heading: 'Craft Your Emails.',
          description:
            'Compose engaging emails for each step in the automation flow. Personalize the content to enhance the subscriber experience and achieve better results.',
          imageUrl:
            'https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,width=1080,quality=75/www/automations-feature-page/Craft.png',
        },
      ]}
      statNumber={formattedCount}
      statDescription="Automations and counting."
      learnMoreUrl="https://www.beehiiv.com/newsletter-solutions/analyze"
      onUpsell={() => {
        pushDataLayerEvent();
      }}
    />
  );
};

export default Teaser;
