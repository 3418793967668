import React, { createContext, useEffect, useState } from 'react';

import api from '../services/swarm';

const CurrentPublicationContext = createContext<[string, (publicationId: string) => void] | undefined>(undefined);

CurrentPublicationContext.displayName = 'CurrentPublicationContext';

const CurrentPublicationProvider = ({
  fallbackPublicationId,
  children,
}: {
  fallbackPublicationId: string;
  children: React.ReactNode;
}) => {
  const defaultToken = localStorage.getItem('currentPublicationId');

  const [currentPublicationId, setCurrentPublicationId] = useState(defaultToken || fallbackPublicationId);

  const handleSetCurrentPublicationId = React.useCallback((id: string) => {
    localStorage.setItem('currentPublicationId', id);

    // So that the Rails side is kept informed
    api
      .post('/update_current_publication', { id })
      .then(() => {
        setCurrentPublicationId(id);
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.error(err);
      });
  }, [setCurrentPublicationId]);

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'currentPublicationId' && e.newValue) {
        setCurrentPublicationId(e.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const contextValue: [string, (publicationId: string) => void] = React.useMemo(() => {
    return [currentPublicationId, handleSetCurrentPublicationId]
  }, [currentPublicationId, handleSetCurrentPublicationId]);

  return (
    <CurrentPublicationContext.Provider value={contextValue}>
      {children}
    </CurrentPublicationContext.Provider>
  );
};

function useCurrentPublicationState() {
  const context = React.useContext(CurrentPublicationContext);
  if (context === undefined) {
    throw new Error(`useCurrentPublicationState must be used within a CurrentPublicationProvider`);
  }
  return context;
}

export { CurrentPublicationContext, CurrentPublicationProvider, useCurrentPublicationState };
