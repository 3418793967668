import { useCallback, useMemo } from 'react';

import Banner from '@/components/Banner';
import { HexEditor } from '@/components/HexEditor';
import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { GlobalStyles } from '@/components/TiptapEditor/lib/GlobalStyles';
import { useSettings } from '@/context/settings-context';
import { NavGroup } from '@/interfaces/nav_group';
import { WebTemplate } from '@/interfaces/web_template';
import { useCustomPageContext } from '@/routes/website_builder/_components/Context/CustomPageContext';

import Layout from '../../_components/Layout';

import CustomPageSettings from './CustomPageSettings';
import TopNavActions from './TopNavActions';

export interface CustomPageData {
  navGroups: NavGroup[] | undefined;
  webTemplate: WebTemplate | undefined;
}

// TODO: we want an easy page to work with that is push-able to production
// without breaking any existing workflows.
// This should be a safe route, we should incrementally make updates here.
const PageV2Layout = () => {
  const { currentPublication, currentPublicationId, changesMade, setChangesMade, content, setContent } =
    useCustomPageContext();

  const { settings } = useSettings();

  const handleUpdate = useCallback(({ editor }: any) => {
    if (!changesMade) {
      setChangesMade(true);
    }
    setContent(editor.getJSON());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialContent = useMemo(() => content, [content]);

  return (
    <Layout
      removePadding
      onPublish={() => {}}
      sidePanelChildren={
        <div className="pt-6 space-y-4">
          <Banner
            title="Under Construction 🚧"
            bodyText="You are looking at an experimental page. Play with it, break it. Report any bugs you found to engineering"
            variant="information"
            isScreenWide={false}
            isCTADisabled
          />
          <CustomPageSettings />
        </div>
      }
      topNavChildren={<TopNavActions />}
    >
      <div className="mx-auto max-w-6xl pt-2 p-8">
        <div className="h-full">
          <div className="flex flex-col w-full space-y-4">
            {/* Editor */}
            <PublicationProvider id={currentPublicationId}>
              <GlobalStyles colors={currentPublication?.color_palette || []} />
              {settings && (
                <HexEditor
                  publicationId={currentPublicationId}
                  settings={settings}
                  onUpdate={handleUpdate}
                  content={initialContent}
                />
              )}
            </PublicationProvider>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PageV2Layout;
