import { useQuery } from 'react-query';

import { DeviceType, PostEmailClickStats } from '../interfaces/post';
import api from '../services/swarm';

interface Arguments {
  id: string;
  isEnabled?: boolean;
  staleTime?: number | undefined;
  filterBy?: 'boosts' | 'ads';
  deviceType?: DeviceType;
}

export default function usePostEmailClickStats({ id, filterBy, isEnabled = true, deviceType, staleTime = 1000 * 60 }: Arguments) {
  return useQuery<PostEmailClickStats>(
    ['posts', id, 'email_click_stats', filterBy, deviceType],
    () =>
      api
        .get(`/posts/${id}/email_click_stats`, {
          params: {
            filter_by: filterBy,
            device_type: deviceType,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
      enabled: isEnabled,
      staleTime,
    }
  );
}
