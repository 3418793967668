import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLongRightIcon,
  BoltIcon,
  ClockIcon,
  CommandLineIcon,
  CursorArrowRaysIcon,
  PaperAirplaneIcon,
  SparklesIcon,
} from '@heroicons/react/20/solid';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

import Card from '@/components/Card';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import useCreateSvixApplication from '@/hooks/webhooks/UseCreateSvixApplication';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

interface GetStartedProps {
  svixConfigured: boolean;
}

const GetStarted = ({ svixConfigured }: GetStartedProps) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();

  const createSvixApplication = useCreateSvixApplication({
    onSuccess: () => {
      toast.success('Webhooks account created successfully');
      setIsCreating(false);
      queryClient.invalidateQueries(['publications', currentPublicationId], { exact: true });
      navigate('/settings/integrations/webhooks/endpoints/new');
    },
    onError: (errPayload) => {
      setIsCreating(false);
      const errors = errPayload?.response?.data?.message || 'Something went wrong';
      toast.error(errors);
    },
  });

  const handleCreateSvixApplication = () => {
    setIsCreating(true);
    analytics.track('Created a Webhook Event');
    createSvixApplication.mutate();
  };

  const actionButton = () => {
    if (!svixConfigured) {
      return (
        <Button
          variant="primary"
          type="button"
          disabled={isCreating}
          loading={isCreating}
          onClick={() => {
            handleCreateSvixApplication();
          }}
        >
          Get Started
        </Button>
      );
    }

    return (
      <Button
        variant="primary"
        type="button"
        onClick={() => {
          navigate('/settings/integrations/webhooks/endpoints/new');
        }}
      >
        Get Started
      </Button>
    );
  };

  return (
    <Card className="p-4 space-y-8 bg-gray-50">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="space-y-6">
          <h3 className="text-xl font-medium">Get events in realtime</h3>
          <p className="text-sm text-gray-500 mt-2">
            Webhooks are a way for us to send you messages when things happen in your account. You can use them to
            trigger actions in other systems.
          </p>
          <div className="space-x-2">
            {actionButton()}
            <Button
              variant="primary-inverse"
              type="button"
              onClick={() => {
                window.open(
                  'https://developers.beehiiv.com/docs/webhooks/84qbkdalj3bo2-overview',
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            >
              View Docs
              <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-2" />
            </Button>
          </div>
        </div>
        <div className="hidden lg:flex items-center px-2 bg-gray-100 rounded-lg justify-between">
          <CursorArrowRaysIcon className="w-12 h-12 text-primary-500" />
          <ArrowLongRightIcon className="w-8 h-8 text-primary-300" />
          <BoltIcon className="w-12 h-12 text-yellow-500" />
          <ArrowLongRightIcon className="w-8 h-8 text-primary-300" />
          <PaperAirplaneIcon className="w-12 h-12 text-primary-500" />
        </div>
      </div>
      <div className="grid grid-cols-3 bg-gray-50">
        <div className="p-2 rounded border border-white space-y-4">
          <ClockIcon className="w-6 h-6 text-primary-500" />
          <p>Get Immediate Updates</p>
        </div>
        <div className="p-2 rounded border border-white space-y-4">
          <SparklesIcon className="w-6 h-6 text-primary-500" />
          <p>Integrate with your favorite tools</p>
        </div>
        <div className="p-2 rounded border border-white space-y-4">
          <CommandLineIcon className="w-6 h-6 text-primary-500" />
          <p>No coding required</p>
        </div>
      </div>
    </Card>
  );
};

export default GetStarted;
