import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from '@/components/Modal';
import Text from '@/components/Text';
import { useSettings } from '@/context/settings-context';
import { useStripeApi, useStripeBillingSession } from '@/hooks/useBilling';
import { BillingActions } from '@/interfaces/billing';
import { PlanPrice } from '@/interfaces/plan_price';
import { Button } from '@/ui/Button';
import { capitalize } from '@/utils';
import { PLAN } from '@/utils/plans';

const redirectToBillingHome = () => {
  window.location.href = '/settings/billing';
};

const DowngradeModal: React.FC<{ organizationId: string; planPrice: PlanPrice }> = ({ organizationId, planPrice }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { settings } = useSettings();

  const stripeDowngradeSession = useStripeBillingSession({
    organizationId,
    action: BillingActions.DOWNGRADE,
  });

  const downgradeToMonthly = useStripeApi({
    organizationId,
    action: BillingActions.DOWNGRADE_TO_MONTHLY,
    redirectToBillingHome,
  });

  const topPlan = settings?.tiered_pricing ? PLAN.MAX : PLAN.SCALE;
  const middlePlan = settings?.tiered_pricing ? PLAN.SCALE : PLAN.GROW;

  if (!planPrice) {
    return null;
  }

  const isAnnualBilling = planPrice.interval === 'year';
  const currentPlan = planPrice.plan_name;

  const onOpen = () => {
    if (settings?.downgrades) {
      if (planPrice.plan_name === middlePlan && planPrice.interval === 'month') {
        return navigate('/settings/billing/downgrade');
      }

      return setIsOpen(true);
    }

    return stripeDowngradeSession.mutate({});
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="p-8 max-w-lg">
          <div className="text-center">
            <Text size="lg" type="bold" className="text-gray-800">
              Downgrade your plan
            </Text>

            <Text size="md" className="text-gray-600 my-4">
              {isAnnualBilling
                ? 'Choose one of the following options to downgrade your plan.'
                : 'Click the following button to downgrade your plan.'}
            </Text>

            <div className="space-x-2 flex justify-center">
              {isAnnualBilling && (
                <Button
                  className="h-10"
                  variant="primary"
                  loading={downgradeToMonthly.isLoading}
                  onClick={() => downgradeToMonthly.mutate()}
                >
                  Change to monthly billing
                </Button>
              )}

              {currentPlan === topPlan && (
                <div>
                  <Button
                    className="h-10"
                    variant={isAnnualBilling ? 'primary-inverse' : 'primary'}
                    loading={stripeDowngradeSession.isLoading}
                    onClick={() => stripeDowngradeSession.mutate({ plan: middlePlan })}
                  >
                    Downgrade to {capitalize(middlePlan)} plan
                  </Button>
                  <Text size="xs" className="text-gray-600 my-2">
                    You will be redirected to Stripe.
                  </Text>
                </div>
              )}
            </div>

            <div className="relative mt-4 mb-4">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-500">Alternative options</span>
              </div>
            </div>

            <Button variant="danger" size="sm" onClick={() => navigate('/settings/billing/downgrade')}>
              Downgrade to free
            </Button>
          </div>
        </div>
      </Modal>
      <Button type="button" variant="danger" onClick={onOpen}>
        Downgrade
      </Button>
    </>
  );
};

export default DowngradeModal;
