import { EyeSlashIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import PostStatusBadge from '@/components/_domain/PostStatusBadge';
import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { Post, PostPlatform } from '@/interfaces/post';
import { Card } from '@/ui/Card';

import PostActions from './PostActions';

interface Props {
  post: Post;
}

const PostHeaderCard = ({ post }: Props) => {
  return (
    // <p className="text-gray-600 max-w-3xl">{post.web_subtitle}</p>
    <Card>
      <div className="flex justify-between">
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-2">
            <Typography token="font-normal/text/xs" className="uppercase" color="info" colorWeight="500">
              {post.platform && post.platform === PostPlatform.BOTH ? 'email and web' : post.platform}
            </Typography>
            {post.hide_from_feed ? (
              <Tooltip
                showIcon={false}
                tooltipClass="w-fit"
                id={`post-${post.id}-hidden-from-feed-tooltip`}
                text="Hidden from feed"
              >
                <EyeSlashIcon className="w-4 h-4" />
              </Tooltip>
            ) : null}
          </div>
          <div className="flex flex-col gap-y-2 sm:items-center sm:flex-row-reverse sm:flex-row sm:gap-x-2">
            <PostStatusBadge status={post.status} />
            <Typography token="font-medium/text/lg">{post.web_title}</Typography>
          </div>
          {post.scheduled_at ? (
            <Typography token="font-normal/text/xs" colorWeight="700">
              {moment(post.scheduled_at).format('MMM Do, YYYY LT')}
            </Typography>
          ) : null}
        </div>
        <PostActions post={post} />
      </div>
    </Card>
  );
};

export default PostHeaderCard;
