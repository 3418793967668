import { useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import { WebTemplate } from '@/interfaces/web_template';
import { Button } from '@/ui/Button';

import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import { ExternalEmbed } from '../../../../../interfaces/external_embed';
import api from '../../../../../services/swarm';
import { Section } from '../../../Components';
import TurnstileCaptchaSetting, { TurnstilePayload } from '../../Website/Advanced/TurnstileCaptchaSetting';

import EditSubscibeForm from './Edit';
import NewSubscribeForm from './New';
import ShowSubscribeForm from './Show';

interface ExternalEmbedsIndexResponse {
  external_embeds: ExternalEmbed[];
}

const SubscribeForms = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [publicationId] = useCurrentPublicationState();
  const [isCreatingNewForm, setIsCreatingNewForm] = useState<boolean>(false);

  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate }: UseQueryResult<WebTemplate, Error> = webTemplateRequest;
  const webTemplateMutation = useUpdateWebTemplate();

  const { data: externalEmbeds } = useQuery<ExternalEmbed[]>(['externalEmbeds', publicationId], () =>
    api
      .get<ExternalEmbedsIndexResponse>(`/publications/${publicationId}/external_embeds`)
      .then((res) => res.data.external_embeds)
  );

  const externalEmbedIdInUrl = searchParams.get('id');
  const shouldViewExternalEmbed = searchParams.get('view') === 'true';
  const editableExternalEmbed =
    externalEmbedIdInUrl && !shouldViewExternalEmbed
      ? externalEmbeds?.find(({ id }) => id === externalEmbedIdInUrl)
      : undefined;

  const openEditForm = (externalEmbedId: string) => {
    searchParams.set('id', externalEmbedId);
    setSearchParams(searchParams);
  };

  const closeEditForm = () => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  const showViewExternalEmbedForm = (externalEmbedId: string) => {
    searchParams.set('id', externalEmbedId);
    searchParams.set('view', 'true');
    setSearchParams(searchParams);
  };

  const closeViewExternalEmbedForm = () => {
    searchParams.delete('id');
    searchParams.delete('view');
    setSearchParams(searchParams);
  };

  return (
    <>
      <EditSubscibeForm
        isOpen={Boolean(externalEmbedIdInUrl)}
        externalEmbed={editableExternalEmbed}
        onClose={closeEditForm}
      />
      <NewSubscribeForm isOpen={isCreatingNewForm} onClose={() => setIsCreatingNewForm(false)} />
      <ShowSubscribeForm
        isOpen={shouldViewExternalEmbed}
        externalEmbedId={externalEmbedIdInUrl}
        onClose={closeViewExternalEmbedForm}
      />
      <Section
        title="Subscribe Forms"
        description="Create customizable and embeddable email subscribe forms for your other websites."
        scrollToId="subscribe_forms"
        hasDivider={false}
        isLoading={!externalEmbeds || !webTemplate}
      >
        <div className="flex justify-between items-center">
          <Button onClick={() => setIsCreatingNewForm(true)}>Create new form</Button>
        </div>
        {externalEmbeds && externalEmbeds.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200 mt-4">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  scope="col"
                >
                  Name
                </th>
                <th className="relative px-6 py-3" scope="col">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {externalEmbeds?.map((externalEmbed) => (
                <tr key={externalEmbed.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                    {externalEmbed.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 text-center mx-auto flex justify-end">
                    <button
                      type="button"
                      onClick={() => showViewExternalEmbedForm(externalEmbed.id)}
                      className="text-indigo-600 hover:text-indigo-900 ml-2"
                    >
                      View
                    </button>
                    <button
                      type="button"
                      onClick={() => openEditForm(externalEmbed.id)}
                      className="text-indigo-600 hover:text-indigo-900 ml-2"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        <TurnstileCaptchaSetting
          toggleable={webTemplate?.recaptcha_disableable || false}
          enabled={webTemplate?.enable_recaptcha || false}
          onChange={(payload: TurnstilePayload) => webTemplateMutation.mutateAsync(payload)}
        />
      </Section>
    </>
  );
};

export default SubscribeForms;
