import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { useCurrentUser } from '../../context/current-user-context';
import api from '../../services/swarm';

type PasswordPayload = {
  old_password: string;
  password: string;
  password_confirmation: string;
};

const useUpdateUserPassword = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (passwordPayload: PasswordPayload) =>
      api.patch(`/users/${currentUser?.id}/password`, {
        publication_id: currentPublicationId,
        user: passwordPayload,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['user', currentUser?.id]);
        toast.success('Password successfully reset!');
        navigate('/');
      },
    }
  );
};

export default useUpdateUserPassword;
