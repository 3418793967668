import useStripeConnectAccount from '@/hooks/boosts/monetize/useStripeConnectAccount';

import { SettingsPageLayout } from '../../Components';

import Connect from './components/Connect';
import ContinueOnboarding from './components/ContinueOnboarding';
import Disconnect from './components/Disconnect';
import GeneralInfo from './components/GeneralInfo';
import Manage from './components/Manage';

const PaymentAccounts = () => {
  const { data: stripeConnectAccount, isLoading: isStripeConnectAccountLoading } = useStripeConnectAccount();
  const { boosts_account_status: boostsAccountStatus, boosts_account_type: boostsAccountType } =
    stripeConnectAccount || {};

  // Each component controls if it should be rendered.
  return (
    <SettingsPageLayout title="Payment Accounts">
      <GeneralInfo
        boostsAccountStatus={boostsAccountStatus}
        boostsAccountType={boostsAccountType}
        isLoading={isStripeConnectAccountLoading}
      />
      <Manage
        boostsAccountStatus={boostsAccountStatus}
        boostsAccountType={boostsAccountType}
        isLoading={isStripeConnectAccountLoading}
      />
      <ContinueOnboarding
        boostsAccountStatus={boostsAccountStatus}
        boostsAccountType={boostsAccountType}
        isLoading={isStripeConnectAccountLoading}
      />
      <Connect
        boostsAccountType={boostsAccountType}
        boostsAccountStatus={boostsAccountStatus}
        isLoading={isStripeConnectAccountLoading}
      />
      <Disconnect boostsAccountType={boostsAccountType} isLoading={isStripeConnectAccountLoading} />
    </SettingsPageLayout>
  );
};

export default PaymentAccounts;
