import { BanknotesIcon, UserMinusIcon } from '@heroicons/react/20/solid';

import LoadingBox from '@/components/LoadingBox';
import useTierChangeEventStatsByResource from '@/hooks/usePremiumData/useTierChangeEventStatsByResource';
import { TierChangeEventResourceType } from '@/interfaces/tier_change_event';

const formatter = new Intl.NumberFormat('en');

interface Props {
  resourceType: TierChangeEventResourceType;
  resourceId: string;
}

const TierChangeStats: React.FC<Props> = ({ resourceType, resourceId }) => {
  const { data, isLoading, isError } = useTierChangeEventStatsByResource({ resourceType, resourceId });

  const { total_upgrades: totalUpgrades = 0, total_downgrades: totalDowngrades = 0 } = data || {};

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <div className="grid gap-8 grid-cols-1 md:grid-cols-3 lg:grid-cols-5 mb-12">
        <div className="flex md:w-auto w-full flex-col justify-between">
          <BanknotesIcon className="h-6 w-6 text-gray-300 mb-4" />
          <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Upgrades</p>
          <p className="leading-none">
            <span className="text-5xl font-black text-violet-500">{formatter.format(totalUpgrades)}</span>
            <span className="text-gray-400 tracking-tighter text-sm"> total</span>
          </p>
        </div>
        <div className="flex md:w-auto w-full flex-col justify-between">
          <UserMinusIcon className="h-6 w-6 text-gray-300 mb-4" />
          <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Downgrades</p>
          <p className="leading-none">
            <span className="text-5xl font-black text-gray-500">{formatter.format(totalDowngrades)}</span>
            <span className="text-gray-400 tracking-tighter text-sm"> total</span>
          </p>
        </div>
      </div>
    </LoadingBox>
  );
};

export default TierChangeStats;
