import { MouseEventHandler, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ImageSelect, Input, Select, Switch, Textarea } from '@/components/Form';
import { APP_LAYOUT_BODY_CONTAINER_ID } from '@/components/Layout/AppLayout/constants';
import RichContent from '@/components/RichContent';
import { Typography } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { usePremiumGifts } from '@/hooks';
import usePremiumGiftOptions from '@/hooks/usePremiumGiftOptions';
import { useCurrentPublication } from '@/hooks/usePublications';
import { TiptapState } from '@/interfaces/editor_versions';
import { Option } from '@/interfaces/general';
import { RewardType } from '@/interfaces/reward';
import { useFormContext } from '@/pages/ReferralProgram/Configure/FormContext';
import { Button } from '@/ui/Button';
import { FormStep } from '@/ui/FormStep';
import { FormStepsWidget } from '@/ui/FormStepsWidget';

import EmailContentBanner from './EmailContentBanner';
import {
  hasFileAttachment,
  hasPromoCodeInEmail,
  insertFileAttachment,
  insertPromoCodeMergeTag,
  removeFileAttachment,
  removeRewardPromoCodeString,
} from './emailContentUtilties';
import { getPrefixedArticleHelperText } from './getPrefixedArticleHelperText';
import PromoCodesForm from './PromoCodesForm';
import SinglePromoCodeForm from './SinglePromoCodeForm';

enum FormSteps {
  SETUP_REWARD = 1,
  SETUP_MILESTONE = 2,
  SETTINGS = 3,
}

const stepTitles = {
  [FormSteps.SETUP_REWARD]: 'Add Reward',
  [FormSteps.SETUP_MILESTONE]: 'Compose Milestone Email',
  [FormSteps.SETTINGS]: 'Additional Settings',
};

const ReferralForm = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();
  const {
    reward,
    setReward,
    emailContent,
    rewardTypeOptions,
    milestone,
    setMilestone,
    rewardImageValue,
    createMilestoneAndReward,
    updateMilestoneAndReward,
    setEmailContent,
    onFetchPreview,
    promoCodesFile,
    isSavingMilestone,
    promoCodes,
  } = useFormContext();

  const { data: premiumGiftsData } = usePremiumGifts({ all: true });
  const premiumGifts = premiumGiftsData?.pages.flatMap((page) => page.premiumGifts) || [];
  const premiumGiftOptions = usePremiumGiftOptions<Option>(premiumGifts, true);

  const isCreating = !reward.id && !milestone.id;

  const [currentStep, setCurrentStep] = useState(FormSteps.SETUP_REWARD);

  const { data: currentPublication } = useCurrentPublication();

  const isSetupRewardStepValid =
    reward.name &&
    reward.reward_type &&
    reward.description &&
    typeof milestone.num_referrals !== 'undefined' &&
    (reward.reward_type === 'premium_gift' ? !!reward.premium_gift_id : true);

  const isSetupMilestoneStepValid = !!milestone.subject_line;
  const isPromoCodeMergeTagMissing = reward.reward_type === 'promo_code' && !hasPromoCodeInEmail(emailContent);

  const submitForm = () => {
    /*
      Due to needing to upload an image
      we have to use multipart form instead of JSON
    */
    const formData = new FormData();
    // Reward
    formData.append('name', reward?.name);
    formData.append('publication_id', currentPublicationId);
    formData.append('prefixed_article', reward.prefixed_article || '');
    formData.append('description', reward.description);
    formData.append('disclaimer', reward.disclaimer || '');
    formData.append('reward_type', reward.reward_type);
    if (reward.reward_type === 'premium_gift') {
      formData.append('premium_gift_id', reward.premium_gift_id || '');
    } else {
      formData.append('premium_gift_id', '');
    }

    if (reward.use_static_promo_code && reward.reward_type === 'promo_code') {
      formData.append('use_static_promo_code', String(reward.use_static_promo_code));
      formData.append('static_promo_code', reward.static_promo_code || '');
    } else {
      formData.append('use_static_promo_code', 'false');
      formData.append('static_promo_code', '');
    }

    // Attach Promo codes csv file
    if (promoCodesFile) {
      formData.append('promo_code_file', promoCodesFile);
    } else if (promoCodes.length > 0) {
      promoCodes.forEach((promoCode, index) => {
        formData.append(`promo_codes_attributes[${index}][code]`, promoCode.code);
      });
    }

    // Only pass image if it's a binary or null.
    // If it's a string, it means the image didn't change
    // and we can leave it alone.
    if (reward?.image instanceof File || reward?.image === null) {
      formData.append('image', reward.image || '');
    }

    // Nested Milestones
    formData.append('milestones_attributes[0][num_referrals]', String(milestone.num_referrals));
    formData.append('milestones_attributes[0][preview_text]', milestone.preview_text);
    formData.append('milestones_attributes[0][subject_line]', milestone.subject_line);
    formData.append('milestones_attributes[0][auto_fulfill]', String(milestone.auto_fulfill));
    formData.append(
      'milestones_attributes[0][delay_achievement_email_until_fulfilled]',
      String(milestone.delay_achievement_email_until_fulfilled)
    );
    formData.append('milestones_attributes[0][tiptap_state]', JSON.stringify(emailContent));
    formData.append('milestones_attributes[0][referral_program_id]', currentPublication?.referral_program_id || '');

    if (isCreating) {
      createMilestoneAndReward(formData, () => {});
      return;
    }

    // Additional attributes required when editing
    formData.append('milestones_attributes[0][id]', milestone.id);
    formData.append('id', reward.id);
    updateMilestoneAndReward(reward.id, formData);
  };

  const progressSteps = [
    {
      label: stepTitles[FormSteps.SETUP_REWARD],
      isCurrent: currentStep === FormSteps.SETUP_REWARD,
      wasCompleted: currentStep > 1,
    },
    {
      label: stepTitles[FormSteps.SETUP_MILESTONE],
      isCurrent: currentStep === FormSteps.SETUP_MILESTONE,
      wasCompleted: currentStep > 2,
    },
    {
      label: stepTitles[FormSteps.SETTINGS],
      isCurrent: currentStep === FormSteps.SETTINGS,
      wasCompleted: currentStep > 3,
    },
  ];

  const handleClickNextStep = () => {
    switch (currentStep) {
      case FormSteps.SETUP_REWARD: {
        setCurrentStep(FormSteps.SETUP_MILESTONE);

        // Based on the Reward type
        // 1. Remove any `{{reward_promo_code}}` merge tags or fileAttachment type blocks
        // 2. Add a merge tag or file attachment block based on the reward type if it doesn't exist
        let newEmailContent = emailContent;

        switch (reward.reward_type) {
          case 'digital':
            newEmailContent = hasPromoCodeInEmail(newEmailContent)
              ? removeRewardPromoCodeString(newEmailContent)
              : newEmailContent;
            newEmailContent = hasFileAttachment(newEmailContent)
              ? newEmailContent
              : { ...insertFileAttachment(newEmailContent) };
            break;
          case 'promo_code':
            newEmailContent = hasFileAttachment(newEmailContent)
              ? removeFileAttachment(newEmailContent)
              : newEmailContent;
            newEmailContent = hasPromoCodeInEmail(newEmailContent)
              ? newEmailContent
              : { ...insertPromoCodeMergeTag(newEmailContent) };
            break;
          default:
            newEmailContent = removeRewardPromoCodeString(removeFileAttachment(newEmailContent))!;
        }

        setEmailContent(newEmailContent);

        break;
      }
      case FormSteps.SETUP_MILESTONE:
        setCurrentStep(FormSteps.SETTINGS);
        break;
      default:
    }

    window?.document?.getElementById(APP_LAYOUT_BODY_CONTAINER_ID)?.scrollTo(0, 0);
  };

  const handleClickPreviousStep = () => {
    switch (currentStep) {
      case FormSteps.SETUP_REWARD:
        break;
      case FormSteps.SETUP_MILESTONE:
        setCurrentStep(FormSteps.SETUP_REWARD);
        break;
      case FormSteps.SETTINGS:
        setCurrentStep(FormSteps.SETUP_MILESTONE);
        break;
      default:
    }

    window?.document?.getElementById(APP_LAYOUT_BODY_CONTAINER_ID)?.scrollTo(0, 0);
  };

  const handleCancel = () => navigate('/referral_program');

  const handleSave: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    submitForm();
  };

  const handleChangeInReward = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value, name },
    } = e;

    setReward({ ...reward, [name]: value });
  };

  const handleFileChange = (file: File) => {
    setReward({ ...reward, image: file });
  };

  const handleFileClear = () => {
    setReward({ ...reward, image: null });
  };

  const handleChangeInRewardType = (_: string, value: RewardType) => {
    setReward({
      ...reward,
      reward_type: value,
    });
  };

  const handleChangeInPremiumGift = (_: string, value: string) => {
    setReward({
      ...reward,
      premium_gift_id: value,
    });
  };

  const handleChangeInMilestone = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value, name },
    } = e;
    setMilestone({ ...milestone, [name]: value });
  };

  const handleChangeInFulfillmentSetting = (name: string, value: boolean) => {
    setMilestone({ ...milestone, [name]: value });
  };

  const handleChangeInUseStaticPromoCodeSetting = (name: string, value: string) => {
    setReward({ ...reward, [name]: value === 'true' });
  };

  const handleContentChange = (value: TiptapState) => {
    setEmailContent(value);
  };

  return (
    <div className="grid gap-6 grid-cols-5">
      <div className="lg:col-span-3 col-span-5">
        <form className="space-y-4">
          <FormStep
            isOpen={currentStep === FormSteps.SETUP_REWARD}
            stepNumber={1}
            stepTitle={stepTitles[FormSteps.SETUP_REWARD]}
            footer={
              <>
                <Button type="button" variant="primary-inverse" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleClickNextStep}
                  disabled={!isSetupRewardStepValid}
                >
                  Continue
                </Button>
              </>
            }
          >
            <Input
              name="name"
              labelText="Reward Name"
              type="text"
              value={reward?.name || ''}
              onChange={handleChangeInReward}
              required
            />
            <Select
              name="reward_type"
              labelText="Reward Type"
              value={reward?.reward_type}
              onSelect={handleChangeInRewardType}
              options={rewardTypeOptions}
              required
            />
            {reward.reward_type === 'premium_gift' ? (
              <Select
                name="premium_gift_id"
                value={reward?.premium_gift_id}
                labelText="Choose Premium Subscription Gift"
                helperText={
                  <Link to="/settings/publication/premium#gift-premium-subscription" className="underline">
                    Manage Premium Subscription Gifts
                  </Link>
                }
                onSelect={handleChangeInPremiumGift}
                options={premiumGiftOptions}
                emptyLabel="No gifts found"
                required
              />
            ) : null}
            <Input
              name="num_referrals"
              labelText="Number of Referrals Needed for Milestone"
              type="number"
              value={milestone?.num_referrals ? String(milestone?.num_referrals) : ''}
              onChange={handleChangeInMilestone}
              required
            />
            <Textarea
              name="description"
              labelText="Description"
              helperText="Type a description"
              value={reward?.description || ''}
              onChange={handleChangeInReward}
              required
            />
            <Input
              name="disclaimer"
              labelText="Disclaimer"
              helperText="Include a disclaimer for your reward"
              type="text"
              value={reward?.disclaimer || ''}
              onChange={handleChangeInReward}
            />
            <Input
              name="prefixed_article"
              labelText="Prefixed Article"
              helperText={getPrefixedArticleHelperText(reward?.name, reward?.prefixed_article)}
              type="text"
              value={reward?.prefixed_article || ''}
              onChange={handleChangeInReward}
            />

            <ImageSelect
              name="image"
              labelText="Image"
              helperText="We recommend a square or landscape image for your reward since it will be used in email and web"
              onFileSelect={handleFileChange}
              onFileClear={handleFileClear}
              file={rewardImageValue}
            />
          </FormStep>

          <FormStep
            isOpen={currentStep === FormSteps.SETUP_MILESTONE}
            stepNumber={2}
            stepTitle={stepTitles[FormSteps.SETUP_MILESTONE]}
            footer={
              <>
                <Button type="button" variant="primary-inverse" onClick={handleClickPreviousStep}>
                  Back
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleClickNextStep}
                  disabled={!isSetupMilestoneStepValid || isPromoCodeMergeTagMissing}
                >
                  Continue
                </Button>
              </>
            }
          >
            <Typography token="font-normal/text/sm" colorWeight="500">
              When a milestone is achieved, the subscriber will receive an email congratulating them and can include any
              additional information about the milestone
            </Typography>
            <Switch
              size="small"
              name="delay_achievement_email_until_fulfilled"
              labelText="Send Email After Review"
              helperText="By default, the achievement email is sent as soon as someone achieves a milestone. Turning this setting on will delay it until it has been fulfilled. This is useful if you don't want to notify a user that they have achieved a milestone until you've had a chance to review their referrals."
              checked={milestone?.delay_achievement_email_until_fulfilled}
              onChange={handleChangeInFulfillmentSetting}
            />
            <Input
              name="subject_line"
              labelText="Subject Line"
              type="text"
              value={milestone?.subject_line || ''}
              onChange={handleChangeInMilestone}
              required
            />
            <Input
              name="preview_text"
              labelText="Preview Text"
              type="text"
              value={milestone?.preview_text || ''}
              onChange={handleChangeInMilestone}
            />
            <EmailContentBanner rewardType={reward.reward_type} />
            <RichContent
              key={reward.reward_type}
              isLoading={false}
              name="milestone-email-content"
              labelText="Content"
              publicationId={currentPublicationId}
              initialValue={emailContent}
              onChange={handleContentChange}
              onFetchPreview={onFetchPreview}
              required
              shouldAutoFocus={false}
            />
          </FormStep>

          <FormStep
            isOpen={currentStep === FormSteps.SETTINGS}
            stepNumber={3}
            stepTitle={stepTitles[FormSteps.SETTINGS]}
            footer={
              <>
                <Button type="button" variant="primary-inverse" onClick={handleClickPreviousStep}>
                  Back
                </Button>
                <Button type="submit" variant="primary" onClick={handleSave} disabled={isSavingMilestone}>
                  {milestone.id ? 'Update' : 'Save'}
                </Button>
              </>
            }
          >
            {reward.reward_type === 'promo_code' && !reward.id && (
              <Select
                name="use_static_promo_code"
                value={String(reward.use_static_promo_code)}
                labelText="Promo Code Type"
                options={[
                  {
                    label: 'Single Code for all fulfillments',
                    value: 'true',
                    description: 'This will assign the same code to all subscribers who achieve this reward',
                  },
                  {
                    label: 'Unique Codes for each fulfillment',
                    value: 'false',
                    description:
                      'This will pull from a list of promo codes and assign a unique code to each subscriber who achieves this reward',
                  },
                ]}
                onSelect={handleChangeInUseStaticPromoCodeSetting}
              />
            )}
            {reward.reward_type === 'promo_code' && !reward.use_static_promo_code ? <PromoCodesForm /> : null}
            {reward.reward_type === 'promo_code' && reward.use_static_promo_code ? <SinglePromoCodeForm /> : null}
            <Switch
              name="auto_fulfill"
              labelText="Auto Fulfill"
              helperText="We will automatically mark any achievements as fulfilled (useful when the email being sent contains the actual reward)"
              checked={milestone?.auto_fulfill}
              onChange={handleChangeInFulfillmentSetting}
            />
          </FormStep>
        </form>
      </div>
      <div className="col-span-5 lg:col-span-2 space-y-4 hidden sm:block">
        <FormStepsWidget steps={progressSteps} />
      </div>
    </div>
  );
};

export default ReferralForm;
