import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Pagination } from '../../interfaces/general';
import { Integration } from '../../interfaces/integration';
import api from '../../services/swarm';

interface ApiResponse {
  integrations: Integration[];
  pagination: Pagination;
}

export default function useIntegrations({ provider }: { provider: string }) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchIntegrations = ({ pageParam = 1 }) =>
    api
      .get(`/integrations`, {
        params: {
          provider,
          page: pageParam,
          per_page: 15,
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['publications', currentPublicationId, 'mailchimp_integrations'],
    fetchIntegrations,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
