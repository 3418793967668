import { useEffect, useState } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import axios from 'axios';

import { useCurrentPublication, useCustomDomains } from '@/hooks';
import { CustomDomain } from '@/interfaces/custom_domain';

async function verifyDns(rootDomain: string, domain: string) {
  try {
    const rootDmarcResult = axios.get(`https://cloudflare-dns.com/dns-query?name=_dmarc.${rootDomain}&type=TXT`, {
      headers: {
        accept: 'application/dns-json',
      },
    });
    const domainDmarcResult = axios.get(`https://cloudflare-dns.com/dns-query?name=_dmarc.${domain}&type=TXT`, {
      headers: {
        accept: 'application/dns-json',
      },
    });

    const [rootDmarcData, domainDmarcData] = await Promise.all([rootDmarcResult, domainDmarcResult]);

    return (
      !!rootDmarcData?.data?.Answer?.find((value: any) => value.data.includes('v=DMARC1')) ||
      !!domainDmarcData?.data?.Answer?.find((value: any) => value.data.includes('v=DMARC1'))
    );
  } catch (e) {
    return true;
  }
}

const ConfirmEmailVerification = () => {
  const { data: customDomains } = useCustomDomains();
  const emailDomain = customDomains?.find((d: CustomDomain) => d.email_enabled);
  const { data: currentPublication } = useCurrentPublication();

  const [showNeedsVerificaiton, setShowNeedsVerification] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);

  useEffect(() => {
    if (emailDomain && currentPublication && refresh) {
      verifyDns(emailDomain.root_domain, emailDomain.domain).then((result) => {
        setShowNeedsVerification(!result);
      });
      setRefresh(false);
    }
  }, [emailDomain, currentPublication, refresh]);

  if (showNeedsVerificaiton) {
    return (
      <div className="w-full mt-4 px-3 py-2.5 bg-yellow-50 border flex flex-row space-x-3 border-yellow-500 rounded">
        <div className="my-auto">
          <ExclamationTriangleIcon height={22} width={22} className="my-auto text-yellow-700" />
        </div>
        <p className="my-auto font-semibold text-sm text-yellow-700">
          Your email sending domain does not have the correct DMARC verification. Please check out the{' '}
          <a
            href="https://support.beehiiv.com/hc/en-us/articles/13650078276375-How-to-set-up-DMARC-authentication"
            className="underline hover:opacity-50 underline-offset-1"
            target="_blank"
            rel="noreferrer"
          >
            DMARC authentication guide
          </a>{' '}
          to ensure your domain has the proper DMARC setup.
        </p>
        <button
          type="button"
          className="w-fit py-1 my-auto bg-yellow-100 border border-yellow-500 text-sm font-semibold text-yellow-700 hover:opacity-80 rounded px-2"
        >
          Refresh
        </button>
      </div>
    );
  }

  return null;
};

export default ConfirmEmailVerification;
