import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { TierChangesGraph, TierChangeStatsByResource } from '@/components/_domain/PremiumData';
import { useCurrentPublication } from '@/hooks';
import { TierChangeEventResourceType } from '@/interfaces/tier_change_event';

import { useWelcomeEmailContext } from '../_layout';

const WelcomeEmailPremiumData = () => {
  const { welcomeEmail } = useWelcomeEmailContext();
  const { data: currentPublication } = useCurrentPublication();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentPublication && !currentPublication.is_premium_enabled) {
      toast.error('You must enable a premium tier');
      navigate(`/welcome_email/analytics`);
    }
  }, [currentPublication, navigate]);

  return (
    <>
      <TierChangeStatsByResource
        resourceType={TierChangeEventResourceType.EMAIL_MESSAGE}
        resourceId={welcomeEmail.id}
      />
      <TierChangesGraph resourceType={TierChangeEventResourceType.EMAIL_MESSAGE} resourceId={welcomeEmail.id} />
    </>
  );
};

export default WelcomeEmailPremiumData;
