import { EmailMessage, EmailMessagePurpose, EmailMessageResourceType } from '../../../../../interfaces/email_message';
import { Input, Textarea } from '../../../../Form';
import SegmentSelect from '../../../../SegmentSelect';

interface ConfigureProps {
  emailMessage: EmailMessage;
  onChange: (params: any) => void;
  showResourceSelect: boolean;
}

const Configure: React.FunctionComponent<ConfigureProps> = ({
  emailMessage,
  onChange,
  showResourceSelect,
}: ConfigureProps) => {
  const isBlast = emailMessage.message_purpose === EmailMessagePurpose.BLAST;

  return (
    <div className="space-y-6 p-4">
      <Input
        name="subject_line"
        value={emailMessage.subject_line || ''}
        labelText="Subject Line"
        onChange={(e) => onChange({ subject_line: e.target.value })}
        required
      />
      <Textarea
        name="preview_text"
        value={emailMessage.preview_text || ''}
        labelText="Preview Text"
        onChange={(e) => onChange({ preview_text: e.target.value })}
        required
      />
      {showResourceSelect && isBlast && (
        <SegmentSelect
          labelText="Segment"
          segmentId={emailMessage.resource_id}
          onSelect={(id) => {
            onChange({
              resource_id: id,
              resource_type: EmailMessageResourceType.SEGMENT,
            });
          }}
          onClear={() => {}}
        />
      )}
    </div>
  );
};

export default Configure;
