import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useTrialStatus = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchTrialStatus = () => api.get(`/publications/${currentPublicationId}/trial_status`).then((res) => res.data);

  return useQuery(['publication-trial-status', currentPublicationId], fetchTrialStatus, {
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(currentPublicationId),
  });
};

export default useTrialStatus;
