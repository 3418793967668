import { forwardRef, useCallback, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Editor } from '@tiptap/core';

import { SimpleSelect } from '@/components/Form';
import Modal from '@/components/Modal';
import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel } from '@/components/TiptapEditor/components/ui/Panel';
import { Tooltip } from '@/components/TiptapEditor/components/ui/Tooltip';
import { useSettings } from '@/context/settings-context';
import { useClickedOutside } from '@/hooks';
import { useOpportunity } from '@/hooks/useAdNetwork/publisher';
import { AdNetworkAdvertisementSponsorText } from '@/interfaces/ad_network/internal/advertisement';

export interface OptionsPanelProps {
  parentRef?: React.RefObject<HTMLDivElement>;
}

type PanelContentProps = {
  onShowDropdown: () => void;
};

const PanelContent = forwardRef<HTMLDivElement, PanelContentProps>(({ onShowDropdown }, ref) => {
  const { settings } = useSettings();
  const hasCustomSponsorTextOnPostEnabled = settings?.custom_sponsor_text_on_post === true;

  const changeSponsorText = useCallback(() => {
    onShowDropdown();
  }, [onShowDropdown]);

  return (
    <Panel ref={ref}>
      {hasCustomSponsorTextOnPostEnabled && (
        <Button
          $size="small"
          $variant="quaternary"
          $fullWidth
          $leftSlot={<Icon name="Undo" />}
          onClick={changeSponsorText}
        >
          Change sponsor text
        </Button>
      )}
    </Panel>
  );
});

export const OptionsPanel = ({
  parentRef,
  editor,
  setSponsorTextContext,
}: {
  parentRef: React.RefObject<HTMLElement>;
  editor: Editor;
  setSponsorTextContext: (sponsorText: string) => void;
}) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const { id: opportunityId, overrideSponsorText } = editor.getAttributes('advertisementOpportunityLogo');
  const { data: opportunity } = useOpportunity({ id: opportunityId });
  const defaultSponsorText = opportunity?.advertisement.sponsor_text;
  const sponsorTextOptions = (
    Object.keys(AdNetworkAdvertisementSponsorText) as Array<keyof typeof AdNetworkAdvertisementSponsorText>
  ).map((key) => {
    const value = AdNetworkAdvertisementSponsorText[key];
    const isDefault = defaultSponsorText === value;

    const label = (value.charAt(0).toUpperCase() + value.slice(1)).replace(/_/g, ' ').replace('Todays', "Today's");

    return {
      value,
      label: `${label}${isDefault ? ' (default value)' : ''}`,
    };
  });

  useClickedOutside([panelRef, buttonRef], () => setPanelOpen(false));

  const togglePanel = useCallback(() => {
    setPanelOpen(!panelOpen);
  }, [panelOpen]);

  const setSponsorText = useCallback(
    (val: string) => {
      editor.commands.updateAttributes('advertisementOpportunityLogo', {
        overrideSponsorText: val !== defaultSponsorText ? val : undefined,
      });
      setSponsorTextContext(val);
    },
    [editor, defaultSponsorText, setSponsorTextContext]
  );

  return (
    <>
      <Modal isOpen={showDropdown} onClose={() => setShowDropdown(false)}>
        <div className="p-6">
          <SimpleSelect
            name="advertisement[sponsor_text]"
            value={overrideSponsorText || defaultSponsorText}
            onSelect={(_, val) => setSponsorText(val as AdNetworkAdvertisementSponsorText)}
            options={sponsorTextOptions}
            labelText="Sponsor Text"
            helperText="Customize the sponsor text shown with this advertisment in this post."
          />
        </div>
      </Modal>
      <Tippy
        render={() => panelOpen && <PanelContent ref={panelRef} onShowDropdown={() => setShowDropdown(true)} />}
        reference={parentRef?.current}
        visible={panelOpen}
        offset={[0, 4]}
        placement="bottom-start"
        interactive
      >
        <div>
          <Tooltip title="Settings">
            <Button
              ref={buttonRef}
              $variant="quaternary"
              $size="small"
              $leftSlot={<Icon name="Settings" />}
              onClick={togglePanel}
              $active={panelOpen}
              $muted={panelOpen}
              $isIconButton
            />
          </Tooltip>
        </div>
      </Tippy>
    </>
  );
};

export default OptionsPanel;
