import { useNavigate } from 'react-router-dom';
import { ArrowPathIcon, BanknotesIcon } from '@heroicons/react/20/solid';

import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import useBoostOffers from '@/hooks/boosts/grow/useBoostOffers';
import { BoostOfferStatus } from '@/interfaces/boosts/grow/boost_offer';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListFilters, useResourceListFilters } from '@/ui/ResourceListFilters';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import BoostOfferListItem from './BoostOfferListItem';

const filterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Draft', value: BoostOfferStatus.DRAFT },
  { label: 'Live', value: BoostOfferStatus.LIVE },
  { label: 'Active', value: BoostOfferStatus.ACTIVE },
  { label: 'Inactive', value: BoostOfferStatus.INACTIVE },
  { label: 'Archived', value: BoostOfferStatus.ARCHIVED },
];

const BoostOffersList = () => {
  const { search, setSearch, filter, setFilter, hasFilter, resetAllFilters } = useResourceListFilters('all');
  const navigate = useNavigate();
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useBoostOffers({
    search,
    status: filter,
    perPage: 5,
  });

  const boostOffers = data?.pages.flatMap((page) => page.boost_offers) || [];
  const hasNoResults = boostOffers.length === 0;
  const totalCount = data?.pages[0]?.pagination?.total || 0;
  const showingCount = boostOffers?.length || 0;

  const handleClickPrimaryAction = () => navigate('/grow/boosts/new');

  const title = hasFilter ? `No results found for current filters` : 'No Offer Created';
  const description = !hasFilter
    ? 'Your Offer is what other publishers will see in the beehiiv Boost Marketplace.'
    : '';
  const primaryActionLabel = hasFilter ? 'Reset Filters' : 'New Offer';
  const onPrimaryActionClick = hasFilter ? resetAllFilters : handleClickPrimaryAction;
  const primaryActionIcon = hasFilter ? ArrowPathIcon : undefined;

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex justify-end w-full">
        <ResourceListFilters
          search={search}
          setSearch={setSearch}
          searchPlaceholder="Search versions"
          filter={filter}
          setFilter={setFilter}
          filterOptions={filterOptions}
        />
      </div>
      <ResourceListSearchText showingCount={showingCount} totalCount={totalCount} />
      {hasNoResults && !isLoading && (
        <EmptyCard
          title={title}
          description={description}
          primaryIcon={BanknotesIcon}
          primaryActionIcon={primaryActionIcon}
          primaryActionLabel={primaryActionLabel}
          onPrimaryActionClick={onPrimaryActionClick}
        />
      )}
      {!hasNoResults && (
        <Items>
          <ItemHeaders>
            <ItemHeader>Cost per acquisition</ItemHeader>
            <ItemHeader>Status</ItemHeader>
            <ItemHeader>Pending subscribers</ItemHeader>
            <ItemHeader>Verified subscribers</ItemHeader>
            <ItemHeader>Total subscribers</ItemHeader>
            <ItemHeader>Pending spend</ItemHeader>
            <ItemHeader>Total spend</ItemHeader>
            <ItemHeader isSticky>&nbsp;</ItemHeader>
          </ItemHeaders>

          <ItemsBody>
            {boostOffers.map((offer) => (
              <BoostOfferListItem key={offer.id} boostOffer={offer} />
            ))}
          </ItemsBody>
        </Items>
      )}
      {hasNextPage && (
        <div className="flex justify-start mt-8 w-fit">
          <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </div>
      )}
    </div>
  );
};

export default BoostOffersList;
