import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { CancelInvite } from '../../interfaces/invites';
import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

const useCancelInvite = (inviteId: string) => {
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (invite: CancelInvite) =>
      api.patch(`/invites/${inviteId}`, {
        publication_id: currentPublication?.id,
        invite,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['invites', currentPublication?.organization_id]);
        toast.success('Invite successfully cancelled!');
      },
    }
  );
};

export default useCancelInvite;
