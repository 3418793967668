import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CurrencyDollarIcon, ExclamationCircleIcon, ShareIcon, UserCircleIcon } from '@heroicons/react/20/solid';

import { SimpleSelect } from '@/components/Form';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import useUpdateCurrentUser from '@/hooks/useUsers/useUpdateUser';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

interface InfoComponentProps {
  title: string;
  Icon: React.ElementType;
  description?: string;
  OptionComponent?: JSX.Element;
}

async function createAffiliate() {
  toast.loading('Creating affiliate link...');
  await api.post('/partner_details');
  window.location.reload();
}

const options = [
  {
    label: 'Agency/Consultant',
    value: 'agency',
  },
  {
    label: 'Review/Comparison Site or Blog',
    value: 'review or comparison site',
  },
  {
    label: 'Software/Tool',
    value: 'software tool',
  },
  {
    label: 'Course Creator',
    value: 'course creator',
  },
  {
    label: 'Social Media Influencer',
    value: 'social media influencer',
  },
  {
    label: 'Affiliate Marketer',
    value: 'affiliate marketer',
  },
  {
    label: 'I just love beehiiv',
    value: 'beehiiv love',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const InfoComponent = ({ title, description, OptionComponent, Icon }: InfoComponentProps) => {
  return (
    <div className="flex flex-row space-x-2 w-full">
      <div className="mb-auto">
        <div className="p-2 rounded-full bg-[#FDF2F8]">
          <Icon className="h-5 w-5 text-[#DB2777]" />
        </div>
      </div>
      <div className="flex flex-col space-y-1.5 w-full">
        <Typography token="font-medium/text/base" className="text-gray-900">
          {title}
        </Typography>
        {description && (
          <Typography token="font-normal/text/sm" className="text-gray-700">
            {description}
          </Typography>
        )}
        <div className="w-full">{OptionComponent || null}</div>
      </div>
    </div>
  );
};

const PartnerProgramAnnouncementModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [hasRun, setHasRun] = useState<boolean>(false);
  const { currentUser } = useCurrentUser();
  const updateUserMutation = useUpdateCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && !hasRun) {
      setOpen(!currentUser?.partner_info);
      setHasRun(true);
    }
  }, [currentUser]);

  const onConfirm = () => {
    if (!currentUser?.partner_info) {
      toast.error('Select the option that best describes you');
      return;
    }
    if (!isChecked) {
      toast.error('Accept the TOS');
      return;
    }
    createAffiliate();
    setOpen(false);
  };

  return (
    <Modal isOpen={open} onClose={() => navigate('/')} className="" includeCloseButton>
      <div className="block max-w-xl">
        <img
          alt="beehiiv partner program"
          className="w-full rounded-t-lg h-32"
          src="https://beehiiv-images-production.s3.amazonaws.com/static_assets/partner+program_modal_illustration.png"
        />
        <div className="p-6 w-full flex flex-col gap-6 max-h-[70vh] sm:max-h-[484px] overflow-scroll">
          <div className="flex flex-col space-y-2">
            <Typography token="font-semibold/text/2xl">Welcome to the beehiiv Partner Program</Typography>
            <Typography token="font-normal/text/sm">
              Join thousands earning monthly passive income as beehiiv partners.
            </Typography>
          </div>
          <InfoComponent
            title="What best describes you?"
            Icon={UserCircleIcon}
            OptionComponent={
              <SimpleSelect
                name="name"
                className="w-full"
                value={currentUser?.partner_info || ''}
                onSelect={(_name, selectedValue) => updateUserMutation.mutateAsync({ partner_info: selectedValue })}
                options={options}
              />
            }
          />
          <InfoComponent
            title="Share"
            description="Instantly access your unique partner link to start sharing today"
            Icon={ShareIcon}
          />
          <InfoComponent
            title="Earn"
            description="50% Commissions for 12 months + become eligible for our quarterly beach club giveaway and other prizes. "
            Icon={CurrencyDollarIcon}
          />
          <InfoComponent
            title="Rules"
            Icon={ExclamationCircleIcon}
            OptionComponent={
              <div className="flex flex-row space-x-1.5">
                <input
                  className="rounded my-auto"
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
                <Typography token="font-normal/text/sm" className="text-gray-700">
                  Accept and agree to our partner{' '}
                  <a
                    href="https://www.beehiiv.partners/c/tos"
                    target="_blank"
                    className="underline"
                    rel="noopener noreferrer"
                  >
                    TOS
                  </a>{' '}
                  to get started.
                </Typography>
              </div>
            }
          />
          <div />
        </div>
        <div className="flex justify-end bg-[#F9FAFB] rounded-b-lg py-4 px-6 mt-auto">
          <Button type="button" variant="primary" onClick={onConfirm}>
            Accept & Join Partner Program
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PartnerProgramAnnouncementModal;
