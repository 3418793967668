import { useCallback, useRef, useState } from 'react';

import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import { useThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelDivider, PanelFooter } from '@/components/TiptapEditor/components/ui/Panel';
import { Asset } from '@/interfaces/asset';
import { Post } from '@/interfaces/post';

import VisibilitySettings from './VisiblitySettings';

type UpdatePostParams = Post & {
  thumbnail_id?: string | null;
  thumbnail?: Asset | null;
};

interface Props {
  updatePost: (data: UpdatePostParams) => void;
  post: Post;
  fileRef: React.RefObject<HTMLInputElement>;
  setShowImageLibrary: (value: boolean) => void;
  showFullMenu?: boolean;
}

const ThumbnailActionsMenu = ({ fileRef, post, setShowImageLibrary, showFullMenu, updatePost }: Props) => {
  const [showVisibilitySettings, setShowVisibilitySettings] = useState(false);
  const panelRef = useRef<HTMLInputElement>(null);
  const { openComposer } = useThreadComposer();

  const handleOpenComposer = () => {
    openComposer();
  };

  const handleFileClick = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showVisibilitySettings) {
    return <VisibilitySettings onBack={() => setShowVisibilitySettings(false)} post={post} updatePost={updatePost} />;
  }

  return (
    <Styled.ContentWrapper>
      <Panel ref={panelRef}>
        {showFullMenu && (
          <>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={handleOpenComposer}
              $fullWidth
              $leftSlot={<Icon name="Comment" />}
              $muted
            >
              Comment
            </Button>
            <PanelDivider />
            <Button
              $variant="quaternary"
              $size="small"
              onClick={() => {
                setShowVisibilitySettings(true);
              }}
              $fullWidth
              $leftSlot={<Icon name="Show" />}
              $rightSlot={<Icon name="ChevronRight" />}
            >
              Visibility
            </Button>
            <PanelDivider />
          </>
        )}
        <Button
          $size="small"
          $variant="quaternary"
          $fullWidth
          $leftSlot={<Icon name="Upload" />}
          onClick={handleFileClick}
        >
          Upload a new image
        </Button>
        <Button
          onClick={() => {
            setShowImageLibrary(true);
          }}
          $size="small"
          $variant="quaternary"
          $fullWidth
          $leftSlot={<Icon name="Library" />}
        >
          Replace from image library
        </Button>
        <PanelFooter>
          <Button
            $variant="quaternary"
            $size="small"
            onClick={() => {
              updatePost({ ...post, ...{ thumbnail_id: 'delete', thumbnail: null } });
            }}
            $fullWidth
            $leftSlot={<Icon name="Trash" />}
            $muted
          >
            Delete
          </Button>
        </PanelFooter>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default ThumbnailActionsMenu;
