import { useCallback } from 'react';

import { Icon } from '../../../TiptapEditor/components/ui/Icon';
import { useMediaLibrary } from '../../context/MediaLibraryContext';

import Styled from './NavigationButton.styles';
import { NavigationButtonProps } from './NavigationButton.types';

const NavigationButton = ({ tabId, libraryId, label, icon = 'Folder' }: NavigationButtonProps) => {
  const { onTabChange, onLibraryChange, openTab, openLibraryId } = useMediaLibrary();

  const isActive = tabId === openTab && (!libraryId || libraryId === openLibraryId);

  const onClick = useCallback(() => {
    onTabChange(tabId);
    if (libraryId && onLibraryChange) {
      onLibraryChange(libraryId);
    }
  }, [onTabChange, onLibraryChange]);

  return (
    <Styled.ButtonWrapper active={isActive} type="button" onClick={onClick}>
      <Styled.ButtonIconWrapper>
        <Icon name={icon} $display="block" />
      </Styled.ButtonIconWrapper>
      <Styled.ButtonText>{label}</Styled.ButtonText>
    </Styled.ButtonWrapper>
  );
};

export default NavigationButton;
