import { FC } from 'react';

import { UpsellPage } from '../../../components/UpsellPage';
import useUpgradeIntent from '../../../hooks/useUpgradeIntent';
import { IntentAction } from '../../../interfaces/upgrades';
import { PLAN } from '../../../utils/plans';

const Teaser: FC = () => {
  const { pushDataLayerEvent } = useUpgradeIntent({ intentAction: IntentAction.USE_REFERRAL_PROGRAM });

  return (
    <UpsellPage
      plan={PLAN.SCALE}
      title="Referral Program"
      description="Turn your readers into a growth engine and unlock the true potential of your subscribers."
      youtubeUrl="https://www.youtube.com/embed/PkAFCc6Z_R8"
      cards={[
        {
          heading: 'Set up and ready to go in just minutes',
          description:
            "No code. No integrations. No third parties. No copy and pasting. Just add your rewards and milestones and you're good to go.",
          imageUrl: 'https://media.beehiiv.net/static_assets/referral_program/setup.png',
        },
        {
          heading: 'Easily customizable, no code needed.',
          description: "Everything will match your newsletter's aesthetic, fully customizable with our no-code editor.",
          imageUrl: 'https://media.beehiiv.net/static_assets/referral_program/customize.png',
        },
        {
          heading: 'Fully integrated in one simple-to-use platform.',
          description: 'Drop the referral section into your newsletter without ever leaving your workflow.',
          imageUrl: 'https://media.beehiiv.net/static_assets/referral_program/integrate.png',
        },
        {
          heading: 'Analytics and reward management.',
          description:
            'All the data is easily accessible in a single platform, and we make fulfillment management simple.',
          imageUrl: 'https://media.beehiiv.net/static_assets/referral_program/analytics.png',
        },
      ]}
      statNumber="17%"
      statDescription="Average increase in monthly growth with our referral program"
      learnMoreUrl="https://www.beehiiv.com/newsletter-solutions/grow"
      onUpsell={() => pushDataLayerEvent()}
    />
  );
};

export default Teaser;
