import { FC, useCallback,useEffect, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import debounce from 'lodash.debounce';

interface Props {
  className?: string;
  name: string;
  value?: number;
  onChange: (name: string, value: number) => void;
  disabled?: boolean;
  increment?: number;
  min?: number;
  max?: number;
  suffix?: string;
  warning?: boolean;
}

const Stepper: FC<Props> = ({
  className,
  name,
  onChange,
  value = 0,
  disabled,
  increment = 1,
  min = 0,
  max,
  suffix,
  warning,
}: Props) => {
  const [internalValue, setInternalValue] = useState<number | string>(value);
  const [inputWidth, setInputWidth] = useState<string>('1ch');
  const inputRef = useRef<HTMLInputElement>(null);
  const inputId = `${name}-input`;

  const updateInputWidth = (valueToSet: number | string) => {
    const {length} = valueToSet.toString();
    setInputWidth(`${Math.max(length, 1) + 1}ch`);
  };

  useEffect(() => {
    setInternalValue(value);
    updateInputWidth(value);
  }, [value]);

  useEffect(() => {
    updateInputWidth(internalValue);
  }, [internalValue]);

  const handleIncrement = () => {
    if (typeof internalValue === 'string') return;
    if (max !== undefined && internalValue + increment > max) return;
    const newValue = internalValue + increment;
    setInternalValue(newValue);
    onChange(name, newValue);
  };

  const handleDecrement = () => {
    if (typeof internalValue === 'string') return;
    if (internalValue - increment < min) return;
    const newValue = internalValue - increment;
    setInternalValue(newValue);
    onChange(name, newValue);
  };

  const validateAndAdjustValue = useCallback(
    debounce((newValue: number) => {
      if (max !== undefined && newValue > max) {
        setInternalValue(max);
        onChange(name, max);
      } else if (newValue < min) {
        setInternalValue(min);
        onChange(name, min);
      } else {
        onChange(name, newValue);
      }
    }, 200),
    [min, max, onChange, name, setInternalValue]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInternalValue(newValue);

    const parsedValue = parseFloat(newValue);
    if (!Number.isNaN(parsedValue)) {
      validateAndAdjustValue(parsedValue);
    }
  };

  return (
    <div className={className}>
      <div
        className={cx(
          'relative mt-1 w-fit flex gap-2 items-center justify-around rounded-md focus:outline-none focus:ring-1',
          'focus:ring-primary-500 focus:border-primary-500',
          'text-left sm:text-sm cursor-default shadow-sm py-1.5 px-3',
          'bg-neutrals-200 border',
          disabled ? 'bg-gray-50 cursor-not-allowed' : '',
          warning ? 'border-feedback-warning-600 hover:border-feedback-warning-300 hover:shadow-warning' : ' border-gray-300',
        )}
      >
        <div>
          <input
            id={inputId}
            ref={inputRef}
            value={internalValue}
            onChange={handleChange}
            disabled={disabled}
            style={{ width: inputWidth }}
            className={cx(
              'form-input min-w-fit border-none focus:border-none focus:ring-0 bg-transparent cursor-pointer',
            )}
            min={min}
            max={max}
          />
        </div>
        {suffix && (
          <span className="text-sm text-gray-500">
            {suffix}
          </span>
        )}
        <div className="flex flex-col items-center justify-center">
          <button
            type="button"
            onClick={handleIncrement}
            disabled={disabled || (typeof internalValue === 'string') || (max !== undefined && parseFloat(internalValue.toString()) + increment > max)}
          >
            <ChevronUpIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
          </button>
          <button
            type="button"
            onClick={handleDecrement}
            disabled={disabled || (typeof internalValue === 'string') || parseFloat(internalValue.toString()) - increment < min}
          >
            <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

Stepper.defaultProps = {
  className: undefined,
  disabled: false,
  increment: 1,
  min: 0,
  max: 100,
  suffix: undefined,
};

export default Stepper;
