import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import IconButton from '@/components/IconHelpers/IconButton';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';

import ActionModal from '../../../components/ActionModal';
import Badge from '../../../components/Badge';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { useDeleteSubscription } from '../../../hooks';
import { Referral } from '../../../interfaces/referral';

interface Props {
  referral: Referral;
  subscriptionMilestoneId?: string;
  onDelete: Function;
}

const badgeTypeFromOptInAt = (optInAt?: Date): 'success' | 'warning' => {
  if (optInAt) return 'success';

  return 'warning';
};

const ReferralTableRow: FC<Props> = (props: Props) => {
  const { referral, subscriptionMilestoneId, onDelete } = props;
  const [deleteRequested, setDeleteRequested] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteSubscriptionMutation = useDeleteSubscription({ subscriptionId: referral.subscriber_id });

  const handleDelete = () => {
    if (!subscriptionMilestoneId) {
      return;
    }

    setIsDeleting(true);
    deleteSubscriptionMutation.mutate(
      {}, // no payload
      {
        onSuccess: () => {
          onDelete();
        },
        onError: (errPayload: any) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        },
        onSettled: () => {
          setIsDeleting(false);
        },
      }
    );
  };

  if (!referral) {
    return null;
  }

  return (
    <>
      {/* Delete Modal */}
      <ActionModal
        isOpen={deleteRequested}
        onClose={() => {
          setDeleteRequested(false);
        }}
        onProceed={handleDelete}
        isWorking={isDeleting}
        resourceId={referral.subscriber_id}
        headerText="Delete Subscriber"
        actionText="Delete"
      >
        <Typography token="font-normal/text/base">
          Are you sure you want to permanently delete this Subscriber? All associated data will be lost.
        </Typography>
      </ActionModal>
      <ItemRow>
        <ItemColumn align={AlignType.TOP}>
          <Link
            type="button"
            className="text-primary-600 hover:text-primary-900"
            to={`/subscribers/${referral.subscriber_id}`}
          >
            <Typography token="font-normal/text/sm">{referral.subscriber_email}</Typography>
          </Link>
        </ItemColumn>
        <ItemColumn align={AlignType.TOP}>
          <Typography token="font-normal/text/sm">{moment(referral.created_at).format('ll')}</Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.TOP}>
          <Badge type={badgeTypeFromOptInAt(referral.opt_in_at)}>{referral.opt_in_at ? 'Confirmed' : 'Pending'}</Badge>
        </ItemColumn>
        <ItemColumn align={AlignType.TOP}>
          {isDeleting ? (
            <LoadingSpinner className="mr-2" />
          ) : (
            <IconButton onClick={() => setDeleteRequested(true)}>
              <TrashIcon />
            </IconButton>
          )}
        </ItemColumn>
      </ItemRow>
    </>
  );
};

export default ReferralTableRow;
