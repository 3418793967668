import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import iro from '@jaames/iro';
import cx from 'classnames';
import styled from 'styled-components';

import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import useWebBuilderOverlay from '@/hooks/useWebBuilderOverlay';
import { Button } from '@/ui/Button';

import { TooltipProps } from '../../_types/misc';

import { InputWrapperProps } from './InputWrapper';
import ResetMenu from './ResetMenu';

interface Props extends Omit<InputWrapperProps, 'children'> {
  label: string;
  tooltip?: TooltipProps;
  className?: string;
  color?: string;
  onSave: (value: any) => void;
  defaultColors?: string[];
  onMakeTransparent?: () => void;
  resettable?: {
    resetValue: any;
    check: boolean;
    label?: string;
  };
}

interface SquareProps {
  color: string;
}

const globalColors: string[] = ['#EF4444', '#FA7315', '#FACC14', '#4BDE80', '#3B82F6', '#6366F1'];

const StyledSquare = styled.div<SquareProps>`
  background-color: ${({ color }) => color};
`;

const GlobalColorComponent = ({ hexColor, selectedHex, setHex }: any) => {
  return (
    <div
      className={cx(
        'w-1/6 aspect-1 p-1 rounded-full',
        selectedHex?.toLowerCase() === hexColor?.toLowerCase() && `border-2 border-[#BFBFBF]`
      )}
    >
      <button
        onClick={() => setHex(hexColor)}
        style={{ backgroundColor: hexColor }}
        className="flex flex-col w-full h-full rounded-full"
        type="button"
      />
    </div>
  );
};

const ColorPopup = ({ setHex, hex, color, defaultColors, onMakeTransparent }: any) => {
  const [tempHex, setTempHex] = useState<string>(hex);
  const pickerObj = useRef<any | null>(null); // Ref type explicitly set to ColorPicker
  const picker = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    setTempHex(hex);
  }, [hex]);

  useEffect(() => {
    if (inputRef.current) {
      // select the text in the input field
      inputRef.current.select();
    }
  }, []);

  useEffect(() => {
    if (picker.current && !pickerObj.current) {
      pickerObj.current = new (iro as any).ColorPicker(picker.current, {
        color: hex,
        width: 232,
        sliderSize: 8,
        boxHeight: 180,
        margin: 16,
        handleRadius: 6,
        layout: [
          {
            component: (iro as any).ui.Box,
            options: {},
          },
          {
            component: (iro as any).ui.Slider,
            options: {
              sliderType: 'hue',
            },
          },
        ],
      });

      pickerObj.current.on('color:change', (newColor: any) => {
        setHex(newColor.hexString.toUpperCase());
      });
    } else {
      pickerObj.current.color.set(hex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hex, color]);

  const onHexColorChange = (e: any) => {
    if (e.key === 'Enter') {
      let inputHex = e.target.value;
      if (/^#?[0-9A-Fa-f]{6}$/.test(inputHex)) {
        // normalize to uppercase with leading #
        inputHex = (inputHex.startsWith('#') ? inputHex : `#${inputHex}`).toUpperCase();
        setHex(inputHex);
      } else {
        // If the input is not a valid hex color, revert to the previous value
        setTempHex(hex);
      }
    } else {
      setTempHex(e.target.value);
    }
  };

  const handleMakeTransparent = () => {
    if (onMakeTransparent) {
      onMakeTransparent();
    }

    setHex('transparent');
  };

  return (
    <div className="flex flex-col space-y-3 bg-white">
      <div ref={picker} />
      <div className="flex space-x-[8px] items-center">
        <div className="flex items-center px-2 border rounded-md border-surface-200 h-7">
          <Typography token="font-normal/text/xs" as="span" colorWeight="900">
            Hex
          </Typography>
        </div>
        <input
          ref={inputRef}
          type="text"
          className="w-full text-xs font-normal border rounded-md border-surface-200 h-7 text-surface-900 hover:bg-surface-100 focus:bg-surface-100 focus:border-surface-400 focus:bg-gray-2 focus:ring-0"
          value={tempHex}
          onChange={(e) => setTempHex(e.target.value)}
          onKeyUp={onHexColorChange}
        />
      </div>
      <div className="flex flex-col space-y-3">
        <div className="flex items-center justify-between space-x-4">
          <Typography token="font-medium/text/xs" color="surface" colorWeight="900" className="pt-2">
            Global Colors
          </Typography>
          {onMakeTransparent && (
            <Button variant="flush" size="xs" type="button" onClick={handleMakeTransparent} className="-mb-2">
              <Typography token="font-light/text/xs" color="surface" colorWeight="500" as="span">
                Transparent
              </Typography>
            </Button>
          )}
        </div>
        <div className="flex flex-row space-x-0.5 w-full">
          {defaultColors.map((hexColor: string) => {
            return <GlobalColorComponent selectedHex={hex} hexColor={hexColor} setHex={setHex} />;
          })}
        </div>
      </div>
    </div>
  );
};

const ColorInput = ({
  label,
  className,
  color,
  onSave,
  defaultColors,
  tooltip,
  onMakeTransparent,
  resettable,
}: Props) => {
  const [initialColor, setInitialColor] = useState<string>('');
  const [hex, setHex] = useState<string>(color || '#000000');
  const [mounted, setMounted] = useState(false);
  const { styles, attributes, setReferenceElement, setPopperElement } = useWebBuilderOverlay({
    align: 'end',
  });

  const dropdownRef = useRef<any>();
  const isTransparent = hex === 'transparent';

  const isResettable = resettable?.check;
  const resettableValue = resettable?.resetValue;
  const resetValueLabel = resettable?.label;

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        onSave(hex);
      }
    },
    [dropdownRef, onSave, hex]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef, handleClickOutside]);

  useEffect(() => {
    if (!mounted && color) {
      setMounted(true);
      setHex(color || '#000000');
      setInitialColor(color || '#000000');
    }
  }, [color, setHex, mounted, setMounted, setInitialColor]);

  useEffect(() => {
    if (mounted && initialColor && color !== initialColor) {
      setHex(color || '#000000');
      setInitialColor(color || '#000000');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, setHex, mounted, setInitialColor]);

  return (
    <div className={cx(className, 'flex justify-between items-center space-x-4')}>
      <div className="flex space-x-1">
        <Typography token="font-normal/text/xs" colorWeight="500">
          {label}
        </Typography>
        {tooltip && <Tooltip id={tooltip.id} text={tooltip.text} iconClass="!text-surface-300" />}
      </div>
      <Popover className="relative">
        <>
          <div className="flex items-center space-x-1">
            {isResettable && (
              <ResetMenu resetValueLabel={resetValueLabel} resettableValue={resettableValue} onSave={onSave} />
            )}
            <Popover.Button
              ref={setReferenceElement}
              className="flex space-x-2 items-center px-2 py-1 rounded-md bg-surface-100 border border-gray-200 w-[95px]"
            >
              {!isTransparent && (
                <div>
                  <StyledSquare className="w-3 h-3" color={hex} />
                </div>
              )}

              <Typography token="font-normal/text/xs" className="truncate">
                {hex}
              </Typography>
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              ref={(node: any) => {
                setPopperElement(node);
                dropdownRef.current = node;
              }}
              style={styles.popper}
              {...attributes.popper}
              className="absolute z-50 p-4 overflow-auto bg-white border border-gray-100 rounded shadow-md top-8 left-100 w-fit"
            >
              <ColorPopup
                hex={hex}
                defaultColors={defaultColors && defaultColors.length ? defaultColors : globalColors}
                setHex={setHex}
                onMakeTransparent={onMakeTransparent}
              />
            </Popover.Panel>
          </Transition>
        </>
      </Popover>
    </div>
  );
};

export default ColorInput;
