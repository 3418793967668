import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { NotificationsModal } from '@/components/Notifications/NotificationsModal';

import UnreadNotfications from './UnreadNotifications';

export const NotificationsDropdown = () => {
  return (
    <Menu as="div" className="relative my-auto h-full justify-center items-center inline-block">
      <Menu.Button className="w-full hover:opacity-90 my-auto h-full">
        <div className="relative flex p-1">
          <BellIcon className="h-5 w-5 text-surface-700" />
          <UnreadNotfications />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cx(
            'z-50 absolute overflow-auto w-[400px] h-[400px] mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none right-0'
          )}
        >
          <NotificationsModal />
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
