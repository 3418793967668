import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { SvgLogo } from '@/components/SvgLogo';
import { useCurrentPublication, useOrganization } from '@/hooks';
import useReport from '@/hooks/useAdNetwork/publisher/useReport';
import { AdNetworkReport } from '@/interfaces/ad_network/publisher/report';
import { Organization } from '@/interfaces/organization';
import { Publication } from '@/interfaces/publication';

interface Props {
  report: AdNetworkReport;
  organization: Organization;
  publication: Publication;
}

const Page = ({ report, organization, publication }: Props) => {
  return (
    <>
      <Helmet>
        <title>
          Remittance Detail for {report.post_web_title} - {moment(report.expected_payment_date).format('MMM D, YYYY')}
        </title>
      </Helmet>
      <div className="flex mb-12 print:hidden">
        <Link className="text-sm text-gray-500 hover:text-primary-500" to="/monetize/ads/reports">
          <div className="flex items-center">
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            <span>Back to all reports</span>
          </div>
        </Link>
      </div>
      <div>
        <div className="grid grid-cols-9 gap-1 mb-10 text-xl">
          <div className="col-span-3 text-left">
            <SvgLogo className="w-20 h-20" stopColorOverride="#000" />
          </div>
          <div className="col-span-3 text-center">REMITTANCE DETAIL</div>
          <div className="col-span-3 text-right text-lg">
            <span className="block">beehiiv</span>
            <span className="block">228 Park Avenue #29976</span>
            <span className="block">New York, NY 10003</span>
            <span className="block">US</span>
          </div>
        </div>
        <div className="grid grid-cols-9 gap-2 border-b-2 border-black pb-10 mb-10 text-xl">
          <div className="col-span-3">
            <div className="block font-bold">PAID TO:</div>
            <div className="block">{organization.name}</div>
            {organization.street_address && <div className="block">{organization.street_address}</div>}
            {(organization.city || organization.state || organization.zip_code) && (
              <div className="block">
                {organization.city}
                {organization.state && `, ${organization.state} `}
                {organization.zip_code}
              </div>
            )}
            {organization.country && <div className="block">{organization.country}</div>}
          </div>
          <div className="col-span-6">
            <span className="block">
              Payment for {publication.name}, Post: {report.post_web_title}
            </span>
            <span className="block">Advertiser: {report.advertiser_name}</span>
            <span className="block">
              Campaign: {report.campaign_name}, sent on {moment(report.window_opened_at).format('MMM D, YYYY')}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-9 gap-2 text-lg">
          <div className="col-span-2">VERIFIED CLICKS</div>
          <div className="col-span-3">COST PER CLICK</div>
          <div className="col-span-4">TOTAL AMOUNT</div>
        </div>
        <div className="border-b-2 border-black pb-10 mb-10">
          <div className="grid grid-cols-9 gap-2">
            <div className="col-span-2">{report.disbursement_approved_clicks}</div>
            <div className="col-span-3">{report.payout_per_click}</div>
            <div className="col-span-4 font-bold">{report.disbursement_approved_amount}</div>
          </div>
        </div>
        <div className="text-center absolute bottom-10 w-full">
          <span className="block">Payment depositied to {organization.name}&apos;s beehiiv wallet.</span>
          <span className="block">
            This was paid in full on {moment(report.expected_payment_date).format('MMM D, YYYY')}
          </span>
        </div>
      </div>
    </>
  );
};

const Loader = () => {
  const { report_id: id } = useParams<'report_id'>() as { report_id: string };
  const { data: report, isSuccess } = useReport({ id });
  const { data: pub, isSuccess: pubSuccess } = useCurrentPublication();
  const { data: org, isSuccess: orgSuccess } = useOrganization(pub?.organization_id);

  if (!isSuccess || !pubSuccess || !orgSuccess) return null;

  return <Page report={report} organization={org.organization} publication={pub} />;
};

export default Loader;
