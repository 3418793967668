import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import styled from 'styled-components';

import OptionsDropdown from '@/components/OptionsDropdown';
import Tooltip from '@/components/Tooltip';
import { Typography, TypographyStack } from '@/components/Typography';
import { Price, PriceInterval, Tier, TierStatus } from '@/interfaces/tier';
import { currencyFormatter } from '@/utils';
import pluralize from '@/utils/pluralize';

import ArchiveTierModal from './ArchiveTierModal';
import DeleteTierModal from './DeleteTierModal';
import UnarchiveTierModal from './UnarchiveTierModal';

interface TierCardWrapperProps {
  centered?: boolean;
}

export const TierCardWrapper = styled.div.attrs<TierCardWrapperProps>(({ centered }) => ({
  className: cx(
    'bg-surface-50 border border-surface-200 min-h-[150px] px-4 py-3 rounded-md',
    centered ? 'flex items-center justify-center' : ''
  ),
}))<TierCardWrapperProps>``;

interface TierCardProps {
  tier: Tier;
  allowUnarchive: boolean;
}

const TierCard: React.FC<TierCardProps> = ({ tier, allowUnarchive }) => {
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showUnarchiveModal, setShowUnarchiveModal] = useState(false);

  const formatPrice = (price: Price) => {
    const hasCents = price.amount_cents % 100 !== 0;
    const formattedPrice = currencyFormatter(
      price.amount_cents / 100.0,
      price.currency,
      hasCents ? 2 : 0,
      hasCents ? 2 : 0
    );

    return `${formattedPrice} ${price.currency.toUpperCase()} / ${price.denominator}`;
  };

  const pricesText = tier.prices
    .filter((price) => price.enabled)
    .sort((price) => {
      if (price.interval === PriceInterval.MONTH) {
        return 1;
      }
      if (price.interval === PriceInterval.YEAR) {
        return 2;
      }

      return 3;
    })
    .map((price) => {
      if (price.interval !== PriceInterval.DONATION) {
        return formatPrice(price);
      }

      return `Customer chooses the price / ${price.denominator}`;
    })
    .join(' • ');

  return (
    <>
      <DeleteTierModal tier={tier} isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)} />
      <ArchiveTierModal tier={tier} isOpen={showArchiveModal} onClose={() => setShowArchiveModal(false)} />
      <UnarchiveTierModal tier={tier} isOpen={showUnarchiveModal} onClose={() => setShowUnarchiveModal(false)} />

      <TierCardWrapper>
        <TypographyStack gap="2">
          <div className="flex items-center justify-between">
            <Typography token="font-medium/text/lg">{tier.name}</Typography>
            <OptionsDropdown
              options={[
                [
                  {
                    label: 'Edit',
                    onClick: () => navigate(`/settings/publication/premium/tiers/${tier.id}`),
                  },
                  ...(tier.status === TierStatus.ACTIVE && !tier.is_default
                    ? [
                        {
                          label: 'Archive',
                          onClick: () => setShowArchiveModal(true),
                        },
                      ]
                    : []),
                  ...(tier.status === TierStatus.ARCHIVED && allowUnarchive
                    ? [
                        {
                          label: 'Unarchive',
                          onClick: () => setShowUnarchiveModal(true),
                        },
                      ]
                    : []),
                  ...(!tier.is_default
                    ? [
                        {
                          label: 'Delete',
                          onClick: () => setShowDeleteModal(true),
                          color: 'red',
                        },
                      ]
                    : []),
                ],
              ]}
              variant="primary"
              className="!bg-transparent !border-none !w-4 !h-4"
            />
          </div>
          <Typography token="font-medium/text/xs" as="div" className="line-clamp-3">
            {pluralize('active subscription', tier.active_subscriptions_count || 0)}
          </Typography>
          <Typography token="font-normal/text/sm">
            <Tooltip
              id={`tier-${tier.id}-prices-tooltip`}
              showIcon={false}
              text={pricesText}
              childrenContainerClass="truncate"
              autoWidth
            >
              {pricesText}
            </Tooltip>
          </Typography>
          <Typography token="font-medium/text/xs" as="div" className="line-clamp-3">
            {tier.description}
          </Typography>
        </TypographyStack>
      </TierCardWrapper>
    </>
  );
};

export default TierCard;
