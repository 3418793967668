import React from 'react';

import { StyledLabel, StyledWrapper } from './styled';
import { ToggleWrapperProps } from './types';

export const ToggleWrapper = ({ switchEl, children, icon = null, isDisabled = false, ...rest }: ToggleWrapperProps) => {
  return (
    <StyledWrapper isDisabled={isDisabled} {...rest}>
      <StyledLabel>
        {icon}
        <span>{children}</span>
      </StyledLabel>
      {switchEl}
    </StyledWrapper>
  );
};

export default ToggleWrapper;
