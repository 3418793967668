/* eslint-disable react/jsx-props-no-spreading */

import { useState } from 'react';
import RcSlider, { SliderProps } from 'rc-slider';
import styled from 'styled-components';

import { subTitleClass } from '../helpers';

import 'rc-slider/assets/index.css';

export const StyledSlider = styled(RcSlider).attrs<SliderProps>({
  className: 'mb-4 mt-2',
  trackStyle: { backgroundColor: '#FF73E3' },
  handleStyle: {
    backgroundColor: '#FF73E3',
    border: 'none',
  },
})``;

interface Props extends SliderProps {
  onChange: (val: number) => void;
  label: string;
  max: number;
  min: number;
  name: string;
  initialValue?: number | string;
  className?: string;
  valueSuffix?: string;
  labelClassName?: string;
}

const Slider = ({
  onChange,
  label,
  initialValue = 0,
  name,
  className,
  max,
  min,
  valueSuffix,
  labelClassName = subTitleClass,
  ...sliderConfig
}: Props) => {
  // convert to number - API sends mix of strings and numbers
  if (typeof initialValue === 'string') {
    // eslint-disable-next-line no-param-reassign
    initialValue = parseInt(initialValue, 10) || 0;
  }

  const constrainedValue = (val: number) => Math.min(Math.max(val, min), max);

  const [currentValue, setCurrentValue] = useState(constrainedValue(initialValue));

  const handleChange = (val: number) => {
    setCurrentValue(val);

    if (onChange) {
      onChange(constrainedValue(val));
    }
  };

  return (
    <div className={className}>
      <div className="flex justify-between">
        <div className="flex items-center space-x-2">
          <h5 className={labelClassName}>
            {label}:&nbsp;{currentValue}
            {valueSuffix}
          </h5>
        </div>
      </div>
      <div className="">
        <StyledSlider min={min} max={max} value={currentValue} onChange={handleChange} {...sliderConfig} />
      </div>
    </div>
  );
};

Slider.defaultProps = {
  className: '',
};

export default Slider;
