import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import {
  ArrowSmallLeftIcon,
  Bars3Icon,
  BuildingOfficeIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import styled from 'styled-components';

import { useSettings } from '@/context/settings-context';

import Text from '../../../Text';

import SettingsLink from './SettingsLink';

const NavWrapper: any = styled.nav`
  @media (max-width: 640px) {
    width: ${({ isOpen }: any) => (isOpen ? '100vw' : '0px')};
    z-index: 9999;
    overflow: ${({ isOpen }: any) => (isOpen ? '' : 'hidden')};
  }
`;

const NavStyled: any = styled.nav`
  width: 230px;
  height: calc(100vh);

  @media (max-width: 640px) {
    width: ${({ isOpen }: any) => (isOpen ? '100vw' : '0px')};
    z-index: 9999;
  }
`;

const MobileDiv = styled.div`
  display: none;

  @media (max-width: 640px) {
    display: block;
  }
`;

interface Props {
  isSettingsOpen: boolean;
  handleCloseSettings: () => void;
  isAdmin: boolean;
}

const SettingsNav = ({ isAdmin, isSettingsOpen, handleCloseSettings }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { settings } = useSettings();

  return (
    <>
      <NavWrapper className="relative" isOpen={isOpen}>
        <Transition.Root show={isSettingsOpen} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-250"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-250"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <NavStyled
              className="border-r border-b border-gray-200 bg-white absolute top-0 left-0 z-10 transition-all duration-200 ease-in-out"
              isOpen={isOpen}
            >
              <div className="flex flex-col space-y-2 p-4">
                <div className="flex space-x-4 items-center">
                  <button
                    className="rounded-sm border border-gray-300 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                    type="button"
                    onClick={handleCloseSettings}
                  >
                    <ArrowSmallLeftIcon className="h-4 w-4" />
                  </button>
                  <Text type="bold" size="sm" className="text-gray-600">
                    Settings
                  </Text>
                </div>
              </div>

              {/* PROFILE SECTION */}
              <div className="px-2 mb-4">
                <div className="text-sm font-medium duration-200 whitespace-nowrap flex items-center space-x-2 w-full cursor-pointer rounded px-2 py-1 justify-between text-gray-500">
                  <div className="flex items-center space-x-2">
                    <div>
                      <UserCircleIcon aria-hidden="true" className="h-4 w-4" />
                    </div>
                    <span className="font-bold">Account</span>
                  </div>
                </div>

                <SettingsLink to="/settings" text="Profile" />
                <SettingsLink to="/settings/notifications" text="Notifications" />
                <SettingsLink to="/settings/password" text="Password" />
              </div>

              {/* ADMIN SECTION */}
              {isAdmin && (
                <div className="px-2">
                  <div className="text-sm font-medium duration-200 whitespace-nowrap flex items-center space-x-2 w-full cursor-pointer rounded px-2 py-1 justify-between text-gray-500">
                    <div className="flex items-center space-x-2">
                      <div>
                        <BuildingOfficeIcon aria-hidden="true" className="h-4 w-4" />
                      </div>
                      <span className="font-bold">Admin</span>
                    </div>
                  </div>
                  <SettingsLink to="/settings/company" text="Company" />
                  <SettingsLink to="/settings/publication" text="Publication" />
                  <SettingsLink to="/post_themes/edit?settings=true" text="Newsletter Builder" />
                  {!settings?.web_builder && <SettingsLink to="/settings/website" text="Website" />}
                  <SettingsLink to="/settings/payment_accounts" text="Payment Accounts" />
                  <SettingsLink to="/settings/integrations" text="Integrations" />
                  <SettingsLink to="/settings/billing" text="Billing" />
                </div>
              )}

              {isOpen && (
                <MobileDiv className="absolute bottom-8 right-8 rounded-full overflow-hidden">
                  <button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    className="rounded-full bg-gray-900 text-white p-2 shadow-xl block md:hidden z-100"
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </MobileDiv>
              )}
            </NavStyled>
          </Transition.Child>
        </Transition.Root>
      </NavWrapper>
      {!isOpen && isSettingsOpen && (
        <MobileDiv className="absolute bottom-8 right-8 rounded-full overflow-hidden">
          <button
            type="button"
            onClick={() => setIsOpen(true)}
            className="rounded-full bg-gray-900 text-white p-2 shadow-xl block md:hidden z-100"
          >
            <Bars3Icon className="h-4 w-4" />
          </button>
        </MobileDiv>
      )}
    </>
  );
};

export default SettingsNav;
