import { Input, Textarea } from '@/components/Form';
import { Typography } from '@/components/Typography';
import Section from '@/routes/settings/_components/Section';

import { useTierConfigurationContext } from '../../_context/tier_configuration_context';
import useTierFormStep from '../../_hooks/useTierFormStep';

const DetailsSection = () => {
  const { tierName, setTierName, previewText, setPreviewText, errors, setErrors } = useTierConfigurationContext();
  const { onFocus, onBlur } = useTierFormStep('details');

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTierName(e.target.value);
    setErrors((prev) => ({ ...prev, name: '' }));
  };

  const handlePreviewTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPreviewText(e.target.value);
    setErrors((prev) => ({ ...prev, name: '' }));
  };

  return (
    <div onFocus={onFocus} onBlur={onBlur}>
      <Section title="Tier Details" description="Customize details about your offering" maxWidthClassName="max-w-4xl">
        <Input
          labelText="Name"
          name="tier_name"
          placeholder="Tier Name"
          className="my-6"
          value={tierName}
          onChange={handleNameChange}
          errorText={errors?.name || ''}
          required
        />
        <Textarea
          labelText="Preview Text"
          name="preview_text"
          placeholderText="Describe the benefits and exclusive content people can expect to get when they pay for this tier."
          rows={6}
          showOptional
          value={previewText || ''}
          onChange={handlePreviewTextChange}
          helperText={
            <Typography token="font-light/text/sm">
              Will be displayed on premium post paywalls and as the description for your upgrade page.
            </Typography>
          }
          errorText={errors?.preview_text || ''}
        />
      </Section>
    </div>
  );
};

export default DetailsSection;
