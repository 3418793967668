import fuzzysort from 'fuzzysort';

import analytics from '@/utils/analytics';

import { Settings } from '../../../../interfaces/setting';
import { IntentAction } from '../../../../interfaces/upgrades';
import { PLAN } from '../../../../utils/plans';
import { EmbedService } from '../../lib/constants';

import { Group } from './types';

const groups: Group[] = [
  {
    name: 'basics',
    title: 'Basics',
    commands: [
      {
        name: 'bulletList',
        label: 'Bulleted List',
        iconName: 'BulletList',
        aliases: ['ul', 'unordered'],
        action: (editor) => {
          editor.chain().focus().toggleBulletList().run();
        },
      },
      {
        name: 'numberedList',
        label: 'Numbered List',
        iconName: 'OrderedList',
        aliases: ['ol', 'ordered'],
        action: (editor) => {
          editor.chain().focus().toggleOrderedList().run();
        },
      },
      {
        name: 'button',
        label: 'Button',
        iconName: 'Button',
        aliases: ['btn'],
        action: (editor) => {
          editor.chain().focus().setButton().run();
        },
      },
      {
        name: 'image',
        label: 'Image',
        iconName: 'Image',
        aliases: ['img'],
        action: (editor) => {
          editor.chain().focus().setImageUpload().run();
        },
      },
      {
        name: 'codeBlock',
        label: 'Code Block',
        iconName: 'CodeBlock',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setCodeBlock().run();
        },
      },
      {
        name: 'table',
        label: 'Table',
        iconName: 'Table',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
        },
      },
      {
        name: 'blockquote',
        label: 'Blockquote',
        iconName: 'Blockquote',
        action: (editor) => {
          editor.chain().focus().setBlockquote().run();
        },
      },
      {
        name: 'contentBreak',
        label: 'Content Break',
        iconName: 'HorizontalRule',
        action: (editor) => {
          editor.chain().focus().setHorizontalRule().run();
        },
      },
      {
        name: 'section',
        label: 'Section',
        iconName: 'Group',
        shouldBeHidden: (editor) => editor.isActive('section') || editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setSection().run();
        },
      },
      {
        name: 'columns',
        label: 'Columns',
        iconName: 'TwoCol',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor
            .chain()
            .focus()
            .setColumns()
            .focus(editor.state.selection.head - 1)
            .run();
        },
      },
      {
        name: 'subscriberBreak',
        label: 'Subscriber Break',
        iconName: 'SubscriberBreak',
        action: (editor) => editor.chain().focus().setSubscriberBreak().run(),
        shouldBeHidden: (editor) => editor.isActive('section') || editor.isActive('columns'),
      },
      {
        name: 'tableOfContents',
        label: 'Table of Contents',
        iconName: 'TableOfContents',
        action: (editor) => editor.chain().focus().setTableOfContents().run(),
        shouldBeHidden: (editor) => editor.isActive('columns'),
      },
      {
        name: 'fileAttachment',
        label: 'File Attachment',
        iconName: 'FileAttachment',
        action: (editor) => editor.chain().focus().setFileAttachment().run(),
      },
    ],
  },
  {
    name: 'headings',
    title: 'Headings',
    commands: [
      {
        name: 'heading1',
        label: 'Heading 1',
        iconName: 'Heading1',
        aliases: ['h1'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 1 }).run();
        },
      },
      {
        name: 'heading2',
        label: 'Heading 2',
        iconName: 'Heading2',
        aliases: ['h2'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 2 }).run();
        },
      },
      {
        name: 'heading3',
        label: 'Heading 3',
        iconName: 'Heading3',
        aliases: ['h3'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 3 }).run();
        },
      },
      {
        name: 'heading4',
        label: 'Heading 4',
        iconName: 'Heading4',
        aliases: ['h4'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 4 }).run();
        },
      },
      {
        name: 'heading5',
        label: 'Heading 5',
        iconName: 'Heading5',
        aliases: ['h5'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 5 }).run();
        },
      },
      {
        name: 'heading6',
        label: 'Heading 6',
        iconName: 'Heading6',
        aliases: ['h6'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 6 }).run();
        },
      },
    ],
  },
  {
    name: 'embeds',
    title: 'Embeds',
    commands: [
      {
        name: 'embedLink',
        label: 'Embed Link',
        iconName: 'Link',
        aliases: ['link', 'embed'],
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          analytics.track('Used Embeds');
          editor.chain().focus().setEmbedInput({ service: undefined }).run();
        },
      },
      {
        name: 'tiktok',
        label: 'Tiktok',
        iconName: 'Tiktok',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setEmbedInput({ service: EmbedService.TIKTOK }).run();
        },
      },
      {
        name: 'instagram',
        label: 'Instagram',
        iconName: 'Instagram',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setEmbedInput({ service: EmbedService.INSTAGRAM }).run();
        },
      },
      {
        name: 'twitter',
        label: 'Twitter',
        iconName: 'Twitter',
        aliases: ['link', 'embed', 'tweet', 'x'],
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setEmbedInput({ service: EmbedService.TWITTER }).run();
        },
      },
      {
        name: 'youtube',
        label: 'YouTube',
        iconName: 'Youtube',
        aliases: ['link', 'embed', 'yt', 'video'],
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setEmbedInput({ service: EmbedService.YOUTUBE }).run();
        },
      },
      {
        name: 'audio',
        label: 'Audio',
        iconName: 'Audio',
        aliases: ['audio'],
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setAudio().run();
        },
      },
    ],
  },
  {
    name: 'dynamic-tags',
    title: 'Dynamic Tags',
    commands: [
      {
        name: 'footnotes',
        label: 'Footnotes',
        iconName: 'Union',
        action: (editor) => {
          editor.chain().focus().insertFootnote().run();
        },
      },
    ],
  },
  {
    name: 'premium',
    title: 'Premium',
    commands: [
      {
        name: 'advertisementOpportunity',
        label: 'Advertisement',
        iconName: 'Banknotes',
        aliases: ['ads'],
        action: (editor) => editor.chain().focus().setAdvertisementOpportunity().run(),
        shouldBeEnabled: (settings: Settings) => !!settings?.ad_network,
        shouldBeHidden: (editor, options) =>
          !options?.allowAds || !options?.settings?.ad_network || editor.isActive('columns'),
        plan: PLAN.SCALE,
        intentAction: IntentAction.USE_AD_NETWORK,
      },
      {
        name: 'aiWriter',
        label: 'AI Writer',
        iconName: 'AIImproveWriting',
        action: (editor) => editor.chain().focus().setAiWriter().run(),
        shouldBeEnabled: (settings: Settings) => (settings?.ai_editor_requests || 0) > 0,
        intentAction: IntentAction.USE_AI_WRITING_TOOLS,
        plan: PLAN.SCALE,
      },
      {
        name: 'aiImage',
        label: 'AI Image',
        iconName: 'AIImage',
        action: (editor) => editor.chain().focus().setAiImage().run(),
        shouldBeEnabled: (settings: Settings) => (settings?.ai_editor_requests || 0) > 0,
        intentAction: IntentAction.USE_AI_WRITING_TOOLS,
        plan: PLAN.SCALE,
      },
      {
        name: 'referralProgram',
        label: 'Referral Program',
        iconName: 'ReferralProgram',
        action: (editor) => editor.chain().focus().setReferralProgram().run(),
        shouldBeEnabled: (settings: Settings) => !!settings?.referral_program,
        shouldBeHidden: (editor) => editor.isActive('columns'),
        intentAction: IntentAction.USE_REFERRAL_PROGRAM,
        plan: PLAN.SCALE,
      },
      {
        name: 'paywallBreak',
        label: 'Paywall Break',
        iconName: 'PaywallBreak',
        action: (editor) => editor.chain().focus().setPaywallBreak().run(),
        shouldBeEnabled: (settings: Settings) => !!settings.premium_subscriptions,
        shouldBeHidden: (editor) => editor.isActive('section') || editor.isActive('columns'),
        intentAction: IntentAction.USE_PREMIUM_SUBSCRIPTIONS,
        plan: PLAN.GROW,
      },
      {
        name: 'poll',
        label: 'Poll',
        iconName: 'Poll',
        action: (editor) => editor.chain().focus().setPoll().run(),
        shouldBeEnabled: (settings: Settings) => !!settings.polls,
        shouldBeHidden: (editor, options) => !options?.allowPolls || editor.isActive('columns'),
        intentAction: IntentAction.USE_POLLS,
        plan: PLAN.GROW,
      },
      {
        name: 'htmlSnippet',
        label: 'HTML Snippet',
        iconName: 'HtmlSnippet',
        action: (editor) => editor.chain().focus().setHtmlSnippet({ language: 'html' }).run(),
        shouldBeEnabled: (settings: Settings) => !!settings.custom_html_blocks,
        shouldBeHidden: (editor) => editor.isActive('columns'),
        intentAction: IntentAction.USE_CUSTOM_HTML,
        plan: PLAN.GROW,
      },
      {
        name: 'recommendation',
        label: 'Recommendation',
        iconName: 'Recommendation',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setRecommendation().run();
        },
      },
      {
        name: 'boost',
        label: 'Boosts',
        iconName: 'Boost',
        shouldBeEnabled: (settings: Settings) => !!settings.boosts_plus,
        // TODO: Unhide when we launch boosts+, instead allow upselling.
        shouldBeHidden: (editor, options) => !options?.settings?.boosts_plus || editor.isActive('columns'),
        intentAction: IntentAction.USE_CUSTOM_HTML,
        plan: PLAN.SCALE,
        action: (editor) => {
          editor.chain().focus().setBoost().run();
        },
      },
    ],
  },
];

export const GROUPS_WITH_COMMANDS = groups.map((group) => {
  const preparedCommands = group.commands.map((item) => {
    const aliases = item.aliases ? item.aliases.join('') : '';

    const preparedLabel = fuzzysort.prepare(item.label);
    const preparedAliases = fuzzysort.prepare(aliases);

    return { ...item, preparedLabel, preparedAliases };
  });

  return { ...group, commands: preparedCommands };
});
