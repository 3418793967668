import { CheckCircleIcon } from '@heroicons/react/20/solid';

import useNotifications from '@/hooks/useNotifications';
import useNotificationsMarkAllAsRead from '@/hooks/useNotificationsMarkAllAsRead';

const MarkAllAsRead = () => {
  const { mutateAsync: markAllAsRead } = useNotificationsMarkAllAsRead();
  const { data } = useNotifications();

  const unreadNotifications = data?.pages[0].meta?.total_unread_count || 0;

  if (unreadNotifications === 0) return null;

  return (
    <button type="button" onClick={() => markAllAsRead()}>
      <CheckCircleIcon className="w-6 h-6 text-gray-300 hover:text-gray-500 duration-200" />
    </button>
  );
};

export default MarkAllAsRead;
