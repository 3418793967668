import { useState } from 'react';
import cx from 'classnames';

import { getBorderRadius, getColor } from '@/components/DesignTokens';
import TabNavigation from '@/components/TabNavigation';
import { Typography } from '@/components/Typography';
import { Tab } from '@/interfaces/general';

import JourneyVisualisation from './JourneyVisualisation';
import PerformanceVisualisation from './PerformanceVisualisation';

interface Props {
  automationId: string;
}

const TimeSeriesMetrics = ({ automationId }: Props) => {
  const [activeTab, setActiveTab] = useState('automationJourney');

  const tabs = [
    {
      name: 'automationJourney',
      label: 'Journey',
      selected: activeTab === 'automationJourney',
      onSelect: () => setActiveTab('automationJourney'),
    },
    {
      name: 'automationPerformance',
      label: 'Performance',
      selected: activeTab === 'automationPerformance',
      onSelect: () => setActiveTab('automationPerformance'),
    },
  ] as Tab[];

  return (
    <div
      className={cx(
        'flex flex-col border p-6 gap-y-2 md:gap-y-0',
        getBorderRadius('border/rounded/large'),
        getColor('surface/200', 'border'),
        getColor('white/900', 'background')
      )}
    >
      <Typography colorWeight="900" weight="bold">
        Automation Overview
      </Typography>
      <TabNavigation tabs={tabs}>
        <div className="mt-6">
          {activeTab === 'automationJourney' ? (
            <JourneyVisualisation automationId={automationId} />
          ) : (
            <PerformanceVisualisation automationId={automationId} />
          )}
        </div>
      </TabNavigation>
    </div>
  );
};

export default TimeSeriesMetrics;
