import { Link } from 'react-router-dom';

import Badge from '@/components/Badge';
import { useUpsellEmail } from '@/hooks';
import Section from '@/routes/settings/_components/Section';
import { Button } from '@/ui/Button';

const UpsellEmailSection: React.FC = () => {
  const { data: upsellEmail } = useUpsellEmail();

  const badgeType = upsellEmail?.active ? 'success' : 'alert';

  return (
    <Section
      title="Upsell Email"
      description="Enable or disable an upsell email that is sent to trialing subscribers without a credit card"
      maxWidthClassName="max-w-4xl"
      titleAction={
        <div className="mb-2">
          {upsellEmail && (
            <Badge className="capitalize" type={badgeType}>
              {upsellEmail.status}
            </Badge>
          )}
        </div>
      }
    >
      <Link to="/upsell_email/analytics">
        <Button type="button" variant="primary-inverse">
          Set Up Upsell Email
        </Button>
      </Link>
    </Section>
  );
};

export default UpsellEmailSection;
