import React, { useEffect, useRef, useState } from 'react';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { XMarkIcon } from '@heroicons/react/20/solid';

import './index.css';

interface Props {
  value?: DateTimePickerProps['value'];
  className?: string;
  onChange?: (date: Date | undefined | null) => void;
  inline?: boolean;
  pickerOptions?: { [key: string]: any };
  displayTimezone?: boolean;
  displayClearButton?: boolean;
  minDate?: Date;
  maxDate?: Date;
  helperText?: string;
  enableTime?: boolean;
  label?: string;
  inputName?: string;
}

const DatePicker: React.FunctionComponent<Props> = (props: Props) => {
  const {
    className,
    value,
    inline,
    onChange,
    pickerOptions,
    displayTimezone,
    displayClearButton,
    minDate,
    maxDate,
    helperText,
    enableTime = true,
    label,
    inputName,
  } = props;

  const [loaded, setLoaded] = useState(false);
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const tzAbbr = Intl.DateTimeFormat('default', { timeZoneName: 'long' })
    ?.formatToParts(new Date())
    .find((part) => part.type === 'timeZoneName')?.value;

  useEffect(() => setLoaded(true), []);

  return (
    <div className={className}>
      {label && inputName && (
        <label htmlFor={inputName} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <div className="datefield relative" ref={containerRef}>
        <div className="flex">
          {loaded && (
            <Flatpickr
              className="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:cursor-not-allowed border-gray-300 opacity-100"
              options={{
                enableTime,
                altInput: true,
                altFormat: enableTime ? 'F j, Y - h:i K' : 'F j, Y',
                appendTo: inline ? containerRef.current : undefined,
                animate: !inline,
                minDate,
                maxDate,
                ...pickerOptions,
              }}
              placeholder="Click to select a date"
              value={value}
              onChange={([date]) => {
                if (onChange) {
                  onChange(date);
                }
              }}
            />
          )}
          {loaded && value && displayClearButton && (
            <button
              name="clear"
              type="button"
              className="absolute right-0 top-0 mt-2 mr-2 z-20"
              onClick={() => {
                if (onChange) {
                  onChange(undefined);
                }
              }}
            >
              <XMarkIcon className="h-5 w-5 text-gray-400 mr-1" aria-hidden="true" />
            </button>
          )}
        </div>
        {((tzAbbr && displayTimezone) || helperText) && (
          <div className="text-xs text-gray-500 flex justify-between mt-2">
            <p>{helperText}</p>
            <p className="ml-4 whitespace-nowrap">{tzAbbr && displayTimezone ? tzAbbr : <>&nbsp;</>}</p>
          </div>
        )}
      </div>
    </div>
  );
};

DatePicker.defaultProps = {
  className: undefined,
  onChange: () => {},
  inline: false,
  pickerOptions: {},
  displayTimezone: true,
};

export default DatePicker;
