import { RefObject, useCallback, useEffect, useState } from 'react';
import { Editor } from '@tiptap/core';

export const useHighlightFocusedThread = (editor: Editor | null, scrollViewRef: RefObject<HTMLDivElement>) => {
  const [lastFocusedThreadId, setLastFocusedThreadId] = useState<string | null>(null);

  const highlightFocusedThreadInSidebar = useCallback(
    ({ editor: e }) => {
      if (!scrollViewRef.current) return;

      const focusedThreads = e?.storage.comments.focusedThreads || [];

      const firstThreadId = focusedThreads[0] as string | undefined;

      if (!firstThreadId?.length || firstThreadId === lastFocusedThreadId) return;

      const thread = scrollViewRef.current.querySelector(`[data-thread-id="${firstThreadId}"]`) as HTMLElement | null;

      if (!thread) return;

      const scrollY = thread.offsetTop - 100;

      scrollViewRef.current.scrollTo({ top: scrollY, behavior: 'smooth' });

      setLastFocusedThreadId(firstThreadId);
    },
    [lastFocusedThreadId, scrollViewRef]
  );

  useEffect(() => {
    if (editor) editor.on('selectionUpdate', highlightFocusedThreadInSidebar);

    return () => {
      if (editor) editor.off('selectionUpdate', highlightFocusedThreadInSidebar);
    };
  }, [editor, highlightFocusedThreadInSidebar]);
};
