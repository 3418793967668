import { useParams } from 'react-router-dom';
import { CheckCircleIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import Badge from '@/components/Badge';
import { useUser } from '@/hooks';
import useAdvertiserPublisherListItem from '@/hooks/useAdNetwork/internal/useAdvertiserPublisherListItem';
import {
  AdNetworkAdvertiserPublisherListItem,
  AdNetworkAdvertiserPublisherListItemReasonOptions,
  AdNetworkAdvertiserPublisherListItemTypeOptions,
} from '@/interfaces/ad_network/internal/advertiser_publisher_list_item';
import { IUser, UserWithRoles } from '@/interfaces/user';
import { DescriptionList } from '@/routes/ad_network/_components/DescriptionList';
import { Button } from '@/ui/Button';

import { PageHeading } from '../../../_components/PageHeading';

interface Props {
  publisherListItem: AdNetworkAdvertiserPublisherListItem;
  addedByUser: IUser | UserWithRoles;
}
const PublisherListItem = ({ publisherListItem, addedByUser }: Props) => {
  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Publisher Preferred/Excluded List</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">{publisherListItem.publisher.name}</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button to="edit" size="xs" variant="primary-inverse" Icon={PencilSquareIcon}>
            Edit
          </Button>
        </PageHeading.Side>
      </PageHeading>
      <div className="divide-y divide-gray-100">
        <DescriptionList>
          <DescriptionList.Item term="ID" copyValue={publisherListItem.id}>
            {publisherListItem.id}
          </DescriptionList.Item>
          <DescriptionList.Item term="Publisher">{publisherListItem.publisher.name}</DescriptionList.Item>
          <DescriptionList.Item term="Entry Type">
            {
              AdNetworkAdvertiserPublisherListItemTypeOptions.find((option) => option.value === publisherListItem.type)
                ?.label
            }
          </DescriptionList.Item>
          <DescriptionList.Item term="Reasons">
            <div className="flow-root">
              <div className="-m-1 flex flex-wrap max-w-md">
                {publisherListItem.reasons.map((reason) => (
                  <div className="p-1" key={reason}>
                    <Badge>
                      {
                        AdNetworkAdvertiserPublisherListItemReasonOptions.find((option) => option.value === reason)
                          ?.label
                      }
                    </Badge>
                  </div>
                ))}
              </div>
            </div>
          </DescriptionList.Item>
          {publisherListItem.expires_on && (
            <DescriptionList.Item term="Expires on">
              <time>{moment(publisherListItem.expires_on).format('ll')}</time>
            </DescriptionList.Item>
          )}
          {publisherListItem.added_by.type === 'Automation' ? (
            <DescriptionList.Item term="Automated entry">
              <CheckCircleIcon className="w-5 h-5 text-green-600" />
            </DescriptionList.Item>
          ) : (
            <DescriptionList.Item term="Created By">
              {addedByUser.first_name} {addedByUser.last_name} ({addedByUser.id})
            </DescriptionList.Item>
          )}
          <DescriptionList.Item term="Created">
            {moment(publisherListItem.created_at).format('lll')}
          </DescriptionList.Item>
          <DescriptionList.Item term="Updated">
            {moment(publisherListItem.updated_at).format('lll')}
          </DescriptionList.Item>
        </DescriptionList>
      </div>
    </>
  );
};

export default function Loader() {
  const { advertiser_id: advertiserId } = useParams<'advertiser_id'>() as { advertiser_id: string };
  const { publisher_list_item_id: publisherListItemId } = useParams<'publisher_list_item_id'>() as {
    publisher_list_item_id: string;
  };
  const { data, isLoading, isError, isSuccess } = useAdvertiserPublisherListItem({ advertiserId, publisherListItemId });
  const { data: user, isLoading: userLoading, isError: userError, isSuccess: userSuccess } = useUser(data?.added_by.id);

  if (!isSuccess || isLoading || isError || userLoading || userError || !userSuccess) return null;

  return <PublisherListItem publisherListItem={data} addedByUser={user} />;
}
