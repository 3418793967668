import { useCallback, useMemo } from 'react';
import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';

import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelHeader } from '@/components/TiptapEditor/components/ui/Panel/styled';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';

import { AddColumnAfterButton, AddColumnBeforeButton, DeleteColumnButton } from '../../../../components/buttons';
import { MenuProps, ShouldShowProps } from '../../../../components/menus/types';
import { Styled } from '../styled';

import { isColumnGripSelected } from './utils';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $fullWidth: true,
};

export const TableColumnMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const shouldShow = useCallback(
    ({ view, state, from }: ShouldShowProps) => {
      if (state) {
        return isColumnGripSelected({ editor, view, state, from: from || 0 });
      }

      return false;
    },
    [editor]
  );

  const { from, $from } = editor.state.selection;

  const isFirstColumn = useMemo(() => {
    if (from === 0) {
      return false;
    }

    if ($from.depth === 0) {
      return false;
    }

    const colPos = $from.before($from.depth);
    let $col = editor.state.doc.resolve(colPos + 1);

    const nodeName = $col.node().type.name;

    $col = nodeName === 'tableCell' || nodeName === 'tableHeader' ? $from : $col;

    if ($col.node().type.name !== 'tableCell' && $col.node().type.name !== 'tableHeader') {
      return false;
    }

    return $col.index($col.depth - 1) === 0;
  }, [from, $from, editor]);

  const isHeaderColumn = useMemo(() => {
    if (from === 0) {
      return false;
    }

    if ($from.depth === 0) {
      return false;
    }

    const cellPos = $from.before($from.depth);
    const $cell = editor.state.doc.resolve(cellPos + 1);

    if ($cell.node().type.name !== 'tableHeader') {
      return false;
    }

    const $tableRow = editor.state.doc.resolve($cell.before($cell.depth));
    const $table = editor.state.doc.resolve($tableRow.before($tableRow.depth));

    // go through each row and check if the first item is also a tableHeader
    let isHeader = true;

    $table.node().descendants((descendant) => {
      if (descendant.type.name !== 'tableRow') {
        return;
      }

      if (descendant.child(0).type.name !== 'tableHeader') {
        isHeader = false;
      }
    });

    return isHeader;
  }, [from, $from, editor]);

  const onToggleHeaderColumn = useCallback(() => {
    return editor.chain().toggleHeaderColumn().run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="tableColumnMenu"
      updateDelay={0}
      tippyOptions={{
        offset: [0, 15],
        popperOptions: {
          modifiers: [{ name: 'flip' }],
        },
        appendTo: () => {
          return appendTo?.current;
        },
      }}
      shouldShow={shouldShow}
    >
      <Panel $width="auto">
        {isFirstColumn && (
          <PanelHeader>
            <ToggleWrapper
              icon={<Icon name="TableColumnHeader" />}
              switchEl={<ToggleSwitch isChecked={isHeaderColumn} onChange={onToggleHeaderColumn} />}
            >
              Set as column head
            </ToggleWrapper>
          </PanelHeader>
        )}
        <Styled.ButtonWrapper>
          {!isFirstColumn || !isHeaderColumn ? (
            <AddColumnBeforeButton
              editor={editor}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...buttonProps}
            />
          ) : null}
          <AddColumnAfterButton
            editor={editor}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
          />
          <DeleteColumnButton
            editor={editor}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
          />
        </Styled.ButtonWrapper>
      </Panel>
    </BaseBubbleMenu>
  );
};

export default TableColumnMenu;
