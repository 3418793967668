import { useQuery } from 'react-query';

import { PostStats } from '../interfaces/post';
import api from '../services/swarm';

interface Arguments {
  id: string;
  isEnabled?: boolean;
}

export default function usePostStats({ id, isEnabled = true }: Arguments) {
  return useQuery<PostStats>(['posts', id, 'stats'], () => api.get(`/posts/${id}/stats`).then((res) => res.data), {
    staleTime: 30000,
    enabled: isEnabled,
  });
}
