import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';

import SelectNodeTypeButton from '../components/SelectNodeTypeButton';
import { AUTOMATION_NODE_WIDTH_CLASSNAME } from '../constants';
import useMaxAllowedSteps from '../hooks/useMaxAllowedSteps';
import { CustomNodeDataType } from '../types';

const NewAddAutomationStepNode = ({ data, targetPosition }: NodeProps<CustomNodeDataType>) => {
  const { getNode } = useReactFlow();
  const hasMaxSteps = useMaxAllowedSteps();
  const shouldShowAddButton = !hasMaxSteps;
  const { parentId, branchArm, branchId } = data;
  const sourceNode = (parentId && getNode(parentId)) || (branchId && getNode(branchId)) || undefined;

  return (
    <div className={AUTOMATION_NODE_WIDTH_CLASSNAME}>
      <Handle className="opacity-0" type="target" position={targetPosition || Position.Top} />
      {shouldShowAddButton && <SelectNodeTypeButton sourceNode={sourceNode} branchArm={branchArm} />}
    </div>
  );
};

export default NewAddAutomationStepNode;
