import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { JSONContent } from '@tiptap/core';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import { TiptapState } from '@/interfaces/editor_versions';
import { IData, Option } from '@/interfaces/general';
import { Image } from '@/interfaces/image';
import { CreateMilestonePayload, Milestone, UpdateMilestonePayload } from '@/interfaces/milestone';
import { Reward, RewardPromoCode } from '@/interfaces/reward';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';
import RewardTypeLabels from '@/values/rewardType';

const rewardTypeOptions = (isPremiumEnabled: boolean, isPremiumGiftRewardEnabled: boolean) => {
  const defaultOptions: Option[] = [
    {
      label: RewardTypeLabels.physical,
      value: 'physical',
      description: 'Used when sending a physical product to someone',
    },
    {
      label: RewardTypeLabels.promo_code,
      value: 'promo_code',
      description: 'Used when including a discount or trial code for someone to redeem',
    },
    {
      label: RewardTypeLabels.digital,
      value: 'digital',
      description: 'Used when sending a file, URL, or digital access to something',
    },
  ];

  if (!isPremiumEnabled || !isPremiumGiftRewardEnabled) {
    return defaultOptions;
  }

  return [
    ...defaultOptions,
    {
      label: RewardTypeLabels.premium_gift,
      value: 'premium_gift',
      description: 'Used when gifting a Premium Subscription',
    },
  ];
};

const DEFAULT_REWARD: Reward = {
  id: '',
  name: '',
  description: '',
  disclaimer: '',
  image: null,
  prefixed_article: '',
  reward_type: 'physical',
  static_promo_code: '',
  use_static_promo_code: true,
};

const DEFAULT_MILESTONE: Omit<Milestone, 'reward'> = {
  id: '',
  tiptap_state: {},
  reward_id: '',
  num_referrals: 0,
  subject_line: '',
  preview_text: '',
  auto_fulfill: false,
  delay_achievement_email_until_fulfilled: false,
};

interface ContextProps {
  isLoadingReward: boolean;
  isSavingReward: boolean;
  reward: Reward;
  rewardImageValue: any;
  rewardTypeOptions: Option[];
  setReward: (reward: Reward) => void;
  resetReward: () => void;
  createReward: (payload: FormData, onSuccess: (id: string) => void) => void;
  updateReward: (id: string, payload: FormData, onSuccess: (id: string) => void) => void;
  isSavingMilestone: boolean;
  isLoadingMilestone: boolean;
  isErrorMilestone: boolean;
  showManagePromoCodes: boolean;
  setShowManagePromoCodes: (value: boolean) => void;
  milestone: Omit<Milestone, 'reward'>;
  emailContent: JSONContent;
  setEmailContent: (tipTapState: TiptapState) => void;
  rewardOptions: Option[];
  rewardsWithTypes: IData;
  createMilestone: (payload: CreateMilestonePayload) => void;
  createMilestoneAndReward: (payload: FormData, onSuccess: (id: string) => void) => void;
  updateMilestone: (payload: UpdateMilestonePayload) => void;
  updateMilestoneAndReward: (id: string, payload: FormData) => void;
  setMilestone: (milestone: Omit<Milestone, 'reward'>) => void;
  fetchAndSetRewardOptions: (publicationId: string) => Promise<void>;
  onFetchPreview: (platform: string, onFetch: Function) => void;
  promoCodesFile: File | null;
  setPromoCodesFile: (file: File | null) => void;
  promoCodes: Pick<RewardPromoCode, 'id' | 'code'>[];
  setPromoCodes: (value: Pick<RewardPromoCode, 'id' | 'code'>[]) => void;
}

const FormContext = createContext<ContextProps>({
  isLoadingReward: false,
  isSavingReward: false,
  reward: DEFAULT_REWARD,
  rewardImageValue: undefined,
  setReward: () => {},
  resetReward: () => {},
  rewardTypeOptions: [],
  createReward: () => {},
  updateReward: () => {},
  isSavingMilestone: false,
  isLoadingMilestone: false,
  isErrorMilestone: false,
  showManagePromoCodes: false,
  setShowManagePromoCodes: () => {},
  milestone: DEFAULT_MILESTONE,
  emailContent: {},
  setEmailContent: () => {},
  rewardOptions: [],
  rewardsWithTypes: {},
  createMilestone: () => {},
  updateMilestone: () => {},
  createMilestoneAndReward: () => {},
  updateMilestoneAndReward: () => {},
  setMilestone: () => {},
  fetchAndSetRewardOptions: async () => {},
  onFetchPreview: () => {},
  promoCodesFile: null,
  setPromoCodesFile: () => {},
  promoCodes: [],
  setPromoCodes: () => {},
});
FormContext.displayName = 'FormContext';

interface Props {
  children: React.ReactNode;
  rewardId?: string;
  milestoneId?: string;
}

const fetchReward = (publicationId: string, rewardId: string): Promise<Reward> => {
  const params = {
    publication_id: publicationId,
  };
  return api.get(`/referral_program/rewards/${rewardId}`, { params }).then((res) => res.data);
};

const fetchRewardOptions = async (publicationId: string) => {
  const params = {
    publication_id: publicationId,
  };

  return api.get('/options/rewards', { params });
};

function fetchMilestone(publicationId: string, milestoneId: string, onError: () => any): Promise<Milestone> {
  const params = {
    publication_id: publicationId,
  };
  return api
    .get(`/referral_program/milestones/${milestoneId}`, { params })
    .then((res) => res.data)
    .catch(onError);
}

export const FormContextProvider = ({ children, rewardId, milestoneId }: Props) => {
  const { settings } = useSettings();
  const [publicationId] = useCurrentPublicationState();
  const { data: currentPublication } = useCurrentPublication();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* Reward */
  const [rewardInState, setRewardInState] = useState<Reward>({
    ...DEFAULT_REWARD,
  });
  const [isLoadingReward, setIsLoadingReward] = useState(false);
  const [isSavingReward, setIsSavingReward] = useState(false);
  /* Reward */

  /* Milestone */
  const [isSavingMilestone, setIsSavingMilestone] = useState(false);
  const [isLoadingMilestone, setIsLoadingMilestone] = useState(false);
  const [isErrorMilestone, setIsErrorMilestone] = useState(false);

  const [showManagePromoCodes, setShowManagePromoCodes] = useState(false);
  const [milestone, setMilestone] = useState<Omit<Milestone, 'reward'>>({
    id: '',
    tiptap_state: {},
    reward_id: '',
    num_referrals: 0,
    subject_line: '',
    preview_text: '',
    auto_fulfill: false,
    delay_achievement_email_until_fulfilled: false,
  });
  const [emailContent, setEmailContent] = useState<JSONContent>({});

  const [rewardOptions, setRewardOptions] = useState<Option[]>([]);
  const [rewardsWithTypes, setRewardsWithTypes] = useState<IData>({});
  /* Milestone */

  /* Promo Codes */
  const [promoCodes, setPromoCodes] = useState<Pick<RewardPromoCode, 'id' | 'code'>[]>([]);
  const [promoCodesFile, setPromoCodesFile] = useState<File | null>(null);
  /* Promo Codes */

  const loadReward = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (rewardId: string) => {
      setIsLoadingReward(true);
      fetchReward(publicationId, rewardId)
        .then((reward: Reward) => {
          setRewardInState({
            id: reward?.id,
            name: reward?.name,
            description: reward?.description,
            disclaimer: reward?.disclaimer,
            image: reward?.image ? (reward?.image as Image) : null,
            prefixed_article: reward?.prefixed_article,
            reward_type: reward?.reward_type,
            static_promo_code: reward?.static_promo_code,
            use_static_promo_code: reward?.use_static_promo_code,
            premium_gift_id: reward?.premium_gift_id,
          });
        })
        .finally(() => setIsLoadingReward(false));
    },
    [publicationId]
  );

  // Load Reward
  useEffect(() => {
    if (rewardId) {
      loadReward(rewardId);
    }
  }, [loadReward, rewardId]);

  const resetReward = useCallback(() => setRewardInState({ ...DEFAULT_REWARD }), []);

  const createReward = useCallback(
    (payload: FormData, onSuccess: (id: string) => void) => {
      setIsSavingReward(true);
      api
        .post('/referral_program/rewards', payload)
        .then((res) => {
          const createdId: string = res.data?.id;
          toast.success('Reward created successfully');
          onSuccess(createdId);
          resetReward();
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsSavingReward(false);
        });
    },
    [resetReward]
  );

  const updateReward = useCallback(
    (id: string, payload: FormData, onSuccess: (id: string) => void) => {
      setIsSavingReward(true);
      api
        .patch(`/referral_program/rewards/${id}`, payload)
        .then((res) => {
          const updatedId: string = res.data?.id;
          toast.success('Reward updated successfully');
          onSuccess(updatedId);
          resetReward();
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsSavingReward(false);
        });
    },
    [resetReward]
  );

  const loadMilestone = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (milestoneId: string) => {
      setIsLoadingMilestone(true);
      fetchMilestone(publicationId, milestoneId, () => setIsErrorMilestone(true))
        .then((response: Milestone) => {
          setMilestone({
            id: response?.id,
            tiptap_state: response?.tiptap_state,
            num_referrals: response?.num_referrals,
            reward_id: response?.reward_id,
            subject_line: response?.subject_line,
            preview_text: response?.preview_text,
            auto_fulfill: response?.auto_fulfill,
            delay_achievement_email_until_fulfilled: response?.delay_achievement_email_until_fulfilled,
          });
          setEmailContent(response?.tiptap_state || {});
          setRewardInState(response.reward);
        })
        .finally(() => setIsLoadingMilestone(false));
    },
    [publicationId]
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const fetchAndSetRewardOptions = useCallback(async (publicationId: string) => {
    try {
      const response = await fetchRewardOptions(publicationId);
      if (response) {
        const rewards = response.data?.options || [];
        const rewardsToTypes: IData = {};

        const options: Option[] = rewards.map((reward: Reward) => {
          // Storing this elsewhere to drive section logic
          rewardsToTypes[reward.id] = reward.reward_type;

          const val: Option = {
            label: reward.name,
            value: reward.id,
          };
          return val;
        });
        setRewardOptions(options);
        setRewardsWithTypes(rewardsToTypes);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  }, []);

  // Load Milestone
  useEffect(() => {
    if (publicationId) {
      fetchAndSetRewardOptions(publicationId);

      if (milestoneId) {
        loadMilestone(milestoneId);
      }
    }
  }, [publicationId, milestoneId, fetchAndSetRewardOptions, loadMilestone]);
  /* eslint-enable */

  const createMilestone = useCallback(
    (payload: CreateMilestonePayload) => {
      setIsSavingMilestone(true);
      api
        .post(`/referral_program/milestones`, payload)
        .then(() => {
          toast.success('Milestone created successfully');
          navigate('/referral_program/configure/milestones');
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsSavingMilestone(false);
        });
    },
    [navigate]
  );

  const updateMilestone = useCallback(
    (payload: UpdateMilestonePayload) => {
      setIsSavingMilestone(true);
      api
        .patch(`/referral_program/milestones/${payload.id}`, payload)
        .then(() => {
          toast.success('Milestone updated successfully');
          navigate('/referral_program/configure/milestones');
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsSavingMilestone(false);
        });
    },
    [navigate]
  );

  const createMilestoneAndReward = useCallback(
    (payload: FormData) => {
      setIsSavingMilestone(true);
      api
        .post(`/referral_program/rewards`, payload)
        .then(() => {
          toast.success('Referral created successfully!');

          analytics.track('Added referral', {
            rewardType: rewardInState.reward_type,
            promoCodeType: rewardInState.use_static_promo_code ? 'single_code' : 'unique_code',
            fulfillmentType: milestone.auto_fulfill ? 'auto' : 'delayed',
          });

          navigate('/referral_program?tab=overview');
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsSavingMilestone(false);
        });
    },
    [milestone.auto_fulfill, navigate, rewardInState.reward_type, rewardInState.use_static_promo_code]
  );

  const updateMilestoneAndReward = useCallback(
    (id: string, payload: FormData) => {
      setIsSavingMilestone(true);
      api
        .patch(`/referral_program/rewards/${id}`, payload)
        .then(() => {
          toast.success('Referral updated successfully!');

          analytics.track('Updated referral', {
            rewardType: rewardInState.reward_type,
            promoCodeType: rewardInState.use_static_promo_code ? 'single_code' : 'unique_code',
            fulfillmentType: milestone.auto_fulfill ? 'auto' : 'delayed',
          });

          navigate('/referral_program?tab=overview');
          queryClient.invalidateQueries([publicationId, 'milestones']);
          queryClient.invalidateQueries([publicationId, 'referral_program', 'rewards', id, 'promo_codes']);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsSavingMilestone(false);
        });
    },
    [navigate, publicationId, queryClient]
  );

  const onFetchPreview = useCallback(
    (platform: string, onFetch: Function) => {
      const payload = {
        publication_id: publicationId,
        reward_id: milestone?.reward_id,
        reward_type: rewardInState?.reward_type,
        platform,
        tiptap_state: emailContent,
      };

      api
        .post(`/referral_program/milestones/preview`, payload)
        .then((resp) => {
          onFetch(resp.data.html);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [emailContent, milestone?.reward_id, publicationId, rewardInState?.reward_type]
  );

  const rewardImageValue =
    // eslint-disable-next-line no-nested-ternary
    rewardInState?.image instanceof File
      ? rewardInState.image
      : typeof rewardInState?.image === 'object' && rewardInState.image !== null
      ? (rewardInState.image as any).url
      : undefined;

  const value = useMemo(() => {
    return {
      isLoadingReward,
      isSavingReward,
      reward: rewardInState,
      rewardImageValue,
      rewardTypeOptions: rewardTypeOptions(
        currentPublication?.is_premium_enabled || false,
        !!settings?.referral_program_premium_gift_reward
      ),
      setReward: setRewardInState,
      resetReward,
      createReward,
      updateReward,
      isSavingMilestone,
      isLoadingMilestone,
      isErrorMilestone,
      showManagePromoCodes,
      setShowManagePromoCodes,
      milestone,
      emailContent,
      setEmailContent,
      rewardOptions,
      rewardsWithTypes,
      createMilestone,
      updateMilestone,
      setMilestone,
      fetchAndSetRewardOptions,
      onFetchPreview,
      createMilestoneAndReward,
      updateMilestoneAndReward,
      promoCodesFile,
      setPromoCodesFile,
      promoCodes,
      setPromoCodes,
    };
  }, [
    isLoadingReward,
    isSavingReward,
    rewardInState,
    rewardImageValue,
    currentPublication?.is_premium_enabled,
    resetReward,
    createReward,
    updateReward,
    isSavingMilestone,
    isLoadingMilestone,
    isErrorMilestone,
    showManagePromoCodes,
    milestone,
    emailContent,
    rewardOptions,
    rewardsWithTypes,
    createMilestone,
    updateMilestone,
    fetchAndSetRewardOptions,
    onFetchPreview,
    createMilestoneAndReward,
    updateMilestoneAndReward,
    promoCodesFile,
    promoCodes,
    settings?.referral_program_premium_gift_reward,
  ]);

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export const useFormContext = () => useContext(FormContext) as ContextProps;
