import { useState } from 'react';
import { LinkIcon } from '@heroicons/react/20/solid';

import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import TabNavigation from '@/components/TabNavigation';
import { useCopyToClipboard } from '@/hooks';
import usePartnerDetails from '@/hooks/usePartner';
import usePartnerLeaderboard from '@/hooks/usePartnerLeaderboard';
import { Card } from '@/ui/Card';
import { MetricCard } from '@/ui/MetricCard';

import PartnerProgramAnnouncementModal from './AnnouncementModal';
import PartnerProgramFAQ from './FAQ';
import PartnerProgramLeaderboard from './Leaderboard';
import Links from './Links';
import PartnerProgramResources from './Resources';
import PartnerProgramStartHere from './StartHere';

const PartnerProgram = () => {
  const [selectedTab, setSelectedTab] = useState<string>('start_here');
  const copy = useCopyToClipboard();
  const { data: partnerDetails, isLoading, refetch }: any = usePartnerDetails();
  const { data: leaderboardData }: any = usePartnerLeaderboard();

  const tabs = [
    {
      name: 'start_here',
      label: 'Start Here',
      selected: selectedTab === 'start_here',
      onSelect: () => setSelectedTab('start_here'),
    },
    {
      name: 'links',
      label: 'Links',
      selected: selectedTab === 'links',
      onSelect: () => setSelectedTab('links'),
    },
    {
      name: 'resources',
      label: 'Resources',
      selected: selectedTab === 'resources',
      onSelect: () => setSelectedTab('resources'),
    },
    ...(window?.env?.REACT_APP_PARTNER_LEADERBOARD_ENABLED === 'true'
      ? [
          {
            name: 'leaderboard',
            label: 'Leaderboard',
            selected: selectedTab === 'leaderboard',
            onSelect: () => setSelectedTab('leaderboard'),
          },
        ]
      : []),
    {
      name: 'faq',
      label: 'FAQ',
      selected: selectedTab === 'faq',
      onSelect: () => setSelectedTab('faq'),
    },
  ];

  const tabComponent: any = {
    start_here: <PartnerProgramStartHere />,
    resources: <PartnerProgramResources />,
    leaderboard: <PartnerProgramLeaderboard leaderboardRows={leaderboardData?.leaderboard_details} />,
    faq: <PartnerProgramFAQ />,
    links: <Links refetch={refetch} links={partnerDetails?.metrics?.links} />,
  };

  const handleCopy = () => {
    copy({ text: partnerDetails?.details?.link, onSuccessText: 'Copied to clipboard!' });
  };

  const formatter = new Intl.NumberFormat('en');
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2,
  });

  return (
    <>
      <PageHeading
        title="Partner Program"
        description="Earn commissions by simply introducing new customers to beehiiv"
      />
      <PartnerProgramAnnouncementModal />
      <div className="flex flex-col lg:flex-row w-full space-y-2 lg:space-y-0 lg:space-x-2">
        <div className="flex flex-col space-y-2 w-full lg:w-3/4">
          <div className="flex flex-col lg:flex-row w-full space-y-2 lg:space-y-0 lg:space-x-2">
            <div className="w-full lg:w-1/3">
              <MetricCard
                variant="primary"
                isLoading={isLoading}
                label="Visitors"
                value={formatter.format(partnerDetails?.metrics?.visitors)}
                tooltip="The unique traffic you've sent to our website."
              />
            </div>
            <div className="w-full lg:w-1/3">
              <MetricCard
                variant="primary"
                isLoading={isLoading}
                label="Leads"
                value={formatter.format(partnerDetails?.metrics?.leads)}
                tooltip="The amount of visitors that have converted into an account signup/trial."
              />
            </div>
            <div className="w-full lg:w-1/3">
              <MetricCard
                variant="primary"
                isLoading={isLoading}
                label="Conversions"
                value={formatter.format(partnerDetails?.metrics?.conversions)}
                tooltip="The amount of leads that have converted into a paid account."
              />
            </div>
          </div>
          <Card density="compact">
            <div className="flex flex-col space-y-2">
              <p className="text-gray-900 font-semibold text-sm">Your Partner Link</p>
              <div className="flex flex-row">
                <input
                  placeholder="https://www.beehiiv.com?via=john"
                  disabled
                  value={partnerDetails?.details?.link}
                  className="appearance-none block w-full px-3 border border-surface-200 focus:border-[#EC4899] py-2 rounded-l-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-transparent sm:text-sm h-full disabled:cursor-not-allowed"
                />
                <button
                  className="bg-[#EEF2FF] hover:bg-[#E0E7FF] w-[150px] flex flex-row justify-center items-center space-x-1 rounded-r-md border border-[#C7D2FE]"
                  type="button"
                  onClick={handleCopy}
                >
                  <LinkIcon className="text-[#4338CA] h-4 w-4 my-auto" />
                  <p className="text-xs lg:text-sm text-[#4338CA] font-medium my-auto">Share & Earn</p>
                </button>
              </div>
              <p className="text-gray-900 font-light text-xs">
                You earn 50% commission for 12 months. Your audience receives a 30-day trial + 20% OFF for 3 months.
              </p>
            </div>
          </Card>
        </div>
        <div className="w-full lg:w-1/4">
          <MetricCard
            variant="secondary"
            isLoading={isLoading}
            className="h-full"
            label="Paid Commissions"
            tooltip="Payouts that have successfully been paid. Thank you!"
            value={currencyFormatter.format(partnerDetails?.payouts?.paid)}
            initialVisibleComplements={3}
            complements={[
              {
                label: 'Pending',
                value: currencyFormatter.format(partnerDetails?.payouts?.pending),
                tooltipText: 'Payouts currently in our 30-day provisioning window',
              },
              {
                label: 'Due',
                value: currencyFormatter.format(partnerDetails?.payouts?.due),
                tooltipText: 'Payouts that are eligible for our next payout cycle on the 15th',
              },
            ]}
          />
        </div>
      </div>
      <TabNavigation tabs={tabs} variant="primary" className="pt-4 pb-4" />
      {tabComponent[selectedTab]}
    </>
  );
};

export default PartnerProgram;
