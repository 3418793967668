import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useUpdateAssignment = (assignmentId: number | null, userId: string | null) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (assignment: any) =>
      api.patch(`/assignments/${assignmentId}`, {
        publication_id: currentPublicationId,
        assignment: assignment.assignment,
        role_id: assignment.role_id,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['user', userId]);
        toast.success('Role Updated!');
      },
    }
  );
};

export default useUpdateAssignment;
