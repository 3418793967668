import moment from 'moment-mini';

import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import Text from '@/components/Text';
import pluralize from '@/utils/pluralize';

import { useSubscriberContext } from '../_context/subscriberContext';
import useSubscriptionTierDetails from '../_hooks/useSubscriptionTierDetails';

const GeneralDetails = () => {
  const { subscription } = useSubscriberContext();
  const { data, isFetching } = useSubscriptionTierDetails({ id: subscription.id }) || {};
  const tierDetails = data?.details;
  const DETAILS_COLORS: any = {
    trial: 'text-feedback-warning-600',
    discount: 'text-feedback-warning-600',
    gift: 'text-feedback-warning-600',
    full_price: 'text-feedback-success-600',
  };

  return (
    <div className="px-4">
      <div className="mt-6 space-y-2">
        <Text size="sm" className="text-gray-500">
          Subscribe Date
        </Text>
        <Text size="md" type="body">
          {moment(subscription.created_at).format('LL')}
        </Text>
      </div>
      <div className="flex mt-6 justify-between">
        <div className="space-y-2 shrink">
          <Text size="sm" className="text-gray-500">
            {pluralize('Subscription Tier', subscription.tiers.length, undefined, true)}
          </Text>
          <Text size="md" type="body" className="capitalize">
            <SkeletonLoader
              isLoading={isFetching}
              skeletons={
                <div className="space-y-6 w-full">
                  <div className="space-y-2">
                    <Skeleton className="h-5 rounded" />
                  </div>
                </div>
              }
            >
              <ul className="space-y-1">
                {tierDetails && (
                  <>
                    {tierDetails.map((detailsData) => (
                      <li key={detailsData.tier_id}>
                        {detailsData.tier_name}
                        <Text size="xs" className={DETAILS_COLORS[detailsData.type]}>
                          {detailsData.text}
                        </Text>
                      </li>
                    ))}
                  </>
                )}
                {tierDetails && !isFetching && tierDetails.length === 0 && <li>Free</li>}
              </ul>
            </SkeletonLoader>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default GeneralDetails;
