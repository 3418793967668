import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { Button } from '@/ui/Button';

import { Textarea } from '../../../../../../components/Form';
import SlideOver from '../../../../../../components/SlideOver';
import SubscriberSelect from '../../../../../../components/SubscriberSelect';
import { useCreateCommentBan } from '../../../../../../hooks/useComments';
import { BanTypes } from '../../../../../../interfaces/comment_ban';

const AddCommentBan = () => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [currentSubscriberId, setCurrentSubscriberId] = useState<string>();

  const createCommentBan = useCreateCommentBan({
    onSuccess: () => {
      toast.success('Saved!');
      queryClient.invalidateQueries(['comment_bans']);
      setReason('');
      setCurrentSubscriberId('');
      setIsOpen(false);
    },
  });

  const handleCreateCommentBan = () => {
    createCommentBan.mutate({
      level: BanTypes.PUBLICATION,
      reason,
      subscriber_id: currentSubscriberId,
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    setReason('');
    setCurrentSubscriberId('');
  };

  return (
    <div>
      <SlideOver bodyId="" isOpen={isOpen} onClose={handleClose} headerText="Add Comment Ban">
        <div className="space-y-4">
          <div className="space-y-4">
            <SubscriberSelect
              labelText="Simulated Subscriber"
              helperText="This still only sends the test to the emails selected above, but will send the email personalized as if sent to this selected subscriber. Great for testing personalization or referral program."
              onSelectSubscriber={(subscriberId: string) => setCurrentSubscriberId(subscriberId)}
              onClearSubscriber={() => setCurrentSubscriberId('')}
            />

            <Textarea
              name="reason"
              labelText="Reason for ban"
              helperText="This can be helpful for other admins to understand why you took this action."
              placeholderText='e.g. "This user was reported by multiple other users.'
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <div className="flex space-x-2 pt-4">
            <Button variant="primary-inverse" onClick={handleClose}>
              Close
            </Button>
            <div>
              <Button loading={createCommentBan.isLoading} onClick={handleCreateCommentBan}>
                {createCommentBan.isLoading ? 'Adding...' : 'Add Ban'}
              </Button>
            </div>
          </div>
        </div>
      </SlideOver>

      <Button onClick={() => setIsOpen(true)}>Add Comment Ban</Button>
    </div>
  );
};

export default AddCommentBan;
