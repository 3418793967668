import { Icon as PhosphorIcon } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';

type Props = {
  Icon: PhosphorIcon;
  text: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
export const ActionMenu = ({ Icon, text, onClick }: Props) => (
  <button type="button" className="group flex items-center gap-1.5 cursor-pointer" onClick={onClick}>
    <div className="flex items-center justify-center w-5 h-5 bg-wb-highlight rounded-md">
      <Icon size={16} className="text-wb-secondary group-hover:text-wb-primary" weight="bold" />
    </div>
    <Text variant="secondary" size="2xs" weight="semibold" className="group-hover:text-wb-primary">
      {text}
    </Text>
  </button>
);
