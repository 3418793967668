import { ChangeEventHandler } from 'react';
import toast from 'react-hot-toast';

import { Select, Textarea } from '@/components/Form';
import Switch from '@/components/Form/Switch';
import { Typography, TypographyStack } from '@/components/Typography';
import { ReferralProgram, ReferralProgramLayout } from '@/interfaces/referral_program';
import { Card } from '@/ui/Card';
import analytics from '@/utils/analytics';

import useReferralProgramForm from '../hooks/useReferralProgramForm';

import ReferralBlockPreview from './ReferralBlockPreview';

const ConfigurationTab = () => {
  const { referralProgramInState, setReferralProgramInState, updateReferralProgram, isUpdating, onStatusChange } =
    useReferralProgramForm();

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const newReferralProgram = { ...referralProgramInState } as ReferralProgram;
    newReferralProgram.email_description = event.target.value;
    setReferralProgramInState(newReferralProgram);
  };

  const handleBlur = () => {
    updateReferralProgram(
      {
        emailDescription: referralProgramInState?.email_description,
      },
      {
        onSuccess: () => {
          toast.success('Email description was updated!');
        },
      }
    );
  };

  const handleSelectLayout = (layout: ReferralProgramLayout) => {
    const newReferralProgram = { ...referralProgramInState } as ReferralProgram;
    newReferralProgram.block_layout = layout;
    setReferralProgramInState(newReferralProgram);
    updateReferralProgram(
      {
        blockLayout: layout,
      },
      {
        onSuccess: () => {
          toast.success('Layout updated!');
          analytics.track('Configured Referral layout', {
            referralBlockLayout: layout,
          });
        },
      }
    );
  };

  return (
    <Card className="space-y-5">
      <TypographyStack>
        <Typography token="font-bold/text/base">Referral Block Configuration</Typography>
        <Typography token="font-normal/text/base" colorWeight="400">
          Determine what style you want to include in your posts to show your referral program
        </Typography>
      </TypographyStack>

      <Card backgroundColor="bg-surface-50 space-y-4">
        <Switch
          name="referral-program-status"
          prefixLabelText="Referral Program"
          helperText="Enable the Referral Block in your Posts"
          checked={!referralProgramInState?.disabled_at}
          onChange={onStatusChange}
          size="small"
          disabled={isUpdating}
        />
        <Textarea
          name="referral-program-email-description"
          labelText="Email Description"
          helperText="The text displayed at the top of your referral block within your email posts"
          value={referralProgramInState?.email_description || ''}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Card>

      <Card backgroundColor="bg-surface-50 space-y-4">
        <Select
          name="referral-program-layout"
          labelText="Referral Template Shown in Post"
          helperText="Choose how you want the referral block to appear on your posts"
          value={referralProgramInState?.block_layout || ReferralProgramLayout.DEFAULT}
          onSelect={(_name: string, value: ReferralProgramLayout) => handleSelectLayout(value)}
          options={[
            { label: 'Default', value: ReferralProgramLayout.DEFAULT },
            { label: 'Upcoming Milestone Only', value: ReferralProgramLayout.UPCOMING_MILESTONE_ONLY },
            { label: 'Reward Program Only', value: ReferralProgramLayout.REWARDS_ONLY },
            {
              label: 'Upcoming Milestones and Reward Program',
              value: ReferralProgramLayout.UPCOMING_MILESTONES_AND_REWARDS,
            },
          ]}
        />

        {referralProgramInState && (
          <ReferralBlockPreview
            key={`${referralProgramInState.disabled_at}`}
            isLoading={isUpdating}
            layout={referralProgramInState.block_layout || ReferralProgramLayout.DEFAULT}
          />
        )}
      </Card>
    </Card>
  );
};

export default ConfigurationTab;
