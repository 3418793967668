import { useQuery } from 'react-query';

import { useCurrentPublication } from '@/hooks';
import api from '@/services/swarm';

const usePausePlan = () => {
  const { data: currentPublication } = useCurrentPublication();

  interface PausePlan {
    resumes_at: string;
  }

  const fetchPausePlan = () =>
    api.get(`/organizations/${currentPublication?.organization_id}/pause_plan`).then((res) => res.data);

  return useQuery<PausePlan>(['pause_plan', currentPublication?.organization_id], fetchPausePlan, {
    keepPreviousData: true,
  });
};

export default usePausePlan;
