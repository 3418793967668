import { useSearchParams } from 'react-router-dom';

import TabNavigation from '@/components/TabNavigation';
import useEmailApplications from '@/hooks/boosts/grow/useEmailApplications';
import usePrimaryOffer from '@/hooks/boosts/grow/usePrimaryOffer';
import useWebApplications from '@/hooks/boosts/grow/useWebApplications';
import { BoostOfferStatus } from '@/interfaces/boosts/grow/boost_offer';
import { Tab } from '@/interfaces/general';

import Applications from './Applications';
import Insights from './Insights';
import OffersHistory from './OffersHistory';
import Overview from './Overview';

const Tabs = () => {
  const { data: primaryOffer } = usePrimaryOffer();
  const { count: webApplicationsCount } = useWebApplications(primaryOffer?.id || '', { perPage: 0 });
  const { count: emailApplicationsCount } = useEmailApplications(primaryOffer?.id, {
    maxSpendCents: primaryOffer?.max_spend_cents,
    perPage: 0,
  });

  const [searchParams] = useSearchParams();
  const activeTabParam = searchParams.get('tab');

  const tabs = [
    {
      name: 'overview',
      label: 'Overview',
      targetRoute: '/grow/boosts?tab=overview',
      selected: !activeTabParam || activeTabParam === 'overview',
    },
    {
      name: 'applications',
      label: 'Applications',
      targetRoute: '/grow/boosts?tab=applications',
      selected: activeTabParam === 'applications',
      count: webApplicationsCount + emailApplicationsCount,
    },
    {
      name: 'insights',
      label: 'Insights',
      targetRoute: '/grow/boosts?tab=insights',
      selected: activeTabParam === 'insights',
    },
    {
      name: 'offerHistory',
      label: 'Offer History',
      targetRoute: '/grow/boosts?tab=offer_history',
      selected: activeTabParam === 'offer_history',
    },
  ] as Tab[];

  const overviewOffer = primaryOffer?.status === BoostOfferStatus.LIVE && primaryOffer ? primaryOffer : undefined;

  return (
    <>
      <TabNavigation variant="tertiary" tabs={tabs} />
      {(!activeTabParam || activeTabParam === 'overview') && <Overview />}
      {activeTabParam === 'applications' && <Applications boostOffer={overviewOffer} />}
      {activeTabParam === 'insights' && <Insights />}
      {activeTabParam === 'offer_history' && <OffersHistory />}
    </>
  );
};

export default Tabs;
