import { useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';

import { Input, Textarea } from '@/components/Form';
import Text from '@/components/Text';
import { useControlledInput, useCurrentPublication, useOrganization } from '@/hooks';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import { UrlPatterns } from './UrlPatterns';

const VerificationRequest = () => {
  const navigate = useNavigate();

  const { data: currentPublication } = useCurrentPublication();
  const organizationQuery = useOrganization(currentPublication?.organization_id);
  const { organization } = organizationQuery?.data || {};
  const isVerified = Boolean(organization?.verified_at);

  const validUrl = (url: string) => UrlPatterns.patterns.generic.test(url);
  const [isRequesting, setIsRequesting] = useState(false);
  const twitter = useControlledInput({ initialState: '' });
  const linkedIn = useControlledInput({ initialState: '' });
  const description = useControlledInput({ initialState: '' });

  const handleSendVerificationRequest = (): void => {
    api
      .post(`/verification_requests`, {
        verification_request: {
          writing_details: description.state,
          twitter_url: twitter.state,
          linkedin_url: linkedIn.state,
        },
        publication_id: currentPublication?.id,
      })
      .then(() => {
        toast.success('Verification request sent!');
        navigate('/');
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setIsRequesting(false));
  };

  const handleUpdate = (e: React.FormEvent): void => {
    e.preventDefault();

    if (twitter.state && !validUrl(twitter.state)) {
      toast.error('Please enter valid URLs');
      return;
    }

    if (linkedIn.state && !validUrl(linkedIn.state)) {
      toast.error('Please enter valid URLs');
      return;
    }

    if (!description.state) {
      toast.error('Please tell us about your publication!');
      return;
    }

    setIsRequesting(true);

    // First update publication details with relevant links
    // Then send verification request
    api
      .patch(`/publications/${currentPublication?.id}`, {
        publication_id: currentPublication?.id,
        publication: {
          twitter_url: twitter.state,
          linkedIn_url: linkedIn.state,
        },
      })
      .then(() => {
        handleSendVerificationRequest();
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  };

  if (isVerified) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex justify-center">
      <Helmet>
        <title>Verification Request - beehiiv</title>
      </Helmet>
      <div className="flex flex-col max-w-lg w-full">
        <div>
          <form onSubmit={handleUpdate} className="border border-gray-200 rounded shadow-sm bg-white rounded shadow-md">
            <div className="space-y-6 p-8">
              <div className="space-y-4">
                <Text type="bold" size="xl" className="text-gray-900">
                  Verify Your Identity
                </Text>
                <div className="bg-blue-50 rounded p-4 space-y-4">
                  <Text type="body" size="sm" className="text-gray-900">
                    <span className="font-bold">Please Note:</span> This information is used for internal purposes ONLY
                    and will not be associated with your newsletter or publicized in any way. Please provide accurate
                    accounts so our team can properly vet and approve you for sending.
                  </Text>
                </div>
              </div>
              <Input
                type="text"
                name="twitter"
                labelText="Where can we find you on Twitter?"
                placeholder="https://twitter.com/beehiiv"
                helperText="Not required but will help you get verified more quickly"
                errorText={twitter.errorMessage}
                value={twitter.state}
                onChange={(e) => {
                  twitter.handleChange(e);
                }}
              />
              <Input
                type="text"
                name="linkedIn"
                labelText="Where can we find you on LinkedIn?"
                placeholder="https://www.linkedin.com/beehiiv"
                helperText="Not required but will help you get verified more quickly"
                errorText={linkedIn.errorMessage}
                value={linkedIn.state}
                onChange={(e) => {
                  linkedIn.handleChange(e);
                }}
              />
              <Textarea
                name="description"
                labelText="Tell Us About Your Publication"
                helperText="This will be displayed on your landing page."
                placeholderText="Your description goes here..."
                errorText={description.errorMessage}
                value={description.state}
                onChange={(e) => {
                  description.setState(e.target.value);
                }}
              />
            </div>
            <div className="bg-gray-100 p-4 flex justify-end">
              <Button loading={isRequesting} type="submit">
                {isRequesting ? 'Requesting...' : 'Send Request'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerificationRequest;
