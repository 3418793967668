import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useSearchParams } from 'react-router-dom';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useDebounce } from 'use-debounce';
import { StringParam, useQueryParam } from 'use-query-params';

import Banner from '@/components/Banner';
import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import LoadingBox from '@/components/LoadingBox';
import Modal from '@/components/Modal';
import { useSettings } from '@/context/settings-context';
import useBoostOffers, { BoostOfferSort } from '@/hooks/boosts/monetize/useBoostOffers';
import useStripeConnectAccount, { StripeAccountStatus } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import useOptions from '@/hooks/useOptions';
import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';

import ConnectToStripe from '../components/ConnectToStripe';
import { BoostOfferCard } from '../Shared/BoostOfferCard';
import Teaser from '../Teaser';

import ApplyModal from './components/ApplyModal';
import BoostOfferSlideover from './components/BoostOfferSlideover';
import Filters from './Filters';
import NoResults from './NoResults';

const sortingOptions: Option<BoostOfferSort>[] = [
  { label: 'Featured', value: BoostOfferSort.FEATURED },
  { label: 'Newest', value: BoostOfferSort.NEWEST },
  { label: 'Oldest', value: BoostOfferSort.OLDEST },
  { label: 'Highest Payout', value: BoostOfferSort.HIGHEST_PAYOUT },
  { label: 'Highest Send Payout', value: BoostOfferSort.HIGHEST_SEND_PAYOUT },
];

const BoostsMarketplace = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const boostOfferIdParam = searchParams.get('boost_offer_id');

  const location = useLocation();
  const locationState = location.state as { throughBoostInviteEmail?: boolean };

  const { settings } = useSettings();
  const [selectedBoostOfferId, setSelectedBoostOfferId] = useState<string | null>(boostOfferIdParam);
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [shouldResetSearch, setShouldResetSearch] = useState(false);
  const [sort, setSort] = useState(sortingOptions[0]);

  const [minPerLeadCents, setMinPerLeadCents] = useState<number | undefined>();
  const [maxPerLeadCents, setMaxPerLeadCents] = useState<number | undefined>();
  const [minPayoutCents, setMinPayoutCents] = useState<number | undefined>();
  const [maxPayoutCents, setMaxPayoutCents] = useState<number | undefined>();
  const [tagIds, setTagIds] = useState<string[]>([]);
  const [eligibleCountries, setEligibleCountries] = useState<string[]>([]);

  const debouncedMinPerLeadCents = minPerLeadCents;
  const [debouncedMaxPerLeadCents] = useDebounce(maxPerLeadCents, 1000);
  const [debouncedMinPayoutCents] = useDebounce(minPayoutCents, 1000);
  const [debouncedMaxPayoutCents] = useDebounce(maxPayoutCents, 1000);
  const [debouncedTagIds] = useDebounce(tagIds, 1000);
  const [debouncedEligibleCountries] = useDebounce(eligibleCountries, 1000);

  const boostOffersQuery = useBoostOffers({
    perPage: 12,
    search: search || '',
    sort: sort.value,
    minPerLeadCents: debouncedMinPerLeadCents,
    maxPerLeadCents: debouncedMaxPerLeadCents,
    minPayoutCents: debouncedMinPayoutCents,
    maxPayoutCents: debouncedMaxPayoutCents,
    tagIds: debouncedTagIds,
    eligibleCountries: debouncedEligibleCountries,
  });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching, isError } = boostOffersQuery;
  const boostOffers = data?.pages.flatMap((page) => page.boost_offers) || [];

  const tagsQuery = useOptions('tags');
  const { data: tagsData } = tagsQuery;

  const isNoResults = !isLoading && boostOffers.length === 0;
  // After triggering search reset, switch boolean back to false
  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch, setShouldResetSearch]);

  const handleSlideoverClose = () => {
    searchParams.delete('boost_offer_id');
    setSearchParams(searchParams);
    setSelectedBoostOfferId(null);
  };

  // Reset search when no results
  const handleResetSearch = () => {
    setSearch(undefined);
    setShouldResetSearch(true);
  };

  const { data: stripeConnectAccount, isLoading: isLoadingStripeAccount } = useStripeConnectAccount();
  const boostsAccountStatus = stripeConnectAccount?.boosts_account_status || StripeAccountStatus.MISSING;

  const [isConnectingToStripe, setIsConnectingToStripe] = useState(false);
  const [selectedBoost, setSelectedBoost] = useState<BoostOffer | null>(null);
  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);

  const handleApply = ({ boostOffer }: { boostOffer: BoostOffer }) => {
    if (!settings?.boosts) {
      setIsUpsellModalOpen(true);
      return;
    }

    if (isLoadingStripeAccount) {
      toast.loading('One moment please...');
      return;
    }

    if (boostsAccountStatus !== StripeAccountStatus.ACTIVE) {
      setIsConnectingToStripe(true);
    } else {
      setSelectedBoost(boostOffer);
    }
  };

  const handleClickConnectToStripe = () => {
    if (!settings?.boosts) {
      setIsUpsellModalOpen(true);
      return;
    }

    setIsConnectingToStripe(true);
  };

  if (settings && !settings.organization_boosts) {
    return <div className="text-bold">Boosts have been disabled for this account.</div>;
  }

  const tags: Option[] =
    tagsData?.options.map((option: [number, string]) => ({
      value: String(option[0]),
      label: option[1],
    })) || ([] as Array<Array<string>>);

  const shouldConnectToStripe = !isLoadingStripeAccount && boostsAccountStatus !== StripeAccountStatus.ACTIVE;
  const showPendingInvitesBanner = locationState?.throughBoostInviteEmail === true;

  return (
    <div>
      <BoostOfferSlideover onClose={handleSlideoverClose} onApply={handleApply} boostOfferId={selectedBoostOfferId} />
      {showPendingInvitesBanner && shouldConnectToStripe ? (
        <Banner
          className="mb-8"
          title="Pending Invites"
          bodyText={
            <>
              You have pending invites to Boost other publications. To review them and earn additional revenue,{' '}
              <button type="button" onClick={handleClickConnectToStripe}>
                <strong>connect</strong>
              </button>{' '}
              a Stripe account using the button below.
            </>
          }
          isScreenWide={false}
        />
      ) : null}
      <PageHeading
        title="Boosts Marketplace"
        description="Monetize your newsletter by boosting other publications in the beehiiv network."
      >
        {shouldConnectToStripe ? (
          <Button Icon={LinkIcon} type="button" onClick={handleClickConnectToStripe}>
            Connect to Stripe
          </Button>
        ) : null}
        <ConnectToStripe
          boostsAccountStatus={boostsAccountStatus}
          isOpen={isConnectingToStripe}
          onClose={() => setIsConnectingToStripe(false)}
        />
        <ApplyModal
          isOpen={!!selectedBoost}
          onClose={() => setSelectedBoost(null)}
          boostOffer={selectedBoost}
          onProceed={() => setSelectedBoost(null)}
        />
      </PageHeading>
      <LoadingBox isLoading={isLoading} isError={isError}>
        <>
          <Filters
            eligibleCountries={eligibleCountries}
            minPerLeadCents={minPerLeadCents}
            setMinPerLeadCents={setMinPerLeadCents}
            maxPerLeadCents={maxPerLeadCents}
            setMaxPerLeadCents={setMaxPerLeadCents}
            minPayoutCents={minPayoutCents}
            setMinPayoutCents={setMinPayoutCents}
            maxPayoutCents={maxPayoutCents}
            setMaxPayoutCents={setMaxPayoutCents}
            tagIds={tagIds}
            setTagIds={setTagIds}
            setEligibleCountries={setEligibleCountries}
            search={search}
            setSearch={setSearch}
            isFetching={isFetching}
            shouldResetSearch={shouldResetSearch}
            onResetSearch={handleResetSearch}
            tags={tags}
            sort={sort}
            setSort={setSort}
            sortingOptions={sortingOptions}
          />
          {isNoResults ? (
            <NoResults handleResetSearch={handleResetSearch} isLoading={isLoading} />
          ) : (
            <div className="grid grid-flow-row gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {boostOffers.map((boostOffer) => (
                <BoostOfferCard
                  boostOffer={boostOffer}
                  onApply={() => handleApply({ boostOffer })}
                  onExpand={() => setSelectedBoostOfferId(boostOffer.id)}
                  key={boostOffer.id}
                />
              ))}
            </div>
          )}
          {hasNextPage && (
            <div className="my-6 text-center">
              <div>
                <Button
                  variant="primary-inverse"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                </Button>
              </div>
            </div>
          )}
        </>
      </LoadingBox>
      <Modal isOpen={isUpsellModalOpen} onClose={() => setIsUpsellModalOpen(false)}>
        <div className="m-6">
          <Teaser />
        </div>
      </Modal>
    </div>
  );
};

export default BoostsMarketplace;
