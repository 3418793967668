import { useMemo } from 'react';

import { SimpleSelect } from '@/components/Form';
import { Milestone } from '@/interfaces/milestone';
import pluralize from '@/utils/pluralize';

interface MilestoneSelectProps {
  milestoneId: string;
  milestones?: Milestone[];
  onChange: (name: string, value: string) => void;
  className?: string;
}

const MilestoneSelect = ({ milestoneId, milestones, onChange, className }: MilestoneSelectProps) => {
  const nameOptions = useMemo(() => {
    if (!milestones) return [];

    return milestones.map((milestone) => {
      return {
        label: milestone.reward.name,
        value: milestone.id,
        description: pluralize('Referral', milestone.num_referrals),
      };
    });
  }, [milestones]);

  return (
    <SimpleSelect
      name="milestone_id"
      value={milestoneId}
      onSelect={onChange}
      options={nameOptions}
      placeholderText="Select a milestone"
      className={className}
    />
  );
};

export default MilestoneSelect;
