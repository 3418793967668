import { SimpleSelect } from '@/components/Form';
import useOptions from '@/hooks/useOptions';
import { optionDataToOptions } from '@/utils';

interface ExternalEmbedSelectProps {
  value: string;
  onChange: (name: string, value: string) => void;
}

const ExternalEmbedSelect = ({ value, onChange }: ExternalEmbedSelectProps) => {
  const externalEmbedsData = useOptions('external_embeds', Infinity);
  const options = optionDataToOptions(externalEmbedsData);

  return (
    <SimpleSelect
      name="value"
      placeholderText="Select Embed"
      value={value || ''}
      onSelect={onChange}
      options={options}
    />
  );
};

export default ExternalEmbedSelect;
