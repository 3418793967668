import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TrashIcon } from '@heroicons/react/24/outline';

import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { SplitTestOption } from '@/interfaces/split_test_option';
import api from '@/services/swarm';

interface Props {
  labelText: string;
  postId: string;
  splitTestOption: SplitTestOption;
  deletable: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  locked?: boolean;
}

const OptionForm = ({ labelText, postId, splitTestOption, deletable, onSave, onDelete, locked }: Props) => {
  const [publicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const [value, setValue] = useState(splitTestOption.value);
  // const [error, setError] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const name = splitTestOption?.id ? `split_test_option_${splitTestOption.id}` : 'new_split_test_option';

  const updateSplitTestOption = useMutation(
    (newValue: string) => {
      return api
        .patch(`/posts/${postId}/split_test_option`, {
          publication_id: publicationId,
          split_test_option: {
            id: splitTestOption.id,
            value: newValue,
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        if (onSave) {
          onSave();
        }
        // setError('');
        return queryClient.invalidateQueries(['split_test', postId]);
      },
      onError: (err: any) => {
        console.log(err);
        // setError(err?.response.data.error || "Couldn't save this option");
      },
    }
  );

  const deleteSplitTestOption = useMutation(
    () => {
      return api
        .delete(`/posts/${postId}/split_test_option`, {
          data: {
            publication_id: publicationId,
            split_test_option: {
              id: splitTestOption.id,
            },
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['split_test', postId]);
      },
    }
  );

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value !== splitTestOption.value) {
      updateSplitTestOption.mutate(event.target.value);
    }
  };

  const handleRemoveOption = () => {
    if (onDelete) onDelete();
    deleteSplitTestOption.mutate();
  };

  return (
    <div
      className="flex flex-row space-x-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="my-auto h-8 w-8 flex flex-col justify-center items-center text-gray-500 border border-action-secondary-200 rounded bg-action-secondary-50">
        <Typography size="xs" colorWeight='900' color='secondary'>
          {labelText}
        </Typography>
      </div>
      <div className='w-full flex flex-row'>
        <Input
          className="w-full my-auto"
          placeholder={`Enter subject line ${labelText}`}
          onBlur={onBlurHandler}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              event.currentTarget.blur();
            }
          }}
          name={name}
          required
          value={value}
          // errorText={error}
          disabled={locked}
        />
        {deletable && !locked && isHovered && (
          <div aria-hidden="true" className="my-auto cursor-pointer" onClick={handleRemoveOption}>
            <TrashIcon className="w-6 h-6 text-gray-500 hover:text-primary-500" />
          </div>
        )}
      </div>
    </div>
  );
};

export default OptionForm;
