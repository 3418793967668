import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import { Instance, sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';

import { CommentingPanel } from '@/components/TiptapEditor/components/panels/Commenting';
import { useThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import { Asset } from '@/interfaces/asset';

import { EditLink } from '../../../components/menus';
import { MenuProps } from '../../../components/menus/types';
import { forceUpdateTippy } from '../../../components/menus/utils/forceUpdateTippy';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { UpdateImagePanel } from '../../../components/panels/UpdateImage';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';
import { API } from '../../../lib/api';
import { usePublicationContext } from '../../../lib/context/PublicationContext';
import { ImageBlockDataPanel } from '../panels/ImageBlockDataPanel';
import { OptionsPanel } from '../panels/Options/OptionsPanel';
import { useImageBlock } from '../views/hooks';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const ImageBlockMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [editLink, setEditLink] = useState(false);
  const { setLoading, setImage } = useImageBlock();
  const { openComposer } = useThreadComposer();

  const tippyInstance = useRef<Instance | null>(null);

  const { publicationId } = usePublicationContext();

  const { id: imageId, url: imageBlockUrl, target: imageBlockTarget } = editor.getAttributes('imageBlock');

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-imageBlock');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('imageBlock');

    return isActive;
  }, [editor]);

  // If the image changes, reset the "Edit link" panel
  useEffect(() => {
    setEditLink(false);
  }, [imageId]);

  const removeNode = useCallback(() => {
    editor.commands.deleteNode('imageBlock');
  }, [editor]);

  const onUpload = useCallback(
    (file: File) => {
      setImage(null);
      setLoading(true);
      API.uploadPublicationAsset({
        file,
        publicationId,
      })
        .then((res) => {
          editor
            .chain()
            .focus()
            .updateAttributes('imageBlock', { id: res.data.id, src: null, title: null, alt: null })
            .run();
        })
        .catch(() => toast.error('There was a problem uploading this image. Make sure it is a valid image file.'))
        .finally(() => {
          setLoading(false);
        });
    },
    [editor, publicationId, setLoading, setImage]
  );

  const onReplace = useCallback(
    (asset: Asset) => {
      setImage(null);
      editor
        .chain()
        .focus()
        .updateAttributes('imageBlock', { id: asset.id, src: null, title: asset.title, alt: asset.alt })
        .run();
    },
    [editor, setImage]
  );

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`imageBlockMenu-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar shouldShowContent={shouldShow()} ref={menuRef}>
        {editLink ? (
          <EditLink
            link={imageBlockUrl}
            target={imageBlockTarget}
            onSetLink={(url: string) => {
              editor.commands.updateAttributes('imageBlock', { url });
              setEditLink(false);
            }}
            onSetTarget={(target: string) => {
              editor.commands.updateAttributes('imageBlock', { url: imageBlockUrl, target });
            }}
            onUnsetLink={() => {
              editor.commands.updateAttributes('imageBlock', { url: '' });
              setEditLink(false);
              return null;
            }}
            onBack={() => {
              setEditLink(false);
              if (tippyInstance.current) {
                forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
              }
            }}
            editor={editor}
          />
        ) : (
          <>
            <Tooltip title="Link">
              <Button
                $leftSlot={<Icon name="Link" />}
                $active={!!imageBlockUrl}
                onClick={() => {
                  setEditLink(true);
                  if (tippyInstance.current) {
                    forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
                  }
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
              />
            </Tooltip>
            <Divider />
            <ImageBlockDataPanel editor={editor} parentRef={menuRef} />
            <OptionsPanel editor={editor} parentRef={menuRef} />
            <Divider />
            <UpdateImagePanel
              tooltip="Upload or pick new image"
              parentRef={menuRef}
              onUpload={onUpload}
              onReplace={onReplace}
            />
            <Divider />
            <CommentingPanel onOpenCommentingPanel={openComposer} />
            <Divider />
            <Tooltip title="Remove image">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $leftSlot={<Icon name="Trash" />}
                onClick={removeNode}
              />
            </Tooltip>
          </>
        )}
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default ImageBlockMenu;
