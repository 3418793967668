import AppliedFilter from '../AppliedFilter';
import { AttributeCondition, NumberOperatorsList } from '../types';

const DeliveryRateFilterApplied = ({
  condition,
  onRemove,
}: {
  condition: AttributeCondition;
  onRemove: () => void;
}) => {
  const operator = NumberOperatorsList.find((o) => o.value === condition.filters.operator);
  if (!operator) return null;

  const operatorLabel = operator.shorthand;

  return <AppliedFilter name="Delivery" label={operatorLabel} onRemove={onRemove} value={condition.filters.value} />;
};

export default DeliveryRateFilterApplied;
