import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useUpdateEmailMessage } from '@/hooks';
import { EmailMessage } from '@/interfaces/email_message';

const useUpdateOptInEmail = (optInEmail: EmailMessage) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useUpdateEmailMessage({
    emailMessageId: optInEmail.id,
    onSuccess: () => {
      queryClient.invalidateQueries(['opt_in_email', currentPublicationId], { exact: true });
      queryClient.invalidateQueries(['email_messages', 'preview', optInEmail?.id], { exact: true });
      toast.success('Opt In saved');
    },
    onError: (err: any) => toast.error(err?.response?.data?.error || 'Something went wrong, please try again'),
  });
};

export default useUpdateOptInEmail;
