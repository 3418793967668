import { useRef } from 'react';

import TabNavigation from '@/components/TabNavigation';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { useHighlightFocusedThread } from '@/components/TiptapEditor/lib/hooks/useHighlightFocusedThread';
import useLocalStorage from '@/hooks/useLocalStorage';

import { useEditorContext } from '../../EditorContext';

import { AllThreadsView } from './views/AllThreads';
import { OpenThreadsView } from './views/OpenThreads';
import { ResolvedThreadsView } from './views/ResolvedThreads';
import { YourThreadsView } from './views/YourThreads';

type SidebarState = 'all' | 'open' | 'resolved' | 'yours';

export type ThreadsProps = {
  onClose: () => void;
  onSelectThread: (threadId: string) => void;
};

export const Threads = ({ onClose, onSelectThread }: ThreadsProps) => {
  const threadsSidebarContainerRef = useRef<HTMLDivElement>(null);

  const { editor } = useEditorContext();

  const [activeTab, setActiveTab] = useLocalStorage<SidebarState>('open', 'threadsSidebarActiveTab');

  const sideTabs = [
    {
      name: 'open',
      label: 'Open',
      selected: activeTab === 'open',
      onSelect: () => setActiveTab('open'),
    },
    {
      name: 'resolved',
      label: 'Resolved',
      selected: activeTab === 'resolved',
      onSelect: () => setActiveTab('resolved'),
    },
    {
      name: 'yours',
      label: 'Yours',
      selected: activeTab === 'yours',
      onSelect: () => setActiveTab('yours'),
    },
    {
      name: 'all',
      label: 'All',
      selected: activeTab === 'all',
      onSelect: () => setActiveTab('all'),
    },
  ];

  useHighlightFocusedThread(editor, threadsSidebarContainerRef);

  return (
    <div className="flex flex-col h-full py-1">
      <div className="flex items-center justify-between flex-none gap-4 mb-8">
        <TabNavigation variant="secondary" className="px-2 w-full h-full" tabs={sideTabs}>
          <button
            className="flex items-center justify-center w-6 h-6 rounded hover:bg-black hover:bg-opacity-5"
            onClick={onClose}
            type="button"
          >
            <Icon name="Close" className="w-4 h-4" />
          </button>
        </TabNavigation>
      </div>
      {editor && (
        <div ref={threadsSidebarContainerRef} className="flex-1 h-full overflow-auto px-4">
          {activeTab === 'open' ? <OpenThreadsView onClickThread={onSelectThread} /> : null}
          {activeTab === 'resolved' ? <ResolvedThreadsView onClickThread={onSelectThread} /> : null}
          {activeTab === 'yours' ? <YourThreadsView onClickThread={onSelectThread} /> : null}
          {activeTab === 'all' ? <AllThreadsView onClickThread={onSelectThread} /> : null}
        </div>
      )}
    </div>
  );
};
