/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading  */

import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import analytics from '@/utils/analytics';

import ActionModal from '../../../../components/ActionModal';
import CustomFieldSelect from '../../../../components/CustomFieldSelect';
import { Input, Switch } from '../../../../components/Form';
import { useDeleteFormQuestion, useUpdateFormQuestion } from '../../../../hooks/useForms';
import { FormQuestion, FormQuestionTypes, QuestionTypes } from '../../../../interfaces/form';

import AddNewQuestion from './AddNewQuestion';
import QuestionOptions from './QuestionOptions';

interface Props {
  questionType: FormQuestionTypes;
  question: FormQuestion;
  hasMoreThanOneQuestion: boolean;
  isDragActive: boolean;
  isLastQuestion: boolean;
  currentCustomFieldIds: string[];
  index: number;
  onAddQuestionClick: () => void;
}

const QuestionForm = ({
  question,
  hasMoreThanOneQuestion,
  isDragActive,
  currentCustomFieldIds,
  isLastQuestion,
  questionType,
  onAddQuestionClick,
}: Props) => {
  const queryClient = useQueryClient();

  const [isHovering, setIsHovering] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRequired, setIsRequired] = useState(question.required || false);
  const [isMultiSelect, setIsMultiSelect] = useState(question.multi_select || false);
  const [isShowMaxCharacters, setShowMaxCharacters] = useState(question.show_max_characters || false);
  const [maxCharacterLimit, setMaxCharacterLimit] = useState(question.max_character_limit || 400);
  const [minCharacterLimit, setMinCharacterLimit] = useState(question.min_character_limit || 0);
  const [promptInput, setPromptInput] = useState<string>(question.prompt || '');
  const [fieldId, setFieldId] = useState<string>(question.custom_field.id || '');
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: question.id });

  const { formId } = useParams();
  const deleteFormQuestion = useDeleteFormQuestion({
    formId: formId || '',
    formQuestionId: question.id,
  });
  const handleDelete = () => {
    deleteFormQuestion.mutate();
    setIsDeleting(false);
  };

  const updateFormQuestion = useUpdateFormQuestion({
    formQuestionId: question.id,
    formId: formId || '',
    onSuccess: () => {
      toast.success('Question saved!');
      queryClient.invalidateQueries(['forms', formId]);
    },
  });

  const isMultipleChoiceQuestion = questionType === QuestionTypes.MULTIPLE_CHOICE;
  const isFreeFormQuestion = questionType === QuestionTypes.FREE_FORM;
  const isDropdownQuestion = questionType === QuestionTypes.DROPDOWN;
  const isLongTextQuestion = questionType === QuestionTypes.LONG_TEXT;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="flex w-full space-x-2"
      ref={hasMoreThanOneQuestion ? setNodeRef : null}
      style={style}
      onMouseOver={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <ActionModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onProceed={handleDelete}
        resourceId={question.id}
        isWorking={deleteFormQuestion.isLoading}
        headerText="Delete Question"
        actionText="Delete"
      >
        Are you sure you want to delete this question?
      </ActionModal>

      <div className="w-full bg-white transition-all border border-gray-200 rounded-md p-4 relative group">
        <div className="flex">
          <div className="space-y-4 w-full">
            <div className="flex space-x-1 mb-2 items-center">
              <div
                className={cx(
                  ' text-xs font-regular rounded px-2 py-0.5 w-min whitespace-nowrap ',
                  isFreeFormQuestion ? 'bg-purple-100 text-purple-900' : '',
                  isMultipleChoiceQuestion ? 'bg-blue-100 text-blue-900' : '',
                  isDropdownQuestion ? 'bg-green-100 text-green-900' : '',
                  isLongTextQuestion ? 'bg-red-100 text-blue-900' : ''
                )}
              >
                {isFreeFormQuestion && `Short response`}
                {isMultipleChoiceQuestion && 'Multiple choice'}
                {isDropdownQuestion && 'Dropdown'}
                {isLongTextQuestion && 'Long response'}
              </div>
            </div>

            <div className="w-full">
              <Input
                name="prompt"
                value={promptInput}
                labelText="Prompt"
                placeholder="Question prompt"
                helperText="This is the question that will be asked to the user"
                onBlur={() => {
                  if (promptInput && promptInput !== question.prompt) {
                    updateFormQuestion.mutate({
                      prompt: promptInput,
                      question_type: questionType,
                      custom_field_id: fieldId,
                      required: question.required,
                      multi_select: question.multi_select,
                      show_max_characters: question.show_max_characters,
                      max_character_limit: question.max_character_limit,
                      min_character_limit: question.min_character_limit,
                      order: question.order,
                    });
                  }
                }}
                onChange={(e: any) => {
                  setPromptInput(e.target.value);
                }}
              />
            </div>
            <CustomFieldSelect
              className=""
              labelText="Custom Field"
              customFieldId={fieldId}
              helperText="Select a custom field to associate with this question"
              onSelectCustomField={(idVal: string) => {
                analytics.track('Custom Fields associated with survey questions');
                setFieldId(idVal);
                if (idVal && idVal !== question.custom_field.id) {
                  updateFormQuestion.mutate({
                    prompt: promptInput,
                    question_type: questionType,
                    custom_field_id: idVal,
                    required: question.required,
                    multi_select: question.multi_select,
                    show_max_characters: question.show_max_characters,
                    max_character_limit: question.max_character_limit,
                    min_character_limit: question.min_character_limit,
                    order: question.order,
                  });
                }
              }}
              onClearCustomField={() => {
                setFieldId('');
              }}
            />
            <div className="space-y-2">
              <p className="block text-sm font-medium text-gray-700">Required?</p>
              <Switch
                variant="primary"
                name="required"
                checked={isRequired}
                onChange={(_name: string, value: boolean) => {
                  setIsRequired(value);
                  updateFormQuestion.mutate({
                    prompt: promptInput || '',
                    question_type: questionType,
                    custom_field_id: fieldId,
                    required: value, // only updating this value
                    multi_select: question.multi_select,
                    show_max_characters: question.show_max_characters,
                    max_character_limit: question.max_character_limit,
                    min_character_limit: question.min_character_limit,
                    order: question.order,
                  });
                }}
              />
              <p className="mt-2 text-xs text-gray-500">
                By selecting this option, users will be required to answer this question. However, for best results, we
                advise you use this option sparingly.
              </p>
            </div>
            {isMultipleChoiceQuestion && question.custom_field.kind === 'list' && (
              <div className="space-y-2">
                <p className="block text-sm font-medium text-gray-700">Allow Multiple Responses?</p>
                <Switch
                  variant="primary"
                  name="multi_select"
                  checked={isMultiSelect}
                  onChange={(_name: string, value: boolean) => {
                    setIsMultiSelect(value);
                    updateFormQuestion.mutate({
                      prompt: promptInput || '',
                      question_type: questionType,
                      custom_field_id: fieldId,
                      required: question.required,
                      multi_select: value, // only updating this value
                      show_max_characters: question.show_max_characters,
                      max_character_limit: question.max_character_limit,
                      order: question.order,
                    });
                  }}
                />
                <p className="mt-2 text-xs text-gray-500">Allow subscribers to choose multiple options.</p>
              </div>
            )}
            {isLongTextQuestion && (
              <>
                <div className="space-y-2">
                  <Input
                    name="max_character_limit"
                    value={maxCharacterLimit.toString()}
                    labelText="Maximum Character Limit"
                    helperText="The upper limit available is 400 characters."
                    onBlur={() => {
                      if (maxCharacterLimit && maxCharacterLimit !== question.max_character_limit) {
                        updateFormQuestion.mutate({
                          prompt: promptInput || '',
                          question_type: questionType,
                          custom_field_id: fieldId,
                          required: question.required,
                          show_max_characters: question.show_max_characters,
                          max_character_limit: maxCharacterLimit,
                          order: question.order,
                        });
                      }
                    }}
                    onChange={(e: any) => {
                      setMaxCharacterLimit(e.target.value);
                    }}
                  />
                </div>
                <div className="space-y-2">
                  <p className="block text-sm font-medium text-gray-700">Show Max Characters</p>
                  <Switch
                    variant="primary"
                    name="show_max_characters"
                    checked={isShowMaxCharacters}
                    onChange={(_name: string, value: boolean) => {
                      setShowMaxCharacters(value);
                      updateFormQuestion.mutate({
                        prompt: promptInput || '',
                        question_type: questionType,
                        custom_field_id: fieldId,
                        required: question.required,
                        show_max_characters: value, // only updating this value
                        max_character_limit: question.max_character_limit,
                        min_character_limit: question.min_character_limit,
                        order: question.order,
                      });
                    }}
                  />
                  <p className="mt-2 text-xs text-gray-500">Display the max character limit under the answer.</p>
                </div>
                <div className="space-y-2">
                  <Input
                    name="min_character_limit"
                    value={minCharacterLimit.toString()}
                    labelText="Minimum Character Limit"
                    helperText="Require a minimum number of characters in the answer before allowing the survey to be submitted (optional)"
                    onBlur={() => {
                      if (minCharacterLimit && minCharacterLimit !== question.min_character_limit) {
                        updateFormQuestion.mutate({
                          prompt: promptInput || '',
                          question_type: questionType,
                          custom_field_id: fieldId,
                          required: question.required,
                          show_max_characters: question.show_max_characters,
                          max_character_limit: question.max_character_limit,
                          min_character_limit: minCharacterLimit,
                          order: question.order,
                        });
                      }
                    }}
                    onChange={(e: any) => {
                      setMinCharacterLimit(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className=" flex flex-col items-end justify-between">
            <div className="flex items-center space-x-1">
              {isHovering && (
                <button
                  type="button"
                  className="transition-all absolute -top-4 -right-4 bg-gray-200 h-6 w-6 rounded-full flex justify-center items-center"
                  onClick={() => setIsDeleting(true)}
                >
                  <XMarkIcon className="h-4 w-4 text-gray-700" />
                </button>
              )}
            </div>
          </div>
        </div>
        {(isMultipleChoiceQuestion || isDropdownQuestion) && (
          <div className="pt-4">
            <QuestionOptions question={question} />
          </div>
        )}
        {!isDragActive && (
          <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-200 h-8 w-0.5" />
        )}
        {!isDragActive && isLastQuestion && (
          <AddNewQuestion
            nextQuestionOrder={question.order + 1}
            currentCustomFieldIds={currentCustomFieldIds}
            onAddQuestionClick={onAddQuestionClick}
          />
        )}
      </div>
      {hasMoreThanOneQuestion && (
        <button
          type="button"
          className="bg-gray-100 hover:bg-gray-200 transition-all px-1 rounded"
          {...attributes}
          {...listeners}
        >
          <div className="flex space-x-1 -ml-2">
            <div className="w-6 h-6">
              <svg
                clipRule="evenodd"
                fillRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="opacity-20"
              >
                <path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                <path d="m20 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
              </svg>
            </div>
          </div>
        </button>
      )}
    </div>
  );
};

export default QuestionForm;
