import { useState } from 'react';
import { Card } from '@tremor/react';

import ActionModal from '@/components/ActionModal';
import { Checkbox, Input, SimpleSelect, Switch } from '@/components/Form';
import CurrencyInput from '@/components/Form/CurrencyInput';
import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { PriceInterval } from '@/interfaces/tier';
import Section from '@/routes/settings/_components/Section';
import analytics from '@/utils/analytics';

import { useTierConfigurationContext } from '../../_context/tier_configuration_context';
import useTierFormStep from '../../_hooks/useTierFormStep';

import { CURRENCY_OPTIONS } from './constants';

const PricingSection = () => {
  const {
    currency,
    setCurrency,
    monthlyEnabled,
    setMonthlyEnabled,
    monthlyIntervalDisplay,
    setMonthlyIntervalDisplay,
    monthlyCents,
    setMonthlyCents,
    monthlyCta,
    setMonthlyCta,
    annualEnabled,
    setAnnualEnabled,
    annualIntervalDisplay,
    setAnnualIntervalDisplay,
    annualCents,
    setAnnualCents,
    annualCta,
    setAnnualCta,
    oneTimeEnabled,
    setOneTimeEnabled,
    oneTimeIntervalDisplay,
    setOneTimeIntervalDisplay,
    oneTimeCents,
    setOneTimeCents,
    oneTimeCta,
    setOneTimeCta,
    donationEnabled,
    setDonationEnabled,
    donationIntervalDisplay,
    setDonationIntervalDisplay,
    donationCta,
    setDonationCta,
    canAddActiveTier,
    isActiveTier,
  } = useTierConfigurationContext();
  const { onFocus, onBlur } = useTierFormStep('pricing');
  const { settings } = useSettings();

  const [donationWarningIsOpen, setDonationWarningIsOpen] = useState(false);
  const [tempMonthlyEnabled, setTempMonthlyEnabled] = useState(monthlyEnabled);
  const [tempAnnualEnabled, setTempAnnualEnabled] = useState(annualEnabled);
  const [tempOneTimeEnabled, setTempOneTimeEnabled] = useState(oneTimeEnabled);
  const [tempDonationEnabled, setTempDonationEnabled] = useState(donationEnabled);

  const handleCurrencyChange = (value: string) => {
    setCurrency(value);
    analytics.track('selected a currency');
  };

  const handleMonthlyEnabledChange = (value: boolean) => {
    if (value && donationEnabled) {
      setTempMonthlyEnabled(value);
      setDonationWarningIsOpen(true);
      return;
    }

    setMonthlyEnabled(value);
  };

  const handleMonthlyCentsChange = (value: number) => {
    setMonthlyCents(value);
    analytics.track('set monthly plan price');
  };

  const handleAnnualEnabledChange = (value: boolean) => {
    if (value && donationEnabled) {
      setTempAnnualEnabled(value);
      setDonationWarningIsOpen(true);
      return;
    }

    setAnnualEnabled(value);
  };

  const handleAnnualCentsChange = (value: number) => {
    setAnnualCents(value);
    analytics.track('set annual plan price');
  };

  const handleOneTimeEnabledChange = (value: boolean) => {
    if (value && donationEnabled) {
      setTempOneTimeEnabled(value);
      setDonationWarningIsOpen(true);
      return;
    }

    setOneTimeEnabled(value);
  };

  const handleOneTimeCentsChange = (value: number) => {
    setOneTimeCents(value);
    analytics.track('set one-time plan price');
  };

  const handleSubscriptionToggleChange = (_name: string, checked: boolean) => {
    handleMonthlyEnabledChange(checked);
    handleAnnualEnabledChange(checked);
  };

  const handlePlanChange = (planType: string, isEnabled: boolean) => {
    switch (planType) {
      case PriceInterval.MONTH:
        setMonthlyEnabled(isEnabled);
        setTempMonthlyEnabled(isEnabled);
        break;
      case PriceInterval.YEAR:
        setAnnualEnabled(isEnabled);
        setTempAnnualEnabled(isEnabled);
        break;
      case PriceInterval.ONE_TIME:
        setOneTimeEnabled(isEnabled);
        setTempOneTimeEnabled(isEnabled);
        break;
      case PriceInterval.DONATION:
        setDonationEnabled(isEnabled);
        setTempDonationEnabled(isEnabled);
        break;
      default:
        break;
    }

    analytics.track(`${isEnabled ? 'enabled' : 'disabled'} ${planType} plan`);
  };

  const handleDonationEnabledChange = (_name: string, checked: boolean) => {
    if (checked && (monthlyEnabled || annualEnabled || oneTimeEnabled)) {
      setTempDonationEnabled(checked);
      setDonationWarningIsOpen(true);
      return;
    }

    handlePlanChange(PriceInterval.DONATION, checked);
  };

  const handleConfirmDonationWarning = () => {
    if (tempDonationEnabled !== donationEnabled) {
      if (tempDonationEnabled) {
        if (monthlyEnabled) handlePlanChange(PriceInterval.MONTH, false);
        if (annualEnabled) handlePlanChange(PriceInterval.YEAR, false);
        if (oneTimeEnabled) handlePlanChange(PriceInterval.ONE_TIME, false);

        handlePlanChange(PriceInterval.DONATION, true);
      } else {
        handlePlanChange(PriceInterval.DONATION, false);
      }
    } else {
      if (donationEnabled && (tempMonthlyEnabled || tempAnnualEnabled || tempOneTimeEnabled)) {
        handlePlanChange(PriceInterval.DONATION, false);
      }

      if (tempMonthlyEnabled !== monthlyEnabled) handlePlanChange(PriceInterval.MONTH, tempMonthlyEnabled);
      if (tempAnnualEnabled !== annualEnabled) handlePlanChange(PriceInterval.YEAR, tempAnnualEnabled);
      if (tempOneTimeEnabled !== oneTimeEnabled) handlePlanChange(PriceInterval.ONE_TIME, tempOneTimeEnabled);
    }

    setDonationWarningIsOpen(false);
  };

  const handleDismissDonationWarning = () => {
    setDonationWarningIsOpen(false);
  };

  return (
    <div onFocus={onFocus} onBlur={onBlur}>
      <ActionModal
        isOpen={donationWarningIsOpen}
        onClose={handleDismissDonationWarning}
        onProceed={handleConfirmDonationWarning}
        modalMessageType="warning"
        headerText="Name Your Price Plan warning"
        resourceId="donationWarning"
        actionText="Confirm Changes"
        cancelText="Discard"
        isWorking={false}
      >
        <p className="mt-4 mb-2">
          <Typography token="font-normal/text/base" colorWeight="900">
            {tempDonationEnabled && (monthlyEnabled || annualEnabled || oneTimeEnabled) ? (
              <>
                The Name Your Price plan is exclusive per tier. If you enable the Name Your Price plan, all other plans
                (Monthly, Annual, and One-Time) will be automatically disabled.
              </>
            ) : (
              <>
                The Name Your Price plan is exclusive per tier. If you enable any other payment options (Monthly,
                Annual, and One-Time), the Name Your Price plan will be automatically disabled.
              </>
            )}
          </Typography>
        </p>
      </ActionModal>

      <Section
        title="Tier Plans & Pricing"
        description={
          <>
            Choose{' '}
            <Typography token="font-semibold/text/sm" colorWeight="900">
              at least one
            </Typography>{' '}
            payment plan type to enable for this tier
          </>
        }
        maxWidthClassName="max-w-4xl"
        className="space-y-4"
        titleAction={
          <SimpleSelect
            name="currency"
            value={currency}
            optionsContainerClassNames={{ width: 'w-72' }}
            buttonClassNames={{ background: 'bg-surface-50', padding: 'py-1 pl-3 pr-10' }}
            onSelect={(_name: string, value: string) => {
              handleCurrencyChange(value);
            }}
            options={CURRENCY_OPTIONS}
          />
        }
      >
        <div className="flex flex-col space-y-4">
          <Card className="space-y-4">
            <div className="flex flex-row justify-between">
              <TypographyStack gap="1">
                <Typography token="font-medium/text/base" className="capitalize">
                  Subscription
                </Typography>
                <Typography token="font-light/text/xs">Enable monthly and annual subscription plan options</Typography>
              </TypographyStack>
              <Switch
                name="subscription_enabled"
                size="large"
                variant="primary"
                checked={monthlyEnabled || annualEnabled}
                onChange={handleSubscriptionToggleChange}
                disabled={!isActiveTier && !canAddActiveTier}
              />
            </div>

            {/* MONTHLY */}
            <div className="flex flex-row space-x-4">
              <Checkbox
                name="monthly_subscription_enabled"
                labelText="Monthly Plan"
                onChange={handleMonthlyEnabledChange}
                checked={monthlyEnabled}
                disabled={!isActiveTier && !canAddActiveTier}
              />
            </div>

            <div className="space-y-2 md:space-y-0 md:space-x-1 md:flex flex-row items-start">
              <div className="relative flex-1">
                <CurrencyInput
                  name="monthly_subscription_amount"
                  onChange={handleMonthlyCentsChange}
                  value={monthlyCents}
                  minCents={monthlyEnabled ? 1 : undefined}
                  currency={currency}
                  className="w-full"
                />
                <span className="absolute right-2 inset-y-1.5">
                  <Typography token="font-normal/text/base" colorWeight="400">
                    {currency.toUpperCase()}
                  </Typography>
                </span>
              </div>
              <div className="w-full md:w-fit md:space-x-1 flex flex-row items-center">
                <div className="w-2 text-center hidden md:block">
                  <Typography token="font-normal/text/base" colorWeight="400">
                    /
                  </Typography>
                </div>
                <Input
                  type="text"
                  name="monthly_subscription_interval_display"
                  placeholder="month"
                  value={monthlyIntervalDisplay}
                  onChange={(e) => setMonthlyIntervalDisplay(e.target.value)}
                  labelText=""
                  className="w-full md:max-w-[100px]"
                />
              </div>
            </div>

            <Input
              type="text"
              name="monthly_subscription_cta"
              placeholder="Get Access"
              value={monthlyCta}
              onChange={(e) => setMonthlyCta(e.target.value)}
              labelText="Button Text"
              helperText="When using the external Stripe checkout, this text will be displayed on the button"
            />

            {/* ANNUAL */}
            <Checkbox
              name="annual_subscription_enabled"
              labelText="Annual Plan"
              onChange={handleAnnualEnabledChange}
              checked={annualEnabled}
              disabled={!isActiveTier && !canAddActiveTier}
            />
            <div className="space-y-2 md:space-y-0 md:space-x-1 md:flex flex-row items-start">
              <div className="relative flex-1">
                <CurrencyInput
                  name="annual_subscription_amount"
                  onChange={handleAnnualCentsChange}
                  value={annualCents}
                  minCents={annualEnabled ? 1 : undefined}
                  currency={currency}
                  className="w-full"
                />
                <span className="absolute right-2 inset-y-1.5">
                  <Typography token="font-normal/text/base" colorWeight="400">
                    {currency.toUpperCase()}
                  </Typography>
                </span>
              </div>
              <div className="w-full md:w-fit md:space-x-1 flex flex-row items-center">
                <div className="w-2 text-center hidden md:block">
                  <Typography token="font-normal/text/base" colorWeight="400">
                    /
                  </Typography>
                </div>
                <Input
                  type="text"
                  name="annual_subscription_interval_display"
                  placeholder="year"
                  value={annualIntervalDisplay}
                  onChange={(e) => setAnnualIntervalDisplay(e.target.value)}
                  labelText=""
                  className="w-full md:max-w-[100px]"
                />
              </div>
            </div>

            <Input
              type="text"
              name="annual_subscription_cta"
              placeholder="Get Access"
              value={annualCta}
              onChange={(e) => setAnnualCta(e.target.value)}
              labelText="Button Text"
              helperText="When using the external Stripe checkout, this text will be displayed on the button"
            />
          </Card>

          {/* ONE-TIME */}
          {settings?.one_time_payments && (
            <Card className="space-y-4">
              <div className="flex flex-row justify-between">
                <TypographyStack gap="1">
                  <Typography token="font-medium/text/base" className="capitalize">
                    One time purchase
                  </Typography>
                  <Typography token="font-light/text/xs">Enable and customize a one time payment plan</Typography>
                </TypographyStack>
                <Switch
                  name="one_time_payment_enabled"
                  size="large"
                  variant="primary"
                  checked={oneTimeEnabled}
                  onChange={(name, checked) => handleOneTimeEnabledChange(checked)}
                  disabled={!isActiveTier && !canAddActiveTier}
                />
              </div>
              <div className="space-y-2 md:space-y-0 md:space-x-1 md:flex flex-row items-start">
                <div className="relative flex-1">
                  <CurrencyInput
                    name="one_time_subscription_amount"
                    onChange={handleOneTimeCentsChange}
                    value={oneTimeCents}
                    minCents={oneTimeEnabled ? 1 : undefined}
                    currency={currency}
                    className="w-full"
                  />
                  <span className="absolute right-2 inset-y-1.5">
                    <Typography token="font-normal/text/base" colorWeight="400">
                      {currency.toUpperCase()}
                    </Typography>
                  </span>
                </div>
                <div className="w-full md:w-fit md:space-x-1 flex flex-row items-center">
                  <div className="w-2 text-center hidden md:block">
                    <Typography token="font-normal/text/base" colorWeight="400">
                      /
                    </Typography>
                  </div>
                  <Input
                    type="text"
                    name="one_time_subscription_interval_display"
                    placeholder="once"
                    value={oneTimeIntervalDisplay}
                    onChange={(e) => setOneTimeIntervalDisplay(e.target.value)}
                    labelText=""
                    className="w-full md:max-w-[100px]"
                  />
                </div>
              </div>

              <Input
                type="text"
                name="one_time_payment_subscription_cta"
                placeholder="Get Access"
                value={oneTimeCta}
                onChange={(e) => setOneTimeCta(e.target.value)}
                labelText="Button Text"
                helperText="When using the external Stripe checkout, this text will be displayed on the button"
              />
            </Card>
          )}

          {/* Donation - Name your price */}
          {settings?.donation_payments && (
            <Card className="space-y-4">
              <div className="flex flex-row justify-between">
                <TypographyStack gap="1">
                  <Typography token="font-medium/text/base" className="capitalize">
                    Name your price
                  </Typography>
                  <Typography token="font-light/text/xs">
                    Enable a one time payment where subscribers can specify the price they wish to pay
                  </Typography>
                </TypographyStack>
                <Switch
                  name="donation_payment_enabled"
                  size="large"
                  variant="primary"
                  checked={donationEnabled}
                  onChange={handleDonationEnabledChange}
                  disabled={!isActiveTier && !canAddActiveTier}
                />
              </div>
              <div className="space-y-2 md:space-y-0 md:space-x-1 md:flex flex-row items-start">
                <div className="relative flex-1">
                  <Typography token="font-normal/text/sm" colorWeight="700">
                    Customer chooses the amount, &ldquo;$1.00&rdquo; minimum.
                  </Typography>
                  <span className="absolute right-2 inset-y-1.5">
                    <Typography token="font-normal/text/base" colorWeight="400">
                      {currency.toUpperCase()}
                    </Typography>
                  </span>
                </div>
                <div className="w-full md:w-fit md:space-x-1 flex flex-row items-center">
                  <div className="w-2 text-center hidden md:block">
                    <Typography token="font-normal/text/base" colorWeight="400">
                      /
                    </Typography>
                  </div>
                  <Input
                    type="text"
                    name="donation_subscription_interval_display"
                    placeholder="once"
                    value={donationIntervalDisplay}
                    onChange={(e) => setDonationIntervalDisplay(e.target.value)}
                    labelText=""
                    className="w-full md:max-w-[100px]"
                  />
                </div>
              </div>

              <Input
                type="text"
                name="donation_payment_subscription_cta"
                placeholder="Get Access"
                value={donationCta}
                onChange={(e) => setDonationCta(e.target.value)}
                labelText="Button Text"
                helperText="When using the external Stripe checkout, this text will be displayed on the button"
              />
            </Card>
          )}
        </div>

        {!isActiveTier && !canAddActiveTier && (
          <TypographyRow className="pt-2">
            <Typography token="font-light/text/xs">
              * You cannot enable these prices because you already have the maximum number of allowed active premium
              tiers. Please{' '}
              <a href="/?new_support_ticket=true" target="_blank">
                <Typography token="font-medium/text/xs" color="secondary" colorWeight="600">
                  contact support
                </Typography>
              </a>{' '}
              if you need more tiers.
            </Typography>
          </TypographyRow>
        )}
      </Section>
    </div>
  );
};

export default PricingSection;
