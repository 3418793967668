import { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

import Banner from '@/components/Banner';
import Stepper from '@/components/Form/Stepper';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Typography, TypographyRow } from '@/components/Typography';
import { UpgradeIntent } from '@/components/UpgradeIntent';
import { useSettings } from '@/context/settings-context';
import {
  useCreateSplitTest,
  useCustomDomains,
  useNewSplitTestOption,
  useUpdateSplitTest,
} from '@/hooks';
import { SplitTestPayload } from '@/hooks/useSplitTest/useUpdateSplitTest';
import { CustomDomain } from '@/interfaces/custom_domain';
import { Post, PostStatus } from '@/interfaces/post';
import { SplitTestOption } from '@/interfaces/split_test_option';
import { IntentAction } from '@/interfaces/upgrades';
import { PLAN } from '@/utils/plans';

import OptionForm from './OptionForm';

export enum DEFAULTS {
  MIN_VARIANTS = 1,
  MIN_SAMPLE_SIZE_PER_VARIANT = 200,
  MIN_DURATION = 300, // 5 minutes
  MAX_DURATION = 14_400, // 4 hours
  SUGGESTED_DURATION = 10_800, // 3 hours
  SUGGESTED_SAMPLE_PCT = 10,
  MIN_SAMPLE_PCT = 1,
  MAX_SAMPLE_PCT = 100,
}

interface Props {
  post: Post;
  splitTesting: boolean;
  splitTestObject: any;
  audienceSizeWarningDisplayed: boolean;
  setParentSplitTestOptions: (options: SplitTestOption[]) => void;
  setSplitTesting: (splitTesting: boolean) => void;
  children: any;
}

const SplitTestForm = ({ post, splitTesting, splitTestObject, audienceSizeWarningDisplayed, setParentSplitTestOptions, setSplitTesting, children }: Props) => {
  const { settings } = useSettings();
  const maxVariants = (settings?.max_split_test_options || 1) - 1; // Subtract 1 for the control.
  const { data: customDomains } = useCustomDomains();
  const isEmailDomainWarmingUp = customDomains?.find((d: CustomDomain) => d.email_enabled)?.warming_up;
  const globalSplitTestingEnabled = String(window.env.REACT_APP_SPLIT_TESTING_ENABLED) === 'true';
  const organizationSplitTestingEnabled = maxVariants >= DEFAULTS.MIN_VARIANTS;
  const [splitTestOptions, setSplitTestOptions] = useState<SplitTestOption[]>([]);
  const [newOption, setNewOption] = useState({} as SplitTestOption);
  const [splitTestError, setSplitTestError] = useState('');
  const [splitTestUpgradeIsOpen, setSplitTestUpgradeIsOpen] = useState(false);
  const [customizeSplitTestUpgradeIsOpen, setCustomizeSplitTestUpgradeIsOpen] = useState(false);
  const [splitTestDuration, setSplitTestDuration] = useState(DEFAULTS.SUGGESTED_DURATION);
  const [splitTestSamplePct, setSplitTestSamplePct] = useState(DEFAULTS.SUGGESTED_SAMPLE_PCT);
  const [showDurationWarning, setShowDurationWarning] = useState(false);
  const [showSamplePctWarning, setShowSamplePctWarning] = useState(false);
  const locked = post.status !== PostStatus.DRAFT;
  const calcOptions = () => {
    let optionCount = splitTestOptions.length;
    if (newOption?.split_test_id) {
      optionCount += 1;
    }

    return optionCount < DEFAULTS.MIN_VARIANTS ? DEFAULTS.MIN_VARIANTS : optionCount;
  };

  const warningMessage = () => {
    if (showDurationWarning && showSamplePctWarning && !audienceSizeWarningDisplayed) {
      return 'Test length and sample size may be too small to get statistically significant results';
    }
    if (showDurationWarning) {
      return 'Test length may be too small to get statistically significant results';
    }

    if (showSamplePctWarning) {
      return 'Sample size may be too small to get statistically significant results';
    }

    return '';
  }

  const createSplitTestMutation = useCreateSplitTest(
    post.id,
    () => {
      setSplitTestError('');
    },
    () => {}
  );

  // New, but not saved.
  const newSplitTestOptionMutation = useNewSplitTestOption(post.id, (data: any) => {
    setNewOption(data);
  });

  const updateSplitTestMutation = useUpdateSplitTest(
    post.id,
    () => {
      setSplitTestError('');
    },
    () => {},
    () => {

    }
  );

  const handleSplitTestUpdate = (updateValue: SplitTestPayload) => {
    if (settings?.split_test_customization) {
      if (splitTesting) {
        updateSplitTestMutation.mutate(updateValue);
      }
    } else {
      setCustomizeSplitTestUpgradeIsOpen(true);
    }
  };

  const handleAddOption = () => {
    if (splitTestOptions.length < maxVariants) {
      newSplitTestOptionMutation.mutate();
    }
  };

  const handleUpsellClose = () => {
    setSplitTesting(false);
    setSplitTestUpgradeIsOpen(false);
  };

  useEffect(() => {
    if (splitTestObject) {
      setSplitTestDuration(splitTestObject.duration);
      setSplitTestSamplePct(splitTestObject.sample_pct);
    }
    if (splitTestObject?.id) {
      setSplitTestOptions(splitTestObject.split_test_options);
      if (setParentSplitTestOptions) setParentSplitTestOptions(splitTestObject.split_test_options);
      if (splitTestObject.split_test_options.length < DEFAULTS.MIN_VARIANTS) newSplitTestOptionMutation.mutate();
    } else {
      setSplitTestOptions([]);
      if (setParentSplitTestOptions) setParentSplitTestOptions([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitTestObject]);

  const actionsHtml = () => {
    if (!splitTesting || createSplitTestMutation.isLoading) {
      return null;
    }

    return (
      <div className="flex flex-row my-4">
        {!locked && !newOption?.split_test_id && splitTestOptions.length < maxVariants && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div className="flex items-center text-primary-500 cursor-pointer" onClick={handleAddOption}>
            <PlusIcon className="h-4 w-4 border-gray-300 rounded-sm cursor-pointer" />
            <span className="ml-2 cursor-pointer text-sm font-medium">Add new subject line</span>
          </div>
        )}
      </div>
    );
  };

  const optionsLabel = (index: number) => {
    switch (index) {
      case 0:
        return 'B';
      case 1:
        return 'C';
      case 2:
        return 'D';
      case 3:
        return 'E';
      default:
        return 'Subject Line';
    }
  };

  const optionsHtml = () => {
    if (!splitTesting) {
      return null;
    }
    if (createSplitTestMutation.isLoading) {
      return (
        <div className="flex items-center">
          <LoadingSpinner className="mr-2" />
          <p className="text-gray-400 text-sm">Loading...</p>
        </div>
      );
    }
    return (
      <div className="space-y-3 mt-3">
        {splitTestOptions.map((option, index) => {
          if (option.control && !locked) {
            // Since we're now creating the control option when the split test begins,
            // this should never happen, but just in case.
            return null;
          }
          return (
            <OptionForm
              key={option.id}
              labelText={optionsLabel(index)}
              postId={post.id}
              splitTestOption={option}
              deletable={splitTestOptions.length > DEFAULTS.MIN_VARIANTS}
              locked={locked}
            />
          );
        })}
        {/* If the user requested a new option, we render it specially. */}
        {newOption?.split_test_id && (
          <OptionForm
            key={newOption.id}
            labelText={optionsLabel(splitTestOptions.length)}
            postId={post.id}
            splitTestOption={newOption}
            deletable
            onSave={() => {
              setNewOption({} as SplitTestOption);
            }}
            onDelete={() => {
              setNewOption({} as SplitTestOption);
            }}
          />
        )}
      </div>
    );
  };

  const handleDurationChange = (name: string, value: number) => {
    setSplitTestDuration(value * 60);
    handleSplitTestUpdate({ duration: value * 60 });

    if (value * 60 < DEFAULTS.SUGGESTED_DURATION) {
      setShowDurationWarning(true);
    } else {
      setShowDurationWarning(false);
    }
  };

  const handleSamplePctChange = (name: string, value: number) => {
    setSplitTestSamplePct(value);
    handleSplitTestUpdate({ sample_pct: value });

    const perVariant = ((splitTestObject?.population_size || 0) * (splitTestSamplePct / 100)) / calcOptions();
    if ((perVariant < DEFAULTS.MIN_SAMPLE_SIZE_PER_VARIANT) && !audienceSizeWarningDisplayed) {
      setShowSamplePctWarning(true);
    } else {
      setShowSamplePctWarning(false);
    }
  };

  if (!globalSplitTestingEnabled && !settings?.split_test_override_enabled) {
    return null;
  }

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          {isEmailDomainWarmingUp && (
            <p className="mt-2 text-xs text-gray-500">
              A/B testing is unavailable until your email domain has finished Smart Warming.
            </p>
          )}
          {locked && <p className="mt-2 text-xs text-gray-500">You can no longer change the A/B Test</p>}
          {createSplitTestMutation.isError && <p className="mt-2 text-xs text-red-500">{splitTestError}</p>}
        </div>
      </div>
      <div className="flex flex-row">
        {organizationSplitTestingEnabled && splitTesting && !createSplitTestMutation.isError && (
          <div className="px-4 pt-4 pb-2 rounded-lg w-full">
            {children}
            {optionsHtml()}

            {actionsHtml()}
            {
              splitTesting &&
              <div className="flex flex-row">
                <TypographyRow gap="3">
                  <Typography token="font-medium/text/sm" className='text-action-primary-500'>A/B test for</Typography>
                  <Stepper
                    name="split-test-duration"
                    suffix='min'
                    min={DEFAULTS.MIN_DURATION / 60}
                    max={DEFAULTS.MAX_DURATION / 60}
                    value={splitTestDuration / 60}
                    onChange={handleDurationChange}
                    warning={showDurationWarning}
                  />
                  <Typography token="font-medium/text/sm" className='text-action-primary-500'>with sample size</Typography>
                  <Stepper
                    name="split-test-percent"
                    suffix='%'
                    min={DEFAULTS.MIN_SAMPLE_PCT}
                    max={DEFAULTS.MAX_SAMPLE_PCT}
                    value={splitTestSamplePct}
                    onChange={handleSamplePctChange}
                    warning={showSamplePctWarning}
                  />
                  <Typography token="font-medium/text/sm" className='text-action-primary-500'>({splitTestObject?.population_size}) subscribers</Typography>
                </TypographyRow>
              </div>
            }
            {
              (showDurationWarning || (showSamplePctWarning)) && (
                <Banner
                  variant='warning'
                  title={warningMessage()}
                  isScreenWide={false}
                  className='mt-6'
                />
              )
            }
          </div>
        )}
      </div>
      <UpgradeIntent
        isOpen={splitTestUpgradeIsOpen}
        intentAction={IntentAction.USE_SPLIT_TEST}
        onClose={handleUpsellClose}
        preselectedPlan={PLAN.GROW}
      />
      <UpgradeIntent
        isOpen={customizeSplitTestUpgradeIsOpen}
        intentAction={IntentAction.USE_SPLIT_TEST_CUSTOMIZATION}
        onClose={() => {
          setCustomizeSplitTestUpgradeIsOpen(false);
        }}
        preselectedPlan={PLAN.SCALE}
      />
    </>
  );
};

export default SplitTestForm;
