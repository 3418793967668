import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { WebhookEndpointCreatePayload } from '@/interfaces/webhooks/endpoint';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  onSuccess?: (response: any) => void;
  onError?: (err: any) => void;
}

const useCreateSvixEndpoint = ({ onSuccess, onError }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const createSvixEndpoint = ({ description, disabled, event_types, url }: WebhookEndpointCreatePayload) =>
    api
      .post('/webhooks/svix_endpoints', {
        publication_id: currentPublicationId,
        endpoint: {
          description,
          disabled,
          event_types,
          url,
        },
      })
      .then((res) => res.data);

  return useMutation(createSvixEndpoint, {
    onSuccess: (response) => {
      onSuccess?.(response);
    },
    onError: (err: any) => {
      if (onError) {
        onError(err);
      } else {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      }
    },
  });
};

export default useCreateSvixEndpoint;
