import { FC, Fragment, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { Menu, Transition } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { FilterDropdownOption } from '../../interfaces/general';

interface Props {
  label?: string;
  options?: FilterDropdownOption[];
  selection?: string;
  portalMountedId?: string;
  children?: React.ReactNode;
}

const FilterDropdown: FC<Props> = (props: Props) => {
  const popperElRef = useRef(null);
  const targetElement = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement?.current, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [14, 8],
        },
      },
    ],
  });

  const { options, label, selection, portalMountedId, children } = props;

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div className="flex items-center" ref={targetElement}>
            <Menu.Button>
              {children ?? (
                <div className="flex flex-row content-center items-center">
                  {!!label && <span className="text-xs font-semibold text-gray-500 mr-2">{label}</span>}
                  <div className="bg-white border border-gray-100 rounded-full h-6 w-6 flex items-center justify-center text-gray-400 hover:text-gray-800 focus:outline-none duration-200">
                    <FunnelIcon className="w-3 h-3" aria-hidden="true" />
                  </div>
                </div>
              )}
            </Menu.Button>
          </div>

          <Portal node={portalMountedId && document ? document.getElementById(portalMountedId) : null}>
            <div ref={popperElRef} className="z-10" style={styles.popper} {...attributes.popper}>
              <Transition
                as={Fragment}
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Menu.Items className="origin-top-right z-10 absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {options?.map((option) => (
                      <Menu.Item key={option.label}>
                        {({ active }) => (
                          <button
                            type="button"
                            key={option.label}
                            onClick={() => option.onClick()}
                            className={cx(
                              active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                              selection && selection.toLowerCase() === option.label.toLowerCase()
                                ? 'bg-gray-100 text-gray-900'
                                : '',
                              'block px-4 py-2 text-sm text-left w-full'
                            )}
                          >
                            {option.label}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </Menu>
  );
};

FilterDropdown.defaultProps = {
  options: [],
};

export default FilterDropdown;
