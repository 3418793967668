import { MouseEventHandler, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/outline';

import { Switch } from '@/components/Form';
import { LineDivider } from '@/components/LineDivider';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { AutomationConditions } from '@/interfaces/automations/automation_condition';
import {
  AutomationTrigger,
  AutomationTriggerEvent,
  AutomationTriggerState,
} from '@/interfaces/automations/automation_trigger';
import { Button } from '@/ui/Button';

import ConditionsLabel from '../components/ConditionsLabel';
import useTrigger from '../hooks/useTrigger';
import { ApiTrigger } from '../Nodes/Triggers/ApiTrigger';
import { DowngradeTrigger } from '../Nodes/Triggers/DowngradeTrigger';
import { EmailSubmissionTrigger } from '../Nodes/Triggers/EmailSubmissionTrigger';
import { FormSubmissionTrigger } from '../Nodes/Triggers/FormSubmissionTrigger';
import NewTriggerNode from '../Nodes/Triggers/NewTriggerNode';
import { PollSubmissionTrigger } from '../Nodes/Triggers/PollSubmissionTrigger';
import { ReferralActionTrigger } from '../Nodes/Triggers/ReferralActionTrigger';
import { SegmentActionTrigger } from '../Nodes/Triggers/SegmentActionTrigger';
import { UnengagedTrigger } from '../Nodes/Triggers/UnengagedTrigger';
import { UpgradeTrigger } from '../Nodes/Triggers/UpgradeTrigger';

import ConfirmModal from './ConfirmModal';

interface Props {
  index: number;
  trigger: AutomationTrigger;
  onChange: (trigger: AutomationTrigger) => void;
}

const ConfigureTrigger = ({ index, trigger, onChange }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const handleChangeInMainConditions = (mainConditions: AutomationConditions) => {
    onChange({
      ...trigger,
      main_conditions: mainConditions,
    });
  };
  const { updateAutomationTrigger, deleteAutomationTrigger } = useTrigger();

  const [currentStatus, setCurrentStatus] = useState(trigger.state);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleClickDelete = () => setShowConfirmDeleteModal(true);
  const handleCancelDelete = () => setShowConfirmDeleteModal(false);

  const handleConfirmDelete = async () => {
    try {
      await deleteAutomationTrigger(trigger.id);
      queryClient.invalidateQueries(['automations', 'automation_triggers', trigger.automation_id]);
      setShowConfirmDeleteModal(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Could not delete the trigger, please try again');
    }
  };

  const handleClickEditConditions: MouseEventHandler<HTMLButtonElement> = () => {
    navigate(`/automations/${trigger.automation_id}/workflow/triggers/${trigger.id}/configure`);
  };

  const isTriggerActive = currentStatus === AutomationTriggerState.PUBLISHED;

  const handleCancelStatusChange = () => {
    setShowConfirmModal(false);
    setCurrentStatus(trigger.state);
  };

  const handleConfirmStatusChange = async () => {
    try {
      await updateAutomationTrigger(trigger.id, {
        ...trigger,
        state: currentStatus,
      });
      queryClient.invalidateQueries(['automations', 'automation_triggers', trigger.automation_id]);
      onChange({
        ...trigger,
        state: currentStatus,
      });
      setShowConfirmModal(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
      setCurrentStatus(trigger.state);
    }
  };

  const handleSwitchChange = (name: string, checked: boolean) => {
    setCurrentStatus(checked ? AutomationTriggerState.PUBLISHED : AutomationTriggerState.DRAFT);
    setShowConfirmModal(true);
  };

  const hasConditions = trigger.conditions?.conditions?.length && trigger.conditions?.conditions?.length > 0;

  const confirmModalHeaderText =
    trigger.state === AutomationTriggerState.DRAFT ? 'Activate Automation Trigger' : 'Deactivate Automation Trigger';

  return (
    <>
      <ConfirmModal
        headerText={confirmModalHeaderText}
        onCancel={handleCancelStatusChange}
        onConfirm={handleConfirmStatusChange}
        isOpen={showConfirmModal}
        isSaving={false}
      >
        {isTriggerActive ? (
          <TypographyStack gap="4" className="text-gray-800">
            <Typography>
              Activating the trigger for this automation flow means that subscribers who meet the trigger conditions
              will start to move through the flow. Please ensure that your automation steps are correct and active.
            </Typography>
            {trigger.event === AutomationTriggerEvent.UNENGAGED && (
              <Typography>
                <b>Double check</b> that your flow has the correct wait times and conditions if you&apos;re
                transitioning subscription statuses to Inactive.
              </Typography>
            )}
            <Typography>Are you sure you want to activate this trigger?</Typography>
          </TypographyStack>
        ) : (
          <TypographyStack gap="4" className="text-gray-800">
            <Typography>
              Subscribers will stop entering this flow automatically through this trigger. Subscribers currently in the
              flow will continue to move through the flow. You can reactivate this trigger at any time.
            </Typography>
            <Typography>Are you sure you want to deactivate this trigger?</Typography>
          </TypographyStack>
        )}
      </ConfirmModal>

      <ConfirmModal
        headerText="Delete Automation Trigger"
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        isOpen={showConfirmDeleteModal}
        isSaving={false}
      >
        <TypographyStack gap="4" className="text-gray-800">
          <Typography>
            This will permanently remove this trigger from this automation flow and subscribers will stop entering this
            flow automatically through this trigger. Subscribers currently in the flow will continue to move through the
            flow.
          </Typography>
          <Typography>Are you sure you want to delete this trigger?</Typography>
        </TypographyStack>
      </ConfirmModal>

      <div className="flex flex-col gap-y-4">
        <div className="flex justify-between">
          <NewTriggerNode
            index={index}
            event={trigger.event}
            mainConditions={trigger.main_conditions}
            isActive
            hideConditionsCount
            hideDescription
          />

          <div className="flex items-center gap-x-1">
            <Switch
              name="activate-automation-step"
              onChange={handleSwitchChange}
              checked={isTriggerActive}
              variant="primary"
              size="small"
              prefixLabelText="Activate"
            />
            <LineDivider direction="vertical" className="mx-2" />
            <button type="button" onClick={handleClickDelete} className="text-gray-400 hover:text-gray-600 p-1">
              <TrashIcon width={16} height={16} />
            </button>
          </div>
        </div>

        {trigger.event === AutomationTriggerEvent.API && (
          <ApiTrigger publicationId={currentPublicationId} automationId={trigger.automation_id} />
        )}
        {trigger.event === AutomationTriggerEvent.DOWNGRADE && (
          <DowngradeTrigger mainConditions={trigger.main_conditions} onChange={handleChangeInMainConditions} />
        )}
        {trigger.event === AutomationTriggerEvent.EMAIL_SUBMISSION && (
          <EmailSubmissionTrigger
            publicationId={currentPublicationId}
            mainConditions={trigger.main_conditions}
            onChange={handleChangeInMainConditions}
          />
        )}
        {trigger.event === AutomationTriggerEvent.FORM_SUBMISSION && (
          <FormSubmissionTrigger mainConditions={trigger.main_conditions} onChange={handleChangeInMainConditions} />
        )}
        {trigger.event === AutomationTriggerEvent.POLL_SUBMISSION && (
          <PollSubmissionTrigger mainConditions={trigger.main_conditions} onChange={handleChangeInMainConditions} />
        )}
        {trigger.event === AutomationTriggerEvent.REFERRAL_ACTION && (
          <ReferralActionTrigger mainConditions={trigger.main_conditions} onChange={handleChangeInMainConditions} />
        )}
        {trigger.event === AutomationTriggerEvent.SEGMENT_ACTION && (
          <SegmentActionTrigger mainConditions={trigger.main_conditions} onChange={handleChangeInMainConditions} />
        )}
        {trigger.event === AutomationTriggerEvent.UNENGAGED && (
          <UnengagedTrigger mainConditions={trigger.main_conditions} onChange={handleChangeInMainConditions} />
        )}
        {trigger.event === AutomationTriggerEvent.UPGRADE && (
          <UpgradeTrigger mainConditions={trigger.main_conditions} onChange={handleChangeInMainConditions} />
        )}

        <div className="flex gap-x-2 items-center">
          {hasConditions ? <ConditionsLabel count={trigger.conditions.conditions?.length || 0} /> : null}
          <Button type="button" variant="primary-inverse" size="xs" onClick={handleClickEditConditions} Icon={PlusIcon}>
            {hasConditions ? 'Edit Conditions' : 'Add Conditions'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConfigureTrigger;
