import { useState } from 'react';

import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import { useThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelDivider, PanelFooter, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { Author } from '@/interfaces/author';
import { Post } from '@/interfaces/post';

import AddAuthorMenu from './AddAuthorMenu';
import AddGuestAuthorMenu from './AddGuestAuthorMenu';
import VisibilitySettings from './VisibilitySettings';

interface Props {
  guestAuthors: Author[];
  users: Author[];
  post: Post;
  updatePost: (data: Post) => void;
  smallVersion?: boolean;
  setShowCreateGuestAuthorModal: (value: boolean) => void;
}

const AuthorsActionsMenu = ({
  post,
  updatePost,
  users,
  guestAuthors,
  smallVersion,
  setShowCreateGuestAuthorModal,
}: Props) => {
  const [showVisibilitySettings, setShowVisibilitySettings] = useState(false);
  const [showAddAuthorMenu, setShowAddAuthorMenu] = useState(false);
  const [showAddGuestAuthorMenu, setShowAddGuestAuthorMenu] = useState(false);

  const { openComposer } = useThreadComposer();

  const handleOpenComposer = () => {
    openComposer();
  };

  if (showAddAuthorMenu) {
    return (
      <AddAuthorMenu onBack={() => setShowAddAuthorMenu(false)} users={users} post={post} updatePost={updatePost} />
    );
  }

  if (showAddGuestAuthorMenu && setShowCreateGuestAuthorModal) {
    return (
      <AddGuestAuthorMenu
        onBack={() => setShowAddGuestAuthorMenu(false)}
        users={guestAuthors}
        post={post}
        updatePost={updatePost}
        setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
      />
    );
  }

  if (showVisibilitySettings) {
    return <VisibilitySettings onBack={() => setShowVisibilitySettings(false)} post={post} updatePost={updatePost} />;
  }

  return (
    <Styled.ContentWrapper>
      <Panel tabIndex={-1}>
        <PanelSection>
          <Styled.MenuButtonWrapper>
            {!smallVersion && (
              <>
                <Button
                  $variant="quaternary"
                  $size="small"
                  onClick={handleOpenComposer}
                  $fullWidth
                  $leftSlot={<Icon name="Comment" />}
                  $muted
                >
                  Comment
                </Button>
                <PanelDivider />
              </>
            )}
            <Button
              $variant="quaternary"
              $size="small"
              onClick={() => {
                setShowAddAuthorMenu(true);
              }}
              $fullWidth
              $leftSlot={<Icon name="Person" className="mr-2" />}
              $rightSlot={<Icon name="ChevronRight" />}
            >
              Add author
            </Button>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={() => {
                setShowAddGuestAuthorMenu(true);
              }}
              $fullWidth
              $leftSlot={<Icon name="Person" className="mr-2" />}
              $rightSlot={<Icon name="ChevronRight" />}
            >
              Add guest author
            </Button>
            {!smallVersion && (
              <>
                <PanelDivider />
                <Button
                  $variant="quaternary"
                  $size="small"
                  onClick={() => {
                    setShowVisibilitySettings(true);
                  }}
                  $fullWidth
                  $leftSlot={<Icon name="Show" />}
                  $rightSlot={<Icon name="ChevronRight" />}
                >
                  Visibility
                </Button>
              </>
            )}
            <PanelDivider />
            <Button
              $variant="quaternary"
              $size="small"
              onClick={() => {
                window.location.href = `${window.location.origin}/website_builder/settings/static_pages`;
              }}
              $fullWidth
              $rightSlot={
                <span className="ml-auto mr-0 mb-[-.2rem]">
                  <Icon name="External" />
                </span>
              }
            >
              <span className="w-full">Manage authors page</span>
            </Button>
            {!smallVersion && (
              <PanelFooter>
                <Button
                  $variant="quaternary"
                  $size="small"
                  onClick={() => {
                    updatePost({ ...post, user_ids: [], guest_author_ids: [] });
                  }}
                  $fullWidth
                  $leftSlot={<Icon name="Trash" />}
                  $muted
                >
                  Delete
                </Button>
              </PanelFooter>
            )}
          </Styled.MenuButtonWrapper>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default AuthorsActionsMenu;
