import LoadingBox from '@/components/LoadingBox';

import Section from '../../../_components/Section';
import { useBillingSummary } from '../../_hooks';

import InvoiceCard from './InvoiceCard';

const BillingSummary: React.FC<{ organizationId: string }> = ({ organizationId }) => {
  const { data, isLoading, isError } = useBillingSummary({ organizationId });

  const { last_invoice: lastInvoice, next_invoice: nextInvoice } = data || {};

  return (
    <Section title="Billing Summary" className="max-w-3xl">
      <LoadingBox
        isLoading={isLoading}
        isError={isError}
        backgroundClassName="bg-transparent"
        errorText="Something went wrong."
        height={150}
      >
        {data && (
          <div className="space-y-4 md:space-y-0 md:flex md:space-x-6">
            <InvoiceCard
              title="Last Payment"
              timestamp={lastInvoice?.timestamp}
              amount={lastInvoice?.amount}
              interval={lastInvoice?.cadence}
            />
            <InvoiceCard
              title="Next Payment Due"
              timestamp={nextInvoice?.timestamp}
              amount={nextInvoice?.amount}
              interval={nextInvoice?.cadence}
            />
          </div>
        )}
      </LoadingBox>
    </Section>
  );
};

export default BillingSummary;
