import { PropsWithChildren, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Typography } from '@/components/Typography';

type Paths =
  | 'analytics'
  | 'seo'
  | 'social_links'
  | 'email_capture'
  | 'signup_flow'
  | 'boosts'
  | 'comments'
  | 'security';

type Props = PropsWithChildren<{
  title: string;
  to: Paths;
  icon: JSX.Element;
}>;

const SectionEditNavigator = ({ title, to, icon }: Props) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isCurrentPath = `/website_builder/settings/${to}` === pathname;

  const handleNavigate = () => {
    navigate(`/website_builder/settings/${to}`);
  };

  return (
    <button
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      type="button"
      onClick={handleNavigate}
      className={cx(
        'py-2 px-3 flex flex-row justify-between items-between space-x-3 bg-surface-50 border rounded-lg border-surface-200',
        (hovered || isCurrentPath) && 'border-surface-400'
      )}
    >
      <Typography token="font-semibold/text/sm" className="text-gray-700 my-auto">
        {title}
      </Typography>
      <div
        className={cx(
          'p-2 bg-surface-100 rounded border',
          isCurrentPath || hovered ? 'bg-white border-surface-400' : 'border-transparent'
        )}
      >
        {icon}
      </div>
    </button>
  );
};

export default SectionEditNavigator;
