import Badge from '../../components/Badge';
import { PostStatus } from '../../interfaces/post';

export interface StatusBadgeProps {
  status: PostStatus;
}

const STATUS_DICTIONARY: {
  [key in PostStatus]: {
    badgeType: 'success' | 'alert' | 'warning' | 'information';
  };
} = {
  draft: {
    badgeType: 'information',
  },
  scheduled: {
    badgeType: 'warning',
  },
  published: {
    badgeType: 'success',
  },
  archived: {
    badgeType: 'alert',
  },
};

const StatusBadge = ({ status }: StatusBadgeProps) => {
  const { badgeType } = STATUS_DICTIONARY[status];

  return (
    <Badge type={badgeType}>
      <span className="capitalize text-xs">{status}</span>
    </Badge>
  );
};

export default StatusBadge;
