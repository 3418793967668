import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  keyId: string | undefined;
}

const useDeleteApiKey = ({ keyId }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    publication_id: currentPublicationId,
  };

  return useMutation(() => api.delete(`/api_keys/${keyId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['api_keys', currentPublicationId]);
      toast.success('Key removed!');
    },
  });
};

export default useDeleteApiKey;
