import { SparklesIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import Grid from '@/components/Grid';
import { Typography, TypographyRow } from '@/components/Typography';

interface UpgradeOptionProps {
  planName: string;
  onClick: () => void;
  selected: boolean;
  upgradeOptions: string[];
}

const UpgradeOption: React.FC<UpgradeOptionProps> = ({ planName, selected, onClick, upgradeOptions }) => {
  return (
    <button type="button" className="w-full" onClick={onClick}>
      <div className={cx(selected ? 'border-surface-500' : 'border-surface-200', 'rounded border')}>
        <TypographyRow className="bg-surface-50 rounded-t py-4 px-6 border-b border-surface-200 justify-between">
          <Typography token="font-semibold/text/base">Upgrade and unlock all features</Typography>
          <div className="p-4 bg-action-tertiary-100 rounded">
            <SparklesIcon className="text-action-tertiary-500 w-6 h-6" />
          </div>
        </TypographyRow>
        <Grid gap={3} columns={2} rows={2} className="px-6 py-4 auto-rows-fr items-center">
          {upgradeOptions.map((option) => (
            <TypographyRow key={option}>
              <Typography token="font-normal/text/sm" colorWeight="700">
                <span className="text-action-tertiary-600">&#10003;</span> {option}
              </Typography>
            </TypographyRow>
          ))}
          <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
            <div className="flex flex-row gap-1">
              <span className="text-action-tertiary-600">&#10003;</span>
              <span>Sending limits lifted based on tier</span>
            </div>
          </Typography>
          <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
            <span className="text-action-tertiary-600">&#10003;</span> All other {planName} features
          </Typography>
        </Grid>
      </div>
    </button>
  );
};

export default UpgradeOption;
