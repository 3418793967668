import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useUpdateEmailMessage } from '@/hooks';
import { EmailMessage } from '@/interfaces/email_message';

const useUpdateWelcomeEmail = (welcomeEmail: EmailMessage) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useUpdateEmailMessage({
    emailMessageId: welcomeEmail.id,
    onSuccess: () => {
      queryClient.invalidateQueries(['welcome_email', currentPublicationId], { exact: true });
      queryClient.invalidateQueries(['email_messages', 'preview', welcomeEmail?.id], { exact: true });
      toast.success('Welcome Email saved');
    },
    onError: (err: any) => toast.error(err?.response?.data?.error || 'Something went wrong, please try again'),
  });
};

export default useUpdateWelcomeEmail;
