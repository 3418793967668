import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useSettings } from '../../../../context/settings-context';
import { SettingsPageLayout } from '../../Components';

const Website = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { settings } = useSettings();
  const isCommentsEnabled = settings?.comments;

  if (settings?.web_builder) {
    return <Navigate to="/website_builder" />;
  }

  const sideTabs = [
    {
      enabled: true,
      name: 'general',
      label: 'General',
      selected: location.pathname === '/settings/website',
      onSelect: () => navigate('/settings/website'),
    },
    {
      enabled: true,
      name: 'analytics',
      label: 'Analytics',
      selected: location.pathname === '/settings/website/analytics',
      onSelect: () => navigate('/settings/website/analytics'),
    },
    {
      enabled: true,
      name: 'advanced',
      label: 'Advanced',
      selected: location.pathname === '/settings/website/advanced',
      onSelect: () => navigate('/settings/website/advanced'),
    },
    {
      enabled: true,
      name: 'pages',
      label: 'Pages',
      selected: location.pathname === '/settings/website/pages',
      onSelect: () => navigate('/settings/website/pages'),
    },
    {
      enabled: true,
      name: 'navbar',
      label: 'Navbar',
      selected: location.pathname === '/settings/website/navbar',
      onSelect: () => navigate('/settings/website/navbar'),
    },
    {
      enabled: isCommentsEnabled,
      name: 'comments',
      label: 'Comments',
      selected: location.pathname === '/settings/website/comments',
      onSelect: () => navigate('/settings/website/comments'),
    },
  ];

  const filteredSideTabs = sideTabs.filter((tab) => tab.enabled);

  return (
    <SettingsPageLayout title="Website" sideTabs={filteredSideTabs}>
      <Outlet />
    </SettingsPageLayout>
  );
};

export default Website;
