import Badge from '@/components/Badge';
import { SubscriptionStatus, SubscriptionStatuses } from '@/interfaces/subscription';

const BADGE_COLORS: any = {
  active: 'success',
  inactive: 'alert',
  pending: undefined,
  needs_attention: 'warning',
};

const StatusBadge = ({ status }: { status: SubscriptionStatus }) => (
  <Badge type={BADGE_COLORS[status]} className="capitalize">
    {SubscriptionStatuses[status]}
  </Badge>
);
export default StatusBadge;
