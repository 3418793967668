import { Editor, Extension } from '@tiptap/core';
import { PluginKey } from '@tiptap/pm/state';
import { ReactRenderer } from '@tiptap/react';
import Suggestion, { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion';
import fuzzysort from 'fuzzysort';
import tippy from 'tippy.js';

import { GROUPS_WITH_COMMANDS } from './groups';
import { MenuList } from './MenuList';
import { Command } from './types';

const extensionName = 'slashCommand';

let popup: any;

export const SlashCommand = Extension.create({
  name: extensionName,

  priority: 200,

  addOptions() {
    return {
      allowPolls: false,
      allowAds: false,
      settings: {},
      onToggleUpgradeIntentModal: () => null,
    };
  },

  onCreate() {
    popup = tippy('body', {
      interactive: true,
      trigger: 'manual',
      placement: 'bottom-start',
      theme: 'slash-command',
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            enabled: false,
          },
        ],
      },
    });
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        char: '/',
        allowSpaces: true,
        startOfLine: true,
        pluginKey: new PluginKey(extensionName),
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const isRootDepth = $from.depth === 1;
          const isParagraph = $from.parent.type.name === 'paragraph';
          const isStartOfNode = $from.parent.textContent?.charAt(0) === '/';
          const isInSection = this.editor.isActive('section');
          const isInColumn = this.editor.isActive('column');

          return (
            (isRootDepth && isParagraph && isStartOfNode) ||
            (isInSection && isParagraph && isStartOfNode) ||
            (isInColumn && isParagraph && isStartOfNode)
          );
        },
        command: ({ editor, props }: { editor: Editor; props: any }) => {
          const {
            view,
            view: {
              state,
              state: {
                selection: { $head, $from },
              },
              dispatch,
            },
          } = editor;

          const end = $from.pos;
          const from = $head?.nodeBefore
            ? end - ($head.nodeBefore.text?.substring($head.nodeBefore.text?.indexOf('/')).length ?? 0)
            : $from.start();

          const tr = state.tr.deleteRange(from, end);
          dispatch(tr);

          props.action(editor);
          view.focus();

          if (!props.isEnabled && props.intentAction) {
            this.options.onToggleUpgradeIntentModal(props.intentAction, props.plan);
          }
        },
        items: ({ query }: { query: string }) => {
          const queryNormalized = query.toLowerCase().trim();

          const withFilteredCommands = GROUPS_WITH_COMMANDS.map((group) => {
            const filteredCommands = queryNormalized
              ? fuzzysort
                  .go(queryNormalized, group.commands, { keys: ['preparedLabel', 'preparedAliases'] })
                  .map(({ obj }) => ({
                    ...obj,
                    highlightedLabel: fuzzysort.highlight(
                      fuzzysort.single(queryNormalized, obj.label)!,
                      '<strong>',
                      '</strong>'
                    ),
                  }))
              : group.commands;

            return {
              ...group,
              commands: (filteredCommands as Command[]).filter((command) =>
                command.shouldBeHidden
                  ? !command.shouldBeHidden(this.editor, {
                      allowPolls: this.options.allowPolls,
                      allowAds: this.options.allowAds,
                      settings: this.options.settings,
                    })
                  : true
              ),
            };
          });

          const withoutEmptyGroups = withFilteredCommands.filter((group) => {
            if (group.commands.length > 0) {
              return true;
            }

            return false;
          });

          const withEnabledSettings = withoutEmptyGroups.map((group) => ({
            ...group,
            commands: group.commands.map((command) => ({
              ...command,
              isEnabled: command.shouldBeEnabled ? command.shouldBeEnabled(this.options.settings) : true,
            })),
          }));

          return withEnabledSettings;
        },
        render: () => {
          let component: any;

          return {
            onStart: (props: SuggestionProps) => {
              component = new ReactRenderer(MenuList, {
                props,
                editor: props.editor,
              });

              const { view } = props.editor;

              const editorNode = view.dom as HTMLElement;
              const maxWidth = 36 * 16;

              const getReferenceClientRect = () => {
                if (!props.clientRect) {
                  return props.editor.storage[extensionName].rect;
                }

                const rect = props.clientRect();

                if (!rect) {
                  return props.editor.storage[extensionName].rect;
                }

                // Account for when the editor is bound inside a container that doesn't go all the way to the edge of the screen
                const editorXOffset = editorNode.getBoundingClientRect().x;
                return new DOMRect(
                  editorNode.clientWidth / 2 - maxWidth / 2 + 8 + editorXOffset,
                  rect.y,
                  rect.width,
                  rect.height
                );
              };

              popup?.[0].setProps({
                getReferenceClientRect,
                maxWidth,
                appendTo: () => props?.editor?.options?.element,
                content: component.element,
              });

              popup?.[0].show();
            },

            onUpdate(props: SuggestionProps) {
              component.updateProps(props);

              const { view } = props.editor;

              const editorNode = view.dom as HTMLElement;
              const maxWidth = 36 * 16;

              const getReferenceClientRect = () => {
                if (!props.clientRect) {
                  return props.editor.storage[extensionName].rect;
                }

                const rect = props.clientRect();

                if (!rect) {
                  return props.editor.storage[extensionName].rect;
                }

                // Account for when the editor is bound inside a container that doesn't go all the way to the edge of the screen
                const editorXOffset = editorNode.getBoundingClientRect().x;
                return new DOMRect(
                  editorNode.clientWidth / 2 - maxWidth / 2 + 8 + editorXOffset,
                  rect.y,
                  rect.width,
                  rect.height
                );
              };

              // eslint-disable-next-line no-param-reassign
              props.editor.storage[extensionName].rect = props.clientRect
                ? getReferenceClientRect()
                : {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                  };
              popup?.[0].setProps({
                getReferenceClientRect,
                maxWidth,
              });
            },

            onKeyDown(props: SuggestionKeyDownProps) {
              if (props.event.key === 'Escape') {
                popup?.[0].hide();

                return true;
              }

              if (!popup?.[0].state.isShown) {
                popup?.[0].show();
              }

              return component.ref?.onKeyDown(props);
            },

            onExit() {
              popup?.[0].hide();
              component.destroy();
            },
          };
        },
      }),
    ];
  },

  addStorage() {
    return {
      rect: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
    };
  },
});

export default SlashCommand;
