import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useUpdateOrganization = (organizationId: string | undefined) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (organization: any) =>
      api.patch(`/organizations/${organizationId}`, {
        publication_id: currentPublicationId,
        organization,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        toast.success('Organization successfully updated!');
        queryClient.invalidateQueries(['organizations', organizationId]);
      },
    }
  );
};

export default useUpdateOrganization;
