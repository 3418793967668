import { Link } from 'react-router-dom';
import moment from 'moment-mini';

import { ChartCard } from '@/components/Chartsv2';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import TabNavigation from '@/components/TabNavigation';
import { TitleSm, Typography } from '@/components/Typography';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { Tab } from '@/interfaces/general';
import { useResourceListFilters } from '@/ui/ResourceListFilters';
import { TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

import useDonationsByPeriod from '../_hooks/useDonationsByPeriod';

enum SortingOptions {
  AMOUNT = 'amount',
  CREATED_AT = 'created_at',
}

const DonationsCard = ({ tabs }: { tabs: Tab[] }) => {
  const { orderBy, direction, handleClickOrderButton } = useResourceListFilters<string, string>(
    'all',
    SortingOptions.CREATED_AT
  );
  // Overview can only be broken down monthly at the moment so filter out the unneeded options
  const excludeOptions = [TimePeriod.LAST_24_HOURS, TimePeriod.LAST_4_WEEKS, TimePeriod.LAST_7_DAYS];
  const { period, TimePeriodSelect } = useTimePeriodSelect({ defaultValue: TimePeriod.ALL_TIME, excludeOptions });
  const { data, isLoading, isError } = useDonationsByPeriod({
    period: period as keyof typeof timePeriodLabels,
    orderBy,
    direction,
  });

  return (
    <ChartCard
      title="Donation History"
      isLoading={isLoading}
      noResults={isError}
      noResultsText=""
      actionChildren={<TimePeriodSelect />}
    >
      <TabNavigation tabs={tabs} />
      <div className="mt-6">
        {data?.chart_data ? (
          <Items className="!shadow-none">
            <ItemHeaders>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={SortingOptions.CREATED_AT}
                isSorting={orderBy === SortingOptions.CREATED_AT}
                currentDirection={direction}
              >
                Date Paid
              </ItemHeader>
              <ItemHeader>Email</ItemHeader>
              <ItemHeader>Tier</ItemHeader>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={SortingOptions.AMOUNT}
                isSorting={orderBy === SortingOptions.AMOUNT}
                currentDirection={direction}
              >
                Amount
              </ItemHeader>
            </ItemHeaders>
            <ItemsBody>
              {data.chart_data.map((row) => (
                <ItemRow className="last:border-b-0" key={row.created_at}>
                  <ItemColumn>
                    <Typography token="font-medium/text/base">{moment(row.created_at).format('LLL')}</Typography>
                  </ItemColumn>
                  <ItemColumn>
                    <Typography token="font-medium/text/base">
                      <Link
                        to={`/subscribers/${row.subscriber_id}`}
                        className="text-primary-600 hover:text-primary-900"
                      >
                        {row.email}
                      </Link>
                    </Typography>
                  </ItemColumn>
                  <ItemColumn>
                    <Typography token="font-medium/text/sm" colorWeight="700">
                      {row.tier_name}
                    </Typography>
                  </ItemColumn>
                  <ItemColumn>
                    <Typography token="font-bold/text/base" color="tertiary" colorWeight="600">
                      {row.formatted_amount}
                    </Typography>
                  </ItemColumn>
                </ItemRow>
              ))}
            </ItemsBody>
          </Items>
        ) : (
          <div className="h-40 px-4 flex items-center justify-center">
            <TitleSm className="text-sm">No data for the chosen time range</TitleSm>
          </div>
        )}
      </div>
    </ChartCard>
  );
};

export default DonationsCard;
