import { useEffect } from 'react';

const DOWN_ARROW_KEY = 'ArrowDown';
const UP_ARROW_KEY = 'ArrowUp';

interface Params {
  triggerElementId?: string; // The id of the element that triggers the component interaction, for example the main button that opens a dropdown. Leave blank if not needed.
  listElementClass: string; // The classname of the elements to navigate between, for example the list items in a dropdown.
}

const useArrowKeyNavigation = ({ triggerElementId, listElementClass }: Params) => {
  const onKeydown = (e: KeyboardEvent) => {
    if (document.activeElement?.id === triggerElementId) {
      if (e.key === DOWN_ARROW_KEY) {
        const nextElement: HTMLElement | null = document.querySelector(`.${listElementClass}`);

        nextElement?.focus();
      }
    } else if (document.activeElement?.classList.contains(listElementClass)) {
      if (e.key === DOWN_ARROW_KEY) {
        const nextElement: HTMLElement | null | undefined =
          document.activeElement?.nextElementSibling?.querySelector(`.${listElementClass}`) ||
          document.activeElement?.parentElement?.nextElementSibling?.querySelector(`.${listElementClass}`);

        if (nextElement?.classList.contains(listElementClass)) {
          nextElement?.focus();
        }
      }

      if (e.key === UP_ARROW_KEY) {
        const prevElement: HTMLElement | null | undefined =
          document.activeElement?.previousElementSibling?.querySelector(`.${listElementClass}`) ||
          document.activeElement?.parentElement?.previousElementSibling?.querySelector(`.${listElementClass}`);

        if (!prevElement) {
          if (triggerElementId) {
            const buttonEl: HTMLElement | null = document.querySelector(`#${triggerElementId}`);

            buttonEl?.focus();
          }
        } else if (prevElement?.classList.contains(listElementClass)) {
          prevElement?.focus();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useArrowKeyNavigation;
