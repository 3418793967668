import Badge from '@/components/Badge';

import { StepProps } from './types';

const ReviewStep = ({ endpoint }: StepProps) => {
  const { url, description, disabled, event_types: eventTypes } = endpoint;

  return (
    <div className="space-y-4">
      <div className="text-md font-semibold">Review Details</div>
      <div className="space-y-2 p-2 rounded-md bg-gray-50">
        <div className="text-gray-400">Webhook Status</div>
        <Badge type={disabled ? 'alert' : 'success'}>{disabled ? 'Disabled' : 'Active'}</Badge>
      </div>
      <div className="space-y-2 p-2 rounded-md bg-gray-50">
        <div className="text-gray-400">Endpoint URL</div>
        <div className="break-all">{url}</div>
      </div>
      <div className="space-y-2 p-2 rounded-md bg-gray-50">
        <div className="text-gray-400">Endpoint Description</div>
        <div>{description}</div>
      </div>
      <div className="space-y-2 p-2 rounded-md bg-gray-50">
        <div className="text-gray-400">Event Types</div>
        <div className="space-x-2 pb-4">
          {eventTypes.map((event: string) => (
            <Badge type="info_blue">{event}</Badge>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewStep;
