import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

interface Props {
  to: string;
  text: string;
}

const SettingsLink = ({ to, text }: Props) => {
  const location = useLocation();
  const selected = location.pathname === to || (location.pathname.startsWith(to) && to !== '/' && to !== '/settings');

  return (
    <div className="ml-6">
      <Link
        to={to}
        className={cx(
          'rounded px-2 py-1 text-sm font-medium duration-200 whitespace-nowrap flex items-center space-x-2 w-full',
          selected ? 'text-primary-500 bg-primary-50' : 'text-gray-500 hover:text-gray-900 hover:bg-gray-50'
        )}
      >
        {text}
      </Link>
    </div>
  );
};

export default SettingsLink;
