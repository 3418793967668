import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Banner from '@/components/Banner';
import LoadingBox from '@/components/LoadingBox';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import { useTrialStatus } from '@/hooks/usePublicationDashboard';
import { SettingsPageLayout } from '@/pages/Settings/Components';

import BillingDetails from './_components/BillingDetails';
import BillingSummary from './_components/BillingSummary';
import CancellationSection from './_components/CancellationSection';
import CurrentPlan from './_components/CurrentPlan';
import LegacyLockInPrompt from './_components/LegacyLockInPrompt';
import PausePlanNotice from './_components/PausePlanNotice';
import StartTrial from './_components/StartTrial';
import UsageInformation from './_components/UsageInformation';

const Billing = () => {
  const { data: currentPublication, isLoading, isError } = useCurrentPublication();
  const { settings } = useSettings();
  const trialStatusQuery = useTrialStatus();
  const { data: trialStatus } = trialStatusQuery || {};
  const trialEligible = trialStatus?.trial_eligible;

  const { organization_id: organizationId } = currentPublication || {};
  const location = useLocation();
  const navigate = useNavigate();
  const [offerApplied, setofferApplied] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.has('offer_applied')) {
      setofferApplied(true);
      query.delete('offer_applied');
      navigate({ search: query.toString() }, { replace: true });
    }
  }, [location, navigate]);

  return (
    <SettingsPageLayout title="Billing">
      <LoadingBox isLoading={isLoading} isError={isError} backgroundClassName="bg-transparent">
        {organizationId && (
          <div className="flex flex-col py-10 px-2 space-y-10 items-center">
            {offerApplied && (
              <Banner
                className="w-2/3 mx-auto"
                isScreenWide={false}
                variant="success"
                title="Offer applied! The discount will be reflected on your bills for the next 3 months."
              />
            )}
            <PausePlanNotice />
            {settings?.tiered_pricing && <LegacyLockInPrompt organizationId={organizationId} />}
            <BillingSummary organizationId={organizationId} />
            <UsageInformation organizationId={organizationId} />
            <CurrentPlan organizationId={organizationId} />
            {trialEligible && <StartTrial organizationId={organizationId} />}
            <BillingDetails organizationId={organizationId} />
            <CancellationSection organizationId={organizationId} />
          </div>
        )}
      </LoadingBox>
    </SettingsPageLayout>
  );
};

export default Billing;
