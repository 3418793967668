import { Tab } from '@headlessui/react';
import { CheckCircleIcon as CheckCircleIconSolid } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { useSetting } from '@/hooks';
import { Setting } from '@/interfaces/setting';
import { Button } from '@/ui/Button';

import { IntentAction } from '../../interfaces/upgrades';
import { FEATURE, PLAN, PREMIUM_PLANS } from '../../utils/plans';
import Modal from '../Modal';

import TieredUpgradeIntent from './TieredUpgradeIntent';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  intentAction?: IntentAction;
  preselectedPlan?: keyof typeof PREMIUM_PLANS;
}

type DataKey = IntentAction | 'default';

type Data = {
  [K in DataKey]: {
    description: string;
    feature?: FEATURE;
  };
};

const UPGRADE_DATA = {
  [IntentAction.ADD_TEAM_MEMBER]: {
    description: 'Team collaboration is just a few clicks away.',
    feature: FEATURE.MULTIPLE_TEAM_MEMBERS,
  },
  [IntentAction.USE_REFERRAL_PROGRAM]: {
    description: 'The best in class referral program is just a few clicks away.',
    feature: FEATURE.INTEGRATED_REFERRAL_PROGRAM,
  },
  [IntentAction.USE_POLLS]: {
    description: 'Native One-Click Polls are just a few clicks away.',
    feature: FEATURE.AUDIENCE_POLLS,
  },
  [IntentAction.USE_AD_NETWORK]: {
    description: 'Monetize your newsletter with premium sponsors.',
    feature: FEATURE.AD_NETWORK,
  },
  [IntentAction.CREATE_SEGMENT]: {
    description: "You've used all your segments, upgrade to add more.",
    feature: FEATURE.AUDIENCE_SEGMENTATION_UNLIMITED,
  },
  [IntentAction.USE_POSTS_REPORT]: {
    description: 'Upgrade to scale, with advanced in-depth reports of your posts.',
    feature: FEATURE.ADVANCED_REPORTS,
  },
  [IntentAction.USE_SUBSCRIBERS_REPORT]: {
    description: 'Upgrade to scale, with advanced in-depth reports of your subscribers.',
    feature: FEATURE.ADVANCED_REPORTS,
  },
  [IntentAction.USE_CUSTOM_HTML]: {
    description: 'Take your newsletter to the next level with custom HTML snippets.',
    feature: FEATURE.CUSTOM_HTML,
  },
  [IntentAction.USE_PREMIUM_SUBSCRIPTIONS]: {
    description: 'Upgrade to monetize your newsletter with premium subscriptions.',
    feature: FEATURE.PREMIUM_SUBSCRIPTIONS,
  },
  [IntentAction.USE_SPLIT_TEST]: {
    description: 'Upgrade to A/B Test your Subject Lines to maximize opens.',
    feature: FEATURE.AB_TESTING,
  },
  [IntentAction.USE_SPLIT_TEST_CUSTOMIZATION]: {
    description: 'Upgrade to set custom A/B Test sizes and durations.',
    feature: FEATURE.SPLIT_TEST_CUSTOMIZATION,
  },
  [IntentAction.EMAIL_A_SEGMENT]: {
    description: 'Upgrade to send emails to specific segments.',
    feature: FEATURE.SEND_SEGMENT_EMAILS,
  },
  [IntentAction.USE_AUTOMATIONS]: {
    description: 'Upgrade to create custom journeys for sending automated messages to your subscribers',
    feature: FEATURE.AUTOMATED_JOURNEYS,
  },
  [IntentAction.USE_FORMS]: {
    description: 'Upgrade to create custom forms for gathering information about your subscribers',
    feature: FEATURE.FORMS,
  },
  [IntentAction.USE_PAGES]: {
    description:
      'Upgrade to customize your Menu Items, create unlimited internal and external Pages, and add category-specific pages to your website for super-charged SEO benefits.',
    feature: FEATURE.PAGES,
  },
  [IntentAction.USE_LANDING_PAGES]: {
    description: 'Upgrade to customize your Landing Page and other website pages to help your site stand out.',
    feature: FEATURE.LANDING_PAGES,
  },
  [IntentAction.USE_AI_WRITING_TOOLS]: {
    description: 'Upgrade to use AI assisted text and image generation tools',
    feature: FEATURE.AI_WRITING_TOOLS,
  },
  default: {
    description: "You won't find a better deal than this.",
  },
} as Data;

const UpgradeIntent: React.FC<Props> = (props: Props) => {
  const { intentAction, isOpen, onClose, preselectedPlan = PLAN.GROW } = props;
  const tieredPricing = useSetting({ setting: Setting.TIERED_PRICING });

  if (tieredPricing) return <TieredUpgradeIntent {...props} />;

  const data = UPGRADE_DATA[intentAction || 'default'];

  const { grow, scale } = PREMIUM_PLANS;
  const plans = [grow, scale];
  const defaultIndex = plans.findIndex((plan) => plan.name === preselectedPlan);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="max-w-3xl w-full">
      <div className="p-8">
        <div className="space-y-4 mb-8">
          <div>
            <h1 className="text-2xl leading-6 font-bold text-gray-900 mb-1">The newsletter suite of your dreams ✨</h1>
            <p className="text-base text-gray-500">{data.description}</p>
          </div>
          <div>
            <Tab.Group defaultIndex={defaultIndex}>
              <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 p-1 w-1/2 mx-auto">
                {plans.map(({ name }) => (
                  <Tab
                    key={name}
                    className={({ selected }) =>
                      cx(
                        'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-700 capitalize',
                        'ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-400 focus:outline-none focus:ring-2',
                        selected ? 'bg-white shadow' : 'hover:bg-gray-50'
                      )
                    }
                  >
                    {name}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="mt-2">
                {plans.map(({ name, priceMonthly, priceAnnually, features, highlightedFeatures }) => {
                  const annualSavings = Math.round(100 - (priceAnnually / (priceMonthly * 12)) * 100);

                  return (
                    <Tab.Panel key={name}>
                      <div className="border rounded-lg p-8 my-4 bg-gray-50">
                        <p>
                          Upgrade to the <span className="font-bold capitalize mb-2">{name}</span> plan
                        </p>
                        <div className="grid grid-cols-2 gap-2 mt-2">
                          <div className="col-span-2 sm:col-span-1">
                            <div className="text-3xl font-bold">
                              <div className="flex items-baseline">
                                <span className="text-4xl inline-flex mr-1 font-extrabold">${priceMonthly}</span>
                                <div className="flex flex-col text-sm font-normal">
                                  <p>/ month</p>
                                </div>
                              </div>
                            </div>
                            <p className="text-gray-600">
                              <span className="text-sm">
                                or save <strong>{annualSavings}%</strong> with an annual plan (${priceAnnually})
                              </span>
                            </p>
                          </div>
                          <div className="col-span-2 sm:col-span-1">
                            <p className="mb-1">Includes</p>
                            <ul className="max-h-64 overflow-scroll relative">
                              {highlightedFeatures.map((feature) => (
                                <li
                                  key={feature}
                                  className={cx('flex items-center', feature === data.feature && 'font-bold')}
                                >
                                  {feature === data.feature ? (
                                    <CheckCircleIconSolid className="w-4 h-4 mr-2 text-green-500" />
                                  ) : (
                                    <CheckCircleIcon className="w-4 h-4 mr-2 text-green-500" />
                                  )}

                                  {feature}
                                </li>
                              ))}
                              {features.map((feature) => (
                                <li
                                  key={feature}
                                  className={cx('flex items-center', feature === data.feature && 'font-bold')}
                                >
                                  {feature === data.feature ? (
                                    <CheckCircleIconSolid className="w-4 h-4 mr-2 text-green-500" />
                                  ) : (
                                    <CheckCircleIcon className="w-4 h-4 mr-2 text-green-500" />
                                  )}

                                  {feature}
                                </li>
                              ))}
                              <div className="h-24 sticky bottom-0 w-full bg-gradient-to-t from-gray-50" />
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>
                  );
                })}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
        <div className="flex space-x-2 justify-end">
          <Button to="/settings/billing" block>
            <span className="text-lg">Go to Billing</span>
          </Button>
        </div>
        <p className="text-sm text-gray-500 text-center mt-4">
          <span>Psst... We also have </span>
          <a
            className="underline hover:text-primary-500"
            href="https://www.beehiiv.com/enterprise"
            target="_blank"
            rel="noopener noreferrer"
          >
            enterprise plans
          </a>
        </p>
      </div>
    </Modal>
  );
};

export default UpgradeIntent;
