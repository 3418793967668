export enum RecommendationStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ARCHIVED = 'archived',
  BLOCKED = 'blocked',
}

export interface RecommendationPublication {
  id: string;
  name: string;
  description: string | null;
  hostname: string;
  logo_url: string;
}

export interface Recommendation {
  id: string;
  publication_id: string;
  recommended_publication_id: string;
  reason: string;
  status: RecommendationStatus;
  attribution_count: number;
  position: number;

  recommended_publication: RecommendationPublication;
  publication: RecommendationPublication;
}
