import { PropsWithChildren } from 'react';
import cx from 'classnames';

import { Typography } from '../Typography';

type Props = PropsWithChildren<{
  className?: string;
}>;

const HelperText = ({ children, className }: Props) => {
  return (
    <Typography token="font-normal/text/xs" className={cx(className, 'block leading-snug')} colorWeight="500">
      {children}
    </Typography>
  );
};

export default HelperText;
