import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import Badge from '@/components/Badge';
import Card from '@/components/Card';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import useRetrySvixMessage from '@/hooks/webhooks/useRetrySvixMessage';
import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';
import { WebhookMessageAttempt } from '@/interfaces/webhooks/message_attempt';
import { Button } from '@/ui/Button';

import StatusBadge from '../Index/StatusBadge';

type Props = {
  endpoint: WebhookEndpoint;
  messageAttempt: WebhookMessageAttempt;
};

const Details = ({ endpoint, messageAttempt }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const [isRetrying, setIsRetrying] = useState(false);

  const retrySvixMessage = useRetrySvixMessage({
    onSuccess: () => {
      toast.success('Retry sent successfully');
      setIsRetrying(false);
      queryClient.invalidateQueries(
        ['publications', currentPublicationId, 'webhooks', 'endpoints', endpoint.id, 'message_attempts'],
        { exact: true }
      );
    },
    onError: (errPayload) => {
      setIsRetrying(false);
      const errors = errPayload?.response?.data?.message || 'Something went wrong';
      toast.error(errors);
    },
  });

  const handleRetrySvixMessage = () => {
    setIsRetrying(true);
    retrySvixMessage.mutate({ endpointId: endpoint.id, messageId: messageAttempt.message_id });
  };

  return (
    <div className="mx-auto w-full grid grid-cols-8 lg:grid-cols-12 gap-4">
      <div className="col-span-8 space-y-4">
        <Card>
          <div className="space-y-2 md:space-y-4">
            <div className="space-y-2 p-2">
              <div className="text-gray-400">Endpoint URL</div>
              <div>{endpoint.url}</div>
            </div>
            <div className="space-y-2 p-2">
              <div className="text-gray-400">Event Status</div>
              <StatusBadge messageAttempt={messageAttempt} />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="space-y-2 p-2">
                <div className="text-gray-400">Response Code</div>
                {messageAttempt.response_status_code}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="col-span-8 lg:col-span-4 space-y-4">
        <Card className="w-full">
          <div className="space-y-2">
            <div className="space-y-2 p-2">
              <div className="text-gray-400">Timestamp</div>
              <time>{moment(messageAttempt.timestamp).format('LLL')}</time>
            </div>
            <div className="space-y-2 p-2">
              <div className="text-gray-400">Event Type</div>
              <div className="space-y-2 pb-4">
                <Badge className="mr-2" type="info_blue">
                  {messageAttempt.message.event_type}
                </Badge>
              </div>
            </div>
          </div>
        </Card>
        <div className="w-full flex flex-row justify-end">
          <Button
            variant="primary"
            loading={isRetrying}
            disabled={isRetrying}
            onClick={() => handleRetrySvixMessage()}
            Icon={ArrowPathIcon}
            iconRight
          >
            Resend Message
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Details;
