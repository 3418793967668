import { SimpleSelect } from '@/components/Form';
import { Option } from '@/interfaces/general';

interface SegmentSelectProps {
  segmentId: string;
  options: Option[];
  onChange: (name: string, value: string) => void;
}

const SegmentSelect = ({ segmentId, options, onChange }: SegmentSelectProps) => {
  return (
    <SimpleSelect
      name="segment_id"
      value={segmentId}
      onSelect={onChange}
      options={options}
      placeholderText="Select a Segment"
      helperText="Select a Dynamic or Static segment. Manual segments are not supported."
    />
  );
};

export default SegmentSelect;
