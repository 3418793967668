import { useEffect, useState } from 'react';

import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelHeader, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';
import { Post } from '@/interfaces/post';

interface Props {
  onBack: () => void;
  post: Post;
  updatePost: (data: Post) => void;
}

const VisibilitySettings = ({ onBack, post, updatePost }: Props) => {
  const [displayTitleInEmail, setDisplayTitleInEmail] = useState(post.display_title_in_email);

  const onToggleShowInEmail = () => {
    setDisplayTitleInEmail((prevVal) => !prevVal);
  };

  useEffect(() => {
    updatePost({ ...post, display_title_in_email: displayTitleInEmail });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayTitleInEmail]);

  return (
    <Styled.ContentWrapper>
      <Panel>
        <PanelHeader>
          <Button
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="ChevronLeft" />}
            onClick={onBack}
            $fullWidth
          >
            Visibility
          </Button>
        </PanelHeader>
        <PanelSection>
          <Styled.PanelSectionInner>
            <ToggleWrapper
              icon={<Icon name="Mail" />}
              switchEl={<ToggleSwitch isChecked={displayTitleInEmail} onChange={onToggleShowInEmail} />}
            >
              Show in email
            </ToggleWrapper>
          </Styled.PanelSectionInner>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default VisibilitySettings;
