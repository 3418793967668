import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import api from '../services/swarm';

interface ApiResponse {
  id: string;
}

const useAddRecommendation = ({ onSuccess }: { onSuccess: () => void }) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, { publicationId: string; reason?: string }>(
    ({ publicationId, reason }) =>
      api
        .post('/recommendations', {
          recommendation: {
            recommended_publication_id: publicationId,
            reason,
          },
          publication_id: currentPublicationId,
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useAddRecommendation;
