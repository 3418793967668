import { useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

import Card from '../../../components/Card';
import { Input, RadioSelect } from '../../../components/Form';
import { BackLink } from '../../../components/Links';
import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { useOrganization } from '../../../hooks';
import { useSendInvite } from '../../../hooks/useInvites';
import { usePublication } from '../../../hooks/usePublications';
import { RoleOption } from '../../../interfaces/organization';
import { capitalize } from '../../../utils';

const NewInvite = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { back }: any = location.state || {};
  const backUrl = back || '/settings/company';

  const [publicationId] = useCurrentPublicationState();
  const currentPublication = usePublication(publicationId)?.data;
  const organizationQuery = useOrganization(currentPublication?.organization_id);
  const { organization } = organizationQuery?.data || {};

  const sendInviteMutation = useSendInvite();

  const [selection, setSelection] = useState<any>(null);
  const [inviteEmail, setInviteEmail] = useState<string>('');

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();

    if (selection && inviteEmail) {
      const match = organization.role_options.find((option: RoleOption) => {
        return option.name === selection;
      });

      await sendInviteMutation.mutateAsync({
        invited_email: inviteEmail,
        role_id: match.role_id,
        invitable_type: match.object_type,
        invitable_id: match.object_id,
      });

      navigate('/settings/company');
    } else {
      toast.error('Please select a role and enter an email address');
    }
  };

  return (
    <div className="space-y-6">
      <BackLink to={backUrl} linkText="Back to company details" />
      <Card>
        <form onSubmit={handleUpdate}>
          <div className="space-y-4">
            <div className="flex space-x-3 items-center">
              <div className="flex flex-col space-y-0.5">
                <h2 className="text-lg leading-6 font-medium text-gray-900 ml-0 flex">Invite New Team Member</h2>
              </div>
            </div>

            <div className="space-y-4">
              <Input
                className="w-full"
                name="email"
                labelText="Email"
                placeholder="new.member@email.com"
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
              />
              {organization?.role_options && (
                <RadioSelect
                  labelText="Change Role"
                  value={selection}
                  onSelect={(val) => setSelection(val)}
                  options={organization?.role_options.map((option: RoleOption) => ({
                    name: capitalize(option.name),
                    value: option.name,
                    description: option.description,
                  }))}
                />
              )}
              <div className="w-full flex justify-end space-x-4 pt-4">
                <Button type="submit" variant="primary" onClick={() => {}}>
                  <div className="flex">
                    <EnvelopeIcon className="h-5 w-5 mr-2" />
                    Send Invite
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default NewInvite;
