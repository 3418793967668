import { Link, useNavigate, useParams } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import moment from 'moment-mini';

import { useCampaign, useCampaignDestroy } from '@/hooks/useAdNetwork/internal';
import useCampaignPaymentRequests from '@/hooks/useAdNetwork/internal/useCampaignPaymentRequests';
import {
  AdNetworkCampaign,
  AdNetworkCampaignAgeOptions,
  AdNetworkCampaignGenderOptions,
  AdNetworkCampaignGeographicalRequirement,
  AdNetworkCampaignGeographicalRequirementOptions,
  AdNetworkCampaignHouseholdIncomeOptions,
  AdNetworkCampaignPayoutMethodOptions,
} from '@/interfaces/ad_network/internal/campaign';
import {
  AdNetworkPaymentRequest,
  AdNetworkPaymentRequestStatus,
} from '@/interfaces/ad_network/internal/payment_request';
import { Button } from '@/ui/Button';

import { DescriptionList } from '../_components/DescriptionList';
import { MultiActionCard } from '../_components/MultiActionCard';
import { PageHeading } from '../_components/PageHeading';
import { CampaignStatusIndicator } from '../_components/StatusIndicator';
import useConfirmDelete from '../_hooks/useConfirmDelete';

interface CampaignsShowProps {
  campaign: AdNetworkCampaign;
}

const CampaignsShow = ({ campaign }: CampaignsShowProps) => {
  const navigate = useNavigate();
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const deleteMutation = useCampaignDestroy({ campaign });
  const warning = 'Once you delete a campaign, there is no going back. Please be certain.';
  const { ConfirmDeleteModal, modalProps, handleDelete, isDeleting } = useConfirmDelete({
    deleteMutation,
    warning,
    confirmWith: campaign.name,
  });
  const { data: paymentRequestData } = useCampaignPaymentRequests({ campaignId: campaign.id, allResults: true });
  const { payment_requests: paymentRequests } = paymentRequestData?.pages?.[0] || {};
  const paidPaymentRequests = paymentRequests?.filter((pr) => pr.status === AdNetworkPaymentRequestStatus.PAID) || [];
  const sentPaymentRequests = paymentRequests?.filter((pr) => pr.status === AdNetworkPaymentRequestStatus.SENT) || [];
  const upcomingPaymentRequests =
    paymentRequests?.filter((pr) => pr.status === AdNetworkPaymentRequestStatus.SCHEDULED) || [];
  const totalPaidCents = paidPaymentRequests.reduce((acc, pr) => acc + pr.amount_cents, 0) || 0;
  const payoutMethodLabel = AdNetworkCampaignPayoutMethodOptions.find(
    (pm) => pm.value === campaign.payout_method
  )?.label;

  const paymentRequestRows = (requests: AdNetworkPaymentRequest[]) => {
    if (requests.length === 0) return <p>$0.00</p>;

    return (
      <div className="flex flex-col space-y-1">
        {requests.map((pr) => (
          <div key={pr.id}>
            {`${currencyFormatter.format(pr.amount_cents / 100)} (${moment.utc(pr.send_at).format('LL')})`}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to={`/ad_network/advertisers/${campaign.advertiser.id}/campaigns`}>
              Campaigns
            </PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb>{campaign.name}</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button to="campaign_opportunity_groups" variant="primary-inverse" size="xs">
            Opportunity groups
          </Button>
          <Button to="advertisements" variant="primary-inverse" size="xs">
            Advertisements
          </Button>
          <Button to="edit" variant="primary-inverse" size="xs" Icon={PencilSquareIcon}>
            Edit
          </Button>
        </PageHeading.Side>
      </PageHeading>

      <div className="divide-y divide-gray-100">
        <div className="p-4 grid grid-cols-1 md:grid-cols-2 space-y-4">
          <div>
            <div className="flex items-center gap-x-3">
              <h1 className="flex gap-x-3 text-base leading-7">
                <Link to={`/ad_network/advertisers/${campaign.advertiser.id}`} className="font-semibold text-gray-800 ">
                  {campaign.advertiser.name}
                </Link>
                <span className="text-gray-400">/</span>
                <span className="font-semibold text-gray-800 line-clamp-1">{campaign.name}</span>
              </h1>
              <CampaignStatusIndicator campaign={campaign} />
            </div>
            <p className="mt-2 text-xs leading-6 text-gray-500">
              <time>{moment(campaign.window_start_date).format('LLL')}</time>
              <span>&nbsp;—&nbsp;</span>
              <time>{campaign.window_always_on ? 'indefinitely' : moment(campaign.window_end_date).format('LLL')}</time>
            </p>
          </div>
        </div>
        <DescriptionList>
          <DescriptionList.Item term="ID" copyValue={campaign.id}>
            {campaign.id}
          </DescriptionList.Item>
          {campaign.internal_name && (
            <DescriptionList.Item term="Internal Name">{campaign.internal_name}</DescriptionList.Item>
          )}
          <DescriptionList.Item term="Window">
            {moment(campaign.window_start_date).format('LL')} -{' '}
            {campaign.window_always_on ? 'indefinitely' : moment(campaign.window_end_date).format('LL')}
          </DescriptionList.Item>
          <DescriptionList.Item term="Clicks Goal">{campaign.clicks_goal.toLocaleString()}</DescriptionList.Item>
          <DescriptionList.Item term="CPC">{campaign.cost_per_click}</DescriptionList.Item>
          <DescriptionList.Item term="Payout Method">{payoutMethodLabel}</DescriptionList.Item>
          <DescriptionList.Item term="Total Spend">{campaign.total_spend}</DescriptionList.Item>
          <DescriptionList.Item term="Total Paid">
            {currencyFormatter.format(totalPaidCents / 100)}
          </DescriptionList.Item>
          {campaign.payout_method !== 'manual' && (
            <>
              <DescriptionList.Item term="Unpaid Payment Requests">
                {paymentRequestRows(sentPaymentRequests)}
              </DescriptionList.Item>
              <DescriptionList.Item term="Upcoming Payment Requests">
                {paymentRequestRows(upcomingPaymentRequests)}
              </DescriptionList.Item>
            </>
          )}

          <DescriptionList.Item term="Created">{moment(campaign.created_at).format('LLL')}</DescriptionList.Item>
          <DescriptionList.Item term="Updated">{moment(campaign.updated_at).format('LLL')}</DescriptionList.Item>
          <DescriptionList.Item term="Target Age Demographics">
            {campaign.ages.map((age) => (
              <span className="pr-1 after:content-[','] last:after:content-['']">
                {AdNetworkCampaignAgeOptions.find((option) => option.value === age)?.label}
              </span>
            ))}
          </DescriptionList.Item>
          <DescriptionList.Item term="Target Gender Demographic">
            {AdNetworkCampaignGenderOptions.find((option) => option.value === campaign.gender)?.label}
          </DescriptionList.Item>
          <DescriptionList.Item term="Target Household Income Demographics">
            {campaign.household_incomes.map((hhi) => (
              <span className="pr-1 after:content-[','] last:after:content-['']">
                {AdNetworkCampaignHouseholdIncomeOptions.find((option) => option.value === hhi)?.label}
              </span>
            ))}
          </DescriptionList.Item>
          <DescriptionList.Item term="Target Geographical Demographics">
            {campaign.geographical_requirements.map((geo_req) => (
              <span className="pr-1 after:content-[','] last:after:content-['']">
                {geo_req === AdNetworkCampaignGeographicalRequirement.OTHER && campaign.geographical_requirements_other}
                {geo_req !== AdNetworkCampaignGeographicalRequirement.OTHER &&
                  AdNetworkCampaignGeographicalRequirementOptions.find((option) => option.value === geo_req)?.label}
              </span>
            ))}
          </DescriptionList.Item>
        </DescriptionList>

        <MultiActionCard>
          <MultiActionCard.Item
            title="Configure campaign"
            description="Edit the name, goals, and other details of this campaign."
            onClick={() => navigate('edit')}
          />
          {campaign.payout_method !== 'manual' && (
            <MultiActionCard.Item
              title="View Payment Requests"
              description="View and manage payment requests sent to the advertiser for this campaign."
              onClick={() => navigate('payment_requests')}
            />
          )}
          <MultiActionCard.Item
            title="View advertisements"
            description="View and manage the advertisements in this campaign."
            onClick={() => navigate('advertisements')}
          />
          <MultiActionCard.Item
            title="View campaign performance"
            description="View click data for opportunities in this campaign."
            onClick={() => navigate('performance')}
          />
          <MultiActionCard.Item
            title="Delete this campaign"
            description={warning}
            loading={isDeleting}
            onClick={() => handleDelete()}
            danger
          />
        </MultiActionCard>
      </div>
      <ConfirmDeleteModal {...modalProps} />
    </>
  );
};

export default function Loader() {
  const { campaign_id: id } = useParams<'campaign_id'>() as { campaign_id: string };
  const { data, isLoading, isError } = useCampaign({ id });

  if (isLoading || isError) return null;

  return <CampaignsShow campaign={data as AdNetworkCampaign} />;
}
