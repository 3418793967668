import { useState } from 'react';
import { useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import ImportSubscriberListForm from './ImportSubscriberListForm';
import ImportSubscriberModalContent from './ImportSubscriberModalContent';
import { ImportState } from './types';
import { useImportSubscriberList } from './useImportSubscriberList';

interface Props {
  segmentId?: string;
  onSuccess?: () => void;
  onFailure?: (errorMessage?: string) => void;
  onClose?: () => void;
}

const ImportSubscriberList = ({ segmentId, onSuccess, onFailure, onClose }: Props) => {
  const {
    modalHeaderText,
    isSystemAdmin,
    file,
    setFile,
    sendWelcomeEmail,
    setSendWelcomeEmail,
    skipEmailValidation,
    setSkipEmailValidation,
    currentPublicationId,
    commaSeparatedEmails,
    setCommaSeparatedEmails,
    handleEmailInputChange,
    publicationName,
    publicationWelcomeEmail,
    csvColumns,
    setCsvColumns,
    setColumnMapping,
    columnMappingErrors,
    setColumnMappingErrors,
    onImportClear,
    onImportConfirm,
    onMappingStart,
    showMappingModal,
    makeFormData,
    currentImportSubscriberStep: currentImportStep,
    isAddingToAutomations,
    onAddToAutomationsChange,
    selectedAutomations,
    onSelectAutomation,
    onDeselectAutomation,
    onDeselectAllAutomations,
    importType,
    handleImportTypeChange,
    handleSubscriberTagSelect,
    handleSubscriberTagDeselect,
    onAddSubscriberTagsChange,
    isAddingSubscriberTags,
    selectedSubscriberTags,
    taggingEnabled,
  } = useImportSubscriberList();

  const [isWorking, setIsWorking] = useState(false);
  const queryClient = useQueryClient();
  const modalId = 'import-subscriber-list-modal';

  const clearModal = () => {
    setIsWorking(false);
    setColumnMappingErrors(false);
    onImportClear();
  };

  const clearPage = () => {
    // Clear the query cache for the current publication so the import appears in the list.
    queryClient.invalidateQueries([currentPublicationId, 'imports']);
    if (segmentId) {
      queryClient.invalidateQueries(['segment', segmentId, currentPublicationId]);
    }
    setFile(null);
    setCommaSeparatedEmails('');
    setSendWelcomeEmail(false);
    setSkipEmailValidation(false);
    setColumnMappingErrors(false);
    clearModal();
  };

  const handleSubmit = async () => {
    setIsWorking(true);

    try {
      const response = await api.post('/subscription_imports', makeFormData(segmentId));
      if (response.data.columns) {
        analytics.track('Imported Subscribers');
        setCsvColumns(response.data.columns);
        setIsWorking(false);
        onMappingStart();
      } else {
        clearPage();
        onSuccess?.();
        onClose?.();
      }
    } catch (error: any) {
      onFailure?.(error?.response?.data?.error);
      clearModal();
    }
  };

  const formDisabled = !file && !commaSeparatedEmails;

  return (
    <div className="space-y-3">
      <ActionModal
        isOpen={currentImportStep !== ImportState.INITIAL}
        onClose={clearModal}
        onProceed={handleSubmit}
        resourceId={currentPublicationId}
        isWorking={isWorking}
        headerText={modalHeaderText}
        actionText="Import"
        modalSize="xl"
        bodyId={modalId}
        overflow="visible"
        disabled={columnMappingErrors}
      >
        <ImportSubscriberModalContent
          publicationName={publicationName}
          showMappingModal={showMappingModal}
          csvColumns={csvColumns}
          setColumnMappingErrors={setColumnMappingErrors}
          setColumnMapping={setColumnMapping}
        />
      </ActionModal>

      <ImportSubscriberListForm
        selectedImportType={importType}
        onImportTypeChange={handleImportTypeChange}
        onFileChange={setFile}
        selectedFile={file}
        commaSeparatedEmails={commaSeparatedEmails}
        onCommaSeparatedEmailsChange={handleEmailInputChange}
        shouldSendWelcomeEmail={sendWelcomeEmail}
        shouldDisableWelcomeEmail={!publicationWelcomeEmail}
        onSendWelcomeEmailChange={setSendWelcomeEmail}
        canSkipEmailValidation={isSystemAdmin}
        shouldSkipEmailValidation={skipEmailValidation}
        onSkipEmailValidationChange={setSkipEmailValidation}
        isAddingToAutomations={isAddingToAutomations}
        onAddToAutomationsChange={onAddToAutomationsChange}
        canEnrollToAutomations
        selectedAutomations={selectedAutomations}
        onSelectAutomation={onSelectAutomation}
        onDeselectAutomation={onDeselectAutomation}
        onDeselectAllAutomations={onDeselectAllAutomations}
        taggingEnabled={taggingEnabled}
        handleSubscriberTagSelect={handleSubscriberTagSelect}
        handleSubscriberTagDeselect={handleSubscriberTagDeselect}
        onAddSubscriberTagsChange={onAddSubscriberTagsChange}
        isAddingSubscriberTags={isAddingSubscriberTags}
        selectedSubscriberTags={selectedSubscriberTags}
      />

      <div>
        <div className="w-full flex space-x-2">
          {onClose && (
            <Button variant="primary-inverse" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button loading={currentImportStep !== ImportState.INITIAL} disabled={formDisabled} onClick={onImportConfirm}>
            Import Subscribers
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImportSubscriberList;
