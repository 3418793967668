import React from 'react';
import { Link } from 'react-router-dom';
import {
  CursorArrowRaysIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';

import { EmailMessageStats } from '../../../../interfaces/email_message';
import { SendStat } from '../../../SendStat';
import { Skeleton } from '../../../SkeletonLoader';

interface Props {
  stats?: EmailMessageStats;
  isLoading: boolean;
  isError: boolean;
}

const Stats = ({ stats, isLoading, isError }: Props) => {
  if (isError) {
    return (
      <div>
        <div className="bg-white rounded p-6 flex items-center flex-col">
          <div className="flex mb-4 bg-red-50 rounded-full h-10 w-10 justify-center items-center">
            <ExclamationTriangleIcon className="text-red-500 h-6 w-6" />
          </div>
          <p className="text-gray-600">
            Something went wrong while loading the metrics. Try{' '}
            <Link reloadDocument to="." className="text-red-700 underline">
              refreshing the page
            </Link>
            .
          </p>
        </div>
      </div>
    );
  }

  if (!stats || isLoading) {
    return (
      <div className="divide-y">
        <div className="pb-4">
          <Skeleton className="h-48 animate-pulse" />
        </div>
        <div className="py-4">
          <Skeleton className="h-48 animate-pulse" />
        </div>
        <div className="py-4">
          <Skeleton className="h-48 animate-pulse" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col col-span-4 sm:col-span-1 divide-y">
      <SendStat
        metric={{
          value: stats.total_sent,
        }}
        label="Recipients"
        Icon={EnvelopeIcon}
        className="pb-8"
      />
      <SendStat
        metric={{
          value: stats.total_unique_opened,
          label: 'unique',
        }}
        progress={{
          label: 'Open Rate',
          percentage: stats.open_rate,
        }}
        label="OPENS"
        Icon={EnvelopeOpenIcon}
        className="py-8"
      />
      <SendStat
        metric={{
          value: stats.total_unique_clicked,
          label: 'unique',
        }}
        comparedMetric={{
          value: stats.total_clicked,
          label: 'total',
        }}
        progress={{
          label: 'Click Rate',
          percentage: stats.click_rate,
        }}
        label="CLICKS"
        Icon={CursorArrowRaysIcon}
        className="py-8"
      />
    </div>
  );
};

export default Stats;
