import React, { FC, useEffect, useRef, useState } from 'react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

import { useClickedOutside } from '../../../hooks';

interface Props {
  options: {
    label: string;
    value: string;
  }[];
  currentValue?: string;
  onUpdate: (val: string) => void;
}

const ThemeEditorDropdown: FC<Props> = ({ options, currentValue, onUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<any>();
  useClickedOutside(ref, () => isOpen && setIsOpen(false));

  const [selectValue, setSelectValue] = useState(currentValue);
  const selectOption =
    options.find((opt) => opt.value === selectValue || opt.value?.toUpperCase() === selectValue?.toUpperCase()) ||
    options[0];

  useEffect(() => {
    if (currentValue !== selectValue) {
      setSelectValue(currentValue);
    }
  }, [currentValue]);

  const onSelectOption = (value: string) => {
    if (selectValue !== value) {
      setSelectValue(value);
      onUpdate(value);
    }

    setIsOpen(false);
  };

  const onToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      <button
        aria-expanded={isOpen}
        aria-haspopup="true"
        className="flex-grow-0 cursor-pointer select-none relative w-full bg-white rounded-md border border-gray-300 pl-2 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
        type="button"
        onClick={onToggleDropdown}
      >
        <div className="flex items-center">
          <span className="ml-3 block truncate w-24 lg:w-32">{selectOption.label}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" />
          </span>
        </div>
      </button>
      {isOpen && (
        <ul
          aria-labelledby="location"
          aria-orientation="vertical"
          className="absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          role="menu"
          ref={ref}
          tabIndex={-1}
        >
          {options.map((option) => (
            <li
              className="flex justify-between hover:bg-primary-100 hover:text-primary-800 cursor-pointer text-gray-900 select-none relative py-2 pl-2 pr-4"
              role="option"
              aria-selected="true"
              onClick={() => onSelectOption(option.value)}
              onKeyDown={() => onSelectOption(option.value)}
              key={option.value}
            >
              <div className="flex items-center">
                <span className="font-normal mx-3 block truncate">{option.label}</span>
              </div>
              {option.value === selectOption.value && <CheckIcon className="h-5 w-5 text-primary-400" />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ThemeEditorDropdown;
