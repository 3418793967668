import { useNavigate } from 'react-router-dom';
import { PencilIcon, RectangleGroupIcon } from '@heroicons/react/20/solid';

import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import SplitButton from '@/components/SplitButton/SplitButton';
import { useTutorial } from '@/hooks/useTutorials';
import { TutorialType } from '@/interfaces/tutorial';
import { Button } from '@/ui/Button';

const Header = ({ createDraftMutation }: { createDraftMutation: any }) => {
  const navigate = useNavigate();
  const tutorial = useTutorial(TutorialType.POSTS);

  return (
    <PageHeading
      title="Posts"
      description="View existing posts, create new posts, and edit templates"
      tutorial={tutorial}
    >
      <div className="flex items-center space-x-2">
        <Button variant="primary-inverse" to="/content_tags">
          Manage Content Tags
        </Button>
        <SplitButton
          variant="primary"
          buttons={[
            {
              label: 'Blank draft',
              helperText: 'Create a blank draft from scratch',
              onClick: () => createDraftMutation.mutateAsync({}),
              button: {
                text: 'Start writing',
                Icon: PencilIcon,
              },
            },
            {
              label: 'Use template',
              helperText: 'Create a draft using a custom template',
              onClick: () => navigate('/templates/posts'),
              button: {
                text: 'Choose template',
                Icon: RectangleGroupIcon,
              },
            },
          ]}
        />
      </div>
    </PageHeading>
  );
};

export default Header;
