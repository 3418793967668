import { ShieldCheckIcon as ShieldCheckIconOutline } from '@heroicons/react/24/outline';
import { ShieldCheckIcon as ShieldCheckIconSolid } from '@heroicons/react/24/solid';

import { Button } from '@/ui/Button';

import { Typography, TypographyStack } from '../Typography';

interface Props {
  hasSubmitted: boolean;
  onClickVerify: () => void;
  isDisabled: boolean;
  isBusy: boolean;
  verificationCompleteMessage?: string;
}

const VerifyIdentity = ({ hasSubmitted, onClickVerify, isDisabled, isBusy, verificationCompleteMessage }: Props) => {
  return (
    <>
      {!hasSubmitted && (
        <Button
          variant="secondary"
          onClick={onClickVerify}
          disabled={isDisabled}
          loading={isBusy}
          Icon={ShieldCheckIconOutline}
        >
          Start Stripe Identity Verification
        </Button>
      )}
      {hasSubmitted && (
        <TypographyStack>
          <Typography size="sm" weight="semibold">
            <div className="flex items-center">
              <ShieldCheckIconSolid className="h-4 w-4 mr-1" />
              Thank you for verifying your identity
            </div>
          </Typography>

          {verificationCompleteMessage && (
            <Typography size="sm" colorWeight="500">
              {verificationCompleteMessage}
            </Typography>
          )}
        </TypographyStack>
      )}
    </>
  );
};

export default VerifyIdentity;
