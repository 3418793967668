import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';

import { Tutorial } from '../../../interfaces/tutorial';
import Badge, { BadgeProps } from '../../Badge';
import VideoModal from '../../Modal/VideoModal';

interface PageHeadingProps {
  title: string;
  description?: React.ReactNode;
  badgeText?: string;
  badgeType?: BadgeProps['type'];
  onBadgeClick?: () => void;
  badgeTooltipText?: string;
  children?: React.ReactNode;
  cta?: React.ReactNode;
  tutorial?: Tutorial;
}

const PageHeading = ({
  title,
  description,
  badgeText,
  badgeTooltipText,
  children,
  cta,
  tutorial,
  badgeType,
  onBadgeClick,
}: PageHeadingProps) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const badge = (
    <Tooltip
      id="page-heading-badge-tooltip"
      isEnabled={!!badgeTooltipText}
      text={badgeTooltipText || ''}
      showIcon={false}
      tooltipClass="text-center"
    >
      <Badge type={badgeType}>{badgeText}</Badge>
    </Tooltip>
  );

  return (
    <>
      {tutorial && (
        <VideoModal
          title={title}
          src={tutorial.url}
          isOpen={isVideoModalOpen}
          onClose={() => setIsVideoModalOpen(false)}
        />
      )}
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="w-full flex-col sm:flex-row flex justify-between mb-6 items-start">
        <div className="mb-4 sm:mb-0">
          <div className="flex items-center">
            <h1 className="text-2xl leading-6 font-extrabold text-gray-900 mr-2 flex items-center">{title}</h1>
            {onBadgeClick ? (
              <button type="button" onClick={onBadgeClick}>
                {badge}
              </button>
            ) : (
              badge
            )}
          </div>
          {description && (
            <div className="line-clamp-2 mt-2">
              <Typography weight="medium" colorWeight="700">
                {description}
              </Typography>
            </div>
          )}
          {tutorial || cta ? (
            <div className="flex gap-x-2 mt-2 items-center">
              {tutorial && (
                <button
                  type="button"
                  onClick={() => setIsVideoModalOpen(true)}
                  className="flex items-center space-x-1 text-sm text-blue-400 hover:text-blue-500"
                >
                  <AcademicCapIcon className="h-4 w-4" />
                  <span>{tutorial.cta}</span>
                </button>
              )}
              {cta}
            </div>
          ) : null}
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};

export default PageHeading;
