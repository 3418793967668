import { PropsWithChildren } from 'react';
import cx from 'classnames';

import { getColor } from '../DesignTokens';

type Props = PropsWithChildren<{
  className?: string;
}>;

export const ItemRow = ({ className, children }: Props) => (
  <tr className={cx('border-b', getColor('surface/200', 'border'), className)}>{children}</tr>
);
