import { PropsWithChildren, ReactNode } from 'react';

import Card from '@/components/Card';
import { getColor } from '@/components/DesignTokens';
import { Typography } from '@/components/Typography';

type Props = PropsWithChildren<{
  stepNumber: number;
  stepTitle: string;
  isOpen: boolean;
  footer: ReactNode;
}>;

const FormStep = ({ children, footer, stepNumber, stepTitle, isOpen }: Props) => (
  <Card noPadding background={!isOpen ? getColor('surface/200', 'background') : undefined}>
    <div className="flex flex-col">
      <div className="flex gap-x-2 p-4">
        <Typography weight="medium" colorWeight="400">
          Step {stepNumber}:
        </Typography>
        <Typography weight="medium" colorWeight={isOpen ? '700' : '400'}>
          {stepTitle}
        </Typography>
      </div>
      {isOpen ? (
        <>
          <div className="flex flex-col gap-y-6 p-4 border-t border-b">{children}</div>
          <div className="flex gap-x-2 p-4 justify-end">{footer}</div>
        </>
      ) : null}
    </div>
  </Card>
);

export default FormStep;
