import { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useOrganization } from '@/hooks';
import { usePublication } from '@/hooks/usePublications';
import { Organization } from '@/interfaces/organization';

import useApplicationSettings from '../../hooks/useApplicationSettings';
import useInterval from '../../hooks/useInterval';
import { GlobalBannerDetails } from '../../interfaces/application_settings';

const useOfferCountdown = ({ expires_at }: { expires_at: string }) => {
  const [timeLeft, setTimeLeft] = useState<number>(Math.max(moment(expires_at).diff(moment()), 0));

  useInterval(
    () => {
      setTimeLeft(Math.max(moment(expires_at).diff(moment()), 0));
    },
    timeLeft > 0 ? 1000 : null
  );

  return {
    timeLeft,
  };
};

const Banner = ({ details, organization }: { details: GlobalBannerDetails; organization: Organization }) => {
  const [hidden, setHidden] = useState(true);

  const localStorageKey = `global-banner-dismissed-${details.local_storage_key}`;
  const { timeLeft } = useOfferCountdown({ expires_at: details.expires_at });

  useEffect(() => {
    const isDismissed = !!localStorage.getItem(localStorageKey);

    if (!isDismissed) {
      setHidden(false);
    }
  }, [localStorageKey, timeLeft]);

  const handleDismiss = () => {
    localStorage.setItem(localStorageKey, 'true');
    setHidden(true);
  };

  if (timeLeft === 0) return null;
  if (hidden) return null;

  if (
    details?.before_organization_created_at &&
    organization?.created_at &&
    new Date(details?.before_organization_created_at) < new Date(organization.created_at)
  ) {
    return null;
  }

  return (
    <div className="relative bg-gray-900">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:px-16 sm:text-center">
          <p className="font-medium text-white">
            <span className="inline">{details.text}</span>
            {details.cta_url?.length > 0 ? (
              <span className="block sm:ml-2 sm:inline-block">
                <a
                  href={details.cta_url}
                  className="font-bold text-primary-400 underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {details.cta_text}
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </span>
            ) : (
              ''
            )}
          </p>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
          <button
            type="button"
            className="flex rounded-md p-2 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white"
            onClick={handleDismiss}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

const GlobalBanner = () => {
  const { data: applicationSettings } = useApplicationSettings();
  const [publicationId] = useCurrentPublicationState();
  const currentPublication = usePublication(publicationId)?.data;
  const { data: organization } = useOrganization(currentPublication?.organization_id);

  if (!applicationSettings?.global_banner) {
    return null;
  }

  if (!organization) {
    return null;
  }

  return <Banner details={applicationSettings.global_banner} organization={organization?.organization} />;
};

export default GlobalBanner;
