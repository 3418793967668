import { ArrowUpRightIcon } from '@heroicons/react/20/solid';

import { useSettings } from '@/context/settings-context';
import useFunds from '@/hooks/boosts/grow/useFunds';
import { Button } from '@/ui/Button';

const AddFundsButton = () => {
  const { settings } = useSettings();
  const { availableBalanceFormatted, canAddFunds, isProcessingCheckout, handleClickAddFunds, addFundsButtonlabel } =
    useFunds();

  if (!canAddFunds) {
    return null;
  }

  if (settings?.boosts_v2 === true) {
    return (
      <Button
        disabled={isProcessingCheckout}
        type="button"
        variant="primary"
        onClick={handleClickAddFunds}
        iconRight
        Icon={ArrowUpRightIcon}
        size="xs"
      >
        {addFundsButtonlabel}
      </Button>
    );
  }

  return (
    <Button disabled={isProcessingCheckout} type="button" onClick={handleClickAddFunds} variant="secondary">
      {addFundsButtonlabel}
      <span className="font-mono ml-2 text-gray-500">{availableBalanceFormatted}</span>
    </Button>
  );
};

export default AddFundsButton;
