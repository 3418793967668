import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CheckIcon, DocumentTextIcon, LinkIcon, LockClosedIcon } from '@heroicons/react/24/outline';

import ActionModal from '../../../../../components/ActionModal';
import Badge from '../../../../../components/Badge';
import EllipsisDropdown from '../../../../../components/EllipsisDropdown';
import { SimpleSelect } from '../../../../../components/Form';
import { useUpdateCustomPage } from '../../../../../hooks/useCustomPages';
import { useDeleteNavLink, useUpdateNavLink } from '../../../../../hooks/useNavlinks';
import { NavGroup } from '../../../../../interfaces/nav_group';
import { NavLink } from '../../../../../interfaces/nav_link';
import { Publication } from '../../../../../interfaces/publication';
import { capitalize } from '../../../../../utils';

import EditLinkPanel from './EditLinkPanel';

interface Props {
  navLink: NavLink;
  navGroups: NavGroup[];
  refetch: () => void;
  publication: Publication;
}

const NavLinkItem = ({ navLink, navGroups, refetch, publication }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);
  const [isLiveModalOpen, setIsLiveModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selection, setSelection] = useState<string | null>(null);

  const isPageLive = navLink.nav_group?.id || navLink?.custom_page?.status === 'live';
  const inNavbarTitle = `Navbar: ${navLink?.nav_group?.title}`;
  const standaloneTitle = 'Standalone Page';
  const liveTitle = navLink?.nav_group?.id ? inNavbarTitle : standaloneTitle;

  const deleteNavLink = useDeleteNavLink({
    navLinkId: navLink?.id || '',
    onSuccess: () => {
      setConfirmDelete(false);
      refetch();
    },
  });
  const handleDelete = async () => {
    deleteNavLink.mutate();
  };

  const options = [
    {
      label: 'Edit',
      onClick: navLink.external_link
        ? () => setIsOpen(true)
        : () => navigate(`/website_builder/custom_pages/${navLink.custom_page?.id}`),
    },
    {
      label: 'Delete',
      onClick: () => setConfirmDelete(true),
    },
  ];

  if (isPageLive) {
    options.unshift({
      label: 'Remove from site',
      onClick: () => setIsRemoveModalOpen(true),
    });
    if (!navLink.external_link) {
      options.unshift({
        label: 'View',
        onClick: () => window.open(`${publication.url}c/${navLink.href}` || '', '_blank'),
      });
    } else {
      options.unshift({
        label: 'View',
        onClick: () => window.open(navLink.href, '_blank'),
      });
    }
  } else {
    options.unshift({
      label: 'Publish to site',
      onClick: () => setIsLiveModalOpen(true),
    });
  }

  const navGroupOptions = navGroups
    .filter((navGroup: NavGroup) => !navGroup.is_category_group)
    .map((navGroup: NavGroup) => ({
      value: navGroup.id,
      label: `Navbar: ${capitalize(navGroup.title)}`,
    }));

  // Only internal pages can be standalone pages
  if (!navLink?.external_link) {
    navGroupOptions.push({
      value: 'live',
      label: 'Standalone Page',
    });
  }

  const updateCustomPage = useUpdateCustomPage({
    customPageId: navLink.custom_page?.id || '',
    onSuccess: () => {
      toast.success('Saved!');
      queryClient.invalidateQueries(['nav_links', publication.id]);
      queryClient.invalidateQueries(['nav_groups', publication.id]);
      setIsLiveModalOpen(false);
      setIsRemoveModalOpen(false);
    },
  });

  const updateNavLink = useUpdateNavLink({
    navLinkId: navLink?.id || '',
    onSuccess: () => {
      toast.success('Saved!');
      queryClient.invalidateQueries(['nav_links', publication.id]);
      queryClient.invalidateQueries(['nav_groups', publication.id]);
      setIsLiveModalOpen(false);
      setIsRemoveModalOpen(false);
    },
  });

  const handleSetToDraft = () => {
    if (navLink?.external_link) {
      updateNavLink.mutate({
        nav_group_id: null,
      });
    } else {
      updateCustomPage.mutate({
        custom_page: {
          status: 'draft',
        },
        nav_link: {
          nav_group_id: null,
        },
      });
    }
  };

  const handleSetLiveAction = () => {
    if (!selection) {
      toast.error('Please select an option');
    } else if (selection === 'live') {
      updateCustomPage.mutate({
        custom_page: {
          status: 'live',
        },
        nav_link: {},
      });
    } else {
      updateCustomPage.mutate({
        custom_page: {
          status: 'live',
        },
        nav_link: {
          nav_group_id: selection,
        },
      });
    }
  };

  const setLive = () => {
    if (navLink?.external_link) {
      updateNavLink.mutate({
        nav_group_id: selection,
      });
    } else {
      handleSetLiveAction();
    }
  };

  return (
    <>
      {navLink.external_link && (
        <EditLinkPanel
          navLink={navLink}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          navGroups={navGroups}
          refetch={refetch}
        />
      )}
      <ActionModal
        isOpen={isRemoveModalOpen}
        onClose={() => setIsRemoveModalOpen(false)}
        onProceed={handleSetToDraft}
        resourceId={navLink.id}
        isWorking={updateCustomPage.isLoading || updateNavLink.isLoading}
        headerText="Remove from Website"
        actionText="Set to Draft"
      >
        <p className="text-sm">
          Are you sure you want to remove this page from your site. It will be set back into a draft state and you can
          always re-add it later.
        </p>
      </ActionModal>
      <ActionModal
        isOpen={isLiveModalOpen}
        onClose={() => {
          setIsLiveModalOpen(false);
          setSelection(null);
        }}
        onProceed={setLive}
        resourceId={navLink.id}
        isWorking={updateCustomPage.isLoading || updateNavLink.isLoading}
        headerText="Add to Website"
        actionText="Set Live"
      >
        <div className="py-4">
          <SimpleSelect
            name="nav_group_id"
            optionsContainerClassNames={{
              height: 'max-h-40',
            }}
            value={selection || ''}
            onSelect={(_name: string, value: string) => setSelection(value)}
            helperText="Choose a destination for this page. You can either add it to a tab in the navbar so it is easily accesible from the homepage, or you can add it as a standalone webpage, which will not appear in your navigation."
            options={navGroupOptions}
          />
        </div>
      </ActionModal>
      <ActionModal
        isOpen={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onProceed={handleDelete}
        resourceId={navLink.id}
        isWorking={deleteNavLink.isLoading}
        headerText="Delete"
        actionText="Delete"
        buttonType="danger"
      >
        <p className="text-sm">
          {navLink.external_link
            ? 'Are you sure you want to delete this link?'
            : 'Are you sure you want to delete this page? You will not be able to recover your work.'}
        </p>
      </ActionModal>
      <div key={navLink.id} className="flex justify-between w-full border border-surface-200 rounded-lg shadow-sm p-4">
        <div className="flex space-x-1">
          <div className="space-y-2">
            <div className="space-x-1 flex">
              <div className="pt-0.5">
                {navLink.external_link ? (
                  <LinkIcon className="w-4 h-4 mr-1" />
                ) : (
                  <DocumentTextIcon className="w-4 h-4 mr-1" />
                )}
              </div>
              <p className="block text-sm font-semibold text-gray-700">{navLink.cta_text}</p>
            </div>
            {isPageLive ? (
              <Badge type="success">
                <CheckIcon className="w-4 h-4 text-feedback-success-600 mr-1" />
                {liveTitle}
              </Badge>
            ) : (
              <Badge type="information">Draft</Badge>
            )}
            {navLink?.custom_page?.premium && (
              <span className="flex text-blue-500 items-center text-sm font-medium">
                <LockClosedIcon className="h-3.5 w-3.5 mr-1" />
                Premium
              </span>
            )}
          </div>
        </div>

        <EllipsisDropdown portalMountedId={navLink.id} options={options} />
      </div>
    </>
  );
};

export default NavLinkItem;
