import ProtectedAction from '../../../../../components/ProtectedAction';
import { useCurrentPublication } from '../../../../../hooks';
import { useDeleteOrganization } from '../../../../../hooks/useOrganization';

const DeleteOrganization = () => {
  const { data: currentPublication } = useCurrentPublication();
  const deleteOrganization = useDeleteOrganization(currentPublication?.organization_id);

  const handleDeleteOrg = async () => {
    await deleteOrganization.mutateAsync();
  };

  return (
    <ProtectedAction
      buttonText="Delete Organization"
      isSubmitting={deleteOrganization.isLoading}
      onProceed={handleDeleteOrg}
      resourceId="delete_organization"
      modalDescription="Are you sure you want to delete this organization? All of the related publications will be deleted as well."
    />
  );
};

export default DeleteOrganization;
