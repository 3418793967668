import Section from '@/routes/settings/_components/Section';
import { capitalize } from '@/utils';
import { PLAN } from '@/utils/plans';

import { usePlanPrice } from '../../_hooks';

import CancelDowngradeModal from './CancelDowngradeModal';
import CustomPlanDowngradeModal from './CustomPlanDowngradeModal';
import DowngradeModal from './DowngradeModal';

const CancellationSection: React.FC<{ organizationId: string }> = ({ organizationId }) => {
  const { data: planPrice } = usePlanPrice({ organizationId });
  const { data: upcomingPlanPrice } = usePlanPrice({ organizationId, upcoming: true });

  if (!planPrice || !upcomingPlanPrice || planPrice?.plan_name === PLAN.LAUNCH) {
    return null;
  }

  const intervalText = (interval: string) => `${interval}ly`;

  const isPendingDowngrade = planPrice.id !== upcomingPlanPrice.id;

  const sectionDescription = isPendingDowngrade
    ? `You are currently scheduled to be downgraded to the ${intervalText(upcomingPlanPrice.interval)} ${capitalize(
        upcomingPlanPrice.plan_name
      )} at the end of your current billing period.`
    : 'Your current plan will remain active until the end of your current billing period.';

  return (
    <Section
      title="Cancellation Actions"
      className="max-w-3xl"
      description={`Please note, these actions are irreversible and may take a few minutes to complete. ${sectionDescription}`}
    >
      {!isPendingDowngrade &&
        (planPrice.plan_name === PLAN.GROW ||
          planPrice.plan_name === PLAN.SCALE ||
          planPrice.plan_name === PLAN.MAX) && <DowngradeModal organizationId={organizationId} planPrice={planPrice} />}
      {!isPendingDowngrade && planPrice.plan_name === PLAN.CUSTOM && <CustomPlanDowngradeModal />}
      {isPendingDowngrade && (
        <CancelDowngradeModal
          organizationId={organizationId}
          currentPlanPrice={planPrice}
          upcomingPlanPrice={upcomingPlanPrice}
        />
      )}
    </Section>
  );
};

export default CancellationSection;
