import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '@phosphor-icons/react';

import { useCreateSite } from '@/hooks/useSite';

import { Button } from '../_components/UI/Button';
import { Text } from '../_components/UI/Text';

const WebsiteOnboardingPage = () => {
  const navigate = useNavigate();
  const createSite = useCreateSite();

  const createSiteMutation = async () => {
    await createSite.mutateAsync();
  };

  return (
    <div className="w-screen h-screen flex flex-col">
      <div className="w-full p-4">
        <Button
          variant="outlined"
          Icon={ArrowLeft}
          onClick={() => {
            navigate('/');
          }}
        >
          Back to dashboard
        </Button>
      </div>
      <div className="w-screen h-screen flex flex-col items-center justify-center gap-4">
        <Text size="xl">Website Onboarding</Text>
        <Text size="md" variant="secondary">
          There&apos;s no site yet for this publication
        </Text>
        <Button variant="primary" onClick={createSiteMutation}>
          Create Site
        </Button>
      </div>
    </div>
  );
};

export default () => <WebsiteOnboardingPage />;
