import { PluginKey } from '@tiptap/pm/state';
import { ReactRenderer } from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import tippy, { Instance as TippyInstance } from 'tippy.js';

import { SuggestionsModal } from './components/SuggestionsModal';

export const suggestions = (container: () => HTMLElement): Omit<SuggestionOptions<any>, 'editor'> => ({
  char: '@',
  pluginKey: new PluginKey('commentMention'),
  allowSpaces: true,
  decorationClass: 'mention-suggestion-pending',
  items: () => {
    return [];
  },
  render() {
    let component: ReactRenderer | null = null;
    let popup: TippyInstance[] | null = null;

    return {
      onStart: (props) => {
        component = new ReactRenderer(SuggestionsModal, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect || !component.element) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect as () => DOMRect,
          appendTo: container,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component?.updateProps(props);

        if (!props.clientRect || !popup) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect as () => DOMRect,
        });
      },

      onExit() {
        if (popup) {
          popup[0].destroy();
        }

        component?.destroy();
      },
      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup?.[0].hide();

          return true;
        }

        return false;
      },
    };
  },
});
