import { useNavigate, useParams } from 'react-router-dom';

import { Typography, TypographyStack } from '@/components/Typography';
import { StripeMigrationStatus } from '@/interfaces/stripe';
import { Button } from '@/ui/Button';

import Wizard from '../../_components/Wizard';
import useTransitionStripeMigration from '../../_hooks/useTransitionStripeMigration';

import PreviewSubscriptionsTable from './_components/PreviewSubscriptionsTable';

const StripeMigrationWizardStepFour = () => {
  const { stripeMigrationId } = useParams();
  const navigate = useNavigate();

  const { mutate: startMigration, isLoading: isStartingMigration } = useTransitionStripeMigration({
    id: stripeMigrationId,
    status: StripeMigrationStatus.PROCESSING,
    onSuccess: () => navigate(`/stripe_migrations/${stripeMigrationId}/finished`),
  });

  const { mutate: goBack, isLoading: isGoingBack } = useTransitionStripeMigration({
    id: stripeMigrationId,
    status: StripeMigrationStatus.PENDING,
    onSuccess: () => navigate(`/stripe_migrations/${stripeMigrationId}/map_products`),
  });

  return (
    <Wizard step={4}>
      <div className="mt-4 py-4 px-4 sm:px-6 space-y-6">
        <TypographyStack gap="4">
          <Typography>
            Review the list below and compare it to your current list of premium subscribers on your other platform.
            Make sure the results align to what you would expect. If the count is lower than expected, you may not have
            mapped all the necessary products from the previous step.
          </Typography>
          <Typography>
            When you select &quot;Start Import&quot; we will ensure all subscriptions are added in beehiiv as premium
            and create corresponding subscriptions on Stripe. All subscriptions will be set up to{' '}
            <strong>not charge until their current period ends</strong>, which will allow them to be premium on beehiiv
            during the interim and their payments pick up on beehiiv starting next period. Any coupons used on the
            original subscription will be copied over assuming they are still valid and applicable to other products.
          </Typography>
          <Typography>
            To ensure that no email receipts or correspondence will be sent to them until their new period starts,
            please update your{' '}
            <a
              href="https://dashboard.stripe.com/settings/emails"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Stripe email settings
            </a>{' '}
            to not send for Successful payments (you will want to update this back once this process is complete).
          </Typography>
        </TypographyStack>
        <PreviewSubscriptionsTable />
        <div className="mt-4 py-4 flex justify-end">
          <Button variant="primary-inverse" onClick={() => goBack()} loading={isGoingBack}>
            Back
          </Button>
          <Button variant="primary" className="ml-2" onClick={() => startMigration()} loading={isStartingMigration}>
            Start Import
          </Button>
        </div>
      </div>
    </Wizard>
  );
};

export default StripeMigrationWizardStepFour;
