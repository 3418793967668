import { useState } from 'react';

import Modal from '@/components/Modal';
import Text from '@/components/Text';
import { Button } from '@/ui/Button';

const CustomPlanDowngradeModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="p-8 max-w-2xl">
          <div className="space-y-4">
            <Text size="lg" type="bold" className="text-gray-800">
              Downgrading your enterprise plan
            </Text>
            <p className="text-base text-gray-500">
              Please contact{' '}
              <a href="mailto:support@beehiiv.com" className="text-primary-500 underline hover:text-primary-700">
                support@beehiiv.com
              </a>{' '}
              to modify your plan.
            </p>
          </div>
        </div>
      </Modal>
      <Button type="button" variant="danger" onClick={() => setIsOpen(true)}>
        Downgrade
      </Button>
    </>
  );
};

export default CustomPlanDowngradeModal;
