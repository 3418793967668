import { useState } from 'react';
import { useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';
import { PublicationLogo } from '@/components/PublicationLogo';
import useDisbursementPayout from '@/hooks/useAdNetwork/internal/useDisbursementPayout';
import useOpportunityStats from '@/hooks/useAdNetwork/internal/useOpportunityStats';
import { AdNetworkDisbursement } from '@/interfaces/ad_network/internal/disbursement';
import {
  AdNetworkDisbursementPayoutAdjustmentReason,
  AdNetworkDisbursementPayoutAdjustmentReasonLabelHash,
} from '@/interfaces/ad_network/internal/disbursement/types';

interface Props {
  disbursement: AdNetworkDisbursement;
  modalOpen: boolean;
  onClose: () => void;
}

const DisbursementImmediatePaymentModal = ({ disbursement, modalOpen, onClose }: Props) => {
  const { opportunity } = disbursement;
  const { publication, advertiser, campaign } = opportunity;
  const [isPaying, setIsPaying] = useState(false);
  useState<AdNetworkDisbursementPayoutAdjustmentReason | null>(disbursement.payout_adjustment_reason);
  const { data: stats } = useOpportunityStats({ id: opportunity.id });
  const hasAdjustedClicks = stats?.total_unique_eligible_clicked !== disbursement.approved_clicks;
  const hasAdjustedAmount = disbursement.approved_amount_cents !== disbursement.amount_cents;
  const modalId = `approve-disbursement-${disbursement.id}`;
  const queryClient = useQueryClient();

  const numberFormatter = new Intl.NumberFormat();

  const onTransitionSuccess = () => {
    onClose();
    setIsPaying(false);
    queryClient.invalidateQueries(['ad_network', 'internal', 'disbursements']);
  };
  const payoutDisbursement = useDisbursementPayout({ id: disbursement.id, onSuccess: onTransitionSuccess });
  const handleTransitionDisbursement = () => {
    setIsPaying(true);
    payoutDisbursement.mutateAsync();
  };

  return (
    <ActionModal
      resourceId={disbursement.id}
      isOpen={modalOpen}
      isWorking={isPaying}
      onClose={onClose}
      onProceed={handleTransitionDisbursement}
      headerText="Pay Disbursement Immediately"
      actionText="Pay Now"
      bodyId={modalId}
    >
      <div className="space-y-4">
        <div className="text-sm">
          <p className="text-xl mb-4">Publication</p>
          <div className="flex flex-row items-center">
            <PublicationLogo
              publication={{ logo_url: publication.logo.url, name: publication.name }}
              size="sm"
              className="mr-2"
            />
            <div>
              <p>{publication.name}</p>
              <p className="text-xs text-gray-500">{publication.owner_email}</p>
            </div>
          </div>
        </div>
        <div className="text-sm">
          <p className="text-xl mb-4">Advertiser</p>
          <div className="flex flex-col">
            <div className="mr-2">
              <img src={advertiser.logo.url} alt={advertiser.name} className="border border-gray-100 rounded h-8" />
            </div>
            <div>
              <p>{advertiser.name}</p>
              <p className="text-xs text-gray-500">{campaign.name}</p>
            </div>
          </div>
        </div>
        <div className="text-sm">
          <div className="space-y-2">
            <div className="flex flex-row items-center justify-between">
              <p>Publisher CPC</p>
              <p>{opportunity.payout_per_click}</p>
            </div>

            <div className="flex flex-row items-center justify-between">
              <p>{hasAdjustedClicks ? 'Adjusted Clicks' : 'Clicks'}</p>
              {hasAdjustedClicks ? (
                <p className="space-x-2">
                  <span className="line-through">
                    {numberFormatter.format(stats?.total_unique_eligible_clicked || 0)}
                  </span>
                  <span>{numberFormatter.format(disbursement.approved_clicks || 0)}</span>
                </p>
              ) : (
                <p>{numberFormatter.format(stats?.total_unique_eligible_clicked || 0)}</p>
              )}
            </div>

            <div className="flex flex-row items-center justify-between">
              <p>{hasAdjustedAmount ? 'Adjusted Payout' : 'Total Payout'}</p>
              {hasAdjustedAmount ? (
                <p className="space-x-2">
                  <span className="line-through">{disbursement.amount}</span>
                  <span>{disbursement.approved_amount}</span>
                </p>
              ) : (
                <p>{disbursement.amount}</p>
              )}
            </div>

            {disbursement.payout_adjustment_reason && (
              <div className="flex flex-row items-center justify-between">
                <p>Adjustment Reason</p>
                <p>{AdNetworkDisbursementPayoutAdjustmentReasonLabelHash[disbursement.payout_adjustment_reason]}</p>
              </div>
            )}
          </div>
        </div>
        <div className="text-sm mt-4 space-y-2">
          <p>
            Clicking &quot;Pay Now&quot; will bypass the 20th payday and immediately pay{' '}
            <span className="font-bold">{publication.name}</span> the approved amount of{' '}
            <span className="font-bold">{disbursement.approved_amount}</span>
          </p>
          <p>
            Are you sure you want to <span className="font-bold">immediately</span> pay this disbursement?
          </p>
        </div>
      </div>
    </ActionModal>
  );
};

export default DisbursementImmediatePaymentModal;
