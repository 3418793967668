import { Tab } from '@headlessui/react';
import cx from 'classnames';

import { WalkthroughPortal } from '@/components/Walkthrough';
import { useWalkthroughContext } from '@/context/walkthrough-context';

import useDashboardUrlState from '../../hooks/useDashboardUrlState';
import { TabTypes } from '../../types';

const SectionTab = ({ text, onClick }: { text: string; onClick: () => void }) => {
  return (
    <Tab
      onClick={onClick}
      className={({ selected }) =>
        cx(
          'w-full rounded-md py-2 text-sm font-medium leading-5',
          'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none',
          selected ? 'bg-white shadow text-action-primary-900' : 'hover:bg-surface-200'
        )
      }
    >
      {text}
    </Tab>
  );
};

const SectionTabList = ({ tabList }: { tabList: string[] }) => {
  const { handleSetTab } = useDashboardUrlState();

  return (
    <Tab.List className="flex space-x-1 max-w-md rounded-lg p-1.5">
      {tabList.map((category: string) => (
        <SectionTab key={category} text={category} onClick={() => handleSetTab(category as TabTypes)} />
      ))}
    </Tab.List>
  );
};

type Props = React.PropsWithChildren<{ tabList: string[]; actionChildren?: React.ReactNode; className?: string }>;
type NoListProps = Omit<Props, 'tabList'>;

const SectionTabPanel = ({ children }: NoListProps) => {
  return <Tab.Panel className="w-full">{children}</Tab.Panel>;
};

const SectionTabs = ({ children, tabList, actionChildren }: Props) => {
  const { currentTab } = useDashboardUrlState();
  const { currentStep, handleNextStep, handlePreviousStep, handleFinishWalkthrough } = useWalkthroughContext();
  const defaultIndex = currentTab ? tabList.indexOf(currentTab) : 0;

  return (
    <Tab.Group defaultIndex={defaultIndex}>
      <div className="flex flex-col space-y-6">
        <WalkthroughPortal
          isActive={currentStep === 6}
          title="Analytics Navigation"
          description="Navigate between tabs to see targeted areas of data analytics to help you manage and grow your newsletter on the dashboard."
          arrowDirection="top"
          hasOverlay
          currentStep={6}
          totalSteps={7}
          continueButton={{
            text: 'Next',
            onClick: () => handleNextStep(),
          }}
          backButton={{
            text: 'Back',
            onClick: () => handlePreviousStep('/automations'),
          }}
          onClose={handleFinishWalkthrough}
        >
          <div className="flex space-x-2 justify-between items-center grid lg:grid-cols-3 grid-cols-1">
            <div className="flex justify-between col-span-2 items-center lg:pr-2.5 pr-0">
              <div className="w-full flex items-end flex-col lg:items-center lg:flex-row rounded-lg bg-surface-100">
                <div className="w-full">
                  <SectionTabList tabList={tabList} />
                </div>
                {actionChildren && <div className="flex lg:w-fit lg:p-0 p-2">{actionChildren}</div>}
              </div>
            </div>
          </div>
        </WalkthroughPortal>
        <Tab.Panels className="mt-12">{children}</Tab.Panels>
      </div>
    </Tab.Group>
  );
};

export { SectionTabPanel, SectionTabs };
