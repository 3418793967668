import cx from 'classnames';

import useNotifications from '@/hooks/useNotifications';

const UnreadCountBadge = () => {
  const { data } = useNotifications();

  const unreadCount = data?.pages[0]?.meta?.total_unread_count || 0;

  if (unreadCount === 0) return null;

  return (
    <div
      className={cx(
        'inline-block ml-2 py-0.5 rounded-full text-xs mr-2 font-semibold text-white bg-[#BE185D]',
        unreadCount > 9 ? 'px-1.5' : 'px-2'
      )}
    >
      {unreadCount > 99 ? '99+' : unreadCount}
    </div>
  );
};

export default UnreadCountBadge;
