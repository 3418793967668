import { PropsWithChildren, useEffect } from 'react';

type Props = PropsWithChildren<{
  elementId?: string;
  scrollIntoView?: boolean;
}>;

export default function ScrollOnMount({ children, elementId, scrollIntoView = false }: Props) {
  useEffect(() => {
    if (elementId) {
      const element = window?.document?.getElementById(elementId);

      if (scrollIntoView) {
        element?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
        return;
      }

      element?.scrollTo(0, 0);
    }
  }, [elementId, scrollIntoView]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
