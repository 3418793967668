import { useMemo } from 'react';
import uniqBy from 'lodash.uniqby';

import { SortableList, SortableListItem, useSortableList } from '@/components/SortableList';
import { Tier } from '@/interfaces/tier';
import { SortableTierItem } from '@/interfaces/upgrade_page';

interface Props {
  tiersOrder: SortableTierItem[];
  tiers: Tier[];
  onSave: (newList: string[]) => void;
  isFreeTierEnabled: boolean;
}

const SortableTiers = ({ tiersOrder, tiers, onSave, isFreeTierEnabled }: Props) => {
  const handleChangeInTiersOrder = (newList: string[]) => {
    onSave(newList);
  };

  const tierById: {
    [key: string]: Tier;
  } = useMemo(
    () =>
      tiers.reduce(
        (pv, cv) => ({
          ...pv,
          [cv.id]: cv,
        }),
        {}
      ),
    [tiers]
  );

  const mappedSortableTierItems = tiers
    .map((tier) => {
      if (!tierById[tier.id]) {
        return undefined;
      }
      return {
        id: tier.id,
        value: tierById[tier.id].name,
      };
    })
    .filter(Boolean) as SortableTierItem[];

  const mappedLocalList: SortableTierItem[] =
    // eslint-disable-next-line no-nested-ternary
    tiersOrder.length > 0
      ? [...tiersOrder, ...mappedSortableTierItems]
      : isFreeTierEnabled
      ? [{ id: 'free', value: 'Free' }, ...mappedSortableTierItems]
      : mappedSortableTierItems;

  const { sortableList, setSortableList } = useSortableList<SortableTierItem>({
    list: uniqBy(mappedLocalList, 'id'),
    addIdToListOfStrings: false,
  });

  return (
    <div className="relative curser-pointer mt-2">
      <SortableList
        listItems={sortableList}
        onItemsSorted={(sortedList: any[]) => {
          setSortableList(sortedList);
          handleChangeInTiersOrder(sortedList);
        }}
      >
        {(list) => {
          return list.map((item: SortableTierItem) => {
            return <SortableListItem key={item.id} listItem={item} text={item.value} />;
          });
        }}
      </SortableList>
    </div>
  );
};

export default SortableTiers;
