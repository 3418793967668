import { FC } from 'react';

import Drawer from '../../../components/Drawer';

interface Props {
  children: React.ReactNode;
  scroll_id?: string;
  title: string;
  active?: boolean;
  onClickHandler: (activeValue: any) => void;
}

const ThemeFormDrawer: FC<Props> = ({ scroll_id, title, active = false, children, onClickHandler }) => {
  return (
    <Drawer
      buttonClassName="border-b flex p-5 justify-between items-center w-full bg-gray-100 sticky top-0 z-10"
      chevronRight
      active={active}
      onClickHandler={onClickHandler}
    >
      <Drawer.Title>
        <div id={scroll_id} className="flex items-center space-x-2 text-xs">
          <div>{title.toUpperCase()}</div>
        </div>
      </Drawer.Title>
      <Drawer.Content className="block p-4">{children}</Drawer.Content>
    </Drawer>
  );
};

export default ThemeFormDrawer;
