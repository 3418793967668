import { useMemo, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Card } from '@tremor/react';

import { Input } from '@/components/Form';
import SortableStringList from '@/components/SortableStringLIst';
import { Typography, TypographyStack } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import Section from '@/routes/settings/_components/Section';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { useTierConfigurationContext } from '../../_context/tier_configuration_context';
import useTierFormStep from '../../_hooks/useTierFormStep';

const Benefits = () => {
  const { settings } = useSettings();
  const {
    monthlyBenefits,
    setMonthlyBenefits,
    annualBenefits,
    setAnnualBenefits,
    oneTimeBenefits,
    setOneTimeBenefits,
    donationBenefits,
    setDonationBenefits,
  } = useTierConfigurationContext();
  const { onFocus, onBlur } = useTierFormStep('benefits');
  const maxBenefits = settings?.max_premium_benefits || 6;

  const [newMonthlyBenefit, setNewMonthlyBenefit] = useState<string>('');
  const [newAnnualBenefit, setNewAnnualBenefit] = useState<string>('');
  const [newOneTimeBenefit, setNewOneTimeBenefit] = useState<string>('');
  const [newDonationBenefit, setNewDonationBenefit] = useState<string>('');

  const addNewMonthlyBenefit = () => {
    setMonthlyBenefits((curr: any) => curr.concat([newMonthlyBenefit]));
    setNewMonthlyBenefit('');
    analytics.track('added a monthly benefit');
  };

  const addNewAnnualBenefit = () => {
    setAnnualBenefits((curr: any) => curr.concat([newAnnualBenefit]));
    setNewAnnualBenefit('');
    analytics.track('added an annual benefit');
  };

  const addNewOneTimeBenefit = () => {
    setOneTimeBenefits((curr: any) => curr.concat([newOneTimeBenefit]));
    setNewOneTimeBenefit('');
    analytics.track('added a one time benefit');
  };

  const addNewDonationBenefit = () => {
    setDonationBenefits((curr: any) => curr.concat([newDonationBenefit]));
    setNewDonationBenefit('');
    analytics.track('added a name your price benefit');
  };

  const allowMoreMonthlyBenefits = useMemo(() => monthlyBenefits.length < maxBenefits, [monthlyBenefits, maxBenefits]);
  const allowMoreAnnualBenefits = useMemo(() => annualBenefits.length < maxBenefits, [annualBenefits, maxBenefits]);
  const allowMoreOneTimeBenefits = useMemo(() => oneTimeBenefits.length < maxBenefits, [oneTimeBenefits, maxBenefits]);
  const allowMoreDonationBenefits = useMemo(
    () => donationBenefits.length < maxBenefits,
    [donationBenefits, maxBenefits]
  );

  return (
    <div onFocus={onFocus} onBlur={onBlur}>
      <Section
        title="Plan Benefits"
        description="Describe what your subscribers can expect to get such as ad-free content,
        behind the scenes, exclusive stories, archival access, or subscriber only events."
        maxWidthClassName="max-w-4xl"
      >
        <div className="space-y-4">
          <div className="flex flex-col space-y-4">
            <Card className="space-y-4">
              <TypographyStack gap="1">
                <Typography token="font-medium/text/base" className="capitalize">
                  Monthly Benefits
                </Typography>
                <Typography token="font-light/text/xs">
                  Customize the benefits that will be displayed when subscribers select the monthly plan
                </Typography>
              </TypographyStack>
              {allowMoreMonthlyBenefits && (
                <>
                  <Input
                    labelText=""
                    name="monthly_benefit"
                    placeholder="Monthly Benefit"
                    className="mt-6"
                    value={newMonthlyBenefit}
                    onChange={(e) => setNewMonthlyBenefit(e.target.value)}
                    onKeyDown={(e) => newMonthlyBenefit && e.key === 'Enter' && addNewMonthlyBenefit()}
                  />
                  <div className="mt-2 flex justify-end">
                    <Button
                      type="button"
                      variant="primary-inverse"
                      Icon={PlusIcon}
                      size="xs"
                      onClick={addNewMonthlyBenefit}
                      disabled={!newMonthlyBenefit}
                    >
                      Add benefit
                    </Button>
                  </div>
                </>
              )}
              <div className="mt-4">
                <SortableStringList
                  id="monthly-benefits-list"
                  stringList={monthlyBenefits}
                  onListChange={setMonthlyBenefits}
                  variant="secondary"
                />
              </div>
            </Card>

            <Card className="space-y-4">
              <TypographyStack gap="1">
                <Typography token="font-medium/text/base" className="capitalize">
                  Yearly Benefits
                </Typography>
                <Typography token="font-light/text/xs">
                  Customize the benefits that will be displayed when subscribers select the yearly plan
                </Typography>
              </TypographyStack>
              {allowMoreAnnualBenefits && (
                <>
                  <Input
                    labelText=""
                    name="annual_benefit"
                    placeholder="Annual Benefit"
                    className="mt-6"
                    value={newAnnualBenefit}
                    onChange={(e) => setNewAnnualBenefit(e.target.value)}
                    onKeyDown={(e) => newAnnualBenefit && e.key === 'Enter' && addNewAnnualBenefit()}
                  />
                  <div className="mt-2 flex justify-end">
                    <Button
                      type="button"
                      variant="primary-inverse"
                      Icon={PlusIcon}
                      size="xs"
                      onClick={addNewAnnualBenefit}
                      disabled={!newAnnualBenefit}
                    >
                      Add benefit
                    </Button>
                  </div>
                </>
              )}
              <div className="mt-4">
                <SortableStringList
                  id="annual-benefits-list"
                  stringList={annualBenefits}
                  onListChange={setAnnualBenefits}
                  variant="secondary"
                />
              </div>
            </Card>
          </div>
          {settings?.one_time_payments && (
            <div className="flex flex-col space-y-4">
              <Card className="space-y-4">
                <TypographyStack gap="1">
                  <Typography token="font-medium/text/base" className="capitalize">
                    One Time Purchase Benefits
                  </Typography>
                  <Typography token="font-light/text/xs">
                    Customize the benefits that will be displayed when subscribers selects a one time purchase
                  </Typography>
                </TypographyStack>
                {allowMoreOneTimeBenefits && (
                  <>
                    <Input
                      labelText=""
                      name="one-time-benefit"
                      placeholder="One Time Purchase Benefit"
                      className="mt-6"
                      value={newOneTimeBenefit}
                      onChange={(e) => setNewOneTimeBenefit(e.target.value)}
                      onKeyDown={(e) => newOneTimeBenefit && e.key === 'Enter' && addNewOneTimeBenefit()}
                    />
                    <div className="mt-2 flex justify-end">
                      <Button
                        type="button"
                        variant="primary-inverse"
                        Icon={PlusIcon}
                        size="xs"
                        onClick={addNewOneTimeBenefit}
                        disabled={!newOneTimeBenefit}
                      >
                        Add benefit
                      </Button>
                    </div>
                  </>
                )}
                <div className="mt-4">
                  <SortableStringList
                    id="one-time-benefits-list"
                    stringList={oneTimeBenefits}
                    onListChange={setOneTimeBenefits}
                    variant="secondary"
                  />
                </div>
              </Card>
            </div>
          )}
          {settings?.donation_payments && (
            <div className="flex flex-col space-y-4">
              <Card className="space-y-4">
                <TypographyStack gap="1">
                  <Typography token="font-medium/text/base" className="capitalize">
                    Name Your Price Purchase Benefits
                  </Typography>
                  <Typography token="font-light/text/xs">
                    Customize the benefits that will be displayed when subscribers selects a name your price purchase
                  </Typography>
                </TypographyStack>
                {allowMoreDonationBenefits && (
                  <>
                    <Input
                      labelText=""
                      name="donation-benefit"
                      placeholder="Name Your Price Purchase Benefit"
                      className="mt-6"
                      value={newDonationBenefit}
                      onChange={(e) => setNewDonationBenefit(e.target.value)}
                      onKeyDown={(e) => newDonationBenefit && e.key === 'Enter' && addNewDonationBenefit()}
                    />
                    <div className="mt-2 flex justify-end">
                      <Button
                        type="button"
                        variant="primary-inverse"
                        Icon={PlusIcon}
                        size="xs"
                        onClick={addNewDonationBenefit}
                        disabled={!newDonationBenefit}
                      >
                        Add benefit
                      </Button>
                    </div>
                  </>
                )}
                <div className="mt-4">
                  <SortableStringList
                    id="one-time-benefits-list"
                    stringList={donationBenefits}
                    onListChange={setDonationBenefits}
                    variant="secondary"
                  />
                </div>
              </Card>
            </div>
          )}
        </div>
      </Section>
    </div>
  );
};

export default Benefits;
