import { PLAN } from '@/utils/plans';

type Features = {
  [category: string]: string[];
};

type PlanFeatures = {
  features: Features;
};

const usePlanFeatures = ({ planName }: { planName?: string }): PlanFeatures => {
  if (planName === PLAN.SCALE) {
    return {
      features: {
        Website: ['Surveys', 'New website templates', 'Custom webpages'],
        Newsletter: ['Audience polls', 'A/B testing', 'beehiiv AI', 'Publish to Segments'],
        Growth: ['Referral Program', 'Boosts'],
        Monetization: ['Ad Network', 'Premium Subscriptions', 'Boosts'],
        Analytics: ['3D Analytics'],
      },
    };
  }

  if (planName === PLAN.MAX) {
    return {
      features: {
        Website: ['Surveys', 'New website templates', 'Custom webpages'],
        Newsletter: ['Audience polls', 'A/B testing', 'beehiiv AI', 'Publish to Segments'],
        Growth: ['Referral Program', 'Boosts'],
        Monetization: ['Ad Network', 'Premium Subscriptions', 'Boosts'],
        Analytics: ['3D Analytics'],
        Support: ['Priority support'],
        Other: ['Remove beehiiv branding', 'NewsletterXP course'],
      },
    };
  }

  return {
    features: {},
  };
};

export default usePlanFeatures;
