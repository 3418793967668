import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Price, Tier } from '@/interfaces/tier';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

type PriceCreateProps = Omit<Price, 'id'>;

interface Variables {
  name: string;
  description?: string;
  prices_attributes: PriceCreateProps[];
}

interface Props {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

const useCreateTier = ({ onSuccess, onError }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const updateTier = (variables: Variables) =>
    api
      .post(`/tiers`, {
        publication_id: currentPublicationId,
        tier: variables,
      })
      .then((res) => res.data);

  return useMutation<Tier, unknown, Variables>(updateTier, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['publication_settings', 'premium', 'tiers', currentPublicationId]);
      toast.success('Tier Created!');
      analytics.track('created a premium tier');
      onSuccess?.();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.errors?.base || 'Something went wrong, please try again.');
      onError?.(err?.response?.data?.errors);
    },
  });
};

export default useCreateTier;
