import { useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';

import { Button } from '@/ui/Button';

import { Icon } from '../../TiptapEditor/components/ui/Icon';

export type UploadButtonProps = {
  onUpload: (files: FileList) => void;
};

const UploadIcon = () => <Icon name="Upload" className="w-4 h-4" />;

export default function UploadButton({ onUpload }: UploadButtonProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;

      if (!files) {
        return;
      }

      onUpload(files);
    },
    [onUpload]
  );

  const allowedFileTypes = 'image/jpeg, image/png, image/gif';

  const inputElement = createPortal(
    <div className="absolute w-0 h-0 overflow-hidden">
      <input type="file" onChange={handleChange} ref={inputRef} accept={allowedFileTypes} multiple />
    </div>,
    document.body
  );

  return (
    <>
      <Button type="button" onClick={handleClick} variant="flush" size="xxs" Icon={UploadIcon}>
        Upload assets
      </Button>
      {inputElement}
    </>
  );
}
