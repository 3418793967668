import React from 'react';

import { Input } from '../../../../../../components/Form';

interface Props {
  url: string;
  onUrlChange: (str: string) => void;
}

const Revue: React.FunctionComponent<Props> = ({ url, onUrlChange }: Props) => (
  <div>
    <p className="font-medium">Step 1</p>
    <p className="mt-2 mb-4 text-sm text-gray-500">
      Ensure that your publication&apos;s profile page is accessible on Revue. This can be done in Revue by going to{' '}
      <b>Account Settings</b> &gt; <b>Profile</b> &gt; <b>Profile Page</b> and selecting <b>Show past issues</b>. Make
      sure to click Save.
    </p>
    <p className="mt-12 font-medium">Step 2</p>
    <p className="mt-2 mb-4 text-sm text-gray-500">
      Input your publication&apos;s profile page URL below. This will be used to pull content and information about your
      publication during the import process.
    </p>
    <Input
      name="url"
      labelText="Profile Page URL"
      value={url}
      placeholderText="https://www.getrevue.co/profile/publication"
      onChange={(e) => onUrlChange(e.target.value)}
    />
  </div>
);

export default Revue;
