import { useState } from 'react';

import { useSettings } from '@/context/settings-context';
import { StripeAccountStatus, StripeAccountType } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import useCreateConnectAccountLoginLink from '@/hooks/stripe/useCreateConnectAccountLoginLink';
import { Section } from '@/pages/Settings/Components';
import { Button } from '@/ui/Button';

interface Props {
  boostsAccountStatus: StripeAccountStatus | undefined;
  boostsAccountType: StripeAccountType | undefined | null;
  isLoading: boolean;
}

const Manage = ({ boostsAccountStatus, boostsAccountType, isLoading }: Props) => {
  const { settings } = useSettings();
  const shouldRender =
    settings?.wallet &&
    boostsAccountType === StripeAccountType.EXPRESS &&
    boostsAccountStatus === StripeAccountStatus.ACTIVE;

  const [isViewing, setIsViewing] = useState<boolean>(false);
  const { mutateAsync: createLoginLink } = useCreateConnectAccountLoginLink({
    onSuccess: (res) => {
      window.open(`${res.url}`, '_blank');
    },
  });

  const manageExpressAccount = () => {
    setIsViewing(true);
    createLoginLink();
    setIsViewing(false);
  };

  if (!shouldRender) return null;

  return (
    <Section
      title="View Stripe Express Account"
      description="View balance and payouts on your Stripe Express account. Manage account settings."
      scrollToId="view-express-account"
      isLoading={isLoading}
    >
      <Button
        variant="primary-inverse"
        type="submit"
        loading={isViewing}
        disabled={isViewing}
        onClick={() => manageExpressAccount()}
      >
        {' '}
        View Express Account{' '}
      </Button>
    </Section>
  );
};

export default Manage;
