import { ValueFormatter } from '@tremor/react';

import { BarChart, ChartCard } from '@/components/Chartsv2';
import { useTierChangeEventsPerPeriod } from '@/hooks/usePremiumData';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { TierChangeEventResourceType } from '@/interfaces/tier_change_event';
import { TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

interface Props {
  defaultTimePeriod?: TimePeriod;
  resourceType?: TierChangeEventResourceType;
  resourceId?: string;
  filterByEvent?: 'Downgrades' | 'Upgrades';
}

const TierChangesGraph: React.FC<Props> = ({
  defaultTimePeriod = TimePeriod.LAST_24_HOURS,
  resourceType,
  resourceId,
  filterByEvent,
}) => {
  const { period, TimePeriodSelect } = useTimePeriodSelect({ defaultValue: defaultTimePeriod });
  const { data, isLoading, isError } = useTierChangeEventsPerPeriod({
    period: period as keyof typeof timePeriodLabels,
    resourceType,
    resourceId,
  });

  const valueFormatter: ValueFormatter = (num) => Math.abs(num).toString();

  const chartCategories = filterByEvent && data?.categories ? [filterByEvent] : data?.categories;
  const chartData =
    filterByEvent && data?.chart_data
      ? data?.chart_data.map((row) => ({
          name: row.name,
          [filterByEvent]: row[filterByEvent],
        }))
      : data?.chart_data;

  return (
    <ChartCard
      title="Premium Subscriber Growth"
      description="Shows premium subscribe upgrades and downgrades over time"
      isLoading={isLoading}
      noResultsText="No upgrades or downgrades within the chosen time range."
      noResults={isError || (!isLoading && chartData?.length === 0)}
      actionChildren={<TimePeriodSelect />}
    >
      {data && (
        <BarChart
          data={chartData || []}
          index="name"
          categories={chartCategories || []}
          allowDecimals={false}
          colors={['violet', 'gray']}
          valueFormatter={valueFormatter}
          yAxisWidth={48}
        />
      )}
    </ChartCard>
  );
};

export default TierChangesGraph;
