import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentUser } from '../../context/current-user-context';
import api from '../../services/swarm';

type UserPayload = {
  first_name?: string;
  last_name?: string;
  email?: string;
  bio?: string;
  twitter_handle?: string;
  linkedin_url?: string;
  facebook_url?: string;
  instagram_url?: string;
  youtube_url?: string;
  tiktok_url?: string;
  partner_info?: string;
};

const useUpdateCurrentUser = () => {
  const { reloadUser } = useCurrentUser();

  return useMutation(
    (currentUserPayload: UserPayload) =>
      api.patch(`/current_user`, {
        ...currentUserPayload,
      }),
    {
      onSuccess: () => {
        reloadUser();
      },
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
    }
  );
};

export default useUpdateCurrentUser;
