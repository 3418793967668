import { XCircleIcon } from '@heroicons/react/20/solid';

import { Option } from '@/interfaces/general';

import Badge from '../Badge';

interface Props {
  options: Option[];
  onDeselect: (value: string) => void;
}

const SelectedOptionBadges = ({ options, onDeselect }: Props) => {
  const handleClickDelete: React.MouseEventHandler<HTMLButtonElement> = (e) =>
    onDeselect((e.currentTarget as HTMLButtonElement).value);

  return (
    <div className="flex flex-wrap gap-x-1 gap-y-1">
      {options.map((option) => (
        <Badge type="information" key={`selected-option-badge-${option.value}`}>
          {option.label}
          <button value={option.value} type="button" className="w-3 h-3 text-gray-400" onClick={handleClickDelete}>
            <XCircleIcon />
          </button>
        </Badge>
      ))}
    </div>
  );
};

export default SelectedOptionBadges;
