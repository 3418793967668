import {
  FocusEventHandler,
  MouseEventHandler,
  ReactEventHandler,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';

import { AnchorPosition, IframeProps } from './Iframe.types';

const getAnchorPosition = (anchor: Element) => {
  const rect = anchor.getBoundingClientRect();

  return {
    left: rect.left,
    top: rect.top - 30,
  };
};

const findAnchor = (linkId: string, urlOffsetIndex: number, iframeDocument: Document) => {
  const matchedAnchors = iframeDocument.body.querySelectorAll(`a[href*="${linkId}"]`);
  if (matchedAnchors.length === 1) {
    return matchedAnchors[0];
  }

  // Looks like there are multiple anchors with the same url/link-id
  // Find all anchors
  const allAnchors = iframeDocument.body.querySelectorAll('a');
  if (allAnchors.length === 0) {
    return undefined;
  }

  // The anchor was not found at index, return
  if (!allAnchors[urlOffsetIndex]) {
    return undefined;
  }

  const foundAnchor = allAnchors[urlOffsetIndex] as HTMLAnchorElement;

  // return anchor if the href at index with the same link id?
  return foundAnchor.href.includes(linkId) ? foundAnchor : undefined;
};

const Iframe = ({
  html,
  title,
  minHeight,
  className,
  highlightURLs = [],
  shouldResizeHeight = false,
  clickedLink,
}: IframeProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState<number>(minHeight || 0);
  const [anchorPositions, setAnchorPositions] = useState<AnchorPosition[]>([]);

  const resizeContent = (event: SyntheticEvent) => {
    if (!shouldResizeHeight) {
      return;
    }

    const newHeight = (event.target as HTMLIFrameElement)?.contentWindow?.document.body.scrollHeight;
    if (typeof newHeight !== 'number') {
      return;
    }

    // A buffer of 1px is added to account for cases where the height of the
    // the content could have a fraction part leading to a vertical scrollbar
    // inside the iframe as the outer container does not account for the
    // actual height of the content
    setHeight(newHeight + 1);
  };

  const handleOnLoad: ReactEventHandler<HTMLIFrameElement> = (event) => {
    resizeContent(event);
  };

  const showHighlightedURLs = useCallback(() => {
    if (highlightURLs.length === 0) {
      return;
    }

    const iframeDocument = (iframeRef.current as HTMLIFrameElement).contentWindow?.document;
    if (!iframeDocument?.body) {
      return;
    }

    const newAnchorPositions: AnchorPosition[] = [];
    highlightURLs.forEach((item) => {
      try {
        const anchor = findAnchor(item.linkId, item.urlOffsetIndex, iframeDocument);
        if (anchor) {
          newAnchorPositions.push({
            element: item.element,
            linkId: item.linkId,
            urlOffsetIndex: item.urlOffsetIndex,
            ...getAnchorPosition(anchor),
          });
        }
      } catch (error) {
        // link not found
      }
    });

    setAnchorPositions(newAnchorPositions);
  }, [highlightURLs]);

  useEffect(() => {
    const iframeDocument = (iframeRef.current as HTMLIFrameElement).contentWindow?.document;
    if (!iframeDocument?.body) {
      return;
    }

    if (!clickedLink) {
      return;
    }

    const anchor = findAnchor(clickedLink.linkId, clickedLink.urlOffsetIndex, iframeDocument);
    if (!anchor) {
      return;
    }

    anchor.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }, [clickedLink]);

  useEffect(() => {
    showHighlightedURLs();
  }, [height, showHighlightedURLs]);

  const setHigherZIndex = (buttonElement: HTMLButtonElement) => {
    buttonElement.style.setProperty('z-index', '2');
  };
  const resetZIndex = (buttonElement: HTMLButtonElement) => {
    buttonElement.style.setProperty('z-index', '1');
  };

  const handleMouseOver: MouseEventHandler<HTMLButtonElement> = (event) => {
    setHigherZIndex(event.currentTarget);
  };
  const handleFocus: FocusEventHandler<HTMLButtonElement> = (event) => {
    setHigherZIndex(event.currentTarget);
  };
  const handleMouseOut: MouseEventHandler<HTMLButtonElement> = (event) => {
    resetZIndex(event.currentTarget);
  };
  const handleBlur: FocusEventHandler<HTMLButtonElement> = (event) => {
    resetZIndex(event.currentTarget);
  };

  return (
    <div className="relative w-full h-full">
      <iframe
        ref={iframeRef}
        title={title}
        className={className}
        srcDoc={html}
        style={
          shouldResizeHeight
            ? {
                minHeight: `${height}px`,
              }
            : undefined
        }
        onLoad={handleOnLoad}
      />
      {anchorPositions.map((anchorPosition, index) => {
        const isSelected =
          anchorPosition.linkId === clickedLink?.linkId &&
          anchorPosition.urlOffsetIndex === clickedLink?.urlOffsetIndex;

        return (
          <button
            type="button"
            className={cx('absolute cursor-default', isSelected ? 'animate-bounce' : '')}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{
              zIndex: isSelected ? 2 : 1,
              top: `${anchorPosition.top}px`,
              left: `${anchorPosition.left}px`,
            }}
            onMouseOver={handleMouseOver}
            onFocus={handleFocus}
            onMouseOut={handleMouseOut}
            onBlur={handleBlur}
          >
            {anchorPosition.element}
          </button>
        );
      })}
    </div>
  );
};

export default Iframe;
