import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import MetricsBadge from '@/components/_domain/MetricsBadge';
import ActionsDropdown from '@/components/ActionsDropdown';
import * as CardUI from '@/components/Card/CardUI';
import { ChartCard } from '@/components/Chartsv2';
import CTACard from '@/components/CTACard';
import { usePageContext } from '@/components/Layout/PageLayout/PageContext';
import { useCreateDraft } from '@/hooks';
import { useEngagement } from '@/hooks/useDashboard';
import { EngagementEndpoints } from '@/hooks/useDashboard/useEngagement';
import { PostStats, PostStatus } from '@/interfaces/post';
import { TimePeriod } from '@/interfaces/time_period';
import StatusBadge from '@/pages/Posts/StatusBadge';
import { Button } from '@/ui/Button';

import { PageProps } from '../types';

interface PropsProps extends PostStats {
  web_title: string;
  overide_scheduled_at: string;
  web_sub_title: string;
  total_clicked: number;
}

const getOpenRate = (post: PropsProps) => {
  const totalUniqueOpened = post.total_unique_opened || 0;
  const totalSent = post.total_sent || 0;

  if (totalSent === 0) {
    return 0;
  }

  const metric = ((100 * totalUniqueOpened) / totalSent).toFixed(1);

  if (metric === 'NaN') {
    return 0;
  }

  return Number(metric);
};

const getClickRate = (post: PropsProps) => {
  const totalUniqueEmailClicked = post.total_unique_email_clicked || 0;
  const totalUniqueOpened = post.total_unique_opened || 0;

  if (totalUniqueOpened === 0) {
    return 0;
  }

  const metric = ((100 * totalUniqueEmailClicked) / totalUniqueOpened).toFixed(1);

  if (metric === 'NaN') {
    return 0;
  }

  return Number(metric);
};

const getMetrics = (post: PropsProps) => {
  const shouldUsePostOpenRate = Boolean(post?.open_rate) && !Number.isNaN(post?.open_rate);
  const shouldUsePostClickRate = Boolean(post?.click_rate) && !Number.isNaN(post?.click_rate);

  return {
    totalSent: post.total_sent,
    openRate: shouldUsePostOpenRate ? Number(post.open_rate) : getOpenRate(post),
    clickRate: shouldUsePostClickRate ? Number(post.click_rate) : getClickRate(post),
  };
};

const Post = ({ post }: any) => {
  const { totalSent, openRate, clickRate } = getMetrics(post);
  let time;

  if (post?.override_scheduled_at) {
    time = moment(post.override_scheduled_at).format('LLL');
  } else if (post?.scheduled_at) {
    time = moment(post.scheduled_at).format('LLL');
  }

  return (
    <div className="p-4">
      <div className="space-y-2">
        <div>
          <CardUI.LargeTitle className="text-sm">{post.web_title}</CardUI.LargeTitle>
          <CardUI.SubText>{post.web_sub_title}</CardUI.SubText>
        </div>
        <div className="flex items-center space-x-1">
          <StatusBadge status={PostStatus.PUBLISHED} />
          <MetricsBadge totalSent={totalSent} openRate={openRate} clickRate={clickRate} />
        </div>
        <div className="mt-2">
          {time && (
            <time className="text-xs text-gray-500" dateTime={time}>
              {time}
            </time>
          )}
        </div>
      </div>
    </div>
  );
};

// Types found in app/services/metrics/publication_top_posts_by_period.rb
enum FilterType {
  OPEN_RATE = 'open_rate',
  CLICK_RATE = 'click_rate',
  // UNSUBSCRIBES = 'unsubscribes',
  // SPAM = 'spam',
}

const BUTTON_TEXTS = {
  [FilterType.OPEN_RATE]: 'Open Rate',
  [FilterType.CLICK_RATE]: 'Click Rate',
  // [FilterType.UNSUBSCRIBES]: 'Unsubscribes',
  // [FilterType.SPAM]: 'Spam',
};

const TopPosts = () => {
  const navigate = useNavigate();
  const createDraftMutation = useCreateDraft();

  const { period } = usePageContext<PageProps>();
  const [filterType, setFilterType] = useState<FilterType>(FilterType.OPEN_RATE);

  const { data, isLoading } = useEngagement({
    endpoint: EngagementEndpoints.TOP_POSTS,
    timePeriod: period,
    filter_params: {
      metric: filterType,
    },
  });

  const topPosts = data?.top_posts || [];
  const isAllTime = period === TimePeriod.ALL_TIME;
  const noResults = topPosts.length === 0;
  const showCTA = isAllTime && noResults && !isLoading;

  const handleStartWriting = () => {
    createDraftMutation.mutateAsync({});
  };

  const options = [
    {
      name: BUTTON_TEXTS[FilterType.OPEN_RATE],
      onClick: () => setFilterType(FilterType.OPEN_RATE),
      isVisible: () => true,
    },
    {
      name: BUTTON_TEXTS[FilterType.CLICK_RATE],
      onClick: () => setFilterType(FilterType.CLICK_RATE),
      isVisible: () => true,
    },
    // TODO: Comment back in when UI is prepped
    // {
    //   name: BUTTON_TEXTS[FilterType.UNSUBSCRIBES],
    //   onClick: () => setFilterType(FilterType.UNSUBSCRIBES),
    //   isVisible: () => true,
    // },
    // {
    //   name: BUTTON_TEXTS[FilterType.SPAM],
    //   onClick: () => setFilterType(FilterType.SPAM),
    //   isVisible: () => true,
    // },
  ];

  if (showCTA) {
    return (
      <CTACard
        variant="secondary"
        title="No published posts"
        description="Get started by writing and publishing your first post"
        ctaText="Create new post"
        onActionClick={handleStartWriting}
        ButtonIcon={PencilIcon}
        Icon={PlusCircleIcon}
      />
    );
  }

  return (
    <div className="flex flex-col space-y-2">
      <ChartCard
        title="Top posts"
        noResults={noResults}
        noResultsText="No results for the selected time period."
        isLoading={isLoading}
        description="Filter your top posts easily to see how they are performing"
        actionChildren={
          <ActionsDropdown
            buttonText={BUTTON_TEXTS[filterType]}
            buttonClass="bg-surface-100"
            size="sm"
            actions={[options]}
            node={{}}
          />
        }
      >
        {noResults ? (
          <div className=" h-40" />
        ) : (
          <CardUI.Card className="p-0 divide-y min-h-40" hasPadding={false}>
            {topPosts.map((post: any) => {
              return (
                <div key={post.web_title} className="p-2">
                  <Post post={post} />
                </div>
              );
            })}
          </CardUI.Card>
        )}

        <div className="flex justify-end pt-6">
          <Button variant="primary-inverse" iconRight onClick={() => navigate('/posts')}>
            All Posts
          </Button>
        </div>
      </ChartCard>
    </div>
  );
};

export default TopPosts;
