import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useApproveAllSubscribers = (onSuccess?: () => void) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(() => api.patch(`/publications/${currentPublicationId}/approve_all_subscriptions`), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: () => {
      toast.success('All subscribers have been approved!');
      if (onSuccess) onSuccess();
    },
  });
};

export default useApproveAllSubscribers;
