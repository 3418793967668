import { useState } from 'react';

import {
  BetweenDateEnum,
  DateFields,
  DateFilterTypes,
  DateOptionsProps,
  PostDateField,
  SubscriberDateField,
} from '../../../interfaces/dates';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import Select from '../Select';

type DateSearchProps = {
  selectValue: string;
  label: string;
  options: DateOptionsProps;
  handleOptionsChange: (value: object) => void;
  selectOptions: [{ label: string; value: string }];
};

const DateSearch = ({ selectValue, label, options, selectOptions, handleOptionsChange }: DateSearchProps) => {
  const [changedStartTime, setChangedStartTime] = useState(false);
  const [changedEndTime, setChangedEndTime] = useState(false);
  const [changedBeforeTime, setChangedBeforeTime] = useState(false);
  const [changedAfterTime, setChangedAfterTime] = useState(false);

  const betweenStartTime = options[DateFilterTypes.BETWEEN][BetweenDateEnum.START];
  const betweenEndTime = options[DateFilterTypes.BETWEEN][BetweenDateEnum.END];
  const beforeTime = options[DateFilterTypes.BEFORE];
  const afterTime = options[DateFilterTypes.AFTER];

  const handleToggleDateRange = (checked: boolean): void => {
    const newOptions = {
      ...options,
      datesEnabled: checked,
    };

    handleOptionsChange(newOptions);
  };

  const handleDateFieldChange = (value: PostDateField | SubscriberDateField) => {
    const newOptions = {
      ...options,
      dateField: value,
    };

    handleOptionsChange(newOptions);
  };

  const handleChangeDateFilterType = (value: DateFilterTypes) => {
    const newOptions = {
      ...options,
      dateFilterType: value,
    };

    handleOptionsChange(newOptions);
  };

  const handleBetweenTimeChange = (type: BetweenDateEnum) => (date: Date | null | undefined) => {
    const newOptions = {
      ...options,
      [DateFilterTypes.BETWEEN]: {
        ...options[DateFilterTypes.BETWEEN],
        [type]: date,
      },
    };

    handleOptionsChange(newOptions);

    if (type === BetweenDateEnum.START) {
      setChangedStartTime(true);
    } else {
      setChangedEndTime(true);
    }
  };

  const handleTimeChange = (type: DateFilterTypes) => (date: Date | null | undefined) => {
    const newOptions = {
      ...options,
      [type]: date,
    };

    handleOptionsChange(newOptions);

    if (type === DateFilterTypes.BEFORE) {
      setChangedBeforeTime(true);
    }

    if (type === DateFilterTypes.AFTER) {
      setChangedAfterTime(true);
    }
  };

  const defaultDate = (change = -1, date = new Date()) => {
    date.setMonth(date.getMonth() + change);

    return date;
  };

  const endTimeMaxDate = () => {
    if (changedStartTime) {
      return options[DateFilterTypes.BETWEEN][BetweenDateEnum.START];
    }

    const date = defaultDate(0);
    date.setFullYear(1970);

    return date;
  };

  const datePickerOptions = {
    clickOpens: options.datesEnabled,
    enableTime: false,
    altFormat: 'F j, Y',
  };

  return (
    <div className="flex lg:flex-row flex-col gap-2 lg:items-center lg:justify-between mb-4">
      <div className="space-y-2">
        <Checkbox
          name="date-range-filter-on"
          checked={options.datesEnabled}
          onChange={handleToggleDateRange}
          labelText={label}
        />
        <div className="flex w-full space-x-0 space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-2">
          <div className="flex w-full space-x-2">
            <Select
              className="w-full text-sm"
              name="date-field"
              disabled={!options.datesEnabled}
              value={selectValue}
              onSelect={(_name: string, value: string) => handleDateFieldChange(value as DateFields)}
              options={selectOptions}
            />
            <Select
              className="w-full text-sm"
              name="posts"
              disabled={!options.datesEnabled}
              value={options.dateFilterType}
              onSelect={(_name: string, value: string) => handleChangeDateFilterType(value as DateFilterTypes)}
              options={[
                { label: 'Is Between', value: DateFilterTypes.BETWEEN },
                { label: 'Is Before', value: DateFilterTypes.BEFORE },
                { label: 'Is After', value: DateFilterTypes.AFTER },
              ]}
            />
          </div>
          {options.dateFilterType === DateFilterTypes.BETWEEN && (
            <>
              <DatePicker
                inline
                className="w-full"
                value={changedStartTime ? betweenStartTime : defaultDate()}
                maxDate={changedEndTime ? betweenEndTime : defaultDate(0)}
                onChange={handleBetweenTimeChange(BetweenDateEnum.START)}
                pickerOptions={datePickerOptions}
              />
              <div className="hidden lg:inline pt-2">
                <p className="block text-sm font-medium text-gray-700">and</p>
              </div>
              <DatePicker
                inline
                className="w-full"
                value={changedEndTime ? betweenEndTime : defaultDate(0)}
                minDate={changedStartTime ? betweenStartTime : endTimeMaxDate()}
                maxDate={new Date()}
                onChange={handleBetweenTimeChange(BetweenDateEnum.END)}
                pickerOptions={datePickerOptions}
              />
            </>
          )}

          {options.dateFilterType === DateFilterTypes.BEFORE && (
            <DatePicker
              value={changedBeforeTime ? beforeTime : defaultDate(0)}
              inline
              className="w-full"
              onChange={handleTimeChange(DateFilterTypes.BEFORE)}
              pickerOptions={datePickerOptions}
            />
          )}

          {options.dateFilterType === DateFilterTypes.AFTER && (
            <DatePicker
              inline
              className="w-full"
              value={changedAfterTime ? afterTime : defaultDate()}
              onChange={handleTimeChange(DateFilterTypes.AFTER)}
              pickerOptions={datePickerOptions}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DateSearch;
