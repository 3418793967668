import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import api from '../services/swarm';

export default function usePostTheme() {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery(
    ['postTheme', currentPublicationId],
    () =>
      api
        .get(`/post_theme`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      staleTime: Infinity,
    }
  );
}
