import useAppLayout from './useAppLayout';

function usePageLayout(): {
  hasFullHeight: boolean;
} {
  const { hasSubLayout } = useAppLayout();

  return {
    hasFullHeight: hasSubLayout,
  };
}

export default usePageLayout;
