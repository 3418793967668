import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';
import { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuid } from 'uuid';

import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import { useCurrentUser } from '../../../../../context/current-user-context';
import api from '../../../../../services/swarm';
import { ToneType } from '../../../components/panels/AiChangeTextTone/types';
import { AiTextSettingsPanel } from '../../../components/panels/AiTextSettings/AiTextSettings';
import { actions } from '../../../components/panels/AiTextSettings/ChangeTextOptions/constants';
import { Button } from '../../../components/ui/Button';
import { ConsumptionProgress } from '../../../components/ui/ConsumptionProgress';
import { HintWrapper } from '../../../components/ui/HintWrapper';
import { Icon } from '../../../components/ui/Icon';
import { Loader } from '../../../components/ui/Loader';
import { Panel, PanelHeadline } from '../../../components/ui/Panel';
import { Textarea } from '../../../components/ui/Textarea';
import { colors } from '../../../lib/colors';
import { useConsumption } from '../../../lib/hooks/useConsumption';

import { Styled } from './AiWriterView.styled';
import { DataProps } from './types';

export const AiWriterView = ({ editor, node, getPos, deleteNode }: NodeViewWrapperProps) => {
  const { currentUser } = useCurrentUser();
  const [data, setData] = useState<DataProps>({
    text: '',
    // text: 'Write something about Mountain View.',
    tone: undefined,
    textUnit: actions[0].label,
    textLength: undefined,
    addHeading: false,
    language: undefined,
  });
  const [previewText, setPreviewText] = useState(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const textareaId = useMemo(() => uuid(), []);
  const { authorId, authorName, usesCollaboration } = node.attrs;
  const isSameAuthor = currentUser?.id === authorId;
  const { consumption, setConsumption, isConsumptionExceeded, showConsumption } = useConsumption();
  const [currentPublicationId] = useCurrentPublicationState();

  if (usesCollaboration && !isSameAuthor) {
    return (
      <HintWrapper
        icon="AI"
        title={`${authorName || 'Someone'} added the AI Writer element to generate magical content`}
      />
    );
  }

  const generateText = useCallback(async () => {
    const { text: dataText, tone, textLength, textUnit, addHeading, language } = data;

    if (!data.text) {
      toast.error('Please enter a description');

      return;
    }

    setIsFetching(true);

    const payload = {
      publication_id: currentPublicationId,
      text: dataText,
      text_length: textLength,
      text_unit: textUnit,
      use_heading: addHeading,
      tone,
      language,
    };

    try {
      axiosRetry(api, {
        retryCondition: (errPayload: AxiosError) => errPayload?.response?.status === 429,
        retryDelay: (retryCount) => 2 ** retryCount * 1000 + Math.random() * 1000,
        retries: 3,
      });

      const response = await api.post('/editor/ai/text/prompt', payload);
      const { data: json } = response;

      const text = json.response;

      if (!text.length) {
        setIsFetching(false);

        return;
      }

      setPreviewText(text);
      setConsumption({
        consumedRequests: json.requests_consumed,
        monthlyAvailableRequests: json.requests_allowed,
      });
      setIsFetching(false);
    } catch (errPayload: any) {
      const errorMessage = errPayload?.response?.data?.error;
      const message = errorMessage !== 'An error occurred' ? `An error has occured: ${errorMessage}` : errorMessage;

      setIsFetching(false);
      toast.error(message);
    }
  }, [data]);

  const insert = useCallback(() => {
    const from = getPos();
    const to = from + node.nodeSize;

    editor.chain().focus().insertContentAt({ from, to }, previewText).run();
  }, [editor, previewText]);

  const discard = useCallback(() => {
    deleteNode();
  }, []);

  return (
    <NodeViewWrapper data-drag-handle>
      <div {...{ inert: editor.isEditable ? undefined : '' }}>
        <Panel $width="100%">
          <Styled.Container>
            {isFetching && <Loader label="Generating cool new stuff" />}
            {previewText && (
              <>
                <PanelHeadline>Preview</PanelHeadline>
                <Styled.PreviewContainer dangerouslySetInnerHTML={{ __html: previewText }} />
              </>
            )}
            <Styled.Header>
              {!isConsumptionExceeded && (
                <PanelHeadline as="label" htmlFor={textareaId}>
                  Prompt
                </PanelHeadline>
              )}
              {showConsumption && (
                <Styled.ConsumptionWrapper>
                  <ConsumptionProgress
                    $isInline
                    used={consumption.consumedRequests || 0}
                    available={consumption.monthlyAvailableRequests || 0}
                    label="AI requests"
                  />
                </Styled.ConsumptionWrapper>
              )}
            </Styled.Header>
            {!isConsumptionExceeded && (
              <Textarea
                id={textareaId}
                value={data.text}
                onChange={(e) => setData((prevData) => ({ ...prevData, text: e.target.value }))}
                placeholder={'Describe what you want me to write about. \r\nFor example: "Write some text about bees."'}
                required
              />
            )}
            <Styled.PromptFooter>
              {isConsumptionExceeded ? (
                <Styled.ConsumptionHint style={{ width: '100%' }}>
                  You have reached your monthly requests limit.
                </Styled.ConsumptionHint>
              ) : (
                <Styled.HorizontalButtonWrapper>
                  <AiTextSettingsPanel
                    textToneValueCallback={(tone: ToneType) => {
                      setData((prevData) => ({ ...prevData, tone }));
                    }}
                    textToneValue={data.tone}
                    textLengthValueCallback={(textLength: string) => {
                      setData((prevData) => ({ ...prevData, textLength }));
                    }}
                    textLengthValue={data.textLength}
                    textLengthUnitCallback={(textUnit: string) => {
                      setData((prevData) => ({ ...prevData, textUnit }));
                    }}
                    textLengthUnit={data.textUnit}
                    addHeadingCallback={(addHeading: boolean) => {
                      setData((prevData) => ({ ...prevData, addHeading }));
                    }}
                    addHeadingValue={data.addHeading}
                    textLanguageValueCallback={(language: string) => {
                      setData((prevData) => ({ ...prevData, language }));
                    }}
                    textLanguageValue={data.language}
                    isConsumptionExceeded={isConsumptionExceeded}
                  />
                </Styled.HorizontalButtonWrapper>
              )}
              <Styled.HorizontalButtonWrapper isConsumptionExceeded={isConsumptionExceeded}>
                {previewText && (
                  <Button $variant="quaternary" $size="small" $leftSlot={<Icon name="Trash" />} onClick={discard}>
                    Discard
                  </Button>
                )}
                {previewText && (
                  <Button
                    $variant="quaternary"
                    $size="small"
                    onClick={insert}
                    disabled={!previewText}
                    $leftSlot={<Icon name="Check" />}
                  >
                    Insert
                  </Button>
                )}
                {!isConsumptionExceeded && (
                  <Button
                    $variant="quaternary"
                    $size="small"
                    $leftSlot={
                      previewText ? (
                        <Icon name="AIRephrase" color={colors.pink[5]} />
                      ) : (
                        <Icon name="AI" color={colors.pink[5]} />
                      )
                    }
                    onClick={generateText}
                    $muted
                    $active
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {previewText ? 'Regenerate' : 'Generate text'}
                  </Button>
                )}
                {isConsumptionExceeded && (
                  <Button
                    $variant="quaternary"
                    $size="small"
                    $leftSlot={<Icon name="Mail" color={colors.pink[5]} />}
                    $muted
                    $active
                    as="a"
                    href="/?new_support_ticket=true"
                    target="_blank"
                    style={{ whiteSpace: 'nowrap', marginLeft: 'auto' }}
                  >
                    Contact support
                  </Button>
                )}
              </Styled.HorizontalButtonWrapper>
            </Styled.PromptFooter>
          </Styled.Container>
        </Panel>
      </div>
    </NodeViewWrapper>
  );
};

export default AiWriterView;
