import { FC, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, HomeIcon, LockClosedIcon, PencilIcon } from '@heroicons/react/20/solid';

import PostStatusBadge from '@/components/_domain/PostStatusBadge';
import ActionModal from '@/components/ActionModal';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import TabNavigation from '@/components/TabNavigation';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication, usePost } from '@/hooks';
import { Tab } from '@/interfaces/general';
import { Post, PostPlatform, PostStatus } from '@/interfaces/post';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import ConfirmEmailVerification from '../ConfirmEmailVerification';

import PostHeaderCard from './PostHeaderCard';

interface Props {
  children: ({ post }: { post: Post }) => JSX.Element | null;
  postId: string;
}

const Container: FC<Props> = (props: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const { pathname } = useLocation();
  const { children, postId } = props;
  const postQuery = usePost({ id: postId });
  const { data: post } = postQuery;
  const { data: currentPublication } = useCurrentPublication();

  // Ensures only live posts can have comments + comments reports
  const navigate = useNavigate();
  const isPostOnWeb = post?.platform !== PostPlatform.EMAIL;
  const isPostLive = post?.status === PostStatus.PUBLISHED;
  const showCommentsSection = settings?.comments && isPostOnWeb && isPostLive;
  const commentsRoute = `/posts/${postId}/comments`;
  const engagementRoute = `/posts/${postId}/engagement`;
  const premiumDataRoute = `/posts/${postId}/premium_data`;
  const shouldRedirectComments = !showCommentsSection && pathname === commentsRoute;

  useEffect(() => {
    if (post && shouldRedirectComments) {
      navigate(`/posts/${postId}`);
    }
  }, [navigate, post, postId, shouldRedirectComments]);

  const constructTabs = useCallback(
    (currentPath: string): Tab[] => {
      const premiumDisabled = post?.status === PostStatus.DRAFT || !currentPublication?.is_premium_enabled;
      let premiumDisabledMessage = 'This post must be published';

      if (!currentPublication?.is_premium_enabled) premiumDisabledMessage = 'You must have a premium tier active';

      if (isV2) {
        return [
          {
            name: 'overview',
            label: 'Overview',
            targetRoute: `/posts/${postId}`,
            selected: currentPath === `/posts/${postId}`,
          },
          {
            name: 'analytics',
            label: 'Performance',
            targetRoute: `/posts/${postId}/analytics`,
            selected: currentPath.includes(`/posts/${postId}/analytics`),
          },
          {
            name: 'engagement-overview',
            label: 'Interactions',
            targetRoute: `/posts/${postId}/engagement-overview`,
            // Support old routes to be backward compatible
            selected:
              currentPath === `/posts/${postId}/engagement-overview` ||
              currentPath === `/posts/${postId}/polls` ||
              currentPath === `/posts/${postId}/comments` ||
              currentPath === `/posts/${postId}/engagement`,
          },
          {
            name: 'split_test',
            label: 'A/B Test',
            disabled: (settings?.max_split_test_options || 0) < 2,
            disableWith: 'Upgrade to access A/B testing',
            targetRoute: `/posts/${postId}/ab_test`,
            selected: currentPath === `/posts/${postId}/ab_test`,
          },
          {
            name: 'premium',
            label: 'Premium Data',
            targetRoute: premiumDataRoute,
            selected: currentPath === premiumDataRoute,
          },
        ];
      }

      return [
        {
          name: 'overview',
          label: 'Overview',
          targetRoute: `/posts/${postId}`,
          selected: currentPath === `/posts/${postId}`,
        },
        {
          name: 'analytics',
          label: 'Analytics',
          targetRoute: `/posts/${postId}/analytics`,
          selected: currentPath.includes(`/posts/${postId}/analytics`),
        },
        {
          name: 'polls',
          label: 'Polls',
          disabled: !settings?.polls,
          disableWith: 'Upgrade to access polls',
          targetRoute: `/posts/${postId}/polls`,
          selected: currentPath === `/posts/${postId}/polls`,
        },
        {
          name: 'split_test',
          label: 'A/B Test',
          disabled: (settings?.max_split_test_options || 0) < 2,
          disableWith: 'Upgrade to access A/B testing',
          targetRoute: `/posts/${postId}/ab_test`,
          selected: currentPath === `/posts/${postId}/ab_test`,
        },
        {
          name: 'comments',
          label: 'Comments',
          targetRoute: commentsRoute,
          disabled: !showCommentsSection,
          disableWith: 'Only available for published posts with a web version',
          selected: currentPath === commentsRoute,
        },
        {
          name: 'engagement',
          label: 'Engagement',
          targetRoute: engagementRoute,
          disabled: false,
          selected: currentPath === engagementRoute,
        },
        {
          name: 'premium',
          label: 'Premium Data',
          targetRoute: premiumDataRoute,
          disabled: premiumDisabled,
          disableWith: premiumDisabledMessage,
          selected: currentPath === premiumDataRoute,
        },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postId, settings, showCommentsSection, post?.status, currentPublication]
  );

  const constructedTabs = constructTabs(pathname);

  const [deleteRequested, setDeleteRequested] = useState(false);

  const onDeleteSelected = () => {
    setDeleteRequested(true);
  };

  const onActionModalClose = () => {
    setDeleteRequested(false);
  };

  const deleteMutation = useMutation(() => api.delete(`/posts/${postId}`).then((res) => res.data), {
    onSuccess: () => {
      navigate('/posts');

      toast.success('Post deleted successfully');
    },
  });

  const isDeleting = deleteMutation.isLoading;

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  const [archiveRequested, setArchiveRequested] = useState(false);

  const onArchiveSelected = () => {
    setArchiveRequested(true);
  };

  const onArchiveModalClose = () => {
    setArchiveRequested(false);
  };

  const archiveMutation = useMutation(
    () =>
      api
        .patch(`/posts/${postId}/transition`, {
          status: 'archived',
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        setArchiveRequested(false);
        postQuery.refetch();
        toast.success('Post archived successfully');
      },
    }
  );

  const isArchiving = archiveMutation.isLoading;

  const handleArchive = () => {
    archiveMutation.mutate();
  };

  const unarchiveMutation = useMutation(
    () =>
      api
        .patch(`/posts/${postId}/transition`, {
          status: 'confirmed',
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        postQuery.refetch();
        toast.success('Post unarchived successfully');
      },
    }
  );

  const hasLiveUrl =
    post &&
    post.url &&
    post.status === PostStatus.PUBLISHED &&
    (post.platform === PostPlatform.WEB || post.platform === PostPlatform.BOTH);

  if (isV2) {
    return (
      <div>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/">
            <HomeIcon className="w-4 h-4" />
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to="/posts">Posts</Breadcrumbs.Item>
          {post ? <Breadcrumbs.Item to={`/posts/${post.id}`}>{post.web_title}</Breadcrumbs.Item> : null}
        </Breadcrumbs>

        {post && (
          <>
            {/* todo: look into how this renders */}
            <ConfirmEmailVerification />

            <div className="flex flex-col gap-y-4">
              <PostHeaderCard post={post} />
              <TabNavigation tabs={constructedTabs} variant="tertiary" />
              {children({ post })}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      {post?.deletable && (
        <ActionModal
          isOpen={deleteRequested}
          onClose={onActionModalClose}
          onProceed={handleDelete}
          resourceId={postId}
          isWorking={isDeleting}
          headerText="Delete post"
          actionText="Delete"
        >
          Are you sure you want to delete this post?
        </ActionModal>
      )}
      {post?.archivable && (
        <ActionModal
          isOpen={archiveRequested}
          onClose={onArchiveModalClose}
          onProceed={handleArchive}
          resourceId={postId}
          isWorking={isArchiving}
          headerText="Archive post"
          actionText="Archive"
        >
          Are you sure you want to archive this post?
        </ActionModal>
      )}

      <div>
        {/* ACTIONS */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center pb-4">
          <div className="flex mb-4 sm:mb-0">
            <Link className="text-sm text-gray-500 hover:text-primary-500" to="/posts">
              <div className="flex items-center">
                <ArrowLeftIcon className="w-4 h-4 mr-2" />
                <span>Back to all posts</span>
              </div>
            </Link>
          </div>
          <div className="flex flex-wrap">
            {hasLiveUrl && (
              <a href={post.url} target="_blank" rel="noreferrer">
                <Button type="button" variant="primary-inverse" className="mr-2">
                  View published
                </Button>
              </a>
            )}
            {post?.deletable && (
              <Button onClick={onDeleteSelected} variant="primary-inverse" className="mr-2">
                <span>Delete</span>
              </Button>
            )}
            {post?.archivable && (
              <Button
                onClick={onArchiveSelected}
                disabled={post.editing_locked}
                variant="primary-inverse"
                className="mr-2"
              >
                <span>Archive</span>
              </Button>
            )}
            {post?.status === PostStatus.ARCHIVED && (
              <Button
                onClick={() => unarchiveMutation.mutate()}
                variant="primary-inverse"
                className="mr-2 mt-2 xs:mt-0"
              >
                <span>Unarchive</span>
              </Button>
            )}
            {post?.status !== PostStatus.ARCHIVED && (
              <Button
                onClick={() =>
                  post?.editing_locked
                    ? toast.error(post?.editing_locked_reason || 'There was a problem editing this post.')
                    : navigate(`/posts/${postId}/edit`)
                }
              >
                <div className="flex">
                  {post?.editing_locked ? (
                    <LockClosedIcon className="w-5 h-5 mr-2" />
                  ) : (
                    <PencilIcon className="w-5 h-5 mr-2" />
                  )}
                  <span>Edit post</span>
                </div>
              </Button>
            )}
          </div>
        </div>

        {post && (
          <>
            <div className="flex flex-col">
              <div className="mb-2">{post?.status && <PostStatusBadge status={post.status} />}</div>
              <div className="flex items-center">
                <h1 className="text-2xl font-bold mr-2 max-w-4xl">{post.web_title}</h1>
              </div>
            </div>
            <p className="text-gray-600 max-w-3xl">{post.web_subtitle}</p>

            <ConfirmEmailVerification />

            <TabNavigation tabs={constructedTabs} className="sm:border-b pt-4 mb-4 lg:px-0" />

            {children({ post })}
          </>
        )}
      </div>
    </>
  );
};

export default Container;
