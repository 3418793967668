import { ChartCard } from '@/components/Chartsv2';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import TabNavigation from '@/components/TabNavigation';
import { TitleSm, Typography } from '@/components/Typography';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { Tab } from '@/interfaces/general';
import { TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

import useOfferEffectivenessByPeriod from '../_hooks/useOfferEffectivenessByPeriod';

const EffectivenessCard = ({ tabs }: { tabs: Tab[] }) => {
  const { period, TimePeriodSelect } = useTimePeriodSelect({ defaultValue: TimePeriod.ALL_TIME });
  const { data, isLoading, isError } = useOfferEffectivenessByPeriod(period as keyof typeof timePeriodLabels);

  const formatValue = (num: number) => new Intl.NumberFormat('en').format(num);

  return (
    <ChartCard
      title="Offer Performance"
      isLoading={isLoading}
      noResults={isError}
      noResultsText=""
      actionChildren={<TimePeriodSelect />}
    >
      <TabNavigation tabs={tabs} />
      <div className="mt-6">
        {data?.chart_data && data.chart_data.length > 0 ? (
          <Items className="!shadow-none">
            <ItemHeaders>
              <ItemHeader>Promotion Name</ItemHeader>
              <ItemHeader>Promotion Type</ItemHeader>
              <ItemHeader>Redemptions</ItemHeader>
            </ItemHeaders>
            <ItemsBody>
              {data.chart_data.map((row) => (
                <ItemRow className="last:border-b-0" key={row.id}>
                  <ItemColumn>
                    <Typography token="font-medium/text/base">{row.name}</Typography>
                  </ItemColumn>
                  <ItemColumn>
                    <Typography token="font-medium/text/sm" colorWeight="700">
                      {row.offer_type}
                    </Typography>
                  </ItemColumn>
                  <ItemColumn>
                    <Typography token="font-medium/text/sm" colorWeight="700">
                      {formatValue(row.redemptions)}
                    </Typography>
                  </ItemColumn>
                </ItemRow>
              ))}
            </ItemsBody>
          </Items>
        ) : (
          <div className="h-40 px-4 flex items-center justify-center">
            <TitleSm className="text-sm">No offer redemptions in the chosen time range</TitleSm>
          </div>
        )}
      </div>
    </ChartCard>
  );
};

export default EffectivenessCard;
