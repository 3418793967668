import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { ApiKeyWithFolders } from '../../interfaces/api_key';
import api from '../../services/swarm';

const useMailchimpFolder = (integrationId?: string) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<ApiKeyWithFolders>(
    ['mailchimp_folders', integrationId, currentPublicationId],
    () =>
      api
        .get(`/integrations/${integrationId}/mailchimp_folders`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!currentPublicationId && !!integrationId,
    }
  );
};

export default useMailchimpFolder;
