import { useEffect, useMemo, useState } from 'react';
import { Color } from '@tremor/react';
import moment from 'moment-mini';

import { AreaChart, ChartCard } from '@/components/Chartsv2';
import { LineDivider } from '@/components/LineDivider';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography, TypographyStack } from '@/components/Typography';
import useMetrics, { AutomationMetricsEndpoints } from '@/hooks/useAutomations/useMetrics';
import { Option } from '@/interfaces/general';
import { TimePeriod } from '@/interfaces/time_period';

import ChartFilters from './ChartFilters';
import Filters from './Filters';

const SENT_CATEGORY: Option = {
  value: 'total_sent',
  label: 'Sent',
  description: 'Emails sent',
};
const DELIVERED_CATEGORY: Option = {
  value: 'total_delivered',
  label: 'Delivered',
  description: 'Emails delivered',
};
const UNIQUE_OPENS_CATEGORY: Option = {
  value: 'total_unique_opened',
  label: 'Unique Opens',
  description: 'Unique email opens',
};
const UNIQUE_CLICKS_CATEGORY: Option = {
  value: 'total_unique_clicked',
  label: 'Unique Clicks',
  description: 'Unique email clicks',
};

const SPAM_REPORTS_CATEGORY: Option = {
  value: 'total_spam_reported',
  label: 'Spam Reports',
  description: 'Emails reported as spam',
};

const COLOR_BY_CATEGORY = {
  [SENT_CATEGORY.label]: 'gray',
  [DELIVERED_CATEGORY.label]: 'emerald',
  [UNIQUE_OPENS_CATEGORY.label]: 'pink',
  [UNIQUE_CLICKS_CATEGORY.label]: 'indigo',
  [SPAM_REPORTS_CATEGORY.label]: 'red',
};

const FILTER_COLOR_BY_CATEGORY = {
  [SENT_CATEGORY.label]: 'text-gray-500',
  [DELIVERED_CATEGORY.label]: 'text-emerald-500',
  [UNIQUE_OPENS_CATEGORY.label]: 'text-pink-500',
  [UNIQUE_CLICKS_CATEGORY.label]: 'text-indigo-500',
  [SPAM_REPORTS_CATEGORY.label]: 'text-red-500',
};

interface Props {
  automationId: string;
}

interface ChartRow {
  name: string;
  total_sent: number;
  total_delivered: number;
  total_unique_opened: number;
  total_unique_clicked: number;
  total_spam_reported: number;
}

interface TableRow {
  id: string;
  subject_line: string;
  created_at: string;
  total_sent: number;
  total_delivered: number;
  open_rate: number;
  click_rate: number;
  spam_rate: number;
}

const PerformanceVisualisation = ({ automationId }: Props) => {
  const [currentTimePeriod, setCurrentTimePeriod] = useState<TimePeriod>(TimePeriod.LAST_7_DAYS);
  const [subjectQuery, setSubjectQuery] = useState<string>('');

  const {
    data: performanceData,
    isLoading: isPerformanceDataLoading,
    refetch: refetchPerformanceData,
  } = useMetrics({
    automationId,
    endpoint: AutomationMetricsEndpoints.PERFORMANCE_METRICS,
    timePeriod: currentTimePeriod,
    subject: subjectQuery,
  });

  const {
    data: emailStepPerformanceData,
    isLoading: isEmailStepPerformanceDataLoading,
    refetch: refetchEmailStepPerformanceData,
  } = useMetrics({
    automationId,
    endpoint: AutomationMetricsEndpoints.SEND_EMAIL_STEP_PERFORMANCE_METRICS,
    timePeriod: currentTimePeriod,
    subject: subjectQuery,
  });

  // Refetch after subject query or time period changes
  useEffect(() => {
    refetchPerformanceData();
    refetchEmailStepPerformanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectQuery, currentTimePeriod]);

  const [visibleCategories, setVisibleCategories] = useState<{ [key: string]: boolean }>({
    [SENT_CATEGORY.label]: true,
    [DELIVERED_CATEGORY.label]: true,
    [UNIQUE_OPENS_CATEGORY.label]: true,
    [UNIQUE_CLICKS_CATEGORY.label]: true,
    [SPAM_REPORTS_CATEGORY.label]: true,
  });

  const handleChangeInShowCategory = (category: string, checked: boolean) => {
    const newVisibleCategories = { ...visibleCategories };
    newVisibleCategories[category] = checked;
    setVisibleCategories(newVisibleCategories);
  };

  const handleChangeInTimePeriod = (timePeriod: TimePeriod) => {
    setCurrentTimePeriod(timePeriod);
  };

  const handleChangeInSubjectQuery = (value: string) => {
    setSubjectQuery(value);
  };

  const mappedChartData: ChartRow[] = useMemo(() => {
    if (isPerformanceDataLoading || typeof performanceData !== 'object') {
      return [];
    }

    return performanceData
      .filter(
        (row: ChartRow) =>
          row.total_sent ||
          row.total_delivered ||
          row.total_unique_opened ||
          row.total_unique_clicked ||
          row.total_spam_reported
      )
      .map((row: ChartRow) => {
        return {
          name: row.name,
          [SENT_CATEGORY.label]: row.total_sent,
          [DELIVERED_CATEGORY.label]: row.total_delivered,
          [UNIQUE_OPENS_CATEGORY.label]: row.total_unique_opened,
          [UNIQUE_CLICKS_CATEGORY.label]: row.total_unique_clicked,
          [SPAM_REPORTS_CATEGORY.label]: row.total_spam_reported,
        };
      });
  }, [performanceData, isPerformanceDataLoading]);

  const mappedTableData: TableRow[] = useMemo(() => {
    if (isEmailStepPerformanceDataLoading || typeof emailStepPerformanceData !== 'object') {
      return [];
    }

    return emailStepPerformanceData.map((row: TableRow) => row);
  }, [isEmailStepPerformanceDataLoading, emailStepPerformanceData]);

  const categoryColors = useMemo(
    () =>
      Object.keys(visibleCategories)
        .filter((category) => visibleCategories[category])
        .map((category) => COLOR_BY_CATEGORY[category] as Color),
    [visibleCategories]
  );

  return (
    <div className="flex flex-col gap-y-6">
      <Filters
        showSubjectFilter
        onChangeInSubject={handleChangeInSubjectQuery}
        subjectQuery={subjectQuery}
        onChangeInPeriod={handleChangeInTimePeriod}
      />
      <div className="flex">
        <div className="hidden md:block">
          <ChartFilters
            filters={[
              SENT_CATEGORY,
              DELIVERED_CATEGORY,
              UNIQUE_OPENS_CATEGORY,
              UNIQUE_CLICKS_CATEGORY,
              SPAM_REPORTS_CATEGORY,
            ]}
            colorByFilter={FILTER_COLOR_BY_CATEGORY}
            onChange={handleChangeInShowCategory}
          />
        </div>
        <ChartCard
          noResultsText={
            isPerformanceDataLoading ? 'Fetching...' : 'No performance metrics available within the chosen time range.'
          }
          noResults={mappedChartData.length === 0}
        >
          <AreaChart
            index="name"
            data={mappedChartData}
            categories={Object.keys(visibleCategories).filter((category) => visibleCategories[category])}
            colors={categoryColors}
            showLegend={false}
            showXAxis
            showGridLines
          />
        </ChartCard>
      </div>

      <LineDivider />

      <Items>
        <ItemHeaders>
          <ItemHeader>Email Subject</ItemHeader>
          <ItemHeader>Sent</ItemHeader>
          <ItemHeader>Delivered</ItemHeader>
          <ItemHeader>Open Rate</ItemHeader>
          <ItemHeader>Click Rate</ItemHeader>
          <ItemHeader>Spam Rate</ItemHeader>
        </ItemHeaders>
        <ItemsBody>
          {mappedTableData.length === 0 && (
            <ItemRow>
              <ItemColumn colSpan={6} align={AlignType.CENTER}>
                <Typography size="xs" colorWeight="500">
                  No emails found
                </Typography>
              </ItemColumn>
            </ItemRow>
          )}
          {mappedTableData.map((tableRow) => (
            <ItemRow>
              <ItemColumn>
                <div className="w-40">
                  <TypographyStack>
                    <Typography weight="medium" colorWeight="900">
                      <div className="truncate" title="Email Subject">
                        {tableRow.subject_line}
                      </div>
                    </Typography>
                    <Typography size="xs" colorWeight="500">
                      <time>Created {moment(tableRow.created_at).format('LLL')}</time>
                    </Typography>
                  </TypographyStack>
                </div>
              </ItemColumn>
              <ItemColumn>
                <Typography weight="medium" size="sm" colorWeight="700">
                  {tableRow.total_sent}
                </Typography>
              </ItemColumn>
              <ItemColumn>
                <Typography weight="medium" size="sm" colorWeight="700">
                  {tableRow.total_delivered}
                </Typography>
              </ItemColumn>
              <ItemColumn>
                <Typography weight="medium" size="sm" colorWeight="700">
                  {tableRow.open_rate ? `${tableRow.open_rate}%` : '-'}
                </Typography>
              </ItemColumn>
              <ItemColumn>
                <Typography weight="medium" size="sm" colorWeight="700">
                  {tableRow.click_rate ? `${tableRow.click_rate}%` : '-'}
                </Typography>
              </ItemColumn>
              <ItemColumn>
                <Typography weight="medium" size="sm" colorWeight="700">
                  {tableRow.spam_rate ? `${tableRow.spam_rate}%` : '-'}
                </Typography>
              </ItemColumn>
            </ItemRow>
          ))}
        </ItemsBody>
      </Items>
    </div>
  );
};

export default PerformanceVisualisation;
