import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/ui/Button';

import { SimpleSelect } from '../../../../../../components/Form';
import { useCustomDomainToggleUsage, useVerifiedCustomDomains } from '../../../../../../hooks/useCustomDomains';
import { CustomDomain, CustomDomainTypes } from '../../../../../../interfaces/custom_domain';

const CustomDomainRedirectConfig: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { data: customDomains } = useVerifiedCustomDomains();
  const [selectedDomain, setSelectedDomain] = useState(
    customDomains?.find((d: CustomDomain) => d.web_redirect_enabled)?.id || ''
  );
  const nonWebDomains = customDomains ? customDomains?.filter((d: CustomDomain) => !d.web_enabled) : [];

  const onUpdateSuccess = () => {
    navigate(`/settings/publication/domains?configuration=${CustomDomainTypes.REDIRECT}`);
  };

  const toggleUsageMutation = useCustomDomainToggleUsage(onUpdateSuccess);

  const handleSubmit = () => {
    if (selectedDomain) {
      toggleUsageMutation.mutate({ id: selectedDomain, type: CustomDomainTypes.REDIRECT, enabled: true });
    }
  };

  return (
    <div className="flex flex-col w-full space-y-8">
      <div>
        <h2 className="text-lg leading-6 font-medium text-gray-900 ml-0">Web Custom Domain</h2>
        <p className="mt-1 text-gray-500 text-sm">
          In order to setup a domain that redirects to your primary domain, we must setup your domain to point to
          beehiiv. Select a verified domain below to start the process, then follow the instructions to get it set up
          with your DNS provider. Once the domain is setup, it will redirect to your primary custom domain. This is
          primarily useful for redirecting the non-www version of your domain to the www version.
        </p>
      </div>

      {customDomains && (
        <SimpleSelect
          labelText="Select a domain"
          name="web_domain"
          value={selectedDomain}
          options={nonWebDomains.map((domain) => ({
            label: domain.domain,
            value: domain.id,
          }))}
          onSelect={(_label, value) => {
            setSelectedDomain(value);
          }}
        />
      )}
      <div className="flex flex-row justify-end space-x-2">
        <Button
          variant="primary-inverse"
          type="button"
          onClick={() => {
            navigate('/settings/publication/domains');
          }}
        >
          Back
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
          loading={toggleUsageMutation.isLoading}
          disabled={toggleUsageMutation.isLoading}
        >
          Configure Domain
        </Button>
      </div>
    </div>
  );
};

export default CustomDomainRedirectConfig;
