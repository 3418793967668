import { Dispatch, SetStateAction, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import { Input, Textarea } from '@/components/Form';
import { Typography } from '@/components/Typography';
import ResetMenu from '@/routes/website_builder/_components/Form/ResetMenu';
import SelectWithSearch from '@/routes/website_builder/_components/Form/SelectWithSearch';
import { Button } from '@/ui/Button';
import parseGoogleFont from '@/utils/parseGoogleFont';

interface Props {
  label: string;
  searchPlaceholder: string;
  value: string;
  noOptionsText: string;
  options: any;
  onSave: (value: string) => void;
  resettable?: {
    resetValue: any;
    check: boolean;
    label?: string;
  };
}

interface CustomFontProps {
  setCustomFontOpen: Dispatch<SetStateAction<boolean>>;
}

interface FontActionModalProps {
  customFontOpen: boolean;
  setCustomFontOpen: Dispatch<SetStateAction<boolean>>;
  onSave: (value: string) => void;
}

const FontActionModal = ({ customFontOpen, setCustomFontOpen, onSave }: FontActionModalProps) => {
  const [googleFontUrl, setGoogleFontUrl] = useState<string>('');
  const [googleFontName, setGoogleFontName] = useState<string>('');
  const [previewText, setPreviewText] = useState<string>('The quick brown fox jumps over the lazy dog.');

  const handleInput = () => {
    setGoogleFontName(parseGoogleFont(googleFontUrl) || '');
  };

  const handleSave = () => {
    onSave(googleFontName);
    setGoogleFontName('');
    setCustomFontOpen(false);
  };

  return (
    <ActionModal
      isOpen={customFontOpen}
      isWorking={false}
      onClose={() => setCustomFontOpen(false)}
      onProceed={handleSave}
      headerText="Add Custom Google Font"
      resourceId="custom_google_font"
      actionText="Add Google Font"
    >
      <div className="flex flex-col space-y-2">
        <Typography token="font-medium/text/sm" className="text-900">
          Google Font URL
        </Typography>
        <Input
          placeholderText="https://fonts.google.com/specimen/..."
          type="text"
          className="w-full"
          name="value"
          value={googleFontUrl}
          onChange={(e) => setGoogleFontUrl(e.target.value)}
          onBlur={handleInput}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleInput();
            }
          }}
          required
        />
        <Typography token="font-light/text/xs" className="text-900">
          Paste the URL of the google font you want to add as a font option for your website
        </Typography>
      </div>
      <div className="flex flex-col space-y-2 text-start">
        <Typography token="font-medium/text/sm" className="text-900">
          Text Preview
        </Typography>
        {googleFontUrl && (
          <style>{`@import url('https://fonts.googleapis.com/css2?family=${googleFontName.replaceAll(
            ' ',
            '+'
          )}');`}</style>
        )}
        <div style={{ fontFamily: googleFontName }}>
          <Textarea name="font_family_preview" value={previewText} onChange={(e) => setPreviewText(e.target.value)} />
        </div>
      </div>
    </ActionModal>
  );
};

const CustomFont = ({ setCustomFontOpen }: CustomFontProps) => {
  return (
    <div className="px-4 py-2 bg-white">
      <Button
        onClick={() => setCustomFontOpen(true)}
        Icon={PlusIcon}
        type="button"
        className="w-full"
        size="xs"
        variant="primary-inverse"
      >
        Add Google Font
      </Button>
    </div>
  );
};

const FontPicker = ({ label, searchPlaceholder, value, noOptionsText, options, onSave, resettable }: Props) => {
  const [customFontOpen, setCustomFontOpen] = useState<boolean>(false);

  const isValueInOptions = options.some((option: any) => option?.label?.toLowerCase() === value?.toLowerCase());
  const updatedOptions = isValueInOptions ? options : [{ label: value, value }, ...options];

  const isResettable = resettable?.check;
  const resettableValue = resettable?.resetValue;
  const resetValueLabel = resettable?.label;

  return (
    <SelectWithSearch
      label={label}
      searchPlaceholder={searchPlaceholder}
      value={value}
      noOptionsText={noOptionsText}
      options={updatedOptions}
      onSave={onSave}
      // eslint-disable-next-line react/no-unstable-nested-components
      Footer={() => <CustomFont setCustomFontOpen={setCustomFontOpen} />}
      // eslint-disable-next-line react/no-unstable-nested-components
      FooterModal={() => (
        <FontActionModal onSave={onSave} customFontOpen={customFontOpen} setCustomFontOpen={setCustomFontOpen} />
      )}
      prefix={
        isResettable && (
          <ResetMenu resettableValue={resettableValue} resetValueLabel={resetValueLabel} onSave={onSave} />
        )
      }
    />
  );
};

export default FontPicker;
