import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { EyeSlashIcon, SparklesIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import moment from 'moment-mini';

import MetricsBadge from '@/components/_domain/MetricsBadge';
import Badge from '@/components/Badge';
import { Checkbox } from '@/components/Form';
import OptionsDropdown, { OptionsDropdownProps } from '@/components/OptionsDropdown';
import { PostPlatform, PostPreview, PostStatus } from '@/interfaces/post';
import { Publication } from '@/interfaces/publication';

import AuthorsBadge from '../AuthorsBadge';
import PostStatsOverviewContainer from '../PostStatsOverviewContainer';
import StatusBadge from '../StatusBadge';

const PostDate = ({ date }: { date: string }) => {
  return (
    <div className="mt-2">
      <time className="text-xs text-gray-500" dateTime={date}>
        {moment(date).format('LLL')}
      </time>
    </div>
  );
};

type ListPostProps = {
  featured?: boolean;
  post: PostPreview;
  postOptions: (post: PostPreview) => OptionsDropdownProps['options'];
  postRoute: (post: PostPreview) => string;
  publication: Publication;
  selectedPosts: string[];
  handlePostSelect: (post_id: string) => (checked: boolean) => void;
};

const ListPost = ({
  post,
  postOptions,
  postRoute,
  publication,
  selectedPosts,
  handlePostSelect,
  featured = false,
}: ListPostProps) => {
  const canShowMetrics = useCallback((newPost: PostPreview) => newPost.status === PostStatus.PUBLISHED, []);

  const isPublished = (newPost: PostPreview) => {
    return newPost.status === PostStatus.SCHEDULED || newPost.status === PostStatus.PUBLISHED;
  };

  const classNames = cx(
    selectedPosts.includes(post.id) ? 'border-primary-default' : 'border-transparent',
    'border-l-4 w-full first:rounded-tl last:rounded-bl'
  );

  return (
    <li className={cx(classNames, 'border-t-gray-200')}>
      <div className="flex flex-row relative">
        <div className="place-self-center w-8">
          <div className="w-8">
            <Checkbox
              name={`post-${post.id}-select`}
              checked={selectedPosts.includes(post.id)}
              onChange={handlePostSelect(post.id)}
              className="ml-3"
            />
          </div>
        </div>

        <div className="p-4 w-5/6 pr-10">
          <div className="flex justify-between space-x-4 mb-2">
            <div className="flex flex-col w-full">
              {featured && (
                <div className="flex space-x-0.5 items-center mb-1">
                  <SparklesIcon className="text-blue-500 h-4 w-4" />
                  <span className="font-bold p-1 text-xs text-blue-500">Featured</span>
                </div>
              )}

              <span className="text-gray-800 font-semibold truncate">
                <Link to={postRoute(post)} className="hover:text-gray-900">
                  {post.title}
                </Link>
              </span>
              {post.subtitle && <span className="text-gray-500 text-sm mb-2 truncate">{post.subtitle}</span>}
            </div>
          </div>

          <div className="flex space-x-2 justify-between items-end">
            <div className="flex items-center flex-wrap gap-2">
              <StatusBadge status={post.status} />
              {post.send_status === 'testing' && (
                <Badge type="warning">
                  <span className="capitalize text-xs">A/B Testing</span>
                </Badge>
              )}

              {post.hide_from_feed && (
                <>
                  <Badge type="info_blue">
                    <span className="capitalize text-xs">
                      <EyeSlashIcon className="w-4" data-tip data-for="post-hidden-from-feed-tt" />
                    </span>
                  </Badge>

                  <ReactTooltip id="post-hidden-from-feed-tt" place="top" type="dark" effect="solid">
                    <span>Post is hidden from feed</span>
                  </ReactTooltip>
                </>
              )}

              {canShowMetrics(post) && (
                <div className="flex items-center">
                  <PostStatsOverviewContainer
                    postStatsOverview={post.stats_overview}
                    postId={post.id}
                    isPremiumEnabled={publication.is_premium_enabled}
                    staleTime={Infinity}
                  >
                    {(stats) =>
                      post.platform === PostPlatform.WEB ? (
                        <MetricsBadge
                          totalWebViewed={stats.total_web_viewed}
                          totalWebClicked={stats.total_unique_web_clicked}
                          totalUpgrades={stats.total_upgrades}
                        />
                      ) : (
                        <MetricsBadge
                          totalSent={stats.total_sent}
                          openRate={stats.open_rate}
                          clickRate={stats.click_rate}
                          totalUpgrades={stats.total_upgrades}
                        />
                      )
                    }
                  </PostStatsOverviewContainer>
                </div>
              )}

              <div className="hidden sm:flex items-center">
                <AuthorsBadge authors={post.authors} />
              </div>
            </div>
          </div>

          {isPublished(post) && <PostDate date={post.override_scheduled_at} />}
        </div>

        <div className="absolute top-4 right-4">
          <OptionsDropdown options={postOptions(post)} />
        </div>
      </div>
    </li>
  );
};

export default ListPost;
