import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { EmailMessageStats } from '../interfaces/email_message';
import api from '../services/swarm';

const useEmailMessageStats = (emailMessageId: string | undefined) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<EmailMessageStats, Error>(
    ['email_messages', 'stats', emailMessageId],
    () =>
      api
        .get(`email_messages/${emailMessageId}/stats`, { params: { publication_id: currentPublicationId } })
        .then((res) => res.data),
    {
      enabled: Boolean(emailMessageId),
      staleTime: 30000,
      keepPreviousData: true,
    }
  );
};

export default useEmailMessageStats;
