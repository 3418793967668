import { useState } from 'react';
import cx from 'classnames';

import DragIcon from '@/components/SortableList/DragIcon';
import Text from '@/components/Text';
import { AutomationStepStepType } from '@/interfaces/automations/automation_step';

import { COLORS_BY_AUTOMATION_STEP_TYPE } from '../constants';
import { useNewAutoLayoutContext } from '../context/new-layout-context';
import { Fold, Unfold } from '../icons';

const Sidebar = () => {
  const { setDraggingAction, draggingAction, automationStepTypeOptions } = useNewAutoLayoutContext();
  const [isFolded, setIsFolded] = useState(false);
  const buttonClassName = 'cursor-pointer text-gray-800 text-sm font-semibold rounded-md';
  const buttonHoverClassName = 'hover:bg-gray-200';
  const buttonDraggingClassName = 'opacity-95'; // fixes weird sharp white edges when dragging
  const buttonSpacing = 'py-1 px-2';
  const titleClassNames = 'p-2';

  const handleDragStart: React.DragEventHandler<HTMLButtonElement> = (event) => {
    const value = (event.target as HTMLButtonElement).value as AutomationStepStepType;
    setDraggingAction(value);
  };
  const handleDragEnd: React.DragEventHandler<HTMLButtonElement> = () => {
    setDraggingAction(null);
  };

  return (
    <div className={cx('py-4 bg-white shadow-md rounded-md box-content', !isFolded ? 'w-64' : 'overflow-hidden w-14')}>
      <div className="px-3">
        <div className="flex justify-between items-center">
          {!isFolded && (
            <Text as="p" size="md" type="subtitle" className="pt-2 px-2">
              Actions
            </Text>
          )}
          <button
            className={cx('flex justify-center items-center w-8 h-8', buttonClassName)}
            type="button"
            onClick={() => setIsFolded(!isFolded)}
          >
            {isFolded ? <Unfold className="w-4 h-4" /> : <Fold className="w-4 h-4" />}
          </button>
        </div>

        {automationStepTypeOptions.map((item, index) => {
          if (item?.isOptGroup === true) {
            return (
              <Text
                // eslint-disable-next-line react/no-array-index-key
                key={`automations-sidebar-text-${index}`}
                as="p"
                size="sm"
                type="subtitle"
                className={cx(titleClassNames, isFolded && 'text-center', !isFolded && 'mt-2', item?.textColor)}
              >
                {isFolded ? <>&bull;</> : item.label}
              </Text>
            );
          }

          const IconComponent = item.icon;

          if (isFolded && IconComponent) {
            return (
              <button
                className={cx(
                  'flex justify-center items-center w-8 h-8',
                  buttonClassName,
                  buttonHoverClassName,
                  draggingAction && buttonDraggingClassName
                )}
                type="button"
                draggable
                // eslint-disable-next-line react/no-array-index-key
                key={`automations-sidebar-button-${index}`}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                value={item.value}
              >
                <IconComponent className="w-4 h-4" />
              </button>
            );
          }

          return (
            <button
              // eslint-disable-next-line react/no-array-index-key
              key={`automations-sidebar-button-${index}`}
              type="button"
              className={cx(
                'group flex items-center justify-between h-8 w-full',
                buttonClassName,
                buttonSpacing,
                draggingAction && buttonDraggingClassName,
                draggingAction &&
                  draggingAction === item.value &&
                  COLORS_BY_AUTOMATION_STEP_TYPE[item.value].background,
                !draggingAction && buttonHoverClassName
              )}
              draggable
              value={item.value}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <div className="flex items-center gap-x-1.5">
                {IconComponent && <IconComponent className="w-3.5 h-3.5" />}
                {item.label}
              </div>
              <DragIcon className="w-3.5 h-3.5 invisible group-hover:visible" />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
