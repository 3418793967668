import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

export default function useCreateSecondFactor() {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      api
        .post('current_user/second_factor', {
          publication_id: currentPublicationId,
        })
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries(['second_factor']);
    },
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
  });
}
