import { useSearchParams } from 'react-router-dom';

import TabNavigation from '@/components/TabNavigation';
import { useSettings } from '@/context/settings-context';
import { Tab } from '@/interfaces/general';

import AffiliateTab from './AffiliateTab';
import EmailTab from './EmailTab';
import WebTab from './WebTab';

interface Props {
  boostedPublicationId: string;
}

const Tabs = ({ boostedPublicationId }: Props) => {
  const { settings } = useSettings();
  const [searchParams] = useSearchParams();
  const activeTabParam = searchParams.get('tab');

  const canViewEmailApplications = settings?.boosts_plus === true;

  const tabs = [
    {
      name: 'web',
      label: 'Web',
      targetRoute: `/monetize/boosts/boosted_publication/${boostedPublicationId}?tab=web`,
      selected: !activeTabParam || activeTabParam === 'web',
    },
    {
      name: 'direct_link',
      label: 'Direct Link',
      targetRoute: `/monetize/boosts/boosted_publication/${boostedPublicationId}?tab=direct_link`,
      selected: activeTabParam === 'direct_link',
    },
  ] as Tab[];

  if (canViewEmailApplications) {
    tabs.splice(1, 0, {
      name: 'email',
      label: 'Email',
      targetRoute: `/monetize/boosts/boosted_publication/${boostedPublicationId}?tab=email`,
      selected: activeTabParam === 'email',
    });
  }

  return (
    <>
      <TabNavigation tabs={tabs} />
      {(!activeTabParam || activeTabParam === 'web') && <WebTab boostedPublicationId={boostedPublicationId} />}
      {activeTabParam === 'email' && canViewEmailApplications && (
        <EmailTab boostedPublicationId={boostedPublicationId} />
      )}
      {activeTabParam === 'direct_link' && <AffiliateTab boostedPublicationId={boostedPublicationId} />}
    </>
  );
};

export default Tabs;
