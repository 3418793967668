import useOptions from './useOptions';

export default function useFonts() {
  const fonts = useOptions('fonts', Infinity);
  const { data: fontData } = fonts;

  const fontOptions = fontData?.options?.map((f: string) => ({ label: f, value: f }));

  return fontOptions;
}
