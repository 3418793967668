import { findChildren, findParentNode, mergeAttributes, Node } from '@tiptap/core';

export enum ColumnLayout {
  Custom = 'custom',
  SidebarLeft = 'sidebar-left',
  SidebarRight = 'sidebar-right',
  TwoColumn = 'two-column',
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    columns: {
      setColumns: () => ReturnType;
      setLayout: (layout: ColumnLayout) => ReturnType;
      toggleStackOnMobile: () => ReturnType;
      setColumnCount: (count: number) => ReturnType;
      setColumnWidth: (columnIndex: number, width: number | undefined) => ReturnType;
    };
  }
}

export const Columns = Node.create({
  name: 'columns',

  group: 'columns',

  content: '(column{2})|(column{3})',

  defining: true,

  isolating: true,

  addAttributes() {
    return {
      layout: {
        default: ColumnLayout.Custom,
      },
      stackOnMobile: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-stack-on-mobile'),
        renderHTML: (attributes) => ({
          'data-stack-on-mobile': attributes.stackOnMobile,
        }),
      },
    };
  },

  addCommands() {
    return {
      setColumns:
        () =>
        ({ commands }) =>
          commands.insertContent(
            `<div data-type="columns"><div data-type="column"><p></p></div><div data-type="column"><p></p></div></div>`
          ),
      setLayout:
        (layout) =>
        ({ commands }) =>
          commands.updateAttributes('columns', { layout }),
      toggleStackOnMobile:
        () =>
        ({ editor, commands }) =>
          commands.updateAttributes('columns', { stackOnMobile: !editor.getAttributes(this.name)?.stackOnMobile }),
      setColumnCount:
        (count) =>
        ({ dispatch, tr, editor }) => {
          const parentNode = findParentNode((node) => node.type.name === 'columns')(editor.state.selection);

          if (!parentNode || !dispatch) {
            return true;
          }

          const columnsNode = parentNode.node;
          const columnsPos = parentNode.pos;
          const columnsCount = columnsNode.childCount;

          if (columnsCount === 2 && count === 3) {
            // Add column
            const newColumnNode = editor.schema.nodes.column.createAndFill();

            if (newColumnNode) {
              tr.insert(columnsPos + columnsNode.nodeSize - 1, newColumnNode);
            }
          } else if (columnsCount === 3 && count === 2) {
            // Remove column
            const lastColumnNode = columnsNode.child(columnsCount - 1);

            tr.delete(
              columnsPos + columnsNode.nodeSize - lastColumnNode.nodeSize - 1,
              columnsPos + columnsNode.nodeSize - 1
            );
          }

          return true;
        },
      setColumnWidth:
        (columnIndex, width) =>
        ({ dispatch, tr, editor }) => {
          const parentNode = findParentNode((node) => node.type.name === 'columns')(editor.state.selection);

          if (!parentNode || !dispatch) {
            return true;
          }

          const children = findChildren(parentNode.node, (node) => node.type.name === 'column');

          if (columnIndex < children.length) {
            const column = children[columnIndex];
            const columnPos = parentNode.start + column.pos;
            const columnNode = column.node;

            tr.setNodeMarkup(parentNode.pos, undefined, {
              ...parentNode.node.attrs,
              layout: ColumnLayout.Custom,
            });

            tr.setNodeMarkup(columnPos, undefined, {
              ...columnNode.attrs,
              width,
            });
          }

          return true;
        },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { 'data-type': 'columns', class: `layout-${HTMLAttributes.layout}` }),
      0,
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="columns"]',
      },
    ];
  },
});

export default Columns;
