import { useState } from 'react';
import toast from 'react-hot-toast';
import { minMax } from '@tiptap/core';
import { TextSelection } from '@tiptap/pm/state';
import { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import api from '../../../../../services/swarm';
import { useConsumption } from '../../../lib/hooks/useConsumption';
import { getInsertionEndPosition } from '../../../lib/utils/getInsertionEndPosition';
import { AiChangeTextTonePanel } from '../../panels/AiChangeTextTone';
import { PromptType, ToneType } from '../../panels/AiChangeTextTone/types';
import { Button } from '../../ui/Button';
import { ConsumptionProgress } from '../../ui/ConsumptionProgress';
import { Icon } from '../../ui/Icon';
import { Loader } from '../../ui/Loader';
import { Divider } from '../../ui/Toolbar';
import { MenuProps } from '../types';

import { Styled } from './ImproveWriting.styled';

export const ImproveWritingMenu = ({ editor, onBack }: MenuProps & { onBack: () => void }) => {
  const [tone, setTone] = useState<ToneType | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const { consumption, setConsumption, isConsumptionExceeded, showConsumption } = useConsumption();
  const [currentPublicationId] = useCurrentPublicationState();

  const processSelectedText = async ({ type, value }: { type: PromptType; value?: ToneType }) => {
    const { from, to, empty } = editor.state.selection;

    if (empty || !type) {
      return;
    }

    setIsFetching(true);

    const selectedText = editor.state.doc.textBetween(from, to, ' ');

    let path = `editor/ai/text/prompt`;
    const payload: any = { publication_id: currentPublicationId, text: selectedText, starts_inline: true, tone };

    switch (type) {
      case 'SHORTEN':
        path = `editor/ai/text/shorten`;
        break;
      case 'EXTEND':
        path = `editor/ai/text/extend`;
        break;
      case 'SIMPLIFY':
        path = `editor/ai/text/simplify`;
        break;
      case 'TONE':
        path = `editor/ai/text/tone`;
        payload.tone = value;
        break;
      default:
    }

    try {
      axiosRetry(api, {
        retryCondition: (errPayload: AxiosError) => errPayload?.response?.status === 429,
        retryDelay: (retryCount) => 2 ** retryCount * 1000 + Math.random() * 1000,
        retries: 3,
      });

      const response = await api.post(path, payload);
      const { data: json } = response;

      const text = json.response;

      if (!text.length) {
        return;
      }

      const range = {
        from,
        to,
      };

      editor
        .chain()
        .focus()
        .insertContentAt(range, text)
        .command(({ dispatch, tr }) => {
          if (dispatch) {
            const insertionEndPosition = getInsertionEndPosition(tr, tr.steps.length - 1, -1);

            if (insertionEndPosition !== -1) {
              const { doc } = tr;
              const newTo = insertionEndPosition.to;
              const minPos = TextSelection.atStart(doc).from;
              const maxPos = TextSelection.atEnd(doc).to;
              const resolvedFrom = minMax(from, minPos, maxPos);
              const resolvedEnd = minMax(newTo, minPos, maxPos);
              const selection = TextSelection.create(doc, resolvedFrom, resolvedEnd);

              tr.setSelection(selection);

              return dispatch(tr);
            }
          }

          return true;
        })
        .run();

      setConsumption({
        consumedRequests: json.requests_consumed,
        monthlyAvailableRequests: json.requests_allowed,
      });

      setIsFetching(false);
    } catch (errPayload: any) {
      const errorMessage = errPayload?.response?.data?.error;
      const message = errorMessage !== 'An error occurred' ? `An error has occured: ${errorMessage}` : errorMessage;

      setIsFetching(false);
      toast.error(message);
    }
  };

  return (
    <>
      <Button
        $variant="quaternary"
        $size="small"
        onClick={() => processSelectedText({ type: 'SHORTEN' })}
        $leftSlot={<Icon name="AIShorten" />}
        disabled={isConsumptionExceeded}
      >
        Shorten
      </Button>
      <Button
        $variant="quaternary"
        $size="small"
        onClick={() => processSelectedText({ type: 'EXTEND' })}
        $leftSlot={<Icon name="AIExtend" />}
        disabled={isConsumptionExceeded}
      >
        Extend
      </Button>
      <Button
        $variant="quaternary"
        $size="small"
        onClick={() => processSelectedText({ type: 'SIMPLIFY' })}
        $leftSlot={<Icon name="AISimplify" />}
        disabled={isConsumptionExceeded}
      >
        Simplify
      </Button>
      <Divider />
      <AiChangeTextTonePanel
        callback={(t: ToneType) => {
          setTone(t);
          processSelectedText({ type: 'TONE', value: t });
        }}
        tippyOptions={{ placement: 'bottom' }}
        disabled={isConsumptionExceeded}
      />
      <Divider />
      {showConsumption && (
        <Styled.ConsumptionWrapper>
          <ConsumptionProgress
            used={consumption.consumedRequests || 0}
            available={consumption.monthlyAvailableRequests || 0}
            $showTooltip
            tooltipTitle={isConsumptionExceeded ? 'Monthly limit reached' : 'Monthly AI requests'}
          />
        </Styled.ConsumptionWrapper>
      )}
      <Button $variant="quaternary" $size="small" onClick={onBack} $leftSlot={<Icon name="Check" />} />
      {isFetching && <Loader label="Generating cool new stuff" />}
    </>
  );
};

export default ImproveWritingMenu;
