import { Editor } from '@tiptap/react';

import {
  AdvertisementOpportunity,
  AdvertisementOpportunityLogo,
  AiImage,
  AiWriter,
  Audio,
  Boost,
  Button,
  EmbedInput,
  Figcaption,
  FileAttachment,
  GenericEmbed,
  HorizontalRule,
  HtmlSnippet,
  ImageBlock,
  ImageUpload,
  PaywallBreak,
  Poll,
  Recommendation,
  ReferralProgram,
  ServiceEmbed,
  SubscriberBreak,
  TableOfContents,
} from '../../../extensions';

export const isTableGripSelected = (node: HTMLElement) => {
  let container = node;

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container.parentElement!;
  }

  const gripColumn = container && container.querySelector && container.querySelector('a.grip-column.selected');
  const gripRow = container && container.querySelector && container.querySelector('a.grip-row.selected');

  if (gripColumn || gripRow) {
    return true;
  }

  return false;
};

export const isCustomNodeSelected = (editor: Editor, node: HTMLElement) => {
  const customNodes = [
    Boost.name,
    Button.name,
    GenericEmbed.name,
    ServiceEmbed.name,
    EmbedInput.name,
    HorizontalRule.name,
    HtmlSnippet.name,
    ImageBlock.name,
    ImageUpload.name,
    PaywallBreak.name,
    Poll.name,
    AiWriter.name,
    AiImage.name,
    Recommendation.name,
    AdvertisementOpportunity.name,
    AdvertisementOpportunityLogo.name,
    ReferralProgram.name,
    Figcaption.name,
    SubscriberBreak.name,
    TableOfContents.name,
    FileAttachment.name,
    Audio.name,
  ];

  return customNodes.some((type) => editor.isActive(type)) || isTableGripSelected(node);
};

export default isCustomNodeSelected;
