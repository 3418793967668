import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import Grid, { Row } from '@/components/Grid';
import { useCurrentPublicationState } from '@/context/current-publication-context';

import api from '../../../../services/swarm';

import Stat, { MoneyStat, TimeStat } from './Stat';

type StatsType = {
  annualRecurringRevenue: number;
  averageDowngradeTime: number;
  averageUpgradeTime: number;
  currency: string;
  monthlyRecurringRevenue: number;
  revenueYearToDate: number;
  subscriptionsCount: { [x: string]: number };
};

const defaultStats = {
  annualRecurringRevenue: 0,
  averageDowngradeTime: 0,
  averageUpgradeTime: 0,
  currency: 'USD',
  monthlyRecurringRevenue: 0,
  revenueYearToDate: 0,
  subscriptionsCount: {},
};

const Stats = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState<StatsType>(defaultStats);
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchStats = useCallback(() => {
    if (currentPublicationId) {
      setIsLoading(true);
      api
        .get(`/publications/${currentPublicationId}/subscriptions_stats`)
        .then(({ data }: { data: StatsType }) => {
          setStats(data);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentPublicationId]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const {
    annualRecurringRevenue,
    averageDowngradeTime,
    averageUpgradeTime,
    currency,
    monthlyRecurringRevenue,
    revenueYearToDate,
    subscriptionsCount,
  } = stats;

  return (
    <Grid gap={4}>
      <Row className="col-span-12 md:col-span-6 xl:col-span-2">
        <Stat title="Number of Premium Subscribers" isLoading={isLoading} value={subscriptionsCount.premium} />
      </Row>

      <Row className="col-span-12 md:col-span-6 xl:col-span-2">
        <MoneyStat title="Revenue YTD" isLoading={isLoading} value={revenueYearToDate} currency={currency} />
      </Row>

      <Row className="col-span-12 md:col-span-6 xl:col-span-2">
        <MoneyStat title="MRR" isLoading={isLoading} value={monthlyRecurringRevenue} currency={currency} />
      </Row>

      <Row className="col-span-12 md:col-span-6 xl:col-span-2">
        <MoneyStat title="ARR" isLoading={isLoading} value={annualRecurringRevenue} currency={currency} />
      </Row>

      <Row className="col-span-12 md:col-span-6 xl:col-span-2">
        <TimeStat title="Avg. Time to Upgrade (days)" isLoading={isLoading} value={averageUpgradeTime} />
      </Row>

      <Row className="col-span-12 md:col-span-6 xl:col-span-2">
        <TimeStat title="Avg. Subscription Duration (days)" isLoading={isLoading} value={averageDowngradeTime} />
      </Row>
    </Grid>
  );
};

export default Stats;
