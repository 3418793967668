import { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import LoadingBox from '@/components/LoadingBox';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PremiumOffer } from '@/interfaces/premium_offer';
import Section from '@/routes/settings/_components/Section';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import useDeletePremiumOffer from '../../_hooks/useDeletePremiumOffer';
import usePremiumOffers from '../../_hooks/usePremiumOffers';

import FormSlideOver from './FormSlideOver';
import RedemptionsSlideOver from './RedemptionsSlideOver';
import Table from './Table';

const PremiumOffersSection: React.FC = () => {
  const [page, setPage] = useState(1);
  const [isNewOfferOpen, setIsNewOfferOpen] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const [offer, setOffer] = useState<PremiumOffer | null>(null);
  const [editOfferId, setEditOfferId] = useState<string>();
  const [deleteOfferId, setDeleteOfferId] = useState<string>();

  const { data, isLoading, refetch, fetchNextPage, isFetchingNextPage } = usePremiumOffers();

  const { mutate, isLoading: isDeleting } = useDeletePremiumOffer(deleteOfferId);

  const offers = data?.pages[page - 1]?.premium_offers || [];
  const pagination = data?.pages[page - 1]?.pagination;

  const onFetchNextPage = () => {
    if (data?.pages[page + 1]) {
      setPage(page + 1);
    } else {
      fetchNextPage().then(() => setPage(page + 1));
    }
  };

  const onAddOfferSuccess = () => {
    setIsNewOfferOpen(false);
    refetch();
    setPage(1);
    analytics.track('created a premium offer');
  };

  const onEditOfferSuccess = () => {
    setEditOfferId(undefined);
    refetch();
    setPage(1);
  };

  const onDeleteOffer = () => {
    mutate(undefined, {
      onSuccess: () => {
        setDeleteOfferId(undefined);
        setPage(1);
        analytics.track('deleted a premium offer');
      },
    });
  };

  return (
    <>
      <FormSlideOver
        isOpen={isNewOfferOpen}
        onClose={() => setIsNewOfferOpen(false)}
        onSuccess={onAddOfferSuccess}
        publicationId={currentPublicationId}
      />
      <FormSlideOver
        isOpen={!!editOfferId}
        onClose={() => setEditOfferId(undefined)}
        onSuccess={onEditOfferSuccess}
        publicationId={currentPublicationId}
        offerId={editOfferId}
      />
      <RedemptionsSlideOver
        isOpen={Boolean(offer)}
        onClose={() => setOffer(null)}
        offer={offer}
        publicationId={currentPublicationId}
      />
      <ActionModal
        isOpen={!!deleteOfferId}
        onClose={() => setDeleteOfferId(undefined)}
        onProceed={onDeleteOffer}
        resourceId={deleteOfferId || ''}
        isWorking={isDeleting}
        headerText="Delete premium offer"
        actionText="Delete"
        buttonType="danger"
      >
        Are you sure you want to delete this premium offer? (Any subscriber that has already redeemed it will continue
        to use it)
      </ActionModal>

      <Section
        title="Premium Offers"
        description="Provide special offers to your subscribers in order to convert them to Premium"
        maxWidthClassName="max-w-4xl"
      >
        <LoadingBox isLoading={isLoading} isError={false} height="250px" backgroundClassName="bg-transparent">
          <Table
            offers={offers}
            pagination={pagination}
            onEditSelected={setEditOfferId}
            onDeleteSelected={setDeleteOfferId}
            onOfferSelected={setOffer}
          />
          <div className="mt-6 flex justify-between">
            <Button onClick={() => setIsNewOfferOpen(true)}>Add New Offer</Button>

            {pagination && pagination.total_pages > 1 && (
              <div className="flex space-x-2">
                <Button
                  size="xs"
                  disabled={page <= 1}
                  onClick={() => setPage(page - 1)}
                  variant="flush"
                  Icon={ChevronLeftIcon}
                >
                  Prev
                </Button>
                <Button
                  size="xs"
                  disabled={page >= pagination.total_pages}
                  loading={isFetchingNextPage}
                  onClick={onFetchNextPage}
                  variant="flush"
                  Icon={ChevronRightIcon}
                  iconRight
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </LoadingBox>
      </Section>
    </>
  );
};

export default PremiumOffersSection;
