import React, { useCallback, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import { Instance, sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';

import Form from '../../../../_domain/PollForm';
import SlideOver from '../../../../SlideOver';
import { MenuProps } from '../../../components/menus/types';
import { forceUpdateTippy } from '../../../components/menus/utils/forceUpdateTippy';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';
import { usePublicationContext } from '../../../lib/context/PublicationContext';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const PollBlockMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const tippyInstance = useRef<Instance | null>(null);
  const { publicationId } = usePublicationContext();
  const [showEditor, setShowEditor] = useState(false);
  const { id: pollId } = editor.getAttributes('poll');
  const queryClient = useQueryClient();

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-poll');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('poll');

    return isActive;
  }, [editor]);

  const removeNode = useCallback(() => {
    editor.chain().focus().deleteNode('poll').deleteSelection().run();
  }, [editor]);

  const clearSelection = useCallback(() => {
    editor.chain().updateAttributes('poll', { id: undefined }).run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`pollBlock-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <SlideOver
        bodyId={`poll-editor-slideover-${pollId}`}
        isOpen={showEditor}
        onClose={() => setShowEditor(false)}
        headerText="New Poll"
      >
        <Form
          publicationId={publicationId}
          onCancel={() => setShowEditor(false)}
          onSuccess={() => {
            queryClient.invalidateQueries(['polls', pollId]);
            setShowEditor(false);
          }}
          slideOverBodyId={`poll-editor-slideover-${pollId}`}
          pollId={pollId}
        />
      </SlideOver>
      <Toolbar ref={menuRef} shouldShowContent={shouldShow()}>
        {pollId && (
          <>
            <Tooltip title="Edit poll">
              <Button
                $leftSlot={<Icon name="Edit" />}
                onClick={() => {
                  if (tippyInstance.current) {
                    forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
                  }
                  setShowEditor(true);
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Clear selection">
              <Button
                $leftSlot={<Icon name="Refresh" />}
                onClick={() => {
                  if (tippyInstance.current) {
                    forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
                  }
                  clearSelection();
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
              />
            </Tooltip>
            <Divider />
          </>
        )}
        <Tooltip title="Remove poll">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $leftSlot={<Icon name="Trash" />}
            onClick={removeNode}
          />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default PollBlockMenu;
