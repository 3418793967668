import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment-mini';

import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { EmailMessage, EmailMessagePreview } from '../../../../interfaces/email_message';
import api from '../../../../services/swarm';
import OptionsDropdown from '../../../OptionsDropdown';
import MetricsBadge from '../../MetricsBadge';
import StatusBadge from '../StatusBadge';

interface ListItemProps {
  emailMessage: EmailMessagePreview;
  viewUrl: string;
  editUrl: string;
  allowDuplicate?: boolean;
}

const ListItem = ({ emailMessage, viewUrl, editUrl, allowDuplicate = true }: ListItemProps) => {
  const navigate = useNavigate();
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const duplicateEmail = useMutation<EmailMessage>(
    () =>
      api
        .post(`/email_messages/${emailMessage.id}/duplicate`, { publication_id: currentPublicationId })
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        if (queryClient) queryClient.setQueriesData(['email_messages', res.id], res);
        toast.success('Email successfully duplicated');
        navigate(viewUrl.replace(emailMessage.id, res.id));
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again');
      },
    }
  );

  const emailMessageOptions = [
    [
      {
        label: 'View',
        onClick: () => {
          navigate(viewUrl);
        },
      },
      ...(emailMessage.last_sent_at
        ? []
        : [
            {
              label: 'Edit',
              onClick: () => {
                navigate(editUrl);
              },
            },
          ]),
      ...(allowDuplicate
        ? [
            {
              label: 'Duplicate',
              onClick: () => duplicateEmail.mutate(),
            },
          ]
        : []),
    ],
  ];

  return (
    <li className="p-4 " key={emailMessage.id}>
      <div className="flex justify-between items-center">
        <div className="w-2/3">
          <p className="truncate">
            <Link to={viewUrl} className="hover:text-gray-900">
              <p className="text-gray-800 font-semibold">{emailMessage.subject_line || 'No subject'}</p>
              <p className="text-gray-500 text-sm">{emailMessage.preview_text}</p>
            </Link>
          </p>
        </div>
        <div>
          <OptionsDropdown options={emailMessageOptions} />
        </div>
      </div>
      <div className="pt-2 flex space-x-2">
        <StatusBadge status={emailMessage.status} lastSentAt={emailMessage.last_sent_at} />
        {emailMessage.last_sent_at && moment(emailMessage.last_sent_at).isBefore() && (
          <MetricsBadge
            totalSent={emailMessage.stats_overview.total_sent}
            openRate={emailMessage.stats_overview.open_rate}
            clickRate={emailMessage.stats_overview.click_rate}
          />
        )}
        {emailMessage.resource_display && (
          <div className="font-medium text-sm text-gray-500">→ {emailMessage.resource_display}</div>
        )}
      </div>
      <div className="mt-2 text-xs text-gray-500">
        {emailMessage.last_sent_at ? (
          <time>{moment(emailMessage.last_sent_at).format('LLL')}</time>
        ) : (
          <div>Not Scheduled</div>
        )}
      </div>
    </li>
  );
};

export default ListItem;
