import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RectangleStackIcon } from '@heroicons/react/24/outline';
import { StringParam, useQueryParam } from 'use-query-params';

import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import SearchInput from '@/components/SearchInput';
import { useRequireSetting } from '@/hooks';
import { useAutomations } from '@/hooks/useAutomations';
import { useTutorial } from '@/hooks/useTutorials';
import { Setting } from '@/interfaces/setting';
import { TutorialType } from '@/interfaces/tutorial';
import { Button } from '@/ui/Button';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import { Empty } from './Empty';
import ListItem from './ListItem';

const AutomationsList = () => {
  useRequireSetting({ setting: Setting.AUTOMATIONS, message: 'Upgrade to create automations', redirectTo: '/' });
  const tutorial = useTutorial(TutorialType.AUTOMATIONS);

  const [search, setSearch] = useQueryParam('search', StringParam);
  const [shouldResetSearch, setShouldResetSearch] = useState(false);
  const navigate = useNavigate();

  const automationsQuery = useAutomations({ search: search || '' });
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching } = automationsQuery;

  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch]);

  const handleResetSearch = () => {
    setSearch(undefined);
    setShouldResetSearch(true);
  };

  const automations = data?.pages.flatMap((page) => page.automations) || [];
  const totalCount = data?.pages[0]?.pagination?.total;
  const showingCount = automations?.length || 0;
  const isNoResults = !isLoading && automations.length === 0;

  return (
    <>
      <PageHeading
        title="Automations 🚀"
        description="Build powerful automated email journeys for your subscribers."
        tutorial={tutorial}
      >
        {(automations.length > 0 || search) && (
          <Button variant="primary" Icon={RectangleStackIcon} onClick={() => navigate('/automations/new')}>
            New Automation
          </Button>
        )}
      </PageHeading>
      {(isLoading || isFetching) && (
        <div className="flex w-full h-128 items-center justify-center">
          <LoadingSpinner />
        </div>
      )}
      {(automations.length > 0 || search) && (
        <div className="flex w-full md:justify-end mb-4">
          <div className="shadow-sm w-full md:w-80 rounded-md">
            <SearchInput
              defaultValue={search || ''}
              shouldDebounce={false}
              shouldReset={shouldResetSearch}
              onClearSearch={handleResetSearch}
              onSearch={setSearch}
              placeholder="Search automations"
            />
          </div>
        </div>
      )}
      {data && automations.length === 0 && (
        <Empty
          hasNoSearchResults={isNoResults && Boolean(search)}
          searchQuery={search}
          onCreate={() => navigate('/automations/new')}
          onResetSearch={handleResetSearch}
        />
      )}
      {data && automations.length > 0 && (
        <div className="flex flex-col gap-y-2">
          {totalCount && <ResourceListSearchText totalCount={totalCount} showingCount={showingCount} />}
          <div className="-mb-2 overflow-visible sm:-mx-6 lg:-mx-8">
            <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-visible border rounded-md border-gray-200 relative">
                <Items>
                  <ItemHeaders>
                    <ItemHeader align={AlignType.LEFT}>Automation Name</ItemHeader>
                    <ItemHeader align={AlignType.LEFT}>Description</ItemHeader>
                    <ItemHeader align={AlignType.LEFT}>Status</ItemHeader>
                    <ItemHeader align={AlignType.LEFT}>Subscribers Enrolled</ItemHeader>
                    <ItemHeader align={AlignType.LEFT}>Subscribers Completed</ItemHeader>
                    <ItemHeader align={AlignType.LEFT}>Last Edited</ItemHeader>
                    <ItemHeader isSticky>&nbsp;</ItemHeader>
                  </ItemHeaders>

                  <ItemsBody>
                    {automations.map((automation) => (
                      <ListItem key={automation.id} automation={automation} />
                    ))}
                  </ItemsBody>
                </Items>
              </div>
            </div>
          </div>

          {hasNextPage && (
            <div className="flex justify-start mt-8 w-fit">
              <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AutomationsList;
