import createGradient from './createGradient';
import { StyledChartProps } from './styledChartData.types';

const getBackgroundColor = ({ dataset, chart }: any) => {
  const { variant = 'primary', type = 'line' } = dataset;

  switch (type) {
    case 'line':
      switch (variant) {
        case 'primary':
          return createGradient({
            chart,
            colors: {
              start: 'rgba(236, 72, 153, 0.01)',
              mid: 'rgba(236, 72, 153, 0.5)',
              end: 'rgba(236, 72, 153, 0.8)',
            },
          });
        case 'secondary':
          return createGradient({
            chart,
            colors: {
              start: 'rgba(59, 130, 246, 0.01)',
              mid: 'rgba(59, 130, 246, 0.5)',
              end: 'rgba(59, 130, 246, 0.8)',
            },
          });
        case 'feedback-success':
          return createGradient({
            chart,
            colors: {
              start: 'rgba(5, 150, 105, 0.01)',
              mid: 'rgba(5, 150, 105, 0.5)',
              end: 'rgba(5, 150, 105, 0.8)',
            },
          });
        case 'muted':
          return 'transparent';
        default:
          return 'transparent';
      }
    case 'bar':
      switch (variant) {
        case 'primary':
          return 'rgba(236, 72, 153, 0.8)';
        case 'secondary':
          return 'rgba(59, 130, 246, 0.8)';
        case 'feedback-success':
          return 'rgba(5, 150, 105, 0.8)';
        case 'muted':
          return 'rgba(0, 0, 0, 0.2)';
        default:
          return 'rgba(0, 0, 0, 0.2)';
      }
    default:
      return 'transparent';
  }
};

const withDatasetOptions = ({ data, chart }: StyledChartProps) => {
  const { datasets } = data;
  return {
    ...data,
    datasets: datasets.map((dataset) => {
      const { variant } = dataset;

      const borderColor = {
        primary: 'rgba(236, 72, 153, 1)',
        secondary: 'rgba(59, 130, 246, 1)',
        'feedback-success': 'rgba(5, 150, 105, 1)',
        muted: '#e2e8f0',
      }[variant];

      const pointBackgroundColor = {
        primary: 'rgba(236, 72, 153, 0.7)',
        secondary: 'rgba(59, 130, 246, 0.7)',
        'feedback-success': 'rgba(5, 150, 105, 0.7)',
        muted: '#e5e7eb',
      }[variant];

      const pointBorderColor = {
        primary: 'rgba(236, 72, 153, 0.7)',
        secondary: 'rgba(59, 130, 246, 0.7)',
        'feedback-success': 'rgba(5, 150, 105, 0.7)',
        muted: '#e5e7eb',
      }[variant];

      const borderDash = {
        primary: [0, 0],
        muted: [5, 5],
        secondary: [0, 0],
        'feedback-success': [0, 0],
      }[variant];

      const pointRadius = {
        primary: 3,
        secondary: 3,
        'feedback-success': 3,
        muted: 0,
      }[variant];

      return {
        ...dataset,
        backgroundColor: getBackgroundColor({ dataset, chart }),
        borderColor,
        pointBackgroundColor,
        pointBorderColor,
        borderDash,
        pointRadius,
        fill: 'start',
        tension: 0.3,
      };
    }),
  };
};

export default withDatasetOptions;
