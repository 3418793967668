import { useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { v4 as generateUuid } from 'uuid';

import Badge from '@/components/Badge';
import { SimpleSelect } from '@/components/Form';
import { usePublications } from '@/hooks/useAdNetwork/internal';
import { AdNetworkPublication } from '@/interfaces/ad_network/internal/publication';
import { LogicalOperators } from '@/interfaces/condition';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import { LoadingListPage } from '../_components/LoadingListPage';
import { PageHeading } from '../_components/PageHeading';
import useFilteringContext, { FilteringContext } from '../_components/PublicationFilters/filtering-context';
import Filters from '../_components/PublicationFilters/Filters';
import useConditionManager from '../_components/PublicationFilters/useConditionManager';

interface Props {
  publications: AdNetworkPublication[];
  hasNextPage: boolean;
  fetchNextPage: () => Promise<any>;
  isRefetching: boolean;
  pagination: Pagination;
  exportState: any;
}

const Publications = ({ publications, hasNextPage, fetchNextPage, isRefetching, pagination, exportState }: Props) => {
  const [selectedReportType, setSelectedReportType] = useState('overview');
  const reportOptions = [
    { label: 'Overview', value: 'overview' },
    { label: 'Earnings Report', value: 'earnings' },
    { label: 'Placements Report', value: 'placements' },
  ];
  const exportParams = {
    page: pagination.page,
    query: '*',
    conditions: exportState,
  };
  const fetchCsv = async () => {
    const reportParams = { ...exportParams, ...{ report_type: selectedReportType } };
    const response = await api.get(`ad_network/internal/publications/export`, { params: reportParams });
    return response.data;
  };

  const downloadCsvMutation = useMutation<any>(fetchCsv, {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.csv]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `export.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    onError: () => {
      console.log('error');
    },
  });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="">Publications</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <div className="flow-root">
          <SimpleSelect
            className="float-left"
            name="value"
            placeholderText="Report Type"
            value={selectedReportType || 'overview'}
            onSelect={(_name: string, value: string) => {
              setSelectedReportType(value);
            }}
            options={reportOptions}
          />
          <Button className="float-left ml-2" type="button" onClick={() => downloadCsvMutation.mutate()}>
            Download CSV
          </Button>
        </div>
      </PageHeading>
      <div className="border-b border-gray-100 p-4 flex space-x-2 justify-between items-center">
        <Filters />
      </div>
      <table className="w-full text-sm divide-y divide-gray-100">
        <thead className="bg-gray-50">
          <tr>
            <th className="!font-normal px-4 py-2" align="left">
              Publication
            </th>
            <th className="!font-normal px-4 py-2" align="left">
              Owner email
            </th>
            <th className="!font-normal px-4 py-2" align="left">
              Plan
            </th>
            <th className="!font-normal px-4 py-2" align="left">
              Tags
            </th>
            <th className="!font-normal px-4 py-2" align="left">
              Active subscribers
            </th>
            <th className="!font-normal px-4 py-2" align="left" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100">
          {publications.map((publication) => {
            return (
              <tr key={publication.id}>
                <td className="px-4 py-2">
                  <p>
                    <Link to={`/ad_network/publications/${publication.id}`}>{publication.name}</Link>
                  </p>
                  <a
                    href={`https://${publication.hostname}`}
                    className="hover:underline text-gray-400 hover:text-primary-600 inline-flex items-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {publication.hostname}
                    <ArrowTopRightOnSquareIcon className="inline-block w-4 h-4 ml-1" />
                  </a>
                </td>
                <td className="px-4 py-2">
                  <a
                    href={`mailto:${publication.owner_email}`}
                    className="hover:underline text-gray-400 hover:text-primary-600"
                  >
                    {publication.owner_email}
                  </a>
                </td>
                <td className="px-4 py-2">{publication.plan_name}</td>
                <td className="px-4 py-2">
                  <div className="flow-root">
                    <div className="-m-1 flex flex-wrap max-w-md">
                      {publication.tags.map((name) => (
                        <div className="p-1" key={name}>
                          <Badge>#{name}</Badge>
                        </div>
                      ))}
                    </div>
                  </div>
                </td>
                <td className="px-4 py-2">{publication.active_subscription_count}</td>
                <td>
                  <a
                    target="_blank"
                    href={`/ad_network/publications/${publication.id}`}
                    rel="noreferrer"
                    className="bg-white hover:bg-gray-100 text-gray-800 disabled:text-gray-600 disabled:bg-gray-100 rounded-md shadow-sm border-gray-300 py-1 px-3 text-sm justify-center duration-200 border font-medium focus:outline-none focus:ring-2 inline-flex items-center disabled:cursor-not-allowed whitespace-nowrap space-x-1"
                  >
                    <span>View</span>
                    <ArrowTopRightOnSquareIcon className="inline-block w-4 h-4 mr-1" />
                  </a>
                </td>
              </tr>
            );
          })}

          {hasNextPage && (
            <tr>
              <td colSpan={5} className="px-4 py-2">
                <Button onClick={fetchNextPage} loading={isRefetching} variant="primary-inverse" size="xs">
                  Load more
                </Button>
              </td>
            </tr>
          )}

          <tr className="sticky bottom-0 bg-white">
            <td colSpan={5} className="px-4 py-2">
              <p>
                Showing {publications.length} of {pagination.total} publications
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

function Loader() {
  const { state } = useFilteringContext();

  const { data, isSuccess, isLoading, isError, hasNextPage, fetchNextPage, isRefetching } = usePublications({
    conditions: JSON.stringify(state),
  });

  if (!isSuccess || isLoading || isError) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  const publications = data?.pages.flatMap((page) => page.publications) || [];
  const pagination = data?.pages[0].pagination;

  return (
    <Publications
      publications={publications}
      hasNextPage={!!hasNextPage}
      fetchNextPage={fetchNextPage}
      isRefetching={isRefetching}
      pagination={pagination}
      exportState={state}
    />
  );
}

export default function ContextLoader() {
  const conditionsManager = useConditionManager({
    initialState: {
      type: 'group',
      logical_operator: LogicalOperators.AND,
      conditions: [],
      uuid: generateUuid(),
    },
    localStorageKey: 'ad_network_publication_filters',
  });

  return (
    <FilteringContext.Provider value={conditionsManager}>
      <Loader />
    </FilteringContext.Provider>
  );
}
