import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { RectangleGroupIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';

import Header from '../../../components/Header';
import LoadingBox from '../../../components/LoadingBox';
import OptionsDropdown from '../../../components/OptionsDropdown';
import Text from '../../../components/Text';
import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { usePostTemplates } from '../../../hooks';
import { PostTemplate } from '../../../interfaces/post_template';
import api from '../../../services/swarm';
import { useTemplateViewContext } from '..';

const Templates: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { resourceType, onSelect } = useTemplateViewContext();

  const [publicationId] = useCurrentPublicationState();

  const postTemplatesQuery = usePostTemplates();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } = postTemplatesQuery;

  const postTemplates = data?.pages.flatMap((page) => page.post_templates) || [];

  const postTemplateOptions = useCallback(
    (post_template: PostTemplate) => [
      [
        {
          label: 'Use this template',
          onClick: () => {
            onSelect(post_template.id);
          },
        },
      ],
      [
        {
          label: 'View',
          onClick: () => {
            navigate(`/templates/${resourceType}/${post_template.id}`);
          },
        },
        {
          label: 'Edit',
          onClick: () => {
            navigate(`/templates/${resourceType}/${post_template.id}/edit`);
          },
        },
      ],
      [
        {
          label: 'Delete',
          color: 'red',
          onClick: () => {
            // eslint-disable-next-line no-alert
            const confirm = window.confirm(`Are you sure? This cannot be undone.`);

            if (confirm) {
              api
                .delete(`/post_templates/${post_template.id}`, {
                  params: {
                    publication_id: publicationId,
                  },
                })
                .then(() => {
                  toast.success('Post template deleted');
                  postTemplatesQuery.refetch();
                })
                .catch((err) => {
                  toast.error(err?.response?.data?.error || 'Something went wrong');
                });
            }
          },
        },
      ],
    ],
    [onSelect, navigate, resourceType, publicationId, postTemplatesQuery]
  );

  const isNoResults = !isLoading && postTemplates.length === 0;

  const NoResults = () => (
    <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
      <div className="flex flex-col items-center gap-4">
        <p className="text-gray-500 text-center">Once you create your first template, it will show up here.</p>
        <Button variant="primary" onClick={() => navigate(`/templates/${resourceType}/new`)}>
          <div className="flex">
            <RectangleGroupIcon className="h-5 w-5 mr-2" />
            New template
          </div>
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <div className="py-4 grid grid-cols-5">
        <div className="col-span-3">
          <Header>Templates</Header>
          <Text>Define custom templates to jump start your writing</Text>
        </div>

        {!isLoading && !isNoResults && (
          <div className="col-span-2 flex items-end justify-end">
            <Button onClick={() => navigate(`/templates/${resourceType}/new`)}>
              <div className="flex">
                <RectangleGroupIcon className="h-5 w-5 mr-2" />
                New template
              </div>
            </Button>
          </div>
        )}
      </div>
      <div className="mb-4">
        <hr />
      </div>
      <LoadingBox isLoading={isLoading} isError={isError}>
        <>
          {isNoResults ? (
            <NoResults />
          ) : (
            <ul className="divide-y border rounded bg-white">
              {postTemplates.map((post_template) => (
                <li className="p-4 " key={post_template.id}>
                  <div className="flex justify-between items-center">
                    <div className="w-2/3">
                      <p className="text-gray-800 font-semibold truncate">
                        <Link to={`/templates/${resourceType}/${post_template.id}`} className="hover:text-gray-900">
                          {post_template.name}
                        </Link>
                      </p>
                    </div>
                    <div>
                      <OptionsDropdown options={postTemplateOptions(post_template)} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
          <div className="text-center mt-6">
            {hasNextPage && (
              <div>
                <Button
                  variant="primary-inverse"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                </Button>
              </div>
            )}
          </div>
        </>
      </LoadingBox>
    </div>
  );
};

export default Templates;
