import { WebhookEndpointCreatePayload } from '@/interfaces/webhooks/endpoint';

export type StepErrors = {
  url?: string;
  event_types?: string;
};

export type StepProps = {
  endpoint: WebhookEndpointCreatePayload;
  errors: StepErrors | null;
  onChange?: (args: WebhookEndpointCreatePayload) => void;
};

export enum WizardSteps {
  DETAILS = 1,
  EVENTS = 2,
  REVIEW = 3,
}
