import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { TrashIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import HelpingHand from '@/components/HelpingHand';
import Table, { TableBody, TableCol, TableRow } from '@/components/Table';

import { Checkbox, Input, SimpleSelect } from '../../../../../../components/Form';
import { useCurrentPublicationState } from '../../../../../../context/current-publication-context';
import { useCustomFields } from '../../../../../../hooks';
import { CsvColumn } from '../../../../../../interfaces/csv_column';
import api from '../../../../../../services/swarm';
import { selectOptions as dataTypeSelectOptions } from '../../../../../../utils/dataTypes';

type TableType = {
  children: React.ReactNode;
  className?: string;
};

const TableColInHeader = ({ className = '', children }: TableType) => {
  const classNames = cx('!px-3 !py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider', className);
  return <TableCol className={classNames}>{children}</TableCol>;
};

const TableColInBody = ({ className = '', children }: TableType) => {
  const classNames = cx('!px-3 !py-2 align-top leading-none', className);
  return <TableCol className={classNames}>{children}</TableCol>;
};

interface Props {
  columns: CsvColumn[];
  onChange: (columnMapping: any, errors: any) => void;
}

const PreImport: FC<Props> = (props: Props) => {
  const NEW_CUSTOM_FIELD_ID = 'new-custom-field';
  const { columns, onChange } = props;
  const customFieldsQuery = useCustomFields({ search: '', all: true });
  const { data } = customFieldsQuery;
  const customFields = data?.pages.flatMap((page) => page.customFields) || [];
  const [overwrite, setOverwrite] = useState(true);
  const [currentPublicationId] = useCurrentPublicationState();

  // {
  //   "0" => { custom_field_id: "", kind: "", display: "", selected: false },
  //   "1" => { custom_field_id: "", kind: "", display: "", selected: false },
  // }
  const initializeColumnMapping: any = columns
    .map((column) => ({
      key: column.column_number,
      value: {
        field_type: column.suggested_custom_field?.id ? 'custom_field' : null,
        custom_field_id: column.suggested_custom_field?.id,
        display: column.suggested_custom_field?.display,
        kind: column.suggested_custom_field?.kind,
        overwrite,
        selected:
          column.suggested_custom_field?.id !== undefined &&
          column.suggested_custom_field?.id !== null &&
          column.suggested_custom_field?.id !== '',
      },
    }))
    .reduce((acc, curr) => ({ ...acc, [curr.key.toString()]: curr.value }), {});
  const [columnMapping, setColumnMapping] = useState(initializeColumnMapping);
  const allColumnsSelected = () => Object.values(columnMapping).every((mapping: any) => mapping.selected);
  const [selectAll, setSelectAll] = useState(allColumnsSelected());
  // {
  //   'display__0': '',
  //   'kind__0' => '',
  //   'custom_field_id__0' => '',
  // }
  const initializeErrors: any = () => {
    const tmp: any = {};
    columns.forEach((column) => {
      tmp[`display__${column.column_number}`] = '';
      tmp[`kind__${column.column_number}`] = '';
      tmp[`custom_field_id__${column.column_number}`] = '';
    });
    return tmp;
  };
  const [errors, setErrors] = useState(initializeErrors());

  const updateError = (fieldName: string, newError: string) => {
    errors[fieldName] = newError;
    setErrors({ ...errors });
  };

  useEffect(() => {
    onChange(columnMapping, errors);
  }, [columnMapping, errors, onChange]);

  const filteredCustomFieldOptions = (columnNumber: number) => {
    const usedCustomFields = Object.values(columnMapping).map((mapping: any) => mapping.custom_field_id);
    const availableCustomFields = customFields.filter(
      (customField) =>
        customField.id === columnMapping[columnNumber].custom_field_id || !usedCustomFields.includes(customField.id)
    );
    const options = availableCustomFields.map((customField) => ({
      label: customField.display,
      value: customField.id,
    }));
    options.unshift({ label: 'New Custom Field', value: NEW_CUSTOM_FIELD_ID });
    return options;
  };

  const checkSelectedColumnErrors = (columnNumber: number) => {
    errors[`display__${columnNumber}`] = '';
    errors[`kind__${columnNumber}`] = '';
    errors[`custom_field_id__${columnNumber}`] = '';
    if (columnMapping[columnNumber]?.custom_field_id === NEW_CUSTOM_FIELD_ID) {
      if (columnMapping[columnNumber]?.display === '' || columnMapping[columnNumber]?.display === undefined) {
        errors[`display__${columnNumber}`] = 'Please enter a name';
      }
      if (columnMapping[columnNumber]?.kind === '' || columnMapping[columnNumber]?.kind === undefined) {
        errors[`kind__${columnNumber}`] = 'Please select a data type';
      }
    } else if (
      columnMapping[columnNumber]?.field_type !== 'subscriber_tags' &&
      (columnMapping[columnNumber]?.custom_field_id === '' ||
        columnMapping[columnNumber]?.custom_field_id === null ||
        columnMapping[columnNumber]?.custom_field_id === undefined)
    ) {
      errors[`custom_field_id__${columnNumber}`] = 'Please select a custom field';
    }
    setErrors({ ...errors });
  };

  const checkUnselectedColumnErrors = (columnNumber: number) => {
    errors[`display__${columnNumber}`] = '';
    errors[`kind__${columnNumber}`] = '';
    errors[`custom_field_id__${columnNumber}`] = '';
    setErrors({ ...errors });
  };

  const handleSelectAllColumnsChange = (checkedValue: boolean) => {
    setSelectAll(checkedValue);

    Object.keys(columnMapping).forEach((key) => {
      columnMapping[key].selected = checkedValue;
      if (checkedValue) {
        checkSelectedColumnErrors(parseInt(key, 10));
      } else {
        checkUnselectedColumnErrors(parseInt(key, 10));
      }
    });
    setColumnMapping({ ...columnMapping });
  };

  const handleOverwriteChange = (checkedValue: boolean) => {
    setOverwrite(checkedValue);

    Object.keys(columnMapping).forEach((key) => {
      columnMapping[key].overwite = checkedValue;
    });
    setColumnMapping({ ...columnMapping });
  };

  const handleSelect = (name: string, value: string) => {
    const fieldName = name.split('__')[0];
    const columnNumber = parseInt(name.split('__')[1], 10);
    const prevVal = columnMapping[columnNumber].custom_field_id;
    columnMapping[columnNumber][fieldName] = value;

    updateError(name, '');
    if (fieldName === 'custom_field_id' && value !== prevVal) {
      const selectedCustomField = customFields.find((customField) => customField.id === value);
      columnMapping[columnNumber].kind = selectedCustomField?.kind;
      columnMapping[columnNumber].display = selectedCustomField?.display;
    }
    if (columnMapping[columnNumber].selected) {
      checkSelectedColumnErrors(columnNumber);
    } else {
      checkUnselectedColumnErrors(columnNumber);
    }
    setColumnMapping({ ...columnMapping });
  };

  const handleCustomFieldNameCheckResponse = (columnNumber: number, errorMessage: string) => {
    updateError(`display__${columnNumber}`, errorMessage);
  };

  const customFieldNameCheckMutation = useMutation(
    ({ columnNumber, display }: { columnNumber: number; display: string }) =>
      api.patch('/custom_fields/name_check', {
        publication_id: currentPublicationId,
        column_number: columnNumber,
        custom_field: {
          display,
        },
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: (resp) => {
        handleCustomFieldNameCheckResponse(resp.data.columnNumber, resp.data.errors);
      },
    }
  );

  const handleCustomFieldNameCheck = async (columnNumber: number, display: string) => {
    await customFieldNameCheckMutation.mutateAsync({ columnNumber, display });
  };

  const handleDisplayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const columnNumber = parseInt(name.split('__')[1], 10);
    columnMapping[columnNumber].display = value;
    handleCustomFieldNameCheck(columnNumber, value);
    setColumnMapping({ ...columnMapping });
  };

  const handleDisplayReset = (columnNumber: number) => {
    columnMapping[columnNumber].display = '';
    columnMapping[columnNumber].custom_field_id = null;
    columnMapping[columnNumber].kind = null;

    setColumnMapping({ ...columnMapping });
    checkSelectedColumnErrors(columnNumber);
  };

  const handleSelectColumnChange = (columnNumber: number, checkedValue: boolean) => {
    columnMapping[columnNumber].selected = checkedValue;
    if (allColumnsSelected()) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    setColumnMapping({ ...columnMapping });
    if (checkedValue) {
      checkSelectedColumnErrors(columnNumber);
    } else {
      checkUnselectedColumnErrors(columnNumber);
    }
  };

  const toggleSubscriberTagColumn = (column: any, value: string) => {
    columnMapping[column.column_number].field_type = value;
    if (value === 'subscriber_tags') {
      columnMapping[column.column_number].display = column.column_name;
      columnMapping[column.column_number].custom_field_id = null;
      columnMapping[column.column_number].kind = null;
      checkUnselectedColumnErrors(column.column_number);
    } else {
      checkSelectedColumnErrors(column.column_number);
    }
    setColumnMapping({ ...columnMapping });
  };

  const isColumnSelected = (columnNumber: number) => columnMapping[columnNumber]?.selected;
  const isColumnNewField = (columnNumber: number) =>
    columnMapping[columnNumber]?.custom_field_id === NEW_CUSTOM_FIELD_ID;
  const isColumnFieldTypeCustomField = (columnNumber: number) =>
    columnMapping[columnNumber]?.field_type === 'custom_field';

  return (
    <div>
      <HelpingHand>
        <p>
          Upload your subscriber list with tags and custom fields by mapping the columns to the appropriate subscriber
          data type below
        </p>
      </HelpingHand>
      <Checkbox
        checked={overwrite}
        onChange={handleOverwriteChange}
        name="overwrite"
        labelText="Overwrite existing custom field data on import"
        className="mt-4 mb-8"
      />
      <div className="overflow-x-scroll md:overflow-x-visible">
        <Table className="shadow rounded-lg table-fixed">
          <TableRow className="bg-gray-50 border-b border-gray-200 rounded-t-lg">
            <TableColInHeader className="w-1/12">
              <Checkbox checked={selectAll} onChange={handleSelectAllColumnsChange} name="email_only" />
            </TableColInHeader>
            <TableColInHeader className="w-2/12">Column</TableColInHeader>
            <TableColInHeader className="w-3/12">Field Type</TableColInHeader>
            <TableColInHeader className="w-3/12 px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
              <div className="uppercase">Custom Field</div>
            </TableColInHeader>
            <TableColInHeader className="w-3/12">Data Type</TableColInHeader>
          </TableRow>
          {/* </div> */}
          <TableBody className="bg-white max-h-96 overflow-y-auto rounded-b-lg">
            {columns.map((column) => (
              <tr
                className="flex-wrap border-b border-gray-200"
                key={`csv-column-${column.column_name}-${column.column_number}`}
              >
                <TableColInBody className="!pt-5">
                  <Checkbox
                    checked={isColumnSelected(column.column_number)}
                    onChange={(checkedValue: boolean) => handleSelectColumnChange(column.column_number, checkedValue)}
                    name={`selected__${column.column_number}`}
                  />
                </TableColInBody>
                <TableColInBody className="!pt-5">{column.column_name}</TableColInBody>
                <TableColInBody>
                  <SimpleSelect
                    name={`column_${column.column_number}_field_type`}
                    value={columnMapping[column.column_number].field_type}
                    onSelect={(name: string, value: any) => {
                      toggleSubscriberTagColumn(column, value);
                    }}
                    options={[
                      { label: 'Custom Field', value: 'custom_field' },
                      { label: 'Subscriber Tags', value: 'subscriber_tags' },
                    ]}
                  />
                </TableColInBody>
                {isColumnSelected(column.column_number) && isColumnFieldTypeCustomField(column.column_number) && (
                  <>
                    <TableColInBody>
                      {!isColumnNewField(column.column_number) && (
                        <SimpleSelect
                          name={`custom_field_id__${column.column_number}`}
                          value={columnMapping[column.column_number]?.custom_field_id}
                          onSelect={handleSelect}
                          options={filteredCustomFieldOptions(column.column_number)}
                          errorText={errors[`custom_field_id__${column.column_number}`]}
                          disabled={!isColumnSelected(column.column_number)}
                        />
                      )}
                      {isColumnNewField(column.column_number) && (
                        <div className="flex flex-row space-x-2">
                          <Input
                            className="w-full"
                            name={`display__${column.column_number}`}
                            placeholderText="New Custom Field"
                            value={columnMapping[column.column_number]?.display}
                            disabled={!isColumnSelected(column.column_number)}
                            errorText={errors[`display__${column.column_number}`]}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleDisplayChange(e);
                            }}
                          />
                          <div aria-hidden="true" onClick={() => handleDisplayReset(column.column_number)}>
                            <TrashIcon className="w-5 h-5 mt-2 text-gray-500" />
                          </div>
                        </div>
                      )}
                    </TableColInBody>
                    <TableColInBody>
                      <SimpleSelect
                        name={`kind__${column.column_number}`}
                        value={columnMapping[column.column_number]?.kind}
                        errorText={errors[`kind__${column.column_number}`]}
                        onSelect={handleSelect}
                        options={dataTypeSelectOptions}
                        disabled={!isColumnSelected(column.column_number) || !isColumnNewField(column.column_number)}
                      />
                    </TableColInBody>
                  </>
                )}
              </tr>
            ))}
          </TableBody>
        </Table>
      </div>
      <p className="mt-2 text-xs text-gray-500">
        *If no columns are selected, emails will still be imported from the csv.
      </p>
    </div>
  );
};
export default PreImport;
