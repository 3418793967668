import { Fragment, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import { useClickedOutside } from '@/hooks';
import { Button } from '@/ui/Button';

import { NavItem } from './NavItem';

interface Props {
  id: string;
  navMenuOpen: string;
  setNavMenuOpen: (id: string) => void;
  forceOpen?: boolean;
  Icon: React.ElementType;
  BoldIcon: React.ElementType;
  title: string;
  largeNavOpen: boolean;
  links: any[];
}

interface NavDropdownProps {
  links: any[];
  isOpen: boolean;
  setNavMenuOpen: (id: string) => void;
}

const NavDropdown = ({ links, isOpen, setNavMenuOpen }: NavDropdownProps) => {
  const navigate = useNavigate();

  return (
    <Transition
      as={Fragment}
      show={isOpen}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={cx(
          'z-50 w-40 absolute mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
          'left-10 top-10'
        )}
      >
        <div className="flex p-2 flex-col space-y-1">
          {links.map((link) => (
            <Menu.Item key={link.title}>
              <Button
                onClick={() => {
                  setNavMenuOpen('');
                  navigate(link.to);
                }}
                type="button"
                variant="flush"
                size="sm"
                className="text-start"
              >
                <span className="w-full text-start">{link.title}</span>
              </Button>
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  );
};

const NavMenu = ({ id, navMenuOpen, setNavMenuOpen, Icon, BoldIcon, title, largeNavOpen, links, forceOpen }: Props) => {
  const { pathname } = useLocation();
  const menuRef = useRef<HTMLDivElement>(null);
  const isActive = links.some((link) => pathname.includes(link.to));
  const isNavMenuOpen = navMenuOpen === id || forceOpen;

  // Close dropdown if mouse clicks outside the dropdown
  useClickedOutside(menuRef, () => {
    if (isNavMenuOpen && !largeNavOpen) {
      setNavMenuOpen('');
    }
  });

  const onClick = () => {
    if (isNavMenuOpen) {
      setNavMenuOpen('');
    } else {
      setNavMenuOpen(id);
    }
  };

  return (
    <Menu ref={menuRef} as="div" className="relative my-auto h-fit flex flex-col text-left">
      <Menu.Item>
        <button
          type="button"
          data-tip
          data-for={`nav-tooltip-${title}`}
          onClick={onClick}
          className={cx(
            'p-2 flex transition ease-in-out flex-col hover:bg-surface-100 rounded-lg duration-300',
            !largeNavOpen && 'aspect-1 flex flex-col justify-center items-center',
            !largeNavOpen && isActive && 'border border-surface-200 bg-surface-100',
            largeNavOpen ? 'w-full' : 'w-fit mx-auto'
          )}
        >
          <div className={cx('flex flex-row justify-between', largeNavOpen && 'w-full')}>
            <div className="flex flex-row w-full space-x-2">
              {isActive ? (
                <BoldIcon className="h-4 my-auto w-4 text-surface-700" />
              ) : (
                <Icon className="h-4 my-auto w-4 text-surface-700" />
              )}
              {largeNavOpen && (
                <Typography token="font-medium/text/sm" className="my-auto text-gray-700">
                  {title}
                </Typography>
              )}
            </div>
            {largeNavOpen && (
              <ChevronDownIcon
                className={cx('h-4 w-4 my-auto text-surface-700 duration-300 rotate-0', isNavMenuOpen && 'rotate-180')}
              />
            )}
          </div>
        </button>
      </Menu.Item>
      {isNavMenuOpen && largeNavOpen ? (
        <div className="w-full flex flex-col">
          {links.map((link) => (
            <NavItem title={link.title} to={link.to} key={link.title} />
          ))}
        </div>
      ) : null}
      {!largeNavOpen && <NavDropdown links={links} isOpen={Boolean(isNavMenuOpen)} setNavMenuOpen={setNavMenuOpen} />}
      {!largeNavOpen && (
        <ReactTooltip id={`nav-tooltip-${title}`} effect="solid" place="right">
          {title}
        </ReactTooltip>
      )}
    </Menu>
  );
};

export default NavMenu;
