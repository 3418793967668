import React, { memo, PropsWithChildren } from 'react';
import Tippy from '@tippyjs/react';

export const Tooltip = memo(
  ({
    center,
    right,
    children,
    placement = 'bottom',
    childrenStyle,
    className,
  }: PropsWithChildren<{
    center: any;
    right?: any;
    placement?: 'top' | 'bottom';
    childrenStyle?: React.CSSProperties;
    className?: HTMLElement['className'];
  }>) => {
    return (
      <Tippy
        animation="scale"
        duration={[100, 0]}
        placement={placement}
        content={
          <div className="bg-wb-inverse shadow-wb-md border border-wb-button flex text-[11px] items-center justify-between w-max h-fit text-wb-invert-primary z-[1999] font-medium tracking-tight px-2.5 py-0.5 rounded-[5px] border-solid">
            <div className="w-max text-center">{center}</div>
            {right && (
              <>
                <span className="text-wb-secondary ml-[5px]">•</span>
                <div className="w-max ml-[5px]">{right}</div>
              </>
            )}
          </div>
        }
      >
        <div className={className} style={childrenStyle}>
          {children}
        </div>
      </Tippy>
    );
  }
);
