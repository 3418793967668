import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';

import { UpgradeIntent } from '@/components/UpgradeIntent';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { SimpleSelect } from '../../../../../components/Form';
import Text from '../../../../../components/Text';
import { useCurrentPublication } from '../../../../../hooks';
import { useNavLinks } from '../../../../../hooks/useNavlinks';
import { NavGroup } from '../../../../../interfaces/nav_group';
import { NavLink } from '../../../../../interfaces/nav_link';
import useUpgradeForPages from '../../../../../pages/Settings/Pages/Website/Pages/hooks/useUpgradeForPages';

import NavLinkItem from './NavLinkItem';
import NewLinkPanel from './NewLinkPanel';

interface Props {
  navGroups: NavGroup[] | undefined;
}

enum RenderTypes {
  ALL = 'all',
  EXTERNAL_LINKS = 'external_links',
  PAGES = 'pages',
}

enum StatusTypes {
  ALL = 'all',
  DISABLED = 'disabled',
  LIVE = 'live',
}

const NavLinkSection = ({ navGroups }: Props) => {
  const navigate = useNavigate();
  const { isOpen, handleClose, intentAction, preselectedPlan, isPagesEnabled, handleOpen } = useUpgradeForPages();
  const { data: currentPublication } = useCurrentPublication();

  const [renderType, setRenderType] = useState<string>(RenderTypes.ALL);
  const isExternalLinks = renderType === RenderTypes.EXTERNAL_LINKS || renderType === RenderTypes.ALL;
  const isPages = renderType === RenderTypes.PAGES || renderType === RenderTypes.ALL;

  const [statusType, setStatusType] = useState<string>(StatusTypes.ALL);
  const isDisabled = statusType === StatusTypes.DISABLED || statusType === StatusTypes.ALL;
  const isLive = statusType === StatusTypes.LIVE || statusType === StatusTypes.ALL;

  const isDefaultFilters = renderType === StatusTypes.ALL && statusType === StatusTypes.ALL;

  const navLinksQuery = useNavLinks({
    search: '',
    perPage: 12,
    externalLinks: isExternalLinks,
    pages: isPages,
    disabled: isDisabled,
    live: isLive,
  });
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = navLinksQuery;

  const navLinks = data?.pages.flatMap((page) => page.nav_links) || [];
  const totalNavLinksCount = data?.pages[0]?.pagination?.total;
  const showingNavLinksCount = navLinks?.length || 0;
  const isNoResults = !isLoading && navLinks.length === 0;

  const hideFilters = isDefaultFilters && isNoResults;

  const handleNewInternalPage = () => {
    if (isPagesEnabled) {
      analytics.track('Published custom beehiiv webpage');
      navigate('/website_builder/custom_pages/new');
    } else {
      handleOpen();
    }
  };

  const handleNewExternalLink = (onClickAllowed: () => void) => {
    if (isPagesEnabled) {
      onClickAllowed();
    } else {
      handleOpen();
    }
  };

  return (
    <div>
      <UpgradeIntent
        isOpen={isOpen}
        onClose={handleClose}
        intentAction={intentAction}
        preselectedPlan={preselectedPlan}
      />
      <div className="flex justify-between lg:flex-row flex-col space-y-2 lg:space-y-0 items-end w-full">
        {!hideFilters && (
          <div className="space-x-4 flex">
            <div className="w-36">
              <SimpleSelect
                name="nav_group_id"
                labelText="Filter by type"
                value={renderType}
                onSelect={(_name: string, value: string) => setRenderType(value)}
                options={[
                  {
                    label: 'All',
                    value: RenderTypes.ALL,
                  },
                  {
                    label: 'External Pages',
                    value: RenderTypes.EXTERNAL_LINKS,
                  },
                  {
                    label: 'Pages',
                    value: RenderTypes.PAGES,
                  },
                ]}
              />
            </div>

            <div className="w-36">
              <SimpleSelect
                name="status"
                labelText="Filter by status"
                value={statusType}
                onSelect={(_name: string, value: string) => setStatusType(value)}
                options={[
                  {
                    label: 'All',
                    value: StatusTypes.ALL,
                  },
                  {
                    label: 'Disabled',
                    value: StatusTypes.DISABLED,
                  },
                  {
                    label: 'Live',
                    value: StatusTypes.LIVE,
                  },
                ]}
              />
            </div>
          </div>
        )}
        <div className="space-x-2 flex">
          {navGroups && (
            <NewLinkPanel navGroups={navGroups} refetch={refetch} onAddExternalLink={handleNewExternalLink} />
          )}{' '}
          <Button Icon={DocumentPlusIcon} onClick={handleNewInternalPage}>
            Internal Page
          </Button>
        </div>
      </div>

      {/* DON'T RENDER SECTION IF PAGES NOT ENABLED */}
      {isPagesEnabled && (
        <div className="space-y-4 pt-12 pb-12">
          {navLinks && navLinks?.length && navGroups ? (
            <div>
              <div className="grid grid-cols-3 gap-4 ">
                {currentPublication &&
                  navLinks?.map((navLink: NavLink) => {
                    return (
                      <NavLinkItem
                        key={navLink.id}
                        navLink={navLink}
                        navGroups={navGroups}
                        refetch={refetch}
                        publication={currentPublication}
                      />
                    );
                  })}
              </div>

              <div className="flex justify-between pt-4 items-center">
                <span className="text-xs font-semibold text-gray-400">{`Showing ${showingNavLinksCount} of ${totalNavLinksCount} results`}</span>

                {hasNextPage && (
                  <div className="text-center my-6">
                    <div>
                      <Button
                        variant="primary-inverse"
                        onClick={() => fetchNextPage()}
                        disabled={!hasNextPage || isFetchingNextPage}
                      >
                        {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              {hideFilters ? null : (
                <Text type="body" size="md" className="text-gray-500">
                  No results
                </Text>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NavLinkSection;
