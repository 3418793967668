import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { EmailMessage } from '../interfaces/email_message';
import api from '../services/swarm';

const useOptInEmail = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<EmailMessage, Error>(
    ['opt_in_email', currentPublicationId],
    () => api.get(`/opt_in_email`, { params: { publication_id: currentPublicationId } }).then((res) => res.data),
    {
      staleTime: Infinity,
      cacheTime: 0,
      enabled: !!currentPublicationId,
      keepPreviousData: true,
    }
  );
};

export default useOptInEmail;
