import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';
import api from '@/services/swarm';

export default function useSvixEndpoint(endpointId: string) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<WebhookEndpoint>(
    ['publications', currentPublicationId, 'webhooks', 'endpoints', endpointId],
    () =>
      api
        .get(`/webhooks/svix_endpoints/${endpointId}`, { params: { publication_id: currentPublicationId } })
        .then((res) => res.data),
    {
      staleTime: 10000,
    }
  );
}
