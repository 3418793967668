import { useRef } from 'react';
import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';
import { Placement } from 'tippy.js';

import BreakWrapper from '@/components/TiptapEditor/components/ui/BreakWrapper';
import { useTiers } from '@/hooks/useTiers';
import { useEditorContext } from '@/pages/Post/Edit/EditorContext';

import { Icon } from '../../../components/ui/Icon';

import { Styled } from './PaywallBreakView.styled';

export interface TippyProps {
  'data-placement': Placement;
  'data-reference-hidden'?: string;
  'data-escaped'?: string;
}

export const PaywallBreakView = ({ node }: NodeViewWrapperProps) => {
  const { formData } = useEditorContext();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const priceId = formData?.paywall_break_price_id;
  const { data: tiers = [] } = useTiers();
  const tier = tiers.find((t) => t.prices.some((p) => p.id === priceId));
  const price = tier?.prices.find((p) => p.id === priceId);

  return (
    <NodeViewWrapper
      data-drag-handle
      data-price-id={priceId}
      ref={wrapperRef}
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      {tier && price ? (
        <Styled.Container contentEditable={false}>
          <div className="text-center">
            <Icon name="PaywallBreak" $size="1.125rem" />
            <Styled.Title>Subscribe to {tier.name} to read the rest</Styled.Title>
            <Styled.Description>
              Become a paying subscriber of {tier.name} to get access to this post and other subscriber-only content.
            </Styled.Description>
            <Styled.Button>{price.cta || 'Upgrade'}</Styled.Button>
            <Styled.Heading>A subscription gets you:</Styled.Heading>
          </div>
          <Styled.List>
            {price.features.map((feature) => (
              <Styled.ListItem key={feature}>
                <Icon name="Check" $size="0.75rem" />
                {feature}
              </Styled.ListItem>
            ))}
          </Styled.List>
        </Styled.Container>
      ) : (
        <BreakWrapper
          icon="PaywallBreak"
          title="Paywall break"
          description="The content below this section will be hidden for non-paying subscribers."
        />
      )}
    </NodeViewWrapper>
  );
};

export default PaywallBreakView;
