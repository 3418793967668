import { PropsWithChildren, useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

import { Textarea } from '@/components/Form';

import { TooltipProps } from '../../../_types/misc';
import CustomizeMoreButton from '../../Helpers/CustomizeMoreButton';
import PropertiesGrouping from '../../Helpers/PropertiesGrouping';

export type TextareaHelperProps = PropsWithChildren<{
  label: string;
  tooltip?: TooltipProps;
  supportsMarkdown?: boolean;
  value: string;
  rows?: number;
  onSave: (value: string) => void;
  helperText?: React.ReactNode;
  nodeId?: string;
  showActionChild?: boolean;
}>;

const TextareaHelper = ({
  label,
  tooltip,
  value,
  rows = 1,
  onSave,
  supportsMarkdown,
  children,
  helperText,
  nodeId,
  showActionChild = true,
}: TextareaHelperProps) => {
  const [text, setText] = useState(value);
  const [overridesShowing, setOverridesShowing] = useState(false);

  return (
    <PropertiesGrouping
      className="!space-y-0"
      label={label}
      supportsMarkdown={supportsMarkdown}
      tooltip={tooltip}
      nodeId={nodeId}
      actionChild={
        showActionChild ? (
          <CustomizeMoreButton
            Icon={PencilSquareIcon}
            isOpen={overridesShowing}
            text="Styles"
            onClose={() => setOverridesShowing(!overridesShowing)}
          />
        ) : null
      }
    >
      <Textarea
        name={label}
        inputClassOverride="appearance-none !text-xs block w-full px-3 py-2 border hover:border-surface-500 rounded-md placeholder-gray-400 focus:outline-none focus:ring-transparent focus:border-surface-900 sm:text-sm h-full disabled:cursor-not-allowed"
        value={text}
        onChange={(e) => setText(e.target.value)}
        rows={rows}
        onBlur={() => onSave(text)}
        helperText={helperText}
      />
      {overridesShowing && <div>{children || null}</div>}
    </PropertiesGrouping>
  );
};

export default TextareaHelper;
