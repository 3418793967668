import { FC, useCallback } from 'react';
import { JSONContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import styled from 'styled-components';

import { OnMediaPayload } from '@/components/MediaLibrary/MediaLibrary.types';

import { ImageUploader } from './ImageUploader';

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
`;

export const ImageUpload: FC<NodeViewProps> = ({ getPos, editor, node }) => {
  const onUpload = useCallback(
    (payload: OnMediaPayload) => {
      const { media: asset } = payload;

      const from = getPos();
      const to = from + node.nodeSize;

      const jsonContent: JSONContent = {
        type: 'imageBlock',
        attrs: {
          id: asset.id,
          title: asset.title,
          captionUrl: asset.source_link || '',
          alt: asset.alt,
        },
        content: [
          {
            type: 'figcaption',
            ...(asset.source_display
              ? {
                  content: [
                    {
                      type: 'text',
                      text: asset.source_display || '',
                    },
                  ],
                }
              : null),
          },
        ],
      };

      editor.chain().insertContentAt({ from, to }, jsonContent).focus().run();
    },
    [editor, getPos, node]
  );

  return (
    <NodeViewWrapper>
      <Wrapper data-drag-handle {...{ inert: editor.isEditable ? undefined : '' }}>
        <ImageUploader onUpload={onUpload} />
      </Wrapper>
    </NodeViewWrapper>
  );
};

export default ImageUpload;
