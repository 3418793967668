import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { SimpleSelect, Textarea } from '@/components/Form';
import { Typography } from '@/components/Typography';
import useUpdateBoostInvite from '@/hooks/boosts/monetize/useUpdateBoostInvite';
import BoostInviteStatus from '@/interfaces/boosts/boost_invite_status';
import { Option } from '@/interfaces/general';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  boostInviteId: string;
}

const REASON_OPTIONS: Option[] = [
  { label: 'CPA too low', value: 'cpa_too_low' },
  { label: 'Publisher not a good fit', value: 'publisher_not_a_good_fit' },
  { label: 'Not interested in Boosts', value: 'not_interested_in_boosts' },
  { label: 'Other (please specify / reason required)', value: 'other' },
];

const DeclineBoostInviteModal = ({ isOpen, onClose, boostInviteId }: Props) => {
  const [presetRejectReason, setPresetRejectReason] = useState('cpa_too_low');
  const [rejectReason, setRejectReason] = useState('');
  const [hasValidationError, setHasValidationError] = useState(false);

  const { mutateAsync: updateBoostInvite, isLoading: isUpdatingBoostInvite } = useUpdateBoostInvite({});

  const usePresetReason = presetRejectReason !== 'other';

  const handleCancel = () => onClose();

  const handleConfirm = async () => {
    setHasValidationError(false);

    if (!usePresetReason && rejectReason.trim() === '') {
      setHasValidationError(true);
      return;
    }

    try {
      await updateBoostInvite({
        id: boostInviteId,
        transition_to: BoostInviteStatus.REJECTED,
        reject_reason: usePresetReason ? presetRejectReason : rejectReason,
      });
      onClose();
    } catch (error: any) {
      // do something
    }
  };

  return (
    <ActionModal
      headerText="Decline Boost Invite"
      actionText="Decline Invite"
      isOpen={isOpen}
      onClose={handleCancel}
      resourceId="confirm-automation"
      onProceed={handleConfirm}
      isWorking={isUpdatingBoostInvite}
    >
      <Typography>
        Are you sure you want to decline this Boost Invite? If so, feel free to share a reason why with the publisher.
      </Typography>
      <div className="flex flex-col gap-y-2">
        <SimpleSelect
          name="boost_invite_reason_select"
          options={REASON_OPTIONS}
          onSelect={(_name: string, value: string) => setPresetRejectReason(value)}
          value={presetRejectReason}
        />

        <Textarea
          name="boost_invite_reason_other"
          value={rejectReason}
          onChange={(e) => {
            setHasValidationError(false);
            setRejectReason(e.target.value);
          }}
          required={!usePresetReason}
          labelText="Reason for declining"
          placeholderText="I don’t think we are a good fit as partners because..."
          errorText={
            hasValidationError
              ? 'Please provide a reason for declining the invite. If "Other" is selected, a reason is required.'
              : undefined
          }
        />
      </div>
    </ActionModal>
  );
};

export default DeclineBoostInviteModal;
