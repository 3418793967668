import toast from 'react-hot-toast';

import { useCurrentPublication } from '@/hooks';
import { useUpdatePublication } from '@/hooks/usePublications';

import InputWithDescription from '../../../../ui/Settings/InputWithDescription';
import { SettingsContainer, SettingsGroup } from '../../../../ui/Settings/SettingsContainer';

const analytics = [
  {
    name: 'facebook_pixel',
    title: 'Meta Pixel ID',
    description: 'Specify a Meta pixel to track conversion from a Meta ad campaign.',
    placeholder: '1234567890',
  },
  {
    name: 'twitter_pixel',
    title: 'X (Twitter) Pixel ID',
    description: 'Specify a Twitter pixel to track conversion from a Twitter ad campaign.',
    placeholder: 'abcdef',
  },
  {
    name: 'tiktok_pixel',
    title: 'TikTok Pixel ID',
    description: 'Specify a TikTok pixel to track conversion from a TikTok ad campaign.',
    placeholder: 'ABCDEFG123456789',
  },
  {
    name: 'google_analytics_4_pixel',
    title: 'Google Analytics (GA4) Pixel ID',
    description: 'Specify a Google Analytics 4 pixel to utilize their suite of tracking tools.',
    placeholder: 'G-123456789',
  },
  {
    name: 'gtm_pixel',
    title: 'Google Tag Manager Pixel ID',
    description: 'Specify a Google Tag Manager account to manage all of your pixels in a single location.',
    placeholder: 'GTM-123456789',
  },
  {
    name: 'gsc_verification_code',
    title: 'Google Search Console Verification Code',
    description: 'Specify a Google Search Console verification content token.',
    placeholder: 'abcdef',
  },
];

const WebBuilderPixels = () => {
  const { data: currentPublication }: any = useCurrentPublication();
  const publicationMutation = useUpdatePublication();

  const onSave = async (updatedValue: any) => {
    try {
      await publicationMutation.mutateAsync(updatedValue);
      toast.success('Saved!');
    } catch (error) {
      toast.error('Could not save. Something went wrong!');
    }
  };

  return (
    <SettingsContainer>
      <SettingsGroup title="Pixels" description="Manage your tracking pixels.">
        <div className="w-full flex flex-col space-y-6">
          {currentPublication &&
            analytics.map((analytic) => {
              return (
                <InputWithDescription
                  key={analytic.name}
                  title={analytic.title}
                  description={analytic.description}
                  placeholder={analytic.placeholder}
                  value={currentPublication[analytic.name] || ''}
                  onSave={(val) => onSave({ [analytic.name]: val })}
                />
              );
            })}
        </div>
      </SettingsGroup>
    </SettingsContainer>
  );
};

export default WebBuilderPixels;
