import { ReactNode } from 'react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import { Color, getColor } from '../DesignTokens';
import IconButton from '../IconHelpers/IconButton';
import { Typography } from '../Typography';

const COLORS = {
  warning: 'bg-feedback-warning-50 text-feedback-warning-900 border border-feedback-warning-200',
  danger: 'bg-red-100 text-red-800',
  success: 'bg-green-100 text-green-800',
  information: 'bg-blue-100 text-blue-800',
};

const TEXT_COLORS = {
  warning: Color.WARNING,
  danger: Color.DANGER,
  success: Color.SUCCESS,
  information: Color.INFO,
};

const ICONS = {
  warning: <ExclamationTriangleIcon className={cx('h-5 w-5', getColor('warning/500', 'text'))} />,
  danger: <ExclamationCircleIcon className="h-5 w-5" />,
  success: <CheckCircleIcon className="h-5 w-5" />,
  information: <InformationCircleIcon className={cx('h-5 w-5', getColor('info/500', 'text'))} />,
};

export interface BannerProps {
  title: string;
  onClick?: () => void;
  onClose?: () => void;
  ctaText?: string;
  className?: string;
  bodyText?: string | ReactNode;
  ctaIcon?: ReactNode;
  isCTADisabled?: boolean;
  isScreenWide?: boolean;
  variant?: 'danger' | 'warning' | 'information' | 'success';
}

const Banner: React.FC<BannerProps> = ({
  variant,
  title,
  bodyText,
  ctaText,
  onClick,
  onClose,
  ctaIcon,
  className = '',
  isCTADisabled = false,
  isScreenWide = true,
}: BannerProps) => {
  const themeClasses = variant && COLORS[variant];
  const icon = variant && ICONS[variant];

  return (
    <div className={cx('p-3 rounded-md', themeClasses, className, isScreenWide && 'w-screen')}>
      <div className="flex justify-between gap-x-4">
        <div className="flex gap-x-2">
          <div>{icon}</div>
          <div className="flex flex-col gap-y-1">
            <Typography token="font-medium/text/sm" color={variant ? TEXT_COLORS[variant] : 'info'} colorWeight="900">
              {title}
            </Typography>
            {bodyText && (
              <Typography token="font-normal/text/xs" colorWeight="500">
                {bodyText}
              </Typography>
            )}
          </div>
        </div>

        {onClick && ctaText && (
          <button
            disabled={isCTADisabled}
            type="button"
            className="max-w-min whitespace-nowrap flex items-center gap-x-2 mr-4"
            onClick={onClick}
          >
            <Typography token="font-medium/text/sm" color={variant ? TEXT_COLORS[variant] : 'info'} colorWeight="900">
              {ctaText}
            </Typography>
            {ctaIcon}
          </button>
        )}

        {onClose ? (
          <IconButton onClick={onClose} backgroundColor="bg-transparent">
            <XMarkIcon />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};

Banner.defaultProps = {
  className: '',
  variant: 'information',
};

export default Banner;
