import { Outlet } from 'react-router-dom';
import {
  ArrowPathIcon,
  BellAlertIcon,
  ChatBubbleLeftRightIcon,
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  EnvelopeOpenIcon,
  LinkIcon,
  NewspaperIcon,
  PencilSquareIcon,
  QueueListIcon,
  ShieldCheckIcon,
} from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';

import { PageProvider } from '../_components/Context/PageContext';
import SectionEditNavigator from '../_components/Helpers/SectionEditNavigator';
import Layout from '../_components/Layout';

export interface AdvancedPageData {}

const SettingsHeader = () => {
  return (
    <div className="flex flex-col space-y-1">
      <Typography token="font-medium/text/xs" className="text-gray-900">
        Website and Page Settings
      </Typography>
      <Typography token="font-normal/text/xs" className="text-gray-600">
        Configure your websites settings in depth, become GDPR compliant, create signup flow, add social links, and
        more.{' '}
      </Typography>
    </div>
  );
};

const sidePanelTitles = [
  {
    title: 'Pixels',
    icon: <PencilSquareIcon className="w-4 h-4 text-surface-900 rounded" />,
  },
  { title: 'SEO', icon: <ComputerDesktopIcon className="w-4 h-4 text-surface-900 rounded" /> },
  { title: 'Social Links', icon: <LinkIcon className="w-4 h-4 text-surface-900 rounded" /> },
  { title: 'Email Capture', icon: <EnvelopeOpenIcon className="w-4 h-4 text-surface-900 rounded" /> },
  { title: 'Signup Flow', icon: <QueueListIcon className="w-4 h-4 text-surface-900 rounded" /> },
  { title: 'Comments', icon: <ChatBubbleLeftRightIcon className="w-4 h-4 text-surface-900 rounded" /> },
  { title: 'Notifications', icon: <BellAlertIcon className="w-4 h-4 text-surface-900 rounded" /> },
  { title: 'Mobile App', icon: <DevicePhoneMobileIcon className="w-4 h-4 text-surface-900 rounded" /> },
  { title: 'Security', icon: <ShieldCheckIcon className="w-4 h-4 text-surface-900 rounded" /> },
  { title: 'Static Pages', icon: <NewspaperIcon className="w-4 h-4 text-surface-900 rounded" /> },
  { title: 'Theme Reset', icon: <ArrowPathIcon className="w-4 h-4 text-surface-900 rounded" /> },
];

const LayoutPage = () => {
  return (
    <PageProvider<AdvancedPageData> pageData={{}}>
      <Layout
        removePadding
        showPageAndScreenActions={false}
        showPublishAndPreviewActions={false}
        sidePanelChildren={
          <div className="flex flex-col">
            <div className="pt-6 sticky top-0 bg-white">
              <SettingsHeader />
              <hr className="w-full mt-6" />
            </div>
            <div className="flex flex-col space-y-6 my-6">
              {sidePanelTitles.map((panel) => {
                const path: any = panel.title.toLowerCase().replace(' ', '_');
                return <SectionEditNavigator key={panel.title} title={panel.title} to={path} icon={panel.icon} />;
              })}
            </div>
          </div>
        }
      >
        <Outlet />
      </Layout>
    </PageProvider>
  );
};

export default LayoutPage;
