import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { WebhookMessageAttempt } from '@/interfaces/webhooks/message_attempt';
import api from '@/services/swarm';

export default function useSvixMessageAttempt(endpointId: string, messageAttemptId: string, messageId: string) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<WebhookMessageAttempt>(
    ['publications', currentPublicationId, 'webhooks', 'message_attempts', messageAttemptId],
    () =>
      api
        .get(`/webhooks/endpoints/${endpointId}/svix_messages/${messageAttemptId}`, {
          params: { publication_id: currentPublicationId, message_attempt_id: messageId },
        })
        .then((res) => res.data),
    {
      staleTime: 10000,
    }
  );
}
