import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { PostPoll } from '../../interfaces/post_poll';
import api from '../../services/swarm';

export default function usePostPoll({ id }: { id: string }) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<PostPoll>(
    ['post_polls', id],
    () =>
      api
        .get(`/post_polls/${id}`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
