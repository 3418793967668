import { useMemo } from 'react';

import { SimpleSelect } from '@/components/Form';
import { useSegments } from '@/hooks';
import {
  AutomationCondition,
  AutomationConditionOperator,
  AutomationConditions,
} from '@/interfaces/automations/automation_condition';
import { LogicalOperators } from '@/interfaces/condition';

import SegmentIdSelect from '../../components/SegmentIdSelect';
import SegmentWarning from '../../components/SegmentWarning';

const segmentActionOptions = [
  { label: 'Entered a segment', value: AutomationConditionOperator.EXISTS },
  { label: 'Exited a segment', value: AutomationConditionOperator.DOES_NOT_EXIST },
];

interface Props {
  mainConditions: AutomationConditions;
  onChange: (mainConditions: AutomationConditions) => void;
}

export const SegmentActionTrigger = ({ mainConditions, onChange }: Props) => {
  const segmentsQuery = useSegments({ shouldFetchAll: true, order: 'segment.name', dir: 'asc' });
  const { data: segmentsData } = segmentsQuery;

  const condition = mainConditions.conditions?.[0];
  const segmentAction = condition?.operator || '';
  const segmentId = condition?.value || '';
  const segment = segmentsData?.pages.flatMap((page) => page.segments).find((s) => s.id === segmentId);

  const segmentOptions = useMemo(() => {
    const segments = segmentsData?.pages.flatMap((page) => page.segments) || [];
    if (!segments) return [];

    return segments
      .filter((s) => s.segment_type !== 'manual')
      .map((s: { id: string; name: string }) => ({
        label: s.name,
        value: s.id,
      }));
  }, [segmentsData]);

  const handleSegmentActionChange = (_name: string, selectedValue: string) => {
    onChange({
      logical_operator: LogicalOperators.AND,
      conditions: [{ ...condition, operator: selectedValue } as AutomationCondition],
    });
  };

  const handleSegmentIdChange = (_name: string, selectedValue: string) => {
    onChange({
      logical_operator: LogicalOperators.AND,
      conditions: [{ ...condition, value: selectedValue } as AutomationCondition],
    });
  };

  return (
    <div className="space-y-2">
      <SimpleSelect
        name="segment_action"
        value={segmentAction}
        onSelect={handleSegmentActionChange}
        options={segmentActionOptions}
        placeholderText="Choose an action"
      />

      <SegmentIdSelect segmentId={segmentId} options={segmentOptions} onChange={handleSegmentIdChange} />

      <SegmentWarning segment={segment} />
    </div>
  );
};
