/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';

import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { FieldSet, Input } from '../../../components/ui/Input';
import { Panel } from '../../../components/ui/Panel';
import { Textarea } from '../../../components/ui/Textarea';
import { Tooltip } from '../../../components/ui/Tooltip';
import { IFileAttrs } from '../types';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const FileAttachmentDataPanel = ({
  parentRef,
  updateAttributes,
  attrs,
}: {
  parentRef: React.RefObject<HTMLElement>;
  updateAttributes: (attrs: Partial<IFileAttrs>) => void;
  attrs: IFileAttrs;
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const hasChanges = attrs.title || attrs.description;

  const { title, description } = attrs;

  useEffect(() => {
    if (isPanelOpen) {
      const outsideClickHandler = (e: Event) => {
        if (
          !buttonRef.current?.contains(e.target as HTMLElement) &&
          !panelRef.current?.contains(e.target as HTMLElement)
        ) {
          setIsPanelOpen(false);
        }
      };

      document.addEventListener('click', outsideClickHandler);

      return () => {
        document.removeEventListener('click', outsideClickHandler);
      };
    }

    return () => {};
  }, [isPanelOpen]);

  const handleTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
    updateAttributes({ title: e.currentTarget.value });
  };

  const handleDescriptionChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    updateAttributes({ description: e.currentTarget.value });
  };

  return (
    <Tippy
      placement="bottom-start"
      reference={parentRef.current}
      offset={[0, 4]}
      render={(tippyRenderAttrs) =>
        isPanelOpen && (
          <Panel ref={panelRef} tabIndex={-1} {...tippyRenderAttrs}>
            <FieldSet>
              <label htmlFor="file-title" className="text-xs font-medium leading-5">
                Title
                <Input id="file-title" value={title} onChange={handleTitleChange} placeholder="Title …" />
              </label>
              <label htmlFor="file-description" className="text-xs font-medium leading-5">
                Description
                <Textarea
                  value={description || ''}
                  id="file-description"
                  placeholder="Description …"
                  onChange={handleDescriptionChange}
                />
              </label>
            </FieldSet>
          </Panel>
        )
      }
      interactive
      visible={isPanelOpen}
      onHide={() => setIsPanelOpen(false)}
    >
      <div>
        <Tooltip title="Edit file attachment information">
          <Button
            ref={buttonRef}
            {...buttonProps}
            $active={hasChanges || isPanelOpen}
            $muted={isPanelOpen && !hasChanges}
            $leftSlot={<Icon name="Edit" />}
            onClick={() => setIsPanelOpen(!isPanelOpen)}
          />
        </Tooltip>
      </div>
    </Tippy>
  );
};

export default FileAttachmentDataPanel;
