import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import analytics from '@/utils/analytics';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { useCurrentUser } from '../context/current-user-context';
import api from '../services/swarm';

interface ApiResponse {
  id: string;
}

const useCreateDraft = () => {
  const navigate = useNavigate();
  const [publicationId] = useCurrentPublicationState();
  const { currentUser } = useCurrentUser();

  return useMutation<ApiResponse, unknown, { postTemplateId?: string; tiptap_state?: any }>(
    ({ postTemplateId, tiptap_state }) =>
      api
        .post('/posts', {
          publication_id: publicationId,
          ...(postTemplateId && { post_template_id: postTemplateId }),
          ...(tiptap_state && { tiptap_state: JSON.stringify(tiptap_state) }),
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        navigate(`/posts/${data.id}/edit`);

        analytics.track('Started a Post');

        analytics.track('Created Post', {
          user_id: currentUser?.id,
          email: currentUser?.email,
        });
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useCreateDraft;
