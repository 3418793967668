import { useState } from 'react';
import { UseMutationResult } from 'react-query';
import moment from 'moment-mini';

import { Input, Textarea } from '@/components/Form';
import CurrencyInput from '@/components/Form/CurrencyInput';
import { AdNetworkPaymentRequest } from '@/interfaces/ad_network/internal/payment_request';
import { Button } from '@/ui/Button';

interface FormProps {
  onSubmitMutation: UseMutationResult<any, any, any, any>;
  paymentRequest?: AdNetworkPaymentRequest;
}

const Form = ({ onSubmitMutation, paymentRequest }: FormProps) => {
  const { mutateAsync, isLoading } = onSubmitMutation;
  const [advertiserMessage, setAdvertiserMessage] = useState(paymentRequest?.advertiser_message || '');
  const [amountCents, setAmountCents] = useState(paymentRequest?.amount_cents || 0);
  const [sendAt, setSendAt] = useState(paymentRequest?.send_at ? paymentRequest.send_at : '');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    formData.set('payment_request[advertiser_message]', String(advertiserMessage));
    formData.set('payment_request[amount_cents]', String(amountCents));
    formData.set('payment_request[send_at]', String(sendAt));

    mutateAsync(formData);
  };

  const handleSetSendAtDate = (date: string) => {
    setSendAt(moment.utc(date).startOf('day').toISOString());
  };

  return (
    <div className="p-4">
      <div className="max-w-xl mx-auto w-full space-y-4">
        <form className="divide-y" onSubmit={onSubmit}>
          <div className="space-y-6 pb-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
              <p className="text-sm text-gray-500">
                Provide details about the payment request. This will be sent to the advertiser on the selected date.
              </p>
            </div>
            <CurrencyInput
              value={amountCents}
              onChange={(val) => setAmountCents(val || 0)}
              name="payment_request[amount_cents]"
              labelText="Payment Amount"
              helperText="The amount the advertiser must pay in this payment."
              required
            />
            <Input
              value={moment.utc(sendAt).format('YYYY-MM-DD')}
              onChange={(e) => handleSetSendAtDate(e.target.value)}
              name="payment_request[send_at]"
              labelText="Send On"
              type="date"
              required
              helperText="The date the payment request will be sent to the advertiser."
            />
            <Textarea
              value={advertiserMessage}
              onChange={(e) => setAdvertiserMessage(e.target.value)}
              name="payment_request[advertiser_message]"
              labelText="Message to Advertiser"
              rows={3}
              helperText="An optional message to the advertiser that will be included in the payment request email."
            />
          </div>
          <Button type="submit" loading={isLoading}>
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Form;
