import React, { useState } from 'react';

import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import Switch from '../../../../../../components/Form/Switch';
import { useControlledInput } from '../../../../../../hooks';
import { ExternalEmbed } from '../../../../../../interfaces/external_embed';
import Section from '../Section';

import ColorInput from './ColorInput';
import Input from './Input';

interface ExternalEmbedFormProps {
  title: string;
  externalEmbed: Partial<ExternalEmbed>;
  useMutation: any;
  onSuccess: () => void;
}

const ExternalEmbedForm: React.FunctionComponent<ExternalEmbedFormProps> = ({
  title,
  externalEmbed,
  useMutation,
  onSuccess,
}) => {
  const name = useControlledInput({ initialState: externalEmbed?.name || '' });

  const header = useControlledInput({ initialState: externalEmbed?.header });
  const description = useControlledInput({ initialState: externalEmbed?.description });
  const inputPlaceholder = useControlledInput({
    initialState: externalEmbed?.input_placeholder || 'Enter your email',
  });
  const buttonText = useControlledInput({ initialState: externalEmbed?.button_text });

  const buttonColor = useControlledInput({ initialState: externalEmbed?.config?.button_color });
  const backgroundColor = useControlledInput({ initialState: externalEmbed?.config?.background_color });
  const textColor = useControlledInput({ initialState: externalEmbed?.config?.text_color });
  const buttonTextColor = useControlledInput({ initialState: externalEmbed?.config?.button_text_color });

  const headerFont = useControlledInput({ initialState: externalEmbed?.config?.header_font });
  const bodyFont = useControlledInput({ initialState: externalEmbed?.config?.body_font });
  const buttonFont = useControlledInput({ initialState: externalEmbed?.config?.button_font });

  const successMessageText = useControlledInput({ initialState: externalEmbed?.success_message_text || '' });
  const successRedirectUrl = useControlledInput({ initialState: externalEmbed?.success_redirect_url || '' });
  const removeEmailFromRedirectUrl = useControlledInput({
    initialState: externalEmbed?.remove_email_from_redirect_url || false,
  });

  const [loading, setLoading] = useState(false);

  const { mutateAsync } = useMutation({ onSuccess });

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const embedParams = {
      id: externalEmbed?.id,
      name: name.state,
      header: header.state,
      description: description.state,
      input_placeholder: inputPlaceholder.state,
      button_text: buttonText.state,
      config: {
        button_color: buttonColor.state,
        background_color: backgroundColor.state,
        text_color: textColor.state,
        button_text_color: buttonTextColor.state,
        header_font: headerFont.state,
        body_font: bodyFont.state,
        button_font: buttonFont.state,
      },
      success_message_text: successMessageText.state,
      success_redirect_url: successRedirectUrl.state,
      remove_email_from_redirect_url: removeEmailFromRedirectUrl.state,
    };

    analytics.track('Created a subscribe form');
    mutateAsync(embedParams).finally(() => setLoading(false));
  };

  return externalEmbed ? (
    <section>
      <form onSubmit={onSubmit}>
        <div className="divide-y divide-gray-200">
          <div>
            <header className="flex justify-between items-center">
              <div>
                <h2 className="text-lg leading-6 font-medium text-gray-900 ml-0">{title}</h2>
              </div>
            </header>
            <Input name="name" labelText="Name" control={name} required />

            <Section title="Content">
              <Input name="header" labelText="Header" control={header} required />
              <Input name="description" labelText="Description" control={description} required />
              <Input name="placeholder" labelText="Input Placeholder" control={inputPlaceholder} required />
              <Input name="button_text" labelText="Button Text" control={buttonText} required />
            </Section>

            <Section title="Color Palette">
              <ColorInput
                label="Button Color"
                description="Color used for the submit button and input border color"
                color={buttonColor}
              />
              <ColorInput
                label="Background Color"
                description="Color used for the card background"
                color={backgroundColor}
              />
              <ColorInput label="Text Color" description="Color used for text within the card" color={textColor} />
              <ColorInput
                label="Button Text Color"
                description="Color used for text within the button"
                color={buttonTextColor}
              />
            </Section>

            <Section
              title="Typography"
              description={
                <>
                  Fonts are case sensitive and should be chosen from{' '}
                  <a href="https://fonts.google.com" target="_blank" className="text-indigo-400" rel="noreferrer">
                    Google Fonts
                  </a>
                  .
                </>
              }
            >
              <Input
                name="config[header_font]"
                labelText="Header Font"
                className="col-span-12 sm:col-span-6 w-1/2"
                control={headerFont}
              />
              <Input
                name="config[body_font]"
                labelText="Body Font"
                className="col-span-12 sm:col-span-6 w-1/2"
                control={bodyFont}
              />
              <Input
                name="config[button_font]"
                labelText="Button Font"
                className="col-span-12 sm:col-span-6 w-1/2"
                control={buttonFont}
              />
            </Section>

            <Section title="After Submission" description="When a visitor subscribes via the form.">
              <Input name="success_message_text" labelText="Show a success message" control={successMessageText} />
              <Input
                name="success_redirect_url"
                labelText="Or, redirect to an external page"
                control={successRedirectUrl}
              />
              <Switch
                name="remove_email_from_redirect_url"
                checked={removeEmailFromRedirectUrl.state}
                onChange={(_, checked) => removeEmailFromRedirectUrl.setState(checked)}
                className="col-span-12 sm:col-span-6 mt-6 w-4/5"
                labelText="Remove emails from the redirect URL"
                helperText="Turn this on to remove emails from the redirect URL and substitute them with the subscription ID."
                disabled={!successRedirectUrl.state}
              />
            </Section>
          </div>
          <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
            <Button type="submit" variant="primary" loading={loading}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </section>
  ) : (
    <Section title="Loading…" />
  );
};

export default ExternalEmbedForm;
