import { useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

import { BillingDetails } from '@/interfaces/billing';
import { Button } from '@/ui/Button';
import { PLAN } from '@/utils/plans';

import Body from '../components/PlanCard/Body';
import Header from '../components/PlanCard/Header';

interface Props {
  billingDetails: BillingDetails | undefined;
  onCancel: () => void;
  onDowngrade: () => void;
  onPreviousStep: () => void;
  isLoading: boolean;
  isDowngrading: boolean;
}

const Confirmation = (props: Props) => {
  const { billingDetails, onCancel, onDowngrade, onPreviousStep, isLoading, isDowngrading } = props;
  const [acknowledgeChanges, setAcknowledgeChanges] = useState(false);

  const currentPlan = {
    name: billingDetails?.current_plan.name || '',
    max_subscriptions: billingDetails?.organization_max_subscriptions || 0,
    max_publications: billingDetails?.current_plan.name === PLAN.MAX ? 10 : 3,
  };

  const launchPlan = { name: 'Launch', max_subscriptions: 2500, max_publications: 3 };

  const onToggleAcknowledgeChanges = () => {
    setAcknowledgeChanges(!acknowledgeChanges);
  };

  return (
    <div className="flex flex-col gap-10">
      <div>
        <h1 className="text-xl font-semibold text-gray-900">Confirm your changes</h1>
      </div>

      <div className="lg:flex items-start justify-between space-y-8 lg:space-y-0">
        <div className="flex-initial lg:w-full flex flex-col gap-6">
          {billingDetails && (
            <Header billingDetails={billingDetails} currentPlan={currentPlan} newPlan={launchPlan} renderTopRowArrow />
          )}
          {billingDetails && (
            <Body
              billingDetails={billingDetails}
              currentPlan={currentPlan}
              onToggleAcknowledgeChanges={onToggleAcknowledgeChanges}
              confirmationStep
            />
          )}
        </div>
      </div>

      <div className="pt-16 flex justify-between">
        <div>
          <Button variant="flush" className="flex flex-row gap-2" onClick={onPreviousStep}>
            <ArrowLeftIcon className="h-4 w-4" /> Back
          </Button>
        </div>

        <div className="flex flex-col-reverse items-end sm:space-x-2 sm:flex-none sm:flex-row">
          <div>
            <Button variant="primary-inverse" onClick={onCancel}>
              Nevermind, keep my plan
            </Button>
          </div>
          <div className="mb-2 sm:mb-0">
            <Button
              variant="danger"
              loading={isLoading}
              disabled={!acknowledgeChanges || isDowngrading}
              onClick={onDowngrade}
            >
              Cancel my plan
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
