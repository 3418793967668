import { Fragment, useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Menu, Transition } from '@headlessui/react';
import { ChartBarSquareIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import {
  CategoryScale,
  Chart as ChartJS,
  ChartArea,
  ChartData,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import cx from 'classnames';
import merge from 'lodash.merge';

import { Switch } from '@/components/Form';

ChartJS.register(LineController, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

function createGradient(
  ctx: CanvasRenderingContext2D,
  area: ChartArea,
  colors: { start: string; mid: string; end: string }
) {
  const { start: colorStart, mid: colorMid, end: colorEnd } = colors;

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.8, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

interface LineChartProps {
  data: ChartData<'line'>;
  options?: any;
  missingDataText?: string;
  variant?: 'primary' | 'secondary';
}

interface ContainerProps extends LineChartProps {
  height?: number;
  defaultDisplayXLabels?: boolean;
  defaultDisplayYLabels?: boolean;
  contained?: boolean;
}

const LineChart = ({ data, options, missingDataText, variant = 'primary' }: LineChartProps) => {
  const hasData = !(data.datasets[0].data.length < 1);

  const ref = useRef<ChartJS<'line'> | null>(null);
  const dataWithDefaultStyles = {
    ...data,
    datasets: data.datasets.map((dataset) => ({ ...dataset, backgroundColor: 'transparent' })),
  };

  const [chartData, setChartData] = useState<ChartData<'line'>>(dataWithDefaultStyles);

  const BORDER_COLORS = {
    primary: 'rgba(236, 72, 153, 1)',
    secondary: 'rgba(59, 130, 246, 1)',
  };

  const POINT_BACKGROUND_COLORS = {
    primary: 'rgba(236, 72, 153, 0.7)',
    secondary: 'rgba(59, 130, 246, 0.7)',
  };

  const GRADIENT_COLORS = {
    primary: {
      start: 'rgba(236, 72, 153, 0.01)',
      mid: 'rgba(236, 72, 153, 0.5)',
      end: 'rgba(236, 72, 153, 0.8)',
    },
    secondary: {
      start: 'rgba(59, 130, 246, 0.01)',
      mid: 'rgba(59, 130, 246, 0.5)',
      end: 'rgba(59, 130, 246, 0.8)',
    },
  };

  useEffect(() => {
    const chart = ref.current;

    if (!chart) return;

    setChartData({
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: createGradient(chart.ctx, chart.chartArea, GRADIENT_COLORS[variant]),
        borderColor: BORDER_COLORS[variant],
        pointBackgroundColor: POINT_BACKGROUND_COLORS[variant],
        borderWidth: 2,
        pointBorderWidth: 0,
        pointRadius: 3,
        lineTension: 0.3,
        fill: 'start',
      })),
    });
  }, [data]);

  return (
    <>
      {hasData ? (
        <Chart type="line" ref={ref} data={chartData} options={options} />
      ) : (
        <div className="flex items-center justify-center h-full py-8">
          <div className="flex flex-col items-center justify-center space-y-2">
            <div className="rounded-full bg-gray-200 w-8 h-8 flex items-center justify-center">
              <ChartBarSquareIcon className="block w-5 h-5 text-gray-400" />
            </div>
            <p className=" text-gray-600">{missingDataText}</p>
          </div>
        </div>
      )}
    </>
  );
};

const LineChartContainer = ({
  data,
  height,
  options,
  missingDataText = 'No data',
  variant = 'primary',
  defaultDisplayXLabels = true,
  defaultDisplayYLabels = true,
  contained = true,
}: ContainerProps) => {
  const [displayXLabels, setDisplayXLabels] = useState(defaultDisplayXLabels);
  const [displayYLabels, setDisplayYLabels] = useState(defaultDisplayYLabels);

  const defaultOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: displayXLabels,
        grid: {
          color: 'rgba(0, 0, 0, 0.01)',
        },
      },
      y: {
        display: displayYLabels,
        min: 0,
        grid: {
          color: 'rgba(0, 0, 0, 0.01)',
        },
      },
    },
  };

  const mergedOptions = merge(defaultOptions, options);

  const containedClass = cx('relative py-4', contained && 'px-4 rounded border');
  const menuClass = cx('absolute', contained ? 'right-4' : 'right-0');

  return (
    <div style={{ height }} className={containedClass}>
      <div className={menuClass}>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button>
              <EllipsisVerticalIcon className="w-4 h-4 text-gray-500" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as="div"
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              as="div"
            >
              <div className="py-1">
                <Menu.Item as="div" className="flex justify-between">
                  <p className="text-gray-700 block pl-4 py-2 text-xs">Display X Labels</p>
                  <Switch
                    checked={displayXLabels}
                    onChange={() => setDisplayXLabels(!displayXLabels)}
                    name="displayXLabels"
                    variant="secondary"
                    className="pr-4"
                  />
                </Menu.Item>
                <Menu.Item as="div" className="flex justify-between">
                  <p className="text-gray-700 block pl-4 py-2 text-xs">Display Y Labels</p>
                  <Switch
                    checked={displayYLabels}
                    onChange={() => setDisplayYLabels(!displayYLabels)}
                    name="displayYLabels"
                    variant="secondary"
                    className="pr-4"
                  />
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <LineChart data={data} options={mergedOptions} missingDataText={missingDataText} variant={variant} />
    </div>
  );
};

export default LineChartContainer;
