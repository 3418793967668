import { PropsWithChildren } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Typography, TypographyStack } from '@/components/Typography';
import { Button } from '@/ui/Button';

import BlackAndWhiteLogo from './BlackAndWhiteLogo';
import ProgressStepper from './ProgressStepper';

type Props = PropsWithChildren<{
  totalSteps?: 2 | 3 | 4;
  title?: string | JSX.Element;
  superTitle?: string;
  subtitle?: string;
  onBack?: () => void;
  currentStep?: number;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  onSkip?: () => void;
  isSubmitDisabled?: boolean;
  isProcessing?: boolean;
  buttonsClassName?: string;
  className?: string;
  centered?: boolean;
}>;

const OnboardingStepForm = ({
  title,
  subtitle,
  superTitle,
  onBack,
  onSubmit,
  onSkip,
  currentStep = 1,
  children,
  totalSteps,
  isSubmitDisabled,
  isProcessing,
  buttonsClassName,
  className = 'max-w-2xl',
  centered = false,
}: Props) => {
  return (
    <div className={cx('flex flex-col space-y-6 w-full gap-10', className)}>
      <div>
        <div className="flex flex-col items-center">
          <BlackAndWhiteLogo size="md" />
        </div>
        <div className={cx('flex flex-col pt-6', centered ? 'items-center' : 'items-start')}>
          {totalSteps && <ProgressStepper totalSteps={totalSteps} currentStep={currentStep} />}

          <div className="text-center">
            <TypographyStack gap="1">
              {superTitle && (
                <Typography
                  weight="normal"
                  size="base"
                  as="p"
                  colorWeight="700"
                  className={cx(centered ? 'text-center' : 'text-left')}
                >
                  {superTitle}
                </Typography>
              )}
              {title && (
                <Typography
                  colorWeight="700"
                  weight="bold"
                  size="2xl"
                  as="h4"
                  className={cx(centered ? 'text-center' : 'text-left')}
                >
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography
                  weight="normal"
                  size="base"
                  as="p"
                  colorWeight="700"
                  className={cx(centered ? 'text-center' : 'text-left')}
                >
                  {subtitle}
                </Typography>
              )}
            </TypographyStack>
          </div>
        </div>
      </div>

      <form className="space-y-8 flex flex-col w-full h-full justify-between" onSubmit={onSubmit}>
        {children}

        <div className={cx('flex w-full justify-between items-center pt-10 pb-8', buttonsClassName)}>
          {onBack ? (
            <Button type="button" onClick={onBack} variant="flush" size="none" Icon={ArrowLeftIcon}>
              Back
            </Button>
          ) : (
            <div />
          )}

          <div className="flex flex-row gap-4">
            {onSkip && (
              <Button className="rounded" type="button" variant="primary-inverse" onClick={onSkip}>
                Skip
              </Button>
            )}
            <Button type="submit" disabled={isSubmitDisabled} loading={isProcessing}>
              Continue
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OnboardingStepForm;
