import React from 'react';
import { SparklesIcon } from '@heroicons/react/20/solid';

import { useTrialStatus } from '@/hooks/usePublicationDashboard';
import { Button } from '@/ui/Button';

interface UpgradeButtonProps {
  plan: string;
  onClick: () => void;
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({ onClick, plan }) => {
  const trialStatusQuery = useTrialStatus();
  const { data: trialStatus } = trialStatusQuery || {};
  const trialEligible = trialStatus?.trial_eligible;

  return (
    <Button Icon={SparklesIcon} onClick={onClick}>
      {trialEligible ? (
        <span>
          Start a <span className="capitalize">{plan}</span> trial
        </span>
      ) : (
        <span>
          Upgrade to <span className="capitalize">{plan}</span>
        </span>
      )}
    </Button>
  );
};

export default UpgradeButton;
