import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

type SubdomainPayload = {
  subdomain?: string;
  email_username?: string;
  custom_email_domain_id?: string;
};

const useUpdateSubdomain = () => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (resource_locator: SubdomainPayload) =>
      api.patch(`/publications/${currentPublicationId}/subdomain`, {
        publication_id: currentPublicationId,
        resource_locator,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => queryClient.invalidateQueries(['publications', currentPublicationId]),
    }
  );
};

export default useUpdateSubdomain;
