import { useCallback } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import styled from 'styled-components';

import { colors } from '../../../../../../lib/colors';
import { useThemeData } from '../../../../../../lib/hooks/useThemeData';
import { Button } from '../../../../../ui/Button';
import { Styled as StyledButton } from '../../../../../ui/Button/styled';
import { PanelHeadline, PanelSection } from '../../../../../ui/Panel';
import { convertToSection } from '../../../../utils';

const Styled = {
  ButtonGroup: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    & > ${StyledButton.Button} {
      color: ${colors.white[5]}; // TODO: Revise
      flex: 0 0 calc(33.333333% - 0.25rem);
      justify-content: center;
      text-align: center;
    }
  `,
};

export const BorderStyle = ({
  editor,
  currentNode,
  currentNodePos,
}: {
  editor: Editor;
  currentNode: Node | null;
  currentNodePos: number;
}) => {
  const borderStyles = useThemeData('border-styles');

  const setBorderStyle = useCallback(
    (style) => {
      convertToSection(editor, currentNode, currentNodePos);

      editor.chain().setSectionBorderStyle(style).run();
    },
    [editor, currentNode, currentNodePos]
  );

  return (
    <PanelSection>
      <PanelHeadline>Style</PanelHeadline>
      <Styled.ButtonGroup>
        {borderStyles.map((borderStyle) => {
          const isActive =
            currentNode?.attrs.borderStyle === borderStyle.key ||
            (!currentNode?.attrs.borderStyle && borderStyle.key === 'solid');

          return (
            <Button
              key={borderStyle.key}
              onClick={() => setBorderStyle(borderStyle.key)}
              $active={isActive}
              $variant="quaternary"
              $size="small"
              $muted={borderStyle.key === 'solid'}
            >
              {borderStyle.label}
            </Button>
          );
        })}
      </Styled.ButtonGroup>
    </PanelSection>
  );
};

export default BorderStyle;
