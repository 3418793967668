import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { ButtonView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    button: {
      setButton: (title?: string, url?: string) => ReturnType;
    };
  }
}

export const Button = Node.create({
  name: 'button',

  group: 'block',

  content: 'inline*',

  marks: '',

  draggable: true,

  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      size: {
        default: 'normal',
        parseHTML: (element) => element.getAttribute('data-size'),
        renderHTML: (attributes) => ({
          'data-size': attributes.size,
        }),
      },
      alignment: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-alignment'),
        renderHTML: (attributes) => ({
          'data-alignment': attributes.alignment,
        }),
      },
      href: {
        default: null,
        parseHTML: (element) => element.getAttribute('href'),
        renderHTML: (attributes) => ({
          href: attributes.href,
        }),
      },
      target: {
        default: null,
        parseHTML: (element) => element.getAttribute('target'),
        renderHTML: (attributes) => ({
          target: attributes.target,
        }),
      },
      isCustom: {
        default: undefined,
      },
      customBackgroundColor: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-custom-background-color'),
        renderHTML: (attributes) => ({
          'data-custom-background-color': attributes.customColor,
        }),
      },
      customTextColor: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-custom-text-color'),
        renderHTML: (attributes) => ({
          'data-custom-text-color': attributes.customColor,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `a[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', this.options.HTMLAttributes, ['a', mergeAttributes(HTMLAttributes, { 'data-type': this.name }), 0]];
  },

  addKeyboardShortcuts() {
    return {
      // On Enter at the end of line, create new paragraph and focus
      Enter: ({ editor }) => {
        const {
          state: {
            selection: { $from, empty },
          },
        } = editor;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

        if (!isAtEnd) {
          return false;
        }

        const pos = editor.state.selection.$from.end() + (empty ? 1 : 0);

        return editor.chain().focus(pos).insertContentAt(pos, { type: 'paragraph' }).run();
      },
    };
  },

  addCommands() {
    return {
      setButton:
        (title = '', url = '', target = null) =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: { href: url, target },
              ...(title.length ? { content: [{ type: 'text', text: title.length ? title : '' }] } : {}),
            })
            .run(),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ButtonView);
  },
});

export default Button;
