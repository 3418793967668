import { useMutation } from 'react-query';
import { JSONContent } from '@tiptap/core';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface UpdatePostContentMutationParams {
  postId: string;
  onSuccess?: () => void;
  onError?: (err: any) => void;
}

export const useUpdatePostContent = ({ postId, onSuccess, onError }: UpdatePostContentMutationParams) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (json: JSONContent) =>
      api.patch(`/posts/${postId}/content`, {
        publication_id: currentPublicationId,
        post: {
          tiptap_state: json,
        },
      }),
    {
      onError,
      onSuccess,
    }
  );
};

export default useUpdatePostContent;
