import { ElementType, ForwardedRef, forwardRef, HTMLProps, ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { ButtonGroupContext } from './ButtonGroup/index';

type Variants =
  | 'primary'
  | 'primary-inverse'
  | 'secondary'
  | 'tertiary'
  | 'flush'
  | 'danger'
  | 'success'
  | 'info'
  | 'warning';

export interface BaseButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'size'> {
  variant?: Variants;
  block?: boolean;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  Icon?: ElementType;
  disableWith?: ReactNode;
  iconRight?: boolean;
  confirm?: string;
  size?: 'xxs' | 'xs' | 'sm' | 'base' | 'lg' | 'xl' | 'none';
  tooltip?: string;
  align?: string;
  spacing?: string;
  shade?: 'default' | 'light' | 'dark';
}

type ButtonPropsWithOnClick = BaseButtonProps & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  to?: never;
};

type ButtonPropsWithSubmit = BaseButtonProps & {
  onClick?: never;
  to?: never;
  type: 'submit' | 'button';
};

type ButtonPropsWithTo = BaseButtonProps & {
  onClick?: never;
  to: string;
};

export type ButtonProps = ButtonPropsWithOnClick | ButtonPropsWithTo | ButtonPropsWithSubmit;

const BASE_BUTTON_CLASS_NAMES = `border font-medium focus:outline-none focus:border-transparent inline-flex items-center disabled:cursor-not-allowed whitespace-nowrap transition-colors duration-200`;

// Base classNames for each button variant
const BUTTON_CLASS_NAMES = {
  primary: `focus:ring-1 bg-action-primary-900 text-action-primary-50 border-action-primary-700 shadow-sm hover:bg-neutrals-100 focus:ring-action-primary-500 focus:bg-action-prima ry-900 disabled:bg-action-primary-300 disabled:border-action-primary-300 disabled:text-action-primary-500`,
  'primary-inverse': `focus:ring-1 bg-neutrals-200 text-surface-900 border-action-primary-inverse-500 shadow-sm hover:bg-action-primary-inverse-700 focus:ring-action-primary-inverse-300 focus:bg-neutrals-200 disabled:bg-surface-50 disabled:border-action-primary-inverse-500 disabled:text-surface-400`,
  secondary: `focus:ring-1 bg-action-secondary-600 text-surface-50 border-action-secondary-600 shadow-sm hover:bg-action-secondary-500 hover:border-action-secondary-500 focus:ring-action-secondary-900 focus:bg-action-secondary-600 disabled:bg-action-secondary-50 disabled:border-action-secondary-200 disabled:text-action-secondary-400`,
  tertiary: `focus:ring-1 bg-action-tertiary-600 text-surface-50 border-action-tertiary-600 shadow-sm hover:bg-action-tertiary-500 hover:border-action-tertiary-500 focus:ring-action-tertiary-900 focus:bg-action-tertiary-600 disabled:bg-action-tertiary-50 disabled:border-action-tertiary-200 disabled:text-action-tertiary-400`,
  flush: `bg-transparent hover:bg-gray-50 text-gray-800 hover:text-gray-900 disabled:text-gray-500 disabled:bg-transparent border-transparent`,
  danger: ``,
  success: `focus:ring-1 bg-feedback-success-50 text-feedback-success-700 border-transparent`,
  info: `focus:ring-1 border-transparent`,
  warning: `focus:ring-1 bg-feedback-warning-50 text-feedback-warning-700 border-feedback-warning-200`,
};

// Base classNames for each button variant inside of a button group
const GROUP_BUTTON_CLASS_NAMES = {
  primary: 'first:rounded-l-md last:rounded-r-md focus:z-10 first:ml-0 -ml-px',
  'primary-inverse': 'first:rounded-l-md last:rounded-r-md focus:z-10 first:ml-0 -ml-px',
  secondary: 'first:rounded-l-md last:rounded-r-md focus:z-10 first:ml-0 -ml-px',
  tertiary: 'first:rounded-l-md last:rounded-r-md focus:z-10 first:ml-0 -ml-px',
  quaternary: 'first:rounded-l-md last:rounded-r-md focus:z-10 first:ml-0 -ml-px',
  info: 'first:rounded-l-md last:rounded-r-md focus:z-10 first:ml-0 -ml-px',
  warning: 'first:rounded-l-md last:rounded-r-md focus:z-10 first:ml-0 -ml-px',
  danger: 'first:rounded-l-md last:rounded-r-md focus:z-10 first:ml-0 -ml-px',
  success: 'border-feedback-success-600 first:rounded-l-md last:rounded-r-md focus:z-10 first:ml-0 -ml-px',
  flush: 'first:rounded-l-md last:rounded-r-md focus:z-10 first:ml-0 -ml-px',
};

const SHADE_CLASS_NAMES: Record<Variants, any> = {
  primary: undefined,
  'primary-inverse': undefined,
  secondary: undefined,
  tertiary: undefined,
  flush: undefined,
  danger: {
    default:
      'bg-feedback-danger-50 text-feedback-danger-700 border-feedback-danger-200 hover:bg-feedback-danger-200 hover:text-feedback-danger-900',
    light:
      'bg-feedback-danger-50 text-feedback-danger-700 border-feedback-danger-200 hover:bg-feedback-danger-200 hover:text-feedback-danger-900',
    dark: 'bg-feedback-danger-600 text-feedback-danger-50 hover:bg-feedback-danger-700 disabled:text-gray-600 disabled:bg-gray-100',
  },
  success: undefined,
  info: {
    default: 'bg-feedback-info-50 text-feedback-info-700',
    light: 'bg-feedback-info-50 text-feedback-info-700',
    dark: 'bg-[#F5F3FF] hover:bg-[#DDD6FE] text-[#4C1D95] disabled:text-gray-600 disabled:bg-gray-100',
  },
  warning: undefined,
};

const GROUP_SHADE_CLASS_NAMES: Record<Variants, any> = {
  primary: undefined,
  'primary-inverse': undefined,
  secondary: undefined,
  tertiary: undefined,
  flush: undefined,
  danger: undefined,
  success: undefined,
  info: {
    default: undefined,
    light: undefined,
    dark: 'border-[#DDD6FE]',
  },
  warning: undefined,
};

const SPACING_CLASS_NAMES = {
  none: 'py-1', // A way to remove side padding, helpful for flush buttons
  xxs: 'py-1 px-3',
  xs: 'py-2 px-3',
  sm: 'py-2 px-3',
  base: 'py-2 px-3',
  lg: 'py-2 px-3',
  xl: 'py-2 px-3',
};

const FONT_SIZING_CLASS_NAMES = {
  none: 'text-xs',
  xxs: 'text-xs',
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
};

const ICON_SIZING_CLASS_NAMES = {
  none: '',
  xxs: 'w-3.5 h-3.5',
  xs: 'w-4 h-4',
  sm: 'w-5 h-5',
  base: 'w-5 h-5',
  lg: 'w-5 h-5',
  xl: 'w-6 h-6',
};

// Prefer not using an arrow function here so it doesn't show up as "Anonymous" component in React dev tools
// eslint-disable-next-line prefer-arrow-callback
export const Button = forwardRef(function InternalButton(props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) {
  const groupContext = useContext(ButtonGroupContext);
  const isInGroup = !!groupContext.variant;

  const { variant: variantFromProps, disabled: disabledFromProps } = props;

  const {
    variant = (groupContext.variant || variantFromProps || 'primary') as Variants,
    disabled = groupContext.disabled || disabledFromProps || false,
    block = false,
    loading = false,
    children,
    className,
    type,
    Icon,
    disableWith,
    iconRight = false,
    confirm,
    onClick: onClickFromProps,
    size = groupContext.size || 'sm',
    tooltip,
    align,
    spacing,
    shade = 'default',
    ...rest
  } = props;

  const navigate = useNavigate();

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // eslint-disable-next-line no-alert
    if (confirm && !window.confirm(confirm)) {
      e.preventDefault();
      return;
    }

    if (onClickFromProps) {
      onClickFromProps(e);
    }

    if (props.to) {
      navigate(props.to);
    }
  };

  const constructedClassName = cx(
    BASE_BUTTON_CLASS_NAMES,
    BUTTON_CLASS_NAMES[variant],
    SHADE_CLASS_NAMES[variant] && SHADE_CLASS_NAMES[variant][shade],
    block && 'w-full flex',
    Icon ? 'gap-x-2' : '',
    align === 'left' ? 'justify-start' : 'justify-center',
    isInGroup ? GROUP_BUTTON_CLASS_NAMES[variant] : 'rounded-md',
    isInGroup && GROUP_SHADE_CLASS_NAMES[variant] && GROUP_SHADE_CLASS_NAMES[variant][shade],
    spacing || SPACING_CLASS_NAMES[size],
    FONT_SIZING_CLASS_NAMES[size],
    className
  );

  const iconClassName = cx(ICON_SIZING_CLASS_NAMES[size]);

  const isDisabled = loading || disabled;

  const renderableChildren = !children && !Icon && !iconRight ? <span>&#8203;</span> : children;

  return (
    <button
      data-tip={tooltip}
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={constructedClassName}
      disabled={isDisabled}
      onClick={onClick}
      {...rest}
    >
      {loading && <LoadingSpinner className="mr-2" color={(variant === 'primary' || variant === 'tertiary') ? 'white' : 'gray'} />}
      {!iconRight && Icon && !loading && <Icon className={iconClassName} />}
      {isDisabled && disableWith ? disableWith : renderableChildren}
      {iconRight && Icon && !loading && <Icon className={iconClassName} />}
      {tooltip && <ReactTooltip delayShow={500} place="bottom" />}
      {tooltip && !children && <span className="sr-only">{tooltip}</span>}
    </button>
  );
});
