import { useCurrentPublicationState } from '@/context/current-publication-context';
import useOptions from '@/hooks/useOptions';
import { usePublication } from '@/hooks/usePublications';

import WaitUntilParamsForm from '../components/WaitUntilParamsForm';

interface Props {
  currentWeekdays: string[];
  currentTimeOfDay: string;
  currentTimeZone?: string;
  onChangeWeekdays: (weekdays: string[]) => void;
  onChangeTimeOfDay: (timeOfDay: string) => void;
  onChangeTimeZone: (timeZone: string) => void;
}

const ConfigureWaitUntil = ({
  currentWeekdays,
  currentTimeOfDay,
  currentTimeZone,
  onChangeWeekdays,
  onChangeTimeOfDay,
  onChangeTimeZone,
}: Props) => {
  const [publicationId] = useCurrentPublicationState();
  const currentPublication = usePublication(publicationId)?.data;
  const timezones = useOptions('timezones');
  const { data: timezoneData } = timezones;

  return (
    <WaitUntilParamsForm
      publicationTimeZone={currentPublication?.time_zone || ''}
      timezones={timezoneData?.options || []}
      weekdays={currentWeekdays}
      onWeekDaysChange={onChangeWeekdays}
      timeOfDay={currentTimeOfDay}
      onTimeOfDayChange={onChangeTimeOfDay}
      timeZone={currentTimeZone || ''}
      onTimeZoneChange={onChangeTimeZone}
    />
  );
};

export default ConfigureWaitUntil;
