import { useEffect, useState } from 'react';
import moment from 'moment-mini';

import { useCurrentPublication } from '../usePublications';
import useUpgradeIntent from '../useUpgradeIntent';

interface ReturnProps {
  daysRemaining: string;
  isTrialActive: boolean;
  trialDays: number;
  upsellIsOpen: boolean;
  handleUpsellOpen: () => void;
  handleUpsellClose: () => void;
}

const TRIAL_DAYS = 14;

const useFreeTrial = (): ReturnProps => {
  const { data: currentPublication } = useCurrentPublication();
  const [isTrialActive, setIsTrialActive] = useState(false);
  const [countdown, setCountdown] = useState<moment.Duration | null>(null);

  useEffect(() => {
    if (currentPublication?.trial_finishes_at) {
      const now = moment();
      const trialEndsAt = moment(currentPublication.trial_finishes_at);
      const duration = moment.duration(trialEndsAt.diff(now));

      if (duration.asMilliseconds() <= 0) {
        setCountdown(null);
        setIsTrialActive(false);
      } else {
        setCountdown(duration);
        setIsTrialActive(true);
      }
    }
  }, [currentPublication]);

  const { isOpen: upsellIsOpen, handleOpen: handleUpsellOpen, handleClose: handleUpsellClose } = useUpgradeIntent({});

  return {
    daysRemaining: countdown?.humanize() || '0 days',
    trialDays: TRIAL_DAYS,
    isTrialActive,
    upsellIsOpen,
    handleUpsellOpen,
    handleUpsellClose,
  };
};

export default useFreeTrial;
