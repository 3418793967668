import { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Select } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import Text from '@/components/Text';
import { usePostTargets } from '@/hooks/usePostTargets';
import { useTiers } from '@/hooks/useTiers';
import { Post } from '@/interfaces/post';
import { PostTargetPlatform } from '@/interfaces/post_target';

import ManagedAudiences from '../_components/ManagedAudiences';

interface WebSectionProps {
  post: Post;
  onChange: (data: any) => void;
  isLocked: boolean;
}

const WebSection: React.FC<WebSectionProps> = ({ post, onChange, isLocked }) => {
  const { postId } = useParams<'postId'>() as { postId: string };

  const { data, isLoading, isError } = usePostTargets(postId, PostTargetPlatform.WEB);
  const postSendTargets = useMemo(() => data?.post_targets || [], [data]);
  const { data: tiers = [] } = useTiers();
  const tierOptions = tiers.flatMap((tier) =>
    tier.prices
      .filter((price) => price.enabled || post.paywall_break_price_id === price.id)
      .map((price) => ({
        label: `${tier.name}/${price.denominator}`,
        value: price.id,
      }))
  );

  const hasPremiumPostSendTargets = postSendTargets.some((pst) => pst.tier === 'premium');
  const paywallBreakPriceId = post.paywall_break_price_id || '';

  useEffect(() => {
    if (!paywallBreakPriceId) {
      const tierIds = postSendTargets.map((pst) => pst.tier_id);
      const priceIds = tiers.filter((t) => tierIds.includes(t.id)).flatMap((t) => t.prices.map((p) => p.id));

      onChange({ paywall_break_price_id: priceIds[0] });
    }
  }, [hasPremiumPostSendTargets]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="space-y-1">
      <Text size="md" type="bold" className="text-gray-600">
        Web Access
      </Text>
      <div className="py-4 space-y-4 bg-white rounded-md border border-gray-200">
        <LoadingBox isLoading={isLoading} isError={isError}>
          <div className="space-y-4 px-4">
            <Text size="sm" type="bold">
              Managed Audiences
            </Text>
            <div className="py-4 bg-white rounded-md border border-gray-200">
              <LoadingBox isLoading={isLoading} isError={isError}>
                <ManagedAudiences
                  postSendTargets={postSendTargets}
                  readOnly={isLocked}
                  platform={PostTargetPlatform.WEB}
                />
              </LoadingBox>
            </div>
          </div>
        </LoadingBox>
        <LoadingBox isLoading={isLoading} isError={isError}>
          {hasPremiumPostSendTargets && (
            <>
              <hr />
              <div className="space-y-4 px-4">
                <Text size="sm" type="bold">
                  Paywall settings
                </Text>
                <Select
                  name="paywall_break_price_id"
                  labelText="Tier/Plan to display on the paywall"
                  value={paywallBreakPriceId}
                  onSelect={(_name: string, value: string) => onChange({ paywall_break_price_id: value })}
                  options={tierOptions}
                />
              </div>
              <div className="px-4">
                <Link
                  to="/settings/publication/premium"
                  target="_blank"
                  className="text-xs text-gray-500 hover:text-gray-700 underline"
                >
                  Create and manage tiers and plans
                </Link>
              </div>
            </>
          )}
        </LoadingBox>
      </div>
      {isLocked && (
        <div className="pt-1">
          <Text size="xs" type="label">
            * You cannot edit the web audience of this post
          </Text>
        </div>
      )}
    </div>
  );
};

export default WebSection;
