import { useNavigate, useSearchParams } from 'react-router-dom';

import TabNavigation from '@/components/TabNavigation';
import { Tab } from '@/interfaces/general';

import ConfigurationTab from './ConfigurationTab';
import FulfillmentTab from './FulfillmentTab';
import OverviewTab from './OverviewTab';

const Tabs = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab');

  const tabs: Tab[] = [
    {
      name: 'overview',
      label: 'Overview',
      selected: !selectedTab || selectedTab === 'overview',
      onSelect: () => navigate(`/referral_program?tab=overview`),
    },
    {
      name: 'fulfillment',
      label: 'Fulfillment',
      selected: selectedTab === 'fulfillment',
      onSelect: () => navigate(`/referral_program?tab=fulfillment`),
    },
    {
      name: 'configuration',
      label: 'Configuration',
      selected: selectedTab === 'configuration',
      onSelect: () => navigate(`/referral_program?tab=configuration`),
    },
  ];

  return (
    <div className="space-y-5">
      <TabNavigation tabs={tabs} variant="tertiary" className="w-full" />
      {!selectedTab || selectedTab === 'overview' ? <OverviewTab /> : null}
      {selectedTab === 'fulfillment' ? <FulfillmentTab /> : null}
      {selectedTab === 'configuration' ? <ConfigurationTab /> : null}
    </div>
  );
};

export default Tabs;
