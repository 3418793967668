import { useLocation } from 'react-router-dom';

function useAppLayout(): {
  isSettingsPages: boolean;
  hasTopPadding: boolean;
  hasSubLayout: boolean;
} {
  const location = useLocation();

  const isPageWithSubLayout = location.pathname.includes('analytics/click_map');
  const isSettingsPages = location.pathname.startsWith('/settings');

  return {
    isSettingsPages,
    hasSubLayout: isPageWithSubLayout,
    hasTopPadding: !isSettingsPages,
  };
}

export default useAppLayout;
