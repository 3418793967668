import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { EmailMessageClick } from '../interfaces/email_message';
import { Pagination } from '../interfaces/general';
import api from '../services/swarm';

interface Props {
  emailMessageId: string | undefined;
}

interface ApiResponse {
  clicks: EmailMessageClick[];
  pagination: Pagination;
}

const useEmailMessageClicks = ({ emailMessageId }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchEmailMessageClicks = ({ pageParam = 1 }) =>
    api
      .get(`/email_messages/${emailMessageId}/clicks`, {
        params: {
          page: pageParam,
          per_page: 15,
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(['email_messages', 'clicks', emailMessageId], fetchEmailMessageClicks, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    enabled: Boolean(emailMessageId),
    staleTime: 30000,
    keepPreviousData: true,
  });
};

export default useEmailMessageClicks;
