import { FC, ReactNode } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

type SwitchVariants = 'primary' | 'secondary' | 'notification';

interface Props {
  name: string;
  checked: boolean;
  onChange: (name: string, checked: boolean) => void;
  className?: string;
  prefixLabelText?: string;
  labelText?: string;
  helperText?: string | ReactNode;
  disabled?: boolean;
  variant?: SwitchVariants;
  size?: 'large' | 'small';
  showIcons?: boolean;
}

const variantCheckedClassMap = {
  primary: 'bg-primary-500',
  secondary: 'bg-gray-600',
  notification: 'bg-[#BE185E]',
};

const variantColorClassMap = {
  primary: 'text-primary-500',
  secondary: 'text-gray-600',
  notification: 'text-[#BE185E]',
};

const Switch: FC<Props> = (props: Props) => {
  const {
    className,
    prefixLabelText,
    labelText,
    name,
    checked,
    onChange,
    helperText,
    disabled = false,
    variant = 'secondary',
    size = 'large',
    showIcons = true,
  } = props;
  const checkedClass = variantCheckedClassMap[variant];
  const color = variantColorClassMap[variant];

  const handleChange = () => {
    if (onChange) {
      onChange(name, !checked);
    }
  };

  const hasLabelOrHelperText: boolean = !!labelText || !!helperText;

  return (
    <div className={cx('inline-flex flex-col justify-center', className)}>
      <div className="flex">
        {prefixLabelText && (
          <div className="mr-3 my-auto">
            <label htmlFor={name} className="block text-sm font-medium text-gray-700">
              {prefixLabelText}
            </label>
          </div>
        )}
        <button
          id={name}
          type="button"
          onClick={handleChange}
          disabled={disabled}
          className={cx(
            'focus:ring-transparent relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
            checked ? checkedClass : 'bg-gray-200',
            disabled ? 'opacity-50 cursor-not-allowed' : '',
            size === 'large' ? 'h-6 w-11' : 'h-5 w-9'
          )}
        >
          <span
            className={cx(
              'pointer-events-none relative inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              // eslint-disable-next-line no-nested-ternary
              checked ? (size === 'large' ? 'translate-x-5' : 'translate-x-4') : 'translate-x-0',
              size === 'large' ? 'h-5 w-5' : 'h-4 w-4'
            )}
          >
            <span
              className={cx(
                'span ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                checked ? 'opacity-0' : 'opacity-100'
              )}
            >
              {showIcons && <XMarkIcon className="h-3 w-3 text-gray-400" />}
            </span>
            <span
              className={cx(
                'ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                checked ? 'opacity-100' : 'opacity-0'
              )}
            >
              {showIcons && <CheckIcon className={cx('h-3 w-3', color)} />}
            </span>
          </span>
          {name && <input type="hidden" name={name} value={`${checked}`} />}
        </button>
        {hasLabelOrHelperText && (
          <div className="ml-3">
            {labelText && (
              <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                {labelText}
              </label>
            )}
          </div>
        )}
      </div>
      {helperText && (
        <div>
          <p className="mt-2 text-xs text-gray-500">{helperText}</p>
        </div>
      )}
    </div>
  );
};

Switch.defaultProps = {
  className: undefined,
  labelText: undefined,
  helperText: undefined,
};

export default Switch;
