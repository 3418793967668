import { useInfiniteQuery } from 'react-query';

import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface ApiResponse {
  endpoints: WebhookEndpoint[];
  has_more: boolean;
  iterator: string;
}

export default function useSvixEndpoints() {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchEndpoints = ({ pageParam = '' }) =>
    api
      .get('/webhooks/svix_endpoints', {
        params: {
          publication_id: currentPublicationId,
          iterator: pageParam,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['publications', currentPublicationId, 'webhooks', 'endpoints'],
    fetchEndpoints,
    {
      getNextPageParam: (lastPage) => {
        const { iterator, has_more: hasMore } = lastPage || {};

        if (hasMore) return iterator;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
