import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

import { Input } from '../../../../../components/Form';
import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import { useCreatePublication } from '../../../../../hooks/usePublications';
import api from '../../../../../services/swarm';

const NewPublication = ({ isOpen, close }: any) => {
  const navigate = useNavigate();
  const [, setCurrentPublicationId] = useCurrentPublicationState();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [subdomain, setSubdomain] = useState<string>('');

  const createPublication = useCreatePublication();

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();

    if (name && description && subdomain) {
      const createdPublication = await createPublication.mutateAsync({
        name,
        description,
      });

      setCurrentPublicationId(createdPublication.data.publication.id);

      // Update subdomain after publication is created
      api
        .patch(`/publications/${createdPublication.data.publication.id}/subdomain`, {
          resource_locator: {
            subdomain,
          },
        })
        .then(() => {
          toast.success('Subdomain successfully updated!');
          navigate(`/settings/publication`);
        })
        .catch(() => {
          toast.error('Invalid subdomain');
          navigate('/settings/publication/domains');
        });
    } else {
      toast.error('Please fill out all fields');
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-90" onClose={close} style={{ zIndex: 999999 }}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-md pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white p-8 shadow-md">
                    <form onSubmit={handleUpdate}>
                      <div className="space-y-4 w-full">
                        <div className="flex space-x-3 items-center w-full">
                          <div className="flex w-full space-x-2 items-center justify-between">
                            <h2 className="text-lg leading-6 font-medium text-gray-900 ml-0 flex">New Publication</h2>
                            <button
                              className="h-7 w-7 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                              type="button"
                              onClick={close}
                            >
                              <XMarkIcon className="h-4 w-4" />
                            </button>
                          </div>
                        </div>

                        <div className="space-y-4">
                          <Input
                            className="w-full"
                            name="name"
                            labelText="Publication Name"
                            placeholder="My New Publication"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <Input
                            className="w-full"
                            name="desription"
                            labelText="This publication is about..."
                            placeholder="A newsletter about lifestyle"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                          <Input
                            className="w-full"
                            name="email"
                            labelText="Subdomain"
                            placeholder="my-new-publication"
                            value={subdomain}
                            onChange={(e) => setSubdomain(e.target.value)}
                          />
                          <div className="w-full flex justify-end space-x-2 pt-4">
                            <Button type="button" variant="primary-inverse" onClick={close}>
                              Cancel
                            </Button>
                            <Button type="submit" variant="primary" loading={createPublication.isLoading}>
                              <div className="flex">
                                {createPublication.isLoading ? 'Creating...' : 'Create Publication'}
                              </div>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewPublication;
