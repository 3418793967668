import { useState } from 'react';

import { Checkbox } from '@/components/Form';
import Input from '@/components/Form/Input';
import SlideOver from '@/components/SlideOver';
import { useCampaignAdvertisements } from '@/hooks/useAdNetwork/internal';
import useCampaignAdGroupCreate from '@/hooks/useAdNetwork/internal/useCampaignAdGroupCreate';
import { AdNetworkAdvertisement } from '@/interfaces/ad_network/internal/advertisement';
import { Button } from '@/ui/Button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  campaignId: string;
  refetch: () => void;
}

interface AdGroupSlideOverFormProps extends Props {
  advertisements: AdNetworkAdvertisement[];
}

const AdGroupSlideOverForm = ({ isOpen, onClose, campaignId, advertisements, refetch }: AdGroupSlideOverFormProps) => {
  const { mutateAsync, isLoading } = useCampaignAdGroupCreate({ campaignId });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedAdvertisements, setSelectedAdvertisements] = useState<string[]>(
    advertisements.map((advertisement) => advertisement.id)
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('ad_group[name]', name);
    formData.append('ad_group[description]', description);
    selectedAdvertisements.forEach((id) => {
      formData.append('ad_group[ad_group_ads_attributes][][advertisement_id]', id);
    });

    mutateAsync(formData).then(() => {
      refetch();
      onClose();
    });
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} headerText="New advertisement group">
      <form className="space-y-6" onSubmit={handleSubmit}>
        <Input name="ad_group[name]" labelText="Name" value={name} onChange={(e) => setName(e.target.value)} />
        <Input
          name="ad_group[description]"
          labelText="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <fieldset className="space-y-2">
          <legend className="flex items-center gap-1 text-sm font-medium text-gray-700">Advertisements</legend>

          {advertisements.map((advertisement) => (
            <Checkbox
              key={advertisement.id}
              name={`advertisements[${advertisement.id}]`}
              checked={selectedAdvertisements.includes(advertisement.id)}
              labelText={`${advertisement.name} (${advertisement.kind})`}
              onChange={() => {
                setSelectedAdvertisements((prev) =>
                  prev.includes(advertisement.id)
                    ? prev.filter((id) => id !== advertisement.id)
                    : [...prev, advertisement.id]
                );
              }}
            />
          ))}
        </fieldset>

        <Button type="submit" loading={isLoading}>
          Save
        </Button>
      </form>
    </SlideOver>
  );
};

export default function Loader(props: Props) {
  const { campaignId } = props;
  const { data, isLoading, isSuccess } = useCampaignAdvertisements({ campaignId });

  if (isLoading) return <div>Loading...</div>;
  if (!isSuccess) return <div>Error</div>;

  const advertisements = data.pages.map((page) => page.advertisements).flat();

  return <AdGroupSlideOverForm {...props} advertisements={advertisements} />;
}
