import { ReactElement } from 'react';

import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';

type Props = {
  title: string;
  subtitle?: string;
  onClick: () => void;
  buttonText: string;
  subText?: string;
  Icon: ReactElement;
  ImageSrc: string;
  ImageAlt: string;
};

const OfferCard = (props: Props) => {
  const { title, subtitle, onClick, buttonText, Icon, subText, ImageSrc, ImageAlt } = props;

  return (
    <div className="border border-surface-200 rounded p-6 flex flex-col-reverse gap-6 md:flex-row">
      <div className="w-full md:w-2/3 flex flex-col gap-4 justify-center md:pr-6">
        <div className="flex flex-col">
          <Typography token="font-semibold/text/base">{title}</Typography>
          <Typography key="font-normal/text/sm">{subtitle}</Typography>
        </div>
        <div className="flex flex-col gap-2">
          <Button className="w-full lg:w-1/3" variant="primary" onClick={onClick}>
            {Icon} {buttonText}
          </Button>
          <Typography weight="light" size="xs" colorWeight="500">
            {subText}
          </Typography>
        </div>
      </div>
      <div className="w-full md:w-1/3">
        <img className="border border-surface-200 rounded-md shadow" src={ImageSrc} alt={ImageAlt} />
      </div>
    </div>
  );
};

export default OfferCard;
