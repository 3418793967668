import { useState } from 'react';

import { Order } from '@/interfaces/general';

function useResourceListFilters<FilterType, OrderByType>(defaultFilter: FilterType, defaultSortOrder = '') {
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<FilterType | string>(defaultFilter);
  const [orderBy, setOrderBy] = useState<OrderByType | string>(defaultSortOrder);
  const [direction, setDirection] = useState<Order>(Order.DESC);

  const handleClickOrderButton = (newOrderBy: OrderByType, newDirection: Order) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  return {
    direction,
    setDirection,
    orderBy,
    setOrderBy,
    hasFilter: search !== '' || filter !== defaultFilter,
    search,
    setSearch,
    resetSearch: () => setSearch(''),
    filter,
    setFilter,
    resetFilter: () => setFilter(defaultFilter),
    resetAllFilters: () => {
      setSearch('');
      setFilter(defaultFilter);
    },
    handleClickOrderButton,
  };
}

export default useResourceListFilters;
