import Text from '../../../../../../components/Text';
import { useImports } from '../../../../../../hooks';

const ImportLimit = () => {
  const useImportsQuery = useImports();
  const { data } = useImportsQuery;
  const formatter = new Intl.NumberFormat('en-US');
  const importLimit = data?.pages[0]?.import_limit || 0;
  const importLimitRemaining = data?.pages[0]?.import_limit_remaining || 0;
  const importsUtilized = importLimit - importLimitRemaining;
  const importLimitStat = `${formatter.format(importsUtilized)}/${formatter.format(importLimit)}`;

  return (
    <div>
      <div className="overflow-hidden rounded-lg bg-white shadow divide-y divide-gray-200 md:divide-y-0 md:divide-x md:flex md:items-center">
        <div className="p-4 lg:px-7">
          <div className="truncate text-xs font-medium text-gray-500">Imported subscribers</div>
          <div className="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{importLimitStat}</div>
        </div>
        <div className="p-4 bg-gray-50 lg:px-7 lg:py-5">
          <Text size="sm">
            This limit counts across all of your publications and is not the same as the subscriber limit associated
            with your plan.{' '}
            <a
              href="https://support.beehiiv.com/hc/en-us/articles/12231316510871-Why-Do-I-Have-An-Import-Limit-"
              rel="noopener noreferrer nofollow"
              target="blank"
              className="text-primary-500 font-medium hover:text-primary-600"
            >
              Read more about import limits in our Knowledge Base
            </a>
            .
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ImportLimit;
