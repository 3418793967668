import { SubscriptionSubscriberTag } from './subscriber_tag';

export type SubscriptionStatus = 'active' | 'inactive' | 'pending' | 'needs_attention';

export interface Subscription {
  id: string;
  subscriber_id: string;
  email: string;
  created_at: string;
  status: SubscriptionStatus;
  tier: string;
  tiers: string[];
  stripe_customer_id: string;
  updated_at: string;
  acquisition_source_display: string;
  acquisition_source_channel: string;
  acquisition_source_source: string;
  acquisition_source_medium: string;
  acquisition_source_campaign: string;
  acquisition_source_device_type: string;
  location: string;
  acquisition_source_referring_url: string;
  acquisition_source_landing_page: string;
  embed_name: string;
  referrer_id: string;
  referrer_email: string;
  latitude: number;
  longitude: number;
  city: string;
  state: string;
  country: string;
  referral_url: string;
  manage_url: string;
  unsubscribed_by_automation_id?: string;
  name?: string;
  subscription_subscriber_tags: SubscriptionSubscriberTag[];
}

export const SubscriptionStatuses: any = {
  active: 'active',
  inactive: 'inactive',
  pending: 'pending',
  needs_attention: 'needs approval',
};

export interface SubscriptionSendStats {
  total_sent: number;
  total_received: number;
  total_unique_opened: number;
  total_clicked: number;
  open_rate: number;
  click_rate: number;
}
