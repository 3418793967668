import { useCurrentPublication, useUpdatePublication } from '@/hooks/usePublications';
import { Option } from '@/interfaces/general';
import { Section, SelectForm } from '@/pages/Settings/Components';
import InputForm from '@/pages/Settings/Components/InputForm';

const options: Option[] = [
  { label: 'None', value: 'none' },
  { label: 'Gated', value: 'gated' },
  { label: 'Popup', value: 'popup' },
];

const EmailCaptureSection = () => {
  const { data: currentPublication } = useCurrentPublication();
  const publicationMutation = useUpdatePublication();

  const currentSelection = options.find((option) => option.value === currentPublication?.email_capture_type);
  const isGated = currentSelection?.value === 'gated';
  const isPopup = currentSelection?.value === 'popup';
  const isNone = currentSelection?.value === 'none';

  return (
    <Section
      title="Email Capture"
      description="Add additional ways of capturing email addresses on posts. By default, this feature is disabled. By selecting either gated content or popup you may enable alternative ways of capturing new subscribers. You may override this settings on a per-post basis."
      scrollToId="email-capture"
      isLoading={!currentPublication}
    >
      <SelectForm
        label="Post email capture type"
        description="Select between none, gated, or popup."
        value={currentSelection || options[0]}
        toastSuccessMessage="Saved!"
        onSave={(val: Option) => {
          return publicationMutation.mutateAsync({
            post_email_capture_type: val.value,
          });
        }}
        options={options}
      />
      {!isNone && (
        <>
          {isGated && (
            <>
              <InputForm
                name="gated_title"
                label="Gated Content Title"
                placeholder={currentPublication?.gated_title || ''}
                value={currentPublication?.gated_title || ''}
                helperText="This will be displayed on all of your publication's subscribe forms."
                onSave={(val) => publicationMutation.mutateAsync({ post_email_gated_title: val })}
                isSaving={publicationMutation.isLoading}
                toastSuccessMessage="Saved!"
              />
              <InputForm
                name="gated_message"
                label="Gated Content Message"
                placeholder={currentPublication?.gated_message || ''}
                value={currentPublication?.gated_message || ''}
                onSave={(val) => publicationMutation.mutateAsync({ post_email_gated_message: val })}
                isSaving={publicationMutation.isLoading}
                toastSuccessMessage="Saved!"
              />
              <InputForm
                name="gated_cta"
                label="Gated Content CTA"
                placeholder={currentPublication?.gated_cta || ''}
                value={currentPublication?.gated_cta || ''}
                onSave={(val) => publicationMutation.mutateAsync({ post_email_gated_cta: val })}
                isSaving={publicationMutation.isLoading}
                toastSuccessMessage="Saved!"
              />
            </>
          )}
          {isPopup && (
            <>
              <InputForm
                name="popup_title"
                label="Popup Content Title"
                placeholder={currentPublication?.popup_title || ''}
                value={currentPublication?.popup_title || ''}
                helperText="This will be displayed on all of your publication's subscribe forms."
                onSave={(val) => publicationMutation.mutateAsync({ post_email_popup_title: val })}
                isSaving={publicationMutation.isLoading}
                toastSuccessMessage="Saved!"
              />
              <InputForm
                name="popup_message"
                label="Popup Content Message"
                placeholder={currentPublication?.popup_message || ''}
                value={currentPublication?.popup_message || ''}
                onSave={(val) => publicationMutation.mutateAsync({ post_email_popup_message: val })}
                isSaving={publicationMutation.isLoading}
                toastSuccessMessage="Saved!"
              />
              <InputForm
                name="popup_cta"
                label="Popup Content CTA"
                placeholder={currentPublication?.popup_cta || ''}
                value={currentPublication?.popup_cta || ''}
                onSave={(val) => publicationMutation.mutateAsync({ post_email_popup_cta: val })}
                isSaving={publicationMutation.isLoading}
                toastSuccessMessage="Saved!"
              />
            </>
          )}
        </>
      )}
    </Section>
  );
};

export default EmailCaptureSection;
