import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import Section from '@/routes/settings/_components/Section';
import analytics from '@/utils/analytics';
import isValidUrlWithProtocol from '@/utils/isValidUrlWithProtocol';

import { useTierConfigurationContext } from '../../_context/tier_configuration_context';
import useTierFormStep from '../../_hooks/useTierFormStep';

const AdvancedSection = () => {
  const { redirectUrl, setRedirectUrl, errors, setErrors } = useTierConfigurationContext();
  const { onFocus, onBlur } = useTierFormStep('advanced');

  const handleRedirectUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.trim();
    setRedirectUrl(newValue);

    if (isValidUrlWithProtocol(newValue) || newValue === '') {
      setErrors((prevErrors) => Object.fromEntries(Object.entries(prevErrors).filter(([k]) => k !== 'redirect_url')));
      analytics.track('set redirect url', { url: newValue });
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        redirect_url: 'Redirect URL must be a valid URL and begin with http:// or https://',
      }));
    }
  };

  return (
    <div onFocus={onFocus} onBlur={onBlur}>
      <Section
        title="Advanced Options"
        description="Optimize the subscriber checkout experience"
        maxWidthClassName="max-w-4xl"
        titleAction={
          <Typography token="font-normal/text/sm" colorWeight="500">
            Optional
          </Typography>
        }
      >
        <Input
          labelText="Redirect URL after purchase"
          name="redirect_url"
          placeholder="https://example.com"
          className="mb-2"
          value={redirectUrl}
          onChange={handleRedirectUrlChange}
          errorText={errors.redirect_url || ''}
        />
        <Typography token="font-light/text/sm">
          Once a subscriber completes the subscribe flow, they will land on this URL associated with the tier they
          selected rather than your homepage.
        </Typography>
      </Section>
    </div>
  );
};

export default AdvancedSection;
