import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import Text from '@/components/Text';
import VerifyIdentity from '@/components/VerifyIdentity';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useOrganization } from '@/hooks';
import { useApiKeys, useDeleteApiKey } from '@/hooks/useIntegrations';
import { usePublication } from '@/hooks/usePublications';
import useStripeIdentityVerificationSession from '@/hooks/useUsers/useStripeIdentityVerificationSession';
import { Button } from '@/ui/Button';

import EditApiKey from './EditApiKey';
import NewApiKey from './NewApiKey';

interface ApiKeyProps {
  id: string;
  name: string;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const ApiKeyItem = ({ id, name, onEditClick, onDeleteClick }: ApiKeyProps) => {
  return (
    <div className="flex justify-between items-center border-b border-gray-100 pb-4">
      <p className="block text-sm font-semibold text-gray-700">{name}</p>
      <div className="flex items-center">
        <EllipsisDropdown
          portalMountedId={id}
          options={[
            {
              label: 'Edit',
              onClick: () => onEditClick(),
            },

            {
              label: 'Delete',
              onClick: () => onDeleteClick(),
            },
          ]}
        />
      </div>
    </div>
  );
};

const ApiKeysSection = () => {
  const [isNewApiKeyPanelOpen, setIsNewApiKeyPanelOpen] = useState<boolean>(false);
  const [keyToEdit, setKeyToEdit] = useState<any>(null);
  const [keyToDelete, setKeyToDelete] = useState<any>(null);

  const apiKeysRequest = useApiKeys();
  const { data: apiKeys } = apiKeysRequest || {};
  const [publicationId] = useCurrentPublicationState();
  const currentPublication = usePublication(publicationId)?.data;
  const organizationId = currentPublication?.organization_id;
  const organizationQuery = useOrganization(currentPublication?.organization_id);
  const { organization } = organizationQuery?.data || {};
  const requiresIdentityVerification = Boolean(organization?.requires_stripe_identity_verification);

  const deleteApiKey = useDeleteApiKey({ keyId: keyToDelete?.id });

  const {
    mutation: { mutateAsync, isLoading },
    stripe,
    submitted,
    isSubmitting,
  } = useStripeIdentityVerificationSession({
    organizationId,
  });

  const handleClickVerify = async () => {
    await mutateAsync({});
  };

  return (
    <>
      <ActionModal
        isOpen={Boolean(keyToDelete)}
        onClose={() => setKeyToDelete(null)}
        onProceed={() => {
          deleteApiKey.mutate();
          setKeyToDelete(null);
        }}
        resourceId={keyToDelete?.id}
        isWorking={deleteApiKey.isLoading}
        headerText="Delete API Key"
        actionText="Delete"
        buttonType="danger"
      >
        Are you sure you want to delete this API Key? This will immediately stop its ability to access your
        organization.
      </ActionModal>
      <NewApiKey isOpen={isNewApiKeyPanelOpen} close={() => setIsNewApiKeyPanelOpen(false)} />
      <EditApiKey
        id={keyToEdit?.id}
        currentValue={keyToEdit?.name}
        isOpen={Boolean(keyToEdit)}
        close={() => setKeyToEdit(null)}
      />

      <div className="flex flex-col space-y-3">
        {apiKeys?.length
          ? apiKeys?.map((apiKey: any) => {
              return (
                <ApiKeyItem
                  key={apiKey.id}
                  id={apiKey.id}
                  name={apiKey.name}
                  onEditClick={() => setKeyToEdit(apiKey)}
                  onDeleteClick={() => setKeyToDelete(apiKey)}
                />
              );
            })
          : null}
      </div>

      <div>
        {requiresIdentityVerification ? (
          <div className="flex mt-4 flex-col space-y-6 justify-start items-start">
            <Text type="body" size="sm">
              Verify your identity to create an API key. We&apos;ve partnered with Stripe to make this as easy as
              possible. Verification takes less than 5 minutes.
            </Text>
            <VerifyIdentity
              isDisabled={!stripe}
              isBusy={isLoading || isSubmitting}
              onClickVerify={handleClickVerify}
              hasSubmitted={submitted}
              verificationCompleteMessage="We're processing the results, expect an email with the outcome shortly. If your verification is successful you'll get access to the API immediately."
            />
            <Text type="body" size="xs">
              Alternatively, you may contact support to get API access. With this method you have to wait{' '}
              <b>48 - 72 hours</b> for your verification
            </Text>
          </div>
        ) : (
          <div className="flex flex-col space-y-5">
            <p className="text-sm font-light text-gray-900">
              Make sure all subscribers added by API have given consent to be signed up via beehiiv&apos;s{' '}
              <a
                href="https://www.beehiiv.com/tou"
                target="_blank"
                className="underline underline-offset-1 hover:opacity-80"
                rel="noreferrer noopener"
              >
                Terms of Use
              </a>{' '}
              &{' '}
              <a
                href="https://www.beehiiv.com/privacy"
                target="_blank"
                className="underline underline-offset-1 hover:opacity-80"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
              .
            </p>
            <Button
              type="button"
              className="w-fit"
              variant="primary-inverse"
              onClick={() => setIsNewApiKeyPanelOpen(true)}
            >
              New API Key
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default ApiKeysSection;
