import { Button } from '@tremor/react';

import Badge from '@/components/Badge';
import SlideOver from '@/components/SlideOver';
import Tooltip from '@/components/Tooltip';
import { useSettings } from '@/context/settings-context';
import useBoostOffer from '@/hooks/boosts/monetize/useBoostOffer';
import useBoostOfferCard from '@/hooks/boosts/monetize/useBoostOfferCard';
import useBoostOfferEligibleCountries from '@/hooks/boosts/useBoostOfferEligibleCountries';
import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';

export interface Props {
  onClose: () => void;
  onApply: ({ boostOffer }: { boostOffer: BoostOffer }) => void;
  boostOfferId?: string | null;
}

const BoostOfferSlideover = ({ onClose, onApply, boostOfferId }: Props) => {
  const { settings } = useSettings();
  const { data: boostOffer } = useBoostOffer(boostOfferId || '');
  const { hasRegionalRestrictions, allMappedEligibleCountries } = useBoostOfferEligibleCountries(
    boostOffer?.eligible_countries || null
  );

  const { alreadyApplied, shouldDisableApply, boostsPlusEnabled, ctaText, ctaIcon, boostedPublication } =
    useBoostOfferCard(boostOffer);

  return (
    <SlideOver
      isOpen={!!boostOfferId}
      onClose={() => onClose()}
      headerText={boostOffer?.boosted_publication?.name || ''}
    >
      <div className="space-y-4">
        {boostOffer && boostedPublication && (
          <>
            <p>{boostedPublication.description}</p>
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Target Audience</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boostOffer.description}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Website</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <a
                    href={boostedPublication.url}
                    className="text-primary-600 hover:text-primary-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {boostedPublication.hostname}
                  </a>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Payout</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {boostOffer.payout_per_lead} per subscriber
                </dd>
              </div>
              {settings?.boosts_plus && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Sending Payout</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {boostsPlusEnabled ? `Up to ${boostOffer.max_payout} per send` : 'Sending Not Enabled'}
                  </dd>
                </div>
              )}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Categories</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex gap-x-2 flex-wrap gap-y-2">
                  {boostedPublication.tags.map((tag) => (
                    <Badge key={tag.id} type="information">
                      #{tag.name}
                    </Badge>
                  ))}
                </dd>
              </div>
              {hasRegionalRestrictions && allMappedEligibleCountries.length > 0 && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    <span className="flex gap-x-1 items-center">
                      Eligible Countries
                      <Tooltip
                        id="boosts-slide-over-eligible-countries-tooltip"
                        text="This offer only pays for eligible subscribers from the specified countries"
                      />
                    </span>
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex gap-x-2 flex-wrap gap-y-2">
                    {allMappedEligibleCountries.map((country) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Badge key={country} type="information">
                        {country}
                      </Badge>
                    ))}
                  </dd>
                </div>
              )}
            </dl>
            <Button disabled={shouldDisableApply} onClick={() => onApply({ boostOffer })} icon={ctaIcon}>
              {alreadyApplied ? ctaText : `Earn ${boostOffer?.payout_per_lead} per subscriber`}
            </Button>
          </>
        )}
      </div>
    </SlideOver>
  );
};

export default BoostOfferSlideover;
