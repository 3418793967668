import { PageProvider } from '@/components/Layout/PageLayout/PageContext';
import { useAudience } from '@/hooks/useDashboard';
import { AudienceEndpoints } from '@/hooks/useDashboard/useAudience';
import useFreeTrial from '@/hooks/useFreeTrial';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import PausePlanNotice from '@/routes/settings/billing/_components/PausePlanNotice';
import { timePeriodLabels } from '@/utils/timePeriods';

import { SectionTabs } from './components/SectionTabs';
import useDashboardUrlState from './hooks/useDashboardUrlState';
import Audience from './Audience';
import Engagement from './Engagement';
import Header from './Header';
import Monetization from './Monetization';
import Overview from './Overview';
import { PageProps } from './types';

const SECTION_TABS = ['Overview', 'Audience', 'Engagement', 'Monetization'];

const Dashboardv2 = () => {
  const { currentPeriod, handleSetPeriod } = useDashboardUrlState();
  const { period, TimePeriodSelect } = useTimePeriodSelect({
    defaultValue: currentPeriod as keyof typeof timePeriodLabels,
  });
  const { isTrialActive } = useFreeTrial();

  const { isLoading, data } = useAudience({
    endpoint: AudienceEndpoints.SUBSCRIPTION_METRICS,
    timePeriod: period as keyof typeof timePeriodLabels,
  });
  const subscriptionKPIs = data?.subscription_kpis;
  const subscriptionEventsChart = data?.subscription_events_chart;
  const activeSubscriptionsChart = data?.active_subscriptions_chart;

  return (
    <PageProvider<PageProps>
      title="Dashboard"
      value={{
        period: period as keyof typeof timePeriodLabels,
        isTrialActive,
        isSubscriptionDataLoading: isLoading,
        subscriptionKPIs,
        subscriptionEventsChart,
        activeSubscriptionsChart,
      }}
    >
      <div className="flex flex-col space-y-10">
        <div className="flex flex-col space-y-4">
          <Header timePeriodSelectChild={<div />} />
          <PausePlanNotice />

          <SectionTabs
            tabList={SECTION_TABS}
            actionChildren={
              <div className="w-full mr-2">
                <TimePeriodSelect
                  handleChange={(timePeriod) => handleSetPeriod(timePeriod as keyof typeof timePeriodLabels)}
                />
              </div>
            }
          >
            <Overview />
            <Audience />
            <Engagement />
            <Monetization />
          </SectionTabs>
        </div>
      </div>
    </PageProvider>
  );
};

export default Dashboardv2;
