import { useMemo, useState } from 'react';
import cx from 'classnames';
import { v4 as generateUuid } from 'uuid';

import { Select } from '@/components/Form';
import { IData } from '@/interfaces/general';
import { StripeMigrationPrice } from '@/interfaces/stripe';

interface Props {
  sourcePrices: StripeMigrationPrice[];
  targetPrices: StripeMigrationPrice[];
  selectedSourcePrices: string[];
  setSelectedSourcePrices: (val: string[]) => void;
  mapping: IData;
  setMapping: (val: IData) => void;
}

const ProductMapSelections: React.FC<Props> = ({
  sourcePrices,
  targetPrices,
  selectedSourcePrices,
  setSelectedSourcePrices,
  mapping,
  setMapping,
}) => {
  const [showAddSourceProduct, setShowAddSourceProduct] = useState(false);

  const targetPriceOptions = useMemo(
    () => targetPrices.map((p) => ({ label: p.display_label, value: p.id })),
    [targetPrices]
  );

  const sourcePriceOptions = useMemo(
    () => sourcePrices.map((p) => ({ label: p.display_label, value: p.id })),
    [sourcePrices]
  );

  const handleMappingSelect = (name: string, value: string) => {
    setMapping({ ...mapping, [name]: value });
  };

  const handleAddSourceProduct = (name: string, value: string) => {
    if (selectedSourcePrices.includes(value)) {
      return;
    }

    setSelectedSourcePrices([...selectedSourcePrices, value]);
    setShowAddSourceProduct(false);
  };

  const handleRemoveSourceProduct = (name: string) => {
    if (!selectedSourcePrices.includes(name)) {
      return;
    }

    // Remove from selected options
    const newSelectedSourcePrices = selectedSourcePrices.filter((p) => p !== name);
    setSelectedSourcePrices(newSelectedSourcePrices);

    // Also make sure we remove any mappings
    // eslint-disable-next-line no-param-reassign
    delete mapping[name];
    setMapping(mapping);
  };

  return (
    <div>
      {sourcePriceOptions
        .filter((price) => selectedSourcePrices.includes(price.value))
        .sort((a, b) => selectedSourcePrices.indexOf(a.value) - selectedSourcePrices.indexOf(b.value))
        .map((price) => (
          <Select
            key={`${price.value}_${generateUuid()}`}
            className="mt-4"
            name={price.value}
            labelText={price.label}
            value={mapping[price.value]}
            onSelect={handleMappingSelect}
            options={targetPriceOptions || []}
            topRightLinkText="Remove Product"
            onTopRightLinkClick={handleRemoveSourceProduct}
            required
          />
        ))}
      {!showAddSourceProduct && selectedSourcePrices.length < sourcePriceOptions.length && (
        <button
          type="button"
          className={cx('text-blue-500 underline', selectedSourcePrices.length > 0 ? 'mt-4' : undefined)}
          onClick={() => setShowAddSourceProduct(true)}
        >
          Add Product ({sourcePriceOptions.length - selectedSourcePrices.length} unmapped products)
        </button>
      )}
      {showAddSourceProduct && (
        <div className="w-full mt-4">
          {selectedSourcePrices.length > 0 && <hr />}
          <Select
            className={selectedSourcePrices.length > 0 ? 'mt-4' : undefined}
            name="addSourceProduct"
            labelText="Add Source Product"
            value=""
            onSelect={handleAddSourceProduct}
            options={sourcePriceOptions.filter((price) => !selectedSourcePrices.includes(price.value)) || []}
            topRightLinkText="Cancel"
            onTopRightLinkClick={() => setShowAddSourceProduct(false)}
          />
        </div>
      )}
    </div>
  );
};

export default ProductMapSelections;
