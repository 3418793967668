import React, { FC } from 'react';

import { Select } from '@/components/Form';
import { Condition, Filter } from '@/interfaces/segment';

import { defaultOperatorByConditionName, operatorOptionsByConditionName } from './operatorOptions';

interface Props {
  condition: Condition;
  index: number;
  subIndex?: number;
  handleFilterChange: (filters: Filter, blockIndex: number, subIndex?: number) => void;
}

const ConditionOperatorSelect: FC<Props> = ({ condition, handleFilterChange, index, subIndex }) => {
  const options = operatorOptionsByConditionName(condition?.name);
  const defaultValue = defaultOperatorByConditionName(condition?.name);

  return (
    <Select
      className="w-full"
      name="operator"
      value={condition.filters?.operator || defaultValue}
      onSelect={(name: string, value: string) => {
        const updatedFilters = {
          operator: value,
        };

        const newFilters = { ...condition.filters, ...updatedFilters };

        handleFilterChange(newFilters, index, subIndex);
      }}
      options={options}
    />
  );
};

export default ConditionOperatorSelect;
