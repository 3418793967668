import Drawer from '@/components/Drawer';

const DropDown = ({ title, children }: { title: string; children: React.ReactChild }) => {
  return (
    <div className="px-4 pt-6">
      <Drawer
        chevronRight
        buttonClassName="flex justify-between items-center w-full bg-surface-50 text-gray-700 font-semibold rounded p-2"
      >
        <Drawer.Title>
          <span className="font-semibold text-gray-700">{title}</span>
        </Drawer.Title>
        <Drawer.Content>{children}</Drawer.Content>
      </Drawer>
    </div>
  );
};

export default DropDown;
