import { useState } from 'react';
import cx from 'classnames';
import moment from 'moment-mini';

import Badge from '@/components/Badge';
import { getColor } from '@/components/DesignTokens';
import { Switch } from '@/components/Form';
import { PublicationLogo } from '@/components/PublicationLogo';
import { Typography, TypographyStack } from '@/components/Typography';
import { BoostAgreement } from '@/interfaces/boosts/grow/boost_agreement';
import { BoostPublication } from '@/interfaces/boosts/grow/boost_publication';
import { Button } from '@/ui/Button';

import ReportPublicationModal from '../Shared/ReportPublicationModal';

interface Props {
  firstBoostAgreement: BoostAgreement;
  liveBoostAgreement?: BoostAgreement;
  publication: BoostPublication;
  onToggleBoostAffiliate: (newValue: boolean) => Promise<BoostAgreement>;
}

const PublicationCard = ({ firstBoostAgreement, liveBoostAgreement, publication, onToggleBoostAffiliate }: Props) => {
  const [showReportModal, setShowReportModal] = useState(false);
  const [isBoostAffiliateEnabled, setIsBoostAffiliateEnabled] = useState(
    liveBoostAgreement?.affiliate_enabled === true
  );
  const [isTogglingBoostAffiliate, setIsTogglingBoostAffiliate] = useState(false);

  const closeModal = () => setShowReportModal(false);

  const handleToggleBoostAffiliate = async () => {
    const newValue = !isBoostAffiliateEnabled;
    setIsTogglingBoostAffiliate(true);
    setIsBoostAffiliateEnabled(newValue);
    await onToggleBoostAffiliate(newValue);
    setIsTogglingBoostAffiliate(false);
  };

  return (
    <>
      <ReportPublicationModal
        isVisible={showReportModal}
        onClose={closeModal}
        onConfirm={closeModal}
        recommendingPublication={publication}
      />
      <div className={cx('flex flex-col rounded-lg border overflow-hidden', getColor('surface/200', 'border'))}>
        <div className="flex flex-col gap-y-2 sm:flex-row sm:items-start justify-between bg-white p-5">
          <div className="flex flex-col gap-y-4 justify-between">
            <div className="flex gap-x-4">
              <PublicationLogo size="lg" publication={publication} />
              <div className="flex flex-col">
                <TypographyStack>
                  <Typography token="font-semibold/text/2xl" colorWeight="900">
                    {publication.name}
                  </Typography>
                </TypographyStack>
              </div>
            </div>
            {publication.description && (
              <div className="flex flex-col sm:flex-row gap-x-1">
                <Typography token="font-medium/text/sm" colorWeight="700">
                  Target Audience:
                </Typography>
                <Typography token="font-normal/text/sm" colorWeight="700">
                  {publication.description}
                </Typography>
              </div>
            )}
            {publication.tags.length > 0 && (
              <div className={cx('flex gap-x-2')}>
                {publication.tags.map((tag) => (
                  <Badge type="tertiary">{tag.name}</Badge>
                ))}
              </div>
            )}
            <a href={publication.url} target="_blank" rel="noreferrer">
              <Typography token="font-normal/text/sm" colorWeight="600" color="tertiary">
                {publication.hostname}
              </Typography>
            </a>
          </div>

          {liveBoostAgreement && (
            <div className="flex justify-end">
              <Switch
                name="activate-boost-affiliate"
                onChange={handleToggleBoostAffiliate}
                checked={isBoostAffiliateEnabled}
                variant="primary"
                size="small"
                prefixLabelText="Direct Link"
                disabled={isTogglingBoostAffiliate}
              />
            </div>
          )}
        </div>
        <div className={cx('flex justify-between items-center px-5 py-3.5', getColor('surface/100', 'background'))}>
          <Typography token="font-normal/text/xs">
            Partner since{' '}
            <time dateTime={firstBoostAgreement.created_at as string}>
              {moment(firstBoostAgreement.created_at).fromNow()}
            </time>{' '}
          </Typography>

          <div className="flex gap-x-2">
            <Button type="button" size="xs" variant="primary" onClick={() => setShowReportModal(true)}>
              Report Abuse
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicationCard;
