import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

export default function useCreateSite() {
  const [currentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();

  return useMutation<void, unknown>(
    () =>
      api.post(`/sites`, {
        publication_id: currentPublicationId,
      }),
    {
      onSuccess: () => {
        toast.success('Website created successfully');

        navigate(`/website_builder_v2`);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
}
