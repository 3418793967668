import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Automation } from '../../interfaces/automations/automation';
import { AutomationTriggerEvent } from '../../interfaces/automations/automation_trigger';
import { Pagination } from '../../interfaces/general';
import api from '../../services/swarm';

interface ApiResponse {
  automations: Automation[];
  pagination: Pagination;
}

export default function useAutomations({
  search,
  ids,
  shouldFetchAll = false,
  dir = 'desc',
  order = 'created_at',
  triggerEvent,
  onlyActive,
}: {
  search?: string;
  ids?: string[];
  shouldFetchAll?: boolean;
  dir?: string;
  order?: string;
  triggerEvent?: AutomationTriggerEvent;
  onlyActive?: boolean;
}) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchAutomations = ({ pageParam = 1 }) =>
    api
      .get(`/automations`, {
        params: {
          page: pageParam,
          all_results: shouldFetchAll,
          publication_id: currentPublicationId,
          q: search,
          ids,
          order,
          dir,
          trigger_event: triggerEvent,
          only_active: onlyActive,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['automations', currentPublicationId, search, ids?.join(','), triggerEvent, onlyActive, order, dir],
    fetchAutomations,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
