import { useState } from 'react';
import { ProgressBar } from '@tremor/react';

import LoadingBox from '@/components/LoadingBox';
import SplitButton from '@/components/SplitButton';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { useStripeBillingSession } from '@/hooks/useBilling';
import { useTrialStatus } from '@/hooks/usePublicationDashboard';
import { BillingActions } from '@/interfaces/billing';
import Section from '@/routes/settings/_components/Section';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';
import { PLAN } from '@/utils/plans';

import { usePlanPrice } from '../../_hooks';

import UpgradeCta from './UpgradeCta';
import UpgradeTierModal from './UpgradeTierModal';

const UsageInformation: React.FC<{ organizationId: string }> = ({ organizationId }) => {
  const { settings } = useSettings();
  const { data: planPrice, isLoading, isError } = usePlanPrice({ organizationId });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const trialStatusQuery = useTrialStatus();
  const { data: trialStatus } = trialStatusQuery || {};
  const trialEligible = trialStatus?.trial_eligible;

  const { active_subscriptions: activeSubscriptions = 1, organization_max_subscriptions: maxSubscriptions = 1 } =
    planPrice || {};

  const stripeUpgradeSession = useStripeBillingSession({
    organizationId,
    action: BillingActions.UPGRADE,
  });

  const topPlanName = settings?.tiered_pricing ? PLAN.MAX : PLAN.SCALE;
  const onTopYearlyPlan = planPrice?.plan_name === topPlanName && planPrice?.interval === 'year';
  const usagePercentage = Math.min(100, (activeSubscriptions / maxSubscriptions) * 100);
  const allowPlanChange = planPrice?.plan_name !== PLAN.CUSTOM && !onTopYearlyPlan;
  const allowTierChange = planPrice?.plan_name !== PLAN.CUSTOM && planPrice?.has_next_tier;

  return (
    <>
      {planPrice && (
        <UpgradeTierModal
          organizationId={organizationId}
          planPrice={planPrice}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <Section
        title="Usage Information"
        className="max-w-3xl"
        titleAction={
          <div className="space-x-2">
            {trialEligible && (
              <Button
                type="button"
                variant="primary-inverse"
                onClick={() => {
                  analytics.track('Started Trial');
                  document.getElementById('start-trial')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }}
              >
                Start a Trial
              </Button>
            )}
            {planPrice && (
              <SplitButton
                variant="primary"
                buttons={[
                  ...(allowPlanChange
                    ? [
                        {
                          label: 'Upgrade Plan Type',
                          helperText: 'Upgrade to annual billing or to Scale and Max plans',
                          onClick: () => stripeUpgradeSession.mutate({}),
                          loading: stripeUpgradeSession.isLoading,
                          button: {
                            text: 'Upgrade',
                          },
                        },
                      ]
                    : []),
                  ...(allowTierChange
                    ? [
                        {
                          label: 'Upgrade Plan Tier',
                          helperText: 'Move to a higher tier of your current plan to unlock more subscribers',
                          onClick: () => setIsModalOpen(true),
                          defaultSelected: usagePercentage >= 90,
                          button: {
                            text: 'Upgrade',
                          },
                        },
                      ]
                    : []),
                ]}
              />
            )}
          </div>
        }
      >
        <LoadingBox isLoading={isLoading} isError={isError} backgroundClassName="bg-transparent" height={150}>
          {planPrice && (
            <div className="space-y-6">
              <UpgradeCta planPrice={planPrice} />
              <div className="space-y-4">
                <div className="bg-surface-50 border border-surface-200 rounded p-6 space-y-4">
                  <Typography as="p" token="font-semibold/text/base">
                    Subscriber Count
                  </Typography>
                  <ProgressBar percentageValue={usagePercentage} color="violet" />
                  <Typography as="p" token="font-normal/text/sm">
                    {activeSubscriptions.toLocaleString()} out of {maxSubscriptions.toLocaleString()} subscribers
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </LoadingBox>
      </Section>
    </>
  );
};

export default UsageInformation;
