import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import {
  BoostAgreement,
  BoostAgreementCreateWithReferralCount,
  BoostAgreementStatus,
} from '@/interfaces/boosts/grow/boost_agreement';
import { BoostOfferStatus } from '@/interfaces/boosts/grow/boost_offer';
import { OrderBy } from '@/interfaces/boosts/order';
import { Order, Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  boost_agreements: BoostAgreement[] | BoostAgreementCreateWithReferralCount[];
  pagination: Pagination;
}

interface Props {
  boostOfferId?: string;
  recommendingPublicationId?: string;
  search?: string;
  perPage?: number;
  status?: BoostAgreementStatus | BoostAgreementStatus[] | null;
  excludeStatus?: BoostAgreementStatus | BoostAgreementStatus[] | null;
  offerStatus?: BoostOfferStatus | 'all';
  orderBy?: OrderBy;
  direction?: Order;
  isEnabled?: boolean;
}

const useBoostAgreements = ({
  boostOfferId,
  recommendingPublicationId,
  excludeStatus,
  perPage = 10,
  search,
  status,
  offerStatus = 'all',
  orderBy = OrderBy.TOTAL_SUBSCRIBER_COUNT,
  direction = Order.DESC,
  isEnabled = true,
}: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBoostAgreements = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/boosts/grow/boost_agreements`, {
        params: {
          boost_offer_id: boostOfferId,
          exclude_status: excludeStatus,
          page: pageParam,
          per_page: perPage,
          publication_id: currentPublicationId,
          recommending_publication_id: recommendingPublicationId,
          q: search,
          status,
          offer_status: offerStatus,
          order_by: orderBy,
          direction,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery(
    [
      currentPublicationId,
      'boosts',
      'grow',
      'boosts_agreements',
      boostOfferId,
      recommendingPublicationId,
      status,
      excludeStatus,
      search,
      offerStatus,
      orderBy,
      direction,
    ],
    fetchBoostAgreements,
    {
      enabled: isEnabled,
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
};

export default useBoostAgreements;
