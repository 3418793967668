import { createContext, ReactNode, useContext } from 'react';

export interface PublicationContextValue {
  publicationId: string;
}

export const PublicationContext = createContext<PublicationContextValue>({
  publicationId: '',
});

export const usePublicationContext = () => useContext(PublicationContext);

export const PublicationProvider = ({ children, id }: { children: ReactNode; id: string }) => {
  return <PublicationContext.Provider value={{ publicationId: id }}>{children}</PublicationContext.Provider>;
};
