import styled from 'styled-components';

const Navigation = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  padding-block: 1.5rem;
`;

const Category = styled.div`
  padding-inline: 1.25rem;
`;

const CategoryTitle = styled.div`
  color: #6b7280;
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  user-select: none;
`;

const CategoryContent = styled.div`
  display: grid;
  grid-gap: 0;
`;

const Styled = {
  Navigation,
  Category,
  CategoryTitle,
  CategoryContent,
};

export default Styled;
