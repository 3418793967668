import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import Grid from '@/components/Grid';
import { usePrompt, useReloadAlert } from '@/hooks';
import { Button } from '@/ui/Button';

import { useTierConfigurationContext } from '../../_context/tier_configuration_context';

import AdvancedSection from './AdvancedSection';
import BenefitsSection from './BenefitsSection';
import DetailsSection from './DetailsSection';
import PricingSection from './PricingSection';
import StepCard from './StepCard';

interface Props {
  isSaving: boolean;
  onSave: () => void;
}

const TierForm: React.FC<Props> = ({ isSaving, onSave }) => {
  const {
    annualCents,
    annualEnabled,
    isDirty,
    monthlyCents,
    monthlyEnabled,
    oneTimeCents,
    oneTimeEnabled,
    donationEnabled,
  } = useTierConfigurationContext();

  // Dirty Prompt
  const [isDirtyPrompting, confirmDirtyPrompt, cancelDirtyPrompt] = usePrompt(isDirty);
  useReloadAlert(!isSaving && isDirty, 'You have unsaved changes. Are you sure you want to leave this page?');

  // Enable Payment Options Prompt
  const [priceNotEnabledWarning, setPriceNotEnabledWarning] = useState(false);
  const enabledNonSubscriptionPrice = oneTimeEnabled || donationEnabled;
  const subscriptionsShouldBeEnabled = (monthlyCents > 0 && !monthlyEnabled) || (annualCents > 0 && !annualEnabled);
  const oneTimePriceShouldBeEnabled = !oneTimeEnabled && oneTimeCents > 0;

  const handleSave = () => {
    if ((!enabledNonSubscriptionPrice && subscriptionsShouldBeEnabled) || oneTimePriceShouldBeEnabled) {
      setPriceNotEnabledWarning(true);
    } else {
      onSave();
    }
  };

  useEffect(() => {
    if (isDirtyPrompting && !isDirty) {
      confirmDirtyPrompt();
    }
  }, [isDirtyPrompting, isDirty, confirmDirtyPrompt]);

  return (
    <>
      <ActionModal
        isOpen={isDirtyPrompting}
        onClose={cancelDirtyPrompt}
        onProceed={confirmDirtyPrompt}
        modalMessageType="warning"
        headerText="Unsaved Changes"
        resourceId="emailMessage"
        actionText="Discard & Leave"
        cancelText="Stay & Save Changes"
        isWorking={false}
      >
        <p className="mb-4">
          It looks like you have unsaved changes on this page. Leaving now will discard any modifications you&apos;ve
          made. What would you like to do?
        </p>
      </ActionModal>

      <ActionModal
        isOpen={priceNotEnabledWarning}
        onClose={() => setPriceNotEnabledWarning(false)}
        onProceed={onSave}
        modalMessageType="warning"
        headerText="Enable Payment Options"
        resourceId="tierPricesNotEnabled"
        actionText="Continue without Enabling"
        cancelText="Okay"
        isWorking={isSaving}
      >
        <div className="space-y-4 mt-4">
          You created but did not enable your payment options.
          <br />
          Please go back and enable your {subscriptionsShouldBeEnabled && 'Subscription Plan'}{' '}
          {subscriptionsShouldBeEnabled && oneTimePriceShouldBeEnabled && 'and/or'}{' '}
          {oneTimePriceShouldBeEnabled && 'One-Time Payment'} before saving the configuration.
        </div>
      </ActionModal>

      <div className="mt-6 mb-28 md:mb-20 lg:mb-12">
        <Grid gap={6}>
          <div className="col-span-12 lg:col-span-8 space-y-6">
            <DetailsSection />
            <PricingSection />
            <BenefitsSection />
            <AdvancedSection />
            <div className="flex justify-end space-x-3">
              <Link to="/settings/publication/premium">
                <Button type="button" variant="primary-inverse">
                  Cancel
                </Button>
              </Link>
              <Button type="button" variant="primary" loading={priceNotEnabledWarning || isSaving} onClick={handleSave}>
                Save Tier Changes
              </Button>
            </div>
          </div>
          <div className="hidden lg:block col-span-4 relative">
            <StepCard />
          </div>
        </Grid>
      </div>
    </>
  );
};

export default TierForm;
