import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { Input } from '@/components/Form';
import { useCopyToClipboard } from '@/hooks';
import { useCreateApiKey } from '@/hooks/useIntegrations';
import { Button } from '@/ui/Button';

interface Props {
  isOpen: boolean;
  close: () => void;
}

const NewApiKey = ({ isOpen, close }: Props) => {
  const [name, setName] = useState<string>('');
  const [newKey, setNewKey] = useState<string>('');

  const copy = useCopyToClipboard();
  const createApiKey = useCreateApiKey({
    onSuccess: (result) => {
      setNewKey(result.data.token);
    },
  });

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    createApiKey.mutateAsync({ name });
  };

  const renderNewKeyForm = () => {
    return (
      <form onSubmit={handleUpdate}>
        <div className="space-y-4 w-full">
          <div className="space-y-4">
            <Input
              className="w-full"
              name="name"
              labelText="Name"
              placeholder="My API Key"
              helperText="This is so you can identify the use case of the API Key"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <div className="w-full flex justify-end space-x-2 pt-4">
              <Button
                type="button"
                variant="primary-inverse"
                onClick={() => {
                  setName('');
                  setNewKey('');
                  close();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" loading={createApiKey.isLoading}>
                <div className="flex">{createApiKey.isLoading ? 'Creating...' : 'Create New Key'}</div>
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  const renderKeyToCopy = () => {
    return (
      <div className="space-y-4">
        <Input
          className="w-full"
          name="name"
          labelText="Copy Your New Key"
          placeholder="My API Key"
          helperText="* Please ensure that you copy this key down. This will not be retrievable after you navigate from this page."
          disabled
          value={newKey}
          onChange={() => {}}
        />
        <div className="w-full flex justify-end space-x-2 pt-4">
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              setName('');
              setNewKey('');
              close();
            }}
          >
            Close
          </Button>
          <Button type="button" variant="primary" onClick={() => copy({ text: newKey })}>
            Copy
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-90"
        onClose={() => {
          setName('');
          setNewKey('');
          close();
        }}
        style={{ zIndex: 999999 }}
      >
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-md pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white p-8 shadow-xl border-l border-gray-200">
                    <div className="flex w-full space-x-2 items-center justify-between pb-4">
                      <h2 className="text-lg leading-6 font-medium text-gray-900 ml-0 flex">New API Key</h2>
                      <button
                        className="h-7 w-7 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                        type="button"
                        onClick={() => {
                          setName('');
                          setNewKey('');
                          close();
                        }}
                      >
                        <XMarkIcon className="h-4 w-4" />
                      </button>
                    </div>
                    {newKey ? renderKeyToCopy() : renderNewKeyForm()}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewApiKey;
