import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import { Typography } from '@/components/Typography';
import api from '@/services/swarm';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  postId: string;
  postsQuery: any;
}

const DeletePostModal = ({ isOpen, setIsOpen, postId, postsQuery }: Props) => {
  const handleOnClose = () => {
    setIsOpen(false);
  };

  const handleOnProceed = () => {
    api
      .delete(`/posts/${postId}`)
      .then(() => {
        setIsOpen(false);
        toast.success('Post deleted');
        postsQuery.refetch();
      })
      .catch(() => {
        toast.error('Could not delete post');
      });
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onProceed={handleOnProceed}
      onClose={handleOnClose}
      resourceId="delete-post-modal"
      isWorking={false}
      actionText="Delete"
      headerText="Delete Post"
    >
      <Typography>Are you sure you want to delete this post?</Typography>
    </ActionModal>
  );
};

export default DeletePostModal;
