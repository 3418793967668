import { useState } from 'react';

import { useSettings } from '@/context/settings-context';
import { StripeAccountStatus, StripeAccountType } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import ConnectToStripe from '@/pages/Monetize/Boosts/components/ConnectToStripe';
import { Section } from '@/pages/Settings/Components';
import { Button } from '@/ui/Button';

interface Props {
  boostsAccountStatus: StripeAccountStatus | undefined;
  boostsAccountType: StripeAccountType | undefined | null;
  isLoading: boolean;
}

const Connect = ({ boostsAccountStatus, boostsAccountType, isLoading }: Props) => {
  const [isConnectingToStripe, setIsConnectingToStripe] = useState<boolean>(false);
  const { settings } = useSettings();

  if (boostsAccountType || boostsAccountStatus !== StripeAccountStatus.MISSING) return null;
  if (!settings?.wallet) return null;

  return (
    <>
      <ConnectToStripe
        boostsAccountStatus={boostsAccountStatus}
        isOpen={isConnectingToStripe}
        onClose={() => setIsConnectingToStripe(false)}
      />
      <Section
        title="Connect Stripe Account"
        description="Create a new Express account"
        scrollToId="connect-stripe-account"
        isLoading={isLoading}
      >
        <div className="flex flex-row space-x-4 mt-8 w-full">
          <Button
            variant="primary"
            type="submit"
            loading={isConnectingToStripe}
            disabled={isConnectingToStripe}
            onClick={() => setIsConnectingToStripe(true)}
          >
            Get Started
          </Button>
        </div>
      </Section>
    </>
  );
};

export default Connect;
