import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Switch } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import RichContent from '@/components/RichContent';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

const CustomFooterEdit: React.FunctionComponent = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  const [fetchingEmail, setFetchingEmail] = useState(true);
  const [active, setActive] = useState(false);
  const [content, setContent] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchCustomFooter = useCallback(() => {
    setFetchingEmail(true);
    const params = { publication_id: currentPublicationId };

    api
      .get(`/custom_footer`, { params })
      .then((res) => {
        setActive(res.data.active);
        setContent(res.data.tiptap_state || {});
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        setIsError(true);
        toast.error(error);
      })
      .finally(() => setFetchingEmail(false));
  }, [currentPublicationId]);

  const onFetchPreview = (_platform: string, onFetch: Function) => {
    const payload = {
      publication_id: currentPublicationId,
      tiptap_state: content,
    };

    api
      .post(`/custom_footer/preview`, payload)
      .then((resp) => {
        onFetch(resp.data.html);
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  };

  const persistCustomFooter = () => {
    setIsSaving(true);

    const payload = {
      publication_id: currentPublicationId,
      custom_footer: {
        status: active,
        tiptap_state: content,
      },
    };

    api
      .patch(`/custom_footer`, payload)
      .then(() => toast.success('Custom Footer saved'))
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    if (currentPublicationId) {
      fetchCustomFooter();
    }
  }, [currentPublicationId, fetchCustomFooter]);

  if (!currentPublicationId) {
    return null;
  }

  const isLoading = fetchingEmail;

  return (
    <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
      <div className="divide-y divide-gray-200">
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900 ml-0 flex">
              Custom Footer {isLoading && <LoadingSpinner className="ml-2" />}
            </h2>
            <p className="mt-1 text-sm text-gray-500">Customize the footer section of your emails.</p>
          </div>
          <div className="mt-12 flex flex-col lg:flex-row">
            <div className="flex-grow space-y-6">
              <Switch
                name="active"
                labelText="Enable Custom Footer Content?"
                checked={active}
                onChange={(_name: string, value: boolean) => setActive(value)}
              />
              <LoadingBox isLoading={isLoading} isError={isError}>
                <RichContent
                  isLoading={isLoading}
                  name="content"
                  labelText="Content"
                  publicationId={currentPublicationId}
                  initialValue={content}
                  onChange={(value) => setContent(value)}
                  onFetchPreview={onFetchPreview}
                />
              </LoadingBox>
            </div>
          </div>
        </div>
        <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
          <Button disabled={isLoading} loading={isSaving} onClick={persistCustomFooter}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomFooterEdit;
