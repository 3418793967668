import { CustomFieldValue } from './custom_field_value';

export enum CustomFieldKind {
  STRING = 'string',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATETIME = 'datetime',
  LIST = 'list',
}

export interface CustomField {
  id: string;
  kind: CustomFieldKind;
  name: string;
  display: string;
  value: CustomFieldValue | undefined;
}
