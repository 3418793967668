export enum PLAN {
  LAUNCH = 'launch',
  SCALE = 'scale',
  MAX = 'max',
  ENTERPRISE = 'enterprise',
}

export const plans = [
  {
    id: PLAN.LAUNCH,
    name: 'Launch',
    logo: (
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="33" fill="string" className="string">
        <path
          fill="#fff"
          stroke="#3843D0"
          strokeWidth="3"
          d="M33.398 13.25a6.512 6.512 0 0 1 0 6.5l-4.887 8.487a6.512 6.512 0 0 1-5.643 3.263h-9.736a6.512 6.512 0 0 1-5.643-3.263L2.602 19.75a6.512 6.512 0 0 1 0-6.498l4.887-8.488A6.512 6.512 0 0 1 13.132 1.5h9.736a6.512 6.512 0 0 1 5.643 3.263l4.887 8.488Z"
        />
      </svg>
    ),
    inclusionDescription: "What's included...",
    features: [
      'Custom Websites',
      'Custom Newsletters',
      'Campaign Analytics',
      'Unlimited Email Sends',
      'Recommendation Network',
      'Optimized Deliverability',
      'Subscriber Tagging',
      'Audience Segmentation',
      'Custom Domains',
      'API Access',
    ],
    tiers: [
      {
        maxSubscribers: 2500,
        priceMonthly: 0,
        priceAnnually: 0,
        pricePerMonthBilledAnnually: '$0',
        pricePerMonthBilledMonthly: '$0',
        pricePerYearBilledAnnually: 'No commitment',
      },
    ],
  },
  {
    id: PLAN.SCALE,
    name: 'Scale',
    logo: (
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="33" fill="string" className="string">
        <path
          fill="#fff"
          stroke="#3843D0"
          strokeWidth="3"
          d="M34.398 13.25a6.512 6.512 0 0 1 0 6.5l-4.887 8.487a6.512 6.512 0 0 1-5.643 3.263h-9.736a6.512 6.512 0 0 1-5.643-3.263L3.602 19.75a6.512 6.512 0 0 1 0-6.498l4.887-8.488A6.512 6.512 0 0 1 14.132 1.5h9.736a6.512 6.512 0 0 1 5.643 3.263l4.887 8.488Z"
        />
        <path
          fill="#fff"
          stroke="#3843D0"
          strokeWidth="3"
          d="M26.378 13.237a6.512 6.512 0 0 1 0 6.526l-.862 1.488a6.512 6.512 0 0 1-5.635 3.249h-1.762a6.512 6.512 0 0 1-5.635-3.25l-.862-1.487a6.512 6.512 0 0 1 0-6.526l.862-1.488A6.512 6.512 0 0 1 18.119 8.5h1.762a6.512 6.512 0 0 1 5.635 3.25l.862 1.487Z"
        />
      </svg>
    ),
    inclusionDescription: 'Everything on Launch +',
    features: [
      'New Website Templates',
      'Email Automations',
      'Referral Program',
      'Ad Network',
      'Boost Network',
      '3D Analytics',
      'beehiiv AI',
      'Segment Sends',
      'Unlimited Teammates',
      'Community Access',
      'Surveys',
    ],
    tiers: [
      {
        maxSubscribers: 1000,
        priceMonthly: 39,
        priceAnnually: 408,
        pricePerMonthBilledAnnually: '$34 per month',
        pricePerMonthBilledMonthly: '$39 per month',
        pricePerYearBilledAnnually: '$408 billed annually',
      },
      {
        maxSubscribers: 2500,
        priceMonthly: 59,
        priceAnnually: 624,
        pricePerMonthBilledAnnually: '$52 per month',
        pricePerMonthBilledMonthly: '$59 per month',
        pricePerYearBilledAnnually: '$624 billed annually',
      },
      {
        maxSubscribers: 5000,
        priceMonthly: 79,
        priceAnnually: 828,
        pricePerMonthBilledAnnually: '$69 per month',
        pricePerMonthBilledMonthly: '$79 per month',
        pricePerYearBilledAnnually: '$828 billed annually',
      },
      {
        maxSubscribers: 10000,
        priceMonthly: 99,
        priceAnnually: 1044,
        pricePerMonthBilledAnnually: '$87 per month',
        pricePerMonthBilledMonthly: '$99 per month',
        pricePerYearBilledAnnually: '$1,044 billed annually',
      },
      {
        maxSubscribers: 25000,
        priceMonthly: 149,
        priceAnnually: 1572,
        pricePerMonthBilledAnnually: '$131 per month',
        pricePerMonthBilledMonthly: '$149 per month',
        pricePerYearBilledAnnually: '$1,572 billed annually',
      },
      {
        maxSubscribers: 50000,
        priceMonthly: 199,
        priceAnnually: 2088,
        pricePerMonthBilledAnnually: '$174 per month',
        pricePerMonthBilledMonthly: '$199 per month',
        pricePerYearBilledAnnually: '$2,088 billed annually',
      },
      {
        maxSubscribers: 75000,
        priceMonthly: 249,
        priceAnnually: 2616,
        pricePerMonthBilledAnnually: '$218 per month',
        pricePerMonthBilledMonthly: '$249 per month',
        pricePerYearBilledAnnually: '$,2616 billed annually',
      },
      {
        maxSubscribers: 100000,
        priceMonthly: 299,
        priceAnnually: 3144,
        pricePerMonthBilledAnnually: '$262 per month',
        pricePerMonthBilledMonthly: '$299 per month',
        pricePerYearBilledAnnually: '$3,144 billed annually',
      },
    ],
  },
  {
    id: PLAN.MAX,
    name: 'MAX',
    logo: (
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="33" fill="string" className="string">
        <path
          fill="#3843D0"
          stroke="#fff"
          strokeWidth="3"
          d="M33.398 13.25a6.512 6.512 0 0 1 0 6.5l-4.887 8.487a6.512 6.512 0 0 1-5.643 3.263h-9.736a6.512 6.512 0 0 1-5.643-3.263L2.602 19.75a6.512 6.512 0 0 1 0-6.498l4.887-8.488A6.512 6.512 0 0 1 13.132 1.5h9.736a6.512 6.512 0 0 1 5.643 3.263l4.887 8.488Z"
        />
      </svg>
    ),
    inclusionDescription: 'Everything on Scale +',
    features: ['Remove beehiiv Branding', 'NewsletterXP Course', 'Up to 10 Publications', 'Priority Support'],
    tiers: [
      {
        maxSubscribers: 1000,
        priceMonthly: 99,
        priceAnnually: 1032,
        pricePerMonthBilledAnnually: '$86 per month',
        pricePerMonthBilledMonthly: '$99 per month',
        pricePerYearBilledAnnually: '$1,032 billed annually',
      },
      {
        maxSubscribers: 2500,
        priceMonthly: 129,
        priceAnnually: 1356,
        pricePerMonthBilledAnnually: '$113 per month',
        pricePerMonthBilledMonthly: '$129 per month',
        pricePerYearBilledAnnually: '$1,356 billed annually',
      },
      {
        maxSubscribers: 5000,
        priceMonthly: 149,
        priceAnnually: 1560,
        pricePerMonthBilledAnnually: '$130 per month',
        pricePerMonthBilledMonthly: '$149 per month',
        pricePerYearBilledAnnually: '$1,560 billed annually',
      },
      {
        maxSubscribers: 10000,
        priceMonthly: 199,
        priceAnnually: 2088,
        pricePerMonthBilledAnnually: '$174 per month',
        pricePerMonthBilledMonthly: '$199 per month',
        pricePerYearBilledAnnually: '$2,088 billed annually',
      },
      {
        maxSubscribers: 25000,
        priceMonthly: 249,
        priceAnnually: 2616,
        pricePerMonthBilledAnnually: '$218 per month',
        pricePerMonthBilledMonthly: '$249 per month',
        pricePerYearBilledAnnually: '$2,616 billed annually',
      },
      {
        maxSubscribers: 50000,
        priceMonthly: 299,
        priceAnnually: 3144,
        pricePerMonthBilledAnnually: '$262 per month',
        pricePerMonthBilledMonthly: '$299 per month',
        pricePerYearBilledAnnually: '$3,144 billed annually',
      },
      {
        maxSubscribers: 75000,
        priceMonthly: 349,
        priceAnnually: 3672,
        pricePerMonthBilledAnnually: '$306 per month',
        pricePerMonthBilledMonthly: '$349 per month',
        pricePerYearBilledAnnually: '$3,672 billed annually',
      },
      {
        maxSubscribers: 100000,
        priceMonthly: 399,
        priceAnnually: 4188,
        pricePerMonthBilledAnnually: '$349 per month',
        pricePerMonthBilledMonthly: '$399 per month',
        pricePerYearBilledAnnually: '$4,188 billed annually',
      },
    ],
  },
  {
    id: PLAN.ENTERPRISE,
    logo: (
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="33" fill="string" className="string">
        <path
          fill="#fff"
          stroke="#3843D0"
          strokeWidth="3"
          d="M34.398 13.25a6.512 6.512 0 0 1 0 6.5l-4.887 8.487a6.512 6.512 0 0 1-5.643 3.263h-9.736a6.512 6.512 0 0 1-5.643-3.263L3.602 19.75a6.512 6.512 0 0 1 0-6.498l4.887-8.488A6.512 6.512 0 0 1 14.132 1.5h9.736a6.512 6.512 0 0 1 5.643 3.263l4.887 8.488Z"
        />
        <path
          fill="#3843D0"
          stroke="#fff"
          strokeWidth="3"
          d="M27.426 13.27a6.512 6.512 0 0 1 0 6.46l-1.422 2.489A6.512 6.512 0 0 1 20.35 25.5h-2.7a6.512 6.512 0 0 1-5.654-3.281l-1.422-2.488a6.512 6.512 0 0 1 0-6.462l1.422-2.488A6.512 6.512 0 0 1 17.649 7.5h2.701a6.512 6.512 0 0 1 5.654 3.281l1.422 2.488Z"
        />
      </svg>
    ),
    name: 'Enterprise',
    inclusionDescription: 'Everything on MAX +',
    features: ['Concierge Onboarding', 'Designated Account Manager', 'Dedicated IP Address', 'Infinite Subscribers'],
    tiers: [
      {
        maxSubscribers: 999999999999,
        priceMonthly: 499,
        priceAnnually: 5988,
        pricePerMonthBilledAnnually: 'Custom Pricing',
        pricePerMonthBilledMonthly: 'Custom Pricing',
        pricePerYearBilledAnnually: '',
      },
    ],
  },
];

export const getLowestTier = (plan: PLAN, maxSubscribers: number) => {
  const lowestTier = plans.find((p) => p.id === plan)?.tiers.find((t) => t.maxSubscribers >= maxSubscribers);

  if (!lowestTier) {
    return { ...plans.find((p) => p.id === plan)?.tiers[0], disabled: true };
  }

  return { ...lowestTier, disabled: false };
};
