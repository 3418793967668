'use client';

import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '../../_utils/cn';

import { Label } from './Label';

type SwitchProps = React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
  labelText?: string;
} & ({ labelText: string; id: string } | { labelText?: undefined; id?: undefined });

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
  ({ className, labelText, id, ...props }, ref) => (
    <div className="flex items-center space-x-2">
      <SwitchPrimitives.Root
        className={cn(
          'peer inline-flex h-[24px] w-[44px] p-0 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-wb-accent data-[state=unchecked]:bg-wb-surface',
          className
        )}
        {...props}
        id={id}
        ref={ref}
      >
        <SwitchPrimitives.Thumb
          className={cn(
            'pointer-events-none block h-5 w-5 rounded-full bg-wb-primary shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0'
          )}
        />
      </SwitchPrimitives.Root>
      {labelText && <Label htmlFor={id}>{labelText}</Label>}
    </div>
  )
);
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
