import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import moment from 'moment-mini';

import Icon from '@/components/TiptapEditor/components/ui/Icon';
import API from '@/components/TiptapEditor/lib/api';
import { useCurrentPublication } from '@/hooks';

interface Props {
  setShowAdsBanner: (showAdsBanner: boolean) => void;
}

const AdsBanner = ({ setShowAdsBanner }: Props) => {
  const { data: publication } = useCurrentPublication();

  const [adOpportunities, setAdOpportunities] = useState<
    Array<{ id: string; advertiser_name: string; selected_date: string }>
  >([]);

  useEffect(() => {
    API.getAdvertisementOpportunitiesOptions({
      publicationId: publication?.id || '',
      page: 1,
    })
      .then((res) => {
        setAdOpportunities(res.data.options);
      })
      .catch((errPayload: AxiosError) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, [publication]);

  return (
    <div className="relative z-0 px-2">
      <div className="mx-auto my-6 max-w-3xl">
        <div className="bg-black bg-opacity-[0.04] p-4 rounded-lg w-full">
          <div className="flex w-full justify-between">
            <div>
              <p className="text-sm font-medium">You have 2 ads available</p>
              <p className="text-sm text-gray-700">
                Drag and drop or just type <span className="font-medium text-black">/ads</span> to add.
              </p>
            </div>
            <div>
              <button
                className="border-0 bg-none ml-auto flex align-middle justify-center"
                type="button"
                onClick={() => {
                  setShowAdsBanner(false);
                }}
              >
                <Icon name="Close" className="text-gray-500" />
              </button>
            </div>
          </div>
          <div className="flex w-full mt-4">
            {adOpportunities.map((ad) => (
              <div
                key={ad.id}
                className="flex pl-1 pr-3 pt-1 border-gray-300 border rounded-lg cursor-grab active:cursor-grabbing mr-4"
                draggable="true"
                onDragStart={(event) => {
                  event.dataTransfer?.setData('text/plain', `${JSON.stringify({ adDrag: true, id: ad.id })}`);
                }}
              >
                <div className="mr-2">
                  <Icon name="DragMenu" className="text-gray-500" />
                </div>
                <div className="text-sm font-medium">
                  {ad.advertiser_name} / {moment(ad.selected_date).format('MMM Do')}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdsBanner;
