import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { DonutChart, Legend } from '@tremor/react';

import Card from '@/components/Card';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentTimeZone } from '@/hooks';
import api from '@/services/swarm';
import { TimePeriod } from '@/utils';

type Datum = {
  name: string;
  value: number;
};

type ResponseType = {
  countryCode: string;
  countryCount: number;
};

const defaultCount: Datum[] = [];

const formatResponse = (data: ResponseType[]): Datum[] =>
  data.map((item) => ({ name: item.countryCode, value: item.countryCount }));

const SubscriptionsByCountry = ({ period }: { period?: TimePeriod }) => {
  const currentTimeZone = useCurrentTimeZone();
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState<Datum[] | []>(defaultCount);
  const [categories, setCategories] = useState<string[]>([]);
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchCounts = useCallback(() => {
    const params = {
      period,
      time_zone: currentTimeZone,
    };

    if (currentPublicationId) {
      setIsLoading(true);
      api
        .get(`/publications/${currentPublicationId}/subscription_countries`, { params })
        .then((data) => formatResponse(data.data))
        .then((newCount) => {
          setCount(newCount);

          const newCategories = newCount.map((item) => `${item.name} (${item.value})`);
          setCategories(newCategories);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentPublicationId, currentTimeZone, period]);

  useEffect(() => {
    fetchCounts();
  }, [fetchCounts]);

  return (
    <Card title="Premium Subscribers By Country" isLoading={isLoading} className="relative w-full h-full">
      <DonutChart
        data={count}
        category="value"
        index="name"
        colors={['pink', 'blue', 'indigo', 'amber', 'cyan', 'emerald', 'fuchsia', 'orange']}
      />
      <Legend
        categories={categories}
        colors={['pink', 'blue', 'indigo', 'amber', 'cyan', 'emerald', 'fuchsia', 'orange']}
        className="w-full justify-center"
      />
    </Card>
  );
};

export default SubscriptionsByCountry;
