import { useTiers } from '@/hooks/useTiers';
import { UpgradePage } from '@/interfaces/upgrade_page';
import {
  NavSectionIcon,
  SectionGroupingSlideOut,
} from '@/routes/website_builder/_components/Helpers/SectionGroupingSlideOut';
import useRecommendedPriceOptions from '@/routes/website_builder/_hooks/useRecommendedPriceOptions';

import FontForm from '../../Form/PageSpecific/FontForm';
import TextareaHelper from '../../Form/PageSpecific/TextAreaHelper';
import SelectInput from '../../Form/SelectInput';
import SwitchInput from '../../Form/SwitchInput';
import PropertiesGrouping from '../../Helpers/PropertiesGrouping';

import Features from './Features';
import SortableTiers from './SortableTiers';

interface Props {
  upgradePage: UpgradePage;
  handleSave: (payload: any) => void;
}

const TiersSection = ({ upgradePage, handleSave }: Props) => {
  const mainSectionWithDefaults = upgradePage.main_section_with_defaults;
  const { data: tiers, isLoading: isLoadingTiers } = useTiers({ onlyActive: true });
  const recommendedPriceOptions = useRecommendedPriceOptions(tiers || []);

  return (
    <SectionGroupingSlideOut
      title="Hero Tiers Section"
      showPlanBadge
      sectionIcon={<NavSectionIcon />}
      highlightProps={{
        sectionId: 'upgrade-tiers-section',
      }}
    >
      <PropertiesGrouping
        label="Checkout Experience"
        tooltip={{
          id: 'upgrade-page-checkout-cta-font-form-tooltip',
          text: 'Enabling this will redirect users to an external Stripe checkout page for payments.',
        }}
      >
        <SwitchInput
          label="External Stripe Checkout Enabled"
          name="main-section-external-stripe-checkout-enabled"
          enabled={mainSectionWithDefaults.enable_external_stripe_checkout}
          onSave={(value: boolean) => {
            handleSave({
              ...upgradePage?.main_section,
              enable_external_stripe_checkout: value,
            });
          }}
        />
        {!mainSectionWithDefaults.enable_external_stripe_checkout && (
          <FontForm
            nodeId="upgrade-page-checkout-cta-font-form"
            label="Payment Form"
            onSave={(key, value) => {
              handleSave({
                ...upgradePage?.main_section,
                [key]: value,
              });
            }}
            textValue={mainSectionWithDefaults.checkout_cta_text}
            onSaveText={(value: string) => {
              handleSave({
                ...upgradePage?.main_section,
                checkout_cta_text: value,
              });
            }}
            editableProps={{
              checkout_cta_bg_color: {
                type: 'colorInput',
                label: 'Button Background Color',
                value: mainSectionWithDefaults.checkout_cta_bg_color,
                resetCheckValue: upgradePage?.main_section.checkout_cta_bg_color,
                resetLabel: 'Global Primary Color',
              },
              checkout_cta_text_color: {
                type: 'colorInput',
                label: 'Button Text Color',
                value: mainSectionWithDefaults.checkout_cta_text_color,
                resetCheckValue: upgradePage?.main_section.checkout_cta_text_color,
                resetLabel: 'Global Text on Primary Color',
              },
            }}
          />
        )}
      </PropertiesGrouping>

      <PropertiesGrouping label="Cards">
        <FontForm
          nodeId="upgrade-page-cards-font-form"
          label="Cards"
          onSave={(key, value) => {
            handleSave({
              ...upgradePage?.main_section,
              [key]: value,
            });
          }}
          editableProps={{
            card_font_family: {
              type: 'fontFamilySelect',
              value: mainSectionWithDefaults.card_font_family,
              resetCheckValue: upgradePage?.main_section.card_font_family,
              resetLabel: 'Global Heading Font',
            },
            card_price_font_size: {
              type: 'fontSizeSelect',
              label: 'Price Font Size',
              value: mainSectionWithDefaults.card_price_font_size,
              resetCheckValue: upgradePage?.main_section.card_price_font_size,
            },
            card_bg_color: {
              type: 'colorInput',
              label: 'Background Color',
              value: mainSectionWithDefaults.card_bg_color,
              resetCheckValue: upgradePage?.main_section.card_bg_color,
              resetLabel: 'Global Background Color',
            },
            card_accent_color: {
              type: 'colorInput',
              label: 'Accent Color',
              value: mainSectionWithDefaults.card_accent_color,
              resetCheckValue: upgradePage?.main_section.card_accent_color,
              resetLabel: 'Global Primary Color',
            },
            card_text_color: {
              type: 'colorInput',
              label: 'Text Color',
              value: mainSectionWithDefaults.card_text_color,
              resetCheckValue: upgradePage?.main_section.card_text_color,
              resetLabel: 'Global Body Color',
            },
            card_border_color: {
              type: 'colorInput',
              label: 'Border Color',
              value: mainSectionWithDefaults.card_border_color,
              resetCheckValue: upgradePage?.main_section.card_border_color,
              resetLabel: 'Global Border Color',
            },
            card_tab_bg_color: {
              type: 'colorInput',
              label: 'Tab Background Color',
              value: mainSectionWithDefaults.card_tab_bg_color,
              resetCheckValue: upgradePage?.main_section.card_tab_bg_color,
              resetLabel: 'Global Primary Color',
            },
            card_tab_pill_bg_color: {
              type: 'colorInput',
              label: 'Tab Pill Background Color',
              value: mainSectionWithDefaults.card_tab_pill_bg_color,
              resetCheckValue: upgradePage?.main_section.card_tab_pill_bg_color,
              resetLabel: 'Global Primary Color',
            },
            card_tab_text_color: {
              type: 'colorInput',
              label: 'Tab Text Color',
              value: mainSectionWithDefaults.card_tab_text_color,
              resetCheckValue: upgradePage?.main_section.card_tab_text_color,
              resetLabel: 'Global Text on Primary Color',
            },
          }}
        />
      </PropertiesGrouping>

      <PropertiesGrouping
        label="Recommended Plan"
        exclamationNote={recommendedPriceOptions.length === 0 ? "You don't have any active tiers" : undefined}
      >
        <SelectInput
          label="Tier/Price"
          disabled={recommendedPriceOptions.length === 0}
          options={recommendedPriceOptions}
          value={mainSectionWithDefaults.most_popular_price_id}
          onSave={(value: string) => {
            handleSave({
              ...upgradePage?.main_section,
              most_popular_price_id: value,
            });
          }}
        />

        {!isLoadingTiers && tiers && tiers.length > 1 && !mainSectionWithDefaults.most_popular_price_id && (
          <PropertiesGrouping label="Cards Order">
            <SortableTiers
              tiersOrder={mainSectionWithDefaults.tiers_order}
              isFreeTierEnabled={mainSectionWithDefaults.enable_free_card}
              tiers={tiers}
              onSave={(newList: string[]) => {
                handleSave({
                  ...upgradePage?.main_section,
                  tiers_order: newList,
                });
              }}
            />
          </PropertiesGrouping>
        )}
      </PropertiesGrouping>

      <PropertiesGrouping label="Free Card">
        <SwitchInput
          label="Enabled"
          name="main-section-free-card-enabled"
          enabled={mainSectionWithDefaults.enable_free_card}
          onSave={(value: boolean) => {
            handleSave({
              ...upgradePage?.main_section,
              enable_free_card: value,
            });
          }}
        />

        {mainSectionWithDefaults.enable_free_card && (
          <>
            <TextareaHelper
              label="Label Text"
              value={mainSectionWithDefaults.free_card_label_text}
              rows={1}
              onSave={(value: string) => {
                handleSave({
                  ...upgradePage?.main_section,
                  free_card_label_text: value,
                });
              }}
              supportsMarkdown={false}
              showActionChild={false}
            />

            <Features
              features={mainSectionWithDefaults.free_card_features}
              onUpdate={(newList: string[]) => {
                handleSave({
                  ...upgradePage?.main_section,
                  free_card_features: newList,
                });
              }}
            />
          </>
        )}
      </PropertiesGrouping>
    </SectionGroupingSlideOut>
  );
};

export default TiersSection;
