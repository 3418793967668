import React, { ReactNode } from 'react';
import { IconComponent } from '@heroicons/react';

import { ButtonProps } from '@/ui/Button';

export interface Tab {
  name: string;
  label: React.ReactNode;
  disabled?: boolean;
  disableWith?: string;
  Icon?: IconComponent;
  selected: boolean;
  targetRoute?: string;
  onSelect?: () => void;
  count?: number;
  notificationVariant?: 'solid' | 'pulse' | 'ping';
  newUntil?: string | Date;
  url?: string;
  target?: string;
  rel?: string;
  upgradeIntent?: React.ReactNode;
}

export interface SideTab {
  name: string;
  label: string;
  targetRoute: string;
  icon: IconComponent;
  hardRedirect?: boolean;
}

export interface Pagination {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

export interface Option<T = string> {
  label: string;
  value: T;
  colorClassName?: string;
  danger?: boolean;
  description?: string;
  icon?: IconComponent;
  isOptGroup?: boolean;
  subItems?: Option[];
  subtitle?: string;
  optionSuffix?: ReactNode;
  optionAction?: ReactNode;
  textColor?: string;
  topBorder?: boolean;
}

export interface CustomFieldOption {
  label: string;
  value: string;
  kind?: string;
}

export interface EllipsisDropdownOption {
  label: string;
  onClick: Function;
  labelClass?: string;
}

export interface ButtonGroupOption {
  label: string;
  onClick: Function;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  disabled?: boolean;
  infoText?: string;
}

export interface FilterDropdownOption {
  label: string;
  onClick: Function;
}

export interface IData {
  [key: string]: any;
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Sort {
  order: string | undefined;
  dir: Order;
}

export enum Duration {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}
