import Text from '@/components/Text';
import { useCurrentPublication } from '@/hooks';
import { useTiers } from '@/hooks/useTiers';
import { PostTarget, PostTargetPlatform, PostTargetTier } from '@/interfaces/post_target';

import ManagedAudienceOption from './ManagedAudienceOption';

interface ManagedAudiencesProps {
  postSendTargets: PostTarget[];
  readOnly: boolean;
  platform: PostTargetPlatform;
}

const ManagedAudiences: React.FC<ManagedAudiencesProps> = ({ postSendTargets, readOnly, platform }) => {
  const { data: publication } = useCurrentPublication();
  const tiersQuery = useTiers();
  const tiers = tiersQuery.data || [];
  const tierOptions = tiers.map((t) => ({
    name: t.name,
    id: t.id,
  }));
  const allPremiumPostTarget = postSendTargets.find(
    (target) =>
      target.receiver_type === 'Publication' &&
      target.tier === PostTargetTier.PREMIUM &&
      target.tier_id === PostTargetTier.PREMIUM
  );

  return (
    <div className="space-y-4 px-4">
      <Text size="sm" type="bold">
        Default Audiences
      </Text>
      {/* Editing Before Published */}

      <ManagedAudienceOption
        postSendTargets={postSendTargets}
        tier={{ name: 'Free', id: 'free' }}
        readOnly={readOnly}
        platform={platform}
      />
      {publication?.is_premium_enabled && (
        <ManagedAudienceOption
          postSendTargets={postSendTargets}
          tier={{ name: 'Premium', id: 'premium' }}
          readOnly={readOnly}
          platform={platform}
        />
      )}
      {publication?.is_premium_enabled && (
        <>
          <hr />
          <Text size="sm" type="bold">
            Premium Tiers
          </Text>
          {tierOptions.map((t) => (
            <ManagedAudienceOption
              key={t.id}
              postSendTargets={postSendTargets}
              tier={t}
              readOnly={readOnly}
              disabled={!!allPremiumPostTarget}
              platform={platform}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default ManagedAudiences;
