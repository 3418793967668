import { Typography, TypographyRow } from '@/components/Typography';
import { Card } from '@/ui/Card';

import { FORM_STEP_CLASSES } from '../../_hooks/useTierFormStep';

const StepCard = () => {
  return (
    <Card density="wide" className="sticky top-6">
      <div className="space-y-6">
        <TypographyRow gap="2" className={`p-2 rounded-md ${FORM_STEP_CLASSES.details}`}>
          <div className="w-6 h-6 border-2 border-surface-200 rounded-full flex items-center justify-center">
            <Typography token="font-normal/text/sm" colorWeight="400" className="leading-3">
              1
            </Typography>
          </div>
          <Typography colorWeight="400">Tier Details</Typography>
        </TypographyRow>
        <TypographyRow gap="2" className={`p-2 rounded-md ${FORM_STEP_CLASSES.pricing}`}>
          <div className="w-6 h-6 border-2 border-surface-200 rounded-full flex items-center justify-center">
            <Typography token="font-normal/text/sm" colorWeight="400" className="leading-3">
              2
            </Typography>
          </div>
          <Typography colorWeight="400">Plan & Pricing</Typography>
        </TypographyRow>
        <TypographyRow gap="2" className={`p-2 rounded-md ${FORM_STEP_CLASSES.benefits}`}>
          <div className="w-6 h-6 border-2 border-surface-200 rounded-full flex items-center justify-center">
            <Typography token="font-normal/text/sm" colorWeight="400" className="leading-3">
              3
            </Typography>
          </div>
          <Typography colorWeight="400">Plan Benefits (optional)</Typography>
        </TypographyRow>
        {/* TODO: Unhide this when the redirect_url is implemented in Orchid */}
        {/* <TypographyRow gap="2" className={`p-2 rounded-md ${FORM_STEP_CLASSES.advanced}`}>
          <div className="w-6 h-6 border-2 border-surface-200 rounded-full flex items-center justify-center">
            <Typography token="font-normal/text/sm" colorWeight="400" className="leading-3">
              4
            </Typography>
          </div>
          <Typography colorWeight="400">Advanced Options (optional)</Typography>
        </TypographyRow> */}
      </div>
    </Card>
  );
};

export default StepCard;
