import Drawer from '@/components/Drawer';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import { useCopyToClipboard } from '@/hooks';
import { Section } from '@/pages/Settings/Components';
import NotEditing from '@/pages/Settings/Components/NotEditing';

import ApiKeysSection from './ApiKeysSection';

const Api = () => {
  const { settings } = useSettings();
  const [currentPublicationId] = useCurrentPublicationState();
  const prefixedPublicationId = `pub_${currentPublicationId}`;
  const copy = useCopyToClipboard();

  return (
    <>
      {/* Publication ID Section */}
      <Section
        title="Publication Id"
        description="The following ID is used to identify your publication when you're using our API."
        scrollToId="publication-id"
      >
        <div>
          {settings?.api_v1 && (
            <Drawer className="border-b" active={settings?.api_v1 && !settings?.api_v2}>
              <Drawer.Title>
                <div className="flex items-center ml-2">
                  <div>API V1</div>
                </div>
              </Drawer.Title>
              <Drawer.Content className="block p-4">
                <div className="flex justify-between">
                  <NotEditing
                    label="Publication ID"
                    value={currentPublicationId}
                    helperText="The following ID is used to identify your publication when you're using our API."
                  />
                  <div className="pt-3">
                    <button
                      type="button"
                      onClick={() => copy({ text: currentPublicationId })}
                      className="text-primary-500 flex items-center space-x-1 py-1 px-2 rounded hover:bg-gray-100"
                    >
                      <span className="font-semibold text-sm">Copy</span>
                    </button>
                  </div>
                </div>
              </Drawer.Content>
            </Drawer>
          )}
          {settings?.api_v2 && (
            <Drawer className="border-b" active>
              <Drawer.Title>
                <div className="flex items-center ml-2">
                  <div>API V2</div>
                </div>
              </Drawer.Title>
              <Drawer.Content className="block p-4">
                <div className="flex justify-between">
                  <NotEditing
                    label="Publication ID"
                    value={prefixedPublicationId}
                    helperText="The following ID is used to identify your publication when you're using our API."
                  />
                  <div className="pt-3">
                    <button
                      type="button"
                      onClick={() => copy({ text: prefixedPublicationId })}
                      className="text-primary-500 flex items-center space-x-1 py-1 px-2 rounded hover:bg-gray-100"
                    >
                      <span className="font-semibold text-sm">Copy</span>
                    </button>
                  </div>
                </div>
              </Drawer.Content>
            </Drawer>
          )}
        </div>
      </Section>

      {/* API Keys Section */}
      {(settings?.api_v1 || settings?.api_v2) && (
        <Section
          title="API Keys"
          description="Create API Keys that can be used to integrate beehiiv with other applications"
          scrollToId="api-keys"
          hasDivider={false}
        >
          <ApiKeysSection />
        </Section>
      )}
    </>
  );
};

export default Api;
