import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Button } from '@/ui/Button';

import PostSelect from '../../../../../components/PostSelect';
import SortFeaturedPosts from '../../../../../components/SortableList/SortableLists/SortFeaturedPosts';
import { useUpdateWebTemplate } from '../../../../../hooks';
import { PartialPost, Post } from '../../../../../interfaces/post';
import { SaveButton } from '../../../Components';

type FeaturedPost = Post | PartialPost;

interface Props {
  webTemplateRequest: any;
  onSuccess?: () => void;

  autoSave?: boolean;
}

const ALLOWED_FEATURED_POSTS = 6;

const FeaturedPostsSection = ({ webTemplateRequest, onSuccess, autoSave = false }: Props) => {
  const webTemplateMutation = useUpdateWebTemplate();

  const webTemplate = webTemplateRequest?.data;
  const iniitalTheme = webTemplate?.theme || '';

  const initialFeaturedPosts = webTemplate?.feature_post_ids;

  const [featuredPostsToUpdate, setFeaturedPostsToUpdate] = useState<string[]>([]);

  const [addNewFeaturedPost, setAddNewFeaturedPost] = useState<boolean>(
    initialFeaturedPosts?.length < ALLOWED_FEATURED_POSTS
  );
  const [currentPost, setCurrentPost] = useState<PartialPost | null>();
  const [hasChangeHappened, setHasChangeHappened] = useState<boolean>(false);

  useEffect(() => {
    if (initialFeaturedPosts?.length) {
      setFeaturedPostsToUpdate(initialFeaturedPosts.map((featuredPost: Post) => featuredPost.id));
    }
  }, [initialFeaturedPosts]);

  const onSave = async (list?: any) => {
    // This is a slightly retrofitted solution to avoid rebuilding this full UI for another purpose.
    // In the future, the overall component/pattern could be a good candidate for a refactor.
    let listToSave;
    if (autoSave) {
      listToSave = list;
    } else {
      listToSave = featuredPostsToUpdate;
    }

    try {
      await webTemplateMutation.mutateAsync({
        theme: iniitalTheme,
        feature_post_ids: listToSave,
      });

      toast.success('Saved!');
      setHasChangeHappened(false);

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      toast.success('There was a problem saving!');
    }
  };

  const handleItemRemoved = (newList: string[]) => {
    setFeaturedPostsToUpdate(newList);
    setHasChangeHappened(true);
    if (autoSave) {
      onSave(newList);
      setHasChangeHappened(false);
    }
  };

  const handleItemsOrdered = (newList: string[]) => {
    setFeaturedPostsToUpdate(newList);
    setHasChangeHappened(true);
    if (autoSave) {
      onSave(newList);
      setHasChangeHappened(false);
    }
  };

  const handleItemAppended = (newList: FeaturedPost[]) => {
    const listOfIds = newList.map((featuredPost: FeaturedPost) => featuredPost.id);
    setCurrentPost(null);
    setAddNewFeaturedPost(false);
    setFeaturedPostsToUpdate(listOfIds);
    setHasChangeHappened(true);
    if (autoSave) {
      onSave(listOfIds);
      setHasChangeHappened(false);
    }
  };

  return (
    <div className="flex justify-between space-x-4">
      <div className="w-full flex flex-col justify-start space-y-4 -mt-3">
        <div className={cx(!featuredPostsToUpdate.length ? 'hidden' : '')}>
          <SortFeaturedPosts
            featuredPosts={initialFeaturedPosts || []}
            onItemRemoved={(newList: any) => handleItemRemoved(newList)}
            onItemsOrdered={(newList: any) => handleItemsOrdered(newList)}
            itemToAppend={currentPost}
            onItemAppended={(newList: any) => handleItemAppended(newList)}
          />
        </div>
        {!addNewFeaturedPost ? (
          <div className="flex flex-col">
            <div
              className={cx(
                featuredPostsToUpdate.length && featuredPostsToUpdate.length >= ALLOWED_FEATURED_POSTS
                  ? 'opacity-40 pointer-events-none'
                  : ''
              )}
            >
              <Button variant="primary-inverse" onClick={() => setAddNewFeaturedPost(true)} type="button">
                Add Post
                <PlusIcon className="ml-1 h-4 w-4" />
              </Button>
            </div>
            {featuredPostsToUpdate.length && featuredPostsToUpdate.length >= ALLOWED_FEATURED_POSTS ? (
              <span className="mt-2 text-xs text-red-500">Please remove at least 1 post before adding another.</span>
            ) : null}
          </div>
        ) : (
          <div className="flex items-center space-x-4">
            <div className="w-full z-80">
              <PostSelect
                dropdownClassnames="h-40"
                labelText="Add Featured Post"
                onSelectFullPost={(post: any) => setCurrentPost(post)}
                onSelectPost={() => null}
                onClearPost={() => setCurrentPost(null)}
                livePostsOnly
                helperText={"Please select a live post to feature on your website's homepage."}
              />
            </div>
            <button
              type="button"
              aria-label="close"
              className="text-gray-400 hover:text-gray-700 cursor-pointer w-fit h-fit bg-transparent rounded-full p-1 hover:bg-gray-100"
              onClick={() => setAddNewFeaturedPost(false)}
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
          </div>
        )}
      </div>

      {!autoSave && (
        <div className="w-12">
          <SaveButton
            isSaving={webTemplateMutation.isLoading}
            isEditing={hasChangeHappened}
            changesDetected={hasChangeHappened}
            type="button"
            onClick={onSave}
          />
        </div>
      )}
    </div>
  );
};

export default FeaturedPostsSection;
