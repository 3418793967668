import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Tier } from '@/interfaces/tier';
import api from '@/services/swarm';

const useTiers = ({
  onlyActive = false,
  excludeDonation = false,
  requireRecurringPrice = false,
  includeActiveSubscriptionsCount = false,
}: {
  onlyActive?: boolean;
  requireRecurringPrice?: boolean;
  excludeDonation?: boolean;
  includeActiveSubscriptionsCount?: boolean;
} = {}) => {
  const [currentPublicationId] = useCurrentPublicationState();
  return useQuery<Tier[]>(
    ['publication_settings', 'premium', 'tiers', currentPublicationId, onlyActive, requireRecurringPrice],
    () =>
      api
        .get(`/tiers`, {
          params: {
            publication_id: currentPublicationId,
            only_active: onlyActive,
            exclude_donation: excludeDonation,
            require_recurring_price: requireRecurringPrice,
            include_active_subscriptions_count: includeActiveSubscriptionsCount,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!currentPublicationId,
    }
  );
};

export default useTiers;
