import { useState } from 'react';
import { useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';
import { SimpleSelect } from '@/components/Form';
import useDisbursementTransition from '@/hooks/useAdNetwork/internal/useDisbursementTransition';
import { AdNetworkDisbursement } from '@/interfaces/ad_network/internal/disbursement';

interface Props {
  disbursement: AdNetworkDisbursement;
  modalOpen: boolean;
  onClose: () => void;
}

const DisbursementManualModal = ({ disbursement, modalOpen, onClose }: Props) => {
  const [isApproving, setIsApproving] = useState(false);
  const [manualPayoutReason, setManualPayoutReason] = useState('');
  const modalId = `approve-disbursement-${disbursement.id}`;
  const queryClient = useQueryClient();

  const handleClose = () => {
    setManualPayoutReason('');
    onClose();
  };

  const transitionDisbursement = useDisbursementTransition({
    id: disbursement.id,
    onSuccess: () => {
      handleClose();
      setIsApproving(false);
      queryClient.invalidateQueries(['ad_network', 'internal', 'disbursements']);
    },
  });

  const handleTransitionDisbursement = () => {
    setIsApproving(true);
    transitionDisbursement.mutateAsync({ status: 'manual', manualPayoutReason });
  };

  return (
    <ActionModal
      resourceId={disbursement.id}
      isOpen={modalOpen}
      isWorking={isApproving}
      onClose={handleClose}
      onProceed={handleTransitionDisbursement}
      headerText="Mark Disbursement as Manually Paid"
      actionText="Confirm"
      disabled={isApproving || !manualPayoutReason}
      bodyId={modalId}
    >
      <p className="pt-4">Are you sure you want to mark this disbursement to be paid outside of beehiiv?</p>
      <p>This can always be undone later.</p>
      <SimpleSelect
        name="manualPayoutReason"
        labelText="Reason for Manual Payout"
        value={manualPayoutReason}
        onSelect={(_name, value) => setManualPayoutReason(value)}
        options={[
          { label: 'Country does not allow Stripe', value: 'country does not allow stripe' },
          { label: 'Manually added ad unit to post', value: 'manually added ad unit to post' },
        ]}
        placeholderText="Select a reason for manual payout"
        className="mt-4 pb-2"
        required
      />
    </ActionModal>
  );
};

export default DisbursementManualModal;
