import { ChangeEventHandler, useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import { Textarea } from '@/components/Form';
import { Typography } from '@/components/Typography';
import useApplicationMetrics from '@/hooks/boosts/grow/useApplicationMetrics';
import { useCreateAbuseReport } from '@/hooks/useAbuseReports';
import { BoostPublication } from '@/interfaces/boosts/grow/boost_publication';

import PublicationDetails from '../Dashboard/components/PublicationDetails';

interface Props {
  recommendingPublication: BoostPublication;
  boostAgreementId?: string;
  isVisible: boolean;
  onConfirm?: () => void;
  onClose: () => void;
}

const ReportPublicationModal = ({
  recommendingPublication,
  boostAgreementId,
  isVisible,
  onConfirm,
  onClose,
}: Props) => {
  const [abuseReportReason, setAbuseReportReason] = useState('');

  const { data } = useApplicationMetrics(boostAgreementId, isVisible && !!boostAgreementId);
  const applicantMetrics = data?.metrics;

  const { mutateAsync: createAbuseReport, isLoading: isReportLoading } = useCreateAbuseReport({});

  const handleChangeInAbuseReportTextarea: ChangeEventHandler<HTMLTextAreaElement> = (event) =>
    setAbuseReportReason(event.target.value);

  const handleClickConfirmReport = async () => {
    try {
      await createAbuseReport({
        publication_id: recommendingPublication.id,
        reason: abuseReportReason,
      });
      onConfirm?.();
      toast.success('Report submitted');
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };

  return (
    <ActionModal
      isOpen={isVisible}
      onClose={onClose}
      resourceId="confirm-report-boost-agreement"
      headerText="Report Publication"
      onProceed={handleClickConfirmReport}
      isWorking={isReportLoading}
    >
      {recommendingPublication && (
        <PublicationDetails
          logoUrl={recommendingPublication.logo_url}
          name={recommendingPublication.name}
          url={recommendingPublication.url}
          description={recommendingPublication.description}
        />
      )}

      <Textarea
        value={abuseReportReason}
        onChange={handleChangeInAbuseReportTextarea}
        labelText="Reason"
        name="abuse_report_reason"
      />

      {applicantMetrics && applicantMetrics.post_count < 5 && (
        <div className="px-4 py-2 bg-yellow-50 rounded-md">
          <Typography size="xs" weight="semibold">
            If you believe this publication is exhibiting any fraudulent behavior, which could include bots, spam
            signups, suspicious content, you can report them for our team to review.
          </Typography>
        </div>
      )}
    </ActionModal>
  );
};

export default ReportPublicationModal;
