export enum CardDensity {
  default = 'default',
  compact = 'compact',
  wide = 'wide',
  none = 'none',
}

export interface CardProps {
  variant?: 'primary' | 'secondary' | 'success';
  density?: CardDensity | keyof typeof CardDensity;
  children?: React.ReactNode;
  className?: string;
  backgroundColor?: string;
  id?: string;
}
