import { CustomDomain } from '../../interfaces/custom_domain';

import useCustomDomains from './useCustomDomains';

const useVerifiedCustomDomains = () => {
  const { data: customDomains } = useCustomDomains();
  return {
    data: customDomains?.filter((customDomain: CustomDomain) => customDomain.verified),
  };
};

export default useVerifiedCustomDomains;
