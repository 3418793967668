import { TiptapState } from './editor_versions';

export enum EmailMessagePurpose {
  WELCOME_EMAIL = 'welcome_email',
  BLAST = 'blast',
  AUTOMATION_STEP = 'automation_step',
  OPT_IN_EMAIL = 'opt_in_email',
}

export enum EmailMessageType {
  WELCOME_EMAIL_MESSAGE = 'WelcomeEmailMessage',
  EMAIL_BLAST_MESSAGE = 'EmailBlastMessage',
  AUTOMATION_STEP_MESSAGE = 'AutomationStepMessage',
  OPT_IN_EMAIL_MESSAGE = 'OptInEmailMessage',
}

export enum EmailMessageStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum EmailMessageResourceType {
  PUBLICATION = 'Publication',
  SEGMENT = 'Segment',
  AUTOMATION_STEP = 'AutomationStep',
}

export interface EmailMessage {
  id: string;
  tiptap_state: TiptapState;
  publication_id: string;
  subject_line: string | null;
  preview_text: string | null;
  type: EmailMessageType;
  message_purpose: EmailMessagePurpose;
  status: EmailMessageStatus;
  active: boolean;
  resource_id: string;
  resource_type: string;
  resource_display?: string;
  last_sent_at: string | null;
  updated_at: string;
  post_poll_ids: string[];
  editing_locked: boolean;
  editing_locked_reason?: string;
}

export interface EmailMessageStats {
  total_sent: number;
  total_delivered: number;
  total_unique_opened: number;
  total_errored: number;
  total_spam_reported: number;
  total_unsubscribes: number;
  open_rate: number;
  click_rate: number;
  error_rate: number;
  spam_rate: number;
  total_clicked: number;
  total_unique_clicked: number;
}

export interface EmailMessageClick {
  id: string;
  url: string;
  total_clicked: number;
  total_unique_clicked: number;
  click_through_rate: number;
}

export interface EmailMessageStatsOverview {
  total_sent?: number;
  open_rate?: number;
  click_rate?: number;
}

export interface EmailMessagePreview {
  id: string;
  subject_line: string | null;
  preview_text: string | null;
  status: EmailMessageStatus;
  last_sent_at: string | null;
  resource_display?: string;
  stats_overview: EmailMessageStatsOverview;
}
