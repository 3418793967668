import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';
import { PLAN } from '@/utils/plans';

import { BillingDetails } from '../../../../../interfaces/billing';

import Body from './PlanCard/Body';
import Header from './PlanCard/Header';

interface Props {
  billingDetails: BillingDetails | undefined;
  onCancel: () => void;
  onNextStep: () => void;
  isLoading: boolean;
}

const Review = (props: Props) => {
  const { billingDetails, onCancel, onNextStep, isLoading } = props;

  const currentPlan = {
    name: billingDetails?.current_plan.name || '',
    max_subscriptions: billingDetails?.organization_max_subscriptions || 0,
    max_publications: billingDetails?.current_plan.name === PLAN.MAX ? 10 : 3,
  };

  const launchPlan = { name: 'Launch', max_subscriptions: 2500, max_publications: 3 };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-semibold text-gray-900">Review your plan changes</h1>
        <Typography token="font-normal/text/sm" colorWeight="700">
          Here&apos;s what to expect at the end of your billing period.
        </Typography>
      </div>

      <SkeletonLoader
        isLoading={!billingDetails}
        skeletons={
          <div className="flex flex-col gap-6">
            <Skeleton className="h-12" />
            <Skeleton className="h-12" />
          </div>
        }
      >
        {billingDetails && (
          <Header
            billingDetails={billingDetails}
            currentPlan={currentPlan}
            newPlan={launchPlan}
            renderTopRowArrow={false}
          />
        )}
      </SkeletonLoader>

      <SkeletonLoader
        isLoading={!billingDetails}
        skeletons={
          <div className="flex flex-col gap-6">
            <Skeleton className="h-12" />
            <Skeleton className="h-12" />
          </div>
        }
      >
        {billingDetails && <Body billingDetails={billingDetails} currentPlan={currentPlan} />}
      </SkeletonLoader>

      <SkeletonLoader
        isLoading={!billingDetails}
        skeletons={
          <div className="flex flex-col gap-6">
            <Skeleton className="h-12" />
            <Skeleton className="h-12" />
          </div>
        }
      >
        <div className="pt-16 pb-8 flex justify-between">
          <div />

          <div className="flex flex-col-reverse items-end sm:space-x-2 sm:flex-none sm:flex-row">
            <div>
              <Button variant="primary-inverse" onClick={onCancel}>
                Nevermind, keep my plan
              </Button>
            </div>
            <div className="mb-2 sm:mb-0">
              <Button variant="primary" loading={isLoading} onClick={onNextStep}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      </SkeletonLoader>
    </div>
  );
};

export default Review;
