import { useState } from 'react';

import BoostAgreementStatus from '@/interfaces/boosts/grow/boost_agreement_status';
import { OrderBy } from '@/interfaces/boosts/order';

import useBoostAgreements from './useBoostAgreements';

interface Options {
  perPage?: number;
}

const useWebApplications = (boostOfferId: string, options?: Options) => {
  const [search, setSearch] = useState<string>('');
  const boostAgreementsQuery = useBoostAgreements({
    boostOfferId,
    status: BoostAgreementStatus.PENDING,
    orderBy: OrderBy.CREATED_AT,
    perPage: typeof options?.perPage === 'number' ? options.perPage : 5,
    search,
  });
  const {
    data,
    isLoading,
    isFetching,
    isRefetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetched,
  } = boostAgreementsQuery;
  const boostAgreements = data?.pages.flatMap((page) => page.boost_agreements) || [];
  const noResults = !isRefetching && !isLoading && boostAgreements.length === 0;

  return {
    search,
    setSearch,
    boostAgreements,
    count: data?.pages[0]?.pagination?.total || 0,
    noResults,
    isLoading,
    isError,
    isFetching,
    isFetched,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};

export default useWebApplications;
