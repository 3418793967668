import ReactTooltip from 'react-tooltip';
import cx from 'classnames';

import { StripeAccountStatus, StripeAccountType } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import { Section } from '@/pages/Settings/Components';

interface Props {
  boostsAccountStatus: StripeAccountStatus | undefined;
  boostsAccountType: StripeAccountType | undefined | null;
  isLoading: boolean;
}

const GeneralInfo = ({ boostsAccountStatus, boostsAccountType, isLoading }: Props) => {
  const accountTypeText = boostsAccountType
    ? boostsAccountType.charAt(0).toUpperCase() + boostsAccountType.slice(1)
    : 'Not Connected';
  const boostsAccountStatusText = boostsAccountStatus
    ? boostsAccountStatus.charAt(0).toUpperCase() + boostsAccountStatus.slice(1)
    : 'Not Connected';

  const statusTooltipId = `status-tooltip-${Math.random()}`;
  const statusColor = {
    [StripeAccountStatus.MISSING]: 'bg-gray-500',
    [StripeAccountStatus.ACTIVE]: 'bg-green-500',
    [StripeAccountStatus.INACTIVE]: 'bg-red-500',
    [StripeAccountStatus.PENDING]: 'bg-yellow-600',
  }[boostsAccountStatus || StripeAccountStatus.MISSING];

  const statusExplanation = {
    [StripeAccountStatus.MISSING]: 'You have not connected a Stripe account yet.',
    [StripeAccountStatus.ACTIVE]: 'Your Stripe account is connected and ready to accept payments.',
    [StripeAccountStatus.INACTIVE]: 'Your Stripe account is connected but not ready to accept payments.',
    [StripeAccountStatus.PENDING]: 'Your Stripe account is connected but is being processed by Stripe.',
  }[boostsAccountStatus || StripeAccountStatus.MISSING];

  const statusBadge = () => {
    return (
      <div className="flex">
        <div className="flex rounded-md bg-gray-100 items-center py-1 px-2 space-x-2 text-xs text-center text-gray-500">
          <div data-tip data-for={statusTooltipId} className="flex items-center space-x-2 cursor-help">
            <p>{boostsAccountStatusText}</p>
            <div className={cx('w-2 h-2 rounded-full transition duration-200', statusColor)} />
          </div>
          <ReactTooltip id={statusTooltipId} place="bottom" type="dark" effect="solid">
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-300">{statusExplanation}</span>
              </div>
            </div>
          </ReactTooltip>
        </div>
      </div>
    );
  };

  const accountTypeTooltipId = `status-tooltip-${Math.random()}`;
  const accountTypeColor = {
    not_connected: 'bg-gray-500',
    [StripeAccountType.STANDARD]: 'bg-green-500',
    [StripeAccountType.EXPRESS]: 'bg-blue-400',
  }[boostsAccountType || 'not_connected'];

  const accountTypeExplanation = {
    not_connected: 'You have not connected a Stripe account yet.',
    [StripeAccountType.STANDARD]: 'Your connected Stripe account is a personal or business account.',
    [StripeAccountType.EXPRESS]: 'Your connected Stripe account is an express account created by beehiiv.',
  }[boostsAccountType || 'not_connected'];

  const accountTypeBadge = () => {
    return (
      <div className="flex">
        <div className="flex rounded-md bg-gray-50 items-center py-1 px-2 space-x-2 text-xs text-center text-gray-500">
          <div data-tip data-for={accountTypeTooltipId} className="flex items-center space-x-2 cursor-help">
            <p>{accountTypeText}</p>
            <div className={cx('w-2 h-2 rounded-full transition duration-200', accountTypeColor)} />
          </div>
          <ReactTooltip id={accountTypeTooltipId} place="bottom" type="dark" effect="solid">
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-300">{accountTypeExplanation}</span>
              </div>
            </div>
          </ReactTooltip>
        </div>
      </div>
    );
  };

  return (
    <Section
      title="Stripe Payment Account"
      description="Connecting an Express Account will allow you to monetize your publication through Boosts or ads. To use this feature, your express account must have transfers enabled."
      scrollToId="general_info"
      isLoading={isLoading}
    >
      <div className="flex flex-row">
        <div className="flex-grow space-y-6">
          <div className="flex flex-row space-x-4">
            <p className="text-gray-500 text-lg">Connected Account Type:</p>
            {accountTypeBadge()}
          </div>
          <div>
            <div className="flex flex-row space-x-4">
              <p className="text-gray-500 text-lg">Account Transfer Status:</p>
              {statusBadge()}
            </div>
            <div className="text-gray-400 text-sm">
              <p>
                <span className="font-semibold">Note:</span> In order to withdraw earnings, transfers must be active on
                your Stripe account.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default GeneralInfo;
