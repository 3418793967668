import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import Loading from '@/pages/Loading';
import { ApiResponse } from '@/routes/monetize/subscriptions/_hooks/useTopLevelMetrics';
import api from '@/services/swarm';

export default function AuthMailchimpCallback() {
  const [currentPublicationId] = useCurrentPublicationState();

  const [params] = useQueryParams<{ code: QueryParamConfig<string>; state: QueryParamConfig<string> }>({
    code: withDefault(StringParam, ''),
    state: withDefault(StringParam, ''),
  });

  const navigate = useNavigate();

  const { isSuccess } = useQuery({
    queryFn: (): Promise<ApiResponse> =>
      api.post('/oauth2/callbacks/mailchimp', {
        code: params.code,
        state: params.state,
        publication_id: currentPublicationId,
      }),
    retry: false,
    onSuccess: () => {
      navigate('/settings/integrations/imports');
      toast.success('Mailchimp connected');
    },
    onError: () => {
      navigate('/settings/integrations/imports');
      toast.error('Mailchimp connection failed');
    },
  });

  if (!isSuccess) return <Loading text="Connecting to mailchimp..." />;

  return <Loading text="Done." />;
}
