import { FC, Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { MODAL_Z_INDEX } from '../zIndexes';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  baseClassName?: string;
  includeCloseButton?: boolean;
  customStyle?: any;
  fullScreen?: boolean;
}

const Modal: FC<Props> = (props: Props) => {
  const {
    isOpen,
    onClose,
    className,
    includeCloseButton = true,
    fullScreen = false,
    baseClassName = `relative inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:align-middle ${
      fullScreen ? 'w-screen h-screen' : 'sm:my-8'
    }`,
    customStyle,
    children,
  } = props;

  const renderModal = (
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>
      {/* This element is to trick the browser into centering the modal contents. */}
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div className={`${baseClassName} ${className}`} style={customStyle}>
          {includeCloseButton && (
            <button
              type="button"
              onClick={onClose}
              className="absolute right-4 top-4 bg-transparent rounded-sm p-1 transition-all hover:bg-gray-200"
            >
              <XMarkIcon className="w-5 h-5 bg-white bg-opacity-20 rounded-sm text-gray-900 text-opacity-60 transition duration-300 ease-in-out" />
            </button>
          )}
          {children}
        </div>
      </Transition.Child>
    </div>
  );

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={cx('fixed inset-0', MODAL_Z_INDEX, !fullScreen && 'overflow-y-auto')}
        onClose={onClose}
      >
        {renderModal}
      </Dialog>
    </Transition.Root>
  );
};

Modal.defaultProps = {
  className: '',
};

export default Modal;
