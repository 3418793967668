import { useInfiniteQuery, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { ContentTag } from '../interfaces/content_tag';
import { Pagination } from '../interfaces/general';
import api from '../services/swarm';

interface ApiResponse {
  contentTags: ContentTag[];
  pagination: Pagination;
}

export default function useContentTags({ search }: { search?: string }) {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const fetchContentTags = ({ pageParam = 1 }) =>
    api
      .get(`/content_tags`, {
        params: {
          page: pageParam,
          per_page: 10,
          publication_id: currentPublicationId,
          q: search,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([currentPublicationId, 'contentTags', search], fetchContentTags, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    retry: false,
    keepPreviousData: true,
    onSuccess: (data) => {
      const contentTags = data.pages.flatMap((page) => page.contentTags);

      contentTags.forEach((contentTagPreview: any) => {
        queryClient.setQueryData(['contentTags', contentTagPreview?.id], {
          id: contentTagPreview?.id,
          name: contentTagPreview?.name,
          description: contentTagPreview?.description,
        });
      });
    },
    onError: (errPayload: any) => errPayload?.response?.data?.error || 'Something went wrong',
  });
}
