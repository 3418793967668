import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { Typography } from '@/components/Typography';

interface Props {
  title: string;
  to: string;
}

export const NavItem = ({ title, to }: Props) => {
  const location = useLocation();
  const isCurrentPath = location.pathname === to;

  return (
    <div className="flex flex-col justify-center items-center w-full pl-6 pr-2">
      <Link
        to={to}
        className={cx(
          'py-1.5 px-2 rounded-lg text-start w-full text-gray-700 duration-300',
          isCurrentPath ? 'bg-surface-100' : 'hover:bg-surface-100 bg-transparent'
        )}
      >
        <Typography token="font-medium/text/sm" className="text-gray-700">
          {title}
        </Typography>
      </Link>
    </div>
  );
};
