import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import ProgressBar from '@/components/ProgressBar';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication, useOrganization } from '@/hooks';
import useCreateBoostAgreementsRestrictions from '@/hooks/boosts/monetize/useCreateBoostAgreementsRestrictions';
import useRequireVerifyIdentity from '@/hooks/boosts/useRequireVerifyIdentity';
import analytics from '@/utils/analytics';

import ActionModal from '../../../../../components/ActionModal';
import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import useCreateBoostAgreement from '../../../../../hooks/boosts/monetize/useCreateBoostAgreement';
import { BoostOffer } from '../../../../../interfaces/boosts/monetize/boost_offer';

import ApplyForm from './ApplyForm';

export interface ApplyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onProceed: () => void;
  boostOffer: BoostOffer | null;
}

const ApplyModal = ({ isOpen, onClose, onProceed, boostOffer }: ApplyModalProps) => {
  const { settings } = useSettings();
  const [currentPublicationId] = useCurrentPublicationState();
  const [whyMe, setWhyMe] = useState<string>('');
  const [disabled, setDisabled] = useState(isOpen);
  const [isCreatingBoostAgreement, setIsCreatingBoostAgreement] = useState(false);
  const queryClient = useQueryClient();
  const createBoostAgreement = useCreateBoostAgreement({
    onSuccess: () => {
      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'monetize', 'boost_offers']);
      queryClient.invalidateQueries(['publications', currentPublicationId]);
      toast.success('Application submitted!');
    },
    onSettled: () => {
      setIsCreatingBoostAgreement(false);
    },
  });
  const { data: currentPublication } = useCurrentPublication();
  const { data: organizationData } = useOrganization(currentPublication?.organization_id);
  const { organization } = organizationData || {};
  // Require identity verification if feature flag is on and the organization is not verified
  const requiresIdentityVerification = useRequireVerifyIdentity();

  const { hasReachedApplyLimit, remainingApplications } = useCreateBoostAgreementsRestrictions();

  useEffect(() => {
    setDisabled(isOpen);

    const timeout = setTimeout(() => {
      setDisabled(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  const handleProceed = () => {
    setIsCreatingBoostAgreement(true);
    analytics.track('Boost Application Submitted');
    createBoostAgreement.mutate({ boost_offer_id: boostOffer?.id || '', whyMe });
    onProceed();
  };

  const pubName = boostOffer?.boosted_publication?.name || '';
  const whyMeLabel = `Why do you think your newsletter is a good fit to Boost ${pubName}?`;

  if (!organization?.id || !currentPublication || !settings) {
    return null;
  }

  const modalFooter =
    currentPublication.current_month_boost_agreements_count > 0 ? (
      <div className="w-56">
        <ProgressBar
          helperText={`You have ${remainingApplications} applications remaining this month`}
          value={currentPublication?.current_month_boost_agreements_count}
          total={settings?.max_monthly_boost_agreements || 0}
        />
      </div>
    ) : null;

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      headerText={`Apply to ${pubName}`}
      isWorking={isCreatingBoostAgreement}
      onProceed={handleProceed}
      resourceId={boostOffer?.id || ''}
      modalSize="md"
      disabled={disabled || requiresIdentityVerification || hasReachedApplyLimit}
      actionText="Submit Application"
      footer={modalFooter}
    >
      <ApplyForm whyMeLabel={whyMeLabel} whyMeValue={whyMe} setWhyMe={setWhyMe} />
    </ActionModal>
  );
};

export default ApplyModal;
