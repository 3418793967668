import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { BodyBase, Title2xl, TypographyStack } from '@/components/Typography';
import useReport from '@/hooks/useAdNetwork/publisher/useReport';
import {
  AdNetworkDisbursementStatus,
  AdNetworkDisbursementStatusLabelHash,
} from '@/interfaces/ad_network/internal/disbursement/types';
import { AdNetworkReport } from '@/interfaces/ad_network/publisher/report';
import { Card } from '@/ui/Card';
import { MetricCard } from '@/ui/MetricCard';

interface Props {
  report: AdNetworkReport;
}

const formattedPercentage = (value: number) => {
  const percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 });
  return percentFormatter.format(value / 100.0);
};

const formattedNumber = (value: number) => {
  const numberFormatter = new Intl.NumberFormat('en-US');
  return numberFormatter.format(value);
};

const Page = ({ report }: Props) => {
  const pageTitle = `Reports / ${report.placement_name}`;

  const payoutTooltip =
    report.disbursement_status === AdNetworkDisbursementStatus.MANUAL
      ? 'Payouts are reviewed by our team and typically processed within 30 days.'
      : 'Payouts are reviewed by our team and processed on the 20th of each month.';

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="flex mb-4 sm:mb-0">
        <Link className="text-sm text-gray-500 hover:text-primary-500" to="/monetize/ads/reports">
          <div className="flex items-center">
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            <span>Back to all reports</span>
          </div>
        </Link>
      </div>
      <div className="flex items-end w-full justify-between">
        <TypographyStack gap="1">
          <Title2xl>{pageTitle}</Title2xl>
          <BodyBase>
            Advertisement summary for placement included in{' '}
            <Link to={`/posts/${report.post_id}`} className="text-feedback-info-500 hover:underline">
              {report.post_web_title}
            </Link>{' '}
            {moment(report.window_opened_at).fromNow()}.
          </BodyBase>
        </TypographyStack>
        <p className="text-sm text-gray-700">Generated on {moment(report.generated_at).format('MMM D, YYYY h:mm A')}</p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-4 mt-3">
        <div className="col-span-2 mb-4 lg:mb-0">
          <Card density="compact">
            <div className="space-y-2">
              <p className="font-medium text-gray-700 text-base truncate">Details</p>
              <dl className="divide-y divide-gray-100">
                <div className=" py-2 grid grid-cols-2 gap-4 px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Advertiser</dt>
                  <dd className=" text-sm leading-6 text-gray-700 col-span-1 mt-0">{report.advertiser_name}</dd>
                </div>
                <div className=" py-2 grid grid-cols-2 gap-4 px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Post</dt>
                  <dd className=" text-sm leading-6 text-feedback-info-500 col-span-1 mt-0 font-semibold">
                    <Link to={`/posts/${report.post_id}`} className="hover:underline">
                      {report.post_web_title}
                    </Link>
                  </dd>
                </div>
                <div className=" py-2 grid grid-cols-2 gap-4 px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Post sent</dt>
                  <dd className=" text-sm leading-6 text-gray-700 col-span-1 mt-0">
                    {moment(report.window_opened_at).format('MMM D, YYYY h:mm A')}
                  </dd>
                </div>
                <div className=" py-2 grid grid-cols-2 gap-4 px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Window closed</dt>
                  <dd className=" text-sm leading-6 text-gray-700 col-span-1 mt-0">
                    {moment(report.window_closed_at).format('MMM D, YYYY h:mm A')}
                  </dd>
                </div>
              </dl>
            </div>
          </Card>
        </div>
        <div className="col-span-3 flex flex-col space-y-4">
          <MetricCard
            variant="primary"
            label="Estimated earnings"
            value={report.disbursement_approved_amount}
            complements={[
              { label: 'CPC', value: report.payout_per_click },
              { label: 'Status', value: AdNetworkDisbursementStatusLabelHash[report.disbursement_status] },
              { label: 'Initial estimate', value: report.initial_estimated_payout },
            ]}
            tooltip={`Earnings are the estimate of the amount of money you have earned from the ad. ${payoutTooltip}`}
          />
          <MetricCard
            variant="primary"
            label="Verified clicks"
            value={formattedNumber(report.disbursement_approved_clicks)}
            complements={[]}
            tooltip="Verified clicks are the number of clicks that are eligible for payment. Payouts are based on unique clicks occurring within 72 hours of a placement."
          />
          <MetricCard
            variant="primary"
            label="Impressions"
            value={formattedNumber(report.total_impressions)}
            complements={[
              { label: 'Open rate', value: formattedPercentage(report.post_open_rate) },
              { label: 'Total sent', value: formattedNumber(report.total_sent) },
              { label: 'Total opens', value: formattedNumber(report.total_opened) },
              { label: 'Total web views', value: formattedNumber(report.total_web_viewed) },
            ]}
            tooltip="Impressions are the number of times the ad has been displayed."
          />
        </div>
      </div>
    </>
  );
};

const Loader = () => {
  const { report_id: id } = useParams<'report_id'>() as { report_id: string };

  const { data: report, isSuccess } = useReport({ id });

  if (!isSuccess) return null;

  return <Page report={report} />;
};

export default Loader;
