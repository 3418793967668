import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Fuse from 'fuse.js';
import { useDebounce } from 'use-debounce';

import { TypeaheadSelect } from '@/components/Form';
import * as DropdownMenu from '@/components/UI/Dropdown';
import { useInfiniteScroll } from '@/hooks';
import { useAdvertiserCampaigns, useAdvertisers } from '@/hooks/useAdNetwork/internal';
import useCampaignOpportunityGroups from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroups';
import { Button } from '@/ui/Button';

import useFilteringContext from '../filtering-context';
import { ConditionGroup } from '../types';

const OpportunityGroupDetails = ({ group }: { group: ConditionGroup }) => {
  const { addCondition, handleClose } = useFilteringContext();
  const [debouncedSearchQuery] = useDebounce('', 800);

  const [advertiserId, setAdvertiserId] = useState<string>('');
  const [advertiserName, setAdvertiserName] = useState<string>('');
  const [campaignId, setCampaignId] = useState<string>('');
  const [campaignName, setCampaignName] = useState<string>('');
  const [groupId, setGroupId] = useState<string>('');

  const {
    data: advertisersData,
    hasNextPage: advertisersHasNextPage,
    fetchNextPage: fetchAdvertisersNextPage,
    isLoading: advertisersLoading,
    isFetchingNextPage: advertiserFetchingNextPage,
  } = useAdvertisers({ query: debouncedSearchQuery });
  const advertisers = advertisersData?.pages.flatMap((page) => page.advertisers) || [];

  const { ref: advertiserLastElementRef } = useInfiniteScroll({
    fetchNextPage: fetchAdvertisersNextPage,
    hasNextPage: advertisersHasNextPage || false,
    isFetching: advertiserFetchingNextPage,
    isLoading: advertisersLoading,
  });

  const {
    data: campaignsData,
    isLoading: campaignsLoading,
    hasNextPage: campaignsHasNextPage,
    fetchNextPage: fetchCampaignsNextPage,
    isFetchingNextPage: campaignsFetchingNextPage,
  } = useAdvertiserCampaigns({ id: advertiserId });
  const campaigns = campaignsData?.pages.flatMap((page) => page.campaigns) || [];

  const { ref: campaginLastElementRef } = useInfiniteScroll({
    fetchNextPage: fetchCampaignsNextPage,
    hasNextPage: campaignsHasNextPage || false,
    isFetching: campaignsFetchingNextPage,
    isLoading: campaignsLoading,
  });

  const {
    data: groupsData,
  } = useCampaignOpportunityGroups({ campaignId });
  const groups = useMemo(() => groupsData?.pages?.flatMap((page) => page.campaign_opportunity_groups) || [], [groupsData]);

  const fuse = useMemo(() => new Fuse(groups.map((thisGroup) => thisGroup.name), { threshold: 0.4 }), [groups]);

  const fetchGroups = async (inputValue?: string) => {
    if (!inputValue || inputValue === '') {
      return groups.map((thisGroup) => ({ label: thisGroup.name, value: thisGroup.id }));
    }

    return fuse.search(inputValue || '').map((option) => ({
      label: option.item,
      value: option.item,
    }));
  };

  const handleApply = () => {
    if (groupId) {
      addCondition(
        {
          type: 'attribute',
          name: 'opportunity_group',
          filters: { operator: 'equal', value: groupId },
        },
        group.uuid
      );

      handleClose();
    } else {
      toast.error('Please select a group');
    }
  };

  return (
    <form
      className="absolute z-10 mt-2 bg-white border border-gray-300 shadow rounded-md text-sm left-0 w-max text-gray-600 p-4 space-y-2"
      onSubmit={handleApply}
    >
      <div className="flex flex-col space-y-3">
        <div className="flex flex-row space-x-2">
          <p className="my-auto w-[100px]">Advertiser</p>
          <DropdownMenu.Root>
            <DropdownMenu.Button className="w-[180px] flex flex-row justify-between border p-2 bg-transparent rounded outline-none ring-0">
              {advertiserName || 'Select Advertiser'}
              <ChevronDownIcon className="my-auto h-3 w-3" />
            </DropdownMenu.Button>
            <DropdownMenu.Content className="p-1 bg-white z-10 rounded-lg shadow-lg min-w-[178px] max-h-[300px] overflow-auto right-0">
              <div>
                {advertisers &&
                  advertisers.map((option: any, index) => {
                    const isLastRow = advertisers.length - 1 === index;

                    return (
                      <DropdownMenu.Item
                        key={option.name}
                        onClick={() => {
                          setAdvertiserId(option.id);
                          setAdvertiserName(option.name);
                        }}
                        ref={isLastRow ? advertiserLastElementRef : null}
                      >
                        <span className="flex-1">{option.name}</span>
                      </DropdownMenu.Item>
                    );
                  })}
              </div>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
        <div className="flex flex-row space-x-2">
          <p className="my-auto w-[100px]">Campaign</p>
          <DropdownMenu.Root>
            <DropdownMenu.Button className="w-[180px] flex flex-row justify-between  border p-2 bg-white rounded outline-none ring-0">
              {campaignName || 'Select Campaign'}
              <ChevronDownIcon className="my-auto h-3 w-3" />
            </DropdownMenu.Button>
            <DropdownMenu.Content className="p-1 bg-white z-10 rounded-lg shadow-lg min-w-[178px] right-0">
              <div>
                {campaigns &&
                  campaigns.map((option: any, index: number) => {
                    const isLastRow = campaigns.length - 1 === index;

                    return (
                      <DropdownMenu.Item
                        key={option.name}
                        onClick={() => {
                          setCampaignId(option.id);
                          setCampaignName(option.name);
                        }}
                        ref={isLastRow ? campaginLastElementRef : null}
                      >
                        <span className="flex-1">{option.name}</span>
                      </DropdownMenu.Item>
                    );
                  })}
              </div>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
        <div className="flex flex-row space-x-2">
          <p className="my-auto w-[100px]">Opportunity Group</p>
          <TypeaheadSelect
            className="w-[180px]"
            name="opportunityGroup"
            placeholderText="Select Group"
            value={groupId}
            onSelect={(name, value) => {
              setGroupId(value);
            }}
            onClear={() => {
              setGroupId('');
            }}
            loadOptions={fetchGroups}
          />
        </div>
      </div>
      <Button variant="primary-inverse" type="submit" size="xs">
        Apply
      </Button>
    </form>
  );
};

export default OpportunityGroupDetails;
