import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { TierChangeEventResourceType } from '@/interfaces/tier_change_event';
import api from '@/services/swarm';

interface Props {
  resourceType: TierChangeEventResourceType;
  resourceId: string;
}

interface ApiResponse {
  total_upgrades: number;
  total_downgrades: number;
}

const useTierChangeStatsByResource = ({ resourceType, resourceId }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchTierChangeData = () => {
    return api
      .get(`/premium_data/tier_change_event_stats_by_resource`, {
        params: {
          publication_id: currentPublicationId,
          resource_type: resourceType,
          resource_id: resourceId,
        },
      })
      .then((res) => res.data);
  };

  return useQuery<ApiResponse>(
    ['premium_data', 'tierChangeEventsByResource', currentPublicationId, resourceType, resourceId],
    fetchTierChangeData
  );
};

export default useTierChangeStatsByResource;
