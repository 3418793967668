import { useParams } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/20/solid';

import { BoostsTopLineMetrics } from '@/components/BoostsTopLineMetrics';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import LoadingBox from '@/components/LoadingBox';
import { Typography, TypographyStack } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { useRequireSetting } from '@/hooks';
import useBoostAgreements from '@/hooks/boosts/monetize/useBoostAgreements';
import useAffiliateLink from '@/hooks/boosts/useAffiliateLink';
import { BoostAgreement } from '@/interfaces/boosts/monetize/boost_agreement';
import { OrderBy } from '@/interfaces/boosts/order';
import { Order } from '@/interfaces/general';
import { Setting } from '@/interfaces/setting';

import PublicationCard from './PublicationCard';
import Tabs from './Tabs';

const BoostedPublication = () => {
  const { settings } = useSettings();
  const { boostedPublicationId } = useParams() as unknown as { boostedPublicationId: string };

  const {
    data,
    isLoading: isLoadingFirstBoostAgreement,
    isError: isErrorFirstBoostAgreement,
  } = useBoostAgreements({
    boostedPublicationId,
    orderBy: OrderBy.CREATED_AT,
    direction: Order.ASC,
    perPage: 1,
    isEnabled: true,
  });

  const boostAgreements = (data?.pages.flatMap((page) => page.boost_agreements) as BoostAgreement[]) || [];
  const firstBoostAgreement = boostAgreements[0];

  const { affiliateLink } = useAffiliateLink(firstBoostAgreement?.boost_offer?.boosted_publication?.url);

  useRequireSetting({
    setting: Setting.BOOSTS,
    message: 'Upgrade to use boosts.',
    redirectTo: '/grow/boosts/overview',
  });

  if (settings && !settings.organization_boosts) {
    return <div className="text-bold">Boosts have been disabled for this account.</div>;
  }

  const isLoading = isLoadingFirstBoostAgreement;
  const isError = isErrorFirstBoostAgreement;

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <div className="flex flex-col gap-y-8">
        <Breadcrumbs>
          <Breadcrumbs.Item to="/">
            <HomeIcon className="w-4 h-4" />
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to="/monetize/boosts">Monetize with Boosts</Breadcrumbs.Item>
          <Breadcrumbs.Item to={`/monetize/boosts/boosted_publication/${boostedPublicationId}`}>
            {firstBoostAgreement?.boost_offer?.boosted_publication?.name}
          </Breadcrumbs.Item>
        </Breadcrumbs>
        {firstBoostAgreement && (
          <PublicationCard
            firstBoostAgreement={firstBoostAgreement}
            publication={firstBoostAgreement?.boost_offer?.boosted_publication}
            affiliateLink={affiliateLink}
          />
        )}

        <TypographyStack>
          <Typography token="font-bold/text/base">Publication Overview</Typography>
          <Typography token="font-normal/text/sm">
            High level glance at how this partner is driving your revenue.
          </Typography>
        </TypographyStack>

        <BoostsTopLineMetrics boostedPublicationId={boostedPublicationId} />

        <TypographyStack>
          <Typography token="font-bold/text/base">Boost Channels</Typography>
          <Typography token="font-normal/text/sm">
            Here are the channels through which you are boosting your partner
          </Typography>
        </TypographyStack>

        <Tabs boostedPublicationId={boostedPublicationId} />
      </div>
    </LoadingBox>
  );
};

export default BoostedPublication;
