import { useMemo } from 'react';

import { useTiers } from '@/hooks/useTiers';
import { Option } from '@/interfaces/general';

import { SimpleSelect } from '../Form';

interface TierSelectProps {
  name?: string;
  tierId: string;
  includeAll?: boolean;
  includeFree?: boolean;
  onChange: (name: string, value: string | string[]) => void;
}

const TierSelect = ({ name = 'tier_id', includeAll, includeFree, tierId, onChange }: TierSelectProps) => {
  const tiersQuery = useTiers();
  const selectOptions = useMemo<Option[]>(() => {
    const tiers = tiersQuery.data || [];
    const options: Option[] = [
      ...(includeAll ? [{ label: 'All Tiers', value: 'all' }] : []),
      ...(includeFree ? [{ label: 'Free Tier', value: 'free' }] : []),
      { label: 'Any Premium Tier', value: 'any' },
    ];

    if (tiers.length === 0) return options;

    return [
      ...options,
      {
        label: 'Premium Tiers',
        value: '',
        isOptGroup: true,
      },
      ...tiers.map((option) => ({
        label: option.name,
        value: option.id,
      })),
    ];
  }, [includeAll, includeFree, tiersQuery.data]);

  return (
    <SimpleSelect
      name={name}
      value={tierId}
      onSelect={onChange}
      options={selectOptions}
      placeholderText="Select a Tier"
    />
  );
};

export default TierSelect;
