import { useNavigate } from 'react-router-dom';
import { BanknotesIcon } from '@heroicons/react/20/solid';

import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import LoadingBox from '@/components/LoadingBox';
import { EmptyCard } from '@/components/ResourceList';
import { useRequireSetting } from '@/hooks';
import { usePremiumSettings } from '@/hooks/usePublications';
import { useTutorial } from '@/hooks/useTutorials';
import { Setting } from '@/interfaces/setting';
import { TutorialType } from '@/interfaces/tutorial';

import { PremiumDataProvider } from '../../_context/premiumDataContext';
import TopLevelMetrics from '../TopLevelMetrics';

import Nav from './Nav';

const PageWrapper = ({ children }: { children: React.ReactNode }) => {
  useRequireSetting({
    setting: Setting.PREMIUM_SUBSCRIPTIONS,
    message: '',
    redirectTo: '/monetize/subscriptions/overview',
  });
  const navigate = useNavigate();

  const { data: premiumSettings, isLoading, isError } = usePremiumSettings();
  const isPremiumActive = premiumSettings?.premium_tier_active;
  const isPremiumEnabled = premiumSettings?.premium_enabled;

  const tutorial = useTutorial(TutorialType.PREMIUM_SUBSCRIPTIONS);

  return (
    <PremiumDataProvider>
      <div className="space-y-5">
        <PageHeading
          title="Premium Subscriptions"
          description="Insights into how your paid subscription offerings are performing."
          tutorial={tutorial}
        />
        <TopLevelMetrics />
        <Nav />
        <LoadingBox backgroundClassName="bg-transparent" isLoading={isLoading} isError={isError}>
          {isPremiumActive && children}
          {!isPremiumActive && (
            <EmptyCard
              title="No Premium Subscription Enabled"
              description="Configure your premium payment tiers in order to set up a premium subscription"
              primaryActionLabel="Create Premium Tier"
              primaryIcon={BanknotesIcon}
              primaryIconTransparentBackground
              primaryIconSize="xl"
              onPrimaryActionClick={() => {
                if (isPremiumEnabled) {
                  navigate('/settings/publication/premium/tiers/new');
                } else {
                  navigate('/settings/publication/premium');
                }
              }}
            />
          )}
        </LoadingBox>
      </div>
    </PremiumDataProvider>
  );
};

export default PageWrapper;
