import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { IOnboarding } from '../interfaces/onboarding';
import api from '../services/swarm';

const useOnboarding = (onboardingId?: string) => {
  const [publicationId] = useCurrentPublicationState();

  return useQuery<IOnboarding>(
    ['onboarding', publicationId],
    () =>
      api
        .get(`/onboarding/${onboardingId}`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: Boolean(onboardingId),
    }
  );
};

export default useOnboarding;
