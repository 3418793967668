import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublication } from '@/hooks';
import api from '@/services/swarm';

const useDeletePausePlan = () => {
  const { data: currentPublication } = useCurrentPublication();
  const queryClient = useQueryClient();

  return useMutation(() => api.delete(`/organizations/${currentPublication?.organization_id}/pause_plan`), {
    onError: (err: any) => {
      toast.error(err?.response?.data?.error || 'Oops there was an issue unpausing your plan');
    },
    onSettled: async () => {
      return queryClient.invalidateQueries(['pause_plan', currentPublication?.organization_id]);
    },
  });
};

export default useDeletePausePlan;
