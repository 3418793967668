import { useMutation } from 'react-query';
import { Link , Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';

import useCampaignPerformance from '@/hooks/useAdNetwork/internal/useCampaignPerformance';
import { AdNetworkCampaignPerformance } from '@/interfaces/ad_network/internal/campaign_performance';
import { LoadingListPage } from '@/routes/ad_network/_components/LoadingListPage';
import { PageHeading } from '@/routes/ad_network/_components/PageHeading';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import CampaignPerformanceOverview from '../_components/CampaignPerformanceOverview';

const CampaignPerformanceLayout = () => {
  const { campaign_id: id } = useParams<'campaign_id'>() as { campaign_id: string };
  const { data: campaign, isSuccess, isLoading, isError } = useCampaignPerformance({ id });
  const fetchCsv = async () => {
    const campaignId = campaign?.id || '';
    const currentUrl = window.location.href;
    const filterParam = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
    const response = await api.get(`ad_network/internal/campaigns/${campaignId}/opportunities/export`, {
      params: { campaign_id: campaignId, filter: filterParam },
    });
    return response.data;
  };

  const downloadCsvMutation = useMutation<any>(fetchCsv, {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.csv]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `export.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    onError: () => {
      console.log('error');
    },
  });

  if (!isSuccess) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{campaign.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Performance</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button
            variant="primary-inverse"
            size="xs"
            type="button"
            Icon={ArrowTopRightOnSquareIcon}
            iconRight
          >
            <Link
              to={`/advertiser_access/${campaign.advertiser.id}/campaign_reports/${campaign.id}`}
              target="_blank"
            >
              Advertiser Access
            </Link>
          </Button>
          <Button
            variant="primary-inverse"
            size="xs"
            disabled={!campaign}
            type="button"
            onClick={() => downloadCsvMutation.mutate()}
            loading={downloadCsvMutation.isLoading}
          >
            Download CSV
          </Button>
          <Button
            variant="primary-inverse"
            size="xs"
            type="button"
            to={`/ad_network/campaigns/${campaign.id}/campaign_opportunity_groups`}
          >
            Provision
          </Button>
        </PageHeading.Side>
      </PageHeading>
      <div className="border-b border-gray-100">
        <CampaignPerformanceOverview campaign={campaign} />
      </div>
      <Outlet context={campaign} />
    </>
  );
};

export function useOutletCampaignPerformance() {
  return useOutletContext<AdNetworkCampaignPerformance>();
}

export default CampaignPerformanceLayout;
