import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FireIcon } from '@heroicons/react/20/solid';

import { useSettings } from '@/context/settings-context';
import { Button } from '@/ui/Button';

import VideoModal from '../../../../components/Modal/VideoModal';
import useCreateStripeCustomer from '../../../../hooks/boosts/grow/useCreateStripeCustomer';
import { useTutorial } from '../../../../hooks/useTutorials';
import { TutorialType } from '../../../../interfaces/tutorial';

const BoostsIntro = () => {
  const tutorial = useTutorial(TutorialType.BOOSTS_GROW);
  const [tutorialOpen, setIsTutorialOpen] = useState(false);
  const navigate = useNavigate();
  const createStripeCustomer = useCreateStripeCustomer({
    onSuccess: () => {
      navigate('/grow/boosts/new');
    },
  });

  const { settings } = useSettings();
  if (settings && !settings.organization_boosts) {
    return <div className="text-bold">Boosts have been disabled for this account.</div>;
  }

  return (
    <div className="space-y-4 p-6 sm:py-32 rounded">
      <div className="mx-auto max-w-lg space-y-6">
        <div className="flex justify-center">
          <FireIcon className="h-8 w-8 text-primary-800" />
        </div>
        <h2 className="text-2xl text-gray-900 font-black text-center">Introducing Boosts</h2>
        <p>
          A powerful new way to grow your audience. Leverage the beehiiv network to reach new audiences, support other
          newsletter creators, and grow your business.
        </p>
        <Button block onClick={() => createStripeCustomer.mutate()}>
          Get started
        </Button>
        <div className="w-full space-y-2">
          {tutorial && (
            <>
              <div className="text-center">
                <button
                  className="text-sm text-secondary-500 hover:underline"
                  type="button"
                  onClick={() => setIsTutorialOpen(true)}
                >
                  Watch the tutorial
                </button>
              </div>
              <VideoModal title="" src={tutorial.url} isOpen={tutorialOpen} onClose={() => setIsTutorialOpen(false)} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BoostsIntro;
