import { UseQueryResult } from 'react-query';

import Card from '@/components/Card';
import { Typography, TypographyStack } from '@/components/Typography';
import TypographyRow from '@/components/Typography/TypographyRow';

import { ApiResponse } from '../../_hooks/useTopLevelMetrics';

const PaidSubscribersCard = ({ query }: { query: UseQueryResult<ApiResponse> }) => {
  const formatter = new Intl.NumberFormat('en');

  const { subscription_tier_breakdown: subscriptionTierBreakdown } = query.data || {};

  return (
    <Card
      isLoading={query.isLoading}
      className="col-span-4 md:col-span-2 lg:col-span-1 flex flex-col justify-between space-y-4"
    >
      {subscriptionTierBreakdown ? (
        <>
          <TypographyStack gap="2">
            <Typography token="font-medium/text/base" colorWeight="700">
              Paid Subscribers
            </Typography>
            <Typography token="font-semibold/text/3xl">
              {formatter.format(subscriptionTierBreakdown.premium)}
            </Typography>
          </TypographyStack>
          <TypographyStack gap="1">
            <TypographyRow className="justify-between">
              <Typography token="font-medium/text/xs" colorWeight="700">
                Free Subscribers
              </Typography>
              <Typography token="font-semibold/text/xs">{formatter.format(subscriptionTierBreakdown.free)}</Typography>
            </TypographyRow>
            <TypographyRow className="justify-between">
              <Typography token="font-medium/text/xs" colorWeight="700">
                Total Subscribers
              </Typography>
              <Typography token="font-semibold/text/xs">
                {formatter.format(subscriptionTierBreakdown.free + subscriptionTierBreakdown.premium)}
              </Typography>
            </TypographyRow>
          </TypographyStack>
        </>
      ) : (
        <TypographyRow className="h-full items-center justify-center text-center min-h-[8rem]">
          <Typography token="font-medium/text/xs" colorWeight="700">
            Error pulling subscriber breakdown
          </Typography>
        </TypographyRow>
      )}
    </Card>
  );
};

export default PaidSubscribersCard;
