import { SimpleSelect } from '@/components/Form';
import usePollChoices from '@/hooks/usePolls/usePollChoices';
import { Option } from '@/interfaces/general';

interface PollChoiceSelectProps {
  id: string;
  pollId: string;
  onChange: (name: string, value: string) => void;
  className?: string;
}

const PollChoiceSelect = ({ id, pollId, onChange, className }: PollChoiceSelectProps) => {
  const { data } = usePollChoices({ id: pollId });
  const choices = data?.poll_choices || [];
  const options: Option[] = [{ label: 'Any Answer', value: 'any' }];

  if (choices?.length > 0) {
    options.push({
      label: 'Answers',
      value: '',
      isOptGroup: true,
    });

    choices.forEach((option: { id: string; label: string }) => {
      options.push({
        label: option.label,
        value: option.id,
      });
    });
  }

  return (
    <SimpleSelect
      name={`poll_${pollId}_choice_id`}
      value={id}
      onSelect={onChange}
      options={options}
      placeholderText="Select an Answer"
      className={className}
    />
  );
};

export default PollChoiceSelect;
