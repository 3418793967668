import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import useDeleteStripeConnectAccount from '@/hooks/boosts/monetize/useDeleteStripeConnectAccount';
import { StripeAccountType } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import { Section } from '@/pages/Settings/Components';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

interface Props {
  boostsAccountType: StripeAccountType | undefined | null;
  isLoading: boolean;
}

const Disconnect = ({ boostsAccountType, isLoading }: Props) => {
  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false);
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState<boolean>(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const disconnectAccount = useDeleteStripeConnectAccount();
  const isExpress = boostsAccountType === StripeAccountType.EXPRESS;
  const buttonText = isExpress ? 'Delete Express Account' : 'Disconnect Standard Account';
  const titleText = isExpress ? 'Delete Stripe Account' : 'Disconnect Stripe Account';
  const descriptionText = isExpress
    ? 'Delete and disconnect your payment express stripe account from your publication.'
    : 'Disconnect your payment stripe account from your publication.';
  const { settings } = useSettings();

  const closeDisconnectModal = () => {
    setIsDisconnectModalOpen(false);
    setIsDisconnecting(false);
  };

  const disconnectBoostsAccount = () => {
    analytics.track('Disconnected stripe');
    setIsDisconnecting(true);
    disconnectAccount.mutate();
    setIsDisconnecting(false);
    closeDisconnectModal();
  };

  const handleDisconnectClick = () => {
    setIsDisconnectModalOpen(true);
  };

  if (!boostsAccountType || !settings?.wallet) return null;

  return (
    <>
      <ActionModal
        isOpen={isDisconnectModalOpen}
        isWorking={isDisconnecting}
        onClose={() => closeDisconnectModal()}
        onProceed={() => disconnectBoostsAccount()}
        resourceId={currentPublicationId}
        headerText="Disconnect Stripe Account"
        actionText="Disconnect"
      >
        <div className="flex flex-col space-y-4">
          <p>
            You will not be able to withdraw any funds or apply to any monetization opportunities until you have
            re-connected a Stripe account.
          </p>
          <p>Are you sure you would like to continue?</p>
        </div>
      </ActionModal>
      <Section title={titleText} description={descriptionText} scrollToId="disconnect" isLoading={isLoading}>
        <Button
          variant="danger"
          shade="dark"
          type="submit"
          loading={isDisconnectModalOpen}
          disabled={isDisconnectModalOpen}
          onClick={() => handleDisconnectClick()}
        >
          {buttonText}
        </Button>
      </Section>
    </>
  );
};

export default Disconnect;
