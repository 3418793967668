import { useState } from 'react';

import { useSettings } from '@/context/settings-context';
import useCreateStripeConnectAccount from '@/hooks/boosts/monetize/useCreateStripeConnectAccount';
import { StripeAccountStatus, StripeAccountType } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import { Section } from '@/pages/Settings/Components';
import { Button } from '@/ui/Button';

interface Props {
  boostsAccountStatus: StripeAccountStatus | undefined;
  boostsAccountType: StripeAccountType | undefined | null;
  isLoading: boolean;
}

const ContinueOnboarding = ({ boostsAccountStatus, boostsAccountType, isLoading }: Props) => {
  const { settings } = useSettings();
  const shouldRender =
    settings?.wallet &&
    boostsAccountType === StripeAccountType.EXPRESS &&
    boostsAccountStatus !== StripeAccountStatus.ACTIVE;

  const [isManaging, setIsManaging] = useState<boolean>(false);
  const { mutateAsync: createStripeConnectAccount, isLoading: isCreating } = useCreateStripeConnectAccount({
    onSuccess: (res) => {
      window.open(`${res.url}`, '_blank');
    },
  });

  const continueOnboardingExpressAccount = () => {
    setIsManaging(true);
    createStripeConnectAccount({});
    setIsManaging(false);
  };

  if (!shouldRender) return null;

  return (
    <Section
      title="Continue Onboarding Stripe Express Account"
      description="Continue the onboarding steps to activate your Stripe Express account."
      scrollToId="continue-setup-express-account"
      isLoading={isLoading}
    >
      <Button
        variant="primary-inverse"
        type="submit"
        loading={isManaging || isCreating}
        disabled={isManaging || isCreating}
        onClick={() => continueOnboardingExpressAccount()}
      >
        {' '}
        Continue Onboarding{' '}
      </Button>
    </Section>
  );
};

export default ContinueOnboarding;
