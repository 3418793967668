import { Link } from 'react-router-dom';

import Text from '@/components/Text';
import { Button } from '@/ui/Button';

import ListItem from '../../../../components/_domain/EmailMessage/ListItem';
import LoadingBox from '../../../../components/LoadingBox';
import { useEmailMessages } from '../../../../hooks';
import { EmailMessageResourceType } from '../../../../interfaces/email_message';
import { useSegmentViewContext } from '../..';

const NoResults = ({ isLoading }: { isLoading: boolean }) => (
  <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
    <div className="flex flex-col items-center gap-4">
      <p className="text-gray-500 text-center">You don&apos;t have any previous sends to this specific segment.</p>
    </div>
  </div>
);

const Blasts = () => {
  const { segment } = useSegmentViewContext();

  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } = useEmailMessages({
    resourceId: segment.id,
    resourceType: EmailMessageResourceType.SEGMENT,
  });

  const emailMessages = data?.pages.flatMap((page) => page.email_messages) || [];
  const isNoResults = emailMessages.length === 0;

  return (
    <>
      <div className="max-w-5xl">
        <Text as="span" size="sm" className="text-gray-800 font-semibold">
          Note:{' '}
        </Text>
        <Text as="span" size="sm" className="text-gray-800">
          This feature has been deprecated so you can no longer create emails here. Any existing segment emails will
          eventually be converted to email-only posts. If you wish to send an email to a specific segment, you can use a{' '}
          <Link to="/posts" className="text-action-tertiary-600">
            regular post
          </Link>
          .
        </Text>
      </div>
      <LoadingBox isLoading={isLoading} isError={isError}>
        <>
          {isNoResults ? (
            <NoResults isLoading={isLoading} />
          ) : (
            <ul className="divide-y border rounded bg-white mt-4">
              {emailMessages.map((emailMessage) => (
                <ListItem
                  emailMessage={emailMessage}
                  viewUrl={`/segments/${segment.id}/blasts/${emailMessage.id}`}
                  editUrl={`/segments/${segment.id}/blasts/${emailMessage.id}/edit`}
                  allowDuplicate={false}
                />
              ))}
            </ul>
          )}
          <div className="text-center mt-6">
            {hasNextPage && (
              <div>
                <Button
                  variant="primary-inverse"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                </Button>
              </div>
            )}
          </div>
        </>
      </LoadingBox>
    </>
  );
};

export default Blasts;
