import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { Textarea } from '@/components/Form';
import { Typography } from '@/components/Typography';
import useWebApplication from '@/hooks/boosts/grow/useWebApplication';
import { BoostAgreement } from '@/interfaces/boosts/grow/boost_agreement';

export interface Props {
  isOpen: boolean;
  onSuccess?: () => void;
  onClickCancel: () => void;
  boostAgreement: BoostAgreement;
}

const ConfirmBoostAgreementRejectModal = ({ isOpen, boostAgreement, onClickCancel, onSuccess }: Props) => {
  const [rejectReason, setRejectReason] = useState('');

  const { handleReject, isRejecting } = useWebApplication(boostAgreement, {
    onRejectSuccess: onSuccess,
  });

  const handleClose = () => {
    onClickCancel();
  };

  return (
    <ActionModal
      isOpen={isOpen}
      isWorking={isRejecting}
      onClose={handleClose}
      onProceed={() => {
        handleReject(rejectReason);
        handleClose();
      }}
      resourceId={boostAgreement.id}
      actionText="Confirm"
    >
      <div className="flex flex-col gap-y-4">
        <Typography token="font-normal/text/sm">
          Are you sure you want to reject this Boost application from{' '}
          <strong>{boostAgreement.recommending_publication.name}</strong>?
        </Typography>

        <Textarea
          name="reject-reason"
          labelText="Share a reason for rejection (optional)"
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
          placeholderText="Not the right fit for our publication."
          helperText="This will be shared with the applicant."
          rows={3}
        />
      </div>
    </ActionModal>
  );
};

export default ConfirmBoostAgreementRejectModal;
