import Badge from '../../../components/Badge';
import { FormStatus } from '../../../interfaces/form';

const STATUS_DICTIONARY: {
  [key in FormStatus]: {
    badgeType: 'success' | 'alert' | 'warning' | 'information';
  };
} = {
  draft: {
    badgeType: 'information',
  },
  live: {
    badgeType: 'success',
  },
  archived: {
    badgeType: 'information',
  },
};

export interface Props {
  status: FormStatus;
}

const StatusBadge = ({ status }: Props) => {
  const { badgeType } = STATUS_DICTIONARY[status];

  return (
    <Badge type={badgeType}>
      <span className="text-xs capitalize">{status}</span>
    </Badge>
  );
};

export default StatusBadge;
