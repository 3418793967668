import { useState } from 'react';
import toast from 'react-hot-toast';

import { Input, Textarea } from '@/components/Form';
import { Typography } from '@/components/Typography';

interface Props {
  title: string;
  description?: string;
  placeholder?: string;
  value: string;
  onSave: (val: string) => Promise<any>;
  type?: 'input' | 'textarea';
  validationCheck?: (val: string) => boolean;
  validationFailMessage?: string;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
}

export default function InputWithDescription({
  title,
  description,
  value,
  onSave,
  placeholder,
  type = 'input',
  validationCheck,
  validationFailMessage,
  toastSuccessMessage,
  toastErrorMessage,
}: Props) {
  const [inputValue, setInputValue] = useState<string>(value);

  const handleSave = async () => {
    // Check for validation if one is provided
    if (validationCheck && inputValue) {
      if (!validationCheck(inputValue)) {
        toast.error(validationFailMessage || 'Something went wrong');
        return;
      }
    }

    if (inputValue !== value) {
      await onSave(inputValue)
        .then(() => {
          if (toastSuccessMessage) {
            toast.success(toastSuccessMessage);
          }
        })
        .catch(() => {
          if (toastErrorMessage) {
            toast.error(toastErrorMessage);
          }
        });
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <Typography token="font-medium/text/base" className="text-gray-900">
        {title}
      </Typography>
      {type === 'textarea' ? (
        <Textarea
          placeholderText={placeholder}
          className="w-full"
          name={title}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleSave}
        />
      ) : (
        <Input
          className="w-full"
          name={title}
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleSave}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSave();
            }
          }}
        />
      )}
      <Typography token="font-light/text/sm" className="text-gray-900">
        {description}
      </Typography>
    </div>
  );
}
