import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useSubscriptionLimitMeter = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchSubscriptionLimitMeter = () =>
    api.get(`/publications/${currentPublicationId}/subscription_limit_meter`).then((res) => res.data);

  return useQuery(['publication-subscription-limit-meter', currentPublicationId], fetchSubscriptionLimitMeter, {
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(currentPublicationId),
  });
};

export default useSubscriptionLimitMeter;
