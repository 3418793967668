import {
  CursorArrowRaysIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  NoSymbolIcon,
  UserMinusIcon,
} from '@heroicons/react/20/solid';
import { Grid } from '@tremor/react';

import ProgressIndicator from '@/components/ProgressIndicator';
import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { PostStats } from '@/interfaces/post';
import { MetricCard } from '@/ui/MetricCard';

import OpensChart from './OpensChart';

interface Props {
  stats: PostStats;
  postId: string;
}

const formatter = new Intl.NumberFormat('en');

const EmailStats = ({ stats, postId }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const totalRecipients = formatter.format(stats.total_sent);
  const totalOpens = formatter.format(stats.total_opened);
  const totalUniqueOpens = formatter.format(stats.total_unique_opened);
  const openRate = stats.open_rate;
  const averageOpenRate = stats.all_time_open_rate;
  const deliveryRate =
    stats.total_sent > 0 ? `${((stats.total_delivered / stats.total_sent) * 100).toFixed(2)}%` : '0%';
  const totalDelivered = formatter.format(stats.total_delivered);
  const unsubscribeRate =
    stats.total_unique_opened > 0
      ? `${((stats.total_unsubscribes / stats.total_unique_opened) * 100).toFixed(2)}%`
      : '0%';
  const totalSpamReports = formatter.format(stats.total_spam_reported);
  const totalUnsubscribes = formatter.format(stats.total_unsubscribes);
  const clickRate = stats.click_rate;
  const averageClickRate = stats.all_time_click_rate;
  const totalClicks = formatter.format(stats.total_email_clicked);
  const totalUniqueClicks = formatter.format(stats.total_unique_email_clicked);

  if (isV2) {
    return (
      <div className="flex flex-col gap-y-7">
        <Grid numCols={1} numColsSm={4} className="gap-4">
          <MetricCard
            variant="secondary"
            label="Recipients"
            value={totalRecipients}
            complements={[
              { label: 'Delivered', value: totalDelivered },
              { label: 'Delivery Rate', value: deliveryRate },
            ]}
          />
          <MetricCard
            variant="secondary"
            label="Open Rate"
            value={`${openRate}%`}
            complements={[
              { label: 'Unique Opens', value: totalUniqueOpens },
              { label: 'Total Opens', value: totalOpens },
            ]}
          />
          <MetricCard
            variant="secondary"
            label="Click Rate"
            value={`${clickRate}%`}
            complements={[
              { label: 'Unique Clicks', value: totalUniqueClicks },
              { label: 'Total Clicks', value: totalClicks },
            ]}
          />
          <MetricCard
            variant="secondary"
            label="Unsubscribe Rate"
            value={unsubscribeRate}
            complements={[
              { label: 'Unsubscribes', value: totalUnsubscribes },
              { label: 'Spam Reports', value: totalSpamReports },
            ]}
          />
        </Grid>
        <div className="flex flex-col gap-y-4">
          <Typography token="font-medium/text/base">Unique Opens Over Time</Typography>
          <OpensChart postId={postId} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="grid gap-8 grid-cols-1 md:grid-cols-3 lg:grid-cols-5 mb-12">
        <div className="flex md:w-auto w-full flex-col">
          <EnvelopeIcon className="h-6 w-6 text-gray-300 mb-4" />
          <div className="mb-8">
            <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Recipients</p>
            <p className="leading-none">
              <span className="text-5xl font-black text-primary-500">{totalRecipients}</span>
              <span className="text-gray-400 tracking-tighter text-sm"> </span>
            </p>
          </div>
        </div>

        <div className="flex md:w-auto w-full flex-col justify-between">
          <EnvelopeOpenIcon className="h-6 w-6 text-gray-300 mb-4" />
          <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Opens</p>
          <p className="leading-none">
            <span className="text-5xl font-black text-primary-500">{totalUniqueOpens}</span>
            <span className="text-gray-400 tracking-tighter text-sm"> unique</span>
          </p>
          <p className="leading-none">
            <span className="text-gray-400 tracking-tighter text-sm">
              vs. <strong className="text-gray-600 text-base">{totalOpens}</strong> total
            </span>
          </p>
          <div className="my-8">
            <ProgressIndicator value={openRate} label="OPEN RATE" averageValue={averageOpenRate} />
          </div>
        </div>
        <div className="flex md:w-auto w-full flex-col justify-between">
          <CursorArrowRaysIcon className="h-6 w-6 text-gray-300 mb-4" />
          <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Clicks</p>
          <p className="leading-none">
            <span className="text-5xl font-black text-primary-500">{totalUniqueClicks}</span>
            <span className="text-gray-400 tracking-tighter text-sm"> unique</span>
          </p>
          <p className="leading-none">
            <span className="text-gray-400 tracking-tighter text-sm">
              vs. <strong className="text-gray-600 text-base">{totalClicks}</strong> total
            </span>
          </p>
          <div className="my-8">
            <ProgressIndicator value={clickRate} label="CTR" averageValue={averageClickRate} />
          </div>
        </div>

        <div className="flex md:w-auto w-full flex-col">
          <UserMinusIcon className="h-6 w-6 text-gray-300 mb-4" />
          <div className="mb-8">
            <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">UNSUBSCRIBES</p>
            <p className="leading-none">
              <span className="text-5xl font-black text-primary-500">{totalUnsubscribes}</span>
              <span className="text-gray-400 tracking-tighter text-sm"> </span>
            </p>
          </div>
        </div>

        <div className="flex md:w-auto w-full flex-col">
          <NoSymbolIcon className="h-6 w-6 text-gray-300 mb-4" />
          <div className="mb-8">
            <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Spam reports</p>
            <p className="leading-none">
              <span className="text-5xl font-black text-primary-500">{totalSpamReports}</span>
              <span className="text-gray-400 tracking-tighter text-sm"> </span>
            </p>
          </div>
        </div>
      </div>

      <div className="my-16 space-y-2 mb-12">
        <div className="flex items-center space-x-2">
          <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Unique opens</p>
          <Tooltip text="Total unique email opens in each hour after publishing" id="opens" />
        </div>
        <OpensChart postId={postId} />
      </div>
    </>
  );
};

export default EmailStats;
