import styled from 'styled-components';

import { colors } from '../../../lib/colors';
import StyledIcon from '../Icon/styled';

export const StyledWrapper = styled.label<{
  isDisabled?: boolean;
}>`
  align-items: center;
  cursor: ${(p) => (p.isDisabled ? 'auto' : 'pointer')};
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  line-height: 1.15;
  opacity: ${(p) => (p.isDisabled ? 0.4 : 1)};
  padding: 0.375rem;
`;

export const StyledLabel = styled.div`
  align-items: center;
  color: ${colors.white[2]};
  display: flex;
  flex: 1;
  font-size: 0.875rem;
  font-weight: 500;
  gap: 0.5rem;

  ${StyledIcon.Wrapper} svg {
    width: 1rem;
    height: 1rem;
  }

  ${StyledIcon.Wrapper} svg,
  ${StyledIcon.Wrapper} svg path {
    fill: ${colors.white[3]};
  }
`;

export default StyledWrapper;
