import { Dispatch, SetStateAction } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

interface Props {
  largeNavOpen: boolean;
  setLargeNavOpen: Dispatch<SetStateAction<boolean>>;
}

const SizingConfiguration = ({ largeNavOpen, setLargeNavOpen }: Props) => {
  return (
    <div className="h-full py-2 w-fit my-auto flex items-center">
      <button
        type="button"
        className="px-1.5 py-2 bg-surface-50 border-surface-100 hover:bg-surface-100 border-y border-r rounded-r-lg"
        onClick={() => setLargeNavOpen(!largeNavOpen)}
      >
        <ChevronLeftIcon className={cx('h-4 w-4 text-surface-700', !largeNavOpen && 'rotate-180')} />
      </button>
    </div>
  );
};

export default SizingConfiguration;
