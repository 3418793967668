import { PartialPost, Post } from '../../../interfaces/post';
import { SortableList, SortableListItem } from '../index';

type FeaturedPosts = Array<Post | PartialPost>;
type FeaturedPost = Post | PartialPost;

interface SortFeaturedPostsProps {
  featuredPosts: FeaturedPosts[] | any;
  onItemRemoved?: (newList: any) => void;
  onItemsOrdered?: (newList: any) => void;
  itemToAppend?: PartialPost | null;
  onItemAppended?: (newList: any) => void;
}

const SortFeaturedPosts = ({
  featuredPosts,
  onItemRemoved,
  onItemsOrdered,
  itemToAppend,
  onItemAppended,
}: SortFeaturedPostsProps) => {
  return (
    <SortableList
      listItems={featuredPosts}
      onItemRemoved={onItemRemoved}
      onItemsOrdered={(newList) => (onItemsOrdered ? onItemsOrdered(newList) : null)}
      itemToAppend={itemToAppend}
      onItemAppended={(newList) => (onItemAppended ? onItemAppended(newList) : null)}
    >
      {(list, onRemoveItem) => {
        return list.map((post: FeaturedPost, index: any) => {
          return (
            <SortableListItem
              key={post.id}
              listItem={post}
              onRemoveItem={() => onRemoveItem(index)}
              text={post.web_title}
            />
          );
        });
      }}
    </SortableList>
  );
};

export default SortFeaturedPosts;
