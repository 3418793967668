import { useLocation, useNavigate } from 'react-router-dom';

import { SimpleSelect } from '../../../components/Form';
import { useSettings } from '../../../context/settings-context';

const Navigator = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { settings } = useSettings();
  const isLandingPagesEnabled = settings?.landing_pages;
  const isUpgradePageEnabled = settings?.upgrade_page;

  const navigationOptions = [
    {
      enabled: true,
      label: 'Newsletter',
      value: '/post_themes/edit',
    },
    {
      enabled: isLandingPagesEnabled,
      label: 'Landing Page',
      value: '/design_lab/landing_page',
    },
    {
      enabled: isUpgradePageEnabled,
      label: 'Upgrade Page',
      value: '/design_lab/upgrade_page',
    },
    {
      enabled: isLandingPagesEnabled,
      label: 'Home Page',
      value: '/design_lab/home_page',
    },
  ];

  const filteredOptions = navigationOptions.filter((option) => option.enabled);
  const currentLocation = filteredOptions.find((option) => option.value === pathname);
  const renderNavigator = filteredOptions.length > 1;

  if (!renderNavigator) {
    return null;
  }

  return (
    <div className="w-48">
      <SimpleSelect
        className="w-full"
        name="navigator"
        dropdownDirection="up"
        value={currentLocation?.value || ''}
        options={filteredOptions}
        onSelect={(_name: string, value: string) => {
          navigate(value);
        }}
      />
    </div>
  );
};

export default Navigator;
