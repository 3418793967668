import axios, { AxiosRequestConfig } from 'axios';

/**
 * @description Base api instance for all swarm endpoints.
 */
const api = axios.create({
  baseURL: `${window?.env?.REACT_APP_API_BASE_URL}/api/v2`,
});

api.interceptors.request.use((axiosConfig: AxiosRequestConfig) => {
  const masqueradeToken = localStorage.getItem('masqueradeToken');
  const realToken = localStorage.getItem('token');

  const token = masqueradeToken || realToken;

  const config = axiosConfig;

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  /**
   * Passing along the true current user as well in case we need to
   * do things only a system admin can do. (For example, ending the
   * masquerade session.)
   */
  if (realToken && masqueradeToken) {
    config.headers = {
      ...config.headers,
      'X-True-User': `Bearer ${realToken}`,
    };
  }

  config.withCredentials = true;

  return config;
});

const UNAUTHORIZED = 401;

/**
 * @description Automatically handle 401 Unauthorized errors and redirect to login
 */
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === UNAUTHORIZED) {
      localStorage.clear();
      const existingPath = window.location.pathname;

      if (existingPath === '/login') {
        window.location.href = '/login';
      } else {
        window.location.href = `/login?redirect=${existingPath}`;
      }
    }
    return Promise.reject(error);
  }
);

export default api;
