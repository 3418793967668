import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ArchiveBoxIcon, LinkIcon, LockClosedIcon, PencilIcon, TrashIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import SplitButton from '@/components/SplitButton';
import { useCopyToClipboard, usePost } from '@/hooks';
import { Post, PostPlatform, PostStatus } from '@/interfaces/post';
import api from '@/services/swarm';

interface Props {
  post: Post;
}

const PostActions = ({ post }: Props) => {
  const postQuery = usePost({ id: post.id });
  const [deleteRequested, setDeleteRequested] = useState(false);
  const navigate = useNavigate();

  const onDeleteSelected = () => {
    setDeleteRequested(true);
  };

  const onActionModalClose = () => {
    setDeleteRequested(false);
  };

  const deleteMutation = useMutation(() => api.delete(`/posts/${post.id}`).then((res) => res.data), {
    onSuccess: () => {
      navigate('/posts');

      toast.success('Post deleted successfully');
    },
  });

  const isDeleting = deleteMutation.isLoading;

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  const [archiveRequested, setArchiveRequested] = useState(false);

  const onArchiveSelected = () => {
    setArchiveRequested(true);
  };

  const onArchiveModalClose = () => {
    setArchiveRequested(false);
  };

  const archiveMutation = useMutation(
    () =>
      api
        .patch(`/posts/${post.id}/transition`, {
          status: 'archived',
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        setArchiveRequested(false);
        postQuery.refetch();
        toast.success('Post archived successfully');
      },
    }
  );

  const isArchiving = archiveMutation.isLoading;

  const handleArchive = () => {
    archiveMutation.mutate();
  };

  const unarchiveMutation = useMutation(
    () =>
      api
        .patch(`/posts/${post.id}/transition`, {
          status: 'confirmed',
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        postQuery.refetch();
        toast.success('Post unarchived successfully');
      },
    }
  );

  const hasLiveUrl =
    post &&
    post.url &&
    post.status === PostStatus.PUBLISHED &&
    (post.platform === PostPlatform.WEB || post.platform === PostPlatform.BOTH);

  const copy = useCopyToClipboard();

  return (
    <>
      {post?.deletable && (
        <ActionModal
          isOpen={deleteRequested}
          onClose={onActionModalClose}
          onProceed={handleDelete}
          resourceId={post.id}
          isWorking={isDeleting}
          headerText="Delete post"
          actionText="Delete"
        >
          Are you sure you want to delete this post?
        </ActionModal>
      )}
      {post?.archivable && (
        <ActionModal
          isOpen={archiveRequested}
          onClose={onArchiveModalClose}
          onProceed={handleArchive}
          resourceId={post.id}
          isWorking={isArchiving}
          headerText="Archive post"
          actionText="Archive"
        >
          Are you sure you want to archive this post?
        </ActionModal>
      )}
      <SplitButton
        size="xs"
        buttons={[
          {
            label: 'Edit post',
            helperText: '',
            onClick: () =>
              post?.editing_locked
                ? toast.error(post?.editing_locked_reason || 'There was a problem editing this post.')
                : navigate(`/posts/${post.id}/edit`),
            isVisible: post?.status !== PostStatus.ARCHIVED,
            button: {
              text: 'Edit post',
              Icon: post?.editing_locked ? LockClosedIcon : PencilIcon,
            },
            defaultSelected: true,
          },
          {
            label: 'View published',
            helperText: '',
            onClick: () => window.open(post.url),
            isVisible: hasLiveUrl === true,
            button: {
              text: 'View published',
              Icon: LinkIcon,
            },
          },
          {
            label: 'Copy link',
            helperText: '',
            onClick: () => copy({ text: post.url }),
            isVisible: hasLiveUrl === true,
            button: {
              text: 'Copy link',
              Icon: LinkIcon,
            },
          },
          {
            label: 'Delete',
            helperText: '',
            onClick: onDeleteSelected,
            isVisible: post?.deletable,
            button: {
              text: 'Delete',
              Icon: TrashIcon,
            },
          },
          {
            label: 'Archive',
            helperText: '',
            onClick: onArchiveSelected,
            isVisible: post?.archivable,
            disabled: post.editing_locked,
            button: {
              text: 'Archive',
              Icon: TrashIcon,
            },
          },
          {
            label: 'Unarchive',
            helperText: '',
            onClick: () => unarchiveMutation.mutate(),
            isVisible: post?.status === PostStatus.ARCHIVED,
            button: {
              text: 'Unarchive',
              Icon: ArchiveBoxIcon,
            },
          },
        ]}
      />
    </>
  );
};

export default PostActions;
