import { Grid } from '@tremor/react';

import { PostStats } from '@/interfaces/post';
import { MetricCard } from '@/ui/MetricCard';

interface Props {
  stats: PostStats;
}

const formatter = new Intl.NumberFormat('en');

const WebAndEmailStats = ({ stats }: Props) => {
  const totalImpressions = formatter.format(stats.total_web_viewed + stats.total_unique_opened);
  const totalClicks = formatter.format(stats.total_web_clicked + stats.total_email_clicked);

  return (
    <div className="flex flex-col gap-y-3">
      <Grid numCols={1} numColsSm={2} className="gap-4">
        <MetricCard variant="secondary" label="Total Impressions" value={totalImpressions} />
        <MetricCard variant="secondary" label="Total Clicks" value={totalClicks} />
      </Grid>
    </div>
  );
};

export default WebAndEmailStats;
