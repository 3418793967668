import moment from 'moment-mini';

import { toSentence } from '@/utils';
import { weekdays as weekdayOptions } from '@/values/weekdays';

export const weekdaysDisplay = (weekdays: any) => {
  if (!weekdays) {
    return '-';
  }

  if (weekdays.length > 0) {
    return toSentence(
      '',
      weekdays.map((weekday: string) => weekdayOptions.find((option) => option.value === weekday)?.label)
    );
  }

  return 'Any day of the week';
};

export const timeOfDayDisplay = (timeOfDay: any) => {
  if (!timeOfDay) {
    return '-';
  }

  const parsedTimeOfDay = moment().startOf('day').add(timeOfDay, 'minutes');

  return parsedTimeOfDay.format('LT');
};

export const timeZoneDisplay = (timeZone: any, currentPublicationTimeZone?: string) => {
  if (typeof timeZone === 'undefined') {
    return '-';
  }

  if (timeZone === currentPublicationTimeZone) {
    return `Default timezone (${currentPublicationTimeZone})`;
  }

  if (timeZone === '') {
    return 'Timezone of the subscriber';
  }

  return timeZone;
};
