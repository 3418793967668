import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import shuffle from 'lodash.shuffle';

import Text from '../../../../components/Text';
import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { useCurrentUser } from '../../../../context/current-user-context';
import { useOrganization } from '../../../../hooks';
import { useBilling, useStripeApi, useStripeBillingSession } from '../../../../hooks/useBilling';
import useCreateDowngrade from '../../../../hooks/useDowngrades/useCreateDowngrade';
import useUpdateDowngrade from '../../../../hooks/useDowngrades/useUpdateDowngrade';
import { usePublication } from '../../../../hooks/usePublications';
import { BillingActions } from '../../../../interfaces/billing';
import { DowngradeReason, DowngradeStatus } from '../../../../interfaces/downgrade';

import PlanBadge from './components/PlanBadge';
import Review from './components/Review';
import StepBar from './components/StepBar';
import Confirmation from './pages/Confirmation';
import Feedback from './pages/Feedback';
import OfferAndPause from './pages/OfferAndPause';
import SpeakWithUs from './pages/SpeakWithUs';

const stepLabels = ['Review Changes', 'Cancellation survey', '', '', 'Confirmation'];

const shuffledReasons = () => {
  const reasons = shuffle([
    { name: 'Too expensive', value: 'too_expensive' },
    { name: 'Not enough subscriber growth', value: 'no_growth' },
    { name: 'Just taking a break', value: 'taking_break' },
    { name: 'Technical issues', value: 'technical_issues' },
    { name: 'Missing feature(s)', value: 'missing_features' },
    { name: 'Moving to another platform', value: 'switched_service' },
    { name: 'Too hard to learn', value: 'too_difficult' },
  ]);
  reasons.push({ name: 'Other (please specify)', value: 'other' });

  return reasons;
};

const Downgrade = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [publicationId] = useCurrentPublicationState();
  const currentPublication = usePublication(publicationId)?.data;
  const organizationQuery = useOrganization(currentPublication?.organization_id);
  const { organization } = organizationQuery?.data || {};
  const { data: billingDetails } = useBilling(organization?.id);
  const stripeDowngradeSession = useStripeBillingSession({
    organizationId: billingDetails?.organization_id || '',
    action: BillingActions.DOWNGRADE,
  });
  const redirectToBillingHome = () => {
    window.location.href = '/settings/billing';
  };
  const stripeApiSession = useStripeApi({
    organizationId: billingDetails?.organization_id || '',
    action: BillingActions.DOWNGRADE,
    redirectToBillingHome,
  });

  const [step, setStep] = useState(1);
  const [previousStep, setPreviousStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isDowngrading, setIsDowngrading] = useState(false);
  const [downgradeId, setDowngradeId] = useState(undefined);
  const [reasons] = useState<{ name: string; value: string }[]>(shuffledReasons());
  const [reason, setReason] = useState<DowngradeReason | undefined>(undefined);
  const [feedback, setFeedback] = useState<string>('');

  const onReasonChange = (value: DowngradeReason) => {
    setReason(value);
  };

  const onFeedbackChange = (value: string) => {
    setFeedback(value);
  };

  const onPreviousStep = () => {
    setPreviousStep(step);
    setStep(step - 1);
  };

  const onNextStep = () => {
    setPreviousStep(step);
    setStep(step + 1);
    setIsLoading(false);
  };

  const downgradeInStripe = () => {
    if (stripeApiSession.isLoading) {
      return null;
    }
    return stripeApiSession.mutate();
  };

  const { mutateAsync: create } = useCreateDowngrade({
    organizationId: organization?.id,
    onSuccess: (res: any) => {
      setDowngradeId(res.data.downgrade.id);
    },
  });

  const update = useUpdateDowngrade({
    organizationId: organization?.id,
    downgradeId: downgradeId || '',
    onSuccess: () => {
      onNextStep();
    },
  });

  const complete = useUpdateDowngrade({
    organizationId: organization?.id,
    downgradeId: downgradeId || '',
    onSuccess: () => {
      downgradeInStripe();
    },
  });

  const onFeedbackNextStep = () => {
    if (!reason) {
      return toast.error('Please tell us why you want to downgrade');
    }

    setIsLoading(true);
    return update.mutate({
      status: DowngradeStatus.FEEDBACK_PROVIDED,
      reason: reason as DowngradeReason,
      feedback,
    });
  };

  const onDowngrade = () => {
    setIsDowngrading(true);
    complete.mutate({ status: DowngradeStatus.COMPLETED });
  };

  const onCancel = () => navigate('/');

  const stepElements = [
    <Review
      billingDetails={billingDetails}
      isLoading={stripeDowngradeSession.isLoading}
      onCancel={onCancel}
      onNextStep={onNextStep}
    />,
    <Feedback
      user={currentUser}
      reasons={reasons}
      reason={reason}
      onReasonChange={onReasonChange}
      feedback={feedback}
      onFeedbackChange={onFeedbackChange}
      onPreviousStep={onPreviousStep}
      onNextStep={onFeedbackNextStep}
      onCancel={onCancel}
      isLoading={isLoading}
    />,
    <OfferAndPause
      user={currentUser}
      onPreviousStep={onPreviousStep}
      onNextStep={onNextStep}
      onCancel={onCancel}
      step={step}
      previousStep={previousStep}
      downgradeId={downgradeId || ''}
      isLoading={isLoading}
    />,
    <SpeakWithUs
      user={currentUser}
      billingDetails={billingDetails}
      reason={reason}
      onPreviousStep={onPreviousStep}
      onNextStep={onNextStep}
      onCancel={onCancel}
      isLoading={isLoading}
    />,
    <Confirmation
      billingDetails={billingDetails}
      isLoading={stripeDowngradeSession.isLoading}
      onCancel={onCancel}
      onDowngrade={onDowngrade}
      isDowngrading={isDowngrading}
      onPreviousStep={onPreviousStep}
    />,
  ];

  const stepProgressions = [15, 50, 75, 75, 100];

  useEffect(() => {
    if (!organization?.id && currentPublication?.id) return;
    create({ status: DowngradeStatus.STARTED });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.id, currentPublication?.id]);

  return (
    <div className="bg-white">
      <nav className="px-6 w-full h-12 border-b border-gray-200 items-center flex justify-between">
        <Text type="bold" size="sm" className="text-gray-600">
          Downgrade
        </Text>

        <PlanBadge size="sm" plan={billingDetails?.current_plan} />
      </nav>

      <div className="container mx-auto max-w-2xl py-10 px-6 lg:px-0 text-surface-700">
        <StepBar labels={stepLabels} stepProgressions={stepProgressions} currentStep={step} />
        {stepElements[step - 1]}
      </div>
    </div>
  );
};

export default Downgrade;
