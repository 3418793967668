import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { PencilSquareIcon, RectangleGroupIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import { WriteButton } from '@/components/UI/WriteButton';
import { useCreateDraft } from '@/hooks';

interface Props {
  largeNavOpen: boolean;
}

interface SmallNavProps {
  buttons: any;
}

const SmallNav = ({ buttons }: SmallNavProps) => {
  return (
    <Menu as="div" className="relative my-auto h-fit flex flex-col text-left">
      <Menu.Button>
        <button
          type="button"
          className="p-2 w-fit mx-auto bg-action-tertiary-50 hover:bg-action-tertiary-100 aspect-1 rounded-lg border border-action-tertiary-200 flex flex-col justify-center items-center"
        >
          <PencilSquareIcon className="h-4 w-4 text-action-tertiary-900" />
        </button>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cx(
            'z-50 w-48 absolute mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            'top-10'
          )}
        >
          <div className="py-1.5 flex flex-col">
            {buttons.map((button: any) => (
              <Menu.Item key={button.label}>
                <button
                  type="button"
                  onClick={button.onClick}
                  className="flex flex-col hover:bg-surface-100 justify-start text-start items-start px-3 py-2 space-y-1"
                >
                  <Typography token="font-medium/text/sm" className="text-gray-700">
                    {button.label}
                  </Typography>
                  <Typography token="font-normal/text/xs" className="text-gray-500">
                    {button.helperText}
                  </Typography>
                </button>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const NavStartWriting = ({ largeNavOpen }: Props) => {
  const createDraftMutation = useCreateDraft();
  const navigate = useNavigate();

  const buttons = [
    {
      label: 'Blank draft',
      helperText: 'Create a blank draft from scratch',
      onClick: () => createDraftMutation.mutateAsync({}),
      button: {
        text: 'Start writing',
        Icon: PencilSquareIcon,
      },
    },
    {
      label: 'Use template',
      helperText: 'Create a draft using a custom template',
      onClick: () => navigate('/templates/posts'),
      button: {
        text: 'Choose template',
        Icon: RectangleGroupIcon,
      },
    },
  ];

  if (!largeNavOpen) {
    return <SmallNav buttons={buttons} />;
  }

  return (
    <WriteButton
      className="w-full"
      buttonClassName="w-full flex flex-row !justify-start items-start py-1.5"
      dropdownClassName="w-full"
      dropdownDownDirection="left"
      size="sm"
      buttons={buttons}
    />
  );
};

export default NavStartWriting;
