import { useState } from 'react';

import { Textarea } from '@/components/Form';
import useAddRecommendation from '@/hooks/useAddRecommendation';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

interface Props {
  publicationId: string;
  publicationName: string;
  onSubmit: () => void;
}

export default function AddRecommendationForm({ publicationId, publicationName, onSubmit }: Props) {
  const [optionWithReason, setOptionWithReason] = useState<string | undefined>();
  const [reason, setReason] = useState('');

  const { mutateAsync: addRecommendation } = useAddRecommendation({ onSuccess: onSubmit });

  return (
    <div className="flex flex-auto flex-col justify-between p-6 space-y-4">
      <div>
        <Textarea
          name="reason"
          value={optionWithReason === publicationId ? reason : ''}
          onChange={(e) => {
            setOptionWithReason(publicationId);
            setReason(e.target.value);
          }}
          labelText="Reason for recommending"
          placeholderText={`I like ${publicationName} because ...`}
        />
      </div>
      <Button
        variant="primary"
        onClick={() => {
          analytics.track('Added Recommendation');
          addRecommendation({ publicationId, reason });
        }}
      >
        Add recommendation
      </Button>
    </div>
  );
}
