import React, { useRef, useState } from 'react';
import cx from 'classnames';
import styled from 'styled-components';

import { useClickedOutside } from '@/hooks';

import ColorPicker from './ColorPicker';

const StyledButton: any = styled.button.attrs({
  type: 'button',
  className:
    'relative w-20 h-6 rounded shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
})`
  background: ${({ bgColor }: any) => bgColor || ''};
`;

interface ColorPopoverProps {
  side?: 'left' | 'right';
  placement: 'top' | 'bottom' | 'center';
  color: string;
  onColorChange: (color: string) => void;
  onClickOutside?: (color: string) => void;
}

const ColorPopover: React.FC<ColorPopoverProps> = ({
  color,
  onColorChange,
  onClickOutside,
  placement = 'top',
  side = 'right',
}: ColorPopoverProps) => {
  const [isShowing, setIsShowing] = useState(false);
  const ref = useRef<any>();
  useClickedOutside(ref, () => {
    setIsShowing(false);
    if (onClickOutside) {
      onClickOutside(color);
    }
  });
  const isTop = placement === 'top';
  const isCenter = placement === 'center';
  const isBottom = placement === 'bottom';
  const isLeft = side === 'left';

  return (
    <div ref={ref} className="relative w-min z-10">
      <StyledButton bgColor={color} onClick={() => setIsShowing(true)} />

      {isShowing && (
        <div
          className={cx(
            'absolute z-10 p-4 bg-white rounded border border-gray-100 shadow-md',
            isTop && 'top-0',
            isCenter && '-top-40',
            isBottom && 'bottom-0',
            isLeft ? 'right-full' : 'left-full'
          )}
        >
          <ColorPicker
            width={215}
            themeColors={[]}
            initialColor={color}
            onColorChange={(c: string) => onColorChange(c)}
            preventDefault
          />
        </div>
      )}
    </div>
  );
};

export default ColorPopover;
