import React from 'react';

import { Textarea } from '@/components/Form';
import Input from '@/components/Form/Input';

interface Props {
  automationName: string;
  automationDescription: string;
  onAutomationNameChange: (value: string) => void;
  onAutomationDescriptionChange: (value: string) => void;
}

const MetadataForm = ({
  automationName,
  automationDescription,
  onAutomationNameChange,
  onAutomationDescriptionChange,
}: Props) => {
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => onAutomationNameChange(e.target.value);
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    onAutomationDescriptionChange(e.target.value);

  return (
    <div className="space-y-6">
      <Input
        name="name"
        labelText="Name"
        placeholderText="Automation name"
        type="text"
        value={automationName}
        onChange={handleNameChange}
        required
      />

      <Textarea
        name="description"
        labelText="Description"
        placeholderText="Automation description"
        value={automationDescription}
        onChange={handleDescriptionChange}
      />
    </div>
  );
};

export default MetadataForm;
