import Badge from '@/components/Badge';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import useSvixMessageAttempts from '@/hooks/webhooks/useSvixMessageAttempts';
import { MessageAttemptStatus } from '@/interfaces/webhooks/message_attempt';

type Props = {
  endpointId: string;
  className?: string;
  messageText?: string;
};

const errorRateBadgeType = (errorRate: number) => {
  if (errorRate < 5) return 'success';
  if (errorRate < 20) return 'warning';

  return 'alert';
};

const ErrorRate = ({ endpointId, className, messageText }: Props) => {
  const messageAttemptsQuery = useSvixMessageAttempts({ endpointId });
  const { data, isLoading } = messageAttemptsQuery;
  const messageAttempts = data?.pages.flatMap((page) => page.message_attempts) || [];
  const failedMessages = messageAttempts.filter((message) => message.status === MessageAttemptStatus.FAILED);
  const errorRate =
    messageAttempts.length === 0 ? 0 : Math.round((failedMessages.length / messageAttempts.length) * 100 * 100) / 100;

  return (
    <Badge type={errorRateBadgeType(errorRate)} className={className}>
      <div className="space-y-1 flex flex-col">
        {messageText && <span className="text-xs">{messageText}</span>}
        {isLoading ? <LoadingSpinner /> : `${errorRate}%`}
      </div>
    </Badge>
  );
};

export default ErrorRate;
