import { FC } from 'react';

import { AssetSelect, Checkbox, Input, Textarea } from '../../components/Form';
import ColorField from '../../components/Form/ColorField';

import Slider from './components/Slider';
import ThemeEditorDropdown from './components/ThemeEditorDropdown';
import { Divider, FormContainer, FormInput, FormSection } from './helpers';
import { ThemeFormProps } from './types';

const HeaderSectionForm: FC<ThemeFormProps> = ({ theme, onUpdate, colorPalette, fonts, currentPublicationId }) => {
  return (
    <FormContainer>
      <FormSection title="Title">
        <FormInput title="Font family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.header_title_font_family}
              onUpdate={(val: string) => onUpdate({ ...theme, header_title_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Font color">
          <ColorField
            placement="bottom"
            color={theme.header_title_font_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, header_title_font_color: color?.hexString })}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="header_title_font_size"
            label="Font size"
            onChange={(val: number) => onUpdate({ ...theme, header_title_font_size: val.toString() })}
            initialValue={theme.header_title_font_size}
            min={12}
            max={50}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="header_title_vertical_padding"
            label="Vertical spacing"
            onChange={(val: number) => onUpdate({ ...theme, header_title_vertical_padding: val.toString() })}
            initialValue={theme.header_title_vertical_padding}
            min={0}
            max={16}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Font weight">
          <ThemeEditorDropdown
            currentValue={theme.header_title_font_weight}
            onUpdate={(val: string) => onUpdate({ ...theme, header_title_font_weight: val })}
            options={[
              { label: 'Lighter', value: 'Lighter' },
              { label: 'Normal', value: 'Normal' },
              { label: 'Bold', value: 'Bold' },
            ]}
          />
        </FormInput>
        <FormInput title="Text alignment">
          <ThemeEditorDropdown
            currentValue={theme.header_title_alignment}
            onUpdate={(val: string) => onUpdate({ ...theme, header_title_alignment: val })}
            options={[
              { label: 'Left', value: 'Left' },
              { label: 'Center', value: 'Center' },
              { label: 'Right', value: 'Right' },
            ]}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Subtitle">
        <FormInput title="Font family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.header_subtitle_font_family}
              onUpdate={(val: string) => onUpdate({ ...theme, header_subtitle_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Font color">
          <ColorField
            placement="bottom"
            color={theme.header_subtitle_font_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, header_subtitle_font_color: color?.hexString })}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="header_subtitle_font_size"
            label="Font size"
            onChange={(val: number) => onUpdate({ ...theme, header_subtitle_font_size: val.toString() })}
            initialValue={theme.header_subtitle_font_size}
            min={12}
            max={50}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="header_title_vertical_padding"
            label="Vertical spacing"
            onChange={(val: number) => onUpdate({ ...theme, header_subtitle_vertical_padding: val.toString() })}
            initialValue={theme.header_subtitle_vertical_padding}
            min={0}
            max={16}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Font weight">
          <ThemeEditorDropdown
            currentValue={theme.header_subtitle_font_weight}
            onUpdate={(val: string) => onUpdate({ ...theme, header_subtitle_font_weight: val })}
            options={[
              { label: 'Lighter', value: 'Lighter' },
              { label: 'Normal', value: 'Normal' },
              { label: 'Bold', value: 'Bold' },
            ]}
          />
        </FormInput>
        <FormInput title="Text alignment">
          <ThemeEditorDropdown
            currentValue={theme.header_subtitle_alignment}
            onUpdate={(val: string) => onUpdate({ ...theme, header_subtitle_alignment: val })}
            options={[
              { label: 'Left', value: 'Left' },
              { label: 'Center', value: 'Center' },
              { label: 'Right', value: 'Right' },
            ]}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Byline">
        <FormInput>
          <Checkbox
            name="header_byline_underline_author"
            onChange={(checked: boolean) => onUpdate({ ...theme, header_byline_underline_author: checked.toString() })}
            labelText="Underline author links?"
            checked={theme.header_byline_underline_author === 'true'}
            className="select-none"
          />
        </FormInput>
        <FormInput title="Font family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.header_byline_font_family}
              onUpdate={(val: string) => onUpdate({ ...theme, header_byline_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Font color">
          <ColorField
            placement="bottom"
            color={theme.header_byline_font_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, header_byline_font_color: color?.hexString })}
          />
        </FormInput>
        <FormInput title="Accent color">
          <ColorField
            placement="bottom"
            color={theme.header_byline_accent_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, header_byline_accent_color: color?.hexString })}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="header_byline_font_size"
            label="Font size"
            onChange={(val: number) => onUpdate({ ...theme, header_byline_font_size: val.toString() })}
            initialValue={theme.header_byline_font_size}
            min={12}
            max={50}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="header_byline_avatar_size"
            label="Avatar size"
            onChange={(val: number) => onUpdate({ ...theme, header_byline_avatar_size: val.toString() })}
            initialValue={theme.header_byline_avatar_size}
            min={30}
            max={75}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="header_byline_vertical_padding"
            label="Vertical spacing"
            onChange={(val: number) => onUpdate({ ...theme, header_byline_vertical_padding: val.toString() })}
            initialValue={theme.header_byline_vertical_padding}
            min={0}
            max={30}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Font weight">
          <ThemeEditorDropdown
            currentValue={theme.header_byline_font_weight}
            onUpdate={(val: string) => onUpdate({ ...theme, header_byline_font_weight: val })}
            options={[
              { label: 'Lighter', value: 'Lighter' },
              { label: 'Normal', value: 'Normal' },
              { label: 'Bold', value: 'Bold' },
            ]}
          />
        </FormInput>
        <FormInput title="Date format">
          <ThemeEditorDropdown
            currentValue={theme.header_byline_date_format}
            onUpdate={(val: string) => onUpdate({ ...theme, header_byline_date_format: val })}
            options={[
              { label: 'Long', value: 'Long' },
              { label: 'Long Ordinal', value: 'Long Ordinal' },
              { label: 'Short', value: 'Short' },
              { label: 'None', value: 'None' },
            ]}
          />
        </FormInput>

        <FormInput>
          <Checkbox
            name="header_byline_reading_time_enabled"
            onChange={(checked: boolean) =>
              onUpdate({ ...theme, header_byline_reading_time_enabled: checked.toString() })
            }
            labelText="Show reading time?"
            checked={theme.header_byline_reading_time_enabled === 'true'}
            className="select-none"
          />
        </FormInput>

        {theme.header_byline_reading_time_enabled === 'true' && (
          <>
            <FormInput title="Reading time font color">
              <ColorField
                placement="bottom"
                color={theme.header_byline_reading_time_font_color}
                colorPalette={colorPalette}
                onColorChange={(color: any) =>
                  onUpdate({ ...theme, header_byline_reading_time_font_color: color?.hexString })
                }
              />
            </FormInput>
            <FormInput title="Reading time label">
              <Input
                onChange={(event) => onUpdate({ ...theme, header_byline_reading_time_label: event.target.value })}
                value={theme.header_byline_reading_time_label}
                type="text"
                name="Reading time label"
              />
            </FormInput>
          </>
        )}
      </FormSection>
      <Divider />
      <FormSection title="Spacing">
        <FormInput>
          <Slider
            name="header_padding"
            label="Inner spacing"
            onChange={(val: number) => onUpdate({ ...theme, header_padding: val.toString() })}
            initialValue={theme.header_padding}
            min={0}
            max={50}
            valueSuffix="px"
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Miscellaneous">
        {currentPublicationId && (
          <FormInput>
            <AssetSelect
              labelText="Custom header image"
              asset={theme.header_asset}
              publicationId={currentPublicationId}
              dimensionSuggestion="1200px x 630px recommended"
              onSelect={(selected) => onUpdate({ ...theme, header_asset_id: selected.id })}
              onClear={() => onUpdate({ ...theme, header_asset_id: null })}
            />
          </FormInput>
        )}
        {theme.header_asset_id && (
          <FormInput>
            <Slider
              label="Logo width"
              name="header_asset_width"
              onChange={(val: number) => onUpdate({ ...theme, header_asset_width: val.toString() })}
              initialValue={theme.header_asset_width}
              min={20}
              max={100}
              valueSuffix="%"
            />
          </FormInput>
        )}
        {theme.header_asset_id && (
          <FormInput title="Logo link">
            <Input
              onChange={(event) => onUpdate({ ...theme, header_asset_url: event.target.value })}
              value={theme.header_asset_url}
              type="url"
              name="Logo link"
            />
          </FormInput>
        )}
        <FormInput title="Date alignment">
          <ThemeEditorDropdown
            currentValue={theme.header_top_bar_alignment}
            onUpdate={(val: string) => onUpdate({ ...theme, header_top_bar_alignment: val })}
            options={[
              { label: 'Left', value: 'Left' },
              { label: 'Right', value: 'Right' },
            ]}
          />
        </FormInput>
        <FormInput title="Date format">
          <ThemeEditorDropdown
            currentValue={theme.header_top_date_format}
            onUpdate={(val: string) => onUpdate({ ...theme, header_top_date_format: val })}
            options={[
              { label: 'Long', value: 'Long' },
              { label: 'Long Ordinal', value: 'Long Ordinal' },
              { label: 'Short', value: 'Short' },
              { label: 'None', value: 'None' },
            ]}
          />
        </FormInput>
        <FormInput title="Custom CSS">
          <Textarea
            onChange={(event) => onUpdate({ ...theme, header_custom_css: event.target.value })}
            value={theme.header_custom_css || ''}
            name="custom_css"
            labelText=""
            placeholderText="Type styles here..."
            rows={8}
          />
        </FormInput>
      </FormSection>
    </FormContainer>
  );
};

export default HeaderSectionForm;
