import { SparklesIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import Grid from '@/components/Grid';
import { Typography, TypographyRow } from '@/components/Typography';

interface TrialScaleOptionProps {
  onClick: () => void;
  selected: boolean;
}

const TrialScaleOption: React.FC<TrialScaleOptionProps> = ({ selected, onClick }) => {
  return (
    <button type="button" className="w-full" onClick={onClick}>
      <div className={cx(selected ? 'border-surface-500' : 'border-surface-200', 'rounded border')}>
        <TypographyRow className="bg-surface-50 rounded-t py-4 px-6 border-b border-surface-200 justify-between">
          <Typography token="font-semibold/text/base">Trial our Scale Plan</Typography>
          <div className="p-4 bg-action-tertiary-100 rounded">
            <SparklesIcon className="text-action-tertiary-500 w-6 h-6" />
          </div>
        </TypographyRow>
        <div className="px-6 py-4">
          <TypographyRow className="pb-4">
            <Typography token="font-normal/text/sm" colorWeight="700">
              All Launch features
            </Typography>
            <Typography token="font-bold/text/sm" colorWeight="700">
              PLUS:
            </Typography>
          </TypographyRow>
          <Grid gap={4} columns={2} rows={2}>
            <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
              <span className="text-action-tertiary-600">&#10003;</span> Deeper Audience Insights
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
              <span className="text-action-tertiary-600">&#10003;</span> Referral Program
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
              <span className="text-action-tertiary-600">&#10003;</span> Email Automations
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
              <span className="text-action-tertiary-600">&#10003;</span> And much more
            </Typography>
          </Grid>
        </div>
      </div>
      <div className="text-left pt-3">
        <Typography token="font-normal/text/xs" colorWeight="700">
          <Typography token="font-semibold/text/xs" colorWeight="700" className="mr-1">
            We don&apos;t require a credit card to get started.
          </Typography>
          When your trial ends, your account will be downgraded to our Launch plan. To avoid losing access to Scale
          features, upgrade your account before the trial period concludes.
        </Typography>
      </div>
    </button>
  );
};

export default TrialScaleOption;
