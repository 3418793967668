import { IconComponent } from '@heroicons/react';
import cx from 'classnames';

import ProgressIndicator from '../ProgressIndicator';

type Metric = { label?: string; value: number };

interface Props {
  metric: Metric;
  comparedMetric?: Metric;
  label: string;
  Icon: IconComponent;
  progress?: { percentage: number; label: string };
  className?: string;
}

const SendStat = ({ metric, comparedMetric, label, Icon, progress, className }: Props) => {
  const formatter = new Intl.NumberFormat('en');

  return (
    <div className={cx('flex md:w-auto w-full flex-col justify-between', className)}>
      <Icon className="h-6 w-6 text-gray-300 mb-4" />
      <div>
        <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">{label}</p>
        <p className="leading-none">
          <span className="text-5xl font-black text-primary-500">{formatter.format(metric.value)}</span>
          <span className="text-gray-400 tracking-tighter text-sm"> {metric.label || <>&nbsp;</>}</span>
        </p>
        {comparedMetric && (
          <p className="leading-none">
            <span className="text-gray-400 tracking-tighter text-sm">
              vs. <strong className="text-gray-600 text-base">{comparedMetric.value}</strong> {comparedMetric.label}
            </span>
          </p>
        )}
      </div>

      {progress && (
        <div className="mt-8">
          <ProgressIndicator value={progress.percentage} label={progress.label} />
        </div>
      )}
    </div>
  );
};

export default SendStat;
