import { useCallback, useMemo } from 'react';
import { SuggestionProps } from '@tiptap/suggestion';

import { Avatar } from '@/components/UI/Avatar';
import usePublicationUsers from '@/hooks/useUsers/usePublicationUsers';

import { Spinner } from '../../TiptapEditor/components/ui/Spinner';
import * as Sheet from '../../UI/Sheet';

export const SuggestionsModal = (props: SuggestionProps) => {
  const { query, command } = props;
  // const selectedItem = useState<string | null>(null);
  const publicationUsers = usePublicationUsers();

  const filteredUsers = useMemo(() => {
    if (!publicationUsers.data) {
      return [];
    }

    return publicationUsers.data.filter((u) => u.name.toLowerCase().includes(query.toLowerCase()));
  }, [publicationUsers.data, query]);

  const handleSelect = useCallback(
    (id: string) => {
      const option = publicationUsers.data?.find((o) => o.id === id);

      if (option) {
        command({ label: option.name, id: option.id });
      }
    },
    [command, publicationUsers.data]
  );

  const loadingSpinner = publicationUsers.isLoading ? (
    <div className="flex items-center justify-center h-8">
      <Spinner />
    </div>
  ) : null;
  const noResults =
    !publicationUsers.isLoading && !filteredUsers?.length ? (
      <div className="flex items-center justify-center h-8 text-sm font-medium leading-none">No results</div>
    ) : null;

  const results =
    !publicationUsers.isLoading && !!publicationUsers.data && publicationUsers.data.length ? (
      <Sheet.ScrollList>
        {filteredUsers.map((u) => (
          <Sheet.Item className="flex items-center gap-2" type="button" key={u.id} onClick={() => handleSelect(u.id)}>
            <Avatar.Wrapper fallback={u.name}>
              {u.profile_picture ? <Avatar.Image src={u.profile_picture.thumb.url} alt={u.name} /> : null}
            </Avatar.Wrapper>
            <span className="flex-1">{u.name}</span>
          </Sheet.Item>
        ))}
      </Sheet.ScrollList>
    ) : null;

  return (
    <Sheet.Surface className="w-64">
      {loadingSpinner}
      {noResults}
      {results}
    </Sheet.Surface>
  );
};
