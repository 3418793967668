import { useState } from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

import Card from '@/components/Card';
import FilterDropdown from '@/components/FilterDropdown';
import LoadingBox from '@/components/LoadingBox';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import useSvixMessageAttempts, { MessageAttemptStatusStringified } from '@/hooks/webhooks/useSvixMessageAttempts';
import { MessageAttemptStatus, MessageAttemptStatusString } from '@/interfaces/webhooks/message_attempt';
import { Button } from '@/ui/Button';

import ListRow from './ListRow';
import NoResults from './NoResults';

type Props = {
  endpointId: string;
};

const List = ({ endpointId }: Props) => {
  const [filter, setFilter] = useState<MessageAttemptStatusStringified>('');
  const filterOptions = [
    { label: 'All', onClick: () => setFilter('') },
    {
      label: 'Succeeded',
      onClick: () => setFilter(MessageAttemptStatus.SUCCESS.toString() as MessageAttemptStatusStringified),
    },
    {
      label: 'Pending',
      onClick: () => setFilter(MessageAttemptStatus.PENDING.toString() as MessageAttemptStatusStringified),
    },
    {
      label: 'Failed',
      onClick: () => setFilter(MessageAttemptStatus.FAILED.toString() as MessageAttemptStatusStringified),
    },
    {
      label: 'Sending',
      onClick: () => setFilter(MessageAttemptStatus.SENDING.toString() as MessageAttemptStatusStringified),
    },
  ];
  const filterLabel = () => {
    if (filter === '') return 'All';

    return MessageAttemptStatusString[Number(filter) as MessageAttemptStatus];
  };
  const messageAttemptsQuery = useSvixMessageAttempts({ endpointId, status: filter });
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, refetch, isRefetching } =
    messageAttemptsQuery;
  const messageAttempts = data?.pages.flatMap((page) => page.message_attempts) || [];
  const noResults = !isLoading && !isError && messageAttempts.length === 0;

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <div className="flex justify-between items-end">
        <h2 className="text-2xl font-bold">Message Attempts</h2>
        <div className="flex flex-row space-x-2">
          <div className="w-full" />
          <div className="flex justify-end w-32 border border-gray-400 rounded-md py-1.5 px-2">
            <FilterDropdown label={filterLabel()} selection={filter} options={filterOptions} />
          </div>
          <button type="button" onClick={() => refetch()} className="border border-gray-400 rounded-md p-1.5">
            {isRefetching ? <LoadingSpinner /> : <ArrowPathIcon className="w-6 h-6 text-gray-400" />}
          </button>
        </div>
      </div>
      <Card noPadding className="py-6">
        <table className="min-w-full divide-y divide-gray-300 px-4">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Status
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Event Type
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Message ID
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Timestamp
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">View</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white break-word">
            {noResults && <NoResults />}
            {!noResults &&
              messageAttempts.map((messageAttempt) => (
                <ListRow key={messageAttempt.id} messageAttempt={messageAttempt} />
              ))}
          </tbody>
        </table>
      </Card>
      {hasNextPage && (
        <div className="text-center mt-2 mb-6">
          <div>
            <Button
              variant="primary-inverse"
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              {isFetchingNextPage ? 'Loading more...' : 'Load more'}
            </Button>
          </div>
        </div>
      )}
    </LoadingBox>
  );
};

export default List;
