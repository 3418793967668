import { useQuery } from 'react-query';

import { Post } from '../interfaces/post';
import api from '../services/swarm';

interface Arguments {
  id: string | undefined;
}

export default function usePost({ id }: Arguments) {
  return useQuery<Post>(['posts', id], () => api.get(`/posts/${id}`).then((res) => res.data), {
    keepPreviousData: true,
    enabled: !!id,
  });
}
