import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import api from '../services/swarm';

const useOptions = (optionEndpoint: string, staleTime = 10000, additionalParams?: any) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery(
    ['options', optionEndpoint, currentPublicationId],
    () =>
      api
        .get(`/options/${optionEndpoint}`, {
          params: {
            publication_id: currentPublicationId,
            ...additionalParams,
          },
        })
        .then((res) => res.data),
    {
      staleTime,
      enabled: Boolean(optionEndpoint),
    }
  );
};

export default useOptions;
