import { useQuery } from 'react-query';

import { useCurrentPublication } from '@/hooks';
import { PlanPrice } from '@/interfaces/plan_price';
import api from '@/services/swarm';

const usePlanPrice = ({ organizationId, upcoming = false }: { organizationId: string; upcoming?: boolean }) => {
  const { data: currentPublication } = useCurrentPublication();

  return useQuery<PlanPrice>(
    ['organization', organizationId, 'plan_price', `upcoming_${upcoming}`],
    () =>
      api
        .get(`/organizations/${organizationId}/plan_price`, {
          params: {
            publication_id: currentPublication?.id,
            upcoming,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!organizationId,
    }
  );
};

export default usePlanPrice;
