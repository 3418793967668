import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon, HomeIcon } from '@heroicons/react/20/solid';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import LoadingBox from '@/components/LoadingBox';
import { Button } from '@/ui/Button';

import { TierConfigurationProvider } from '../_context/tier_configuration_context';
import { useTier } from '../_hooks';

const TierConfigurationLayout = () => {
  const navigate = useNavigate();
  const { tierId } = useParams();
  const { data: tier, isLoading, isError } = useTier({ tierId });

  return (
    <div className="w-full min-h-screen bg-surface-50 py-6">
      <div className="px-6 max-w-8xl 0">
        <Breadcrumbs>
          <Breadcrumbs.Item to="/">
            <HomeIcon className="w-4 h-4" />
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to="/settings/publication">Publication</Breadcrumbs.Item>
          <Breadcrumbs.Item to="/settings/publication/premium">Premium</Breadcrumbs.Item>
          <Breadcrumbs.Item to={`/settings/publication/premium/tiers/${tier?.id}`}>Tier Configuration</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex justify-end mt-3">
          <Button
            onClick={() => navigate('/website_builder?page=upgrade_page&tab=layout')}
            type="button"
            Icon={ArrowTopRightOnSquareIcon}
            iconRight
          >
            Design Upgrade Page
          </Button>
        </div>
        <LoadingBox isLoading={isLoading} isError={isError}>
          <TierConfigurationProvider tier={tier}>
            <Outlet />
          </TierConfigurationProvider>
        </LoadingBox>
      </div>
    </div>
  );
};

export default TierConfigurationLayout;
