import { useParams } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import Badge from '@/components/Badge';
import { Button } from '@/ui/Button';

import { usePublication } from '../../../../hooks/useAdNetwork/internal';
import { AdNetworkPublication } from '../../../../interfaces/ad_network/internal/publication';
import { DescriptionList } from '../../_components/DescriptionList';
import { PageHeading } from '../../_components/PageHeading';

import { ProfileDescriptionList } from './_components/ProfileDescriptionList';

interface Props {
  publication: AdNetworkPublication;
}

const Publication = ({ publication }: Props) => {
  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Publications</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">{publication.name}</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button to="profile" variant="primary-inverse" size="xs">
            Manage profile
          </Button>
          <Button to="opportunities" variant="primary-inverse" size="xs">
            Opportunities
          </Button>
        </PageHeading.Side>
      </PageHeading>

      <div className="divide-y divide-gray-100">
        <DescriptionList>
          <DescriptionList.Item term="ID" copyValue={publication.id}>
            {publication.id}
          </DescriptionList.Item>
          <DescriptionList.Item term="Name">{publication.name}</DescriptionList.Item>
          <DescriptionList.Item term="Description">{publication.description}</DescriptionList.Item>
          <DescriptionList.Item term="Website">
            <a href={`https://${publication.hostname}`} target="_blank" rel="noreferrer">
              {publication.hostname}
              <ArrowTopRightOnSquareIcon className="inline-block w-4 h-4 ml-1" />
            </a>
          </DescriptionList.Item>
          <DescriptionList.Item term="Owner Email">
            <a href={`mailto:${publication.owner_email}`}>{publication.owner_email}</a>
          </DescriptionList.Item>
          <DescriptionList.Item term="Active Subscriber Count">
            {publication.active_subscription_count}
          </DescriptionList.Item>
          <DescriptionList.Item term="Tags">
            <div className="flow-root">
              <div className="-m-1 flex flex-wrap max-w-md">
                {publication.tags.map((name) => (
                  <div className="p-1" key={name}>
                    <Badge>#{name}</Badge>
                  </div>
                ))}
              </div>
            </div>
          </DescriptionList.Item>
          <DescriptionList.Item term="Created">
            <time>{moment(publication.created_at).fromNow()}</time>
          </DescriptionList.Item>
          <DescriptionList.Item term="Updated">
            <time>{moment(publication.updated_at).fromNow()}</time>
          </DescriptionList.Item>
          <ProfileDescriptionList />
        </DescriptionList>
      </div>
    </>
  );
};

export default function Loader() {
  const { publication_id: id } = useParams<'publication_id'>() as { publication_id: string };
  const { data: publication, isSuccess, isLoading, isError } = usePublication({ id });

  if (!isSuccess || isLoading || isError) return null;

  return <Publication publication={publication} />;
}
