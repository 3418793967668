import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { TiptapState } from '@/interfaces/editor_versions';
import { EmailMessage } from '@/interfaces/email_message';
import api from '@/services/swarm';

interface MutationParams {
  emailMessageId: string | undefined;
  onSuccess?: (emailMessage: EmailMessage) => void;
  onError?: (err: string) => void;
}

interface EmailMessageParams {
  tiptap_state?: TiptapState;
  subject_line?: string | null;
  preview_text?: string | null;
  active?: boolean;
}

const useUpdateEmailMessage = (params: MutationParams) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<EmailMessage, unknown, EmailMessageParams>(
    (emailMessageParams) =>
      api.patch(`/email_messages/${params.emailMessageId}`, {
        publication_id: currentPublicationId,
        email_message: {
          ...emailMessageParams,
        },
      }),
    {
      onSuccess: (data) => {
        if (params.onSuccess) params.onSuccess(data);
      },
      onError: (err: any) => {
        if (params.onError) params.onError(err);
      },
    }
  );
};

export default useUpdateEmailMessage;
