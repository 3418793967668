import { BellIcon } from '@heroicons/react/24/outline';

import { Notifications } from '@/interfaces/notification';
import { Button } from '@/ui/Button';

import NotificationItem from '../NotificationItem/NotificationItem';

interface Props {
  notifications: Notifications;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
}

const NotificationItems = ({ notifications, hasNextPage, fetchNextPage }: Props) => {
  if (notifications.length === 0) {
    return (
      <div className="border border-gray-200 rounded-md text-center space-y-4 p-12">
        <BellIcon className="w-6 h-6 mx-auto text-gray-900" />
        <p className="text-center text-sm text-gray-500">No activity yet, check back later.</p>
        <Button to="/settings/notifications" variant="primary-inverse">
          Configure notifications
        </Button>
      </div>
    );
  }

  return (
    <>
      <ul className="divide-y divide-gray-100">
        {notifications.map((notification: any, index: number) => (
          <NotificationItem key={notification?.id || index} notification={notification} />
        ))}
      </ul>
      {hasNextPage && (
        <div className="flex text-center">
          <button type="button" onClick={fetchNextPage} className="w-full text-sm text-gray-500">
            View more
          </button>
        </div>
      )}
    </>
  );
};

export default NotificationItems;
