import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Button } from '@/ui/Button';

import { TOP_BAR_HEIGHT_CLASS_NAME } from './constants';
import { HelpDropdown } from './HelpDropdown';
import { NotificationsDropdown } from './NotificationsDropdown';
import SizingConfiguration from './SizingConfiguration';
import { UserDropdown } from './UserDropdown';

interface Props {
  largeNavOpen: boolean;
  setLargeNavOpen: Dispatch<SetStateAction<boolean>>;
}

const TopBar = ({ largeNavOpen, setLargeNavOpen }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={cx('screen:flex flex-col justify-between items-center print:hidden', TOP_BAR_HEIGHT_CLASS_NAME)}>
      <div className="w-full h-full flex flex-row justify-center items-between">
        <SizingConfiguration largeNavOpen={largeNavOpen} setLargeNavOpen={setLargeNavOpen} />
        <div className="w-full py-2 pr-6 flex flex-row space-x-4 justify-end items-end bg-white">
          <Button
            onClick={() => navigate('/partner_program')}
            size="sm"
            variant="flush"
            type="button"
            className="my-auto"
          >
            Share & Earn
          </Button>
          <Button onClick={() => navigate('/wallet')} size="sm" variant="flush" type="button" className="my-auto">
            Wallet
          </Button>
          <HelpDropdown />
          <NotificationsDropdown />
          <UserDropdown />
        </div>
      </div>
      <hr className="w-full" />
    </div>
  );
};

export default TopBar;
