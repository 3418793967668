import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import analytics from '@/utils/analytics';

import { NewInvite } from '../../interfaces/invites';
import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

const useSendInvite = () => {
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (invite: NewInvite) =>
      api.post(`/invites`, {
        publication_id: currentPublication?.id,
        invite,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: (res) => {
        try {
          const { invite } = res.data;
          analytics.track('Account Added User', { role_id: invite?.role_id });
          queryClient.invalidateQueries(['invites', currentPublication?.organization_id]);
          toast.success('Invite was sent successfully!');
        } catch {
          toast.error('Please try again');
        }
      },
    }
  );
};

export default useSendInvite;
