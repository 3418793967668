import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

export interface SubscriptionTierDetail {
  tier_id: string;
  tier_name: string;
  type: 'trial' | 'discount' | 'gift' | 'full_price';
  text: string;
}

export interface SubscriptionTierDetailsApiResponse {
  details: SubscriptionTierDetail[];
}

const useSubscriptionTierDetails = ({ id }: { id: string | undefined }) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<SubscriptionTierDetailsApiResponse>(
    ['subscription', id, 'tier_details', currentPublicationId],
    () =>
      api
        .get(`/subscriptions/${id}/premium_details`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!id,
    }
  );
};

export default useSubscriptionTierDetails;
