import { useCreateDraft } from '@/hooks';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';

interface Props {
  opportunity: AdNetworkOpportunity;
}

function useAddOpportunityToPost({ opportunity }: Props) {
  const createDraftMutation = useCreateDraft();

  return () => {
    createDraftMutation.mutateAsync({
      tiptap_state: {
        type: 'doc',
        content: [
          {
            type: 'advertisementOpportunity',
            attrs: {
              id: opportunity.id,
            },
          },
        ],
      },
    });
  };
}

export default useAddOpportunityToPost;
