import { memo } from 'react';

import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { Tooltip } from '../../ui/Tooltip';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $showTooltip: true,
  $isIconButton: true,
};

export type CommentingPanelProps = {
  onOpenCommentingPanel: () => void;
};

export const CommentingPanel = memo(({ onOpenCommentingPanel }: CommentingPanelProps) => {
  return (
    <Tooltip title="Add a comment">
      <Button {...buttonProps} $leftSlot={<Icon name="Comment" />} $muted onClick={onOpenCommentingPanel} />
    </Tooltip>
  );
});

CommentingPanel.displayName = 'CommentingPanel';
