import React, { forwardRef, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import { cn } from '@/utils/cn';

export const { Button } = Menu;

export type RootProps = React.ComponentPropsWithoutRef<'div'>;

export const Root = forwardRef<HTMLDivElement, RootProps>(({ className, ...props }, ref) => {
  return <Menu as="div" className={cn('relative', className)} ref={ref} {...props} />;
});

Root.displayName = 'DropdownMenu.Root';

export type ContentProps = React.ComponentPropsWithoutRef<'div'>;

export const Content = forwardRef<HTMLDivElement, ContentProps>(({ className, ...props }, ref) => {
  const contentClassName = cn(
    'absolute mt-1 p-2 bg-white border rounded-lg shadow-xl min-w-dropdown flex flex-col gap-1',
    className
  );

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className={contentClassName} ref={ref} {...props} />
    </Transition>
  );
});

Content.displayName = 'DropdownMenu.Content';

export type ItemProps = React.ComponentPropsWithoutRef<'div'> & {
  active?: boolean;
};

export const Item = forwardRef<HTMLDivElement, ItemProps>(({ className, active, ...props }, ref) => {
  const itemClassName = cn(
    'w-full flex items-center gap-1 p-2 rounded-lg leading-none cursor-pointer text-xs font-semibold',
    !active && 'text-gray-700 hover:bg-gray-50 hover:text-black',
    active && 'bg-gray-100 text-black',
    className
  );

  return (
    <Menu.Item>
      <div className={itemClassName} ref={ref} {...props} />
    </Menu.Item>
  );
});

Item.displayName = 'DropdownMenu.Item';

export type SeparatorProps = React.ComponentPropsWithoutRef<'div'>;

export const Separator = forwardRef<HTMLDivElement, SeparatorProps>(({ className, ...props }, ref) => {
  const separatorClassName = cn('w-full h-px bg-gray-200', className);

  return <div className={separatorClassName} ref={ref} {...props} />;
});

Separator.displayName = 'DropdownMenu.Separator';
