import { useState } from 'react';
import toast from 'react-hot-toast';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

import ActionModal from '../../../components/ActionModal';
import { Input } from '../../../components/Form';
import api from '../../../services/swarm';

import NotEditing from './NotEditing';

interface Props {
  value: string;
  userId: string;
}

const emailValidation = (value: string) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value);
};

const EmailForm = ({ value, userId }: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleSubmit = () => {
    if (!inputValue || inputValue === value) {
      toast.error('Please enter a new value to save!');
      return;
    }

    if (inputValue !== value && !emailValidation(inputValue)) {
      toast.error('Please enter a valid email address');
      return;
    }

    setIsSaving(true);
    api
      .post(`/users/${userId}/user_changes`, {
        field: 'email',
        new_value: inputValue,
      })
      .then((resp) => {
        toast.success(resp.data.message);
        setIsEditing(false);
      })
      .catch((errPayload) => {
        toast.error('Something went wrong' || errPayload?.response?.data?.message);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      <ActionModal
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        onProceed={handleSubmit}
        resourceId={value}
        isWorking={isSaving}
        headerText="Send New Email Confirmation"
        actionText="Send Request"
      >
        <Input
          name="email"
          labelText="New Email"
          helperText="Your email will not be changed until you confirm it."
          placeholder={value}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
        />
      </ActionModal>
      <div className="flex justify-between items-center pb-4 border-b border-gray-100 last:border-b-0">
        <NotEditing label="Email" value={value} />

        <div className="pt-4">
          <Button variant="danger" onClick={() => setIsEditing(true)}>
            <EnvelopeIcon className="w-4 h-4 mr-2 text-red-500" />
            Change Email
          </Button>
        </div>
      </div>
    </>
  );
};

export default EmailForm;
