import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';

import LoadingBox from '@/components/LoadingBox';
import useSvixEndpoints from '@/hooks/webhooks/useSvixEndpoints';
import { Button } from '@/ui/Button';

import Help from '../components/Help';
import GetStarted from '../GetStarted';

import ListRow from './ListRow';

const List = () => {
  const endpointQuery = useSvixEndpoints();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } = endpointQuery;
  const endpoints = data?.pages.flatMap((page) => page.endpoints) || [];
  const navigate = useNavigate();

  if (!isLoading && !isError && endpoints.length === 0) {
    return <GetStarted svixConfigured />;
  }

  return (
    <>
      <div className="flex flex-row items-end justify-between">
        <h2 className="text-2xl font-bold">Endpoints</h2>
        <Help />
      </div>
      <LoadingBox isLoading={isLoading} isError={isError}>
        <div className="my-4 space-y-4">
          <div className="flex justify-end items-end">
            <Button
              variant="primary"
              onClick={() => {
                navigate('/settings/integrations/webhooks/endpoints/new');
              }}
            >
              <PlusIcon className="w-4 h-4 mr-2" />
              New Endpoint
            </Button>
          </div>
          <ul className="space-y-4">
            {endpoints.map((endpoint) => (
              <ListRow key={endpoint.id} endpoint={endpoint} />
            ))}
          </ul>
        </div>
        {hasNextPage && (
          <div className="text-center mt-2 mb-6">
            <div>
              <Button
                variant="primary-inverse"
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
              >
                {isFetchingNextPage ? 'Loading more...' : 'Load more'}
              </Button>
            </div>
          </div>
        )}
      </LoadingBox>
    </>
  );
};

export default List;
