import { FC, useState } from 'react';
import { CheckIcon, MinusIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { useDeliveryContext } from './context/DeliveryContext';

interface Props {
  className?: string;
  name: string;
  labelText?: string;
  isChecked: boolean;
  isMinus: boolean;
  onChange: () => void;
  helperText?: string;
  placeholder?: boolean;
  isMutating?: boolean;
  debounce?: number;
  readOnly?: boolean;
}

interface CoreProps {
  name: string;
  labelText?: string;
  isChecked: boolean;
  isMinus: boolean;
  helperText?: string;
  placeholder?: boolean;
  readOnly?: boolean;
  isMutating?: boolean;
  isDebouncing?: boolean;
}

const Core = ({
  isChecked,
  isMinus,
  readOnly,
  labelText,
  isMutating,
  isDebouncing,
  name,
  helperText,
  placeholder,
}: CoreProps) => {
  const { isChanging } = useDeliveryContext();
  const bgColor = (isChecked && (readOnly ? 'bg-gray-200' : 'bg-black')) || (isMinus && 'bg-gray-400') || 'bg-white';
  const borderStyles =
    (isChecked && (readOnly ? 'border-gray-200' : 'border-black')) ||
    (isMinus && 'border-gray-400') ||
    'border-gray-300';
  const cursorStyles = readOnly || isChanging ? 'cursor-not-allowed' : 'cursor-pointer';

  return (
    <>
      <div className="flex items-center">
        <div
          className={cx(
            'border rounded-sm flex justify-center items-center w-4 h-4',
            bgColor,
            borderStyles,
            cursorStyles
          )}
        >
          {isChecked ? (
            <CheckIcon className="w-2 h-2 text-white" strokeWidth={5} />
          ) : (
            <MinusIcon className="w-2 h-2 text-white" strokeWidth={5} />
          )}
        </div>

        {!placeholder && labelText && (
          <label
            htmlFor={name}
            className={cx(
              'text-gray-800 pl-2 text-sm font-medium',
              (isMutating || isDebouncing) && 'animate-pulse-fast',
              cursorStyles
            )}
          >
            {labelText}
          </label>
        )}
        {placeholder && (
          <div className="ml-2 text-sm font-medium truncate bg-gray-200 rounded animate-pulse text-transparent w-36 h-4" />
        )}
      </div>
      {!placeholder && helperText && (
        <p
          className={cx(
            'text-left ml-6 text-sm text-gray-700 mt-1',
            (isMutating || isDebouncing) && 'animate-pulse-fast'
          )}
        >
          {helperText}
        </p>
      )}
      {placeholder && (
        <div className="ml-6 mt-2 text-sm font-medium truncate bg-gray-200 rounded animate-pulse text-transparent w-20 h-4" />
      )}
    </>
  );
};

const Checkbox: FC<Props> = (props: Props) => {
  const {
    className,
    name,
    labelText,
    isChecked,
    isMinus,
    onChange,
    helperText,
    placeholder,
    isMutating,
    debounce,
    readOnly,
  } = props;
  const [isDebouncing, setIsDebouncing] = useState(false);
  const { isChanging } = useDeliveryContext();

  const handleClick = () => {
    if (debounce && debounce > 0) {
      setIsDebouncing(true);
      setTimeout(() => setIsDebouncing(false), debounce);
    }

    onChange();
  };

  return (
    <div className={className}>
      {readOnly ? (
        <Core
          isChecked={isChecked}
          isMinus={isMinus}
          readOnly={readOnly}
          labelText={labelText}
          isMutating={isMutating}
          isDebouncing={isDebouncing}
          name={name}
          helperText={helperText}
          placeholder={placeholder}
        />
      ) : (
        <button
          name={name}
          type="button"
          className="disabled:cursor-not-allowed"
          disabled={isMutating || isDebouncing || isChanging}
          onClick={handleClick}
        >
          <Core
            isChecked={isChecked}
            isMinus={isMinus}
            readOnly={readOnly}
            labelText={labelText}
            isMutating={isMutating}
            isDebouncing={isDebouncing}
            name={name}
            helperText={helperText}
            placeholder={placeholder}
          />
        </button>
      )}
    </div>
  );
};

Checkbox.defaultProps = {
  className: undefined,
};

export default Checkbox;
