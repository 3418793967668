import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { TierChangeEventResourceType } from '@/interfaces/tier_change_event';
import api from '@/services/swarm';
import { TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

import useCurrentTimeZone from '../useCurrentTimeZone';

interface Props {
  period: keyof typeof timePeriodLabels;
  resourceType?: TierChangeEventResourceType;
  resourceId?: string;
}

interface ChartData {
  name: string;
  Upgrades: number;
  Downgrades: number;
}

interface ApiResponse {
  chart_data: ChartData[];
  categories: string[];
  period: TimePeriod;
  time_zone: string;
}

const useTierChangeEventsPerPeriod = ({ period, resourceType, resourceId }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const currentTimeZone = useCurrentTimeZone();

  const fetchTierChangeData = () => {
    return api
      .get(`/premium_data/tier_change_events_per_period/${period}`, {
        params: {
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
          resource_type: resourceType,
          resource_id: resourceId,
        },
      })
      .then((res) => res.data);
  };

  return useQuery<ApiResponse>(
    [
      'premium_data',
      'tierChangeEventsPerPeriod',
      currentPublicationId,
      period,
      currentTimeZone,
      resourceType,
      resourceId,
    ],
    fetchTierChangeData,
    {
      keepPreviousData: true,
    }
  );
};

export default useTierChangeEventsPerPeriod;
