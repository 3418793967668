import { Input, SimpleSelect } from '@/components/Form';
import { AutomationConditions } from '@/interfaces/automations/automation_condition';
import { LogicalOperators } from '@/interfaces/condition';

const NAME_OPTIONS = [
  { label: 'Days without opens', value: 'last_opened_days_ago' },
  { label: 'Days without clicks', value: 'last_clicked_days_ago' },
  { label: 'Days without opens or clicks', value: 'last_opened_or_clicked_days_ago' },
];

interface Props {
  mainConditions: AutomationConditions;
  onChange: (mainConditions: AutomationConditions) => void;
}

export const UnengagedTrigger = ({ mainConditions, onChange }: Props) => {
  const condition = mainConditions.conditions?.[0];

  const handleChange = (params: any) => {
    onChange({ logical_operator: LogicalOperators.AND, conditions: [{ ...condition, ...params }] });
  };

  return (
    <div className="mt-2 space-y-4 lg:space-y-0 lg:flex lg:space-x-2">
      <SimpleSelect
        className="w-64"
        name="name"
        value={condition?.name || ''}
        onSelect={(_name, selectedValue) => {
          handleChange({ name: selectedValue });
        }}
        options={NAME_OPTIONS}
      />
      <Input
        type="number"
        className="w-24"
        name="value"
        value={condition?.value}
        onChange={(event) => {
          handleChange({ value: event.target.value, hasErrors: event.target.value === '' });
        }}
        errorText={condition?.hasErrors ? 'This field is required' : undefined}
        required
      />
    </div>
  );
};
