import { useMemo, useState } from 'react';

import PageWrapper from '../_components/PageWrapper';

import DonationsCard from './_components/DonationsCard';
import EffectivenessCard from './_components/EffectivenessCard';
import OverviewCard from './_components/OverviewCard';

const MonetizeSubscriptionsBreakdown = () => {
  const [currentTab, setCurrentTab] = useState('overview');

  const tabs = useMemo(
    () => [
      {
        name: 'overview',
        label: 'Overview',
        selected: currentTab === 'overview',
        onSelect: () => setCurrentTab('overview'),
      },
      {
        name: 'donations',
        label: 'Donations',
        selected: currentTab === 'donations',
        onSelect: () => setCurrentTab('donations'),
      },
      {
        name: 'effectiveness',
        label: 'Promotion Effectiveness',
        selected: currentTab === 'effectiveness',
        onSelect: () => setCurrentTab('effectiveness'),
      },
    ],
    [currentTab]
  );

  return (
    <PageWrapper>
      {currentTab === 'overview' && <OverviewCard tabs={tabs} />}
      {currentTab === 'effectiveness' && <EffectivenessCard tabs={tabs} />}
      {currentTab === 'donations' && <DonationsCard tabs={tabs} />}
    </PageWrapper>
  );
};

export default MonetizeSubscriptionsBreakdown;
