import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useReactFlow } from 'reactflow';

import { useSettings } from '@/context/settings-context';
import { useAutomationSteps, useAutomationTriggers } from '@/hooks/useAutomations';
import useJourneyStepCounts from '@/hooks/useAutomations/useJourneyStepCounts';
import useReferringAutomations, { ReferringAutomationData } from '@/hooks/useAutomations/useReferringAutomations';
import { Automation } from '@/interfaces/automations/automation';
import { AutomationStep, AutomationStepStepType } from '@/interfaces/automations/automation_step';
import { AutomationTrigger } from '@/interfaces/automations/automation_trigger';
import { Option } from '@/interfaces/general';
import { useAutomationViewContent } from '@/pages/AutomationView';
import { zoomToReactFlowNode } from '@/utils/zoomToReactFlowNode';

import { ACTION_ITEMS } from '../constants';

const NewAutoLayoutContext = createContext<
  | {
    changeLog: any[];
    pushToChangeLog: (value: any) => void;
    automation: Automation;
    automationSteps: AutomationStep[];
    automationTriggers: AutomationTrigger[];
    referringAutomations: ReferringAutomationData[];
    isSelectingAutomationStep: boolean;
    setIsSelectingAutomationStep: (value: boolean) => void;
    draggingAction: AutomationStepStepType | null;
    setDraggingAction: (action: AutomationStepStepType | null) => void;
    zoomToNode: (nodeId: string) => void;
    isDrawerOpen: boolean;
    sortingStepId: string | null;
    setSortingStepId: (stepId: string | null) => void;
    isSorting: boolean;
    setIsSorting: (value: boolean) => void;
    automationStepTypeOptions: Option[];
    isLoading: boolean;
    isError: boolean;
  }
  | undefined
>(undefined);
NewAutoLayoutContext.displayName = 'NewAutoLayoutContext';

const NewAutoLayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const { automationId } = useParams() as unknown as { automationId: string };
  const location = useLocation();
  const { automationStepId, triggerId } = useParams() as unknown as {
    automationId: string;
    automationStepId: string;
    triggerId: string;
  };
  const { fitView } = useReactFlow();
  const { automation } = useAutomationViewContent();
  const [changeLog, setChangeLog] = useState<any[]>([]);
  const [isSelectingAutomationStep, setIsSelectingAutomationStep] = useState(false);
  const [draggingAction, setDraggingAction] = useState<AutomationStepStepType | null>(null);
  const [sortingStepId, setSortingStepId] = useState<string | null>(null);
  const [isSorting, setIsSorting] = useState(false);
  const { settings } = useSettings();
  const isApiaryEnabled = settings?.use_apiary || false;
  const automationStepTypeOptions = ACTION_ITEMS.filter((item) => {
    if (item.value === AutomationStepStepType.WEBHOOK && !settings?.automations_webhook_step) {
      return false;
    }

    return true;
  });

  const {
    data: automationStepsData,
    isLoading: isLoadingAutomationSteps,
    isError: isErrorAutomationSteps,
  } = useAutomationSteps(automationId);
  const {
    data: journeyStepCountsData,
    isLoading: isLoadingJourneyStepCounts,
    isError: isErrorJourneyStepCounts,
  } = useJourneyStepCounts({ automationId, isEnabled: isApiaryEnabled });
  const {
    data: automationTriggersData,
    isLoading: isLoadingAutomationTriggers,
    isError: isErrorAutomationTriggers,
  } = useAutomationTriggers(automationId);
  const {
    data: referringAutomationsData,
    isLoading: isLoadingReferringAutomations,
    isError: isErrorReferringAutomations,
  } = useReferringAutomations(automationId);

  const isDrawerOpen = !!(
    location.pathname.match('workflow/triggers/configure') ||
    location.pathname.match(`workflow/triggers/${triggerId}/configure`) ||
    location.pathname.match(`workflow/steps/${automationStepId}/configure`)
  );

  const pushToChangeLog = useCallback(
    (value: any) => {
      setChangeLog([...changeLog, value]);
    },
    [changeLog]
  );

  const zoomToNode = useCallback((nodeId: string) => zoomToReactFlowNode(nodeId, fitView), [fitView]);

  const isLoading =
    isLoadingAutomationSteps ||
    isLoadingJourneyStepCounts ||
    isLoadingAutomationTriggers ||
    isLoadingReferringAutomations;
  const isError =
    isErrorAutomationSteps || isErrorJourneyStepCounts || isErrorAutomationTriggers || isErrorReferringAutomations;

  const automationSteps = useMemo(
    () =>
      (automationStepsData || []).map((step) => {
        const journeyStepCounts = journeyStepCountsData?.[step.id];
        if (!journeyStepCounts) return step;

        return {
          ...step,
          journey_step_counts: journeyStepCounts,
        };
      }),
    [automationStepsData, journeyStepCountsData]
  );
  const automationTriggers = useMemo(() => automationTriggersData || [], [automationTriggersData]);
  const referringAutomations = useMemo(() => referringAutomationsData || [], [referringAutomationsData]);

  const contextValue = useMemo(
    () => ({
      changeLog,
      pushToChangeLog,
      isSelectingAutomationStep,
      setIsSelectingAutomationStep,
      draggingAction,
      setDraggingAction,
      automation,
      automationSteps,
      automationTriggers,
      referringAutomations,
      zoomToNode,
      isDrawerOpen,
      sortingStepId,
      setSortingStepId,
      isSorting,
      setIsSorting,
      automationStepTypeOptions,
      isLoading,
      isError,
    }),
    [
      changeLog,
      pushToChangeLog,
      isSelectingAutomationStep,
      setIsSelectingAutomationStep,
      draggingAction,
      automation,
      automationSteps,
      automationTriggers,
      referringAutomations,
      zoomToNode,
      isDrawerOpen,
      sortingStepId,
      setSortingStepId,
      isSorting,
      setIsSorting,
      automationStepTypeOptions,
      isLoading,
      isError,
    ]
  );

  return <NewAutoLayoutContext.Provider value={contextValue}>{children}</NewAutoLayoutContext.Provider>;
};

function useNewAutoLayoutContext() {
  const context = React.useContext(NewAutoLayoutContext);
  if (context === undefined) {
    throw new Error(`useNewAutoLayoutContext must be used within an NewAutoLayoutContext`);
  }
  return context;
}

export { NewAutoLayoutContext, NewAutoLayoutProvider, useNewAutoLayoutContext };
