import { useQuery } from 'react-query';

import { PostInformation } from '../interfaces/post';
import api from '../services/swarm';

export default function usePostInformation({ id }: { id: string }) {
  return useQuery<PostInformation>(
    ['posts', id, 'information'],
    () => api.get(`/posts/${id}/information`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
