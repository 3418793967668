import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SparklesIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCreateNavGroup } from '@/hooks/useNavGroups';
import useOptions from '@/hooks/useOptions';
import { LayoutPageData } from '@/routes/website_builder';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import { Button } from '@/ui/Button';

const AddCategoriesGroup = () => {
  const { handleSaved } = usePageContext<LayoutPageData>();
  const contentTags = useOptions('content_tags');
  const hasContentTags = contentTags?.data?.options.length > 0;

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const [isAddCategoriesModalOpen, setIsAddCategoriesModalOpen] = useState(false);
  const [isCategoriesCreating, setIsCategoriesCreating] = useState(false);

  const createNavGroup = useCreateNavGroup({
    onSuccess: () => {
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);

      if (isAddCategoriesModalOpen) {
        setIsCategoriesCreating(false);
        setIsAddCategoriesModalOpen(false);
      }

      handleSaved();
    },
    onError: () => {
      if (isAddCategoriesModalOpen) {
        setIsCategoriesCreating(false);
        setIsAddCategoriesModalOpen(false);
      }
    },
  });

  const handleCreateCategoriesGroup = () => {
    setIsCategoriesCreating(true);
    createNavGroup.mutate({ title: 'Categories', group_type: 'category' });
  };

  return (
    <>
      <Button
        variant="primary-inverse"
        className="mt-4"
        size="xs"
        onClick={() => setIsAddCategoriesModalOpen(true)}
        Icon={SparklesIcon}
      >
        Add Categories Group
      </Button>

      <div>
        {hasContentTags ? (
          <ActionModal
            isOpen={isAddCategoriesModalOpen}
            onClose={() => setIsAddCategoriesModalOpen(false)}
            onProceed={handleCreateCategoriesGroup}
            resourceId=""
            isWorking={isCategoriesCreating}
            headerText="Add Categories Group"
            actionText="Add"
          >
            <div className="space-y-2 text-sm">
              <p>
                By adding a Categories Menu Item to your navbar we will automatically add a dropdown menu with links to
                all of your category-specific content.
              </p>
              <p>
                For example: If you have posts tagged as <span className="font-bold">Finance</span> via your
                publication&apos;s content tags then there will be a link to a new Finance-specific page on your site.
              </p>
              <div className="bg-blue-50 p-4 rounded space-y-2">
                <p className="text-xs text-blue-500">
                  <span className="font-bold">So what?</span>
                </p>
                <p className="text-xs text-blue-500">
                  These pages backlink across category-specific content as well as point to other content across your
                  publication. This can have powerful SEO benefits and help your site + content pages rank in search.
                </p>
              </div>
            </div>
          </ActionModal>
        ) : (
          <ActionModal
            isOpen={isAddCategoriesModalOpen}
            onClose={() => setIsAddCategoriesModalOpen(false)}
            onProceed={() => navigate('/content_tags')}
            resourceId=""
            isWorking={isCategoriesCreating}
            headerText="You need to add Content Tags first"
            actionText="Add Content Tags"
          >
            <div className="space-y-2 text-sm">
              <div className="bg-blue-50 p-4 rounded space-y-2">
                <p className="text-xs text-blue-500">
                  <span className="font-bold">How can I enable this feature?</span>
                </p>
                <p className="text-xs text-blue-500">
                  The Categories Menu Item will be available to you after creating{' '}
                  <span className="font-bold">at least one</span> Content Tag and linking it to a Post.
                </p>
                <p className="text-xs text-blue-500">
                  Content Tags provide meaningful context for readers and help group your posts into logical categories.
                </p>
                <p className="text-xs text-blue-500">
                  By then enabling our Categories feature, we will automatically add a dropdown Menu Item with links to
                  all of your category-specific content.
                </p>
                <p className="text-xs text-blue-500">
                  The combination of these two features can have powerful SEO benefits and help your site + content
                  pages rank in search.
                </p>
                <p className="text-xs text-blue-500">Ready to get started?</p>
              </div>
            </div>
          </ActionModal>
        )}
      </div>
    </>
  );
};

export default AddCategoriesGroup;
