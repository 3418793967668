/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useCallback, useState } from 'react';

import DatePicker from './DatePicker';
import Input from './Input';
import Switch from './Switch';

interface Props extends React.HTMLProps<HTMLInputElement> {
  className?: string;
  kind: string;
  labelText?: string;
  name: string;
  value: string | undefined;
  onBlur?: (value: any) => void;
  onChange?: (value: any) => void;
}

const MagicInput = forwardRef((props: Props) => {
  const { className = '', name, value, kind, labelText, onBlur, onChange } = props;

  const [fieldValue, setFieldValue] = useState(value || '');
  const [boolValue, setBoolValue] = useState(value === 'true');

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => onBlur?.(e.target.value);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value);

  const DynamicInput = useCallback(
    (inputProps: any) => {
      const handleSwitchChange = (v: boolean) => {
        if (v === false) {
          setFieldValue('false');
          setBoolValue(false);
          onBlur?.(false);
          onChange?.(false);
        } else {
          setFieldValue('true');
          setBoolValue(true);
          onBlur?.(true);
          onChange?.(true);
        }
      };

      const handleDateChange = (date: Date | undefined) => {
        setFieldValue(date ? date.toISOString() : '');
        onBlur?.(date || '');
        onChange?.(date || '');
      };

      const { onChange: fieldOnChange, ...rest } = inputProps;
      switch (kind) {
        case 'string':
          return <Input {...inputProps} />;
        case 'integer':
          return <Input {...inputProps} type="number" />;
        case 'boolean':
          return (
            <div className="mt-2">
              <Switch
                checked={fieldValue === 'true'}
                onChange={(_name: any, switchValue: boolean) => handleSwitchChange(switchValue)}
                {...rest}
              />
            </div>
          );
        case 'date':
        case 'datetime':
          return (
            <DatePicker {...rest} onChange={handleDateChange} enableTime={kind === 'datetime'} displayClearButton />
          );
        default:
          return <Input {...inputProps} />;
      }
    },
    // We don't need to add the other dependencies with this useCallback
    // fieldValue actually break the inputs, because it re-renders and loses input focus.
    // boolValue is being used to force a re-render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kind, boolValue]
  );

  return (
    <div className={`${className} w-full flex-col`}>
      <label htmlFor={name} className="font-regular text-gray-500 pt-0.5 text-sm">
        {labelText}
      </label>
      <DynamicInput
        name={name}
        value={fieldValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(e.target.value);
          handleChange(e);
        }}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleBlur(e)}
      />
    </div>
  );
});

export default MagicInput;
