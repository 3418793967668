import { useNavigate } from 'react-router-dom';

const useGoToBilling = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/settings/billing');
  };

  return handleClick;
};

export default useGoToBilling;
