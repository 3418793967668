import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import { Icon } from '../../TiptapEditor/components/ui/Icon';

export type SearchBarProps = {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  suffix?: JSX.Element;
};

const SearchBar = React.forwardRef(
  ({ onChange, value, placeholder, suffix }: SearchBarProps, ref: React.Ref<HTMLLabelElement>) => {
    const [currentValue, setCurrentValue] = useState(value);
    const inputRef = useRef<HTMLInputElement>(null);
    const [hasFocus, setHasFocus] = useState(false);

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    useEffect(() => {
      if (!inputRef.current) {
        return () => {};
      }

      const focusHandler = () => {
        setHasFocus(true);
      };

      const blurHandler = () => {
        setHasFocus(false);
      };

      inputRef.current.addEventListener('focus', focusHandler);
      inputRef.current.addEventListener('blur', blurHandler);

      return () => {
        inputRef.current?.removeEventListener('focus', focusHandler);
        inputRef.current?.removeEventListener('blur', blurHandler);
      };
    }, []);

    const valueChanged = useMemo(() => currentValue !== value, [currentValue, value]);

    const handleSubmit = useCallback(
      (e: React.FormEvent) => {
        e.preventDefault();
        onChange(currentValue);
      },
      [valueChanged, currentValue, onChange]
    );

    const formClassName = classNames(
      'flex items-center w-full h-9 gap-2 border rounded p-1 pl-2',
      !hasFocus && 'border-transparent',
      hasFocus && 'bg-gray-100 border-gray-200'
    );
    return (
      <label
        ref={ref}
        htmlFor="searchInput"
        className="relative flex items-center w-full px-6 py-3 bg-white border-b border-black/10 cursor-text"
      >
        <form className={formClassName} onSubmit={handleSubmit}>
          <div className="flex-none text-gray-600 pointer-events-none">
            <Icon name="Search" $display="block" $size="1.125rem" />
          </div>
          <input
            ref={inputRef}
            id="searchInput"
            className="relative inset-0 z-0 block w-full p-0 text-sm bg-transparent border-0 outline-none focus:ring-0"
            type="text"
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            placeholder={placeholder}
          />
          {valueChanged && (
            <button
              className="flex-none px-2 py-1.5 text-xs font-semibold text-white bg-black rounded-lg"
              type="submit"
            >
              Search
            </button>
          )}
        </form>
        {suffix && <div className="flex-none ml-2">{suffix}</div>}
      </label>
    );
  }
);

export default SearchBar;
