import styled from 'styled-components';

import { Styled as StyledIcon } from '../../../components/ui/Icon/styled';
import { colors } from '../../../lib/colors';

export const Styled = {
  SelectionContainer: styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem;
  `,

  Container: styled.div`
    align-items: center;
    background: ${colors.black[9]};
    border: solid 1px ${colors.black[9]};
    border-radius: 0.25rem;
    color: ${colors.black[3]};
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 0.875rem;
    font-weight: 500;
    margin: -0.5rem;
    padding: 2rem;

    & > ${StyledIcon.Wrapper} {
      svg path {
        fill: ${colors.black[4]};
      }
    }
  `,
  Title: styled.p`
    &&& {
      color: ${colors.black[1]};
      line-height: 1.15;
      margin-bottom: 0.75rem;
      margin-top: 0.5rem;
      text-align: center;
    }
  `,
  Description: styled.p`
    &&& {
      line-height: 1.2;
      margin-top: 0;
      text-align: center;
    }
  `,
  Button: styled.a`
    margin: 1rem 0;
    border-radius: 8px;
    display: inline-flex;
    line-height: 1.2;
    text-decoration: none;
    background-color: ${colors.black[2]};
    color: ${colors.white[2]};
    padding: 0.625rem;
  `,
  Heading: styled.p`
    margin: 0;
    font-weight: bold;
  `,
  List: styled.div`
    margin-top: 1rem;
  `,
  ListItem: styled.div`
    display: flex;
    align-items: center;
    flex-gap: 0.25rem;

    & > ${StyledIcon.Wrapper} {
      margin: 0.1rem 0.25rem 0 0;
    }
  `,
};

export default Styled;
