import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { EyeIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import Badge from '@/components/Badge';
import HelpingHand from '@/components/HelpingHand';
import WorkflowBuilderSkeletonView from '@/components/WorkflowBuilderSkeletonView';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import analytics from '@/utils/analytics';

import ActionModal from '../../../components/ActionModal';
import { BackLink } from '../../../components/BackLink';
import { Input, Textarea } from '../../../components/Form';
import PageHeading from '../../../components/Layout/PageLayout/PageHeading';
import { useDeleteForm, useForm, useReorderFormQuestions, useUpdateForm } from '../../../hooks/useForms';
import usePublishForm from '../../../hooks/useForms/usePublishForm';
import { Form, FormQuestion, FormStatus } from '../../../interfaces/form';
import { Button } from '../../../ui/Button';

import ArchiveFormButton from './FormSettings/ArchiveFormButton';
import DeleteFormButton from './FormSettings/DeleteFormButton';
import PreviewFormButton from './FormSettings/PreviewFormButton';
import PublishFormButton from './FormSettings/PublishFormButton';
import UnarchiveFormButton from './FormSettings/UnarchiveFormButton';
import AddNewQuestion from './QuestionForm/AddNewQuestion';
import QuestionForm from './QuestionForm';

const EditForm = () => {
  const { settings } = useSettings();
  const { pathname } = useLocation();
  const { formId } = useParams();
  const { data: form, isLoading: isFormLoading, isError: isFormError } = useForm(formId);
  const isFormLive = form?.status === FormStatus.LIVE;
  const isFormArchived = form?.status === FormStatus.ARCHIVED;

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();

  // Form field states
  const [title, setTitle] = useState(form?.title || '');
  const [description, setDescription] = useState(form?.description || '');
  const [ctaText, setCtaText] = useState(form?.cta_text || '');
  const [thankYouMessage, setThankYouMessage] = useState(form?.thank_you_message || '');
  const [questions, setQuestions] = useState(form?.form_questions || []);
  const hasMoreThanOneQuestion = questions?.length > 1;
  const currentCustomFieldIds = questions?.map((question) => question.custom_field.id);

  // Form Builder states
  const [isSorting, setIsSorting] = useState(false);
  const [sortingQuestionId, setSortingQuestionId] = useState<string | null>(null);

  useEffect(() => {
    if (form) {
      setTitle(form.title);
      setDescription(form.description);
      setCtaText(form.cta_text);
      setThankYouMessage(form.thank_you_message);
      setQuestions(form.form_questions);
    }
  }, [form]);

  // Hanndle saving form as draft
  const updateForm = useUpdateForm({
    formId: formId || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['forms', formId]);
    },
  });
  const handleSaveAsDraft = async (isSilentSave: boolean) => {
    await updateForm.mutateAsync({
      status: FormStatus.DRAFT,
      title,
      description,
      ctaText,
      thankYouMessage,
    });
    analytics.track('Created a Survey Form');
    if (!isSilentSave) {
      toast.success('Survey saved!');
    }
  };

  const handleUpdateLiveForm = async () => {
    try {
      await updateForm.mutateAsync({
        status: FormStatus.LIVE,
        title,
        description,
        ctaText,
        thankYouMessage,
      });
      toast.success('Survey saved!');
    } catch (error) {
      toast.error('There was an error updating this survey');
    }
  };

  const hasFormChangedCheck = () => {
    if (form) {
      return (
        form.title !== title ||
        form.description !== description ||
        form.cta_text !== ctaText ||
        form.thank_you_message !== thankYouMessage
      );
    }

    return false;
  };

  const onAddNewQuestionClick = () => {
    const hasFormChanged = hasFormChangedCheck();

    if (hasFormChanged) {
      handleSaveAsDraft(true);
    }
  };

  // Handle Publishing form
  const [isPublishing, setIsPublishing] = useState(false);
  const publishForm = usePublishForm({
    formId: formId || '',
    onSuccess: () => {
      toast.success('Survey published!');
      queryClient.invalidateQueries(['forms', formId]);
      navigate('/forms');
    },
  });
  const handlePublish = () => {
    publishForm.mutate({
      title,
      description,
      ctaText,
      thankYouMessage,
    });
  };

  // Handle Deleting form
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteForm = useDeleteForm({
    formId: form?.id || '',
  });
  const handleDelete = async () => {
    try {
      await deleteForm.mutateAsync();
      setIsDeleting(false);
      navigate('/forms');
    } catch (error) {
      toast.error('There was an error deleting this survey');
      setIsDeleting(false);
    }
  };

  // Drag and drop logic
  const reorderFormQuestions = useReorderFormQuestions({
    formId: formId || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['forms', formId]);
    },
  });

  const [isDragActive, setIsDragActive] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragStart = () => {
    if (!hasMoreThanOneQuestion) {
      return null;
    }

    return setIsDragActive(true);
  };

  const reorderQuestion = (activeQuestionId: string, overQuestionId: string) => {
    const activePostitionId: any = questions.find((position: any) => position.id === activeQuestionId);
    const overPostitionId: any = questions.find((position: any) => position.id === overQuestionId);
    const oldIndex = questions.indexOf(activePostitionId);
    const newIndex = questions.indexOf(overPostitionId);
    const newList = arrayMove(questions, oldIndex, newIndex);
    setQuestions(newList);
    return reorderFormQuestions.mutate({
      questionOrder: newList.map((item: any, index: any) => {
        return { id: item.id, order: index + 1 };
      }),
    });
  };

  const onDragEnd = (event: any) => {
    if (!hasMoreThanOneQuestion) {
      return null;
    }

    const { active, over } = event;

    if (active.id !== over.id) {
      reorderQuestion(active.id, over.id);
    }

    return setIsDragActive(false);
  };

  const handlePublishForm = () => {
    setIsPublishing(true);
  };

  const renderHeaderActions = () => {
    if (isFormLive) {
      return (
        <Button onClick={handleUpdateLiveForm} variant="primary" loading={updateForm.isLoading}>
          {updateForm.isLoading ? 'Updating...' : 'Update Survey'}
        </Button>
      );
    }

    return (
      <div className="flex space-x-2 justify-end">
        <Button onClick={() => setIsDeleting(true)} variant="danger">
          Delete
        </Button>
        <Button onClick={() => handleSaveAsDraft(false)} variant="primary-inverse" loading={updateForm.isLoading}>
          {updateForm.isLoading ? 'Saving...' : 'Save as draft'}
        </Button>
        <Button onClick={handlePublishForm}>Publish</Button>
      </div>
    );
  };

  if (settings?.surveys_v2 === true) {
    const tabs = [
      {
        name: 'formBuilder',
        label: 'Form Builder',
        selected: pathname === `/forms/${formId}/edit/form_builder`,
        onSelect: () => navigate(`/forms/${formId}/edit/form_builder`),
      },
      {
        name: 'responses',
        label: 'Responses',
        selected: [`/forms/${formId}/edit/responses`, `/forms/${formId}/edit/summary`].includes(pathname),
        onSelect: () => navigate(`/forms/${formId}/edit/responses`),
      },
      {
        name: 'settings',
        label: 'Settings',
        selected: pathname === `/forms/${formId}/edit/settings`,
        onSelect: () => navigate(`/forms/${formId}/edit/settings`),
      },
    ];

    const handleClickSettings = () => navigate(`/forms/${formId}/edit/settings`);

    if (!currentPublication) {
      return null;
    }

    return (
      <ReactFlowProvider>
        <WorkflowBuilderSkeletonView
          tabs={tabs}
          isLoading={isFormLoading}
          isError={isFormError}
          label={form?.title}
          backPath="/forms"
          onClickSettings={handleClickSettings}
          statusBadge={
            <Badge type={isFormLive ? 'success' : 'information'}>
              <span className="text-xs capitalize">{form?.status}</span>
            </Badge>
          }
          outletContext={{
            form,
            isSorting,
            isFormLoading,
            isFormError,
            setIsSorting,
            sortingQuestionId,
            setSortingQuestionId,
            reorderQuestion,
            currentCustomFieldIds,
          }}
          headerButtons={
            form ? (
              <>
                {!isFormArchived && !isFormLive ? <DeleteFormButton form={form} /> : null}
                {!isFormLive ? <PreviewFormButton form={form} /> : null}
                {isFormArchived ? <UnarchiveFormButton form={form} /> : null}
                {!isFormLive && !isFormArchived ? <PublishFormButton form={form} /> : null}
                {isFormLive && !isFormArchived ? <ArchiveFormButton form={form} /> : null}
              </>
            ) : null
          }
        />
      </ReactFlowProvider>
    );
  }

  return (
    <div className="pb-24">
      <ActionModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onProceed={handleDelete}
        resourceId={formId || ''}
        isWorking={deleteForm.isLoading}
        headerText="Delete Survey"
        actionText="Delete"
        buttonType="danger"
      >
        Are you sure you want to delete this survey?
      </ActionModal>
      <ActionModal
        isOpen={isPublishing}
        onClose={() => setIsPublishing(false)}
        onProceed={handlePublish}
        resourceId={formId || ''}
        isWorking={publishForm.isLoading}
        headerText="Publish Survey"
        actionText="Publish"
      >
        <div className="space-y-2">
          <p>Are you sure you want to publish this survey?</p>
          <p>
            To protect the intregrity of the data you receive from subscribers,{' '}
            <span className="font-bold text-gray-900">
              you will NOT be able to edit any of the survey details once it is live.
            </span>
          </p>
          <p>
            Maybe you would like to preview this survey before turning it on?{' '}
            {form?.url && form?.form_questions?.length ? (
              <div className="text-sm text-primary-500 underline underline-primary-500 flex items-center">
                <a
                  href={`${form.url}?preview=true`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm text-primary-500 underline underline-primary-500 flex items-center"
                >
                  <span>Click to Preview</span>.
                </a>
              </div>
            ) : null}
          </p>
        </div>
      </ActionModal>
      <div>
        <BackLink to="/forms">Exit</BackLink>
        <div className="flex flex-col items-end">
          <div className="w-full">
            {isFormLive && (
              <div className="mb-2">
                <Badge type="success">
                  <span className="text-xs capitalize">Live</span>
                </Badge>
              </div>
            )}
            <PageHeading title="Build a new survey" description="Add questions and publish a new survey below">
              {form ? renderHeaderActions() : <div className="h-8 w-40 bg-gray-200 animate-pulse rounded" />}
            </PageHeading>
          </div>
          {form?.url && form?.form_questions?.length && !isFormLive ? (
            <div className="text-sm text-primary-500 underline underline-primary-500 flex items-center">
              <EyeIcon className="w-4 h-4 mr-1 underline underline-primary-500" />
              <a
                href={`${form.url}?preview=true`}
                target="_blank"
                rel="noreferrer"
                className="text-sm text-primary-500 underline underline-primary-500 flex items-center"
              >
                <span>Preview Survey</span>
              </a>
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex  flex-col space-y-8 w-full">
        <div className="w-full">
          <div className="flex flex-col space-y-3">
            <Input
              name="title"
              value={title}
              labelText="Title"
              placeholder="Website Survey"
              onChange={(e) => setTitle(e.target.value)}
            />
            <Textarea
              name="description"
              value={description}
              labelText="Description"
              placeholderText="This survey is to collect feedback on the new website design."
              helperText="Use this to set a helpful description or to provide a prompt to fill out the survey for your subscribers!"
              onChange={(e) => setDescription(e.target.value)}
            />
            <Input
              name="cta_text"
              value={ctaText}
              labelText="CTA Text"
              placeholder="Submit Survey"
              helperText="This is the text that will appear on the submit button for your survey."
              onChange={(e) => setCtaText(e.target.value)}
            />
            <Input
              name="thank_you_text"
              value={thankYouMessage}
              labelText="Thank You Message"
              placeholder="Thanks for your submission, it will go towards improving the publication!"
              helperText="This is the message users will see after submitting the survey."
              onChange={(e) => setThankYouMessage(e.target.value)}
            />
          </div>
        </div>
        {isFormLive && (
          <HelpingHand>
            <p>
              This survey is already live! To prevent inconsistent data, we prevent users from updating live surveys.
              However, feel free to edit any of the details above.
            </p>
          </HelpingHand>
        )}
        <div className={cx('transition-all space-y-8 w-full relative', isFormLive && 'opacity-60 pointer-events-none')}>
          {questions?.length ? (
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
            >
              <SortableContext items={questions} strategy={verticalListSortingStrategy}>
                {questions?.length
                  ? questions.map((question: FormQuestion, index: number) => {
                      const isLastQuestion = index === questions.length - 1;

                      return (
                        <QuestionForm
                          key={question.id}
                          question={question}
                          isDragActive={isDragActive}
                          isLastQuestion={isLastQuestion}
                          questionType={question.question_type}
                          hasMoreThanOneQuestion={hasMoreThanOneQuestion}
                          currentCustomFieldIds={currentCustomFieldIds}
                          onAddQuestionClick={onAddNewQuestionClick}
                          index={index}
                        />
                      );
                    })
                  : null}
              </SortableContext>
            </DndContext>
          ) : (
            <AddNewQuestion
              isInitialQuestion
              nextQuestionOrder={1}
              currentCustomFieldIds={[]}
              onAddQuestionClick={onAddNewQuestionClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

type ContextType = {
  form: Form;
  isFormLoading: boolean;
  isFormError: boolean;
  isSorting: boolean;
  setIsSorting: (isSorting: boolean) => void;
  sortingQuestionId: string | null;
  setSortingQuestionId: (questionId: string | null) => void;
  reorderQuestion: (activeQuestionId: string, overQuestionId: string) => Promise<void>;
  currentCustomFieldIds: string[];
};

export const useEditFormViewContent = () => useOutletContext<ContextType>();

export default EditForm;
