import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { HandRaisedIcon } from '@heroicons/react/24/outline';

import ActionModal from '../../../../../../components/ActionModal';
import { Input, Switch } from '../../../../../../components/Form';
import { useUpdateWebTemplate, useWebTemplate } from '../../../../../../hooks';
import { useCurrentPublication } from '../../../../../../hooks/usePublications';
import { SignupFlowItem } from '../../../../../../interfaces/web_template';
import { isValidUrl } from '../../../../../../utils';

import FlowStep from './FlowStep';

interface Props {
  step: SignupFlowItem;
}

const CustomStep = ({ step }: Props) => {
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
  const [url, setUrl] = useState('');

  const webTemplateMutation = useUpdateWebTemplate();
  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate }: any = webTemplateRequest;

  useEffect(() => {
    if (webTemplate?.custom_signup_redirect_url) {
      setUrl(webTemplate?.custom_signup_redirect_url);
    }
  }, [webTemplate]);

  const addCustomRedirect = async () => {
    if (!isValidUrl(url)) {
      toast.error('Please include a valid URL');
      return;
    }

    await webTemplateMutation.mutateAsync({ custom_signup_redirect_url: url });
    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
    toast.success('Saved!');
    setIsModalOpen(false);
  };

  const removeCustomRedirect = async () => {
    await webTemplateMutation.mutateAsync({ custom_signup_redirect_url: null });
    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
    setUrl('');
    toast.success('Saved!');
    setIsDisableModalOpen(false);
  };

  return (
    <>
      {/* Disable Action Modal */}
      <ActionModal
        isOpen={isDisableModalOpen}
        onClose={() => setIsDisableModalOpen(false)}
        resourceId=""
        headerText={step.modal_header}
        onProceed={removeCustomRedirect}
        isWorking={false}
        actionText={step.action_text}
      >
        <div className="text-sm space-y-4">{step.modal_body}</div>
      </ActionModal>

      {/* Enable Action Modal */}
      <ActionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        resourceId=""
        headerText={webTemplate?.custom_signup_redirect_url ? 'Update Custom Redirect' : 'Add Custom Redirect'}
        onProceed={addCustomRedirect}
        isWorking={webTemplateMutation.isLoading}
        actionText={webTemplate?.custom_signup_redirect_url ? 'Update Redirect' : 'Add Redirect'}
      >
        <div className="text-sm space-y-4">
          <p>
            This is an opportuntity to customize the final step of your signup flow by sending users to an external link
            or an internal page on your site (other than the default home page).
          </p>
          <div>
            <p className="font-bold">If you choose an external link:</p>
            <p>
              A new tab will open for your readers to the link, while still sending your website to the home page in the
              background.
            </p>
          </div>

          <Input
            name="url"
            labelText="Link"
            value={url}
            required
            placeholder="Add the URL here"
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
      </ActionModal>
      <div className="space-y-8">
        <FlowStep
          title={step.title}
          enabled
          url={step.full_url}
          onEdit={webTemplate?.custom_signup_redirect_url ? () => setIsModalOpen(true) : undefined}
        >
          {step.enabled ? (
            <Switch
              name="custom"
              checked={step.enabled}
              onChange={() => setIsDisableModalOpen(true)}
              variant="primary"
            />
          ) : (
            <button
              type="button"
              onClick={() => setIsModalOpen(true)}
              className="flex text-xs font-regular text-primary-500 items-center hover:text-primary-600 underline"
            >
              Customize
            </button>
          )}
        </FlowStep>
        <div className="bg-blue-50 p-4 rounded space-y-2">
          <div className="flex space-x-1 items-center">
            <HandRaisedIcon className="h-3 w-3 font-bold text-xs text-blue-500" />
            <span className="font-bold text-xs text-blue-500">Helping Hand:</span>
          </div>
          <p className="text-xs text-blue-600">
            New signups to your publication will be redirected through the highlighted steps in the order they are
            displayed above.
          </p>
          <p className="text-xs text-blue-600">
            By default, subscribers will be redirected to the home page on completion of this flow. By clicking
            &apos;customize&apos; you can optionally choose to send new subscribers to a different internal or external
            page on completion.
          </p>
          <p className="text-xs text-blue-600">
            And finally, don&apos;t forget to add some custom messaging for each step in the flow to help your readers
            along! Select &apos;message&apos; next to each step to customize.
          </p>
        </div>
      </div>
    </>
  );
};

export default CustomStep;
