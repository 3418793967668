import { Button } from '@/ui/Button';

const PartnerProgramStartHere = () => {
  const resources = [
    {
      id: 1,
      title: 'Overview',
      description: 'Share your partner link with your contacts and followers',
      redirectUrl: 'https://www.youtube.com/watch?v=TZCeDnea51E',
      buttonCTA: 'Watch Video',
      imageUrl: 'https://beehiiv-images-production.s3.amazonaws.com/static_assets/partnerprogram.png',
    },
    {
      id: 2,
      title: 'Share',
      description: '15 ways to promote beehiiv as a partner',
      buttonCTA: 'Read Article',
      redirectUrl: 'https://blog.beehiiv.com/p/beehiiv-partner-program-promotion',
      imageUrl: 'https://beehiiv-images-production.s3.amazonaws.com/static_assets/partner-program-image-2.png',
    },
    {
      id: 3,
      title: 'Unlock Payouts',
      description: 'Complete our quick tax compliance process to unlock payouts',
      redirectUrl:
        'https://support.beehiiv.com/hc/en-us/articles/23350822822551-Unlocking-Partner-Payouts-How-to-become-tax-compliant-and-get-paid',
      buttonCTA: 'Getting Started Guide',
      imageUrl: 'https://beehiiv-images-production.s3.amazonaws.com/static_assets/partner-program-image.png',
    },
  ];

  return (
    <div className="p-6 w-full flex flex-col space-y-6 bg-white border rounded-lg">
      <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4 w-full">
        {resources.map((resource) => {
          return (
            <div className="p-4 bg-gray-50 border w-full lg:w-1/3 rounded-lg flex flex-col space-y-3.5">
              <img
                alt={`${resource.title}`}
                className="object-cover aspect-video rounded flex-shrink-0"
                src={resource.imageUrl}
              />
              <div className="flex flex-row space-x-2">
                <div className="p-1.5 bg-violet-500 rounded-full w-6 h-6 text-sm flex flex-col justify-center items-center text-white font-medium">
                  {resource.id}
                </div>
                <p className="my-auto text-gray-900 font-medium">{resource.title}</p>
              </div>
              <p className="font-regular text-gray-600 text-sm flex-grow">{resource.description}</p>
              <div className="flex justify-end mt-auto">
                <Button
                  onClick={() => window.open(resource.redirectUrl, '_blank')}
                  type="button"
                  size="xs"
                  className="w-fit"
                  variant="secondary"
                >
                  {resource.buttonCTA}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PartnerProgramStartHere;
