import { useCallback, useEffect, useMemo } from 'react';

type RefType = React.RefObject<HTMLElement> | React.RefObject<HTMLElement>[];

/**
 * Detects clicks outside of a given set of DOM elements and calls a callback function when a click is detected.
 *
 * @param refs - A single DOM element reference or an array of DOM element references.
 * @param callback - A function to be called when a click occurs outside of the specified DOM elements.
 */
const useClickedOutside = (refs: RefType, callback: () => void) => {
  const refArray = useMemo(() => {
    return Array.isArray(refs) ? refs : [refs];
  }, [refs]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      const isOutside = !refArray.some((ref) => ref.current?.contains(e.target as Node));
      if (isOutside) {
        callback();
      }
    },
    [refArray, callback]
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [refs, callback, handleClick]);
};

export default useClickedOutside;
