import { ArrowUpRightIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import LoadingBox from '@/components/LoadingBox';
import { Typography, TypographyRow } from '@/components/Typography';
import { Button } from '@/ui/Button';

import Section from '../../../_components/Section';
import { useBillingSummary, usePlanPrice } from '../../_hooks';

import PlanCard from './PlanCard';

const BEEHIIV_PRICING_URL = 'https://www.beehiiv.com/pricing';

const CurrentPlan: React.FC<{ organizationId: string }> = ({ organizationId }) => {
  const { data: billingSummary } = useBillingSummary({ organizationId, includeLaunch: true });
  const { next_invoice: nextInvoice } = billingSummary || {};

  const { data: planPrice, isLoading: isLoadingCurrent, isError: isErrorCurrent } = usePlanPrice({ organizationId });

  const {
    data: upcomingPlanPrice,
    isLoading: isLoadingUpcoming,
    isError: isErrorUpcoming,
  } = usePlanPrice({ organizationId, upcoming: true });

  const showUpcoming = upcomingPlanPrice && upcomingPlanPrice.id !== planPrice?.id;

  const pricingUrl = `${BEEHIIV_PRICING_URL}?subscriberCount=${planPrice?.active_subscriptions}`;

  return (
    <Section
      title="Your Current Plan"
      className="max-w-3xl"
      titleAction={
        <div className="flex space-x-4">
          <a href={pricingUrl} target="_blank" rel="noreferrer">
            <Button type="button" variant="primary-inverse" iconRight Icon={ArrowUpRightIcon}>
              Compare Plans
            </Button>
          </a>
        </div>
      }
    >
      <LoadingBox
        isLoading={isLoadingCurrent || isLoadingUpcoming}
        isError={isErrorCurrent || isErrorUpcoming}
        backgroundClassName="bg-transparent"
        height={300}
      >
        <div className="space-y-6">
          {planPrice && <PlanCard planPrice={planPrice} />}
          {showUpcoming && (
            <div className="space-y-2">
              <TypographyRow>
                <Typography as="p" token="font-medium/text/base">
                  Upcoming Plan
                </Typography>
                {nextInvoice?.timestamp && (
                  <Typography token="font-medium/text/sm" colorWeight="500">
                    (starting {moment(nextInvoice.timestamp).format('LL')})
                  </Typography>
                )}
              </TypographyRow>
              <PlanCard planPrice={upcomingPlanPrice} />
            </div>
          )}
        </div>
      </LoadingBox>
    </Section>
  );
};

export default CurrentPlan;
