import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

export default function useCreatePage() {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();

  return useMutation(
    async ({ parentPath }: { parentPath: string[] }) => {
      const { data } = await api.post(`/pages`, {
        publication_id: currentPublicationId,
        parent_path: parentPath,
      })
      return data
    },
    {
      onSuccess: (data) => {
        toast.success('Page created successfully');
        queryClient.invalidateQueries(['publications', currentPublicationId, 'pages']);
        queryClient.invalidateQueries(['publications', currentPublicationId, 'sites', "null"]);


        navigate(`/website_builder_v2/page/${data.id}`);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
}
