import { Outlet, useLocation } from 'react-router-dom';

import PageHeading from '../../components/Layout/PageLayout/PageHeading';
import TabNavigation from '../../components/TabNavigation';
import { useRequireSetting } from '../../hooks';
import { useTutorial } from '../../hooks/useTutorials';
import { Tab } from '../../interfaces/general';
import { Setting } from '../../interfaces/setting';
import { TutorialType } from '../../interfaces/tutorial';

const NavContainer = () => {
  useRequireSetting({ setting: Setting.RECOMMENDATIONS, message: 'Access denied.', redirectTo: '/' });

  const tutorial = useTutorial(TutorialType.RECOMMENDATIONS);
  const { pathname } = useLocation();

  const tabs = [
    {
      name: 'recommendations',
      label: 'Your recommendations',
      targetRoute: `/recommendations`,
      selected: pathname === '/recommendations',
    },
    {
      name: 'external',
      label: 'Recommending you',
      targetRoute: `/recommendations/external`,
      selected: pathname === '/recommendations/external',
    },
  ] as Tab[];

  return (
    <>
      <PageHeading
        title="Recommendations"
        description="Curate and share your favorite publications with your audience"
        tutorial={tutorial}
      />
      <TabNavigation tabs={tabs} className="sm:border-b pt-4 mb-4" />
      <Outlet />
    </>
  );
};

export default NavContainer;
