import toast from 'react-hot-toast';

import { SimpleSelect } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useUpdateWebTemplate, useWebTemplate } from '@/hooks';

const CommentsOrdering = () => {
  const webTemplateMutation = useUpdateWebTemplate(() => {
    toast.success('Saved!');
  });
  const { data: webTemplate, isLoading } = useWebTemplate();

  const handleUpdateCommentOrdering = (value: string) => {
    webTemplateMutation.mutate({
      comments_ordering: value,
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col space-y-1">
        <Typography token="font-medium/text/base" className="text-900">
          Comment Ordering
        </Typography>
        <Typography token="font-light/text/xs" className="text-900">
          Order comments by newest, oldest, or most popular.
        </Typography>
      </div>
      {!isLoading && (
        <div className="flex space-x-2 w-full">
          <div className="w-80">
            <SimpleSelect
              name="comment-ordering"
              labelText="Select Order"
              value={webTemplate?.comments_ordering || ''}
              onSelect={(_name: string, value: string) => handleUpdateCommentOrdering(value)}
              options={webTemplate?.comment_order_options || []}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentsOrdering;
