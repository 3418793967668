import { useInfiniteQuery, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Pagination } from '../interfaces/general';
import { Import } from '../interfaces/import';
import api from '../services/swarm';

interface ApiResponse {
  imports: Import[];
  pagination: Pagination;
  import_limit: number;
  import_limit_remaining: number;
}

export default function useImports() {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const fetchImports = ({ pageParam = 1 }) =>
    api
      .get(`/imports`, {
        params: {
          page: pageParam,
          per_page: 10,
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([currentPublicationId, 'imports'], fetchImports, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    retry: false,
    keepPreviousData: true,
    onSuccess: (data) => {
      const imports = data.pages.flatMap((page) => page.imports);

      imports.forEach((importPreview) => {
        queryClient.setQueryData(['imports', importPreview?.id], {
          created_at: importPreview?.created_at,
          failure_reason: importPreview?.failure_reason,
          file: importPreview?.file,
          id: importPreview?.id,
          import_type: importPreview?.import_type,
          imported_at: importPreview?.imported_at,
          imported_subscription_count: importPreview?.imported_subscription_count,
          imported_subscription_count_by_status: importPreview?.imported_subscription_count_by_status,
          publication_id: importPreview?.publication_id,
          status: importPreview?.status,
        });
      });
    },
    onError: (errPayload: any) => errPayload?.response?.data?.error || 'Something went wrong',
  });
}
