import React from 'react';
import Tippy from '@tippyjs/react/headless';

import { Styled } from './styled';
import { TippyProps, TooltipProps } from './types';

const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

const ShortcutKey = ({ children }: { children: string }): JSX.Element => {
  if (children === 'Mod') {
    return <Styled.Key>{isMac ? '⌘' : 'Ctrl'}</Styled.Key>; // ⌃
  }

  if (children === 'Shift') {
    return <Styled.Key>⇧</Styled.Key>;
  }

  if (children === 'Alt') {
    return <Styled.Key>{isMac ? '⌥' : 'Alt'}</Styled.Key>;
  }

  return <Styled.Key>{children}</Styled.Key>;
};

export const Tooltip = ({
  children,
  enabled = true,
  title,
  shortcut,
  tippyOptions = {},
}: TooltipProps): JSX.Element => {
  if (enabled) {
    return (
      <Tippy
        delay={750}
        offset={[0, 15]}
        touch={false}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...tippyOptions}
        render={(attrs: TippyProps) => (
          <Styled.Tooltip
            tabIndex={-1}
            data-placement={attrs['data-placement']}
            data-reference-hidden={attrs['data-reference-hidden']}
            data-escaped={attrs['data-escaped']}
          >
            {title && <Styled.Title>{title}</Styled.Title>}
            {shortcut && shortcut.map((shortcutKey) => <ShortcutKey key={shortcutKey}>{shortcutKey}</ShortcutKey>)}
          </Styled.Tooltip>
        )}
      >
        <div>{children}</div>
      </Tippy>
    );
  }

  return <>{children}</>;
};

export default Tooltip;
