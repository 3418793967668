import { Fragment, ReactNode } from 'react';

import { useSettings } from '@/context/settings-context';
import usePostEmailClickStats from '@/hooks/usePostEmailClickStats';
import usePostSendAndOpenStats from '@/hooks/usePostSendAndOpenStats';
import usePostStats from '@/hooks/usePostStats';
import usePostWebStats from '@/hooks/usePostWebStats';
import usePublicationTotalClickedStats from '@/hooks/usePublicationTotalClickedStats';
import usePublicationTotalOpenedStats from '@/hooks/usePublicationTotalOpenedStats';
import usePublicationTotalSentStats from '@/hooks/usePublicationTotalSentStats';
import { DeviceType, PostStats } from '@/interfaces/post';

interface Props {
  postId: string;
  children: (stats: PostStats, isLoading: boolean, isError: boolean) => ReactNode; // todo: make stats a union of new types
  deviceType?: DeviceType;
}

const clickRate = (totalClicked?: number, totalOpened?: number) => {
  if (!totalOpened) {
    return 0;
  }

  return Number((((totalClicked || 0) / totalOpened) * 100).toFixed(2));
};

const openRate = (totalOpened?: number, totalSent?: number) => {
  if (!totalSent) {
    return 0;
  }

  return Number((((totalOpened || 0) / totalSent) * 100).toFixed(2));
};

const errorRate = (totalErrored?: number, totalSent?: number) => {
  if (!totalSent) {
    return 0;
  }

  return Number((((totalErrored || 0) / totalSent) * 100).toFixed(2));
};

const PostStatsContainer = ({ postId, children, deviceType }: Props) => {
  const { settings } = useSettings();
  const canUseApiary = settings?.use_apiary === true;

  // Honeycomb calls
  // Deprecate this once we've moved to Apiary on production for all Publications
  const { data, isLoading, isError } = usePostStats({ id: postId, isEnabled: !canUseApiary });

  // Apiary calls
  const {
    data: postWebStatsData,
    isLoading: isLoadingPostWebStats,
    isError: isErrorPostWebStats,
  } = usePostWebStats({ id: postId, isEnabled: canUseApiary, staleTime: undefined });
  const {
    data: postEmailClickStatsData,
    isLoading: isLoadingPostEmailClickStats,
    isError: isErrorPostEmailClickStats,
  } = usePostEmailClickStats({ id: postId, isEnabled: canUseApiary, deviceType, staleTime: undefined });
  const {
    data: postSendAndOpenStatsData,
    isLoading: isLoadingPostSendAndOpenStats,
    isError: isErrorPostSendAndOpenStats,
  } = usePostSendAndOpenStats({ id: postId, isEnabled: canUseApiary, staleTime: undefined });
  const {
    data: publicationTotalSentData,
    isLoading: isLoadingPublicationTotalSentStats,
    isError: isErrorPublicationTotalSentStats,
  } = usePublicationTotalSentStats({ isEnabled: canUseApiary });
  const {
    data: publicationTotalClickedData,
    isLoading: isLoadingPublicationTotalClickedStats,
    isError: isErrorPublicationTotalClickedStats,
  } = usePublicationTotalClickedStats({ isEnabled: canUseApiary });
  const {
    data: publicationTotalOpenedData,
    isLoading: isLoadingPublicationTotalOpenedStats,
    isError: isErrorPublicationTotalOpenedStats,
  } = usePublicationTotalOpenedStats({ isEnabled: canUseApiary });

  const totalDelivered = postSendAndOpenStatsData?.total_delivered || 0;

  if (canUseApiary) {
    return (
      <Fragment key={`post-stats-container-${postId}`}>
        {children(
          {
            total_sent: postSendAndOpenStatsData?.total_sent || 0,
            total_opened: postSendAndOpenStatsData?.total_opened || 0,
            total_unique_opened: postSendAndOpenStatsData?.total_unique_opened || 0,
            total_email_clicked: postEmailClickStatsData?.total_verified_clicked || 0,
            total_unique_email_clicked: postEmailClickStatsData?.total_unique_verified_clicked || 0,
            open_rate: openRate(postSendAndOpenStatsData?.total_unique_opened, postSendAndOpenStatsData?.total_sent),
            all_time_open_rate: openRate(
              publicationTotalOpenedData?.total_opened,
              publicationTotalSentData?.total_sent
            ),
            click_rate: clickRate(
              postEmailClickStatsData?.total_unique_verified_clicked,
              postSendAndOpenStatsData?.total_unique_opened
            ),
            click_rate_by_total_delivered: clickRate(
              postEmailClickStatsData?.total_unique_verified_clicked,
              totalDelivered
            ),
            click_rate_by_total_opened: clickRate(
              postEmailClickStatsData?.total_unique_verified_clicked,
              postSendAndOpenStatsData?.total_opened
            ),
            all_time_click_rate: clickRate(
              publicationTotalClickedData?.total_clicked,
              publicationTotalOpenedData?.total_opened
            ),
            total_web_clicked: postWebStatsData?.total_web_clicked || 0,
            total_unique_web_clicked: postWebStatsData?.total_unique_web_clicked || 0,
            total_web_viewed: postWebStatsData?.total_web_viewed || 0,
            total_unsubscribes: postSendAndOpenStatsData?.total_unsubscribes || 0,
            total_spam_reported: postSendAndOpenStatsData?.total_spam_reported || 0,
            error_rate: errorRate(postSendAndOpenStatsData?.total_errored, postSendAndOpenStatsData?.total_sent),
            total_delivered: totalDelivered,
            total_errored: postSendAndOpenStatsData?.total_errored || 0,
          },
          isLoadingPostWebStats ||
            isLoadingPostEmailClickStats ||
            isLoadingPostSendAndOpenStats ||
            isLoadingPublicationTotalSentStats ||
            isLoadingPublicationTotalClickedStats ||
            isLoadingPublicationTotalOpenedStats,
          isErrorPostWebStats ||
            isErrorPostEmailClickStats ||
            isErrorPostSendAndOpenStats ||
            isErrorPublicationTotalSentStats ||
            isErrorPublicationTotalClickedStats ||
            isErrorPublicationTotalOpenedStats
        )}
      </Fragment>
    );
  }

  return (
    <>
      {children(
        {
          total_sent: data?.total_sent || 0,
          total_opened: data?.total_opened || 0,
          total_unique_opened: data?.total_unique_opened || 0,
          total_email_clicked: data?.total_email_clicked || 0,
          total_unique_email_clicked: data?.total_unique_email_clicked || 0,
          open_rate: data?.open_rate || 0,
          all_time_open_rate: data?.all_time_open_rate || 0,
          click_rate: data?.click_rate || 0,
          click_rate_by_total_delivered: 0,
          click_rate_by_total_opened: 0,
          all_time_click_rate: data?.all_time_click_rate || 0,
          total_web_clicked: data?.total_web_clicked || 0,
          total_unique_web_clicked: data?.total_unique_web_clicked || 0,
          total_web_viewed: data?.total_web_viewed || 0,
          total_unsubscribes: data?.total_unsubscribes || 0,
          total_spam_reported: data?.total_spam_reported || 0,
          error_rate: data?.error_rate || 0,
          total_delivered: data?.total_delivered || 0,
          total_errored: data?.total_errored || 0,
        },
        isLoading,
        isError
      )}
    </>
  );
};

export default PostStatsContainer;
