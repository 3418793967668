import { BadgeProps, BadgeSize, BadgeType } from './Badge.types';

const colors = {
  success: 'bg-feedback-success-100 border border-feedback-success-200 text-feedback-success-900',
  warning: 'bg-feedback-warning-100 border border-feedback-warning-200 text-feedback-warning-900',
  danger: 'bg-feedback-danger-100 border border-feedback-danger-200 text-feedback-danger-900',
  info: 'bg-feedback-info-100 border border-feedback-info-200 text-feedback-info-800',
  default_lined: 'bg-surface-100 border border-surface-200 text-gray-700',
  default_filled: 'bg-surface-100 border border-surface-100 text-gray-700',
  tertiary_lined: 'bg-action-tertiary-100 border border-action-tertiary-200 text-action-tertiary-800',
};

const sizes = {
  sm: 'px-1 py-0.5',
  base: 'px-2 py-1',
};

const Badge = ({
  text,
  leadingIcon,
  trailingIcon,
  size = BadgeSize.BASE,
  type = BadgeType.DEFAULT_LINED,
}: BadgeProps) => {
  return (
    <div className={`inline-flex items-center gap-1 rounded ${colors[type]} ${sizes[size]}`}>
      {leadingIcon}
      <span className="font-medium text-xs">{text}</span>
      {trailingIcon}
    </div>
  );
};

export default Badge;
