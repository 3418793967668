import { ReactNode, useState } from 'react';
import cx from 'classnames';

import { getBorderRadius, getColor } from '../DesignTokens';

import useIntersectionObserver from './useIntersectionObserver';

type Props = {
  isSecondary?: boolean;
  className?: string;
  children: ReactNode | ((isInViewport: boolean) => ReactNode);
};

export const Items = ({ children, className, isSecondary = false }: Props) => {
  const [isInViewport, setIsInViewport] = useState(false);

  const { ref } = useIntersectionObserver({
    onIntersect: (value: boolean) => {
      setIsInViewport(value);
    },
  });

  return (
    <div
      className={cx(
        'overflow-auto sm:overflow-visible',
        !isSecondary && 'shadow-sm',
        !isSecondary && getColor('white/900', 'background'),
        isSecondary && getColor('surface/50', 'background'),
        getBorderRadius('border/rounded/medium'),
        className
      )}
      ref={ref}
    >
      <table className="w-full h-full">{typeof children === 'function' ? children(isInViewport) : children}</table>
    </div>
  );
};
