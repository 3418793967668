import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { CheckIcon, ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Placement } from '@popperjs/core';
import cx from 'classnames';

import useArrowKeyNavigation from '@/hooks/useArrowKeyNavigation';
import { Option } from '@/interfaces/general';

import Text from '../../components/Text';

interface Props<T = string | string[]> {
  name: string;
  onSelect: (name: string, value: T) => void;
  value: T;
  dropdownDirection?: 'up' | 'down';
  autoWidth?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  label?: string;
  options?: Option[];
  placeholderText?: string;
  suffixElement?: React.ReactNode;
  variant?: 'default' | 'ellipsis' | 'right-aligned';
  matchWidth?: boolean;
}

const getPlacement = ({
  dropdownDirection,
  variant,
}: {
  dropdownDirection: Props['dropdownDirection'];
  variant: Props['variant'];
}) => {
  let placement: Placement = 'bottom-start';

  if (dropdownDirection === 'up') {
    placement = 'top';
  }

  if (!dropdownDirection && (variant === 'ellipsis' || variant === 'right-aligned')) {
    placement = 'bottom-end';
  }

  return placement;
};

const Dropdown = ({
  dropdownDirection,
  helperText,
  label,
  name,
  onSelect,
  options,
  placeholderText,
  suffixElement,
  value,
  variant,
  autoWidth,
  fullWidth,
  matchWidth = false,
}: Props) => {
  const [referenceEl, setReferenceEl] = useState<HTMLButtonElement | null>(null);
  const [popperEl, setPopperEl] = useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { styles, attributes } = usePopper(referenceEl, popperEl, {
    placement: getPlacement({ dropdownDirection, variant }),
  });
  const isVariantDefault = !variant || variant === 'default';
  const isVariantEllipsis = variant === 'ellipsis';
  const isVariantRightAligned = variant === 'right-aligned';
  const selectedLabel = options?.filter((option) => option.value === value)[0]?.label;

  useArrowKeyNavigation({ triggerElementId: `select-${name}-button`, listElementClass: `select-${name}-option` });

  const toggleOptions = () => {
    setIsOpen((prev) => !prev);
  };

  const [buttonWidth, setButtonWidth] = useState<number | null>(null);

  useEffect(() => {
    if (matchWidth && referenceEl) {
      setButtonWidth(referenceEl.offsetWidth);
    }
  }, [matchWidth, referenceEl]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        referenceEl &&
        !referenceEl?.contains(event.target as Node) &&
        popperEl &&
        !popperEl?.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [referenceEl, popperEl]);

  return (
    <>
      {label && (
        <div className="mb-2">
          <label htmlFor={`select-${name}-button`} className="font-medium text-gray-800 text-sm">
            {label}
          </label>
        </div>
      )}
      {(isVariantDefault || isVariantRightAligned) && (
        <button
          className={cx(
            'shadow-sm flex h-9 items-center justify-between text-left border-surface-200 text-gray-900 rounded-md border py-1 px-3 bg-white',
            { 'min-w-dropdown': (!variant || variant === 'default') && !autoWidth },
            { 'w-full': fullWidth }
          )}
          id={`select-${name}-button`}
          onClick={toggleOptions}
          ref={setReferenceEl}
          type="button"
        >
          <input type="hidden" name={name} value={value} />
          <Text type="body" size="sm" className="text-ellipsis overflow-hidden whitespace-nowrap">
            {selectedLabel || placeholderText}
          </Text>
          {suffixElement || <ChevronDownIcon className="h-5 w-5 text-surface-500 ml-2" aria-hidden="true" />}
        </button>
      )}
      {isVariantEllipsis && (
        <button className="text-gray-900 bg-white" onClick={toggleOptions} ref={setReferenceEl} type="button">
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      )}
      {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}

      {isOpen &&
        createPortal(
          <div
            className={cx(
              'mt-1 shadow-md py-1 text-left border-surface-200 rounded-md bg-white z-50 max-h-60 overflow-auto',
              {
                'min-w-dropdown': !matchWidth,
              }
            )}
            ref={setPopperEl}
            style={{
              ...styles.popper,
              width: matchWidth && buttonWidth ? `${buttonWidth}px` : undefined,
            }}
            {...attributes.popper}
          >
            <ul>
              {options?.map((option) => {
                const selected = value === option.value;
                const handleSelect = () => {
                  toggleOptions();
                  onSelect(name, option.value);
                };

                if (option.isOptGroup) {
                  return (
                    <li tabIndex={-1} key={option.label} className="py-1 text-gray-700 font-semibold">
                      <div
                        tabIndex={-1}
                        className={`select-${name}-option py-2 pl-3 pr-9 font-bold text-gray-400 text-xs mt-1 border-t`}
                      >
                        {option.label}
                      </div>
                    </li>
                  );
                }

                return (
                  <li key={`${name}-${option.value}`}>
                    <button
                      aria-label={option.label}
                      className={cx(
                        `select-${name}-option flex py-1 px-3 items-center justify-between text-left min-w-dropdown text-sm w-full hover:bg-surface-100`,
                        {
                          'bg-surface-100': selected,
                          'bg-white': !selected,
                          'py-3 font-semibold': option.description,
                          'py-1 h-9': !option.description,
                          'border-t border-surface-100': option.topBorder,
                          'text-red-600': option.danger,
                        }
                      )}
                      onClick={handleSelect}
                      type="button"
                    >
                      <div>
                        <div>{option.label}</div>
                        {option.description && (
                          <div className="text-gray-700 font-normal mt-2">
                            <Text type="body" size="xs">
                              {option.description}
                            </Text>
                          </div>
                        )}
                      </div>
                      {selected && <CheckIcon width={15} height={15} className="mr-1" aria-hidden="true" />}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>,
          document.body
        )}
    </>
  );
};

export default Dropdown;
