import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';

import { EmailMessage } from '../../../../../interfaces/email_message';
import { IData } from '../../../../../interfaces/general';
import api from '../../../../../services/swarm';
import PreviewModal from '../../../../PreviewModal';
import TabNavigation from '../../../../TabNavigation';

import Configure from './Configure';
import Test from './Test';

interface RightPaneProps {
  emailMessage: EmailMessage;
  onChange: (params: any) => void;
  showResourceSelect: boolean;
}

const RightPane: React.FunctionComponent<RightPaneProps> = ({
  emailMessage,
  onChange,
  showResourceSelect,
}: RightPaneProps) => {
  const [activeTab, setActiveTab] = useState('configure');
  const [showPreview, setShowPreview] = useState(false);

  const onFetchPreview = useCallback(
    (_platform: string, advancedParams: IData, onFetch: Function) => {
      api
        .get(`/email_messages/${emailMessage.id}/preview`, {
          params: { publication_id: emailMessage.publication_id, ...advancedParams },
        })
        .then((resp) => {
          onFetch(resp.data.html);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [emailMessage.id, emailMessage.publication_id]
  );

  const sideTabs = [
    {
      name: 'configure',
      label: 'Configure',
      selected: activeTab === 'configure',
      onSelect: () => setActiveTab('configure'),
    },
    {
      name: 'preview',
      label: 'Preview',
      selected: false,
      onSelect: () => setShowPreview(true),
    },
    {
      name: 'test',
      label: 'Test',
      selected: activeTab === 'test',
      onSelect: () => setActiveTab('test'),
    },
  ];

  return (
    <>
      <div className="h-12 flex items-center">
        <TabNavigation className="px-2 w-full h-full border-b" tabs={sideTabs} />
      </div>
      <div className="h-full pb-16 xl:pb-32 overflow-y-auto">
        {activeTab === 'configure' && (
          <Configure emailMessage={emailMessage} onChange={onChange} showResourceSelect={showResourceSelect} />
        )}
        {activeTab === 'test' && <Test emailMessage={emailMessage} />}
      </div>
      <PreviewModal
        showSubscriberSelect
        fetchPreview={onFetchPreview}
        active={showPreview}
        tabs={['Email']}
        onClose={() => setShowPreview(false)}
      />
    </>
  );
};

export default RightPane;
