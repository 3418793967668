import { useTier } from '@/routes/settings/publication/premium/_hooks';

export const TierName = (id: string) => {
  const { data } = useTier({ tierId: id });
  return data?.name;
};

export default function useTierLabel(value: string) {
  if (!value) return null;

  switch (value) {
    case 'all':
      return 'All Tiers';
    case 'free':
      return 'Free Tier';
    case 'any':
      return 'Any Premium Tier';
    default:
      return TierName(value);
  }
}
