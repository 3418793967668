import { useCallback, useState } from 'react';
import { BubbleMenu as BaseBubbleMenu, isNodeSelection, posToDOMRect } from '@tiptap/react';

import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { Divider, Toolbar } from '../../ui/Toolbar';
import { Tooltip } from '../../ui/Tooltip';
import { MenuProps } from '../types';

import { EditLink } from './EditLink';
import { Styled } from './LinkMenu.styled';

const buttonProps = {
  $variant: 'quaternary',
  $showTooltip: true,
  $size: 'small',
  $isIconButton: true,
};

export const LinkMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const [showEdit, setShowEdit] = useState(false);
  const getReferenceClientRect = useCallback(() => {
    const {
      view,
      state,
      state: {
        selection: { from, to },
      },
    } = editor;

    if (isNodeSelection(state.selection)) {
      const node = view.nodeDOM(from) as HTMLElement;

      if (node) {
        return node.getBoundingClientRect();
      }
    }

    return posToDOMRect(view, from, to);
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('link');

    const nonLinkBlocks = ['imageBlock'];

    return isActive && !nonLinkBlocks.some((block) => editor.isActive(block));
  }, [editor]);

  const editorHref = editor.getAttributes('link').href;
  const editorTarget = editor.getAttributes('link').target;

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="textMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        onHidden: () => {
          setShowEdit(false);
        },
      }}
    >
      <Toolbar shouldShowContent={shouldShow()}>
        <Styled.Container>
          {showEdit ? (
            <EditLink
              link={editorHref}
              target={editorTarget}
              onSetLink={(url: string, target: string) => {
                editor.chain().focus().extendMarkRange('link').setLink({ href: url, target }).run();
                setShowEdit(false);
              }}
              onSetTarget={(target: string) => {
                const url = editor.getAttributes('link').href;
                editor.chain().focus().extendMarkRange('link').setLink({ href: url, target }).run();
              }}
              onUnsetLink={() => {
                editor.chain().focus().extendMarkRange('link').unsetLink().run();
                setShowEdit(false);
                return null;
              }}
              onBack={() => {
                setShowEdit(false);
              }}
              editor={editor}
            />
          ) : (
            <>
              <Styled.IconWrapper>
                <Icon name="Website" />
              </Styled.IconWrapper>
              <Styled.Link href={editorHref} title={editorHref} target={editorTarget}>
                {editorHref}
              </Styled.Link>
              <Tooltip title="Edit link">
                <Button
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...buttonProps}
                  $leftSlot={<Icon name="Edit" />}
                  onClick={() => {
                    setShowEdit(true);
                  }}
                />
              </Tooltip>
              <Divider />
              <Tooltip title="Remove link">
                <Button
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...buttonProps}
                  $leftSlot={<Icon name="Trash" />}
                  onClick={() => {
                    editor.chain().focus().extendMarkRange('link').unsetLink().run();
                  }}
                />
              </Tooltip>
            </>
          )}
        </Styled.Container>
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default LinkMenu;
