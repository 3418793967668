import moment from 'moment-mini';

import Badge from '@/components/Badge';
import Text from '@/components/Text';
import UrlCopy from '@/components/UrlCopy';
import { SubscriptionStatuses } from '@/interfaces/subscription';
import pluralize from '@/utils/pluralize';

import { useSubscriberContext } from '../_context/subscriberContext';

const BADGE_COLORS: any = {
  active: 'success',
  inactive: 'alert',
  pending: undefined,
  needs_attention: 'warning',
};

const GeneralDetails = () => {
  const { subscription } = useSubscriberContext();

  return (
    <div className="pt-8 px-4">
      <div className="bg-surface-50 text-gray-700 p-2 font-semibold rounded">General Details</div>
      <div className="flex mt-6 justify-between">
        <div className="space-y-2 shrink">
          <Text size="sm" className="text-gray-500">
            {pluralize('Subscription Tier', subscription.tiers.length, undefined, true)}
          </Text>
          <Text size="sm" type="body" className="capitalize">
            <ul className="space-y-1">
              {subscription.tiers.map((tier) => (
                <li key={tier}>{tier}</li>
              ))}
            </ul>
          </Text>
        </div>
        <div className="border-r border-gray-200 mx-6" />
        <div className="space-y-2 grow">
          <Text size="sm" className="text-gray-500">
            Subscription Status
          </Text>
          <Badge type={BADGE_COLORS[subscription.status]} className="capitalize">
            {SubscriptionStatuses[subscription.status]}
          </Badge>
        </div>
      </div>
      <div className="mt-6 space-y-2">
        <Text size="sm" className="text-gray-500">
          Subscribe Date
        </Text>
        <Text size="sm" type="body">
          {moment(subscription.created_at).format('LL')}
        </Text>
      </div>
      <div className="mt-6 space-y-2">
        <Text size="sm" className="text-gray-500">
          Unique Referral Link
        </Text>
        <UrlCopy url={subscription.referral_url} />
      </div>
      <div className="mt-6 space-y-2">
        <Text size="sm" className="text-gray-500">
          Stripe Link
        </Text>
        {subscription.stripe_customer_id ? (
          <UrlCopy url={`https://dashboard.stripe.com/customers/${subscription.stripe_customer_id}`} />
        ) : (
          <Text size="sm" type="body">
            &ndash;
          </Text>
        )}
      </div>
    </div>
  );
};

export default GeneralDetails;
