/* eslint-disable */

import { FC, useState, useCallback } from 'react';
import toast from 'react-hot-toast';

// Components
import { TypeaheadSelect } from '@/components/Form';

// Interfaces
import { Publication } from '@/interfaces/options';
import { Option } from '@/interfaces/general';

// Services
import api from '@/services/swarm';

interface Props {
  labelText?: string;
  helperText?: string;
  className?: string;
  labelClassName?: string;
  placeholderText?: string;
  onSelectPublication: (publicationId: string) => void;
  onClearPublication: () => void;
  value?: string;
  filterIds?: string[];
}

const PublisherSelect: FC<Props> = (props: Props) => {
  const {
    labelText,
    helperText,
    className,
    labelClassName,
    onSelectPublication,
    onClearPublication,
    placeholderText,
    value,
    filterIds,
  } = props;
  const [publicationId, setPublicationId] = useState('');

  const fetchPublications = useCallback((inputValue?: string) => {
    const params = {
      page: 1,
      per_page: 25,
      conditions: `{"type":"group","logical_operator":"and","conditions":[{"type":"attribute","name":"name","filters":{"operator":"contain","value":"${inputValue}"}}]}`,
    };

    return api
      .get(`/ad_network/internal/publications`, { params })
      .then((res) => {
        const publications = res.data?.publications || [];

        const options: Option[] = publications.map((publication: Publication) => {
          const val: Option = {
            label: publication.name,
            value: publication.id,
          };
          return val;
        });

        return options.filter((option) => !filterIds?.includes(option.value));
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, []);

  const handleSelect = (name: string, value: string) => {
    setPublicationId(value);
    onSelectPublication(value);
  };

  const handleClear = (name: string) => {
    setPublicationId('');
    onClearPublication();
  };

  return (
    <TypeaheadSelect
      name="publication"
      labelText={labelText || ''}
      helperText={helperText}
      placeholderText={placeholderText}
      value={value || publicationId}
      onSelect={handleSelect}
      onClear={handleClear}
      loadOptions={fetchPublications}
      labelClassName={labelClassName}
      className={className}
    />
  );
};

PublisherSelect.defaultProps = {
  className: undefined,
  labelClassName: undefined,
  labelText: 'Publication',
  helperText: undefined,
  placeholderText: 'Select a Publication',
  filterIds: [],
};

export default PublisherSelect;
