import { ErrorInfo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import styled from 'styled-components';

import { Button } from '@/ui/Button';

import { Subtitle, Title } from '../../components/Text';
import { useAuth } from '../../context/auth-context';

interface Props {
  statusCode: number;
  errorState?: ErrorState;
}

export interface ErrorState {
  error: Error | AxiosError | null;
  errorInfo: ErrorInfo | null;
}

const errorCodeConfigs: {
  [key: number | string]: { title: string; subtitle: string; buttonText: string; image: string };
} = {
  404: {
    title: '404',
    subtitle: 'We can’t find the page you’re looking for.',
    buttonText: 'Go to home',
    image: '404.svg',
  },
  500: {
    title: '500',
    subtitle: 'We aren’t exactly sure what happened, but our servers are telling us something is wrong.',
    buttonText: 'Go to home',
    image: '500.svg',
  },
  fallback: {
    title: 'Oops, that’s our bad',
    subtitle: 'We aren’t exactly sure what happened, but our servers are telling us something is wrong.',
    buttonText: 'Go to home',
    image: '500.svg',
  },
};

const Image = styled.img.attrs({
  className: 'm-auto',
})`
  width: 248px;
  height: 240px;
`;

const defaultErrorState: ErrorState = {
  error: null,
  errorInfo: null,
};

const goTo = (path: string) => {
  window.location.href = window.location.origin + path;
};

const ButtonWithShadow = styled(Button)`
  background-color: #209bf1;
  width: 315px;
  height: 58px;
  box-shadow: 6px 6px 0px rgba(32, 155, 241, 0.25);
  font-size: 22px;
  line-height: 41px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 400px) {
    width: unset;
    height: unset;
    font-size: 18px;
    line-height: 30px;
  }
`;

const ErrorPage: React.FC<Props> = ({ statusCode = 404, errorState = { ...defaultErrorState } }) => {
  const errorConfig = errorCodeConfigs[statusCode] || errorCodeConfigs.fallback;
  const { error, errorInfo } = errorState;
  const { isMasquerading, endMasquerade } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isMasquerading && location.pathname === '/system_admin/users/masquerade') {
      endMasquerade.mutateAsync().then(() => {
        navigate(0);
      });
    }
    Sentry.captureException(error || new Error(`${statusCode}: ${window.location.href}`), {
      tags: {
        status_code: `${statusCode}`,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col">
      <div className="h-full flex flex-col min-h-screen">
        <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 p-5">
          <div className="text-center sm:mx-auto sm:w-full sm:max-w-3xl">
            <Image src={`/images/${errorConfig.image}`} alt="not_found" />
            <Title>{errorConfig.title}</Title>
            <Subtitle>{errorConfig.subtitle}</Subtitle>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex flex-col justify-center">
            <ButtonWithShadow
              className="m-auto justify-evenly"
              Icon={ArrowRightIcon}
              iconRight
              onClick={() => goTo('/')}
            >
              {errorConfig.buttonText}
            </ButtonWithShadow>
          </div>
          {process.env.NODE_ENV === 'development' && (
            <div className="mt-10">
              <details style={{ whiteSpace: 'pre-wrap' }}>
                {error && error.toString()}
                <br />
                {errorInfo?.componentStack}
              </details>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
