import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useUpdatePublication } from '@/hooks/usePublications';

import { ImageSelect, Input, Switch } from '../../../../components/Form';
import ColorField from '../../../../components/Form/ColorField';
import { useUpdateHomePage } from '../../../../hooks/useHomePages';
import { FONT_SIZE_OPTIONS } from '../../../../interfaces/font';
import { HomePage, SignupWidget, TextBlock } from '../../../../interfaces/home_page';
import api from '../../../../services/swarm';
import ThemeEditorDropdown from '../../../ThemeEditor/components/ThemeEditorDropdown';
import { Divider, FormInput, FormSection } from '../../../ThemeEditor/helpers';
import { usePageContext } from '../../components/PageContext';
import {
  BORDER_SIDE_OPTIONS,
  BORDER_WIDTH_OPTIONS,
  OPACITY_OPTIONS,
  PADDING_OPTIONS,
  RADIUS_OPTIONS,
  SHADOW_OPTIONS,
} from '../../utils/designOptions';

const WIDTH_OPTIONS = [
  {
    label: 'Full',
    value: 'full',
  },
  {
    label: 'Contained',
    value: 'contained',
  },
];

const Header = () => {
  const queryClient = useQueryClient();
  const { pageData, colorPalette, setIsSaving, handleSaved, currentPublication, fontOptions } =
    usePageContext<HomePage>();
  const publicationMutation = useUpdatePublication();

  const isDefaultHeader = pageData?.general_section?.theme?.header_type === 'default';

  const [bgImage, setBgImage] = useState<File | string>(pageData.main_bg_image_url || '');
  const [general, setGeneral] = useState<any>(pageData.main_section.general);
  const [contentContainer, setContentContainer] = useState<any>(pageData.main_section.content_container);
  const [logo, setLogo] = useState<any>(pageData.main_section.logo);
  const [headline, setHeadline] = useState<TextBlock | null>(null);
  const [description, setDescription] = useState<TextBlock | null>(null);
  const [signupWidget, setSignupWidget] = useState<SignupWidget>(pageData.main_section.signup_widget);
  const [socialWidget, setSocialWidget] = useState<any>(pageData.main_section.social_widget);
  const [authorsWidget, setAuthorsWidget] = useState<any>(pageData.main_section.authors_widget);

  const [header, setHeader] = useState<any>(pageData.general_section.header);

  const isBorderColorTransparent = contentContainer?.border_color === 'transparent';
  const isBGColorTransparent = contentContainer?.bg_color === 'transparent';
  const isContentContainerTransparent = isBorderColorTransparent && isBGColorTransparent;

  useEffect(() => {
    if (pageData) {
      setGeneral(pageData.main_section.general);
      setContentContainer(pageData.main_section.content_container);
      setLogo(pageData.main_section.logo);
      setHeadline(pageData.main_section.headline);
      setDescription(pageData.main_section.description);
      setSignupWidget(pageData.main_section.signup_widget);
      setSocialWidget(pageData.main_section.social_widget);
      setAuthorsWidget(pageData.main_section.authors_widget);
      setHeader(pageData.general_section.header);
    }
  }, [pageData]);

  const updateHomePage = useUpdateHomePage({
    pageId: pageData?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['home_page', currentPublication?.id, 'draft']);
      handleSaved();
    },
  });

  const handleSaveMainSection = (payload: any) => {
    setIsSaving(true);
    updateHomePage.mutate({ home_page: { main_section: JSON.stringify(payload) } });
  };

  const handleSaveGeneralSection = (payload: any) => {
    setIsSaving(true);
    updateHomePage.mutate({ home_page: { general_section: JSON.stringify(payload) } });
  };

  const handleUploadImage = (type: string, file: File | null) => {
    setIsSaving(true);
    const formData = new FormData();

    if (typeof file !== 'string') {
      formData.append(`home_page[${type}]`, file || '');
    }

    formData.append('publication_id', currentPublication?.id || '');

    api
      .patch(`/home_pages/${pageData?.id}`, formData)
      .then(() => {
        queryClient.invalidateQueries(['home_page', currentPublication?.id, 'draft']);
        handleSaved();
      })
      .catch((err) => {
        setIsSaving(false);
        toast.error(err?.response?.data?.error || 'Something went wrong');
      });
  };

  return (
    <>
      {!isDefaultHeader && (
        <FormSection title="Header">
          <FormInput title="Width" helperText="Choose between a full-width or contained header.">
            <ThemeEditorDropdown
              currentValue={header.width}
              onUpdate={(val: string) =>
                handleSaveGeneralSection({
                  ...pageData?.general_section,
                  header: {
                    ...header,
                    width: val,
                  },
                })
              }
              options={WIDTH_OPTIONS}
            />
          </FormInput>
        </FormSection>
      )}
      {isDefaultHeader && (
        <>
          <FormSection title="Default Header">
            <FormInput title="Image">
              <ImageSelect
                name="main_bg_image"
                dimensionSuggestion="high-quality PNGs are recommended for best results."
                onFileSelect={(file) => {
                  setBgImage(file);
                  handleUploadImage('main_bg_image', file);
                }}
                onFileClear={() => {
                  setBgImage('');
                  handleUploadImage('main_bg_image', null);
                }}
                file={bgImage}
              />
            </FormInput>
            <FormInput title="Gloss effect opacity" helperText="Add a gloss-effect to your background image.">
              <ThemeEditorDropdown
                currentValue={general?.bg_gloss_opacity}
                onUpdate={(val: string) =>
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    general: { ...general, bg_gloss_opacity: val },
                  })
                }
                options={OPACITY_OPTIONS.map((option) => {
                  return {
                    label: `${option}%`,
                    value: option,
                  };
                })}
              />
            </FormInput>
            <FormInput
              title="Color"
              helperText="Note: If a background image has been applied this will be the color of the gloss-effect / overlay applied to the image."
            >
              <ColorField
                placement="bottom"
                color={general?.bg_color}
                colorPalette={colorPalette}
                onColorChange={(color: any) => setGeneral({ ...general, bg_color: color?.hexString } as TextBlock)}
                onBlur={() => {
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    general,
                  });
                }}
              />
            </FormInput>
          </FormSection>
          <Divider />
          <FormSection title="Content Container">
            <FormInput title="Background color">
              <div className="flex space-x-4">
                <ColorField
                  placement="bottom"
                  color={contentContainer?.bg_color}
                  colorPalette={colorPalette}
                  onColorChange={(color: any) =>
                    setContentContainer({ ...contentContainer, bg_color: color?.hexString })
                  }
                  onBlur={() => {
                    handleSaveMainSection({
                      ...pageData?.main_section,
                      content_container: contentContainer,
                    });
                  }}
                />
                {!isBGColorTransparent && (
                  <button
                    type="button"
                    className="text-primary-500 text-xs underline"
                    onClick={() => {
                      handleSaveMainSection({
                        ...pageData?.main_section,
                        content_container: {
                          ...contentContainer,
                          bg_color: 'transparent',
                        },
                      });
                    }}
                  >
                    transparent
                  </button>
                )}
              </div>
            </FormInput>
            <FormInput title="Border color">
              <div className="flex space-x-4">
                <ColorField
                  placement="bottom"
                  color={contentContainer?.border_color}
                  colorPalette={colorPalette}
                  onColorChange={(color: any) =>
                    setContentContainer({ ...contentContainer, border_color: color?.hexString })
                  }
                  onBlur={() => {
                    handleSaveMainSection({
                      ...pageData?.main_section,
                      content_container: contentContainer,
                    });
                  }}
                />
                {!isBorderColorTransparent && (
                  <button
                    type="button"
                    className="text-primary-500 text-xs underline"
                    onClick={() => {
                      handleSaveMainSection({
                        ...pageData?.main_section,
                        content_container: {
                          ...contentContainer,
                          border_color: 'transparent',
                        },
                      });
                    }}
                  >
                    transparent
                  </button>
                )}
              </div>
            </FormInput>
            {!isContentContainerTransparent && (
              <div>
                <FormInput title="Padding">
                  <ThemeEditorDropdown
                    currentValue={contentContainer?.padding}
                    onUpdate={(val: string) =>
                      handleSaveMainSection({
                        ...pageData?.main_section,
                        content_container: {
                          ...contentContainer,
                          padding: val,
                        },
                      })
                    }
                    options={PADDING_OPTIONS}
                  />
                </FormInput>
                {!isBorderColorTransparent && (
                  <>
                    <FormInput title="Border radius">
                      <ThemeEditorDropdown
                        currentValue={contentContainer?.border_radius}
                        onUpdate={(val: string) =>
                          handleSaveMainSection({
                            ...pageData?.main_section,
                            content_container: {
                              ...contentContainer,
                              border_radius: val,
                            },
                          })
                        }
                        options={RADIUS_OPTIONS}
                      />
                    </FormInput>
                    <FormInput title="Border width">
                      <ThemeEditorDropdown
                        currentValue={contentContainer?.border_width}
                        onUpdate={(val: string) =>
                          handleSaveMainSection({
                            ...pageData?.main_section,
                            content_container: {
                              ...contentContainer,
                              border_width: val,
                            },
                          })
                        }
                        options={BORDER_WIDTH_OPTIONS}
                      />
                    </FormInput>
                    <FormInput title="Border sides">
                      <ThemeEditorDropdown
                        currentValue={contentContainer?.border_sides}
                        onUpdate={(val: string) =>
                          handleSaveMainSection({
                            ...pageData?.main_section,
                            content_container: {
                              ...contentContainer,
                              border_sides: val,
                            },
                          })
                        }
                        options={BORDER_SIDE_OPTIONS}
                      />
                    </FormInput>
                  </>
                )}
              </div>
            )}
          </FormSection>
          <Divider />
          <FormSection title="Logo">
            <FormInput title="Enabled">
              <Switch
                variant="primary"
                name="logo_enabled"
                checked={logo.enabled}
                onChange={(_name: string, updatedValue: boolean) => {
                  const updatedLogo = { ...logo, enabled: updatedValue };
                  setLogo(updatedLogo);
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    logo: updatedLogo,
                  });
                }}
              />
            </FormInput>
            <FormInput title="Size">
              <ThemeEditorDropdown
                currentValue={logo?.size}
                onUpdate={(val: string) =>
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    logo: {
                      ...logo,
                      size: val,
                    },
                  })
                }
                options={SHADOW_OPTIONS.filter((option) => option.value !== 'none' && option.value !== 'xl')}
              />
            </FormInput>
            <FormInput title="Shadow size">
              <ThemeEditorDropdown
                currentValue={logo?.shadow}
                onUpdate={(val: string) =>
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    logo: {
                      ...logo,
                      shadow: val,
                    },
                  })
                }
                options={SHADOW_OPTIONS}
              />
            </FormInput>
          </FormSection>
          <Divider />
          <FormSection title="Headline">
            <FormInput title="Color">
              <ColorField
                placement="bottom"
                color={headline?.color}
                colorPalette={colorPalette}
                onColorChange={(color: any) => setHeadline({ ...headline, color: color?.hexString } as TextBlock)}
                onBlur={() => {
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    headline,
                  });
                }}
              />
            </FormInput>
            <FormInput title="Text">
              <Input
                name="headline"
                value={headline?.text}
                maxLength={100}
                onChange={(e) => setHeadline({ ...headline, text: e.target.value } as TextBlock)}
                onBlur={() => {
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    headline,
                  });
                }}
              />
            </FormInput>
            <FormInput title="Font family">
              <ThemeEditorDropdown
                currentValue={headline?.font}
                onUpdate={(val: string) =>
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    headline: { ...headline, font: val },
                  })
                }
                options={fontOptions}
              />
            </FormInput>
            <FormInput title="Font size">
              <ThemeEditorDropdown
                currentValue={headline?.size}
                onUpdate={(val: string) =>
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    headline: { ...headline, size: val },
                  })
                }
                options={FONT_SIZE_OPTIONS}
              />
            </FormInput>
          </FormSection>
          <Divider />
          <FormSection title="Description">
            <FormInput title="Color">
              <ColorField
                placement="bottom"
                color={description?.color}
                colorPalette={colorPalette}
                onColorChange={(color: any) => setDescription({ ...description, color: color?.hexString } as TextBlock)}
                onBlur={() => {
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    description,
                  });
                }}
              />
            </FormInput>
            <FormInput title="Text">
              <Input
                name="description"
                value={description?.text}
                maxLength={250}
                onChange={(e) => setDescription({ ...description, text: e.target.value } as TextBlock)}
                onBlur={() => {
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    description,
                  });
                }}
              />
            </FormInput>
            <FormInput title="Font family">
              <ThemeEditorDropdown
                currentValue={description?.font}
                onUpdate={(val: string) =>
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    description: { ...description, font: val },
                  })
                }
                options={fontOptions}
              />
            </FormInput>
            <FormInput title="Font size">
              <ThemeEditorDropdown
                currentValue={description?.size}
                onUpdate={(val: string) =>
                  handleSaveMainSection({
                    ...pageData?.main_section,
                    description: { ...description, size: val },
                  })
                }
                options={FONT_SIZE_OPTIONS}
              />
            </FormInput>
          </FormSection>
        </>
      )}
      <Divider />
      <FormSection
        title="Signup Widget"
        protip="These updates will be applied to any signup widgets across the page, excluding any signup widgets in the footer of the site."
      >
        <FormInput title="Background color">
          <ColorField
            placement="bottom"
            color={signupWidget?.bg_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => setSignupWidget({ ...signupWidget, bg_color: color?.hexString })}
            onBlur={() => {
              handleSaveMainSection({
                ...pageData?.main_section,
                signup_widget: signupWidget,
              });
            }}
          />
        </FormInput>
        <FormInput title="Button text color">
          <ColorField
            placement="bottom"
            color={signupWidget?.text_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => setSignupWidget({ ...signupWidget, text_color: color?.hexString })}
            onBlur={() => {
              handleSaveMainSection({
                ...pageData?.main_section,
                signup_widget: signupWidget,
              });
            }}
          />
        </FormInput>
        <FormInput title="Font family">
          <ThemeEditorDropdown
            currentValue={signupWidget?.font}
            onUpdate={(val: string) =>
              handleSaveMainSection({
                ...pageData?.main_section,
                signup_widget: { ...signupWidget, font: val },
              })
            }
            options={fontOptions}
          />
        </FormInput>
        <FormInput title="Button text">
          <Input
            name="cta_text"
            value={signupWidget?.cta_text}
            maxLength={20}
            onChange={(e) => setSignupWidget({ ...signupWidget, cta_text: e.target.value })}
            onBlur={() => {
              handleSaveMainSection({
                ...pageData?.main_section,
                signup_widget: signupWidget,
              });
            }}
          />
        </FormInput>
        <FormInput title="Placeholder text">
          <Input
            name="placeholder_text"
            value={signupWidget?.placeholder_text}
            maxLength={30}
            onChange={(e) => setSignupWidget({ ...signupWidget, placeholder_text: e.target.value })}
            onBlur={() => {
              handleSaveMainSection({
                ...pageData?.main_section,
                signup_widget: signupWidget,
              });
            }}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Authors Widget">
        {/** READ: There was a legacy field that handled this action --- to avoid fragmenting the logic we break from the paradigm of using homepage data for this specific field. In the future this needs to be consolidated. */}
        <FormInput title="Enabled">
          <Switch
            variant="primary"
            name="authors_widget_enabled"
            checked={currentPublication?.render_authors_widget || false}
            onChange={async (_name: string, updatedValue: boolean) => {
              await publicationMutation.mutateAsync({ render_authors_widget: updatedValue });
              handleSaved();
            }}
          />
        </FormInput>
        <FormInput title="Text color" helperText="Applied to label & description text">
          <ColorField
            placement="bottom"
            color={authorsWidget?.text_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => setAuthorsWidget({ ...authorsWidget, text_color: color?.hexString })}
            onBlur={() => {
              handleSaveMainSection({
                ...pageData?.main_section,
                authors_widget: authorsWidget,
              });
            }}
          />
        </FormInput>
        <FormInput
          title="Dropdown background color"
          helperText="Dropdown is only shown when there are more than 2 authors"
        >
          <ColorField
            placement="bottom"
            color={authorsWidget?.dropdown_bg_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => setAuthorsWidget({ ...authorsWidget, dropdown_bg_color: color?.hexString })}
            onBlur={() => {
              handleSaveMainSection({
                ...pageData?.main_section,
                authors_widget: authorsWidget,
              });
            }}
          />
        </FormInput>
        <FormInput title="Dropdown text color" helperText="Dropdown is only shown when there are more than 2 authors">
          <ColorField
            placement="bottom"
            color={authorsWidget?.dropdown_text_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) =>
              setAuthorsWidget({ ...authorsWidget, dropdown_text_color: color?.hexString })
            }
            onBlur={() => {
              handleSaveMainSection({
                ...pageData?.main_section,
                authors_widget: authorsWidget,
              });
            }}
          />
        </FormInput>
        <FormInput title="Label text" helperText="Override the default label text">
          <Input
            name="label"
            value={authorsWidget?.label}
            maxLength={50}
            onChange={(e) => setAuthorsWidget({ ...authorsWidget, label: e.target.value })}
            onBlur={() => {
              handleSaveMainSection({
                ...pageData?.main_section,
                authors_widget: authorsWidget,
              });
            }}
          />
        </FormInput>
        <FormInput title="Description text" helperText="Override the default description text">
          <Input
            name="text"
            value={authorsWidget?.text}
            maxLength={50}
            onChange={(e) => setAuthorsWidget({ ...authorsWidget, text: e.target.value })}
            onBlur={() => {
              handleSaveMainSection({
                ...pageData?.main_section,
                authors_widget: authorsWidget,
              });
            }}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection
        title="Social Widget"
        protip={
          <>
            <p>Updates here will only impact the social widget in the Default header.</p>
            <p>
              Additionally, for this widget to show on your page, you must ensure you have added AT LEAST one social
              icon in the{' '}
              <a href="https://app.beehiiv.com/settings/publication#social-accounts" className="font-bold underline">
                Social Accounts setting.
              </a>
            </p>
          </>
        }
      >
        <FormInput title="Enabled">
          <Switch
            variant="primary"
            name="social_widget_enabled"
            checked={socialWidget.enabled}
            onChange={(_name: string, updatedValue: boolean) => {
              const updatedWidget = { ...socialWidget, enabled: updatedValue };
              setSocialWidget(updatedWidget);
              handleSaveMainSection({
                ...pageData?.main_section,
                social_widget: updatedWidget,
              });
            }}
          />
        </FormInput>
        <FormInput title="Text color" helperText="Applied to label & icons">
          <ColorField
            placement="bottom"
            color={socialWidget?.text_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => setSocialWidget({ ...socialWidget, text_color: color?.hexString })}
            onBlur={() => {
              handleSaveMainSection({
                ...pageData?.main_section,
                social_widget: socialWidget,
              });
            }}
          />
        </FormInput>
        <FormInput title="Label text" helperText="Override the default label text">
          <Input
            name="label"
            value={socialWidget?.label}
            maxLength={50}
            onChange={(e) => setSocialWidget({ ...socialWidget, label: e.target.value })}
            onBlur={() => {
              handleSaveMainSection({
                ...pageData?.main_section,
                social_widget: socialWidget,
              });
            }}
          />
        </FormInput>
      </FormSection>
    </>
  );
};

export default Header;
