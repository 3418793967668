import { UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';

import { useWebTemplate } from '@/hooks';
import { useCustomPage } from '@/hooks/useCustomPages';
import { useNavGroups } from '@/hooks/useNavGroups';
import { WebTemplate } from '@/interfaces/web_template';

import { CustomPageProvider } from '../../../_components/Context/CustomPageContext';
import { PageProvider } from '../../../_components/Context/PageContext';
import PageV2Layout, { CustomPageData } from '../../_components/PageV2Layout';

// TODO: Merge this page to default $customPageId/index.tsx
// TODO: Create a way to migrate from the old editor to new editor
const CustomPageBuilderV2 = () => {
  const { customPageId } = useParams();

  const navGroupsQuery = useNavGroups();
  const { data: navGroups } = navGroupsQuery;

  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate, isLoading: isLoadingWebTemplate }: UseQueryResult<WebTemplate, Error> = webTemplateRequest;

  const pageQuery = useCustomPage(customPageId);
  const { data: customPage, isLoading: isLoadingCustomPage } = pageQuery;

  return (
    <PageProvider<CustomPageData>
      pageData={{
        navGroups,
        webTemplate,
      }}
    >
      <CustomPageProvider
        key={customPageId}
        customPage={customPage}
        isLoading={customPageId ? isLoadingCustomPage || isLoadingWebTemplate : isLoadingWebTemplate}
        isEditing={!!customPageId}
      >
        <PageV2Layout />
      </CustomPageProvider>
    </PageProvider>
  );
};

export default CustomPageBuilderV2;
