import React from 'react';
import { useParams } from 'react-router-dom';

import usePublicationProfile from '@/hooks/useAdNetwork/internal/usePublicationProfile';
import { AdNetworkPublicationProfile } from '@/interfaces/ad_network/internal/publication_profile';
import { DescriptionList } from '@/routes/ad_network/_components/DescriptionList';
import { LoadingList } from '@/routes/ad_network/_components/LoadingList';

interface Props {
  publicationProfile: AdNetworkPublicationProfile | null;
}

const Profile = ({ publicationProfile }: Props) => {
  const acceptedPricingModels = publicationProfile
    ? [
        publicationProfile.accepts_pricing_model_cpc && 'CPC',
        publicationProfile.accepts_pricing_model_cpm && 'CPM',
        publicationProfile.accepts_pricing_model_flat_rate && 'Flat Rate',
        publicationProfile.accepts_pricing_model_affiliate && 'Affiliate',
      ].filter(Boolean)
    : ['Unknown'];

  // primary, secondary, tertiary, poll, dedicated
  const acceptedAdTypes = publicationProfile
    ? [
        publicationProfile.accepts_ad_type_primary && 'Primary',
        publicationProfile.accepts_ad_type_secondary && 'Secondary',
        publicationProfile.accepts_ad_type_tertiary && 'Tertiary',
        publicationProfile.accepts_ad_type_poll && 'Poll',
        publicationProfile.accepts_ad_type_dedicated && 'Dedicated',
      ].filter(Boolean)
    : ['Unknown'];

  const acceptedContentTypes = publicationProfile
    ? ['Standard', publicationProfile.accepts_content_cannabis && 'Cannabis'].filter(Boolean)
    : ['Unknown'];

  // eslint-disable-next-line no-nested-ternary
  const usBased = publicationProfile ? (publicationProfile.us_based ? 'Yes' : 'No') : 'Unknown';

  const salesTeamType = publicationProfile ? publicationProfile.sales_team_type : 'Unknown';
  const tier = publicationProfile ? publicationProfile.tier : 'Unknown';
  const readiness = publicationProfile ? publicationProfile.ad_network_readiness : 'Unknown';

  const badApple = publicationProfile?.bad_apple ? `Yes (${publicationProfile.bad_apple_reason})` : 'No';

  return (
    <DescriptionList>
      <DescriptionList.Item term="Tier">{tier}</DescriptionList.Item>
      <DescriptionList.Item term="Readiness">{readiness}</DescriptionList.Item>
      <DescriptionList.Item term="Accepted Pricing Models">{acceptedPricingModels.join(', ')}</DescriptionList.Item>
      <DescriptionList.Item term="Accepted Ad Types">{acceptedAdTypes.join(', ')}</DescriptionList.Item>
      <DescriptionList.Item term="Accepted Content Types">{acceptedContentTypes.join(', ')}</DescriptionList.Item>
      <DescriptionList.Item term="Sales Team Type">{salesTeamType}</DescriptionList.Item>
      <DescriptionList.Item term="US Based">{usBased}</DescriptionList.Item>
      <DescriptionList.Item term="Minimum Success Payouts">
        <div className="flex flex-col">
          <p>Per month: {publicationProfile?.minimum_success_monthly_payout || 'Unknown'}</p>
          <p>Per send: {publicationProfile?.minimum_success_send_payout || 'Unknown'}</p>
        </div>
      </DescriptionList.Item>
      <DescriptionList.Item term="Bad Apple">{badApple}</DescriptionList.Item>
    </DescriptionList>
  );
};

export default function Loader() {
  const { publication_id: publicationId } = useParams<'publication_id'>() as { publication_id: string };

  const { data: publicationProfile, isLoading, isSuccess } = usePublicationProfile({ publicationId });

  if (isLoading) return <LoadingList />;
  if (!isSuccess) return null;

  return <Profile publicationProfile={publicationProfile} />;
}
