import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Setting } from '@/interfaces/setting';

import { UpsellPage } from '../../../components/UpsellPage';
import { useSettings } from '../../../context/settings-context';
import { usePollResponseCount, useSetting } from '../../../hooks';
import useUpgradeIntent from '../../../hooks/useUpgradeIntent';
import { IntentAction } from '../../../interfaces/upgrades';
import { PLAN } from '../../../utils/plans';

const Teaser = () => {
  const { pushDataLayerEvent } = useUpgradeIntent({ intentAction: IntentAction.USE_POLLS });
  const { settings } = useSettings();
  const tieredPricing = useSetting({ setting: Setting.TIERED_PRICING, defaultValue: false });
  const navigate = useNavigate();

  const { data } = usePollResponseCount();

  useEffect(() => {
    if (settings?.polls) {
      toast.success('Polls are now enabled.');
      navigate('/polls');
    }
  }, [settings, navigate]);

  const count = data?.count || 1000000;
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  });
  const formattedCount = `${formatter.format(count)}+`;

  return (
    <UpsellPage
      plan={tieredPricing ? PLAN.SCALE : PLAN.GROW}
      title="Native One-Click Polls"
      description="As if Instagram Story polls and newsletters had a baby—collect feedback and insights from readers directly in your newsletter."
      youtubeUrl="https://www.youtube.com/embed/4laKV_GLyKA"
      cards={[
        {
          heading: 'Unlimited polls. Created in seconds.',
          description:
            "Whether you run a simple satisfaction survey or want reader's thoughts on a specific story—you can now seamlessly collect valuable insights.",
          imageUrl: 'https://media.beehiiv.net/static_assets/polls/create.png',
        },
        {
          heading: 'Drop polls into your newsletter with a single click.',
          description:
            'Within the same streamlined workflow, you can add polls directly to your content. Unlocking a whole new way to engage with your readers.',
          imageUrl: 'https://media.beehiiv.net/static_assets/polls/drop.png',
        },
        {
          heading: 'Easily collect, view and share results.',
          description:
            'Seamlessly gather both quantitative and qualitative insights. Filter, export, and share results in just a few simple clicks.',
          imageUrl: 'https://media.beehiiv.net/static_assets/polls/view.png',
        },
      ]}
      statNumber={formattedCount}
      statDescription="Responses and counting."
      learnMoreUrl="https://www.beehiiv.com/newsletter-solutions/analyze"
      onUpsell={() => {
        pushDataLayerEvent();
      }}
    />
  );
};

export default Teaser;
