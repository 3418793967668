import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useSettings } from '@/context/settings-context';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { Input } from '../../../../../../components/Form';
import SlideOver from '../../../../../../components/SlideOver';
import Text from '../../../../../../components/Text';
import { useCurrentPublicationState } from '../../../../../../context/current-publication-context';
import { CustomDomain } from '../../../../../../interfaces/custom_domain';
import api from '../../../../../../services/swarm';

import Verify from './Verify';

interface Props {
  isOpen?: boolean;
  onClose?: any;
  customDomainToVerify?: CustomDomain;
}

const CustomDomainNew = ({ isOpen, onClose, customDomainToVerify }: Props) => {
  const [domain, setDomain] = useState('');
  const [currentPublicationId] = useCurrentPublicationState();
  const [, setShouldVerify] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { settings } = useSettings();
  const [createdCustomDomain, setCreatedCustomDomain] = useState<CustomDomain | undefined>();
  const queryClient = useQueryClient();

  const handleSubmit = () => {
    setIsLoading(true);
    api
      .post(`/custom_domains`, {
        publication_id: currentPublicationId,
        custom_domain: {
          domain,
        },
      })
      .then(({ data: customDomain }: { data: CustomDomain }) => {
        analytics.track('Added a custom domain');
        queryClient.invalidateQueries(['custom_domains', currentPublicationId]);
        setCreatedCustomDomain(customDomain);
        setDomain('');
        setIsLoading(false);
        if (customDomain.verified) {
          toast.success('Domain successfully added');
          onClose();
        } else {
          setShouldVerify(true);
        }
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  };

  const renderShouldVerify = () => {
    const customDomain = createdCustomDomain || customDomainToVerify;

    if (customDomain) {
      return <Verify customDomain={customDomain} onClose={onClose} />;
    }

    return null;
  };
  const supportsDomainRedirects = settings?.apex_domains;
  const renderNewDomain = () => {
    return (
      <>
        <div className="mb-8">
          <h2 className="text-lg leading-6 font-medium text-gray-900 ml-0">Custom Domain</h2>
          <Text className="mt-1 text-gray-500" size="sm">
            In order to link a custom domain to your beehiiv newsletter, we must verify that you own the domain. Add the
            domain below and then follow the instructions to start the ownership verification process.
          </Text>
        </div>
        <div className="pb-8 w-10/12 mt-10">
          <Text className="font-medium">Enter your domain</Text>
          <Text size="sm" className="mt-2 text-gray-500">
            We recommend that you use a subdomain if possible. Doing this is generally considered better practice for
            both web and email.
          </Text>
          <Text size="sm" className="mt-2 text-gray-500">
            Example:
          </Text>
          <Text size="sm" className="mt-2 text-gray-500">
            {supportsDomainRedirects ? (
              <>
                For a web domain use <code className="bg-gray-100 px-2 py-1 rounded-sm">www.yourdomain.com</code> and
                then add <code className="bg-gray-100 px-2 py-1 rounded-sm">yourdomain.com</code> and configure it as a
                redirect domain.
              </>
            ) : (
              <>
                For a web domain use <code className="bg-gray-100 px-2 py-1 rounded-sm">www.yourdomain.com</code>{' '}
                instead of <code className="bg-gray-100 px-2 py-1 rounded-sm">yourdomain.com</code>.{' '}
              </>
            )}
          </Text>
          <Text size="sm" className="mt-2 text-gray-500">
            For email sending use <code className="bg-gray-100 px-2 py-1 rounded-sm">mail.yourdomain.com</code> instead
            of <code className="bg-gray-100 px-2 py-1 rounded-sm">yourdomain.com</code>.
          </Text>
          <Text size="sm" className="mt-6 text-gray-500">
            <b>Note:</b> If you are migrating to beehiiv and have been sending emails from a custom domain on your
            previous platform, we strongly recommmend that you use the same domain here (even if it does not use a
            subdomain as mentioned above). This ensures that you will maintain the domain reputation that you have built
            and minimize the chance of deliverability issues. For example, if you were previously sending your
            newsletter from <code className="bg-gray-100 px-2 py-1 rounded-sm">yourdomain.com</code>, we recommend that
            you do that here as well.
          </Text>
          <div className="mt-6">
            <Input
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              name="domain"
              labelText="Domain"
              placeholder="www.my-domain.com"
            />
          </div>
        </div>
        <div className="mt-10 flex justify-end space-x-2">
          <Button variant="primary-inverse" onClick={onClose}>
            Back
          </Button>
          <Button loading={isLoading} onClick={() => handleSubmit()}>
            {isLoading ? 'Adding...' : 'Add Domain'}
          </Button>
        </div>
      </>
    );
  };

  return (
    <SlideOver
      bodyId={currentPublicationId}
      headerText={customDomainToVerify ? 'Verify Domain' : 'New Custom Domain'}
      isOpen={Boolean(isOpen)}
      onClose={onClose}
    >
      {customDomainToVerify ? renderShouldVerify() : renderNewDomain()}
    </SlideOver>
  );
};

export default CustomDomainNew;
