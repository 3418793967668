import { FC, useCallback } from 'react';
import toast from 'react-hot-toast';

import { Option } from '../../interfaces/general';
import { Automation } from '../../interfaces/options';
import api from '../../services/swarm';
import { TypeaheadSelect } from '../Form';

interface Props {
  labelText?: string;
  helperText?: string;
  className?: string;
  labelClassName?: string;
  automationId?: string;
  onSelectAutomation: (automationId: string) => void;
  onClearAutomation: () => void;
  publicationId: string;
  allowAnyAutomation?: boolean;
}

const AutomationSelect: FC<Props> = (props: Props) => {
  const {
    labelText,
    helperText,
    className,
    labelClassName,
    automationId,
    onSelectAutomation,
    onClearAutomation,
    publicationId,
    allowAnyAutomation,
  } = props;

  const fetchAutomations = useCallback(
    (inputValue?: string) => {
      const params = {
        publication_id: publicationId,
        q: inputValue,
      };

      return api
        .get(`/options/automations/`, { params })
        .then((res) => {
          const automations = res.data?.options || [];
          const options: Option[] = [];

          if (allowAnyAutomation && !params.q) {
            options.push({ label: 'Any automation', value: 'any' });

            if (automations.length > 0) {
              options.push({ label: 'Automations', value: 'automations', isOptGroup: true });
            }
          }

          automations.forEach((automation: Automation) => {
            options.push({ label: automation.name, value: automation.id });
          });

          return options;
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [publicationId, allowAnyAutomation]
  );

  const loadInitialValue = useCallback(() => {
    if (!automationId) {
      return undefined;
    }

    if (allowAnyAutomation && automationId === 'any') {
      return new Promise<Option>((resolve) => {
        resolve({ label: 'Any automation', value: 'any' });
      });
    }

    const params = {
      publication_id: publicationId,
      id: automationId,
    };

    return api
      .get(`/options/automations/`, { params })
      .then((res) => {
        const automations = res.data?.options || [];
        const onlyAutomation: Automation = automations.length > 0 ? automations[0] : null;
        const option = onlyAutomation && {
          label: onlyAutomation?.name,
          value: onlyAutomation?.id,
        };

        return option;
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, [publicationId, automationId, allowAnyAutomation]);

  const handleSelect = (name: string, value: string) => {
    onSelectAutomation(value);
  };

  const handleClear = () => {
    onClearAutomation();
  };

  return (
    <TypeaheadSelect
      name="automation"
      labelText={labelText || ''}
      helperText={helperText}
      placeholderText="Select a Automation"
      value={automationId || ''}
      onSelect={handleSelect}
      onClear={handleClear}
      loadOptions={fetchAutomations}
      loadInitialValue={loadInitialValue}
      labelClassName={labelClassName}
      className={className}
    />
  );
};

AutomationSelect.defaultProps = {
  className: undefined,
  labelClassName: undefined,
  labelText: 'Automation',
  helperText: undefined,
};

export default AutomationSelect;
