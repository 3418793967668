import { PropsWithChildren } from 'react';
import { Button, Title } from '@tremor/react';
import cx from 'classnames';

import { Typography } from '@/components/Typography';

type Props = PropsWithChildren<any>;

type CardProps = PropsWithChildren<{
  className?: string;
  color?: 'surface' | 'primary' | 'secondary' | 'warning' | 'danger' | 'info' | 'success';
  hasPadding?: boolean;
}>;

const COLOR_CLASS_MAP = Object.freeze({
  surface: 'border border-surface-200 bg-white',
  primary: 'border border-action-primary-200 bg-action-primary-50',
  secondary: 'border border-action-secondary-200 bg-action-secondary-50',
  warning: 'border border-feedback-warning-200 bg-feedback-warning-50',
  danger: 'border border-feedback-danger-200 bg-feedback-danger-50',
  info: 'border border-feedback-info-200 bg-feedback-info-50',
  success: 'border border-feedback-success-200 bg-feedback-success-50',
});

const Card = ({ children, className, color = 'surface', hasPadding = true }: CardProps) => {
  return <div className={cx('rounded-lg', hasPadding && 'p-4', COLOR_CLASS_MAP[color], className)}>{children}</div>;
};

const LargeTitle = ({ children, className, ...rest }: Props) => {
  return (
    <Title {...rest} className={cx(className, 'font-bold')}>
      {children}
    </Title>
  );
};

const SmallTitle = ({ children }: Props) => {
  return (
    <Typography weight="semibold" size="sm">
      {children}
    </Typography>
  );
};

const SubText = ({ children, className }: Props) => {
  return (
    <Typography weight="normal" size="sm" colorWeight="500" className={className}>
      {children}
    </Typography>
  );
};

const HelperText = ({ children, className }: Props) => {
  return (
    <Typography weight="normal" size="xs" colorWeight="500" className={className}>
      {children}
    </Typography>
  );
};

const CTA = ({ onClick, children, icon, color }: Props) => {
  return (
    <Button size="xs" variant="light" color={color} icon={icon} iconPosition="right" onClick={onClick}>
      {children}
    </Button>
  );
};

export { Card, CTA, HelperText, LargeTitle, SmallTitle, SubText };
