import { useCurrentPublication, useUpdatePublication } from '@/hooks/usePublications';
import { Option } from '@/interfaces/general';
import { SelectForm } from '@/pages/Settings/Components';

import InputWithDescription from '../../../../ui/Settings/InputWithDescription';
import { SettingsContainer, SettingsGroup } from '../../../../ui/Settings/SettingsContainer';

const options: Option[] = [
  { label: 'None', value: 'none' },
  { label: 'Gated', value: 'gated' },
  { label: 'Popup', value: 'popup' },
];

const WebBuilderEmailCapture = () => {
  const { data: currentPublication } = useCurrentPublication();
  const publicationMutation = useUpdatePublication();

  const currentSelection = options.find((option) => option.value === currentPublication?.email_capture_type);
  const isGated = currentSelection?.value === 'gated';
  const isPopup = currentSelection?.value === 'popup';
  const isNone = currentSelection?.value === 'none';

  return (
    <SettingsContainer>
      <SettingsGroup
        title="Email Capture"
        description="Choose alternative ways to capture email addresses of new subscribers on your posts. This feature is turned off by default."
      >
        <div className="flex flex-col space-y-6 w-full">
          {currentPublication && (
            <>
              <SelectForm
                label="Email Capture Type"
                description="Select none, gated, or popup."
                value={currentSelection || options[0]}
                toastSuccessMessage="Saved!"
                options={options}
                onSave={(val: Option) => {
                  return publicationMutation.mutateAsync({
                    post_email_capture_type: val.value,
                  });
                }}
              />
              {!isNone && (
                <>
                  {isGated && (
                    <>
                      <InputWithDescription
                        title="Gated Content Title"
                        description="This will be displayed on all of your subscribe forms."
                        placeholder="Subscribe to keep reading"
                        value={currentPublication?.gated_title || ''}
                        onSave={(val: string) => publicationMutation.mutateAsync({ post_email_gated_title: val })}
                        toastSuccessMessage="Saved!"
                      />
                      <InputWithDescription
                        title="Gated Content Message"
                        description="This content is free, but you must be subscribed to [X] Newsletter to continue reading."
                        placeholder="This will be displayed on all of your subscribe forms."
                        value={currentPublication?.gated_message || ''}
                        onSave={(val) => publicationMutation.mutateAsync({ post_email_gated_message: val })}
                        toastSuccessMessage="Saved!"
                        type="textarea"
                      />
                      <InputWithDescription
                        title="Gated Content CTA"
                        description="This will be displayed as the copy for you call to action button."
                        placeholder="Subscribe"
                        value={currentPublication?.gated_cta || ''}
                        onSave={(val) => publicationMutation.mutateAsync({ post_email_gated_cta: val })}
                        toastSuccessMessage="Saved!"
                      />
                    </>
                  )}
                  {isPopup && (
                    <>
                      <InputWithDescription
                        title="Popup Content Title"
                        description="This will be displayed on all of your subscribe forms."
                        placeholder="Enjoying this post?"
                        value={currentPublication?.popup_title || ''}
                        onSave={(val) => publicationMutation.mutateAsync({ post_email_popup_title: val })}
                        toastSuccessMessage="Saved!"
                      />
                      <InputWithDescription
                        title="Popup Content Message"
                        description="Subscribe to get more content like this delivered to your inbox for free!"
                        placeholder="This will be displayed on all of your subscribe forms."
                        value={currentPublication.popup_message || ''}
                        onSave={(val) => publicationMutation.mutateAsync({ post_email_popup_message: val })}
                        toastSuccessMessage="Saved!"
                        type="textarea"
                      />
                      <InputWithDescription
                        title="Popup Content CTA"
                        description="This will be displayed as the copy for you call to action button."
                        placeholder="Subscribe"
                        value={currentPublication.popup_cta || ''}
                        onSave={(val) => publicationMutation.mutateAsync({ post_email_popup_cta: val })}
                        toastSuccessMessage="Saved!"
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </SettingsGroup>
    </SettingsContainer>
  );
};

export default WebBuilderEmailCapture;
