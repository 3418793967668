import { ElementType, PropsWithChildren } from 'react';
import { ArrowRightIcon, RocketLaunchIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { Grid } from '@tremor/react';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import { BillingDetails } from '@/interfaces/billing';
import { usePlanPrice } from '@/routes/settings/billing/_hooks';
import { capitalize } from '@/utils';

type Plan = {
  name: string;
  max_subscriptions: number;
  max_publications: number;
};

type Props = {
  billingDetails: BillingDetails;
  currentPlan: Plan | undefined;
  newPlan: Plan | undefined;
  renderTopRowArrow?: boolean;
};

type GapComponentProps = {
  leftChild: React.ReactNode;
  rightChild: React.ReactNode;
  arrowRendered?: boolean;
};

const GapComponent = ({ leftChild, rightChild, arrowRendered = true }: GapComponentProps) => {
  return (
    <Grid className="grid-cols-12 mw-full h-full pr-4">
      <div className="col-span-5 w-full">{leftChild}</div>
      <div className="col-span-2 flex items-center justify-center">
        {arrowRendered && <ArrowRightIcon className="w-4 h-4" />}
      </div>
      <div className="col-span-5 flex justify-end mr-4">
        <div className="md:whitespace-nowrap w-3/4 text-left">{rightChild}</div>
      </div>
    </Grid>
  );
};

interface PriceDetailsProps {
  planName: string;
  price: string;
  variant?: 'secondary' | 'tertiary';
  Icon: ElementType;
}

const CLASSNAMES = Object.freeze({
  secondary: {
    iconColor: 'text-action-secondary-500',
    bgColor: 'bg-action-secondary-100',
  },
  tertiary: {
    iconColor: 'text-action-tertiary-500',
    bgColor: 'bg-action-tertiary-100',
  },
});

const BodyTypography = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Typography token="font-normal/text/sm" colorWeight="700">
      {children}
    </Typography>
  );
};

const HeaderTypography = ({ children }: PropsWithChildren<{}>) => {
  return <Typography token="font-semibold/text/base">{children}</Typography>;
};

const PriceDetails = ({ planName, price, variant = 'secondary', Icon }: PriceDetailsProps) => {
  return (
    <div className="flex gap-4 items-center">
      <div className={cx('p-4 rounded-md', CLASSNAMES[variant].bgColor)}>
        <Icon className={cx('md:w-6 md:h-6 h-4 w-4', CLASSNAMES[variant].iconColor)} />
      </div>

      <div className="flex flex-col">
        <Typography token="font-semibold/text/base">{planName}</Typography>
        <BodyTypography>{price}</BodyTypography>
      </div>
    </div>
  );
};

const Header = (props: Props) => {
  const { billingDetails, currentPlan, newPlan, renderTopRowArrow } = props;
  const organizationId = billingDetails.organization_id;
  const { data: planPrice } = usePlanPrice({ organizationId });
  const formatter = Intl.NumberFormat('en');

  return (
    <div className="flex flex-col gap-2">
      <div className="border border-surface-200 rounded">
        <div className="px-6 pt-6 pb-4 bg-surface-50 border-b border-surface-200">
          <GapComponent
            leftChild={<HeaderTypography>Your current plan</HeaderTypography>}
            rightChild={<HeaderTypography>Your new plan</HeaderTypography>}
            arrowRendered={false}
          />
        </div>
        <div className="flex flex-col overflow-x-auto space-y-4 p-4">
          <GapComponent
            leftChild={
              <PriceDetails
                Icon={SparklesIcon}
                variant="tertiary"
                planName={capitalize(currentPlan?.name || '')}
                price={`$${(planPrice?.unit_amount || 0) / 100}/${planPrice?.interval}`}
              />
            }
            rightChild={<PriceDetails Icon={RocketLaunchIcon} planName="Launch" price="Free" />}
            arrowRendered={renderTopRowArrow}
          />
          {!renderTopRowArrow && (
            <div>
              <GapComponent
                leftChild={
                  <BodyTypography>
                    Up to {formatter.format(currentPlan?.max_subscriptions || 0)} subscribers
                  </BodyTypography>
                }
                rightChild={
                  <BodyTypography>Up to {formatter.format(newPlan?.max_subscriptions || 0)} subscribers</BodyTypography>
                }
              />
              <GapComponent
                leftChild={<BodyTypography>Unlimited team members</BodyTypography>}
                rightChild={<BodyTypography>1 team member</BodyTypography>}
              />
              {currentPlan?.max_publications && currentPlan.max_publications > 3 && (
                <GapComponent
                  leftChild={<BodyTypography>10 publications</BodyTypography>}
                  rightChild={<BodyTypography>3 publications</BodyTypography>}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
