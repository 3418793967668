import React, { FC } from 'react';

import { Select } from '../../../components/Form';
// Hooks
import useOptions from '../../../hooks/useOptions';
// Utils
import { optionDataToOptions } from '../../../utils';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const ExternalEmbedInput: FC<Props> = ({ value, onChange }) => {
  const externalEmbedsData = useOptions('external_embeds', Infinity);
  const externalEmbedOptions = optionDataToOptions(externalEmbedsData);

  return (
    <Select
      className="w-full"
      name="value"
      placeholderText="Select Embed"
      value={value || ''}
      onSelect={(name: string, selectedValue: string) => onChange(selectedValue)}
      options={externalEmbedOptions}
      emptyLabel="No embeds found"
    />
  );
};

export default ExternalEmbedInput;
