import { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { SLIDE_OVER_Z_INDEX } from '../zIndexes';

interface Props {
  isOpen: boolean;
  onClose: Function;
  headerText: string;
  headerDescription?: string;
  children: React.ReactNode;
  actionsChildren?: React.ReactNode;
  className?: string;
  bodyId?: string;
  width?: string;
  position?: 'left' | 'right';
}

const SlideOver: FC<Props> = (props: Props) => {
  const {
    isOpen,
    onClose,
    headerText,
    children,
    actionsChildren,
    className,
    bodyId,
    headerDescription,
    width,
    position = 'right',
  } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={cx('fixed inset-0 overflow-hidden', className, SLIDE_OVER_Z_INDEX)}
        onClose={(value) => !value && handleClose()}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div
            className={cx(
              'fixed inset-y-0 max-w-full flex',
              position === 'right' ? 'right-0 md:pl-10' : 'left-0 md:pr-10'
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom={position === 'right' ? 'translate-x-full' : '-translate-x-full'}
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo={position === 'right' ? 'translate-x-full' : '-translate-x-full'}
            >
              <div className={cx('w-screen', width || 'max-w-xl')} id={bodyId}>
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div>
                          <Dialog.Title className="text-lg font-medium text-gray-900">{headerText}</Dialog.Title>
                          {headerDescription && <p className="mt-1 text-sm text-gray-500">{headerDescription}</p>}
                        </div>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
                            onClick={handleClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">{children}</div>
                  </div>
                  {actionsChildren && <div className="flex-shrink-0 px-4 py-4 flex justify-end">{actionsChildren}</div>}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

SlideOver.defaultProps = {
  className: undefined,
  actionsChildren: undefined,
};

export default SlideOver;
