import Card from "@/components/Card"
import { Typography } from "@/components/Typography";
import { PostTargetAction } from "@/interfaces/post_target"
import AudienceBadges from "@/pages/Post/Overview/AudienceBadges"

interface Props {
  publishType: any;
  emailPostSendTargets: any[];
  webPostSendTargets: any[];
  showEmailSection: boolean;
  showWebSection: boolean;
}

const SummaryCard = ({publishType, emailPostSendTargets, webPostSendTargets, showEmailSection, showWebSection}: Props) => {
  return (
    <Card className="flex flex-col gap-6">
      <Typography token="font-medium/text/lg">
        Summary
      </Typography>
      <Typography colorWeight="500" token="font-normal/text/sm">
        Publish to <Typography token="font-medium/text/sm">{publishType.name}</Typography>
      </Typography>
      {showEmailSection && <div className="flex flex-col gap-2">
        <Typography colorWeight="500" token="font-normal/text/sm">
          Email audience <Typography token="font-medium/text/sm">{emailPostSendTargets.reduce((acc, target) => { return acc + target.num_active_subscribers }, 0) || 0} recipients</Typography>
        </Typography>
        <div className="flex items-start content-start gap-2 self-stretch flex-wrap">
          <AudienceBadges includedAudiences={emailPostSendTargets.filter((target) => target.action === PostTargetAction.INCLUDE) || []} excludedAudiences={emailPostSendTargets.filter((target) => target.action === PostTargetAction.EXCLUDE) || []} />
        </div>
      </div>}
      {showWebSection && <div className="flex flex-col gap-2">
        <Typography colorWeight="500" token="font-normal/text/sm">
          Web audience
        </Typography>
        <div className="flex items-start content-start gap-2 self-stretch flex-wrap">
          <AudienceBadges includedAudiences={webPostSendTargets.filter((target) => target.action === PostTargetAction.INCLUDE) || []} excludedAudiences={webPostSendTargets.filter((target) => target.action === PostTargetAction.EXCLUDE) || []} />
        </div>
      </div>}
    </Card>
  )
}

export default SummaryCard;
