import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/outline';

import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';

import { Checkbox, RadioSelect } from '../../../../../../components/Form';
import { useMailchimpFolder } from '../../../../../../hooks/useIntegrations';
import useIntegrations from '../../../../../../hooks/useIntegrations/useIntegrations';
import { IntegrationProvider } from '../../../../../../interfaces/integration';
import Select from '../../../../Components/SelectForm/Select';

import MailchimpImportOptions from './MailchimpImportOptions';

interface Props {
  integrationId: string | undefined;
  setIntegrationId: (id: string) => void;
  setIntegrationProvider: (provider: string) => void;
  importTypeSelection: string;
  setImportTypeSelection: (value: string) => void;
  selectedFolderIds: Array<string>;
  setSelectedFolderIds: (folderIds: string[]) => void;
}

const Mailchimp: React.FunctionComponent<Props> = ({
  integrationId,
  setIntegrationId,
  setIntegrationProvider,
  selectedFolderIds,
  setSelectedFolderIds,
  setImportTypeSelection,
  importTypeSelection,
}) => {
  const { data } = useIntegrations({ provider: IntegrationProvider.MAILCHIMP });
  const isCustomizeByFolderSelected = String(importTypeSelection) === MailchimpImportOptions[1].value;

  const integrations = data?.pages.flatMap((page) => page.integrations) || [];
  const { data: mailchimpFolder } = useMailchimpFolder(integrationId);
  const folders = mailchimpFolder?.folders || [];
  const isConnected = integrations.length > 0;

  const hasFolders = folders.length > 0;

  const handleFolderId = (newFolderId: string) => {
    if (!selectedFolderIds.includes(newFolderId)) {
      setSelectedFolderIds([...selectedFolderIds, newFolderId]);
    } else {
      setSelectedFolderIds(selectedFolderIds.filter((folderId) => folderId !== newFolderId));
    }
  };

  const options: Option[] = integrations.map((integration) => ({
    label: `Account #${integration.uid}`,
    value: integration.id,
  }));

  const selectIntegration = (option: Option) => {
    setIntegrationId(option.value);
    setIntegrationProvider(IntegrationProvider.MAILCHIMP);
  };

  useEffect(() => {
    if (integrations.length > 0) {
      setIntegrationId(integrations[0].id);
      setIntegrationProvider(IntegrationProvider.MAILCHIMP);
    }
  });

  return (
    <div>
      <p className="font-medium">Step 1</p>
      <p className="mt-2 mb-4 text-sm text-gray-500">
        To import content from Mailchimp, you&apos;ll need to connect your Mailchimp account to beehiiv. This will allow
        us to pull your content and information about your publication during the import process. Once connected, you
        can come back to this page to import your content.
      </p>
      {isConnected ? (
        <>
          <div>
            <Button type="button" variant="primary-inverse" Icon={CheckIcon} disabled>
              Connected
            </Button>
          </div>
          <Link className="text-xs hover:text-primary-500 text-gray-500 mt-2" to="/settings/integrations">
            Connect another account?
          </Link>
          <p className="mt-12 font-medium">Step 2</p>
          <p className="mt-2 mb-4 text-sm text-gray-500">
            Select the Mailchimp account you&apos;d like to import content from.
          </p>
          <Select options={options} initialValue={options[0]} onChange={(val) => selectIntegration(val)} />
          {hasFolders && (
            <div className="mt-4 space-y-4">
              <RadioSelect
                size="md"
                value={String(importTypeSelection)}
                options={MailchimpImportOptions}
                onSelect={(value: string) => setImportTypeSelection(value)}
              />
              {isCustomizeByFolderSelected && (
                <div className="space-y-2">
                  <p className="mt-2 mb-2 text-sm text-gray-500">
                    Check the folder options you would like to import campaigns from:
                  </p>
                  {folders?.map((folder) => {
                    return (
                      <div key={folder.id} className="space-y-2">
                        <Checkbox
                          labelText={folder.name}
                          name={folder.name}
                          checked={selectedFolderIds.includes(folder.id)}
                          onChange={() => {
                            handleFolderId(folder.id);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <Link to="/settings/integrations#mailchimp">
          <Button variant="primary-inverse" type="button">
            Connect to Mailchimp
          </Button>
        </Link>
      )}
    </div>
  );
};

export default Mailchimp;
