import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  onSuccess?: (response: any) => void;
  onError?: (err: any) => void;
}

const useCreateSvixApplication = ({ onSuccess, onError }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const createSvixApplication = (): any =>
    api
      .post('/webhooks/svix_applications', {
        publication_id: currentPublicationId,
      })
      .then((res) => res.data);

  return useMutation(createSvixApplication, {
    onSuccess: (response) => {
      onSuccess?.(response);
    },
    onError: (err: any) => {
      if (onError) {
        onError(err);
      } else {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      }
    },
  });
};

export default useCreateSvixApplication;
