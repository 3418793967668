import React, { useCallback } from 'react';
import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import { sticky } from 'tippy.js';

import { MenuProps } from '../../../components/menus/types';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const BlockquoteFigureMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'blockquoteFigure');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('blockquoteFigure');

    return isActive;
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="blockquoteMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar shouldShowContent={shouldShow()}>
        <Tooltip title="Variant 1">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $active={editor.isActive('blockquoteFigure', { variant: '1' })}
            $leftSlot={<Icon name="BorderQuote" />}
            onClick={() => {
              editor.chain().focus().setBlockquoteVariant('1').run();
            }}
          />
        </Tooltip>
        <Tooltip title="Variant 2">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $active={editor.isActive('blockquoteFigure', { variant: '2' })}
            $leftSlot={<Icon name="Blockquote" />}
            onClick={() => {
              editor.chain().focus().setBlockquoteVariant('2').run();
            }}
          />
        </Tooltip>
        <Tooltip title="Variant 3">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $active={editor.isActive('blockquoteFigure', { variant: '3' })}
            $leftSlot={<Icon name="Dropquote" />}
            onClick={() => {
              editor.chain().focus().setBlockquoteVariant('3').run();
            }}
          />
        </Tooltip>
        <Divider />
        <Tooltip title="Delete quote">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $leftSlot={<Icon name="Trash" />}
            onClick={() => {
              editor.chain().focus().deleteNode('blockquoteFigure').run();
            }}
          />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default BlockquoteFigureMenu;
