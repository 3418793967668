/* eslint-disable react/jsx-props-no-spreading */

import { FC, Fragment, ReactNode, useMemo, useRef, useState } from 'react';
import { Modifier, usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import { v4 as generateUuid } from 'uuid';

import { Option } from '../../interfaces/general';

import HelperText from './HelperText';
import { SelectSubElementClassNames } from './types';

interface Props {
  className?: string;
  name: string;
  labelText?: string;
  labelTextClassName?: string;
  required?: boolean;
  value?: string;
  onSelect: (name: string, value: string | any) => void;
  onClose?: () => void;
  options: Option[];
  topRightLinkText?: string;
  onTopRightLinkClick?: (name: string) => void;
  disabled?: boolean;
  helperText?: string | React.ReactNode;
  placeholderText?: string | ReactNode;
  portalMountedId?: string;
  errorText?: string;
  button?: ReactNode;
  buttonClassNames?: SelectSubElementClassNames;
  optionsContainerClassNames?: SelectSubElementClassNames;
  suffixElement?: ReactNode;
  emptyLabel?: string;
  shouldBindOptionAsValue?: boolean;
}

const Select: FC<Props> = ({
  className,
  name,
  labelText,
  labelTextClassName,
  required,
  onSelect,
  onClose,
  value,
  options,
  topRightLinkText,
  onTopRightLinkClick,
  disabled,
  helperText,
  placeholderText,
  portalMountedId,
  errorText,
  button,
  buttonClassNames = {},
  optionsContainerClassNames = {},
  suffixElement = <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />,
  emptyLabel,
  shouldBindOptionAsValue = false,
}: Props) => {
  const popperElRef = useRef(null);
  const targetElement = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const modifiers = useMemo(
    (): Modifier<string, Record<string, unknown>>[] => [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'matchReferenceSize',
        enabled: true,
        fn: ({ state, instance }) => {
          const widthOrHeight =
            state.placement.startsWith('left') || state.placement.startsWith('right') ? 'height' : 'width';

          if (!popperElement) return;

          const popperSize =
            popperElement[`offset${widthOrHeight[0].toUpperCase() + widthOrHeight.slice(1)}` as 'offsetWidth'];
          const referenceSize = state.rects.reference[widthOrHeight];

          if (Math.ceil(popperSize) >= Math.floor(referenceSize)) return;

          // @ts-ignore: Style is accessible
          popperElement.style[widthOrHeight] = `${referenceSize}px`;
          instance.update();
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
    ],
    [popperElement]
  );

  const { styles, attributes } = usePopper(targetElement?.current, popperElement, {
    placement: 'bottom',
    modifiers,
  });

  const handleSelect = (selectedValue: string | any) => {
    onSelect(name, selectedValue);
  };

  const selectedOption = options.find((option) => !option.isOptGroup && option.value === value);

  return (
    <div className={className}>
      <Listbox
        value={value}
        onChange={handleSelect}
        disabled={disabled}
        by={shouldBindOptionAsValue ? 'value' : undefined}
      >
        {({ open }) => (
          <>
            {(labelText || topRightLinkText) && (
              <div className="flex justify-between">
                {labelText && (
                  <Listbox.Label className={labelTextClassName || 'block text-sm font-medium text-gray-700'}>
                    {labelText}
                    {required ? ' *' : ''}
                  </Listbox.Label>
                )}
                {topRightLinkText && onTopRightLinkClick && (
                  <button
                    type="button"
                    className="text-primary-600 hover:text-primary-900 text-sm ml-2"
                    onClick={() => onTopRightLinkClick(name)}
                  >
                    {topRightLinkText}
                  </button>
                )}
              </div>
            )}
            <div className={cx('relative', labelText && !labelTextClassName ? 'mt-1' : undefined)}>
              <div ref={targetElement}>
                {button && <Listbox.Button>{button}</Listbox.Button>}
                {!button && (
                  <Listbox.Button
                    className={cx(
                      'relative w-full rounded-md focus:outline-none focus:ring-1',
                      buttonClassNames.focus || 'focus:ring-primary-500 focus:border-primary-500',
                      buttonClassNames.text || 'text-left sm:text-sm',
                      buttonClassNames.cursor || 'cursor-default',
                      buttonClassNames.shadow || 'shadow-sm',
                      buttonClassNames.padding || 'py-2 pl-3 pr-10',
                      buttonClassNames.background || 'bg-white',
                      buttonClassNames.border || 'border border-gray-300',
                      buttonClassNames.hover,
                      disabled ? 'bg-gray-50 cursor-not-allowed' : ''
                    )}
                  >
                    {selectedOption && (
                      <span className="w-full truncate">
                        <span className="truncate">{selectedOption.label}</span>
                      </span>
                    )}
                    {!selectedOption && (
                      <span className="w-full truncate">
                        <span className="truncate">{placeholderText || 'Select an Option'}</span>
                      </span>
                    )}
                    {suffixElement && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        {suffixElement}
                      </span>
                    )}
                  </Listbox.Button>
                )}
              </div>

              <Portal node={portalMountedId && document ? document.getElementById(portalMountedId) : null}>
                <div ref={popperElRef} className="z-10" style={styles.popper} {...attributes.popper}>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    beforeEnter={() => setPopperElement(popperElRef.current)}
                    afterLeave={() => {
                      setPopperElement(null);
                      onClose?.();
                    }}
                  >
                    <Listbox.Options
                      className={cx(
                        'absolute z-10 bg-white shadow-lg max-h-60 rounded-md py-2 px-2 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
                        optionsContainerClassNames.position || 'origin-top-right right-0',
                        optionsContainerClassNames.width || 'w-full'
                      )}
                    >
                      {options.length === 0 && emptyLabel ? (
                        <div className="py-2 pl-3 pr-9 font-bold text-gray-400 text-xs">{emptyLabel}</div>
                      ) : null}
                      {options.map((option, index) => {
                        if (option.isOptGroup) {
                          return (
                            <div
                              key={`select-group-label-${option.label}-${generateUuid()}`}
                              className={cx(
                                'py-2 pl-3 pr-9 font-bold text-gray-400 text-xs',
                                index > 0 && 'mt-1 border-t'
                              )}
                            >
                              {option.label}
                            </div>
                          );
                        }

                        return (
                          <Listbox.Option
                            key={option.value}
                            className={({ active }) =>
                              cx(
                                active ? 'text-gray-900 bg-gray-100' : 'text-gray-800',
                                'cursor-pointer select-none relative py-2 pl-3 pr-9 rounded-lg'
                              )
                            }
                            value={shouldBindOptionAsValue ? option : option.value}
                          >
                            {({ selected, active }) => {
                              const Icon = option.icon;
                              return (
                                <>
                                  <div className="flex flex-col">
                                    <div
                                      className={cx(
                                        'flex gap-x-2 items-center',
                                        selected ? 'font-semibold' : 'font-normal',
                                        'truncate'
                                      )}
                                    >
                                      {Icon && <Icon className="w-4 h-4" />}
                                      {option.label}
                                    </div>
                                    {option.description ? (
                                      <span className={cx('font-normal text-xs text-gray-400')}>
                                        {option.description}
                                      </span>
                                    ) : null}
                                  </div>

                                  {selected ? (
                                    <span
                                      className={cx(
                                        active ? 'text-gray-900' : 'text-gray-800',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              );
                            }}
                          </Listbox.Option>
                        );
                      })}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Portal>
              {helperText && <HelperText className="mt-2">{helperText}</HelperText>}
              {errorText && <p className="mt-2 text-xs text-red-500">{errorText}</p>}
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

Select.defaultProps = {
  className: undefined,
  required: false,
  topRightLinkText: undefined,
  onTopRightLinkClick: undefined,
  disabled: false,
  helperText: undefined,
  labelText: undefined,
  placeholderText: undefined,
};

export default Select;
