import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface Props {
  onSuccess: (response: any) => void;
}

interface ApiResponse {
  url: string;
}

const useCreateConnectAccountLoginLink = ({ onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const createStripeConnectAccount = (): Promise<ApiResponse> =>
    api
      .post('/stripe_account/connect_accounts/login_link', {
        publication_id: currentPublicationId,
      })
      .then((res) => res.data);

  return useMutation(createStripeConnectAccount, {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
    },
  });
};

export default useCreateConnectAccountLoginLink;
