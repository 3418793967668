import { FC } from 'react';
import { Link } from 'react-router-dom';

// Components
import TablePagination from '../../../components/TablePagination';
import { Pagination } from '../../../interfaces/general';
// Interfaces
import { SubscriptionMilestone } from '../../../interfaces/milestone';

import StatusBadge from './StatusBadge';

interface Props {
  subscriptionMilestones: SubscriptionMilestone[] | undefined;
  pagination: Pagination | undefined;
  onPageSelected: (pageNum: number) => void;
  onManageSelected?: (subscriptionMilestone: SubscriptionMilestone) => void;
}

const FulfillmentTable: FC<Props> = (props: Props) => {
  const { subscriptionMilestones, pagination, onPageSelected, onManageSelected } = props;

  if (!subscriptionMilestones || !pagination) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Subscriber
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Reward
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Required Referrals
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  {onManageSelected && (
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Manage</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {subscriptionMilestones.map((subscriptionMilestone: SubscriptionMilestone) => (
                  <tr key={subscriptionMilestone.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="text-sm font-medium text-gray-900">
                          <Link
                            type="button"
                            className="text-primary-600 hover:text-primary-900"
                            to={`/subscribers/${subscriptionMilestone.subscriber_id}`}
                          >
                            {subscriptionMilestone.subscriber_email}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{subscriptionMilestone.reward_name}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{subscriptionMilestone.num_referrals}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        <StatusBadge status={subscriptionMilestone.status} />
                      </div>
                    </td>
                    {onManageSelected && (
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          type="button"
                          className="text-primary-600 hover:text-primary-900"
                          onClick={() => onManageSelected(subscriptionMilestone)}
                        >
                          Manage
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <TablePagination className="mt-2" pagination={pagination} onPageSelected={onPageSelected} />
    </div>
  );
};

FulfillmentTable.defaultProps = {
  onManageSelected: () => {},
};

export default FulfillmentTable;
