import { useCallback, useLayoutEffect } from 'react';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import { Doc } from 'yjs';

import { useEditorContext } from '@/pages/Post/Edit/EditorContext';

import { API } from '../api';

export const useProvider = ({
  postId,
  publicationId,
  initialDocument,
}: {
  postId: string;
  publicationId: string;
  initialDocument?: Doc;
}) => {
  const { collaborationEnabled, provider, setProvider } = useEditorContext();
  // Intitialize a provider for the managed collaboration server
  const tiptapCollabInit = useCallback(async () => {
    const response = await API.getTiptapCollabToken({ resourceId: postId, resourceType: 'post', publicationId });
    const { token, document_name: documentName, app_id: appId } = response.data;

    const newProvider = new TiptapCollabProvider({
      appId,
      token,
      document: initialDocument,
      name: documentName,
      onSynced: (data) => {
        if (data.state === true) {
          setProvider(newProvider);
        }

        const autoVersioningEnabled = newProvider?.document
          .getMap<number>(`__tiptapcollab__config`)
          .get('autoVersioning');

        const intervalSeconds = newProvider?.document.getMap<number>(`__tiptapcollab__config`).get('intervalSeconds');

        if (!autoVersioningEnabled) {
          newProvider?.document.getMap<number>(`__tiptapcollab__config`).set('autoVersioning', 1);
        }

        if (intervalSeconds !== 600) {
          newProvider?.document.getMap<number>(`__tiptapcollab__config`).set('intervalSeconds', 600);
        }
      },
    });
  }, [initialDocument, postId, publicationId, setProvider]);

  useLayoutEffect(() => {
    if (!provider && collaborationEnabled) {
      tiptapCollabInit();
    }

    return () => {
      provider?.destroy();
    };
  }, [provider, tiptapCollabInit, collaborationEnabled]);

  return { provider };
};

export default useProvider;
