import { PlanType } from '@/interfaces/publication';

import { useTrialStatus } from '../usePublicationDashboard';
import { useCurrentPublication } from '../usePublications';

const usePlan = (): { plan: PlanType; trial: any; isLoading: boolean } => {
  const { data: currentPublication } = useCurrentPublication();
  const trialStatusQuery = useTrialStatus();
  const { data: trialStatus, isLoading } = trialStatusQuery || {};
  const isFinished = trialStatus?.is_finished;
  let plan: PlanType = 'custom';

  if (!isFinished && !isLoading) {
    plan = 'trial';
  } else {
    plan = currentPublication?.plan_name || 'custom';
  }

  return {
    plan,
    trial: trialStatus,
    isLoading,
  };
};

export default usePlan;
