import { useState } from 'react';

import { WalkthroughPortal } from '@/components/Walkthrough';
import { useWalkthroughContext } from '@/context/walkthrough-context';

import { LeftPane, Main, RightPane } from '../components/Layout';
import { SectionTabPanel } from '../components/SectionTabs';

import ActiveSubscribersChart from './ActiveSubscribersChart';
import BeehiivImpactChart from './BeehiivImpactChart';
import KPIs from './KPIs';
import Onboarding from './Onboarding';
import PendingAdNetworkOpportunities from './PendingAdNetworkOpportunities';
import RecentPosts from './RecentPosts';
import Resources from './Resources';
import Streak from './Streak';

const Overview = () => {
  const { currentStep, handleNextStep, handleFinishWalkthrough } = useWalkthroughContext();
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState<boolean>(false);

  return (
    <SectionTabPanel>
      <Main>
        <LeftPane>
          <KPIs />
          <ActiveSubscribersChart />
          <BeehiivImpactChart />
        </LeftPane>

        <RightPane>
          <RecentPosts />
          <PendingAdNetworkOpportunities />
          <WalkthroughPortal
            isActive={currentStep === 1}
            title="Onboarding checklist 📝"
            description="Keep track of your progress towards publishing your newsletter with our checklist."
            arrowDirection="right"
            positionClassNameOverride="-left-[340px]"
            hasOverlay
            currentStep={1}
            totalSteps={7}
            continueButton={{
              text: 'Next',
              onClick: () => handleNextStep(),
            }}
            backButton={{
              text: 'Back',
              onClick: () => {},
            }}
            onClose={handleFinishWalkthrough}
          >
            <Onboarding
              isOnboardingCompleted={isOnboardingCompleted}
              setIsOnboardingCompleted={setIsOnboardingCompleted}
            />
          </WalkthroughPortal>
          <Streak />
          <Resources isOnboardingCompleted={isOnboardingCompleted} />
        </RightPane>
      </Main>
    </SectionTabPanel>
  );
};

export default Overview;
