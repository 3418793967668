import { TierChangesGraph, TierChangeStatsByResource } from '@/components/_domain/PremiumData';
import { TierChangeEventResourceType } from '@/interfaces/tier_change_event';

const PremiumData = ({ emailMessageId }: { emailMessageId: string }) => {
  return (
    <div className="p-6">
      <TierChangeStatsByResource resourceType={TierChangeEventResourceType.EMAIL_MESSAGE} resourceId={emailMessageId} />
      <TierChangesGraph resourceType={TierChangeEventResourceType.EMAIL_MESSAGE} resourceId={emailMessageId} />
    </div>
  );
};

export default PremiumData;
