import { HTMLProps, PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  textColor?: string;
}> &
  Omit<HTMLProps<HTMLButtonElement>, 'type'>;

const IconButton = ({
  children,
  backgroundColor = 'bg-white',
  hoverBackgroundColor = 'hover:bg-gray-100',
  textColor = 'text-gray-700',
  className = '',
  ...rest
}: Props) => {
  return (
    <button
      type="button"
      className={cx(
        'flex items-center justify-center w-4 h-4 p-1 box-content rounded-lg',
        hoverBackgroundColor,
        backgroundColor,
        textColor,
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default IconButton;
