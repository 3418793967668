import { useState } from 'react';

import { Button } from '@/ui/Button';

import { SimpleSelect } from '../../../../../../components/Form';
import Text from '../../../../../../components/Text';
import { useCommentBans } from '../../../../../../hooks/useComments';
import { BanScopes, BanTypes, ScopeTypes } from '../../../../../../interfaces/comment_ban';
import { Section } from '../../../../Components';

import AddCommentBan from './AddCommentBan';
import CommentBanItem from './CommentBanItem';

const CommentBansSection = () => {
  const [renderType, setRenderType] = useState<string>(BanScopes.ALL);
  const isAll = renderType === BanScopes.ALL;

  const [renderStatus, setRenderStatus] = useState<ScopeTypes>(BanScopes.ACTIVE);
  const isDefaultFilters = renderType === BanScopes.ALL && renderStatus === BanScopes.ALL;

  const paginatedCommentBansQuery = useCommentBans({
    level: isAll ? undefined : renderType,
    scope: renderStatus,
  });
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = paginatedCommentBansQuery;
  const commentBans = data?.pages.flatMap((page: any) => page.comment_bans) || [];
  const totalCommentBansCount = data?.pages[0]?.pagination?.total;
  const showingCommentBansCount = commentBans?.length || 0;
  const isNoResults = !isLoading && commentBans.length === 0;

  const hideFilters = isDefaultFilters && isNoResults;

  return (
    <Section
      title="Comment Bans"
      description="Manage your website's comment bans below."
      scrollToId="comment-bans"
      isLoading={!data}
      hasDivider={false}
    >
      <div className="space-y-6">
        <div className="flex space-x-2 w-full">
          <div className="w-40">
            <SimpleSelect
              name="comment_ban_type"
              labelText="Filter by level"
              value={renderType}
              onSelect={(_name: string, value: string) => setRenderType(value)}
              options={[
                {
                  label: 'All',
                  value: BanScopes.ALL,
                },
                {
                  label: 'Post',
                  value: BanTypes.POST,
                },
                {
                  label: 'Publication',
                  value: BanTypes.PUBLICATION,
                },
              ]}
            />
          </div>
          <div className="w-40">
            <SimpleSelect
              name="comment_ban_status"
              labelText="Filter by status"
              value={renderStatus}
              onSelect={(_name: string, value: any) => setRenderStatus(value)}
              options={[
                {
                  label: 'All',
                  value: BanScopes.ALL,
                },
                {
                  label: 'Active',
                  value: BanScopes.ACTIVE,
                },
                {
                  label: 'Inactive',
                  value: BanScopes.INACTIVE,
                },
              ]}
            />
          </div>
        </div>
        {commentBans && commentBans?.length ? (
          <div>
            <div>
              {commentBans.map((commentBan: any) => {
                return <CommentBanItem key={commentBan.id} commentBan={commentBan} />;
              })}
            </div>

            <div className="flex justify-between pt-4 items-center">
              <span className="text-xs font-semibold text-gray-400">{`Showing ${showingCommentBansCount} of ${totalCommentBansCount} results`}</span>

              {hasNextPage && (
                <div className="text-center my-6">
                  <div>
                    <Button
                      variant="primary-inverse"
                      onClick={() => fetchNextPage()}
                      disabled={!hasNextPage || isFetchingNextPage}
                    >
                      {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            {hideFilters ? null : (
              <Text type="body" size="md" className="text-gray-500">
                No results
              </Text>
            )}
          </>
        )}

        <AddCommentBan />
      </div>
    </Section>
  );
};

export default CommentBansSection;
