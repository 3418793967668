import { FC } from 'react';

import Card from '../../../../components/Card';
import KPI from '../../../../components/KPI';
import KPIGroup from '../../../../components/KPIGroup';
import { Skeleton, SkeletonLoader } from '../../../../components/SkeletonLoader';
import { useSegmentKpis } from '../../../../hooks/useSegmentDetail';

interface Props {
  segmentId: string;
}

const KPIs: FC<Props> = (props: Props) => {
  const { segmentId } = props;

  const kpisQuery = useSegmentKpis({ segmentId });
  const { data: kpiData, isLoading } = kpisQuery || {};

  // Send Kpis
  const openRate = `${kpiData?.open_rate?.toFixed(1) || 0.0}%`;
  const clickThroughRate = `${kpiData?.click_through_rate?.toFixed(1) || 0.0}%`;

  // Subscriber Kpis
  const totalUnsubscribed = kpiData?.total_unsubscribed?.toLocaleString('en-US');
  const pctUnsubscribed = `${kpiData?.pct_unsubscribed || 0.0}%`;

  const totalPremium = kpiData?.total_premium_subscribers?.toLocaleString('en-US');
  const pctPremium = `${kpiData?.pct_premium || 0.0}%`;

  // Referral kpis
  const totalReferrals = kpiData?.total_referrals?.toLocaleString('en-US');
  const avgReferralsPerReferrer = kpiData?.average_referrals_per_referrer?.toFixed(1) || 0.0;
  const pctReferring = `${kpiData?.pct_referring || 0.0}%`;
  return (
    <Card className="px-0 py-0">
      <SkeletonLoader
        className="px-0 py-0"
        isLoading={isLoading}
        skeletons={
          <div>
            <div className="flex space-x-8 items-center mb-4 p-4">
              <div className="flex flex-col space-y-4 w-full">
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
              <div className="flex flex-col space-y-4 w-full">
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
              <div className="flex flex-col space-y-4 w-full">
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
              <div className="flex flex-col space-y-4 w-full">
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            </div>
            <div className="flex space-x-8 items-center mb-4 p-4">
              <div className="flex flex-col space-y-4 w-full">
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
              <div className="flex flex-col space-y-4 w-full">
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
              <div className="flex flex-col space-y-4 w-full">
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
              <div className="flex flex-col space-y-4 w-full">
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            </div>
          </div>
        }
      >
        <KPIGroup columns={4} className="w-full">
          <KPI title="Open Rate" value={openRate} isLoading={isLoading} />
          <KPI title="Click Through Rate" value={clickThroughRate} isLoading={isLoading} />
          <KPI title="Unsubscribes" value={totalUnsubscribed || 0} isLoading={isLoading} />
          <KPI title="Unsubscribe Rate" value={pctUnsubscribed || 0} isLoading={isLoading} />
        </KPIGroup>
        <KPIGroup columns={5} className="w-full border-t border-gray-100">
          <KPI title="Premium Rate" value={pctPremium} isLoading={isLoading} />
          <KPI title="Premium" value={totalPremium || 0} isLoading={isLoading} />
          <KPI title="Referral Rate" value={pctReferring || 0} isLoading={isLoading} />
          <KPI title="Referrals" value={totalReferrals} isLoading={isLoading} />
          <KPI title="Referrals per Referrer" value={avgReferralsPerReferrer} isLoading={isLoading} />
        </KPIGroup>
      </SkeletonLoader>
    </Card>
  );
};

export default KPIs;
