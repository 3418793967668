import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { UpsellPage } from '../../../../components/UpsellPage';
import { useSettings } from '../../../../context/settings-context';
import useUpgradeIntent from '../../../../hooks/useUpgradeIntent';
import { IntentAction } from '../../../../interfaces/upgrades';
import { PLAN } from '../../../../utils/plans';

const Teaser = () => {
  const { pushDataLayerEvent } = useUpgradeIntent({ intentAction: IntentAction.USE_BOOSTS_MONETIZE });
  const { settings } = useSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (settings?.boosts) {
      toast.success('Boosts are now enabled.');
      navigate('/monetize/boosts');
    }
  }, [settings, navigate]);

  return (
    <UpsellPage
      plan={PLAN.SCALE}
      title="Introducing Boosts"
      description="100% passive income—the easiest way to monetize your newsletter without lifting a finger. No confusing setup, no APIs needed, and absolutely no coding required."
      cards={[
        {
          heading: 'Browse dozens of qualified offers.',
          description: 'Our publisher network is home to thousands of the top newsletters in the world.',
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto,width=500/static_assets/boosts/offers.png',
        },
        {
          heading: 'Request to Boost whichever newsletters you’d like.',
          description:
            'As soon as they approve your request (average response time is less than a day) you can activate the Boost.',
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto,width=500/static_assets/boosts/request.png',
        },
        {
          heading: 'Generate leads on autopilot.',
          description:
            'After a reader subscribes to your newsletter, they’ll also be prompted to subscribe to your boosted newsletters. You don’t need to lift a finger.',
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto,width=500/static_assets/boosts/subscribe.png',
        },
        {
          heading: 'Watch the money pile up.',
          description:
            'Once subscribers are verified to be active and engaged, we’ll deposit funds directly into your beehiiv wallet. 100% passive income. The typical verification window is roughly 14 days.',
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto,width=500/static_assets/boosts/earn.png',
        },
      ]}
      learnMoreUrl="https://www.beehiiv.com/newsletter-solutions/monetize/boosts"
      onUpsell={() => {
        pushDataLayerEvent();
      }}
    />
  );
};

export default Teaser;
