import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useUpdatePublication = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (publication: any) =>
      api.patch(`/publications/${currentPublicationId}`, {
        publication_id: currentPublicationId,
        publication,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        if (onSuccess) onSuccess();
        queryClient.invalidateQueries(['publications', currentPublicationId]);
      },
    }
  );
};

export default useUpdatePublication;
