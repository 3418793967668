/* eslint-disable */
import { ChangeEvent, ChangeEventHandler, FC, Ref, RefObject, useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import cx from 'classnames';
import styled, { css } from 'styled-components';
import { CheckIcon } from '@heroicons/react/24/outline';

interface Props {
  className?: string;
  name: string;
  labelText?: string;
  checked: boolean;
  onChange: (checked: boolean, event?: ChangeEvent<HTMLInputElement>) => void;
  size?: 'md' | 'lg';
  disabled?: boolean;
  helperText?: string;
  indeterminate?: boolean;
  color?: 'primary' | 'secondary' | 'tertiary';
  overrideColor?: string;
}

const COLOR_MAP = Object.freeze({
  primary: 'text-action-primary-900',
  secondary: 'text-action-secondary-600',
  tertiary: 'text-action-tertiary-600',
});

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  ${css`
    background-image: url('data:image/svg+xml,${ReactDOMServer.renderToString(
      <CheckIcon style={{ color: 'white' }} />
    )}');
  `}
`;

const Checkbox: FC<Props> = (props: Props) => {
  const {
    className,
    name,
    labelText,
    checked,
    onChange,
    size,
    disabled,
    helperText,
    indeterminate = false,
    color = 'secondary',
    overrideColor,
  } = props;
  const isLarge = size === 'lg';

  const [isChecked, setIsChecked] = useState(checked);

  // TODO: Clean up the onChange logic to be completely passed in from parent
  useEffect(() => {
    if (checked != isChecked) {
      setIsChecked(checked);
    }
  }, [checked]);

  const handleChange = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <div className={cx(className)}>
      <div className="flex space-x-3">
        <Input
          ref={(input: HTMLInputElement) => {
            if (input) {
              input.indeterminate = indeterminate;
            }
          }}
          className={cx(
            'mt-1 focus:ring-transparent h-4.5 w-4.5 border-gray-300 rounded-sm disabled:cursor-not-allowed',
            !overrideColor && COLOR_MAP[color],
            overrideColor
          )}
          id={name}
          name={name}
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
        />

        {labelText && (
          <label
            htmlFor={name}
            className={cx(
              disabled ? 'text-gray-500 cursor-not-allowed' : 'text-gray-700',
              isLarge ? 'text-lg font-regular' : 'text-sm font-normal'
            )}
          >
            {labelText}
          </label>
        )}
      </div>
      {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
    </div>
  );
};

Checkbox.defaultProps = {
  className: undefined,
  size: 'md',
};

export default Checkbox;
