import { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import moment from 'moment-mini';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import SlideOver from '@/components/SlideOver';
import useReferrals from '@/hooks/useSubscriptionMilestones/useReferrals';
import { SubscriptionMilestone } from '@/interfaces/milestone';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import useFulfillment from './hooks/useFulfillment';
import AssignPromoCode from './AssignPromoCode';
import ReferralTable from './ReferralTable';
import StatusBadge from './StatusBadge';

interface Props {
  subscriptionMilestoneId?: string;
  publicationId: string;
  className?: string;
  isOpen: boolean;
  onClose: Function;
  onSuccess: Function;
}

const ActionSlideOver: FC<Props> = ({
  subscriptionMilestoneId,
  publicationId,
  isOpen,
  className,
  onClose,
  onSuccess,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [showAssignPromoCode, setShowAssignPromoCode] = useState(false);
  const [subscriptionMilestone, setSubscriptionMilestone] = useState<SubscriptionMilestone>();
  const [milestoneReached, setMilestoneReached] = useState(true);
  const slideOverBodyId = 'manage-fulfillment';

  const {
    data: referralsData,
    refetch: refetchReferrals,
    isLoading: isLoadingReferrals,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useReferrals(subscriptionMilestone?.subscription_id);
  const referrals = referralsData?.pages.flatMap((page) => page.referrals) || [];

  useEffect(() => {
    if (!isLoadingReferrals) {
      const calcMilestoneReached = () =>
        (referralsData?.pages[0]?.pagination?.total || 0) >= (subscriptionMilestone?.num_referrals || 0);
      setMilestoneReached(calcMilestoneReached());
    }
  }, [isLoadingReferrals, subscriptionMilestone, referralsData]);

  const fetchSubscriptionMilestone = useCallback(() => {
    if (subscriptionMilestoneId) {
      const params = {
        publication_id: publicationId,
      };

      setIsLoading(true);
      api
        .get(`/referral_program/subscription_milestones/${subscriptionMilestoneId}`, { params })
        .then((res) => {
          setSubscriptionMilestone(res.data);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [publicationId, subscriptionMilestoneId]);

  useEffect(() => {
    fetchSubscriptionMilestone();
  }, [fetchSubscriptionMilestone]);

  const {
    onMarkAsPending,
    isMarkingAsPending,
    onMarkAsIgnored,
    isMarkingAsIgnored,
    onMarkAsFulfilled,
    isMarkingAsFulfilled,
    canPerformActions,
    canMarkAsPending,
    canMarkAsIgnored,
    canMarkAsFulfilled,
  } = useFulfillment(subscriptionMilestone, onSuccess);

  const onResetAndCloseSlideOver = () => {
    setShowAssignPromoCode(false);
    setSubscriptionMilestone(undefined);
    onClose();
  };

  const onDelete = () => {
    // fetchReferrals();
    refetchReferrals();
  };

  const milestoneWarning = () => {
    if (milestoneReached) return null;

    return (
      <div className="sticky top-0 mb-6">
        <div className="bg-red-50 border-l-4 border-red-400 p-4 flex items-center justify-between">
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-md font-medium text-primary-900 mb-2">
              This referrer no longer qualifies for this milestone. You can ignore this milestone, or fulfill it anyway.
            </h2>
          </div>
        </div>
      </div>
    );
  };

  const actions = () => {
    if (!canPerformActions) return null;

    return (
      <>
        {canMarkAsPending && (
          <Button variant="primary-inverse" onClick={onMarkAsPending} loading={isMarkingAsPending}>
            Mark as pending
          </Button>
        )}
        {canMarkAsIgnored && (
          <Button variant="primary-inverse" className="ml-2" onClick={onMarkAsIgnored} loading={isMarkingAsIgnored}>
            Mark as ignored
          </Button>
        )}
        {canMarkAsFulfilled && (
          <Button variant="primary" className="ml-2" onClick={onMarkAsFulfilled} loading={isMarkingAsFulfilled}>
            Mark as fulfilled
          </Button>
        )}
      </>
    );
  };

  const statusBadge = () => {
    if (!subscriptionMilestone) {
      return null;
    }

    if (subscriptionMilestone.status === 'pending') {
      return <StatusBadge status={subscriptionMilestone?.status} />;
    }

    if (subscriptionMilestone.status === 'awaiting_promo_code') {
      return (
        <span className="flex">
          <StatusBadge status={subscriptionMilestone?.status} />
          <p className="ml-2">({moment(subscriptionMilestone.awaiting_promo_code_at).fromNow()})</p>
        </span>
      );
    }

    if (subscriptionMilestone.status === 'ignored') {
      return (
        <span className="flex">
          <StatusBadge status={subscriptionMilestone?.status} />
          <p className="ml-2">({moment(subscriptionMilestone.ignored_at).fromNow()})</p>
        </span>
      );
    }

    return (
      <span className="flex">
        <StatusBadge status={subscriptionMilestone?.status} />
        <p className="ml-2">({moment(subscriptionMilestone.fulfilled_at).fromNow()})</p>
      </span>
    );
  };

  const onSuccessAssignPromoCode = () => {
    setShowAssignPromoCode(false);
    fetchSubscriptionMilestone();
  };

  const renderPromoCodeAssign = () => {
    if (showAssignPromoCode && subscriptionMilestoneId && subscriptionMilestone) {
      return (
        <AssignPromoCode
          subscriptionMilestoneId={subscriptionMilestoneId}
          rewardId={subscriptionMilestone?.reward_id}
          publicationId={publicationId}
          onSuccess={onSuccessAssignPromoCode}
          slideOverBodyId={slideOverBodyId}
        />
      );
    }

    return (
      <button type="button" onClick={() => setShowAssignPromoCode(true)} className="text-blue-500 underline">
        Assign
      </button>
    );
  };

  const renderBody = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <>
        {milestoneWarning()}
        <h2 className="text-md font-medium text-gray-900 mb-2">Subscriber</h2>
        <p className="mb-4">{subscriptionMilestone?.subscriber_email}</p>
        <h2 className="text-md font-medium text-gray-900 mb-2">Status</h2>
        <div className="mb-4">{statusBadge()}</div>
        <h2 className="text-md font-medium text-gray-900 mb-2">Reward</h2>
        <p className="mb-4">
          {subscriptionMilestone?.reward_name} ({subscriptionMilestone?.num_referrals} referrals)
        </p>
        {subscriptionMilestone?.is_fulfilled_via_promo_code && (
          <>
            <h2 className="text-md font-medium text-gray-900 mb-2">Promo Code</h2>
            <div className="mb-4">
              {subscriptionMilestone?.reward_promo_code_id
                ? subscriptionMilestone?.promo_code
                : renderPromoCodeAssign()}
            </div>
          </>
        )}
        <h2 className="text-md font-medium text-gray-900 mb-2 flex items-center">
          Referrals{' '}
          {isLoadingReferrals ? <LoadingSpinner className="ml-2" /> : `(${referralsData?.pages[0]?.pagination?.total})`}
        </h2>
        <ReferralTable
          referrals={referrals}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          subscriptionMilestoneId={subscriptionMilestoneId}
          onDelete={onDelete}
        />
      </>
    );
  };

  return (
    <SlideOver
      bodyId={slideOverBodyId}
      headerText="Manage achievement"
      isOpen={isOpen}
      onClose={onResetAndCloseSlideOver}
      className={className}
      actionsChildren={actions()}
    >
      {renderBody()}
    </SlideOver>
  );
};

ActionSlideOver.defaultProps = {
  className: undefined,
  subscriptionMilestoneId: undefined,
};

export default ActionSlideOver;
