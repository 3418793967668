import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { EmailMessagePreview, EmailMessageResourceType, EmailMessageType } from '../interfaces/email_message';
import { Pagination } from '../interfaces/general';
import api from '../services/swarm';

interface ApiResponse {
  email_messages: EmailMessagePreview[];
  pagination: Pagination;
}

interface UseEmailMessagesProps {
  resourceId?: string;
  resourceType?: EmailMessageResourceType;
  type?: EmailMessageType;
  search?: string;
}

export default function useEmailMessages({ resourceId, resourceType, type, search }: UseEmailMessagesProps) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchEmailMessages = ({ pageParam = 1 }) =>
    api
      .get(`/email_messages`, {
        params: {
          page: pageParam,
          publication_id: currentPublicationId,
          resource_id: resourceId,
          resource_type: resourceType,
          q: search,
          type,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['email_messages', currentPublicationId, resourceId, resourceType, search],
    fetchEmailMessages,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
