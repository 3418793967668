import { useMemo } from 'react';

import { Typography } from '@/components/Typography';
import { useCustomFields } from '@/hooks';
import {
  AutomationStepUpdateSubscription,
  AutomationStepUpdateSubscriptionCustomField,
} from '@/interfaces/automations/automation_step';

import { findCustomField } from './findCustomField';
import { formatCustomFieldValue } from './formatCustomFieldValue';

interface Props {
  updateSubscriptionData?: AutomationStepUpdateSubscription;
}

const NewUpdateSubscriptionAutomationStepNode = ({ updateSubscriptionData }: Props) => {
  const customFieldsQuery = useCustomFields({ search: '', all: true });
  const { data: customFieldsData, isLoading } = customFieldsQuery;
  const customFields = useMemo(
    () => customFieldsData?.pages.flatMap((page) => page.customFields) || [],
    [customFieldsData]
  );

  const changedItems =
    !isLoading &&
    updateSubscriptionData?.custom_fields
      ?.map((customFieldData: AutomationStepUpdateSubscriptionCustomField, index: number) => {
        const customField = findCustomField(customFields, customFieldData.id);
        if (!customField) return null;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`update-subscription-custom-field-item-${index}`}>
            <Typography size="xs">
              Set <strong>{customField.display}</strong> to{' '}
              <strong>{formatCustomFieldValue(customField, customFieldData.value)}</strong>
            </Typography>
          </li>
        );
      })
      .filter((field) => field !== null);

  if (updateSubscriptionData?.status && changedItems) {
    changedItems.splice(
      0,
      0,
      <li key="update-subscription-custom-field-status-item">
        <Typography size="xs">
          Set <strong>status</strong> to <strong>{updateSubscriptionData?.status}</strong>
        </Typography>
      </li>
    );
  }

  return (
    <>
      <Typography size="xs">Update status and custom field values</Typography>
      {changedItems && <ul className="list-disc ml-6">{changedItems}</ul>}
    </>
  );
};

export default NewUpdateSubscriptionAutomationStepNode;
