import { useQuery } from 'react-query';

import { UserWithRoles } from '@/interfaces/user';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useUser = (userId: string | undefined) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<UserWithRoles, Error>(
    ['user', userId],
    () =>
      api
        .get(`/users/${userId}`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!userId,
    }
  );
};

export default useUser;
