import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { AdNetworkOpportunity, AdNetworkOpportunityStatus } from '@/interfaces/ad_network/publisher/opportunity';
import api from '@/services/swarm';

interface SuccessResponse extends AdNetworkOpportunity {}
interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}
interface AcceptVariables {
  selected_date: string;
  advertisement_id: string;
  status: AdNetworkOpportunityStatus.ACCEPTED;
}
interface RejectVariables {
  status: AdNetworkOpportunityStatus.REJECTED;
  rejection_reason: string;
  rejection_reason_comment: string;
}

type Variables = AcceptVariables | RejectVariables;

export default function useOpportunityUpdate({ id }: { id: string }) {
  const [publicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation<SuccessResponse, ErrorResponse, Variables>(
    (opportunity) =>
      api
        .patch(`/ad_network/publisher/opportunities/${id}`, {
          publication_id: publicationId,
          opportunity,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<AdNetworkOpportunity | undefined>(
          [publicationId, 'ad_network', 'publisher', 'opportunities', id],
          (prevData) => {
            if (!prevData) return prevData;

            return {
              ...prevData,
              ...data,
            };
          }
        );
      },

      onError: (error) => {
        const { errors } = error.response.data;

        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).forEach((key) => {
            toast.error(`${key} ${errors[key][0]}`);
          });
        } else {
          toast.error('Something went wrong');
        }
      },
    }
  );
}
