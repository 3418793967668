import { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tiptap/core';
import Bold from '@tiptap/extension-bold';
import Document from '@tiptap/extension-document';
import Italic from '@tiptap/extension-italic';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/react';

import { cn } from '@/utils/cn';

import { Button } from '../TiptapEditor/components/ui/Button';
import { Icon } from '../TiptapEditor/components/ui/Icon';
import { Tooltip } from '../TiptapEditor/components/ui/Tooltip';
import { Shortcuts } from '../TiptapEditor/extensions/Shortcuts';

import { CommentEditorProps } from './CommentEditor.types';
import { suggestions } from './suggestions';

import './CommentEditor.css';

const CommentDocument = Document.extend({
  content: 'paragraph+',
});

export const CommentEditor = ({
  className,
  onSubmit,
  content,
  placeholder = 'Add a comment ...',
  autoFocus,
}: CommentEditorProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const getContainerRef = useCallback(() => containerRef.current || document.body, []);

  const editorRef = useRef<Editor | null>(null);

  const [hasContent, setHasContent] = useState(false);
  const hasContentRef = useRef(hasContent);

  const handleSubmit = useCallback(() => {
    if (!editorRef.current || !hasContentRef.current || !onSubmit) return;

    onSubmit({
      html: editorRef.current.getHTML(),
      json: editorRef.current.getJSON(),
    });

    editorRef.current.commands.setContent('');
  }, [onSubmit]);

  const editor = useEditor({
    content,
    extensions: [
      CommentDocument,
      Text,
      Paragraph,
      Bold,
      Italic,
      Mention.configure({
        renderText({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
        },
        HTMLAttributes: {
          class: 'mention-suggestion',
        },
        suggestion: suggestions(getContainerRef),
      }),
      Placeholder.configure({
        placeholder,
      }),
      Shortcuts.configure({
        additionalShortcuts: {
          'Mod-Enter': () => {
            handleSubmit();
            return true;
          },
        },
      }),
    ],
    editorProps: {
      attributes: {
        class: 'outline-0 max-h-[12rem] comment-editor',
      },
    },
    onCreate: ({ editor: e }) => {
      editorRef.current = e;
    },
    onUpdate: ({ editor: e }) => {
      const contentNonEmpty = !!e.getText().trim();
      setHasContent(contentNonEmpty);
      hasContentRef.current = contentNonEmpty;
    },
  });

  useEffect(() => {
    if (autoFocus && editor) {
      editor.commands.focus();
    }
  }, [autoFocus, editor]);

  return (
    <div ref={containerRef} className={cn('flex flex-col gap-2', className)}>
      <div className="flex-1 overflow-auto">
        <EditorContent editor={editor} />
      </div>
      <div className="flex items-center justify-between gap-8">
        {/* <div className="flex items-center flex-none">
          <Button
            $variant="tertiary"
            $size="small"
            $isIconButton
            $leftSlot={<Icon name="Emoji" />}
            onClick={console.log}
          />
          <Button
            $variant="tertiary"
            $size="small"
            $isIconButton
            $leftSlot={<Icon name="Mention" />}
            onClick={() => editor?.chain().focus().insertContent('@').run()}
          />
        </div> */}
        <div className="flex items-center flex-none ml-auto">
          <Tooltip enabled title="Add comment" shortcut={['Mod', 'Enter']} tippyOptions={{ placement: 'bottom' }}>
            <Button
              $variant="tertiary"
              disabled={!hasContent}
              $size="small"
              $isIconButton
              $leftSlot={<Icon name="ArrowSmallRight" />}
              onClick={handleSubmit}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
