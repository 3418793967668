import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChartBarIcon, PlusIcon } from '@heroicons/react/20/solid';

import PostPoll from '@/components/_domain/PostPoll';
import { EmptyCard } from '@/components/ResourceList';
import { UpgradeIntent } from '@/components/UpgradeIntent';
import { useSettings } from '@/context/settings-context';
import { Post, PostStatus } from '@/interfaces/post';
import { IntentAction } from '@/interfaces/upgrades';
import analytics from '@/utils/analytics';
import { PLAN } from '@/utils/plans';

interface Props {
  post: Post;
}

const Polls = ({ post }: Props) => {
  const navigate = useNavigate();

  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const [pollsUpgradeIsOpen, setPollsUpgradeIsOpen] = useState(false);

  useEffect(() => {
    if (!settings?.polls) {
      return;
    }

    if (post.status !== PostStatus.PUBLISHED && post.status !== PostStatus.ARCHIVED) {
      return;
    }

    if (!post.post_poll_ids) {
      return;
    }

    if (post.post_poll_ids.length === 0) {
      return;
    }

    analytics.track('Poll Responses');
  }, [post.post_poll_ids, post.status, settings?.polls]);

  if (!settings?.polls) {
    return (
      <>
        <UpgradeIntent
          isOpen={pollsUpgradeIsOpen}
          intentAction={IntentAction.USE_POLLS}
          onClose={() => setPollsUpgradeIsOpen(false)}
          preselectedPlan={PLAN.GROW}
        />
        {isV2 ? (
          <EmptyCard
            title="Polls"
            description="Polls are only available on premium beehiiv plans."
            primaryIcon={ChartBarIcon}
            primaryActionLabel="Upgrade"
            primaryActionIcon={null}
            onPrimaryActionClick={() => setPollsUpgradeIsOpen(true)}
          />
        ) : (
          <p className="mb-64 text-gray-600 text-sm">
            Polls are only available on premium beehiiv plans.{' '}
            <span
              aria-hidden
              onClick={() => setPollsUpgradeIsOpen(true)}
              className="cursor-pointer text-primary-500 underline hover:text-grey-500"
            >
              Upgrade
            </span>{' '}
            to get access.
          </p>
        )}
      </>
    );
  }

  if (post.status !== PostStatus.PUBLISHED && post.status !== PostStatus.ARCHIVED) {
    return isV2 ? (
      <EmptyCard
        title="Polls"
        description="Poll metrics are only available for published posts. Once published, come back here to see your poll's performance."
        primaryIcon={ChartBarIcon}
      />
    ) : (
      <p className="mb-64 text-gray-600 text-sm">
        Poll metrics are only available for published posts. Once published, come back here to see your poll&apos;s
        performance.
      </p>
    );
  }

  if (!post.post_poll_ids) {
    return isV2 ? (
      <EmptyCard title="Loading Polls..." primaryIcon={ChartBarIcon} />
    ) : (
      <p className="mb-64 text-gray-600 text-sm">Loading...</p>
    );
  }

  if (post.post_poll_ids.length === 0) {
    return isV2 ? (
      <EmptyCard
        title="No Polls Created"
        description="You can create a poll for a future post"
        primaryIcon={ChartBarIcon}
        primaryActionLabel="New Poll"
        primaryActionIcon={PlusIcon}
        onPrimaryActionClick={() => navigate('/polls')}
      />
    ) : (
      <p className="mb-64 text-gray-600 text-sm">
        This post did not include any polls.{' '}
        <Link to="/polls" className="text-primary-600 underline">
          Create one
        </Link>{' '}
        to use in a future post.
      </p>
    );
  }

  return (
    <div className="space-y-4">
      {post.post_poll_ids.map((postPollId) => (
        <PostPoll postPollId={postPollId} />
      ))}
    </div>
  );
};

export default Polls;
