import styled, { css } from 'styled-components';

const ButtonText = styled.span`
  font-size: 0.875rem;
  flex: 1 1 auto;
`;

const ButtonIconWrapper = styled.span`
  flex: 0 0 auto;

  svg {
    display: block;
  }
`;

const ButtonWrapper = styled.button<{ active?: boolean }>`
  align-items: center;
  border-radius: 0.25rem;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  margin-inline: -0.375rem;
  padding: 0.63rem 0.375rem;
  text-align: left;
  width: 100%;

  ${({ active }) =>
    active &&
    css`
      background-color: rgba(0, 0, 0, 0.04);
      color: #111827;

      ${ButtonText} {
        font-weight: 600;
      }

      ${ButtonIconWrapper} {
        color: #ec4899;
      }
    `}
`;

const Styled = {
  ButtonWrapper,
  ButtonIconWrapper,
  ButtonText,
};

export default Styled;
