import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import analytics from '@/utils/analytics';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { NewPublication } from '../../interfaces/publication';
import api from '../../services/swarm';

const useCreatePublication = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (publication: NewPublication) =>
      api.post(`/publications`, {
        publication_id: currentPublicationId,
        ...publication,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: (result: any) => {
        analytics.track('Publication Created', {
          id: result?.data?.publication?.id,
          organizationId: result?.data?.publication?.organization_id,
        });
        return result?.data?.publication;
      },
    }
  );
};

export default useCreatePublication;
