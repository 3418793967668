import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

const useDeleteSvixEndpoint = () => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    publication_id: currentPublicationId,
  };

  return useMutation((endpointId: string) => api.delete(`/webhooks/svix_endpoints/${endpointId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.message || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['publications', currentPublicationId, 'webhooks', 'endpoints']);
      toast.success('Endpoint Removed!');
    },
  });
};

export default useDeleteSvixEndpoint;
