import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { AdminAccess, PermissionLevel, PermissionResource, Permissions } from '../interfaces/permissions';
import api from '../services/swarm';

import { useCurrentPublicationState } from './current-publication-context';

interface IPermissionsContext {
  permissions?: Permissions;
  adminAccess?: AdminAccess;
  isLoading: boolean;
  reloadPermissions: () => void;
  checkPermissions: (resource: PermissionResource, permission: PermissionLevel) => boolean | undefined;
}

const PermissionsContext = createContext<IPermissionsContext | undefined>(undefined);

PermissionsContext.displayName = 'PermissionsContext';

const PermissionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState<Permissions>();
  const [adminAccess, setAdminAccess] = useState<AdminAccess | undefined>(undefined);
  const [publicationId] = useCurrentPublicationState();

  const reloadPermissions = useCallback(() => {
    if (!publicationId || publicationId === '') {
      return;
    }

    const params = {
      publication_id: publicationId,
    };

    setIsLoading(true);

    api
      .get('/permissions', { params })
      .then((res) => {
        setAdminAccess(res.data.admin);

        const dataCopy = { ...res.data };
        delete dataCopy.admin;
        setPermissions(dataCopy);
      })
      .finally(() => setIsLoading(false));
  }, [publicationId]);

  const checkPermissions = useCallback(
    (resource: PermissionResource, permission: PermissionLevel) =>
      permissions && permissions[resource]?.includes(permission),
    [permissions]
  );

  useEffect(() => {
    reloadPermissions();
  }, [reloadPermissions]);

  return useMemo(() => (
      <PermissionsContext.Provider
        value={{
          reloadPermissions,
          checkPermissions,
          permissions,
          isLoading,
          adminAccess,
        }}
      >
        {children}
      </PermissionsContext.Provider>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [reloadPermissions, checkPermissions, permissions, isLoading, adminAccess]);
};

function usePermissions() {
  const context = React.useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error(`usePermissions must be used within a PermissionsProvider`);
  }
  return context;
}

export { PermissionsContext, PermissionsProvider, usePermissions };
