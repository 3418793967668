import { EmailMessageStatus } from '../../../../interfaces/email_message';
import Badge from '../../../Badge';

interface StatusBadgeProps {
  status: EmailMessageStatus;
  lastSentAt: string | null;
}

type SegmentBlastStatus = 'draft' | 'scheduled' | 'sent';

const STATUS_DICTIONARY: {
  [key in SegmentBlastStatus]: {
    badgeType: 'success' | 'alert' | 'warning' | 'information';
  };
} = {
  draft: {
    badgeType: 'information',
  },
  scheduled: {
    badgeType: 'warning',
  },
  sent: {
    badgeType: 'success',
  },
};

// Since the email infrastructure is setup to handle sending messages a single time to a group or
// multiple times to various people, we don't store a draft/scheduled/sent status on the actual
// model. This means we need to derive it.
const findDerivedStatus = (status: EmailMessageStatus, lastSentAt: string | null) => {
  if (status === EmailMessageStatus.ACTIVE) {
    if (!lastSentAt) return 'scheduled';

    return new Date(lastSentAt) > new Date() ? 'scheduled' : 'sent';
  }

  return 'draft';
};

const StatusBadge = ({ status, lastSentAt }: StatusBadgeProps) => {
  const derivedStatus = findDerivedStatus(status, lastSentAt);
  const { badgeType } = STATUS_DICTIONARY[derivedStatus];

  return (
    <Badge type={badgeType}>
      <span className="capitalize text-xs">{derivedStatus}</span>
    </Badge>
  );
};

export default StatusBadge;
