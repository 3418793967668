import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import { Editor } from '@tiptap/core';
import { Transaction } from '@tiptap/pm/state';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import { CollabHistory, CollabOnUpdateProps } from '@tiptap-pro/extension-collaboration-history';
import cx from 'classnames';

import { Settings } from '../../interfaces/setting';
import { IntentAction } from '../../interfaces/upgrades';
import { EditorContext } from '../../pages/Post/Edit/EditorContext';
import { PLAN, PREMIUM_PLANS } from '../../utils/plans';
import { DragHandleButton } from '../TiptapEditor/components/DragHandleButton';
import { LinkMenu, RemoveBlockMenu, TextMenu } from '../TiptapEditor/components/menus';
import { ThreadComposer } from '../TiptapEditor/components/panels/ThreadComposer';
import {
  ThreadComposerConsumer,
  ThreadComposerProvider,
} from '../TiptapEditor/components/panels/ThreadComposer/Context';
import { AdvertisementOpportunityMenu } from '../TiptapEditor/extensions/AdvertisementOpportunity/menus/AdvertisementOpportunityMenu';
import { AdvertisementOpportunityLogoMenu } from '../TiptapEditor/extensions/AdvertisementOpportunityLogo/menus/AdvertisementOpportunityLogoMenu';
import { AudioMenu } from '../TiptapEditor/extensions/Audio/menus/AudioMenu';
import { BlockquoteFigureMenu } from '../TiptapEditor/extensions/BlockquoteFigure/menus';
import { ButtonMenu } from '../TiptapEditor/extensions/Button/menus';
import { ExtensionKit } from '../TiptapEditor/extensions/extension-kit';
import { FileAttachmentMenu } from '../TiptapEditor/extensions/FileAttachment/menus/FileAttachmentMenu';
import { GenericEmbedMenu } from '../TiptapEditor/extensions/GenericEmbed/menus';
import { CaptionMenu } from '../TiptapEditor/extensions/ImageBlock/menus/CaptionMenu';
import { ImageBlockMenu } from '../TiptapEditor/extensions/ImageBlock/menus/ImageBlockMenu';
import { ColumnsMenu } from '../TiptapEditor/extensions/MultiColumn/menus/ColumnsMenu';
import { PollBlockMenu } from '../TiptapEditor/extensions/Poll/menus/PollBlockMenu';
import { TableColumnMenu, TableRowMenu } from '../TiptapEditor/extensions/Table/menus';
import { TableOfContentsMenu } from '../TiptapEditor/extensions/TableOfContents/menus';
import { EditorUser } from '../TiptapEditor/lib/types';
import { Styled } from '../TiptapEditor/styled';
import { UpgradeIntent as UpgradeIntentModal } from '../UpgradeIntent';

interface TiptapEditorProps {
  allowAds?: boolean;
  allowPolls?: boolean;
  className?: string;
  content?: Content;
  onBlur?: (props: { editor: Editor; event: Event }) => void;
  onUpdate?: (props: { editor: Editor; transaction: Transaction }) => void;
  onUsersUpdate?: (users: EditorUser[]) => void;
  provider?: TiptapCollabProvider;
  publicationId: string;
  settings: Settings;
  useCollabHistory?: boolean;
  userColor?: string;
  userId?: string;
  userName?: string;
  usesCollaboration?: boolean;
  onVersionUpdate?: (payload: CollabOnUpdateProps) => void;
  threadsSidebarOpen?: boolean;
  shouldAutoFocus?: boolean;
}

interface UpgradeIntentModalState {
  isOpen: boolean;
  action?: IntentAction;
  plan?: keyof typeof PREMIUM_PLANS;
}

export const HexEditor = ({
  publicationId,
  settings,
  onBlur,
  onUpdate,
  onUsersUpdate,
  userId,
  userName,
  userColor,
  usesCollaboration,
  provider,
  className,
  allowPolls = false,
  allowAds = false,
  content = '',
  useCollabHistory = false,
  onVersionUpdate,
  threadsSidebarOpen,
  shouldAutoFocus = true,
}: TiptapEditorProps): JSX.Element | null => {
  const menuContainerRef = useRef(null);
  const [hideTextMenu, setHideTextMenu] = useState(false);

  const { setEditor, setShowSearchAndReplaceMenu, setShowThreadsSidebar } = useContext(EditorContext);

  const [upgradeIntentModal, setUpgradeIntentModal] = useState<UpgradeIntentModalState>({
    isOpen: false,
    action: undefined,
  });

  const editor = useEditor({
    extensions: [
      ...(ExtensionKit({
        publicationId,
        userName,
        userId,
        userColor,
        usesCollaboration,
        provider,
        settings,
        allowPolls,
        allowAds,
        onToggleUpgradeIntentModal: (action: IntentAction, plan: keyof typeof PREMIUM_PLANS) => {
          setUpgradeIntentModal((prevState) => ({ isOpen: !prevState.isOpen, action, plan }));
        },
        additionalShortcuts: {
          'Mod-f': () => {
            setShowSearchAndReplaceMenu(true);

            setTimeout(() => document.querySelector<HTMLInputElement>('#search-input')?.focus());

            return true;
          },
        },
        openThreadsSidebar: () => setShowThreadsSidebar(true),
      }) as any),
      useCollabHistory ? CollabHistory.configure({ provider, onUpdate: onVersionUpdate }) : null,
    ],
    editorProps: {
      attributes: {
        class: className || '',
      },
    },
    onCreate: ({ editor: tiptapEditor }) => {
      setEditor(tiptapEditor as any);

      tiptapEditor.view.dom.addEventListener('mousedown', () => setHideTextMenu(true));
      tiptapEditor.view.dom.addEventListener('mouseup', () => setHideTextMenu(false));
    },
    onBlur: (props) => {
      onBlur?.(props);
    },
    onUpdate,
    autofocus: shouldAutoFocus,
    ...(usesCollaboration ? {} : { content }),
  });

  const users = useMemo(() => {
    if (!editor?.storage.collaborationCursor?.users) {
      return [];
    }

    return editor.storage.collaborationCursor?.users.map((user: EditorUser) => {
      const names = user.name?.split(' ');
      const firstName = names?.[0];
      const lastName = names?.[names.length - 1];
      const initials = `${firstName?.[0] || '?'}${lastName?.[0] || '?'}`;

      return { ...user, initials: initials.length ? initials : '?' };
    });
  }, [editor?.storage.collaborationCursor?.users]);

  useEffect(() => {
    onUsersUpdate?.(users);
  }, [users, onUsersUpdate]);

  if (!editor) {
    return null;
  }

  return (
    <ThreadComposerProvider>
      <ThreadComposerConsumer>
        {({ isComposing }) => (
          <>
            <ThreadComposer editor={editor} />
            <div ref={menuContainerRef}>
              <DragHandleButton editor={editor} appendTo={menuContainerRef} />
              <RemoveBlockMenu editor={editor} appendTo={menuContainerRef} />
              <LinkMenu editor={editor} appendTo={menuContainerRef} />
              <TextMenu editor={editor} appendTo={menuContainerRef} shouldHide={hideTextMenu || isComposing} />
              <BlockquoteFigureMenu editor={editor} appendTo={menuContainerRef} />
              <GenericEmbedMenu editor={editor} appendTo={menuContainerRef} />
              <ButtonMenu editor={editor} appendTo={menuContainerRef} />
              <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
              <CaptionMenu editor={editor} appendTo={menuContainerRef} />
              <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
              <FileAttachmentMenu editor={editor} appendTo={menuContainerRef} />
              <AudioMenu editor={editor} appendTo={menuContainerRef} />
              <AdvertisementOpportunityMenu editor={editor} appendTo={menuContainerRef} />
              <AdvertisementOpportunityLogoMenu editor={editor} appendTo={menuContainerRef} />
              <PollBlockMenu editor={editor} appendTo={menuContainerRef} />
              <TableOfContentsMenu editor={editor} appendTo={menuContainerRef} />
              <TableRowMenu editor={editor} appendTo={menuContainerRef} />
              <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
            </div>
            <Styled.Container>
              <EditorContent
                editor={editor}
                suppressContentEditableWarning
                className={cx(threadsSidebarOpen && 'threads-sidebar-open')}
              />
            </Styled.Container>
            <UpgradeIntentModal
              isOpen={upgradeIntentModal.isOpen}
              intentAction={upgradeIntentModal.action}
              preselectedPlan={upgradeIntentModal.plan || PLAN.SCALE}
              onClose={() => {
                setUpgradeIntentModal({ ...upgradeIntentModal, isOpen: false });
              }}
            />
          </>
        )}
      </ThreadComposerConsumer>
    </ThreadComposerProvider>
  );
};

export default HexEditor;
