import { OrderBy, SpendOrderBy } from '@/interfaces/boosts/order';

export const orderByOptions = [
  { label: 'Pending subscribers', value: OrderBy.PENDING_SUBSCRIBER_COUNT },
  { label: 'Verified subscribers', value: OrderBy.ACCEPTED_SUBSCRIBER_COUNT },
  { label: 'Total subscribers', value: OrderBy.TOTAL_SUBSCRIBER_COUNT },
];

export const spendOrderByOptions = [
  { label: 'Pending spend', value: SpendOrderBy.PENDING_SPEND },
  { label: 'Total spend', value: SpendOrderBy.TOTAL_SPEND },
];
