import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentPublication, useUpdateWebTemplate } from '@/hooks';

import ActionModal from '../../../../../../components/ActionModal';
import { Switch } from '../../../../../../components/Form';
import { SignupFlowItem, WebTemplate } from '../../../../../../interfaces/web_template';

import FlowStep from './FlowStep';
import { UpdateMessage, useUpdateMessage } from './UpdateMessage';

interface Props {
  step: SignupFlowItem;
  webTemplate: WebTemplate;
}

const RecommendationsStep = ({ step, webTemplate }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: currentPublication } = useCurrentPublication();
  const webTemplateMutation = useUpdateWebTemplate();
  const signupMessaging = webTemplate?.signup_flow_messaging;

  const {
    isOpen: isMessageModalOpen,
    message,
    handleClose: handleCloseMessageModal,
    handleOpen: handleOpenMessageModal,
    onChange,
  } = useUpdateMessage({ message: signupMessaging?.recommendations_message || '' });

  const handleUpdateMessage = async () => {
    await webTemplateMutation.mutateAsync({
      signup_flow_messaging: JSON.stringify({
        ...signupMessaging,
        recommendations_message: message,
      }),
    });

    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
    toast.success('Saved!');
    handleCloseMessageModal();
  };

  const handleToggle = async () => {
    await webTemplateMutation.mutateAsync({
      enable_recommendations_page_post_signup: !webTemplate?.enable_recommendations_page_post_signup,
    });
    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
  };

  const handleEnable = async () => {
    if (webTemplate?.has_active_recommendations) {
      handleToggle();
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      {/* Enable Action Modal */}
      <ActionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        resourceId=""
        headerText="Add some recommendations first!"
        onProceed={() => navigate('/recommendations')}
        isWorking={false}
        actionText="Let's do it"
      >
        <div className="text-sm space-y-4">
          <p>To add recommendations to this signup flow you will first need to add some newsletters recommendations.</p>
        </div>
      </ActionModal>

      {/** Custom Page Message Modal */}
      <UpdateMessage
        isOpen={isMessageModalOpen}
        onClose={handleCloseMessageModal}
        isLoading={false}
        message={message}
        onProceed={handleUpdateMessage}
        name={step?.title?.split(' ')?.join('_')?.toLowerCase()}
        onChange={onChange}
      />

      <FlowStep title={step.title} url={step.full_url} enabled={step.enabled} onUpdateMessage={handleOpenMessageModal}>
        <Switch
          name="recommendations"
          checked={step.enabled}
          onChange={() => {
            if (step.enabled) return handleToggle();

            return handleEnable();
          }}
          variant="primary"
        />
      </FlowStep>
    </>
  );
};

export default RecommendationsStep;
