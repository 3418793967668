export interface DnsRecord {
  type: string;
  name: string;
  value: string;
}

export enum CustomDomainTypes {
  WEB = 'web',
  EMAIL = 'email',
  REDIRECT = 'redirect',
}

export interface CustomDomain {
  id: string;
  domain: string;
  email_username: string;
  verified: boolean;
  unused: boolean;
  root_domain: string;
  verification_record: DnsRecord;
  web_enabled: boolean;
  web_redirect_enabled: boolean;
  email_enabled: boolean;
  web_verification_pending: boolean;
  web_redirect_verification_pending: boolean;
  email_verification_pending: boolean;
  email_authenticated_domains: DnsRecord[];
  web_authenticated_domains: DnsRecord[];
  warming_up: boolean;
}
