import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/ui/Button';

import { Checkbox, Textarea } from '../../../../components/Form';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import SubscriberSelect from '../../../../components/SubscriberSelect';
// Contexts
import { useCurrentUser } from '../../../../context/current-user-context';
import { useSettings } from '../../../../context/settings-context';
// Interfaces
import { Post } from '../../../../interfaces/post';
import { IUser } from '../../../../interfaces/user';
// Services
import api from '../../../../services/swarm';

interface Props {
  post: Post;
  onSubmit: () => void;
}

const TestSend: React.FunctionComponent<Props> = (props: Props) => {
  const { post, onSubmit } = props;
  const { currentUser } = useCurrentUser();
  const { settings } = useSettings();
  const [emailOptions, setEmailOptions] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([currentUser?.email]);
  const [additionalEmails, setAdditionalEmails] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentSubscriberId, setCurrentSubscriberId] = useState<string>();

  const fetchUsers = useCallback(() => {
    if (currentUser) {
      api.get(`/publications/${post.publication_id}/users`).then((resp) => {
        setIsLoading(false);
        setEmailOptions(resp.data.map((u: IUser) => u.email));
      });
    }
  }, [currentUser, post.publication_id]);

  const submitTest = () => {
    api
      .post(`/posts/${post.id}/test_send`, {
        test_send: {
          selected_emails: selectedEmails,
          extra_addresses: additionalEmails,
          current_subscriber_id: currentSubscriberId,
        },
      })
      .then(() => {
        onSubmit();
        toast.success('Test email sent');
      })
      .catch((errPayload) => {
        toast.error(errPayload.response.data.message || 'There was a problem sending your test');
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  if (!currentUser || !settings) {
    return null;
  }

  return (
    <>
      {isLoading ? (
        <div className="h-full w-full flex justify-center items-center">
          <LoadingSpinner className="mr-3" />
          <p className="text-gray-500">Loading</p>
        </div>
      ) : (
        <div className="h-full px-4 pt-4 pb-2 overflow-auto">
          <p className="text-xl text-gray-800 mb-2">Send a test</p>
          {settings.external_test_sends ? (
            <p className="text-sm text-gray-500">Select up to 10 email addresses to receive the test.</p>
          ) : (
            <p className="text-sm text-gray-500">
              Select up to 10 email addresses to receive the test. If you need to send tests to emails outside of your
              organization,&nbsp;
              <a
                className="underline hover:text-primary-600"
                href="https://support.beehiiv.com/hc/en-us/requests/new"
                target="_blank"
                rel="noreferrer"
              >
                contact support
              </a>
            </p>
          )}
          <div className="mt-8 space-y-3">
            {emailOptions.map((email) => (
              <Checkbox
                key={email}
                labelText={email}
                name={email}
                checked={selectedEmails.includes(email)}
                onChange={(checked) => {
                  if (checked) {
                    setSelectedEmails([...selectedEmails, email]);
                  } else {
                    setSelectedEmails(selectedEmails.filter((e) => e !== email));
                  }
                }}
              />
            ))}
          </div>
          {settings.external_test_sends && (
            <div className="mt-8 max-w-3xl">
              <Textarea
                name="additional_emails"
                labelText="Enter additional emails, separated by commas"
                value={additionalEmails}
                onChange={(e) => setAdditionalEmails(e.target.value)}
              />
            </div>
          )}
          <div className="mt-16 max-w-3xl">
            <p className="text-lg text-gray-800 mb-4">Advanced</p>
            <SubscriberSelect
              labelText="Simulated Subscriber"
              helperText="This still only sends the test to the emails selected above, but will send the email personalized as if sent to this selected subscriber. Great for testing personalization or referral program."
              onSelectSubscriber={(subscriberId: string) => setCurrentSubscriberId(subscriberId)}
              onClearSubscriber={() => setCurrentSubscriberId('')}
            />
          </div>
          <div className="flex justify-end mt-8">
            <Button variant="primary-inverse" onClick={submitTest}>
              Send
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default TestSend;
