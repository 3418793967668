import { Tab } from '@/interfaces/general';

export const getTabs = (segmentId: string, pathname: string): Tab[] => [
  {
    name: 'overview',
    label: 'Overview',
    targetRoute: `/segments/${segmentId}`,
    selected: pathname === `/segments/${segmentId}`,
  },
  {
    name: 'blasts',
    label: 'Email this segment',
    targetRoute: `/segments/${segmentId}/blasts`,
    selected: pathname.includes(`/segments/${segmentId}/blasts`),
  },
  {
    name: 'configure',
    label: 'Configure',
    targetRoute: `/segments/${segmentId}/edit`,
    selected: pathname === `/segments/${segmentId}/edit`,
  },
];
