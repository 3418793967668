import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Navigate } from 'react-router-dom';

interface Props {
  redirectPath?: string;
}

const NoPermission = ({ redirectPath }: Props) => {
  useEffect(() => {
    toast.error('You do not have permission to access that page');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Navigate to={redirectPath || '/'} />;
};

export default NoPermission;
