import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { Input } from '@/components/Form';
import { useUpdateApiKey } from '@/hooks/useIntegrations';
import { Button } from '@/ui/Button';

interface Props {
  isOpen: boolean;
  close: () => void;
  id: string;
  currentValue: string;
}

const EditApiKey = ({ currentValue, isOpen, close, id }: Props) => {
  const [name, setName] = useState<string>(currentValue);

  const updateApiKey = useUpdateApiKey({ keyId: id, onSuccess: close });

  useEffect(() => {
    if (!name && currentValue) {
      setName(currentValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    updateApiKey.mutate({ name });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-90" onClose={close} style={{ zIndex: 999999 }}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-md pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white p-8 shadow-xl border-l border-gray-200">
                    <div className="flex w-full space-x-2 items-center justify-between pb-4">
                      <h2 className="text-lg leading-6 font-medium text-gray-900 ml-0 flex">Edit Key Name</h2>
                      <button
                        className="h-7 w-7 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                        type="button"
                        onClick={close}
                      >
                        <XMarkIcon className="h-4 w-4" />
                      </button>
                    </div>
                    <form onSubmit={handleUpdate}>
                      <div className="space-y-4 w-full">
                        <div className="space-y-4">
                          <Input
                            className="w-full"
                            name="name"
                            labelText="Name"
                            placeholder="My API Key"
                            helperText="This is so you can identify the use case of the API Key"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />

                          <div className="w-full flex justify-end space-x-2 pt-4">
                            <Button type="button" variant="primary-inverse" onClick={close}>
                              Cancel
                            </Button>
                            <Button type="submit" variant="primary" loading={updateApiKey.isLoading}>
                              <div className="flex">{updateApiKey.isLoading ? 'Updating...' : 'Update Key Name'}</div>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditApiKey;
