import React, { useCallback, useEffect, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash.debounce';

interface SearchInputProps {
  onSearch: (value: string) => void;
  defaultValue?: string;
  onClearSearch?: () => void;
  placeholder?: string;
  shouldReset?: boolean;
  shouldDebounce?: boolean;
  debounceMilliseconds?: number;
  variant?: 'black' | string | undefined;
  block?: boolean;
  searchInputLineHeight?: number;
}

const SearchInput = ({
  defaultValue,
  onClearSearch,
  shouldDebounce,
  shouldReset,
  onSearch,
  placeholder,
  debounceMilliseconds = 200,
  variant,
  block,
  searchInputLineHeight = 17,
}: SearchInputProps) => {
  const [query, setQuery] = useState(defaultValue || '');

  useEffect(() => {
    if (shouldReset) {
      setQuery('');
    }
  }, [shouldReset]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(
    debounce((s) => onSearch(s), debounceMilliseconds),
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!shouldDebounce) e.preventDefault();

    setQuery(e.target.value);

    // Checks if value has been cleared so parent component can have clear callback
    if (!e.target.value && onClearSearch) {
      onClearSearch();
    }

    if (shouldDebounce) {
      handleSubmit.cancel();
      handleSubmit(e.target.value);
    }
  };

  let searchIconClass;
  let inputClass;

  switch (variant) {
    case 'dark':
      searchIconClass = 'h-4 w-4 text-white opacity-80';
      inputClass =
        'placeholder-shown:text-ellipsis block w-full h-full py-2 pl-8 pr-3 border-0 rounded-md placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 text-sm';
      break;
    default:
      searchIconClass = 'h-4 w-4 text-gray-400';
      inputClass =
        'placeholder-shown:text-ellipsis block w-full h-full py-2 pl-8 pr-3 border-0 rounded-md text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 text-sm';
  }

  return (
    <form
      className="w-full h-full shadow-sm rounded-md border border-gray-300"
      onSubmit={(e) => {
        e.preventDefault();

        onSearch(query);
      }}
    >
      <div className={block ? 'w-full' : 'flex-1 h-full flex justify-center lg:justify-end'}>
        <div className={block ? 'w-full' : 'max-w-lg h-full w-full lg:max-w-xs'}>
          <div className="relative h-full">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className={searchIconClass} />
            </div>
            <input
              className={inputClass}
              placeholder={placeholder}
              style={{
                ...(variant === 'dark'
                  ? { background: 'rgba(255, 255, 255, 0.05)', color: 'rgba(255,255,255,0.8)' }
                  : {}),
                lineHeight: `${searchInputLineHeight}px`,
              }}
              onChange={handleChange}
              value={query}
              type="search"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

SearchInput.defaultProps = {
  shouldReset: false,
  shouldDebounce: true,
};

export default SearchInput;
