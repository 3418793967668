import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

const PartnerProgramFAQ = () => {
  const [selectedFAQ, setSelectedFAQ] = useState<number>(0);

  const faqs = [
    {
      id: 1,
      question: 'How do payouts work?',
      answer:
        'Payments are made to all partners on the 15th of each month, which covers the commissionable earnings from the prior month. These payouts are made via Paypal.',
    },
    {
      id: 2,
      question: 'What do I do if my PayPal payout fails?',
      answer:
        'If your PayPal payout fails when we make payouts you will need to simply update your PayPal address and or fix any pending issues with your PayPal account.',
    },
    {
      id: 3,
      question: 'Can I run paid traffic to my partner link?',
      answer:
        "You cannot run search engine ads on Bing and or Google (especially on branded terms or domain names.) If you're running search ads directly to your affiliate link this will result in your account being permanently banned. Keep in mind, ads on Meta, LinkedIn, or other social platforms are ok to run ads.",
    },
    {
      id: 4,
      question: 'What if a user upgrades or downgrades?',
      answer:
        'Your commission will simply be tracked and fluctuate up or down if a user you referred upgrades or downgrades their plan.',
    },
    {
      id: 5,
      question: 'What is the cookie duration?',
      answer:
        'Cookies expire after 60-days. If a user for example signs up after clicking your link within that 60-day window and becomes a paying beehiiv customer, that qualifies for a commission!',
    },
    {
      id: 6,
      question: 'Can I sign up using my own affiliate link?',
      answer:
        'Signing up with your own link is NOT allowed. If you do happen to sign up using your own link the commission will be removed.',
    },
    {
      id: 7,
      question: 'How does your Tax Compliance work?',
      answer:
        'All partners are required to complete our intake form to ensure your account is tax compliant. You can start sharing your link immediately but eligible payouts seen within your Rewardful account may be withheld until all all your proper tax paperwork has been submitted.',
    },
    {
      id: 8,
      question: 'What is the difference between Visitors, Leads, & Conversions?',
      answer:
        'Visitors would be the unique traffic you send to one of our pages. Leads would be the amount of that traffic that actually signs up for an account. Conversions are the amount of those leads that end up paying for any beehiiv plan.',
    },
  ];

  return (
    <div className="p-6 w-full flex flex-col space-y-6 bg-white border rounded-lg">
      <div className="flex flex-col space-y-3">
        {faqs.map((pair) => {
          return (
            <div key={pair.id} className="flex flex-col space-y-3 w-full">
              <div className="flex flex-col w-full space-y-4">
                <button
                  onClick={() => {
                    if (pair.id === selectedFAQ) {
                      setSelectedFAQ(0);
                    } else {
                      setSelectedFAQ(pair.id);
                    }
                  }}
                  type="button"
                  className="flex flex-row justify-between items-between w-full"
                >
                  <p className="text-gray-900 font-medium">{pair.question}</p>
                  <ChevronDownIcon
                    className={cx('h-5 w-5 text-surface-500', pair.id === selectedFAQ && 'rotate-180')}
                  />
                </button>
                {pair.id === selectedFAQ && <p className="text-gray-700 text-sm">{pair.answer}</p>}
              </div>
              <hr className="w-full" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PartnerProgramFAQ;
