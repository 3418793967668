import { useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

import useConsumption from '@/components/TiptapEditor/lib/hooks/useConsumption';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

import { LanguageType, PromptType } from './types';

const getPath = (type: PromptType) => {
  switch (type) {
    case 'CORRECT':
      return `editor/ai/text/correct`;
    case 'COMPLETE':
      return `editor/ai/text/complete`;
    case 'TRANSLATE':
      return `editor/ai/text/translate`;
    default:
      return null;
  }
};

type ProcessPayload = {
  language?: LanguageType['value'];
  publication_id: string;
  starts_inline: boolean;
  text: string;
};

export const useAITools = ({ text, onText }: { text: string | null; onText: (text: string) => void }) => {
  const [isFetching, setIsFetching] = useState(false);
  const { consumption, isConsumptionExceeded, refetchConsumption, setConsumption, showConsumption } = useConsumption();

  const [currentPublicationId] = useCurrentPublicationState();

  const process = useCallback(
    async ({ type, value }: { type: PromptType; value?: LanguageType['value'] }) => {
      if (isFetching || isConsumptionExceeded || !text) return null;

      const payload: ProcessPayload = { publication_id: currentPublicationId, text, starts_inline: true };

      setIsFetching(true);

      const path = getPath(type);

      if (!path) {
        return null;
      }

      if (type === 'TRANSLATE') {
        payload.language = value;
      }

      try {
        axiosRetry(api, {
          retries: 3,
          retryDelay: (retryCount) => 2 ** retryCount * 1000 + Math.random() * 1000,
          retryCondition: (errPayload: AxiosError) => errPayload?.response?.status === 429,
        });

        const res = await api.post<{ response: string; requests_consumed: number; requests_allowed: number }>(
          path,
          payload
        );
        const { data: json } = res;

        const output = json.response;

        if (!output) {
          toast.error('An error has occured');
          return null;
        }

        onText(output);

        analytics.track('useAItools', { type });

        setConsumption({
          consumedRequests: json.requests_consumed,
          monthlyAvailableRequests: json.requests_allowed,
        });
      } catch (e: any) {
        const errorMessage = e?.response?.data?.error;
        const message = errorMessage !== 'An error occurred' ? `An error has occured: ${errorMessage}` : errorMessage;

        toast.error(message);
      }

      setIsFetching(false);

      return null;
    },
    [text, isConsumptionExceeded, setConsumption, currentPublicationId, isFetching, onText]
  );

  const onFixSpelling = useCallback(async () => {
    await process({ type: 'CORRECT' });
  }, [process]);

  const onCompleteSentence = useCallback(async () => {
    await process({ type: 'COMPLETE' });
  }, [process]);

  const onTranslate = useCallback(
    (language: LanguageType['value']) => {
      process({ type: 'TRANSLATE', value: language });
    },
    [process]
  );

  return {
    isFetching,
    consumption,
    refetchConsumption,
    showConsumption,
    setConsumption,
    isConsumptionExceeded,
    onFixSpelling,
    onCompleteSentence,
    onTranslate,
  };
};
