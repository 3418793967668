import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import Banner from '@/components/Banner';
import Card from '@/components/Card';
import LoadingBox from '@/components/LoadingBox';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { PostEngagement } from '@/interfaces/post';
import api from '@/services/swarm';
import { useResourceListFilters } from '@/ui/ResourceListFilters';

interface Props {
  postId: string;
}

const Engagement = ({ postId }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const { orderBy, direction, handleClickOrderButton } = useResourceListFilters('', 'total_opened');

  const { data, isLoading, isError } = useQuery<PostEngagement[]>(
    ['posts', postId, 'engagements', orderBy, direction],
    () =>
      api
        .get(`/posts/${postId}/engagements`, {
          params: {
            order: orderBy,
            dir: direction,
          },
        })
        .then((res) => res.data),
    { staleTime: 30000 }
  );

  const table = (
    <Items>
      <ItemHeaders isSticky>
        <ItemHeader>Email</ItemHeader>
        <ItemHeader
          onClick={handleClickOrderButton}
          orderBy="total_opened"
          isSorting={orderBy === 'total_opened'}
          currentDirection={direction}
        >
          Total Opens
        </ItemHeader>
        <ItemHeader
          onClick={handleClickOrderButton}
          orderBy="total_clicked"
          isSorting={orderBy === 'total_clicked'}
          currentDirection={direction}
        >
          Total Clicks
        </ItemHeader>
        <ItemHeader
          onClick={handleClickOrderButton}
          orderBy="unsubscribed_at"
          isSorting={orderBy === 'unsubscribed_at'}
          currentDirection={direction}
        >
          Unsubscribed?
        </ItemHeader>
      </ItemHeaders>
      <ItemsBody>
        {data?.length === 0 ? (
          <ItemRow>
            <ItemColumn colSpan={4} align={AlignType.CENTER}>
              <Typography token="font-normal/text/sm" colorWeight="700">
                No data
              </Typography>
            </ItemColumn>
          </ItemRow>
        ) : null}
        {data?.map((engagement: PostEngagement) => (
          <ItemRow key={engagement.subscriberId}>
            <ItemColumn align={AlignType.LEFT}>
              {engagement.email === null && <Typography token="font-normal/text/sm">Deleted subscriber</Typography>}

              {engagement.email && (
                <Link to={`/subscribers/${engagement.subscriberId}`}>
                  <Typography token="font-normal/text/sm" color="secondary" colorWeight="600">
                    {engagement.email}
                  </Typography>
                </Link>
              )}
            </ItemColumn>
            <ItemColumn>
              <Typography token="font-normal/text/sm" colorWeight="700">
                {engagement.totalOpened}
              </Typography>
            </ItemColumn>
            <ItemColumn>
              <Typography token="font-normal/text/sm" colorWeight="700">
                {engagement.totalClicked}
              </Typography>
            </ItemColumn>
            <ItemColumn>
              <Typography token="font-normal/text/sm" colorWeight="700">
                {engagement.unsubscribedAt === null ? 'No' : 'Yes'}
              </Typography>
            </ItemColumn>
          </ItemRow>
        ))}
      </ItemsBody>
    </Items>
  );

  const banner = (
    <Banner
      isScreenWide={false}
      variant="information"
      title="The total clicks per subscriber can be inflated in certain cases due to link scanning and tracking mechanisms employed by organizations and email service providers."
      bodyText=""
    />
  );

  if (isV2) {
    return (
      <LoadingBox isLoading={isLoading} isError={isError}>
        <div className="flex flex-col gap-y-6">
          {banner}
          {table}
        </div>
      </LoadingBox>
    );
  }

  return (
    <Card>
      <LoadingBox isLoading={isLoading} isError={isError}>
        <div className="flex flex-col gap-y-4">
          {banner}
          <Typography token="font-semibold/text/sm">Top 50 subscribers by post engagement metrics</Typography>
          {table}
        </div>
      </LoadingBox>
    </Card>
  );
};

export default Engagement;
