import { ArrowRightIcon } from '@heroicons/react/24/solid';

import Banner from '@/components/Banner';
import { useSettings } from '@/context/settings-context';
import useFunds from '@/hooks/boosts/grow/useFunds';

import BoostOfferForm from '../../Shared/BoostOfferForm';
import BoostOfferFormHeader from '../../Shared/BoostOfferFormHeader';

const NewBoostOffer = () => {
  const { settings } = useSettings();
  const { hasBalance, handleClickAddFunds, addFundsButtonlabel, isProcessingCheckout } = useFunds();
  if (settings && !settings.organization_boosts) {
    return <div className="text-bold">Boosts have been disabled for this account.</div>;
  }

  return (
    <>
      <BoostOfferFormHeader
        banner={
          !hasBalance && (
            <div className="w-full mb-4">
              <Banner
                isScreenWide={false}
                variant="warning"
                title="You have insufficient funds to create an offer"
                ctaText={addFundsButtonlabel}
                ctaIcon={!isProcessingCheckout ? <ArrowRightIcon className="h-3 w-3" /> : undefined}
                isCTADisabled={isProcessingCheckout}
                bodyText=""
                onClick={handleClickAddFunds}
              />
            </div>
          )
        }
      />
      <BoostOfferForm />
    </>
  );
};

export default NewBoostOffer;
