import Select from '@/components/Form/Select';
import SearchInput from '@/components/SearchInput';

import { ResourceListFiltersProps } from './ResourceListFilters.types';

const ResourceListFilters = ({
  search,
  setSearch,
  setFilter,
  filter,
  filterOptions,
  orderBy,
  setOrderBy,
  sortOrderOptions,
  searchPlaceholder = 'Search',
  filterLabel = '',
}: ResourceListFiltersProps) => {
  const handleChangeInFilter = (_name: string, value: string) => setFilter?.(value);

  const handleChangeInSortOrder = (_name: string, value: string) => setOrderBy?.(value);

  return (
    <div className="flex gap-x-2 w-full justify-end">
      {setSearch && (
        <div className="w-fit">
          <SearchInput
            defaultValue={search}
            shouldDebounce
            onClearSearch={() => setSearch('')}
            onSearch={(val) => setSearch(val)}
            placeholder={searchPlaceholder}
          />
        </div>
      )}
      {filterOptions && (
        <Select
          name="resource-list-filter-select"
          placeholderText={filterLabel}
          value={filter}
          onSelect={handleChangeInFilter}
          options={filterOptions}
          className="w-48"
          optionsContainerClassNames={{
            width: 'w-64',
          }}
        />
      )}
      {setOrderBy && sortOrderOptions && (
        <Select
          name="resource-list-sort-order-select"
          value={orderBy}
          onSelect={handleChangeInSortOrder}
          options={sortOrderOptions}
          className="w-48"
          optionsContainerClassNames={{
            width: 'w-64',
          }}
        />
      )}
    </div>
  );
};

export default ResourceListFilters;
