const Divider = ({ children }: { children: React.ReactNode }) => (
  <div className="relative">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300" />
    </div>
    <div className="relative flex justify-center">
      <span className="px-2 bg-white text-sm text-gray-500">{children}</span>
    </div>
  </div>
);

export default Divider;
