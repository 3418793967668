import React, { FC } from 'react';

import { Select } from '../../../components/Form';
// Hooks
import useOptions from '../../../hooks/useOptions';
// Utils
import { optionDataToOptions } from '../../../utils';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const EmailActionInput: FC<Props> = ({ value, onChange }) => {
  const emailActionsData = useOptions('email_actions', Infinity);
  const emailActionOptions = optionDataToOptions(emailActionsData);

  return (
    <Select
      className="w-full"
      name="value"
      placeholderText="Select Action"
      value={value || ''}
      onSelect={(name: string, selectedValue: string) => onChange(selectedValue)}
      options={emailActionOptions}
    />
  );
};

export default EmailActionInput;
