import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import Banner from '@/components/Banner';
import { useDeletePausePlan, usePausePlan } from '@/hooks/usePausePlan';

const PausePlanNotice: React.FC = () => {
  const { data: pausePlanData } = usePausePlan();
  const navigate = useNavigate();
  const { mutate, isLoading: isDeleting } = useDeletePausePlan();
  const [showUnpauseModal, setShowUnpauseModal] = useState(false);

  if (!pausePlanData?.resumes_at) {
    return null;
  }

  const onUnpausePlan = () => {
    mutate(undefined, {
      onSuccess: () => {
        navigate('/settings/billing');
      },
    });
  };

  return (
    <>
      <ActionModal
        isOpen={showUnpauseModal}
        onClose={() => setShowUnpauseModal(false)}
        onProceed={onUnpausePlan}
        resourceId=""
        isWorking={isDeleting}
        headerText="Resume Plan"
        actionText="Resume Plan"
        buttonType="danger"
      >
        Are you sure you want to resume your plan? You will be unable to pause it again and could be charged immediately
        if you have used your remaining time.
      </ActionModal>
      <div className="max-w-3xl mx-auto">
        <Banner
          variant="information"
          isScreenWide={false}
          title={`Your plan is currently paused and will be resumed on ${moment(pausePlanData.resumes_at).format(
            'MMMM Do YYYY'
          )}`}
          bodyText="While your plan is paused, you will still have access to the Launch (free) features. You can resume your plan anytime before this date."
          ctaText="Unpause Plan"
          onClick={() => {
            setShowUnpauseModal(true);
          }}
        />
      </div>
    </>
  );
};

export default PausePlanNotice;
