import { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { CursorArrowRaysIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash.debounce';

import { useSettings } from '@/context/settings-context';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useCurrentPublicationState } from '../../context/current-publication-context';
import { usePost, usePrompt, usePublicationColorPalette, useReloadAlert } from '../../hooks';
import useFonts from '../../hooks/useFonts';
import usePostTheme from '../../hooks/usePostTheme';
import usePostThemePreview from '../../hooks/usePostThemePreview';
import useUpdatePostTheme from '../../hooks/useUpdatePostTheme';
import useUpdatePostThemePreview from '../../hooks/useUpdatePostThemePreview';
import Navigator from '../DesignLab/components/Navigator';

import ThemeFormDrawer from './components/ThemeFormDrawer';
import BackgroundForm from './BackgroundForm';
import Blockquote2Form from './Blockquote2Form';
import Blockquote3Form from './Blockquote3Form';
import BlockquoteForm from './BlockquoteForm';
import BodyTextForm from './BodyTextForm';
import ButtonForm from './ButtonForm';
import ContentBreakForm from './ContentBreakForm';
import FooterSectionForm from './FooterSectionForm';
import HeaderSectionForm from './HeaderSectionForm';
import HeaderTextForm from './HeaderTextForm';
import ImageForm from './ImageForm';
import LinkForm from './LinkForm';
import ListForm from './ListForm';
import TableForm from './TableForm';
import { PostTheme } from './types';

const editorOptions = [
  { label: 'Background', value: 'background', formComponent: BackgroundForm },
  { label: 'Body Text', value: 'bodyText', formComponent: BodyTextForm },
  { label: 'Header Text', value: 'headerText', formComponent: HeaderTextForm },
  { label: 'Email Header', value: 'emailHeader', formComponent: HeaderSectionForm },
  { label: 'Email Footer', value: 'emailFooter', formComponent: FooterSectionForm },
  { label: 'Buttons', value: 'buttons', formComponent: ButtonForm },
  { label: 'Content Breaks', value: 'contentBreaks', formComponent: ContentBreakForm },
  { label: 'Images', value: 'images', formComponent: ImageForm },
  { label: 'Lists', value: 'lists', formComponent: ListForm },
  { label: 'Blockquotes (Variant 1)', value: 'blockquotes', formComponent: BlockquoteForm },
  { label: 'Blockquotes (Variant 2)', value: 'blockquotes_2', formComponent: Blockquote2Form },
  { label: 'Blockquotes (Variant 3)', value: 'blockquotes_3', formComponent: Blockquote3Form },
  { label: 'Embeds', value: 'embeds', formComponent: LinkForm },
  { label: 'Tables', value: 'tables', formComponent: TableForm },
];

const UNSAVED_MESSAGE = 'You have unsaved changes. Are you sure you want to exit prior to saving?';

const ThemeEditor: React.FC = () => {
  const { postId } = useParams<'postId'>() as { postId: string | undefined };
  const { data: post } = usePost({ id: postId });
  const [currentPublicationId] = useCurrentPublicationState();
  const { data: colorPalette } = usePublicationColorPalette(currentPublicationId);
  const { data: previewHtml } = usePostThemePreview();
  const { data: postTheme, isLoading, isSuccess } = usePostTheme();
  const updateThemePreview = useUpdatePostThemePreview();
  const fontOptions = useFonts();
  const publishTheme = useUpdatePostTheme();
  const { settings } = useSettings();

  // If the URL path has a postId - go back to valid Edit Post page, otherwise to Manage Posts
  // If URL path doesn't have postId, go to Settings
  let backPath: string;
  if (postId) {
    if (post) backPath = `/posts/${postId}/edit`;
    else backPath = '/posts';
  } else {
    backPath = '/';
  }

  const [currentTheme, setCurrentTheme] = useState(postTheme as PostTheme);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const iframe = useRef(null);
  const [activeDrawer, setActiveDrawer] = useState(0);

  const shouldPrompt = isSaving || unsavedChanges;
  const [isPrompting, confirmPrompt] = usePrompt(shouldPrompt);
  useReloadAlert(shouldPrompt, UNSAVED_MESSAGE);

  useEffect(() => {
    if (isPrompting && !shouldPrompt) {
      confirmPrompt();
    }
  }, [isPrompting, shouldPrompt, confirmPrompt]);

  useEffect(() => {
    if (!isLoading && isSuccess && postTheme) {
      setCurrentTheme(postTheme);
    }
  }, [isLoading, isSuccess, postTheme]);

  const handleClose = () => {
    window.location.href = backPath;
  };

  const fetchPreview = useMemo(
    () =>
      debounce((newTheme: PostTheme) => {
        setIsPreviewLoading(true);
        updateThemePreview
          .mutateAsync(newTheme)
          .then((res: any) => {
            if (iframe.current) {
              (iframe.current as any).contentDocument.documentElement.innerHTML = res.html;
            }
          })
          .finally(() => setIsPreviewLoading(false));
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const refreshPreview = (newTheme: PostTheme) => {
    fetchPreview.cancel();
    fetchPreview(newTheme);
  };

  const updateTheme = (obj: PostTheme) => {
    const newTheme: PostTheme = { ...currentTheme, ...obj };
    setUnsavedChanges(true);
    setCurrentTheme(newTheme);
    refreshPreview(newTheme);
  };

  const handlePublishTheme = async () => {
    setIsSaving(true);
    analytics.track('Published Changes');

    try {
      await publishTheme.mutateAsync(currentTheme);
      toast.success('Theme published!');
    } catch (e) {
      toast.error('Error publishing theme.');
    }

    setIsSaving(false);
    setUnsavedChanges(false);
  };

  const drawerClickHandler = (index: number) => {
    return (activeValue: boolean) => {
      if (activeValue) {
        setActiveDrawer(index);
        const tag = document.getElementById(`drawer-${String(index)}`);
        window.scrollTo({
          top: tag?.offsetTop,
          behavior: 'smooth',
        });
      }
    };
  };

  return (
    <div className="flex flex-row w-full h-full">
      <div className="z-10 w-1/4 bg-white min-h-screen border-r border-1 border-gray-200">
        {/* Editor */}
        <div
          className="text-xl font-light text-center"
          style={{
            backgroundImage: 'linear-gradient(rgba(255,255,255,1) 75%, rgba(255,255,255,0))',
          }}
        >
          {currentTheme &&
            editorOptions.map(({ label, value, formComponent: FormComponent }, index) => (
              <ThemeFormDrawer
                scroll_id={`drawer-${String(index)}`}
                active={index === activeDrawer}
                title={label}
                key={`${value}${index === activeDrawer}`}
                onClickHandler={drawerClickHandler(index)}
              >
                <FormComponent
                  theme={currentTheme}
                  onUpdate={updateTheme}
                  fonts={fontOptions}
                  colorPalette={colorPalette}
                  currentPublicationId={currentPublicationId}
                />
              </ThemeFormDrawer>
            ))}
        </div>
      </div>
      {/* Post Preview */}
      <div className="fixed top-0 right-0 h-full bg-white w-3/4">
        <div className="w-full h-full flex flex-col">
          <div className="flex-grow">
            <iframe ref={iframe} title="preview" className="w-full h-full" srcDoc={previewHtml?.html} />
          </div>
          <div className="h-20 w-full bg-white border border-t border-gray-200 flex justify-between p-5 items-center">
            {!settings?.web_builder && <Navigator />}
            <div className="flex-grow" />
            {isPreviewLoading && <LoadingSpinner className="mr-2" />}
            {unsavedChanges && (
              <>
                <ExclamationTriangleIcon className="h-6 w-6" />
                <h5 className="pl-1 pr-3 text-xs font-bold">Unsaved&nbsp;changes</h5>
              </>
            )}
            <div className="flex items-center space-x-2">
              <Button variant="primary-inverse" type="button" disabled={false} onClick={handleClose}>
                Exit
              </Button>
              <Button
                variant="primary"
                type="button"
                Icon={CursorArrowRaysIcon}
                disabled={isSaving || !unsavedChanges}
                loading={isSaving}
                onClick={handlePublishTheme}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeEditor;
