import * as React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Typography } from '@/components/Typography';

import { Button, ButtonProps } from './Button';
import { ButtonGroup } from './ButtonGroup';

export interface WriteButtonProps {
  size?: ButtonProps['size'];
  dropdownDownDirection?: 'left' | 'right';
  dropdownClassName?: string;
  className?: string;
  buttonClassName?: string;
  buttons: Array<{
    children?: React.ReactElement;
    label: string;
    onClick: (btn: any) => void;
    helperText?: string;
    defaultSelected?: boolean;
    disabled?: boolean;
    loading?: boolean;
    button?: {
      text?: string;
      Icon?: React.ElementType;
    };
  }>;
}

export const WriteButton: React.FC<WriteButtonProps> = ({
  buttons,
  className,
  buttonClassName,
  size,
  dropdownDownDirection = 'right',
  dropdownClassName,
}) => {
  const [currentButtonIndex, setCurrentButtonIndex] = React.useState(
    Math.max(
      buttons.findIndex((b) => b.defaultSelected),
      0
    )
  );

  const currentButton = buttons[currentButtonIndex];

  if (buttons.length === 0) {
    return null;
  }

  return (
    <Menu as="div" className={cx('relative inline-block', className)}>
      {({ open }) => (
        <>
          {buttons.map((b) => b.children)}
          <div>
            <ButtonGroup className={className} variant="write" size={size}>
              <Button
                className={buttonClassName}
                Icon={currentButton.button?.Icon}
                onClick={currentButton.onClick}
                disabled={currentButton.disabled}
                loading={currentButton.loading}
              >
                {currentButton.button?.text || currentButton.label}
              </Button>
              {buttons.length > 1 && (
                <Menu.Button as={Button}>
                  <ChevronDownIcon
                    className={`w-4 h-4 transition ease-out transform duration-100 ${open && 'rotate-180'}`}
                  />
                </Menu.Button>
              )}
            </ButtonGroup>
          </div>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={cx(
                dropdownClassName,
                'z-50 absolute w-64 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                dropdownDownDirection === 'left' ? 'left-0' : 'right-0'
              )}
            >
              <div className="px-1 py-1 ">
                {buttons.map((button, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Menu.Item key={`split-button-${index}-${button.label}`}>
                    {() => (
                      <button
                        type="button"
                        className={cx(
                          'group flex flex-col rounded-md items-start w-full px-2 py-2 text-sm',
                          button.disabled ? 'cursor-not-allowed' : 'cursor-pointer text-gray-900 hover:bg-gray-100'
                        )}
                        onClick={(e) => {
                          button.onClick(e);
                          setCurrentButtonIndex(index);
                        }}
                        disabled={button.disabled}
                      >
                        <Typography as="span" token="font-medium/text/sm" className="block">
                          {button.label}
                        </Typography>
                        {button.helperText && (
                          <Typography
                            as="span"
                            token="font-normal/text/xs"
                            colorWeight="700"
                            className="text-gray-700 text-left"
                          >
                            {button.helperText}
                          </Typography>
                        )}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
