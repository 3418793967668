import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ArrowPathIcon, CheckIcon, SunIcon } from '@heroicons/react/24/outline';

import Modal from '@/components/Modal';
import Text from '@/components/Text';
import { useCurrentPublication } from '@/hooks';
import { Button } from '@/ui/Button';

import DnsRecordDisplay from '../../../../../components/_domain/CustomDomain/DnsRecordDisplay';
import ActionModal from '../../../../../components/ActionModal';
import Badge from '../../../../../components/Badge';
import EllipsisDropdown from '../../../../../components/EllipsisDropdown';
import SlideOver from '../../../../../components/SlideOver';
import { usePermissions } from '../../../../../context/permissions-context';
import {
  useCustomDomainConfigurationVerification,
  useCustomDomainToggleUsage,
  useVerifiedCustomDomains,
} from '../../../../../hooks/useCustomDomains';
import { CustomDomain, CustomDomainTypes, DnsRecord } from '../../../../../interfaces/custom_domain';
import NotEditing from '../../../Components/NotEditing';

import EmailConfig from './CustomDomainView/EmailConfig';
import RedirectConfig from './CustomDomainView/RedirectConfig';
import WebConfig from './CustomDomainView/WebConfig';

interface Props {
  domain?: CustomDomain;
  type: CustomDomainTypes;
}

const SelectedDomainDetails: React.FC<Props> = ({ domain, type }: Props) => {
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { checkPermissions } = usePermissions();
  const canCustomDomain = checkPermissions('domain_setting', 'update');
  const { data: customDomains } = useVerifiedCustomDomains();
  const verifications = {
    [CustomDomainTypes.WEB]: domain?.web_verification_pending,
    [CustomDomainTypes.REDIRECT]: domain?.web_redirect_verification_pending,
    [CustomDomainTypes.EMAIL]: domain?.email_verification_pending,
  };
  const verificationPending = verifications[type];
  const toggleUsageMutation = useCustomDomainToggleUsage();
  const [configurationModalOpen, setConfigurationModalOpen] = useState<boolean>(
    searchParams.get('configuration') === type
  );
  const { data: currentPublication } = useCurrentPublication();

  const handleModalClose = () => {
    setConfigurationModalOpen(false);
  };

  const verifyDomainSettingsMutation = useCustomDomainConfigurationVerification(type);

  const handleRemove = () => {
    if (domain) {
      toggleUsageMutation.mutate({ id: domain.id, type, enabled: false });
    }
  };

  if (!canCustomDomain) return null;

  if (!domain) {
    // If they don't have any verified domains to choose from, don't show anything here.
    if (customDomains && customDomains.length === 0) return null;

    return (
      <>
        <SlideOver
          bodyId={type}
          isOpen={isPanelOpen}
          onClose={() => setIsPanelOpen(false)}
          headerText="Select Custom Domain"
        >
          {type === CustomDomainTypes.WEB ? <WebConfig /> : ''}
          {type === CustomDomainTypes.REDIRECT ? <RedirectConfig /> : ''}
          {type === CustomDomainTypes.EMAIL ? <EmailConfig /> : ''}
        </SlideOver>
        <div className="space-y-2 my-4">
          <Button
            variant="primary-inverse"
            onClick={(e) => {
              e.stopPropagation();
              setIsPanelOpen(true);
            }}
            className="capitalize"
          >
            {`Select Custom ${type} Domain`}
          </Button>
        </div>
      </>
    );
  }

  const actionModalHtml = () => {
    const forEmail = type === CustomDomainTypes.EMAIL;
    const records = forEmail ? domain.email_authenticated_domains : domain.web_authenticated_domains;

    return (
      <div className="space-y-4 text-sm text-gray-500">
        {verificationPending ? (
          <>
            <p>
              Follow the instructions below, in order to setup{' '}
              <code className="bg-gray-100 px-2 py-1 rounded-sm">{domain.domain}</code>
            </p>
            <div>
              <p className="font-medium">Create DNS records</p>
              <p>
                Go to your DNS provider and create <strong>all</strong> of the following records with the specified
                values. Once done, click &quot;Verify Setup&quot; below to check your {type} configuration. We
                additionally will check on your behalf over the next 24 hours.
              </p>
            </div>
          </>
        ) : (
          <p>
            Below are the DNS records needed for your {type} setup. Please ensure that these DNS records remain present
            at all times.
          </p>
        )}
        <div>
          {forEmail && (
            <p>
              These records will validate that you own this domain so that our email provider can generate the
              corresponding SPF and DKIM for secure and verified sending.
            </p>
          )}
          {records.map((record: DnsRecord) => (
            <DnsRecordDisplay key={record.name} record={record} className="my-8" />
          ))}
        </div>
      </div>
    );
  };

  const badgeHtml = () => {
    if (verificationPending) {
      return (
        <Badge type="information">
          <ArrowPathIcon className="w-4 h-4 text-blue-800 mr-2" />
          Verifying Configuration
        </Badge>
      );
    }

    if (type === CustomDomainTypes.EMAIL && domain.warming_up) {
      return (
        <Badge type="information">
          <SunIcon className="w-4 h-4 text-yellow-400 mr-2" />
          Smart Warming in progress
        </Badge>
      );
    }

    return (
      <Badge type="success">
        <CheckIcon className="w-4 h-4 text-green-800 mr-2" />
        Live
      </Badge>
    );
  };

  return (
    <div className="flex justify-between">
      {verificationPending ? (
        <ActionModal
          isOpen={configurationModalOpen}
          isWorking={verifyDomainSettingsMutation.isLoading}
          onClose={handleModalClose}
          onProceed={verifyDomainSettingsMutation.mutate}
          resourceId={domain.id}
          headerText="Verify Domain Configuration"
          actionText="Verify Setup"
          modalSize="md"
        >
          {actionModalHtml()}
        </ActionModal>
      ) : (
        <Modal isOpen={configurationModalOpen} onClose={handleModalClose}>
          <div className="p-8 max-w-2xl">
            <Text size="lg" type="bold" className="text-gray-800 w-fit mb-8">
              DNS Records for {type}
            </Text>
            {actionModalHtml()}
          </div>
        </Modal>
      )}
      <NotEditing label={domain.domain} value={badgeHtml()} />
      <EllipsisDropdown
        portalMountedId={domain.id}
        options={[
          {
            label: verificationPending ? 'Verify' : 'View DNS Records',
            onClick: () => setConfigurationModalOpen(true),
          },
          ...(type === CustomDomainTypes.EMAIL && currentPublication?.on_dedicated_ip
            ? []
            : [
                {
                  label: 'Remove',
                  onClick: () => handleRemove(),
                },
              ]),
        ]}
      />
    </div>
  );
};

export default SelectedDomainDetails;
