import toast from 'react-hot-toast';

import analytics from '@/utils/analytics';

import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import { useCurrentUser } from '../../../../../context/current-user-context';
import { useSettings } from '../../../../../context/settings-context';
import { useOrganization, useUpdateOrganization, useUser } from '../../../../../hooks';
import { usePublication } from '../../../../../hooks/usePublications';
import { Section } from '../../../Components';
import AddressForm from '../../../Components/AddressForm';
import InputForm from '../../../Components/InputForm';

import DeleteOrganization from './DeleteOrganization';

const General = () => {
  const [publicationId] = useCurrentPublicationState();
  const currentPublication = usePublication(publicationId)?.data;
  const organizationQuery = useOrganization(currentPublication?.organization_id);
  const { organization } = organizationQuery?.data || {};
  const { settings } = useSettings();
  const { currentUser } = useCurrentUser();
  const userQuery = useUser(currentUser?.id);
  const { data: user } = userQuery;

  const organizationMutation = useUpdateOrganization(currentPublication?.organization_id);

  const handleUpdate = async ({ street, city, state, zip, country }: any) => {
    const addressItems = [street, city, state, zip, country].filter((item) => Boolean(item)).length;
    const hasAtleastOneItem = Boolean(addressItems);
    const isMissingAnyItems = !street || !city || !state || !zip || !country;

    if (hasAtleastOneItem && isMissingAnyItems) {
      toast.error('Please fill out all address fields to save!');
    } else {
      await organizationMutation.mutateAsync({
        city,
        state,
        country,
        street_address: street,
        zip_code: zip,
      });
    }
  };

  const handleDelete = async () => {
    await organizationMutation.mutateAsync({
      city: '',
      state: '',
      country: '',
      street_address: '',
      zip_code: '',
    });
  };

  return (
    <>
      <Section
        title="General"
        description="Manage your general website settings below."
        scrollToId="general"
        isLoading={!organization}
      >
        <InputForm
          label="Company Name"
          placeholder="beehiiv"
          value={organization?.name || ''}
          onSave={(val: string) => {
            analytics.track('Company Name Change');
            return organizationMutation.mutateAsync({ name: val });
          }}
          helperText="Note: Your company name is not currently displayed anywhere but here."
          isSaving={organizationMutation.isLoading}
        />
        <AddressForm
          label="Address"
          id={organization?.street_address ? 'org-address' : null}
          street={organization?.street_address || ''}
          country={organization?.country || ''}
          countryName={organization?.country_name || ''}
          zip={organization?.zip_code || ''}
          state={organization?.state || ''}
          city={organization?.city || ''}
          onSave={(address: any) => handleUpdate(address)}
          onDelete={handleDelete}
          isSaving={organizationMutation.isLoading}
        />
      </Section>
      {(settings?.system_admin || user?.owns_organization) && (
        <Section
          title="Delete Organization"
          description="You may delete your organization at any time. This will delete all of your publications and all of your team members."
          scrollToId="delete-organization"
          hasDivider={false}
        >
          <DeleteOrganization />
        </Section>
      )}
    </>
  );
};

export default General;
