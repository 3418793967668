import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';

const useDeleteStripeConnectAccount = () => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    publication_id: currentPublicationId,
  };

  return useMutation(() => api.delete('/boosts/monetize/stripe_connect_account', { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.message || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'monetize', 'stripe_connect_account']);
      toast.success('Account Disconnected!');
    },
  });
};

export default useDeleteStripeConnectAccount;
