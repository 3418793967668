import styled from 'styled-components';

import { colors } from '../TiptapEditor/lib/colors';

const Wrapper = styled.div`
  background: ${colors.white};
  display: grid;
  grid-template-columns: 16.25rem 1fr;
  height: 100%;
  overflow: hidden;
`;

const NavigationContainer = styled.div`
  background: ${colors.white};
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;

const Styled = {
  Wrapper,
  NavigationContainer,
};

export default Styled;
