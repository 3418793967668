import React from 'react';

import { Textarea } from '@/components/Form';
import Input from '@/components/Form/Input';
import Select from '@/components/Form/Select';
import { Option } from '@/interfaces/general';

interface Props {
  segmentName: string;
  segmentDescription: string;
  onSegmentNameChange: (value: string) => void;
  onSegmentDescriptionChange: (value: string) => void;
  segmentType?: string;
  segmentOptions?: Option[];
  onSegmentTypeChange?: (value: string) => void;
}

const MetadataForm = ({
  segmentName,
  segmentDescription,
  segmentType,
  segmentOptions,
  onSegmentNameChange,
  onSegmentDescriptionChange,
  onSegmentTypeChange,
}: Props) => {
  const onSelectSegmentType = (selectedName: string, selectedValue: string) => {
    if (onSegmentTypeChange) {
      onSegmentTypeChange(selectedValue);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => onSegmentNameChange(e.target.value);

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    onSegmentDescriptionChange(e.target.value);

  return (
    <div className="lg:w-1/2">
      <div className="space-y-6">
        <Input
          name="name"
          labelText="Name"
          placeholderText="Name of segment"
          type="text"
          value={segmentName}
          onChange={handleNameChange}
          required
        />

        <Textarea
          name="description"
          value={segmentDescription}
          labelText="Description"
          placeholderText="Short description of your segment …"
          onChange={handleDescriptionChange}
        />

        {segmentType && segmentOptions && onSelectSegmentType && (
          <Select
            name="segment_type"
            labelText="Segment Type"
            helperText="Static are only generated once. Dynamic are regenerated daily."
            value={segmentType}
            onSelect={onSelectSegmentType}
            options={segmentOptions}
            required
          />
        )}
      </div>
    </div>
  );
};

export default MetadataForm;
