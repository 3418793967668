import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import Card from '@/components/Card';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import useCreateSvixEndpoint from '@/hooks/webhooks/useCreateSvixEndpoint';
import { WebhookEndpointCreatePayload } from '@/interfaces/webhooks/endpoint';
import { Button } from '@/ui/Button';
import validateUrl from '@/utils/validateUrl';

import Help from '../../components/Help';

import StepsOverview from './Steps/Overview';
import { StepErrors, WizardSteps } from './Steps/types';
import { DetailsStep, EventsStep, ReviewStep } from './Steps';

const Create = () => {
  const [endpoint, setEndpoint] = useState<WebhookEndpointCreatePayload>({
    description: '',
    url: '',
    event_types: [],
    disabled: false,
  });
  const [errors, setErrors] = useState<StepErrors>({});
  const [step, setStep] = useState<number>(WizardSteps.DETAILS);
  const steps = [
    <DetailsStep endpoint={endpoint} errors={errors} onChange={(payload) => setEndpoint(payload)} />,
    <EventsStep endpoint={endpoint} errors={errors} onChange={(payload) => setEndpoint(payload)} />,
    <ReviewStep endpoint={endpoint} errors={errors} />,
  ];
  const stepTitles = ['Details', 'Events', 'Review'];
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const { mutateAsync: createEndpoint, isLoading: isCreating } = useCreateSvixEndpoint({
    onSuccess: () => {
      toast.success('Webhook endpoint created successfully');
      queryClient.invalidateQueries(['publications', currentPublicationId, 'webhooks', 'endpoints']);
      navigate('/settings/integrations/webhooks');
    },
  });

  const checkErrors = () => {
    let tmpErrors = { ...errors };
    if (step === WizardSteps.DETAILS) {
      if (!validateUrl(endpoint.url)) {
        tmpErrors = { ...tmpErrors, url: 'Please enter a valid URL' };
      } else {
        delete tmpErrors.url;
      }
    } else if (step === WizardSteps.EVENTS) {
      if (!endpoint.event_types.length) {
        tmpErrors = { ...tmpErrors, event_types: 'Please select at least one event type' };
      } else {
        delete tmpErrors.event_types;
      }
    }
    return tmpErrors;
  };

  const handleStepChange = (newStep: number) => {
    // If going back, just change the step and reset the errors
    if (newStep < step) {
      setErrors({});
      return setStep(newStep);
    }

    const tmpErrors = checkErrors();
    const errorCount = Object.keys(tmpErrors).length;
    if (errorCount === 0) return setStep(newStep);

    return setErrors(tmpErrors);
  };

  const handleSave = () => {
    createEndpoint(endpoint);
  };

  return (
    <div className="w-full px-4">
      <div className="mx-auto max-w-4xl w-full space-y-6">
        <div className="mt-8 flex flex-row items-end justify-between">
          <h2 className="text-2xl font-bold">New Endpoint</h2>
          <Help />
        </div>
        <div className="grid grid-cols-8 lg:grid-cols-12 gap-4">
          <div className="col-span-8 space-y-4">
            <Card>
              <h2 className="text-2xl font-bold">
                <span className="text-gray-400">Step {step}: </span>
                <span>{stepTitles[step - 1]}</span>
              </h2>
            </Card>
            <Card className="space-y-8">
              {steps[step - 1]}
              <div className="flex flex-row justify-between space-x-2 items-center">
                {step !== steps.length ? (
                  <Button
                    variant="primary"
                    onClick={() => handleStepChange(step + 1)}
                    className="space-x-2"
                    Icon={ChevronRightIcon}
                    iconRight
                  >
                    Next
                  </Button>
                ) : (
                  <Button disabled={isCreating} loading={isCreating} variant="primary" onClick={() => handleSave()}>
                    Save
                  </Button>
                )}
                <a href="/settings/integrations/webhooks#Webhooks" className="text-primary-400 hover:text-primary-500">
                  Cancel
                </a>
              </div>
            </Card>
          </div>
          <div className="hidden lg:block col-span-4">
            <StepsOverview step={step} onClick={(arg) => handleStepChange(arg)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
