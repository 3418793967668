import { PropsWithChildren, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { SimpleSelect } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import { useStandardAccount } from '@/hooks/stripe';
import useReconcile from '@/hooks/stripe/useReconcile';
import { StripeStandardAccountStatus } from '@/hooks/stripe/useStandardAccount';
import Countries from '@/interfaces/boosts/countries';
import Section from '@/routes/settings/_components/Section';
import { Button } from '@/ui/Button';

import CancellationSection from './CancellationSection';
import StripeWarning from './StripeWarning';

type Props = PropsWithChildren<{}>;

const EnsureStripeConnection: React.FC<Props> = ({ children }) => {
  const { data: account, isLoading, isError, refetch } = useStandardAccount();
  const [country, setCountry] = useState('US');

  const isConnected = account && account.account_status !== StripeStandardAccountStatus.MISSING;
  const isOnboarding = account?.account_status === StripeStandardAccountStatus.ONBOARDING;
  const hasMissingProducts = account?.account_status === StripeStandardAccountStatus.MISSING_PRODUCTS;

  const countryOptions = useMemo(
    () =>
      Object.keys(Countries).map((key) => {
        return { value: key, label: Countries[key] };
      }),
    []
  );

  const createStripeProductsMutation = useReconcile({
    onSuccess: () => {
      toast.success('Products created successfully!');
      refetch();
    },
  });

  return (
    <LoadingBox backgroundClassName="bg-transparent" isLoading={isLoading} isError={isError}>
      {!isConnected && (
        <Section
          title="Tier Configuration"
          description="Customize plans and pricing for your subscriptions."
          maxWidthClassName="max-w-4xl"
          scrollToId="stripe-account"
        >
          <StripeWarning mutationProps={{ country }}>
            In order to enable multiple tier subscription payments, you need to connect a Stripe account to beehiiv.
            Choose your country from the list below, then select &apos;Connect to Stripe&apos;.
            <div className="my-4 text-surface-900">
              <SimpleSelect
                options={countryOptions}
                value={country}
                onSelect={(_name, value) => setCountry(value)}
                labelText="Country"
                required
                name="country"
                className="w-full"
              />
            </div>
          </StripeWarning>
        </Section>
      )}

      {isConnected && isOnboarding && (
        <div className="flex flex-col space-y-10 items-center px-4">
          <Section
            title="Tier Configuration"
            description="Customize plans and pricing for your subscriptions."
            maxWidthClassName="max-w-4xl"
            scrollToId="stripe-account"
          >
            <StripeWarning>
              Your Stripe account is connected, but they need more information to finish verifying your account. Click
              below to finish setting up your account.
            </StripeWarning>
          </Section>
          <CancellationSection />
        </div>
      )}

      {isConnected && hasMissingProducts && (
        <Section
          title="Tier Configuration"
          description="Customize plans and pricing for your subscriptions."
          maxWidthClassName="max-w-4xl"
          scrollToId="stripe-account"
        >
          <StripeWarning hideButton>
            Your Stripe account is connected, but one or more of the required beehiiv products are missing. Click below
            to create the missing products.
            <div className="my-4 text-surface-900">
              <Button
                variant="primary"
                type="button"
                loading={createStripeProductsMutation.isLoading}
                onClick={() => createStripeProductsMutation.mutate()}
              >
                Recreate beehiiv products
              </Button>
            </div>
          </StripeWarning>
        </Section>
      )}

      {isConnected && !isOnboarding && !hasMissingProducts && children}
    </LoadingBox>
  );
};

export default EnsureStripeConnection;
