import { useState } from 'react';

import { SimpleSelect } from '@/components/Form';
import { MultiActionModal, Step } from '@/components/MultiActionModal';
import useCreateStripeConnectAccount from '@/hooks/boosts/monetize/useCreateStripeConnectAccount';
import { StripeAccountStatus } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import Countries from '@/interfaces/boosts/countries';
import analytics from '@/utils/analytics';

interface Props {
  boostsAccountStatus: StripeAccountStatus;
  isOpen: boolean;
  onClose: () => void;
}

const useStripeStep = ({
  boostsAccountStatus,
  onClose,
}: {
  boostsAccountStatus: StripeAccountStatus;
  onClose: () => void;
}) => {
  const {
    mutateAsync: createStripeConnectAccount,
    isLoading: isWorking,
    isError,
  } = useCreateStripeConnectAccount({
    onSuccess: (res) => {
      analytics.track('Connected a Stripe Account');
      window.open(`${res.url}`, '_blank');
    },
  });
  const [country, setCountry] = useState('US');

  const title = {
    [StripeAccountStatus.MISSING]: 'Connect Stripe to continue',
    [StripeAccountStatus.PENDING]: 'Waiting on Stripe',
    [StripeAccountStatus.INACTIVE]: 'Stripe needs more information',
    [StripeAccountStatus.ACTIVE]: "You're all set!",
  }[boostsAccountStatus];

  const countryOptions = Object.keys(Countries).map((key) => {
    return { value: key, label: Countries[key] };
  });

  const children = {
    [StripeAccountStatus.MISSING]: (
      <>
        <p>
          In order to earn money with beehiiv&apos;s monetization opportunities, you need to first connect with Stripe
          so you can get paid.
        </p>
        <p>Choose your country from the list below, then select &apos;Continue&apos;.</p>
        <SimpleSelect
          options={countryOptions}
          value={country}
          onSelect={(_name, value) => setCountry(value)}
          labelText="Country"
          required
          name="country"
          className="w-full"
        />
      </>
    ),
    [StripeAccountStatus.PENDING]:
      'Your Stripe account is connected, but we are waiting on them to finish verifying your information. This usually only takes a few minutes.',
    [StripeAccountStatus.INACTIVE]:
      'Your Stripe account is connected, but they need more information to continue verifying your account.',
    [StripeAccountStatus.ACTIVE]: 'Your Stripe account is active. You can update your account information at any time.',
  }[boostsAccountStatus];

  let actions = [
    {
      actionText: 'Continue',
      onClick: () => createStripeConnectAccount({ country }),
      isWorking,
      variant: 'primary',
    },
  ] as Step['actions'];

  if (boostsAccountStatus === StripeAccountStatus.ACTIVE) {
    actions = [
      {
        actionText: 'Sounds good',
        onClick: () => onClose(),
        variant: 'primary',
      },
    ];
  }

  if (boostsAccountStatus === StripeAccountStatus.MISSING) {
    actions = [
      {
        actionText: 'Create new account',
        onClick: () => createStripeConnectAccount({ country }),
        isWorking,
        variant: 'primary',
      },
    ];
  }

  return {
    title,
    children,
    actions,
    isError,
  };
};

const ConnectToStripe = ({ boostsAccountStatus, isOpen, onClose }: Props) => {
  const { title, children, actions } = useStripeStep({
    boostsAccountStatus,
    onClose,
  });

  const steps = [
    {
      title,
      actions,
      children,
    },
  ] as Step[];

  return (
    <MultiActionModal
      bodyId="boosts-application"
      isOpen={isOpen}
      onClose={onClose}
      resourceId="connect_to_stripe"
      steps={steps}
      modalSize="lg"
    />
  );
};

export default ConnectToStripe;
