import { useNavigate } from 'react-router-dom';
import { BanknotesIcon } from '@heroicons/react/24/outline';

import SubscriptionStateEvents from '@/components/charts/SubscriptionStateEvents/';
import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import { useCurrentPublication, useRequireSetting } from '@/hooks';
import { useAudience } from '@/hooks/useDashboard';
import { AudienceEndpoints } from '@/hooks/useDashboard/useAudience';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { useTutorial } from '@/hooks/useTutorials';
import { Setting } from '@/interfaces/setting';
import { TutorialType } from '@/interfaces/tutorial';
import { Button } from '@/ui/Button';
import { TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

const MonetizeSubscriptions = () => {
  useRequireSetting({
    setting: Setting.PREMIUM_SUBSCRIPTIONS,
    message: '',
    redirectTo: '/monetize/subscriptions/overview',
  });

  const { data: currentPublication } = useCurrentPublication();
  const navigate = useNavigate();
  const tutorial = useTutorial(TutorialType.PREMIUM_SUBSCRIPTIONS);

  const { period, TimePeriodSelect } = useTimePeriodSelect({ defaultValue: TimePeriod.ALL_TIME });

  const { isLoading, data } = useAudience({
    endpoint: AudienceEndpoints.ALL_SUBCRIPTION_EVENTS,
    timePeriod: period as keyof typeof timePeriodLabels,
  });

  return (
    <>
      <PageHeading
        title="Premium Subscriptions"
        description="Insight into your premium subscriptions."
        tutorial={tutorial}
      >
        <Button variant="primary" onClick={() => navigate('/settings/publication/premium')} Icon={BanknotesIcon}>
          Edit Premium Settings
        </Button>
      </PageHeading>
      <SubscriptionStateEvents
        isPremiumPublication={currentPublication?.is_premium_enabled}
        isLoading={isLoading}
        data={data}
        actionChildren={<TimePeriodSelect />}
      />
    </>
  );
};

export default MonetizeSubscriptions;
