import { FC, memo, useState } from 'react';

import PreviewModal from '@/components/PreviewModal';
import { TiptapEditor } from '@/components/TiptapEditor';
import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { GlobalStyles } from '@/components/TiptapEditor/lib/GlobalStyles';
import { useSettings } from '@/context/settings-context';
import { usePublication } from '@/hooks/usePublications';
import { IData } from '@/interfaces/general';
import { Button } from '@/ui/Button';

interface Props {
  className?: string;
  publicationId: string;
  name: string;
  initialValue: any;
  labelText: string;
  onChange: (value: any | undefined) => void;
  isLoading?: boolean;
  required?: boolean;
  onFetchPreview: (platform: string, onFetch: Function) => void;
  platforms?: string[];
  fetchPreviewDisabled?: boolean;
  allowPolls?: boolean;
  allowAds?: boolean;
  description?: string;
  shouldAutoFocus?: boolean;
}

const MemoizedTiptapEditor = memo(TiptapEditor);

const RichContent: FC<Props> = (props: Props) => {
  const {
    className,
    name,
    labelText,
    initialValue,
    onChange,
    required,
    publicationId,
    onFetchPreview,
    platforms,
    fetchPreviewDisabled,
    allowPolls = false,
    allowAds = false,
    isLoading = false,
    description,
    shouldAutoFocus = true,
  } = props;
  const { settings } = useSettings();
  const { data: publication } = usePublication(publicationId);
  const [showPreview, setShowPreview] = useState(false);

  const handleTiptapChange = (data: any) => {
    onChange(data.editor.getJSON());
  };

  const onShowPreview = () => {
    setShowPreview(true);
  };

  const onHidePreview = () => {
    setShowPreview(false);
  };

  const fetchPreview = (platform: string, advancedParams: IData, onFetch: Function) => {
    if (showPreview) {
      onFetchPreview(platform, onFetch);
    }
  };

  if (!settings || !publication) {
    return null;
  }

  return (
    <div className={className}>
      {showPreview && (
        <PreviewModal
          showSubscriberSelect
          fetchPreview={fetchPreview}
          active={showPreview}
          onClose={onHidePreview}
          tabs={platforms}
        />
      )}
      <div className="flex justify-between items-end mb-2">
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {labelText}
          {required ? ' *' : ''}
        </label>
        <Button
          variant="primary-inverse"
          size="xs"
          type="button"
          onClick={onShowPreview}
          disabled={fetchPreviewDisabled}
        >
          Preview
        </Button>
      </div>
      {!!description && (
        <p className="text-xs text-gray-500" style={{ marginBottom: '0.5em' }}>
          {description}
        </p>
      )}
      <div className="relative rounded-md border border-gray-300 shadow-sm bg-white">
        <div className="pt-12 overflow-y-auto" style={{ minHeight: '44rem' }}>
          {!isLoading && (
            <PublicationProvider id={publicationId}>
              <GlobalStyles colors={publication.color_palette} />
              <MemoizedTiptapEditor
                onUpdate={handleTiptapChange}
                publicationId={publicationId}
                settings={settings}
                content={initialValue}
                allowPolls={allowPolls}
                allowAds={allowAds}
                shouldAutoFocus={shouldAutoFocus}
              />
            </PublicationProvider>
          )}
        </div>
      </div>
    </div>
  );
};

RichContent.defaultProps = {
  className: undefined,
  required: false,
  platforms: ['Email'],
  fetchPreviewDisabled: false,
};

export default RichContent;
