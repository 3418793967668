import { FC, Fragment, useRef } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Dialog, Transition } from '@headlessui/react';
import cx from 'classnames';

import Input from '@/components/Form/Input';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Post } from '@/interfaces/post';
import { PostTemplate } from '@/interfaces/post_template';
import api from '@/services/swarm';
import { Button, ButtonProps } from '@/ui/Button';

export interface Props {
  isOpen: boolean;
  isWorking?: boolean;
  onClose: () => void;
  post: Post;
  modalHeightClassName?: string;
  disabled?: boolean;
  buttonType?: ButtonProps['variant'];
}

const SaveAsTemplateModal: FC<Props> = ({
  post,
  isOpen,
  isWorking,
  onClose,
  modalHeightClassName,
  disabled = false,
  buttonType = 'primary',
}: Props) => {
  const cancelRef = useRef(null);
  const [publicationId] = useCurrentPublicationState();
  const { id: postId } = post;

  const saveAsTemplate = useMutation<PostTemplate, unknown, { formData: FormData }>(
    ({ formData }) => {
      const name = formData.get('post_template[name]') || 'Untitled template';
      return api
        .post('/post_templates', {
          publication_id: publicationId,
          from_post_id: postId,
          post_template: {
            name,
          },
        })
        .then((res) => res.data.id);
    },
    {
      onSuccess: () => {
        toast.success('Template saved');
        onClose();
      },
      onError: (err) => {
        toast.error('There was an error saving the template');
        console.log(err);
      },
    }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    saveAsTemplate.mutate({ formData });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed overflow-y-auto inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-20" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <section className="modal z-50 fixed inset-0 w-screen overflow-auto">
            <div className="flex min-h-full justify-center p-4 items-center">
              <Dialog.Panel
                className="model-guts flex flex-col mx-auto bg-white rounded-lg text-left shadow-xl w-full md:max-w-xl"
                id="add-guest-author-modal-panel"
              >
                <form onSubmit={handleSubmit}>
                  <input type="hidden" value={publicationId} name="publication_id" />
                  <section className="p-6 overflow-y-auto">
                    <div className={cx('flex flex-col sm:flex-row gap-4', modalHeightClassName)}>
                      <div className="w-full flex flex-col">
                        <div className="w-full flex flex-row space-x-4">
                          <div className="flex flex-col">
                            <p className="mb-1 text-lg font-semibold">New Template</p>
                            <p className="text-sm text-gray-500 mt-2 max-w-xl">
                              Templates are the easiest way to jump start your writing. Your post will be saved as a
                              &apos;template,&apos; and you can use it as a starting point rather than starting from
                              scratch.
                            </p>
                          </div>
                        </div>
                        <div className="mt-10">
                          <Input
                            labelText="Template name"
                            type="text"
                            label="Full name*"
                            name="post_template[name]"
                            placeholder="Name"
                            helperText="You can always change this later"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="flex justify-between items-center bg-[#F9FAFB] rounded-b-lg py-4 px-6 mt-auto">
                    <div className="flex items-center gap-3 ml-auto">
                      <Button ref={cancelRef} type="button" variant="primary-inverse" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button variant={buttonType} type="submit" disabled={disabled} loading={isWorking}>
                        Save template
                      </Button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </section>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default SaveAsTemplateModal;
