import { useState } from 'react';

import TabNavigation from '@/components/TabNavigation';

import EmailMessageList from './EmailMessageList';
import PostList from './PostList';

interface Props {
  pollId: string;
  publishedCount: number | undefined;
}

const AppearsIn: React.FC<Props> = ({ pollId, publishedCount }) => {
  const [activeTab, setActiveTab] = useState('posts');

  const tabs = [
    {
      name: 'posts',
      label: 'Posts',
      selected: activeTab === 'posts',
      onSelect: () => setActiveTab('posts'),
    },
    {
      name: 'email_messages',
      label: 'Other Emails',
      selected: activeTab === 'email_messages',
      onSelect: () => setActiveTab('email_messages'),
    },
  ];

  return (
    <div className="bg-white p-4 rounded border space-y-4">
      {publishedCount && (
        <p className="font-bold mb-2">
          Published {publishedCount} time{publishedCount === 1 ? '' : 's'}
        </p>
      )}
      <TabNavigation tabs={tabs} variant="secondary" />
      {activeTab === 'posts' && <PostList pollId={pollId} />}
      {activeTab === 'email_messages' && <EmailMessageList pollId={pollId} />}
    </div>
  );
};

export default AppearsIn;
