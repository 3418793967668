import { useMemo } from 'react';

import { useSettings } from '@/context/settings-context';
import { Setting, Settings } from '@/interfaces/setting';

const useSetting = <T extends Setting>({ setting, defaultValue }: { setting: T; defaultValue?: Settings[T] }) => {
  const { settings } = useSettings();

  const value = useMemo(() => {
    return settings?.[setting] ?? defaultValue;
  }, [settings, setting, defaultValue]);

  return value;
};

export default useSetting;
