import { useState } from 'react';
import toast from 'react-hot-toast';

import ActionsDropdown from '@/components/ActionsDropdown';
import { ActionOption } from '@/components/ActionsDropdown/ActionsDropdown';
import Tooltip from '@/components/Tooltip';
import useUpdateBoostAgreement from '@/hooks/boosts/grow/useUpdateBoostAgreement';
import { BoostAgreementCreateWithReferralCount, BoostAgreementStatus } from '@/interfaces/boosts/grow/boost_agreement';

import ReportPublicationModal from './ReportPublicationModal';

interface Props {
  boostAgreement: BoostAgreementCreateWithReferralCount;
  onSuccess: () => void;
  canReport?: boolean;
}

const BoostAgreementActionsDropdown = ({ boostAgreement, onSuccess, canReport = true }: Props) => {
  const [showReportModal, setShowReportModal] = useState(false);

  const { status, id, boost_offer: boostOffer } = boostAgreement;

  const { mutateAsync: updateBoostAgreement } = useUpdateBoostAgreement({
    boostOfferId: boostOffer.id,
    onSuccess: () => {
      onSuccess?.();
      toast.success('Boost agreement updated.');
    },
  });

  const handleTransition = (boostAgreementId: string, transition_to: BoostAgreementStatus) => {
    updateBoostAgreement({ boostAgreementId, transition_to });
  };

  const areActionsDisabled = boostAgreement.is_reported === true;

  const actions: ActionOption[][] = [[]];

  if (status === BoostAgreementStatus.ACTIVE) {
    actions[0].push({
      name: 'Pause',
      isVisible: () => true,
      onClick: () => handleTransition(id, BoostAgreementStatus.PAUSED),
    });
  } else if (status === BoostAgreementStatus.INACTIVE) {
    actions[0].push({
      name: 'Archive',
      isVisible: () => true,
      onClick: () => handleTransition(id, BoostAgreementStatus.ARCHIVED),
    });
  } else if (status === BoostAgreementStatus.PAUSED) {
    actions[0].push({
      name: 'Resume',
      isVisible: () => true,
      onClick: () => handleTransition(id, BoostAgreementStatus.ACTIVE),
    });
    actions[0].push({
      name: 'Deactivate',
      isVisible: () => true,
      onClick: () => handleTransition(id, BoostAgreementStatus.INACTIVE),
    });
  }

  if (canReport) {
    actions[0].push({
      name: 'Report',
      isVisible: () => true,
      onClick: () => setShowReportModal(true),
    });
  }

  const handleClickConfirm = async () => {
    setShowReportModal(false);
    onSuccess();
  };

  if (actions[0].length === 0) {
    return null;
  }

  // Pending, Rejected, and Archived states have no actions from this page.
  return (
    <>
      <ReportPublicationModal
        isVisible={showReportModal}
        boostAgreementId={boostAgreement.id}
        recommendingPublication={boostAgreement.recommending_publication}
        onConfirm={handleClickConfirm}
        onClose={() => setShowReportModal(false)}
      />
      <Tooltip
        tooltipClass="text-center"
        id={`disabled-boost-actions-tooltip-${boostAgreement.id}`}
        text="You have reported the Publication"
        showIcon={false}
        autoWidth={false}
        isEnabled={areActionsDisabled}
      >
        <ActionsDropdown
          disabled={areActionsDisabled}
          node={boostAgreement}
          actions={actions}
          dropdownClass="left-0 lg:left-auto lg:right-0"
        />
      </Tooltip>
    </>
  );
};

export default BoostAgreementActionsDropdown;
