import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/24/outline';

import analytics from '@/utils/analytics';

import Modal from '../../../../../../components/Modal';
import RaiseSubscriberImportLimitApprovalRequestForm from '../../../../../../components/RaiseSubscriberImportLimitApprovalRequestForm';
import Text from '../../../../../../components/Text';
import { useCurrentPublicationState } from '../../../../../../context/current-publication-context';
import { useSettings } from '../../../../../../context/settings-context';
import { useCurrentPublication, useOrganization } from '../../../../../../hooks';
import { ApprovalRequest } from '../../../../../../interfaces/approval_request';
import api from '../../../../../../services/swarm';
import IdentityVerification from '../IdentityVerification';

type ApprovalRequestApiParams = {
  publication_id: string;
  approval_request: ApprovalRequest;
};

const RaiseImportLimit = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const { settings } = useSettings();
  const formEnabled = settings?.raise_subscriber_import_limit_form;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { data: currentPublication } = useCurrentPublication();
  const organizationQuery = useOrganization(currentPublication?.organization_id);
  const { organization } = organizationQuery?.data || {};
  const requiresIdentityVerification = Boolean(organization?.requires_stripe_identity_verification);

  const createApprovalRequest = useMutation(
    (approvalRequestData: ApprovalRequestApiParams) => api.post('/approval_requests', approvalRequestData),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        analytics.track('Increased Import Limit');
        toast.success('Request submitted successfully');
        setFormSubmitted(true);
      },
    }
  );

  const onFormSubmit = async (approvalRequestData: ApprovalRequest) => {
    const requestParams = {
      publication_id: currentPublicationId,
      approval_request: approvalRequestData,
    };

    await createApprovalRequest.mutateAsync(requestParams);
    setFormSubmitted(true);
  };

  const onSupport = () => setIsModalOpen(true);

  const closeForm = () => {
    setIsModalOpen(false);
    setFormSubmitted(false);
  };

  const modalHtml = () => (
    <Modal
      isOpen={isModalOpen}
      includeCloseButton={false}
      onClose={() => setIsModalOpen(false)}
      className="bg-white rounded-t-lg py-6 px-4 space-y-3 max-w-lg w-full z-50"
    >
      <>
        {formSubmitted ? (
          <>
            <div className="flex justify-between mb-4">
              <Text size="lg" type="bold" className="text-gray-800 w-fit">
                Limit increase requested
              </Text>
              <div>
                <button
                  className="rounded-sm p-1 border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                  type="button"
                  onClick={closeForm}
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            </div>
            <Text size="sm" className="text-gray-600">
              Your request has been created successfully. A member of our support staff will respond to your request
              within <b>48 - 72 hours</b> hours, pending your plan tier.
            </Text>
            {requiresIdentityVerification && (
              <Text size="sm" className="text-gray-600 mt-4">
                Do you need your limit increased sooner? Go back, <b>Start Stripe Identity Verification</b> and your
                limit will be increased within 5 minutes.
              </Text>
            )}
          </>
        ) : (
          <>
            <div className="flex justify-between mb-6">
              <Text size="lg" type="bold" className="text-gray-800 w-fit">
                Request import limit increase
              </Text>
              <div>
                <button
                  className="rounded-sm p-1 border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                  type="button"
                  onClick={closeForm}
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            </div>
            <RaiseSubscriberImportLimitApprovalRequestForm onSubmit={onFormSubmit} />
          </>
        )}
      </>
    </Modal>
  );

  return (
    <>
      {formEnabled && modalHtml()}

      {requiresIdentityVerification ? (
        <IdentityVerification onSupport={onSupport} />
      ) : (
        <Text type="body" size="sm">
          Do you need to import more subscribers?{' '}
          {formEnabled ? (
            <button type="button" className="text-primary-500 hover:text-primary-600" onClick={onSupport}>
              Complete this form
            </button>
          ) : (
            <Link reloadDocument to="/?new_support_ticket" className="text-primary-500 hover:text-primary-600">
              Contact support
            </Link>
          )}{' '}
          and we&apos;ll help increase your subscriber import limit.
        </Text>
      )}
    </>
  );
};

export default RaiseImportLimit;
