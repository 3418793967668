import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';
import analytics from '@/utils/analytics';

import { Input, Textarea } from '../../components/Form';
import PageHeading from '../../components/Layout/PageLayout/PageHeading';
import { useCurrentPublicationState } from '../../context/current-publication-context';
import { useCopyToClipboard } from '../../hooks';
import { useTutorial } from '../../hooks/useTutorials';
import { TutorialType } from '../../interfaces/tutorial';

const platformSubstitutions = {
  beehiiv: '{{email}}',
  ActiveCampaign: '%EMAIL%',
  AWeber: '{!email}',
  ConvertKit: '{{subscriber.email_address}}',
  Drip: '{{subscriber.email}}',
  HubSpot: '{{contact.email}}',
  Klaviyo: '{{email}}',
  Mailchimp: '*|EMAIL|*',
  'Campaign Monitor': '[email]',
  MailerLite: '{$email}',
  'Constant Contact': '{{email}}',
  PostUp: '[-email-]',
  Sailthru: '{email}',
  Other: '<email>',
};

type Platform = keyof typeof platformSubstitutions;

const Tip = ({ selectedPlatform }: { selectedPlatform: string }) => {
  switch (selectedPlatform) {
    case 'Other':
      return (
        <div>
          Any platform that supports dynamic content can use beehiiv magic links. If the publisher is using a platform
          not listed above, simply replace the{' '}
          <code className="font-bold text-sm bg-gray-100 rounded px-0.5 text-primary-500">&lt;email&gt;</code>{' '}
          placeholder in the URL above with whatever is appropriate based on the platform&apos;s documentation.
        </div>
      );
    case 'beehiiv':
      return (
        <div>
          <div>
            Any publisher that uses <strong>{selectedPlatform}</strong> can drop this link into their newsletter for
            easy, one-click signups. Need more in-depth tracking? Add UTM parameters and more in the advanced options.
            &rarr;
          </div>
          <br />
          <div className="italic">
            Hint: beehiiv creators can also use{' '}
            <Link to="/recommendations" className="text-gray-900 underline font-semibold">
              recommendations
            </Link>{' '}
            directly in the editor for a nicely styled one-click signup button.
          </div>
        </div>
      );
    default:
      return (
        <div>
          Any publisher that uses <strong>{selectedPlatform}</strong> can drop this link into their newsletter for easy,
          one-click signups. Need more in-depth tracking? Add UTM parameters and more in the advanced options. &rarr;
        </div>
      );
  }
};

const MagicLinksView = () => {
  const tutorial = useTutorial(TutorialType.MAGIC_LINKS);

  const [currentPublicationId] = useCurrentPublicationState();
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>('beehiiv');
  const [redirectTo, setRedirectTo] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');

  const params: { [key: string]: string } = {
    email: platformSubstitutions[selectedPlatform],
    redirect_to: encodeURIComponent(redirectTo).replace(
      encodeURIComponent(platformSubstitutions[selectedPlatform]),
      platformSubstitutions[selectedPlatform]
    ),
    utm_source: encodeURIComponent(utmSource),
    utm_medium: encodeURIComponent(utmMedium),
    utm_campaign: encodeURIComponent(utmCampaign),
  };

  const query = Object.keys(params)
    .filter((key) => params[key])
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  const host = window.env.REACT_APP_MAGIC_LINK_HOST;
  const link = `${host}v1/${currentPublicationId}?${query}`;

  const copy = useCopyToClipboard();

  const handleReset = () => {
    setRedirectTo('');
    setUtmSource('');
    setUtmMedium('');
    setUtmCampaign('');
  };

  return (
    <>
      <PageHeading
        title="Magic Links"
        description="Build a custom magic link to boost conversions with one-click subscribe links"
        tutorial={tutorial}
      />
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 align-start">
        <div className="space-y-4 col-span-2">
          <Textarea className="break-all" readOnly value={link} name="magic_link" labelText="Your custom magic link" />
          <div className="bg-gray-200 rounded w-full flex p-4 space-x-4 border">
            <div>💡</div>
            <div className="text-gray-800">
              <Tip selectedPlatform={selectedPlatform} />
            </div>
          </div>
          <Button
            Icon={ClipboardDocumentIcon}
            onClick={() => {
              analytics.track('Copied Magic Link');
              copy({ text: link, onSuccessText: 'Copied!' });
            }}
          >
            Copy to clipboard
          </Button>
        </div>
        <div className="space-y-4">
          <div className="space-y-4">
            <Dropdown
              fullWidth
              name="platform"
              label="Platform"
              helperText="Select the platform used by the newsletter where you want to send your magic link"
              value={selectedPlatform}
              onSelect={(_, value) => {
                analytics.track('Selected a Platform');
                setSelectedPlatform(value as Platform);
              }}
              placeholderText="Select a platform"
              options={Object.keys(platformSubstitutions).map((platform) => ({ value: platform, label: platform }))}
              matchWidth
            />
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="py-2 text-sm flex items-center space-x-1">
                    <span>Advanced options</span>
                    <ChevronDownIcon
                      className={cx(
                        'inline w-5 h-5 text-gran-600 translate transform transition duration-50 ease-out',
                        open && 'rotate-180'
                      )}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="space-y-4">
                    <Input
                      value={utmSource}
                      name="utm_source"
                      labelText="UTM Source"
                      onChange={(e) => setUtmSource(e.target.value)}
                    />
                    <Input
                      value={utmMedium}
                      name="utm_medium"
                      labelText="UTM Medium"
                      onChange={(e) => setUtmMedium(e.target.value)}
                    />
                    <Input
                      value={utmCampaign}
                      name="utm_campaign"
                      labelText="UTM Campaign"
                      onChange={(e) => setUtmCampaign(e.target.value)}
                    />
                    <Input
                      value={redirectTo}
                      name="redirect_to"
                      labelText="Redirect URL"
                      onChange={(e) => setRedirectTo(e.target.value)}
                      helperText="Optionally add a URL to redirect to after the user signs up successfully."
                    />
                    <Button variant="primary-inverse" onClick={handleReset}>
                      Reset
                    </Button>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </div>
    </>
  );
};

export default MagicLinksView;
