import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TeamAssignment } from '@/interfaces/team_assignment';
import { Button } from '@/ui/Button';

import Text from '../../../../../components/Text';
import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import { useSettings } from '../../../../../context/settings-context';
import { useOrganization } from '../../../../../hooks';
import { useInvites } from '../../../../../hooks/useInvites';
import { useTeam } from '../../../../../hooks/usePublicationDashboard';
import { usePublication } from '../../../../../hooks/usePublications';
import { Section } from '../../../Components';
import InviteMemberForm from '../../../Components/InviteMemberForm';
import MemberForm from '../../../Components/MemberForm';

import CancelInvite from './CancelInvite';
import ResendInvite from './ResendInvite';

const Team = () => {
  const [newInvitePanelOpen, setNewInvitePanelOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const [publicationId] = useCurrentPublicationState();
  const { settings } = useSettings();
  const currentPublication = usePublication(publicationId)?.data;
  const organizationQuery = useOrganization(currentPublication?.organization_id);
  const { organization } = organizationQuery?.data || {};
  const teamQuery = useTeam();
  const { team } = teamQuery?.data || {};

  const invitesQuery = useInvites(currentPublication?.organization_id, true);
  const { data: invites } = invitesQuery || {};

  return (
    <>
      {/* Team Section */}
      <InviteMemberForm
        isOpen={newInvitePanelOpen}
        close={() => setNewInvitePanelOpen(false)}
        organization={organization}
      />
      <Section
        title="Team"
        description="Manage your team and permissions below."
        scrollToId="team"
        isLoading={!team}
        hasDivider={settings?.multiple_team_members}
      >
        {team && team.map((assignment: TeamAssignment) => {
          const isModalOpen = searchParams.get('memberId') === String(assignment.id);
          // TODO: organization.role_options filters the options by the current_user's access level
          // TODO:       We should pull this list from it's own endpoint and ensure it returns all role options.
          const roleOption = organization?.role_options?.find((option: any) => {
            return option.object_type === assignment.assignable_type && option.level === assignment.role.name;
          });

          return (
            <MemberForm
              key={assignment.id}
              name={`${assignment.user.first_name} ${assignment.user.last_name}`}
              email={assignment.user.email}
              role={assignment.role.name}
              currentRoleOption={roleOption}
              organization={organization}
              bio={assignment.user.bio}
              assignment={assignment}
              imageUrl={assignment.user.profile_picture.thumb.url}
              publicationId={publicationId}
              isInitiallyEditing={isModalOpen}
            />
          );
        })}
        {settings?.multiple_team_members && (
          <div className="pt-4">
            <Button variant="primary-inverse" onClick={() => setNewInvitePanelOpen(true)}>
              Invite More Members
            </Button>
          </div>
        )}
      </Section>

      {/* Pending Invites Section */}
      {settings?.multiple_team_members && (
        <Section
          title="Pending Invites"
          description="Manage your team and permissions below."
          scrollToId="pending-invites"
          isLoading={!team || !invites}
        >
          <div className="flex flex-col space-y-6">
            {invites?.pending?.length ? (
              invites?.pending?.map((invite: any) => {
                return <CancelInvite key={invite.id} invite={invite} />;
              })
            ) : (
              <Text type="body" size="md" className="text-gray-500">
                No Invites Pending
              </Text>
            )}
          </div>
        </Section>
      )}

      {/* Expired Invites Section */}
      {settings?.multiple_team_members && (
        <Section
          title="Expired Invites"
          description="Manage your team and permissions below."
          scrollToId="expired-invites"
          hasDivider={false}
          isLoading={!team || !invites}
        >
          <div className="flex flex-col space-y-6">
            {invites?.expired?.length ? (
              invites?.expired?.map((invite: any) => {
                return <ResendInvite key={invite.id} invite={invite} />;
              })
            ) : (
              <Text type="body" size="md" className="text-gray-500">
                No Invites Expired
              </Text>
            )}
          </div>
        </Section>
      )}
    </>
  );
};
export default Team;
