import { Typography } from '@/components/Typography';
import pluralize from '@/utils/pluralize';

import { ResourceListSearchTextProps } from './ResourceListSearchText.types';

const ResourceListSearchText = ({ showingCount, totalCount }: ResourceListSearchTextProps) => {
  return (
    <Typography token="font-normal/text/xs" colorWeight="500">
      Showing {showingCount} of {pluralize('result', totalCount)}
    </Typography>
  );
};

export default ResourceListSearchText;
