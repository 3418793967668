/* eslint-disable react/jsx-props-no-spreading */
// Spread operators needed for dndkit dependency

import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Typography } from '@/components/Typography';

import DragIcon from './DragIcon';

interface Props {
  className?: string;
  listItem: any;
  text: string | React.ReactNode;
  onRemoveItem?: (item: any) => void;
  actionChild?: React.ReactNode;
  children?: React.ReactNode;
}

const SortableListItem = ({ listItem, text, onRemoveItem, actionChild, className, children }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: listItem.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={cx('bg-surface-100 shadow-sm border border-surface-200 rounded flex flex-col p-2', className)}
      ref={setNodeRef}
      style={style}
    >
      <div className="flex justify-between items-center">
        <div
          className={cx(
            'flex space-x-2 items-center flex-grow overflow-hidden',
            isDragging ? 'cursor-grabbing' : 'cursor-grab'
          )}
          {...attributes}
          {...listeners}
        >
          <div className="flex w-3.5 h-3.5">
            <DragIcon />
          </div>
          {typeof text === 'string' ? <Typography token="font-semibold/text/xs">{text}</Typography> : text}
        </div>

        {actionChild ||
          (onRemoveItem && (
            <button
              type="button"
              aria-label="close"
              onClick={() => {
                onRemoveItem?.(listItem);
              }}
              className="p-0.5 rounded-full hover:bg-gray-200 h-fit"
            >
              <XMarkIcon className="h-3.5 w-3.5 text-surface-400" />
            </button>
          ))}
      </div>

      {children && <div className="pl-5 pt-2 w-full">{children}</div>}
    </div>
  );
};

export default SortableListItem;
