import toast from 'react-hot-toast';

import { useCurrentPublication } from '../../../../../hooks';
import { useUpdatePublication } from '../../../../../hooks/usePublications';
import { Section } from '../../../Components';
import InputForm from '../../../Components/InputForm';

const Analytics = () => {
  const { data: currentPublication }: any = useCurrentPublication();
  const publicationMutation = useUpdatePublication();

  const onSave = async (updatedValue: any) => {
    try {
      await publicationMutation.mutateAsync(updatedValue);
      toast.success('Saved!');
    } catch (error) {
      toast.error('Could not save. Something went wrong!');
    }
  };

  return (
    <Section
      title="Tracking Pixels"
      description="Manage your tracking pixels below."
      scrollToId="analytics"
      isLoading={!currentPublication}
      hasDivider={false}
    >
      <InputForm
        name="facebook_pixel"
        label="Meta Pixel ID"
        placeholder="1234567890"
        helperText="Use a Meta pixel to track conversion from a Meta ad campaign."
        value={currentPublication?.facebook_pixel || ''}
        onSave={(val) => onSave({ facebook_pixel: val })}
        isSaving={publicationMutation.isLoading}
      />
      <InputForm
        name="twitter_pixel"
        label="X (Twitter) Pixel ID"
        placeholder="abcdef"
        helperText="Use a Twitter pixel to track conversion from a Twitter ad campaign."
        value={currentPublication?.twitter_pixel || ''}
        onSave={(val) => onSave({ twitter_pixel: val })}
        isSaving={publicationMutation.isLoading}
      />
      <InputForm
        name="tiktok_pixel"
        label="TikTok Pixel ID"
        placeholder="ABCDEFG123456789"
        helperText="Use a TikTok pixel to track conversion from a TikTok ad campaign."
        value={currentPublication?.tiktok_pixel || ''}
        onSave={(val) => onSave({ tiktok_pixel: val })}
        isSaving={publicationMutation.isLoading}
      />
      <InputForm
        name="google_analytics_4_pixel"
        label="Google Analytics (GA4) Pixel ID"
        placeholder="G-123456789"
        helperText="Use a Google Analytics 4 pixel to utilize their suite of tracking tools."
        value={currentPublication?.google_analytics_4_pixel || ''}
        onSave={(val) => onSave({ google_analytics_4_pixel: val })}
        isSaving={publicationMutation.isLoading}
      />
      <InputForm
        name="gtm_pixel"
        label="Google Tag Manager Pixel ID"
        placeholder="GTM-123456789"
        helperText="Use Google Tag Manager to manage all of your pixels in a single location."
        value={currentPublication?.gtm_pixel || ''}
        onSave={(val) => onSave({ gtm_pixel: val })}
        isSaving={publicationMutation.isLoading}
      />
    </Section>
  );
};

export default Analytics;
