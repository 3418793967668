import Tag from '@/components/Tag';
import pluralize from '@/utils/pluralize';

interface Props {
  count: number;
  showLabel?: boolean;
}

const Icon = () => (
  <svg width="100%" height="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="5"
      height="5"
      rx="1.5"
      transform="matrix(1 0 0 -1 11 16)"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      width="5"
      height="5"
      rx="1.5"
      transform="matrix(1 0 0 -1 2 9)"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.5 1L4.5 4" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.5 9L4.5 16" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7.5 6.5L12 6.5C12.8284 6.5 13.5 7.17157 13.5 8L13.5 10.5"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 6.5L12 6.5C12.8284 6.5 13.5 7.17157 13.5 8L13.5 10.5"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ConditionsLabel = ({ count, showLabel = true }: Props) => {
  return <Tag icon={<Icon />} label={showLabel ? pluralize('condition', count) : count} />;
};

export default ConditionsLabel;
