import React, { FC } from 'react';

import { Select } from '@/components/Form';
import useOptions from '@/hooks/useOptions';
import { optionDataToOptions } from '@/utils';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const AutomationActionInput: FC<Props> = ({ value, onChange }) => {
  const automationActionsData = useOptions('automation_actions', Infinity);
  const automationActionOptions = optionDataToOptions(automationActionsData);

  return (
    <Select
      className="w-full"
      name="value"
      placeholderText="Select Action"
      value={value || ''}
      onSelect={(name: string, selectedValue: string) => onChange(selectedValue)}
      options={automationActionOptions}
    />
  );
};

export default AutomationActionInput;
