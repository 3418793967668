import { Typography } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { usePublication } from '@/hooks/usePublications';
import { AutomationStepWaitUntilParams } from '@/interfaces/automations/automation_step';

import { timeOfDayDisplay, timeZoneDisplay, weekdaysDisplay } from './waitUntilDisplayUtils';

interface Props {
  waitUntilParams: AutomationStepWaitUntilParams;
}

const NewBranchAutomationStepNode = ({ waitUntilParams }: Props) => {
  const [publicationId] = useCurrentPublicationState();
  const currentPublication = usePublication(publicationId)?.data;

  const waitItems = [
    weekdaysDisplay(waitUntilParams.weekdays),
    timeOfDayDisplay(waitUntilParams.time_of_day),
    timeZoneDisplay(waitUntilParams.time_zone, currentPublication?.time_zone),
  ]
    .filter((value) => value !== '-')
    .map((value, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={`wait-until-item-${index}`}>
        <Typography size="xs">{value}</Typography>
      </li>
    ));

  return (
    <>
      <Typography size="xs">Wait for specific weekdays & time of the day</Typography>
      {waitItems && <ul className="list-disc ml-6">{waitItems}</ul>}
    </>
  );
};

export default NewBranchAutomationStepNode;
