import Badge, { BadgeProps } from '@/components/Badge';
import LoadingBox from '@/components/LoadingBox';
import Text from '@/components/Text';
import { AutomationJourneyStatus } from '@/interfaces/automations/automation_journey';

import useAutomationJourneys from './_hooks/useAutomationJourneys';

interface Props {
  label: string;
  statuses: AutomationJourneyStatus[];
  badgeType: BadgeProps['type'];
}

const AutomationGroup = ({ label, statuses, badgeType }: Props) => {
  const { data, isLoading, isError } = useAutomationJourneys({ limit: 6, statuses });
  const journeys = data?.automation_journeys || [];

  return (
    <LoadingBox isLoading={isLoading} isError={isError} height="80px">
      <div className="space-y-2">
        <Text size="sm" className="text-gray-500">
          {label}
        </Text>
        <div className="flex flex-wrap gap-2">
          {journeys.length > 0 ? (
            journeys.map((journey) => (
              <Badge type={badgeType} className="px-2 py-1 whitespace-nowrap">
                {journey.automation_name}
              </Badge>
            ))
          ) : (
            <Text size="sm" className="text-gray-900">
              &ndash;
            </Text>
          )}
        </div>
      </div>
    </LoadingBox>
  );
};

export default AutomationGroup;
