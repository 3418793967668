import { Dispatch, SetStateAction, useState } from 'react';
import { DecodedValueMap } from 'use-query-params';

import FilterButton from '@/components/FilterButton';
import { DateSearch } from '@/components/Form';
import SearchInput from '@/components/SearchInput';
import { SlideDown } from '@/components/Transitions';
import { BetweenDateEnum, DateFilterTypes, PostDateField } from '@/interfaces/dates';
import { OptionTypes, PostAdvancedSearchForm, PostFormQueryParams } from '@/interfaces/post';
import { Dropdown } from '@/ui/Dropdown';

import CheckboxOptions from './CheckboxOptions';

type SearchProps = {
  search: string | null | undefined;
  setSearch: Dispatch<SetStateAction<string | null | undefined>>;
  statusFilter: string | null | undefined;
  authors: string[][];
  contentTags: string[][];
  advancedSearchOptions: DecodedValueMap<PostFormQueryParams>;
  shouldResetSearch: boolean;

  handleResetSearch: () => void;
  handlePostFilter: Function;
  handleAdvancedSearch: (form: PostAdvancedSearchForm) => void;
};

const Search = ({
  search,
  setSearch,
  statusFilter,
  authors,
  contentTags,
  advancedSearchOptions,
  shouldResetSearch,
  handlePostFilter,
  handleResetSearch,
  handleAdvancedSearch,
}: SearchProps) => {
  const defaultDateOptions = {
    enabled: advancedSearchOptions.advancedEnabled,
    [DateFilterTypes.BETWEEN]: {
      [BetweenDateEnum.START]: advancedSearchOptions.startDate,
      [BetweenDateEnum.END]: advancedSearchOptions.endDate,
    },
    [DateFilterTypes.BEFORE]: advancedSearchOptions.beforeDate,
    [DateFilterTypes.AFTER]: advancedSearchOptions.afterDate,
    dateFilterType: advancedSearchOptions.dateFilterType,
    dateField: advancedSearchOptions.dateField,
    datesEnabled: advancedSearchOptions.datesEnabled,
  };

  const defaultBoolOptions = {
    [OptionTypes.AUTHOR_IDS]: advancedSearchOptions.authorIds || [],
    [OptionTypes.AUDIENCE]: advancedSearchOptions.audience || [],
    [OptionTypes.CONTENT_TAGS]: advancedSearchOptions.contentTags || [],
    [OptionTypes.PLATFORMS]: advancedSearchOptions.platforms || [],
  };

  const defaultSearchOptions = {
    showAdvanced: advancedSearchOptions.advancedEnabled,
    dates: defaultDateOptions,
    bools: defaultBoolOptions,
  };

  const [searchOptions, setSearchOptions] = useState<PostAdvancedSearchForm>(defaultSearchOptions);

  const handleShowAdvancedToggle = () => {
    const newSearchOptions = {
      ...searchOptions,
      showAdvanced: !searchOptions.showAdvanced,
    };

    setSearchOptions(newSearchOptions);
    handleAdvancedSearch(newSearchOptions);
  };

  const handleSearchOptionsChange = (key: string) => (values: object) => {
    const newSearchOptions = {
      ...searchOptions,
      [key]: values,
    };

    setSearchOptions(newSearchOptions);
    handleAdvancedSearch(newSearchOptions);
  };

  const postFilterOptions = [
    { label: 'All Posts', value: 'all' },
    { label: 'Featured', value: 'featured' },
    { label: 'Published', value: 'published' },
    { label: 'Scheduled', value: 'scheduled' },
    { label: 'Draft', value: 'draft' },
    { label: 'Archived', value: 'archived' },
  ];

  return (
    <div className="mb-8 pb-8 pt-4 border-b border-gray-200">
      <div className="flex flex-col gap-2">
        <div className="flex w-full justify-between space-y-2 md:space-y-0 md:space-x-2 flex-col items-end md:flex-row md:items-center">
          <div className="flex flex-col w-full space-x-0 space-y-2 md:space-x-2 md:space-y-0 md:flex-row">
            <div className="w-full sm:w-fit">
              <SearchInput
                defaultValue={search || ''}
                shouldDebounce={false}
                shouldReset={shouldResetSearch}
                onClearSearch={handleResetSearch}
                onSearch={setSearch}
                placeholder="Search posts"
              />
            </div>

            <div className="w-full md:max-w-xs">
              <Dropdown
                name="posts"
                value={statusFilter || 'all'}
                onSelect={(_name: string, value: string | string[]) => handlePostFilter(value)}
                options={postFilterOptions}
              />
            </div>
          </div>

          <FilterButton on={searchOptions.showAdvanced} handleClick={handleShowAdvancedToggle} />
        </div>
      </div>

      <SlideDown show={searchOptions.showAdvanced}>
        <div className="flex w-full space-x-2 items-center py-6">
          <span className="whitespace-nowrap text-xs font-semibold text-gray-400">Advanced filters</span>
          <div className="border-b border-gray-200 w-full" />
        </div>
        <CheckboxOptions
          options={searchOptions.bools}
          authors={authors}
          contentTags={contentTags}
          handleOptionsChange={handleSearchOptionsChange('bools')}
        />
        <DateSearch
          selectValue={PostDateField.SEND_COMPLETED_AT}
          label="Show posts where"
          options={searchOptions.dates}
          selectOptions={[{ label: 'Sent At', value: PostDateField.SEND_COMPLETED_AT }]}
          handleOptionsChange={handleSearchOptionsChange('dates')}
        />
      </SlideDown>
    </div>
  );
};

export default Search;
