import Badge from '@/components/Badge';

import { PostStatus } from '../../../interfaces/post';

export interface PostStatusBadgeProps {
  status: PostStatus;
}

const STATUS_DICTIONARY: {
  [key in PostStatus]: {
    badgeType: 'success' | 'alert' | 'warning' | 'information';
  };
} = {
  draft: {
    badgeType: 'information',
  },
  scheduled: {
    badgeType: 'warning',
  },
  published: {
    badgeType: 'success',
  },
  archived: {
    badgeType: 'alert',
  },
};

const PostStatusBadge = ({ status }: PostStatusBadgeProps) => {
  const { badgeType } = STATUS_DICTIONARY[status];

  return (
    <Badge type={badgeType} className="w-fit h-fit capitalize">
      {status}
    </Badge>
  );
};

export default PostStatusBadge;
