import { useCallback } from 'react';
import toast from 'react-hot-toast';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { TiptapState } from '@/interfaces/editor_versions';
import api from '@/services/swarm';

import { useWelcomeEmailContext } from '../_layout';

const useFetchPreview = (tiptapState: TiptapState) => {
  const { welcomeEmail } = useWelcomeEmailContext();
  const [currentPublicationId] = useCurrentPublicationState();

  return useCallback(
    (_platform: string, onFetch: Function) => {
      const payload = {
        publication_id: currentPublicationId,
        tiptap_state: tiptapState,
      };

      api
        .post(`/email_messages/${welcomeEmail.id}/preview`, payload)
        .then((resp) => {
          onFetch(resp.data.html);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [currentPublicationId, tiptapState, welcomeEmail.id]
  );
};

export default useFetchPreview;
