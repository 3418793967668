import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { v4 as generateUuid } from 'uuid';

import useCampaignOpportunityGroup from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroup';
import useCampaignOpportunityGroupOpportunitiesCreate from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroupOpportunitiesCreate';
import useCampaignOpportunityGroupPublications from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroupPublications';
import {
  AdNetworkCampaignOpportunityGroup,
  AdNetworkCampaignOpportunityGroupStatus,
} from '@/interfaces/ad_network/internal/campaign_opportunity_group';
import { AdNetworkPublication } from '@/interfaces/ad_network/internal/publication';
import { LogicalOperators } from '@/interfaces/condition';
import { Pagination } from '@/interfaces/general';
import { Button } from '@/ui/Button';

import { Badge } from '../../_components/Badge';
import { LoadingListPage } from '../../_components/LoadingListPage';
import { PageHeading } from '../../_components/PageHeading';
import useFilteringContext, { FilteringContext } from '../../_components/PublicationFilters/filtering-context';
import Filters from '../../_components/PublicationFilters/Filters';
import useConditionManager from '../../_components/PublicationFilters/useConditionManager';
import { Tabs } from '../../_components/Tabs';

interface Props {
  publications: AdNetworkPublication[];
  campaignOpportunityGroup: AdNetworkCampaignOpportunityGroup;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<any>;
  isRefetching: boolean;
  pagination: Pagination;
  refetch: () => Promise<any>;
}

const formatter = new Intl.NumberFormat('en-US');

const NoResults = () => {
  return (
    <div className="p-4 space-y-2">
      <p className="text-sm">No more publications found.</p>
      <p className="text-sm">
        If you are finished staging publications, go to review & provision to complete the process.
      </p>
    </div>
  );
};

const CampaignOpportunityGroupProvision = ({
  publications,
  hasNextPage,
  fetchNextPage,
  isRefetching,
  pagination,
  campaignOpportunityGroup,
  refetch,
}: Props) => {
  const [selectedPublicationIds, setSelectedPublicationIds] = useState(new Set<string>());
  const [excludePublicationIds, setExcludePublicationIds] = useState(new Set<string>());
  const [allSelected, setAllSelected] = useState(false);

  const handleSelect = (event: React.MouseEvent<HTMLInputElement>) => {
    const { checked, value } = event.currentTarget;

    if (checked) {
      if (allSelected) {
        excludePublicationIds.delete(value);
        setExcludePublicationIds(new Set(excludePublicationIds));
      } else {
        selectedPublicationIds.add(value);
        setSelectedPublicationIds(new Set(selectedPublicationIds));
      }
    } else if (allSelected) {
      if (Array.from(excludePublicationIds).length === pagination.total - 1) {
        setAllSelected(false);
        setExcludePublicationIds(new Set());
      } else {
        excludePublicationIds.add(value);
        setExcludePublicationIds(new Set(excludePublicationIds));
      }
    } else {
      selectedPublicationIds.delete(value);
      setSelectedPublicationIds(new Set(selectedPublicationIds));
    }
  };

  const handleToggleAll = () => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedPublicationIds(new Set());
      setExcludePublicationIds(new Set());
    } else {
      setAllSelected(true);
      setExcludePublicationIds(new Set());
    }
  };

  const stageMutate = useCampaignOpportunityGroupOpportunitiesCreate({ id: campaignOpportunityGroup.id });
  const { state } = useFilteringContext();

  const handleStage = () => {
    stageMutate
      .mutateAsync({
        allSelected,
        excludePublicationIds: Array.from(excludePublicationIds),
        selectedPublicationIds: Array.from(selectedPublicationIds),
        conditions: JSON.stringify(state),
      })
      .then(() => {
        refetch();
      });
  };

  const indeterminate =
    (selectedPublicationIds.size > 0 && !allSelected) || (allSelected && excludePublicationIds.size > 0);

  const selectCount = allSelected ? pagination.total - excludePublicationIds.size : selectedPublicationIds.size;

  const disabled =
    selectCount === 0 || campaignOpportunityGroup.status !== AdNetworkCampaignOpportunityGroupStatus.DRAFT;

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{campaignOpportunityGroup.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to=".">Provision</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <div className="border-b border-gray-100 p-4 flex space-x-2 items-center justify-between">
        <div>
          <Tabs>
            <Tabs.Tab to="">Find publications</Tabs.Tab>
            <Tabs.Tab to="../select">Select advertisements</Tabs.Tab>
            <Tabs.Tab to="../review">Review &amp; provision</Tabs.Tab>
          </Tabs>
        </div>
        <div className="flex space-x-2 items-center justify-center">
          {campaignOpportunityGroup.status === AdNetworkCampaignOpportunityGroupStatus.STAGING && (
            <p className="text-feedback-warning-600 text-sm">Staging, please wait.</p>
          )}
          <p className="text-sm text-gray-600">
            {formatter.format(campaignOpportunityGroup.estimated_clicks_by_status.draft)} est. clicks
          </p>
          <Button type="submit" size="xs" variant="primary-inverse" disabled={disabled} onClick={handleStage}>
            Stage {selectCount || ''} selected
          </Button>
        </div>
      </div>
      <div className="border-b border-gray-100 p-4 flex space-x-2 justify-center items-center">
        <Filters />
      </div>
      {publications.length === 0 ? (
        <NoResults />
      ) : (
        <>
          <p className="text-sm px-4 py-2">
            Showing {publications.length} of {pagination.total} publications, {selectCount} selected
          </p>
          <table className="w-full text-sm divide-y divide-gray-100">
            <thead className="bg-gray-50">
              <tr>
                <th className="!font-normal px-4 py-2" align="left">
                  <input
                    type="checkbox"
                    className="focus:ring-transparent h-4.5 w-4.5 border-gray-300 rounded-sm indeterminate:text-gray-400 text-primary-600"
                    checked={allSelected}
                    ref={(input) => {
                      if (input) {
                        // eslint-disable-next-line no-param-reassign
                        input.indeterminate = indeterminate;
                      }
                    }}
                    onChange={handleToggleAll}
                  />
                </th>
                <th className="!font-normal px-4 py-2" align="left">
                  Publication
                </th>
                <th className="!font-normal px-4 py-2" align="left">
                  Owner email
                </th>
                <th className="!font-normal px-4 py-2" align="left">
                  Plan
                </th>
                <th className="!font-normal px-4 py-2" align="left">
                  Tags
                </th>
                <th className="!font-normal px-4 py-2" align="left">
                  Active subscribers
                </th>
                <th className="!font-normal px-4 py-2" align="left" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {publications.map((publication) => {
                const checked =
                  (allSelected && !excludePublicationIds.has(publication.id)) ||
                  selectedPublicationIds.has(publication.id);

                return (
                  <tr key={publication.id}>
                    <td className="px-4 py-2">
                      <input
                        name="selected_publications"
                        value={publication.id}
                        checked={checked}
                        onClick={(event) => handleSelect(event)}
                        type="checkbox"
                        className="focus:ring-transparent h-4.5 w-4.5 border-gray-300 rounded-sm disabled:text-gray-400 text-primary-600"
                      />
                    </td>
                    <td className="px-4 py-2">
                      <p>
                        <Link to={`/ad_network/publications/${publication.id}`}>{publication.name}</Link>
                      </p>
                      <a
                        href={`https://${publication.hostname}`}
                        className="hover:underline text-gray-400 hover:text-primary-600 inline-flex items-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {publication.hostname}
                        <ArrowTopRightOnSquareIcon className="inline-block w-4 h-4 ml-1" />
                      </a>
                    </td>
                    <td className="px-4 py-2">
                      <a
                        href={`mailto:${publication.owner_email}`}
                        className="hover:underline text-gray-400 hover:text-primary-600"
                      >
                        {publication.owner_email}
                      </a>
                    </td>
                    <td className="px-4 py-2">{publication.plan_name}</td>
                    <td className="px-4 py-2">
                      <div className="flow-root">
                        <div className="-m-1 flex flex-wrap max-w-md">
                          {publication.tags.map((name) => (
                            <div className="p-1" key={name}>
                              <Badge>#{name}</Badge>
                            </div>
                          ))}
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-2">{publication.active_subscription_count}</td>
                    <td className="space-x-1">
                      <a
                        target="_blank"
                        href={`/ad_network/publications/${publication.id}`}
                        rel="noreferrer"
                        className="bg-white hover:bg-gray-100 text-gray-800 disabled:text-gray-600 disabled:bg-gray-100 rounded-md shadow-sm border-gray-300 py-1 px-3 text-sm justify-center duration-200 border font-medium focus:outline-none focus:ring-2 inline-flex items-center disabled:cursor-not-allowed whitespace-nowrap space-x-1"
                      >
                        <span>View</span>
                        <ArrowTopRightOnSquareIcon className="inline-block w-4 h-4 mr-1" />
                      </a>
                    </td>
                  </tr>
                );
              })}

              {hasNextPage && (
                <tr>
                  <td colSpan={5} className="px-4 py-2">
                    <Button onClick={fetchNextPage} loading={isRefetching} variant="primary-inverse" size="xs">
                      Load more
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

function Loader({ id }: { id: string }) {
  const { state } = useFilteringContext();

  const {
    data: publicationsData,
    isSuccess: publicationsIsSuccess,
    isLoading: publicationsIsLoading,
    isError: publicationsIsError,
    hasNextPage,
    fetchNextPage,
    isRefetching,
    refetch: refetchPublications,
  } = useCampaignOpportunityGroupPublications({
    id,
    conditions: JSON.stringify(state),
  });

  const {
    data: groupData,
    isSuccess: groupIsSuccess,
    isLoading: groupIsLoading,
    isError: groupIsError,
    refetch: refetchGroup,
  } = useCampaignOpportunityGroup({ id });

  const isLoading = publicationsIsLoading || groupIsLoading;
  const isError = publicationsIsError || groupIsError;

  if (!publicationsIsSuccess || !groupIsSuccess || isLoading)
    return <LoadingListPage isLoading={isLoading} isError={isError} />;

  const publications = publicationsData?.pages.flatMap((page) => page.publications) || [];
  const pagination = publicationsData?.pages[0].pagination;

  const refetch = () => Promise.all([refetchPublications(), refetchGroup()]);

  return (
    <CampaignOpportunityGroupProvision
      campaignOpportunityGroup={groupData}
      publications={publications}
      hasNextPage={!!hasNextPage}
      fetchNextPage={fetchNextPage}
      isRefetching={isRefetching}
      pagination={pagination}
      refetch={refetch}
    />
  );
}

export default function ContextLoader() {
  const { campaign_opportunity_group_id: id } = useParams<'campaign_opportunity_group_id'>() as {
    campaign_opportunity_group_id: string;
  };

  const conditionsManager = useConditionManager({
    initialState: {
      type: 'group',
      logical_operator: LogicalOperators.AND,
      conditions: [],
      uuid: generateUuid(),
    },
    localStorageKey: `ad_network_publication_filters_group_${id}`,
  });

  return (
    <FilteringContext.Provider value={conditionsManager}>
      <Loader id={id} />
    </FilteringContext.Provider>
  );
}
