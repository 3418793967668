import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { BillingDetails } from '../../interfaces/billing';
import api from '../../services/swarm';

const useBilling = (organizationId: string | undefined) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<BillingDetails>(
    ['billing', 'organizations', organizationId],
    () =>
      api
        .get(`/organizations/${organizationId}/billing`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      staleTime: 10000,
      enabled: !!organizationId,
    }
  );
};

export default useBilling;
