import { useState } from 'react';

import { useCurrentPublication, useUpdatePublication } from '@/hooks/usePublications';
import { Option } from '@/interfaces/general';
import { SelectForm } from '@/pages/Settings/Components';

// See /app/models/concerns/publications/boost_settings_defaults.rb
enum BoostDisplayOrderEnum {
  RANDOM = 'random',
  HIGHEST_CPA = 'highest_cpa',
}

const OptionByValue: Record<BoostDisplayOrderEnum, Option> = {
  [BoostDisplayOrderEnum.RANDOM]: { label: 'Random', value: BoostDisplayOrderEnum.RANDOM },
  [BoostDisplayOrderEnum.HIGHEST_CPA]: {
    label: 'Highest Earning Per Acquisition',
    value: BoostDisplayOrderEnum.HIGHEST_CPA,
  },
};

const Options: Option[] = [
  OptionByValue[BoostDisplayOrderEnum.RANDOM],
  OptionByValue[BoostDisplayOrderEnum.HIGHEST_CPA],
];

const BoostDisplayOrder = () => {
  const publication = useCurrentPublication().data;
  const [boostDisplayOrder, setBoostDisplayOrder] = useState<BoostDisplayOrderEnum>(
    (publication?.boost_display_order as BoostDisplayOrderEnum) || BoostDisplayOrderEnum.RANDOM
  );
  const updatePublication = useUpdatePublication().mutateAsync;

  const handleBoostDisplayOrderChange = (value: BoostDisplayOrderEnum) => {
    setBoostDisplayOrder(value);
    return updatePublication({ boost_display_order: value });
  };

  return (
    <SelectForm
      label="Web Boosts Order"
      description="Choose how to show your Boost offers. By default, we randomly select 3 offers to show to new subscribers."
      value={OptionByValue[boostDisplayOrder]}
      toastSuccessMessage="Saved!"
      onSave={(option: Option) => handleBoostDisplayOrderChange(option.value as BoostDisplayOrderEnum)}
      options={Options}
    />
  );
};

export default BoostDisplayOrder;
