import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { EmailMessage } from '../interfaces/email_message';
import api from '../services/swarm';

export default function useEmailMessage({ emailMessageId }: { emailMessageId: string | undefined }) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchEmailMessage = () =>
    api
      .get(`/email_messages/${emailMessageId}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<EmailMessage>(['email_messages', emailMessageId], fetchEmailMessage, {
    keepPreviousData: true,
    staleTime: 30000,
    enabled: Boolean(emailMessageId),
  });
}
