import { PropsWithChildren } from 'react';
import cx from 'classnames';

import { getColor } from '../DesignTokens';
import { TOP_BAR_FROM_TOP_CLASS_NAME } from '../Layout/AppLayout/TopBar/constants';

type Props = PropsWithChildren<{
  isSecondary?: boolean;
  isSticky?: boolean;
}>;

export const ItemHeaders = ({ children, isSecondary = false, isSticky = false }: Props) => {
  return (
    <thead
      className={cx(
        isSecondary && getColor('surface/50', 'background'),
        isSecondary && 'border-b',
        isSecondary && getColor('surface/300', 'border'),
        !isSecondary && getColor('surface/100', 'background'),
        isSticky && 'sticky z-10',
        isSticky && TOP_BAR_FROM_TOP_CLASS_NAME
      )}
    >
      <tr>{children}</tr>
    </thead>
  );
};
