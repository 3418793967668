import React, { useRef } from 'react';
import { LinkIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { useCopyToClipboard } from '../../hooks';
import HelperText from '../Form/HelperText';
import Stack from '../Stack';
import Tooltip from '../Tooltip';

interface UrlCopyProps {
  className?: string;
  url: string;
  name?: string;
  labelText?: string;
  helperText?: string;
  tooltip?: string;
}

const UrlCopy: React.FC<UrlCopyProps> = ({ url, className, name, labelText, helperText, tooltip }: UrlCopyProps) => {
  const copy = useCopyToClipboard();
  const inputRef = useRef<any | null>(null);

  const handleCopyClick = (): void => {
    copy({ text: url });
    inputRef?.current?.select();
  };

  return (
    <Stack direction="col" gap={1}>
      {labelText && (
        <label htmlFor={name} className="block text-xs font-semibold text-gray-900">
          {labelText}
        </label>
      )}
      <Tooltip id={`url-copy-tooltip-${name}`} text={tooltip || ''} showIcon={false} isEnabled={!!tooltip}>
        <div className="flex group shadow-sm rounded-lg">
          <input
            ref={inputRef}
            name={name}
            readOnly
            type="url"
            value={url}
            className={cx(
              'appearance-none text-gray-600 block w-full px-3 py-2 border border-r-0 focus:ring-inset border-gray-200 placeholder-gray-400 focus:outline-none focus:ring-transparent focus:border-gray-200 sm:text-sm cursor-pointer disabled:cursor-not-allowed rounded-l-lg group-hover:bg-gray-50 overflow-hidden text-ellipsis',
              className
            )}
            onClick={handleCopyClick}
          />
          <button
            type="button"
            onClick={handleCopyClick}
            className="flex items-center space-x-1 rounded-r-lg bg-indigo-50 px-4 border border-indigo-200 focus:outline-none group-hover:bg-indigo-100"
          >
            <LinkIcon className="w-4 h-4 text-indigo-600" />
            <span className="text-indigo-700">Copy</span>
          </button>
        </div>
      </Tooltip>
      {helperText && <HelperText className="mt-2">{helperText}</HelperText>}
    </Stack>
  );
};

UrlCopy.defaultProps = {
  className: '',
};

export default UrlCopy;
