/* eslint-disable no-alert */

import { useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-mini';

import { useOpportunity, useOpportunityDestroy } from '@/hooks/useAdNetwork/internal';
import useOpportunityUpdateStatus from '@/hooks/useAdNetwork/internal/useOpportunityUpdateStatus';
import { AdNetworkOpportunity, AdNetworkOpportunityStatus } from '@/interfaces/ad_network/internal/opportunity';
import { Button } from '@/ui/Button';

import { DescriptionList } from '../_components/DescriptionList';
import { MultiActionCard } from '../_components/MultiActionCard';
import { PageHeading } from '../_components/PageHeading';
import OpportunityStatusIndicator from '../_components/StatusIndicator/OpportunityStatusIndicator';
import useConfirmDelete from '../_hooks/useConfirmDelete';

interface OpportunityShowProps {
  opportunity: AdNetworkOpportunity;
}

const OpportunityShow = ({ opportunity }: OpportunityShowProps) => {
  const navigate = useNavigate();

  const deleteMutation = useOpportunityDestroy({ opportunity });
  const updateStatusMutation = useOpportunityUpdateStatus({ id: opportunity.id });
  const warning = 'Once you delete a opportunity, there is no going back. Please be certain.';

  const { ConfirmDeleteModal, modalProps, handleDelete, isDeleting } = useConfirmDelete({
    deleteMutation,
    warning,
    confirmWith: opportunity.id,
  });

  const isDraft = opportunity.status === AdNetworkOpportunityStatus.DRAFT;
  const isWaitingOnPublisher = opportunity.status === AdNetworkOpportunityStatus.PENDING;
  const isPublisherAccepted = opportunity.status === AdNetworkOpportunityStatus.ACCEPTED;
  const isPublisherRejected = opportunity.status === AdNetworkOpportunityStatus.REJECTED;
  const isPublisherResponded = isPublisherAccepted || isPublisherRejected;
  const isCanceled = opportunity.status === AdNetworkOpportunityStatus.CANCELED;

  const queryClient = useQueryClient();

  const updateStatus = ({ status }: { status: AdNetworkOpportunityStatus }) => {
    if (window.confirm('Are you sure?')) {
      updateStatusMutation.mutateAsync({ status }).then(() => {
        queryClient.invalidateQueries(['ad_network', 'internal', 'opportunities', opportunity.id]);
      });
    }
  };

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to={`/ad_network/publications/${opportunity.publication.id}/opportunities`}>
              {opportunity.publication.name}
            </PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb>{opportunity.campaign.name}</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          {isDraft && (
            <>
              <Button
                onClick={() => updateStatus({ status: AdNetworkOpportunityStatus.PENDING })}
                variant="primary-inverse"
                size="xs"
              >
                Send to publisher
              </Button>

              <Button to="edit" variant="primary-inverse" size="xs">
                Edit
              </Button>
            </>
          )}
        </PageHeading.Side>
      </PageHeading>

      <div className="divide-y divide-gray-100">
        <div className="p-4 grid grid-cols-1 md:grid-cols-2 space-y-4">
          <div>
            <div className="flex items-center gap-x-3">
              <h1 className="flex gap-x-3 text-base leading-7 items-center truncate">
                <Link
                  to={`/ad_network/publications/${opportunity.publication.id}`}
                  className="font-semibold text-gray-800 "
                >
                  {opportunity.advertiser.name}
                </Link>
                <span className="text-gray-400">/</span>
                <Link
                  to={`/ad_network/campaigns/${opportunity.campaign.id}`}
                  className="font-semibold text-gray-800 truncate"
                >
                  {opportunity.campaign.name}
                </Link>
              </h1>
              <div className="flex items-center">
                <OpportunityStatusIndicator opportunity={opportunity} />
              </div>
            </div>
            <p className="mt-2 text-xs leading-6 text-gray-500">
              <time>{moment(opportunity.campaign.window_start_date).format('LLL')}</time>
              <span>&nbsp;—&nbsp;</span>
              <time>{moment(opportunity.campaign.window_end_date).format('LLL')}</time>
            </p>
          </div>
        </div>
        <DescriptionList>
          <DescriptionList.Item term="ID" copyValue={opportunity.id}>
            {opportunity.id}
          </DescriptionList.Item>
          <DescriptionList.Item term="Group ID" copyValue={opportunity.campaign_opportunity_group_id || ''}>
            {opportunity.campaign_opportunity_group_id}
          </DescriptionList.Item>
          <DescriptionList.Item term="Estimated Payout">{opportunity.estimated_payout}</DescriptionList.Item>
          <DescriptionList.Item term="Payout Per Click">{opportunity.payout_per_click}</DescriptionList.Item>
          <DescriptionList.Item term="Deadline">{moment(opportunity.deadline).format('LLL')}</DescriptionList.Item>
          <DescriptionList.Item term="Message to publisher">{opportunity.message_to_publisher}</DescriptionList.Item>
          {opportunity.status === AdNetworkOpportunityStatus.REJECTED && (
            <>
              <DescriptionList.Item term="Rejection Reason">{opportunity.rejection_reason}</DescriptionList.Item>
              <DescriptionList.Item term="Rejection Reason Comment">
                {opportunity.rejection_reason_comment}
              </DescriptionList.Item>
            </>
          )}
          <DescriptionList.Item term="Created">{moment(opportunity.created_at).format('LLL')}</DescriptionList.Item>
          <DescriptionList.Item term="Updated">{moment(opportunity.updated_at).format('LLL')}</DescriptionList.Item>
        </DescriptionList>

        {(!isCanceled || isWaitingOnPublisher || isPublisherResponded || isDraft) && (
          <MultiActionCard>
            {!isCanceled && (
              <MultiActionCard.Item
                title="Configure opportunity"
                description="Edit the name, goals, and other details of this opportunity."
                onClick={() => navigate('edit')}
              />
            )}
            {(isWaitingOnPublisher || isPublisherResponded) && (
              <MultiActionCard.Item
                title="Cancel this opportunity"
                description={"You won't be able to undo this action."}
                loading={updateStatusMutation.isLoading}
                onClick={() => updateStatus({ status: AdNetworkOpportunityStatus.CANCELED })}
                danger
              />
            )}
            {isDraft && (
              <MultiActionCard.Item
                title="Delete this opportunity"
                description={warning}
                loading={isDeleting}
                onClick={() => handleDelete()}
                danger
              />
            )}
          </MultiActionCard>
        )}
      </div>
      <ConfirmDeleteModal {...modalProps} />
    </>
  );
};

export default function Loader() {
  const { opportunity_id: id } = useParams<'opportunity_id'>() as { opportunity_id: string };
  const { data, isLoading, isError, isSuccess } = useOpportunity({ id });

  if (!isSuccess || isLoading || isError) return null;

  return <OpportunityShow opportunity={data} />;
}
