import { SortableList, SortableListItem, useSortableList } from '@/components/SortableList';
import { useWebTemplate } from '@/hooks';
import useOptions from '@/hooks/useOptions';
import { HomePage } from '@/interfaces/home_page';
import FeaturedPosts from '@/pages/Settings/Pages/Website/Advanced/FeaturedPosts';
import ThemeEditorDropdown from '@/pages/ThemeEditor/components/ThemeEditorDropdown';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import ColorInput from '@/routes/website_builder/_components/Form/ColorInput';
import FormHelper from '@/routes/website_builder/_components/Form/PageSpecific/FormHelper';
import SelectInput from '@/routes/website_builder/_components/Form/SelectInput';
import SwitchInput from '@/routes/website_builder/_components/Form/SwitchInput';
import TextInput from '@/routes/website_builder/_components/Form/TextInput';
import OverrideGlobalSettingNote from '@/routes/website_builder/_components/Helpers/OverrideGlobalSettingNote';
import PropertiesGrouping from '@/routes/website_builder/_components/Helpers/PropertiesGrouping';
import {
  PostsSectionIcon,
  SectionGroupingSlideOut,
} from '@/routes/website_builder/_components/Helpers/SectionGroupingSlideOut';
import { RADIUS_OPTIONS } from '@/routes/website_builder/_types/options';
import FontPicker from '@/ui/Settings/FontPicker';

interface Props {
  homePage?: HomePage;
  handleSave: (payload: any) => void;
}

type CategoryItem = {
  id: string;
  value: string;
};

const PostsSection = ({ homePage, handleSave }: Props) => {
  const { handleSaved, fontOptions } = usePageContext();
  const webTemplateRequest = useWebTemplate();

  const postsSectionWithDefaults = homePage?.posts_section_with_defaults;

  const tags = useOptions('content_tags');
  const tagOptions = tags?.data?.options
    ?.filter((tag: any) => !homePage?.posts_section?.featured_sections?.category_posts.includes(tag.display))
    ?.map((tag: any) => {
      return { label: tag?.display, value: tag?.display };
    });

  const currentFeaturedSections: any = homePage?.posts_section?.featured_sections || [];
  const categoryPosts = currentFeaturedSections?.category_posts || [];

  const { sortableList, setSortableList, reStringifyList } = useSortableList<CategoryItem>({
    list: categoryPosts,
    addIdToListOfStrings: true,
  });

  return (
    <SectionGroupingSlideOut
      title="Posts Section"
      showPlanBadge
      sectionIcon={<PostsSectionIcon />}
      highlightProps={{
        sectionId: 'posts-section',
      }}
    >
      <PropertiesGrouping label="Featured Posts" indent>
        <div className="pt-3">
          <FeaturedPosts
            webTemplateRequest={webTemplateRequest}
            autoSave
            onSuccess={() => {
              handleSaved();
            }}
          />
        </div>
      </PropertiesGrouping>

      <PropertiesGrouping label="Latest Posts" indent>
        <SwitchInput
          label="Enabled"
          name="posts-section-latest-posts-enabled"
          enabled={postsSectionWithDefaults?.featured_sections.latest_posts_enabled || false}
          onSave={(value: boolean) => {
            handleSave({
              ...homePage?.posts_section,
              featured_sections: {
                ...homePage?.posts_section.featured_sections,
                latest_posts_enabled: value,
              },
            });
          }}
        />
        {postsSectionWithDefaults?.featured_sections.latest_posts_enabled && (
          <TextInput
            name="latest_posts_label"
            label="Section Label"
            placeholderText="Customize the label"
            value={postsSectionWithDefaults?.featured_sections.latest_posts_label || ''}
            onSave={(value: string) => {
              handleSave({
                ...homePage?.posts_section,
                featured_sections: {
                  ...homePage?.posts_section.featured_sections,
                  latest_posts_label: value,
                },
              });
            }}
          />
        )}
      </PropertiesGrouping>

      <PropertiesGrouping label="Categories Posts" indent>
        <div className="space-y-2">
          <SortableList
            listItems={sortableList}
            onItemsSorted={(sortedList: any[]) => {
              setSortableList(sortedList);
              const reStringifiedList = reStringifyList(sortedList);
              return handleSave({
                ...homePage?.posts_section,
                featured_sections: {
                  ...currentFeaturedSections,
                  category_posts: reStringifiedList,
                },
              });
            }}
          >
            {(list) => {
              return list.map((item: CategoryItem) => {
                return (
                  <SortableListItem
                    key={item.id}
                    listItem={item}
                    onRemoveItem={(categoryItem: CategoryItem) => {
                      const newList = list.filter((category: CategoryItem) => category.id !== categoryItem.id);
                      setSortableList(newList);
                      const reStringifiedList = reStringifyList(newList);
                      return handleSave({
                        ...homePage?.posts_section,
                        featured_sections: {
                          ...currentFeaturedSections,
                          category_posts: reStringifiedList,
                        },
                      });
                    }}
                    text={item.value}
                  />
                );
              });
            }}
          </SortableList>

          {tagOptions?.length > 0 ? (
            <ThemeEditorDropdown
              currentValue=""
              onUpdate={(val: string) => {
                const newVal: CategoryItem = { id: String(sortableList.length + 1), value: val };
                setSortableList([...sortableList, newVal]);
                return handleSave({
                  ...homePage?.posts_section,
                  featured_sections: {
                    ...currentFeaturedSections,
                    category_posts: [...(currentFeaturedSections?.category_posts || []), val],
                  },
                });
              }}
              options={tagOptions}
            />
          ) : null}
        </div>
      </PropertiesGrouping>

      <FormHelper
        label="Post Cards"
        description={<OverrideGlobalSettingNote to="/website_builder?sectionId=post-styles" />}
        actionLabel="Styles"
        alwaysShowingChildren={null}
      >
        <SwitchInput
          label="Fill Enabled"
          name="posts-section-card-fill-enabled"
          enabled={postsSectionWithDefaults?.card.fill_enabled || false}
          onSave={(value: boolean) => {
            handleSave({
              ...homePage?.posts_section,
              card: {
                ...homePage?.posts_section.card,
                fill_enabled: value,
              },
            });
          }}
        />
        {postsSectionWithDefaults?.card.fill_enabled && (
          <>
            <ColorInput
              label="Background Color"
              color={postsSectionWithDefaults?.card.bg_color}
              resettable={{
                label: 'Global Card Background Color',
                resetValue: null,
                check: homePage?.posts_section?.card.bg_color !== null,
              }}
              onSave={(color: string) => {
                handleSave({
                  ...homePage?.posts_section,
                  card: {
                    ...homePage?.posts_section.card,
                    bg_color: color,
                  },
                });
              }}
            />
            <ColorInput
              label="Border Color"
              color={postsSectionWithDefaults?.card.border_color}
              resettable={{
                label: 'Global Card Border Color',
                resetValue: null,
                check: homePage?.posts_section?.card.border_color !== null,
              }}
              onSave={(color: string) => {
                handleSave({
                  ...homePage?.posts_section,
                  card: {
                    ...homePage?.posts_section.card,
                    border_color: color,
                  },
                });
              }}
            />
          </>
        )}
        <SelectInput
          label="Radius"
          value={postsSectionWithDefaults?.card.border_radius || ''}
          options={RADIUS_OPTIONS}
          resettable={{
            resetValue: null,
            check: homePage?.posts_section.card.border_radius !== null,
            label: 'Global Radius',
          }}
          onSave={(value: string) => {
            handleSave({
              ...homePage?.posts_section,
              card: {
                ...homePage?.posts_section.card,
                border_radius: value,
              },
            });
          }}
        />
        <ColorInput
          label="Title Color"
          color={postsSectionWithDefaults?.card.text_color}
          resettable={{
            label: 'Global Post Title Color',
            resetValue: null,
            check: homePage?.posts_section?.card.text_color !== null,
          }}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.posts_section,
              card: {
                ...homePage?.posts_section.card,
                text_color: color,
              },
            });
          }}
        />
        <FontPicker
          label="Title Font Family"
          searchPlaceholder="Search Font"
          value={postsSectionWithDefaults?.card.heading_font || ''}
          noOptionsText="No Matching Fonts"
          options={fontOptions}
          resettable={{
            check: homePage?.posts_section?.card.heading_font !== null,
            label: 'Global Heading Font',
            resetValue: null,
          }}
          onSave={(value: string) => {
            handleSave({
              ...homePage?.posts_section,
              card: {
                ...homePage?.posts_section.card,
                heading_font: value,
              },
            });
          }}
        />
        <ColorInput
          label="Subtitle Color"
          color={postsSectionWithDefaults?.card?.body_text_color}
          resettable={{
            label: 'Global Post Subtitle Color',
            resetValue: null,
            check: homePage?.posts_section?.card.body_text_color !== null,
          }}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.posts_section,
              card: {
                ...homePage?.posts_section.card,
                body_text_color: color,
              },
            });
          }}
        />
        <FontPicker
          label="Subtitle Font Family"
          searchPlaceholder="Search Font"
          value={postsSectionWithDefaults?.card.font || ''}
          noOptionsText="No Matching Fonts"
          options={fontOptions}
          resettable={{
            check: homePage?.posts_section?.card.font !== null,
            label: 'Global Body Font',
            resetValue: null,
          }}
          onSave={(value: string) => {
            handleSave({
              ...homePage?.posts_section,
              card: {
                ...homePage?.posts_section.card,
                font: value,
              },
            });
          }}
        />
        <ColorInput
          label="Premium Text Color"
          color={postsSectionWithDefaults?.card.premium_text_color}
          resettable={{
            label: 'Global Premium Text Color',
            resetValue: null,
            check: homePage?.posts_section?.card.premium_text_color !== null,
          }}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.posts_section,
              card: {
                ...homePage?.posts_section.card,
                premium_text_color: color,
              },
            });
          }}
        />
        <ColorInput
          label="Insights Color"
          color={postsSectionWithDefaults?.card.timestamp_text_color}
          resettable={{
            label: 'Global Insights Color',
            resetValue: null,
            check: homePage?.posts_section?.card.timestamp_text_color !== null,
          }}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.posts_section,
              card: {
                ...homePage?.posts_section.card,
                timestamp_text_color: color,
              },
            });
          }}
        />
        <SwitchInput
          label="Tags Enabled"
          name="posts-section-card-tags-enabled"
          enabled={postsSectionWithDefaults?.card.tags_enabled || false}
          onSave={(value: boolean) => {
            handleSave({
              ...homePage?.posts_section,
              card: {
                ...homePage?.posts_section.card,
                tags_enabled: value,
              },
            });
          }}
        />
        <ColorInput
          label="Tags Background Color"
          color={postsSectionWithDefaults?.card.tags_bg_color}
          resettable={{
            label: 'Global Tags Background Color',
            resetValue: null,
            check: homePage?.posts_section?.card.tags_bg_color !== null,
          }}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.posts_section,
              card: {
                ...homePage?.posts_section.card,
                tags_bg_color: color,
              },
            });
          }}
        />
        <ColorInput
          label="Tags Text Color"
          color={postsSectionWithDefaults?.card.tags_text_color}
          resettable={{
            label: 'Global Tags Text Color',
            resetValue: null,
            check: homePage?.posts_section?.card.tags_text_color !== null,
          }}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.posts_section,
              card: {
                ...homePage?.posts_section.card,
                tags_text_color: color,
              },
            });
          }}
        />
      </FormHelper>
    </SectionGroupingSlideOut>
  );
};

export default PostsSection;
