import { useEffect, useState } from 'react';
import { ChatBubbleLeftIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import { usePublications } from '@/hooks/usePublications';
import { BillingDetails } from '@/interfaces/billing';
import { DowngradeReason } from '@/interfaces/downgrade';
import { Publication } from '@/interfaces/publication';
import { IUser } from '@/interfaces/user';
import { Button } from '@/ui/Button';

import OfferCard from '../components/OfferCard';

interface Props {
  user?: IUser;
  billingDetails: BillingDetails | undefined;
  reason: DowngradeReason | undefined;
  onPreviousStep: () => void;
  onNextStep: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

const SpeakWithUs = (props: Props) => {
  const { user, billingDetails, reason, onPreviousStep, onNextStep, onCancel, isLoading } = props;

  const [showContactForm, setShowContactForm] = useState(false);
  const [showCustomerSuccessManagerForm, setShowCustomerSuccessManagerForm] = useState(false);
  const [showSolutionEngineerForm, setShowSolutionEngineerForm] = useState(false);
  const [showCeoForm, setShowCeoForm] = useState(false);

  const { data: publications } = usePublications();

  useEffect(() => {
    const meetingScript = document.createElement('script');
    meetingScript.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    meetingScript.async = true;
    document.body.appendChild(meetingScript);

    const formScript = document.createElement('script');
    formScript.src = 'https://js.hsforms.net/forms/embed/v2.js';
    formScript.async = true;
    document.body.appendChild(formScript);

    formScript.addEventListener('load', () => {
      const { env: { REACT_APP_HUBSPOT_PORTAL_ID, REACT_APP_HUBSPOT_CONTACT_CEO_FORM_ID } = {} } = window;
      if (window.hbspt && REACT_APP_HUBSPOT_PORTAL_ID && REACT_APP_HUBSPOT_CONTACT_CEO_FORM_ID) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: REACT_APP_HUBSPOT_PORTAL_ID,
          formId: REACT_APP_HUBSPOT_CONTACT_CEO_FORM_ID,
          target: '#contactCeoForm',
        });
      }
    });

    return () => {
      document.body.removeChild(meetingScript);
      document.body.removeChild(formScript);
    };
  }, []);

  const onHandleNextStep = () => {
    onNextStep();
  };

  const onConnectWithSolutionsEngineer = () => {
    setShowContactForm(true);
    setShowSolutionEngineerForm(true);
  };

  const onConnectWithCustomerSuccessManager = () => {
    setShowContactForm(true);
    setShowCustomerSuccessManagerForm(true);
  };

  const onConnectWithCeo = () => {
    setShowContactForm(true);
    setShowCeoForm(true);
  };

  const ConnectWithSolutionsEngineer = (
    <OfferCard
      title="Connect with a Senior Solutions Engineer"
      onClick={onConnectWithSolutionsEngineer}
      buttonText="Connect Now"
      subText="Upon accepting, you’ll be redirected to our contact form."
      Icon={<ChatBubbleLeftIcon className="h-5 w-5 mr-2" />}
      ImageSrc="https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,quality=90/static_assets/downgrade/connect_with_support.png"
      ImageAlt="Connect with a solutions engineer"
    />
  );

  const ConnectWithCustomerSuccessManager = (
    <OfferCard
      title="Connect with a Customer Success Manager"
      onClick={onConnectWithCustomerSuccessManager}
      buttonText="Connect"
      subText="Upon accepting, you’ll be redirected to our contact form."
      Icon={<ChatBubbleLeftIcon className="h-5 w-5 mr-2" />}
      ImageSrc="https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,quality=90/static_assets/downgrade/connect_with_support.png"
      ImageAlt="Connect with customer success manager"
    />
  );

  const ContactCeo = (
    <OfferCard
      title="Connect with our CEO"
      onClick={onConnectWithCeo}
      buttonText="Connect"
      subText="On accepting, you'll be redirected to a form to compose an email that will be sent directly to the CEO's inbox."
      Icon={<EnvelopeIcon className="h-5 w-5 mr-2" />}
      ImageSrc="https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,quality=90/static_assets/downgrade/connect_with_tyler.png"
      ImageAlt="Connect with CEO"
    />
  );

  const SOLUTIONS_ENGINEER_DOWNGRADE_REASONS = [
    DowngradeReason.SWITCHED_SERVICE,
    DowngradeReason.TECHNICAL_ISSUES,
    DowngradeReason.NO_GROWTH,
  ];

  const CUSTOMER_SUCCESS_MANAGER_DOWNGRADE_REASONS = [
    DowngradeReason.TOO_DIFFICULT,
    DowngradeReason.TAKING_BREAK,
    DowngradeReason.MISSING_FEATURES,
    DowngradeReason.TOO_EXPENSIVE,
    DowngradeReason.OTHER,
  ];

  const renderSupportOption = () => {
    if (SOLUTIONS_ENGINEER_DOWNGRADE_REASONS.includes(reason as DowngradeReason)) {
      return ConnectWithSolutionsEngineer;
    }
    if (CUSTOMER_SUCCESS_MANAGER_DOWNGRADE_REASONS.includes(reason as DowngradeReason)) {
      return ConnectWithCustomerSuccessManager;
    }
    return null;
  };

  if (!user) return <></>;

  const publicationsString = publications?.map((publication: Publication) => publication.name).join(', ');

  const prefillValues = {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    "Is there anything else you'd like us to know before our meeting?": `Publications: ${publicationsString}`,
  };

  const headerTextOptions = {
    default: {
      title: 'Can we connect you to a member of our team?',
      description: "Let's discuss what isn't working and how we might be able to help.",
    },
    solutionsEngineer: {
      title: 'We look forward to connecting',
      description: 'The more feedback you share, the more we can help.',
    },
    customerSuccess: {
      title: 'We look forward to connecting',
      description: 'The more feedback you share, the more we can help.',
    },
    ceo: {
      title: 'We look forward to connecting',
      description:
        'This email will be sent directly to our CEO’s inbox. The more feedback you share, the more we can help.',
    },
  };

  const headerText = () => {
    if (showSolutionEngineerForm) {
      return headerTextOptions.solutionsEngineer;
    }
    if (showCustomerSuccessManagerForm) {
      return headerTextOptions.customerSuccess;
    }
    if (showCeoForm) {
      return headerTextOptions.ceo;
    }
    return headerTextOptions.default;
  };

  const prefillParams = new URLSearchParams(prefillValues).toString();

  return (
    <div className="flex flex-col gap-4 justify-between min-h-[80vh]">
      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-semibold text-gray-900">{headerText().title}</h1>
        <Typography token="font-normal/text/sm" colorWeight="700">
          {headerText().description}
        </Typography>
      </div>
      <div className="flex flex-col gap-4">
        {!showContactForm && renderSupportOption()}
        {!showContactForm &&
          parseInt(billingDetails?.subscription_price?.replace('$', '') || '0', 10) > 98 &&
          ContactCeo}
      </div>
      <div className="flex flex-col gap-4">
        <div
          className={cx('meetings-iframe-container', showSolutionEngineerForm ? '' : 'hidden')}
          data-src={`https://meetings.hubspot.com/viktor-tretyak/solutions-engineering-plan-discussion?embed=true&${prefillParams}`}
        />
        <div
          className={cx('meetings-iframe-container', showCustomerSuccessManagerForm ? '' : 'hidden')}
          data-src={`https://hello.beehiiv.com/meetings/ej-white/customer-success-plan-discussion?embed=true&${prefillParams}`}
        />
        <div className={cx('meetings-iframe-container', showCeoForm ? '' : 'hidden')} id="contactCeoForm" />
      </div>
      <div className="flex justify-between">
        <div>
          <Button variant="flush" className="flex flex-row gap-2" onClick={onPreviousStep}>
            <ArrowLeftIcon className="h-4 w-4" /> Back
          </Button>
        </div>

        <div className="space-x-2">
          <Button variant="primary-inverse" onClick={onCancel}>
            Nevermind, keep my plan
          </Button>

          <Button variant="primary" loading={isLoading} onClick={onHandleNextStep}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SpeakWithUs;
