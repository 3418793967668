import { useState } from 'react';
import { ArrowTopRightOnSquareIcon, CurrencyDollarIcon, CursorArrowRaysIcon } from '@heroicons/react/20/solid';

import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';
import { Card } from '@/ui/Card';

import { OpportunityBadge } from '../OpportunityBadge';

interface Props {
  opportunity: AdNetworkOpportunity;
}

const OpportunityDetails = ({ opportunity }: Props) => {
  const [estimatedCtr, setEstimatedCtr] = useState(0.01);
  const estimatedClicks = Math.round(opportunity.estimated_opens * estimatedCtr);
  const estimatedPayout = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format((estimatedClicks * opportunity.payout_per_click_cents) / 100);
  const showCalculator = opportunity.estimated_opens > 100;

  return (
    <Card density="none">
      {/* Advertiser Details */}
      <div className="flex border-b border-surface-200 p-6 space-y-6">
        <div className="space-y-6">
          <OpportunityBadge opportunity={opportunity} />
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <Typography as="p" token="font-medium/text/base">
                {opportunity.advertiser.name}
              </Typography>
              <a href={opportunity.advertiser.url} target="_blank" rel="noreferrer noopener">
                <ArrowTopRightOnSquareIcon className="w-4 h-4 text-gray-500" />
              </a>
            </div>
            <Typography as="p" token="font-normal/text/sm" colorWeight="500">
              {opportunity.advertiser.description}
            </Typography>
          </div>
        </div>
      </div>
      {/* CPC */}
      <div className="flex border-b border-surface-200 p-6 space-y-6">
        <div className="space-y-6">
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-3">
              <div className="p-1 bg-action-secondary-50 rounded">
                <CursorArrowRaysIcon className="w-4 h-4 text-action-secondary-600" />
              </div>
              <Typography as="p" token="font-medium/text/base">
                {opportunity.payout_per_click} CPC
              </Typography>
            </div>
            <Typography as="p" token="font-normal/text/sm" colorWeight="500">
              This ad pays {opportunity.payout_per_click} per unique click within a single send.
            </Typography>
          </div>
        </div>
      </div>
      {/* Estimated Payout */}
      {showCalculator && (
        <div className="border-b border-surface-200 p-6 space-y-6">
          <div className="space-y-6">
            <div className="flex flex-col space-y-2 w-full">
              <div className="flex items-center space-x-3">
                <div className="p-1 bg-action-secondary-50 rounded">
                  <CurrencyDollarIcon className="w-4 h-4 text-action-secondary-600" />
                </div>
                <Typography as="p" token="font-medium/text/base">
                  {estimatedPayout} estimated payout
                </Typography>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="range"
                min="0"
                max="0.02"
                step="any"
                value={estimatedCtr}
                onChange={(e) => setEstimatedCtr(parseFloat(e.target.value))}
                className="appearance-none w-full h-1 bg-surface-100 rounded accent-black"
              />
              <div className="flex-shrink-0">
                <Typography token="font-medium/text/sm">
                  {new Intl.NumberFormat('en-US', {
                    style: 'percent',
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }).format(estimatedCtr)}{' '}
                  CTR
                </Typography>
              </div>
              <Tooltip
                id="estimated-payout"
                text="Estimated payout is calculated by multiplying the total number of subscribers by the average open
                    rate and the CTR (click-through rate)"
              />
            </div>
            <Typography token="font-light/text/xs">
              Modify to see how different click-through rates affect your estimated payout.
            </Typography>
          </div>
        </div>
      )}
      {/* Message */}
      {(opportunity.message_to_publisher || opportunity.campaign.default_message_to_publisher) && (
        <div className="p-6">
          <div className="space-y-6">
            <Typography as="p" token="font-normal/text/sm">
              {opportunity.message_to_publisher || opportunity.campaign.default_message_to_publisher}
            </Typography>
            <Typography as="p" token="font-normal/text/sm" className="text-gray-700">
              The beehiiv Team
            </Typography>
          </div>
        </div>
      )}
    </Card>
  );
};

export default OpportunityDetails;
