import { useEffect, useMemo, useState } from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { NoSymbolIcon } from '@heroicons/react/20/solid';

import { SimpleSelect } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import SearchInput from '@/components/SearchInput';
import Text from '@/components/Text';
import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';
import { Button } from '@/ui/Button';

import { useSearchContext } from '../../_context/searchContext';
import { ActivityFeedApiResponse } from '../../_hooks/useActivityFeedEvents';

import useEventTypeDropdownOptions from './_hooks/useEventTypeDropdownOptions';
import ActivityFeedItem from './ActivityFeedItem';

interface Props {
  title: string;
  emptyText: string;
  query: UseInfiniteQueryResult<ActivityFeedApiResponse, unknown>;
  eventTypeOptions: ActivityFeedEventType[];
}

const ActivityFeed = ({ title, emptyText, query, eventTypeOptions }: Props) => {
  const { search, setSearch, eventType, setEventType } = useSearchContext();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, isError } = query;
  const events = useMemo(() => data?.pages.flatMap((page) => page.events) || [], [data]);

  const [shouldResetSearch, setShouldResetSearch] = useState(false);

  const filteredEventTypeOptions = useEventTypeDropdownOptions(eventTypeOptions);

  const clearSearch = () => {
    setSearch(undefined);
    setEventType(undefined);
    setShouldResetSearch(true);
  };

  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch]);

  return (
    <div className="mt-4 space-y-4">
      <div className="space-y-4 md:space-y-0 md:space-x-2 md:flex justify-between">
        <div className="w-full sm:w-fit">
          <SearchInput
            defaultValue={search || ''}
            onClearSearch={clearSearch}
            shouldReset={shouldResetSearch}
            onSearch={setSearch}
            placeholder="Search"
            shouldDebounce={false}
            block
          />
        </div>
        {filteredEventTypeOptions.length > 0 && (
          <SimpleSelect
            name="event_type"
            value={eventType || ''}
            onSelect={(_, val) => setEventType(val)}
            options={filteredEventTypeOptions}
            optionsContainerClassNames={{ width: 'min-w-[14rem]' }}
          />
        )}
      </div>
      <div className="bg-white rounded-lg">
        <div className="p-5 border-b border-gray-200">
          <Text size="sm" type="bold" className="text-gray-900">
            {title}
          </Text>
        </div>
        <div className="px-10 py-5 space-y-3">
          <LoadingBox
            isLoading={isFetching && !isFetchingNextPage}
            isError={isError}
            backgroundClassName="bg-transparent"
          >
            {events.length > 0 ? (
              <>
                {events.map((feedEvent) => (
                  <ActivityFeedItem
                    key={`${feedEvent.type}/${feedEvent.resource_id}/${feedEvent.timestamp}`}
                    event={feedEvent}
                  />
                ))}
                <div>
                  {hasNextPage ? (
                    <Button
                      variant="flush"
                      onClick={() => fetchNextPage()}
                      disabled={!hasNextPage || isFetchingNextPage}
                      loading={isFetchingNextPage}
                    >
                      Show More
                    </Button>
                  ) : (
                    <Text size="sm" className="ml-2 font-semibold text-gray-400 italic">
                      Beginning of event history
                    </Text>
                  )}
                </div>
              </>
            ) : (
              <div className="flex flex-col text-center items-center justify-center max-w-xs h-96 mx-auto">
                <NoSymbolIcon className="w-8 h-8 text-gray-300" />
                <Text size="sm" className="text-gray-500 mt-2">
                  {search || eventType ? 'No events found. Try searching for something different' : emptyText}
                </Text>
                {(search || eventType) && (
                  <Button variant="primary-inverse" onClick={clearSearch} className="mt-4">
                    Clear search
                  </Button>
                )}
              </div>
            )}
          </LoadingBox>
        </div>
      </div>
    </div>
  );
};

export default ActivityFeed;
