import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import Card from '@/components/Card';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import useDeleteSvixEndpoint from '@/hooks/webhooks/useDeleteSvixEndpoint';
import useTestSvixEndpoint from '@/hooks/webhooks/useTestSvixEndpoint';
import useUpdateSvixEndpoint from '@/hooks/webhooks/useUpdateSvixEndpoint';
import { WebhookEndpoint, WebhookEndpointUpdatePayload, WebhooksEventType } from '@/interfaces/webhooks/endpoint';
import { SwitchForm, TagForm } from '@/pages/Settings/Components';
import InputForm from '@/pages/Settings/Components/InputForm';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';
import validateUrl from '@/utils/validateUrl';

import Help from '../../components/Help';
import ErrorRate from '../Shared/ErrorRate';

type Props = {
  endpoint: WebhookEndpoint;
};

const Details = ({ endpoint }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const [showSecret, setShowSecret] = useState(false);
  const updateEndpoint = useUpdateSvixEndpoint();
  const eventTypeOptions = Object.values(WebhooksEventType).map((eventType) => [eventType, eventType]);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteEndpoint = useDeleteSvixEndpoint();
  const { mutateAsync: deleteEndpointMutation, isLoading } = deleteEndpoint;
  const testEndpoint = useTestSvixEndpoint({
    endpointId: endpoint.id,
  });
  const [selectedEventType, setSelectedEventType] = useState<string>(endpoint.event_types[0]);

  const handleUpdate = (attribute: string, value: string | boolean | WebhooksEventType[]) => {
    const params: Record<string, any> = { id: endpoint.id };
    params[attribute] = value;

    return updateEndpoint.mutateAsync(params as WebhookEndpointUpdatePayload, {});
  };

  const handleDelete = () => {
    deleteEndpointMutation(endpoint.id);
    queryClient.invalidateQueries(['publications', currentPublicationId, 'webhooks', 'endpoints'], { exact: true });
    navigate('/settings/integrations/webhooks');
  };

  const handleTestWebhook = async (eventType: string) => {
    try {
      await testEndpoint.mutateAsync({ eventType });
      toast.success('Test webhook sent!');
    } catch (error) {
      toast.error('Failed to send test webhook!');
    }
  };

  return (
    <>
      <div className="flex flex-row items-end justify-between">
        <h2 className="text-2xl font-bold">Endpoint Details</h2>
        <Help />
      </div>
      <div className="mx-auto w-full grid grid-cols-8 lg:grid-cols-12 gap-4">
        <div className="col-span-8 space-y-4">
          <Card>
            <div className="space-y-2 md:space-y-4">
              <InputForm
                label="Endpoint URL"
                placeholder="https://example.com/webhook"
                value={endpoint.url}
                onSave={(val: string) => handleUpdate('url', val)}
                isSaving={updateEndpoint.isLoading}
                toastSuccessMessage="Saved!"
                noBorder
                validationCheck={validateUrl}
                validationFailMessage="Please enter a valid URL"
              />
              <InputForm
                label="Endpoint Description"
                placeholder="Premium subscriber updates sent to Zapier"
                value={endpoint.description}
                onSave={(val: string) => handleUpdate('description', val)}
                isSaving={updateEndpoint.isLoading}
                toastSuccessMessage="Saved!"
                noBorder
              />
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <SwitchForm
                  name="status"
                  label="Webhook Status"
                  value={!endpoint.disabled}
                  onSave={(val: boolean) => handleUpdate('disabled', !val)}
                  toastSuccessMessage="Saved!"
                  description={endpoint.disabled ? 'Disabled' : 'Active'}
                  noBorder
                />
                <div className="space-y-2 p-2 md:justify-self-end">
                  <div className="text-gray-400">Error Rate</div>
                  <div className="space-x-2 pb-4">
                    <ErrorRate endpointId={endpoint.id} />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-span-8 lg:col-span-4 space-y-4">
          <Card className="w-full">
            <div className="space-y-2">
              <div className="space-y-2 p-2">
                <div className="text-gray-400">Creation Date</div>
                <time>{moment(endpoint.created_at).format('LLL')}</time>
              </div>
              <div className="space-y-2 px-2">
                {/*
                  Description is not optional. If you don't want "empty"
                  to show up, you pass in a non-empty string ' '
                */}
                <TagForm
                  name="event_types"
                  label="Event Types"
                  description=" "
                  options={eventTypeOptions}
                  minSelections={1}
                  maxSelections={999}
                  currentSelections={endpoint.event_types}
                  isSaving={updateEndpoint.isLoading}
                  onSave={(val: string[]) => handleUpdate('event_types', val as WebhooksEventType[])}
                  toastSuccessMessage="Saved!"
                  noBorder
                />
              </div>
              <div className="space-y-2 p-2">
                <div className="text-gray-400">Endpoint Secret</div>
                {showSecret ? (
                  <div className="text-sm break-all">{endpoint.secret}</div>
                ) : (
                  <div className="flex flex-row items-center space-x-1">
                    <span>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
                    <button
                      type="button"
                      className="text-sm text-gray-500 hover:text-gray-700"
                      onClick={() => setShowSecret(true)}
                    >
                      <EyeIcon className="h-4 w-4 mr-1" />
                    </button>
                  </div>
                )}
              </div>
              <div className="p-2">
                <div className="text-gray-400 mb-2">Send Test Events</div>
                <div className="space-y-4">
                  <div className="flex flex-col space-y-4">
                    <Dropdown
                      name="testEventType"
                      options={endpoint.event_types.map((eventType) => ({
                        label: eventType,
                        value: eventType,
                      }))}
                      value={selectedEventType}
                      onSelect={(_, value) => setSelectedEventType(value as string)}
                      fullWidth
                      matchWidth
                    />
                    <Button
                      onClick={() => handleTestWebhook(selectedEventType)}
                      disabled={testEndpoint.isLoading}
                      className="w-full"
                    >
                      {testEndpoint.isLoading ? 'Sending...' : 'Send Test'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="w-full">
            <div className="p-2">
              <div className="text-gray-400 mb-2">Danger Zone</div>
              <p className="text-sm text-gray-600 mb-4">
                Deleting this endpoint will permanently remove it and stop all webhook deliveries. This action cannot be
                undone.
              </p>
              <Button variant="danger" onClick={() => setIsDeleting(true)} className="w-full">
                <TrashIcon className="h-4 w-4 mr-1" />
                Delete Endpoint
              </Button>
            </div>
          </Card>
        </div>
      </div>
      <ActionModal
        isOpen={isDeleting}
        resourceId={endpoint.id}
        onClose={() => setIsDeleting(false)}
        onProceed={() => handleDelete()}
        headerText="Delete Endpoint"
        isWorking={isLoading}
        actionText="Delete"
        buttonType="danger"
      >
        <div className="my-8 space-y-2">
          <p className="text-gray-700 italic">{endpoint.url}</p>
          <p>Are you sure you want to delete this endpoint? This action cannot be undone.</p>
        </div>
      </ActionModal>
    </>
  );
};

export default Details;
