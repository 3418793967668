import { FC, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import TablePagination from '@/components/TablePagination';
import { Pagination } from '@/interfaces/general';
import { Subscription } from '@/interfaces/subscription';
import api from '@/services/swarm';

interface Props {
  premiumOfferId: string;
  publicationId: string;
  className?: string;
}

const RedemptionsTable: FC<Props> = (props: Props) => {
  const { premiumOfferId, publicationId, className } = props;
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<Pagination>();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchSubscriptions = useCallback(() => {
    if (premiumOfferId && publicationId) {
      setIsLoading(true);

      const params = {
        publication_id: publicationId,
        premium_offer_id: premiumOfferId,
        page: Number.isNaN(page) ? 1 : page,
      };

      api
        .get(`/subscriptions`, { params })
        .then((res) => {
          setSubscriptions(res.data?.subscriptions || []);
          setPagination(res?.data?.pagination || {});
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [publicationId, premiumOfferId, page]);

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  const onPageSelected = (targetPage: number) => {
    setPage(targetPage);
  };

  const onSubscriberSelected = (subscriberId: string) => {
    navigate(`/subscribers/${subscriberId}`);
  };

  return (
    <div className={className}>
      <div className="flex">
        <h2 className="text-md leading-6 font-medium text-gray-900">Redemptions ({pagination?.total})</h2>
        {isLoading && <LoadingSpinner className="ml-2" />}
      </div>
      <div className="flex flex-col mt-2">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {(subscriptions || []).map((subscription: Subscription) => (
                    <tr key={subscription.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          <button
                            type="button"
                            onClick={() => onSubscriberSelected(subscription.subscriber_id)}
                            className="text-sm text-gray-500 hover:text-primary-500"
                          >
                            {subscription.email}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {pagination && <TablePagination pagination={pagination} onPageSelected={onPageSelected} />}
      </div>
    </div>
  );
};

export default RedemptionsTable;
