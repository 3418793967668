import { CursorArrowRaysIcon, EyeIcon } from '@heroicons/react/20/solid';
import { Grid } from '@tremor/react';

import { useSettings } from '@/context/settings-context';
import { PostStats } from '@/interfaces/post';
import { MetricCard } from '@/ui/MetricCard';

interface Props {
  stats: PostStats;
}

const formatter = new Intl.NumberFormat('en');

const WebStats = ({ stats }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const totalUniquePageViews = formatter.format(stats.total_web_viewed);
  const totalClicks = formatter.format(stats.total_web_clicked);
  const totalUniqueClicks = formatter.format(stats.total_unique_web_clicked);

  if (isV2) {
    return (
      <div className="flex flex-col gap-y-3">
        <Grid numCols={1} numColsSm={2} className="gap-4">
          <MetricCard variant="secondary" label="Unique Page Views" value={totalUniquePageViews} />
          <MetricCard
            variant="secondary"
            label="Unique Clicks"
            value={totalUniqueClicks}
            complements={[{ label: 'Total Clicks', value: totalClicks }]}
          />
        </Grid>
      </div>
    );
  }
  return (
    <div className="grid gap-8 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
      <div className="flex md:w-auto w-full flex-col">
        <EyeIcon className="h-6 w-6 text-gray-300 mb-4" />
        <div className="mb-8">
          <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Pageviews</p>
          <p className="leading-none">
            <span className="text-5xl font-black text-indigo-500">{totalUniquePageViews}</span>
            <span className="text-gray-400 tracking-tighter text-sm"> unique</span>
          </p>
          <p className="leading-none invisible">
            <span className="text-sm">placeholder</span>
          </p>
        </div>
      </div>
      <div className="flex md:w-auto w-full flex-col">
        <CursorArrowRaysIcon className="h-6 w-6 text-gray-300 mb-4" />
        <div className="mb-8">
          <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Clicks</p>
          <p className="leading-none">
            <span className="text-5xl font-black text-indigo-500">{totalUniqueClicks}</span>
            <span className="text-gray-400 tracking-tighter text-sm"> unique</span>
          </p>
          <p className="leading-none">
            <span className="text-gray-400 tracking-tighter text-sm">
              vs. <strong className="text-gray-600 text-base">{totalClicks}</strong> total
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WebStats;
