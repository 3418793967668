import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';

import api from '../services/swarm';

interface Arguments {
  isEnabled?: boolean;
}

export default function usePublicationTotalSentStats({ isEnabled = true }: Arguments) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<{
    total_sent: number;
  }>(
    ['publication_stats', currentPublicationId, 'total_sent'],
    () =>
      api
        .get(`/publication_stats/total_sent`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
      enabled: isEnabled,
    }
  );
}
