import { ArrowLeftEndOnRectangleIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import { Typography } from '@/components/Typography';
import { usePrompt } from '@/hooks';
import { AdNetworkOpportunity, AdNetworkOpportunityStatus } from '@/interfaces/ad_network/publisher/opportunity';

interface Props {
  opportunity: AdNetworkOpportunity;
}

const ExitIntent = ({ opportunity }: Props) => {
  const [isPrompting, confirmPrompt, cancelPrompt] = usePrompt(
    opportunity.status === AdNetworkOpportunityStatus.PENDING
  );

  return (
    <ActionModal
      isOpen={isPrompting}
      resourceId={opportunity.id}
      modalSize="lg"
      isWorking={false}
      onProceed={() => cancelPrompt()}
      onClose={() => confirmPrompt()}
      actionText="Sounds good, let's do it"
      cancelText="I'm not ready to decide"
    >
      <div className="space-y-6">
        <div className="flex space-x-4">
          <div className="w-10 h-10 bg-feedback-info-50 rounded-full relative flex flex-shrink-0">
            <ArrowLeftEndOnRectangleIcon className="absolute top-1/2 right-1/2 transform -translate-y-1/2 translate-x-1/2 w-6 h-6 text-feedback-info-600" />
          </div>
          <div className="flex flex-col">
            <Typography token="font-medium/text/lg" colorWeight="900">
              Did you forget to reply? This opportunity expires {moment(opportunity.deadline).fromNow()}.
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="700">
              Publishers who consistently reply & run ads will receive more consistent opportunities in the future.
            </Typography>
          </div>
        </div>
        <div className="space-y-6">
          <ul className="list-disc list-inside space-y-2">
            <li>
              <Typography token="font-normal/text/base" colorWeight="700">
                Is the ad a good fit? Choose a date, creative, and accept it!
              </Typography>
            </li>
            <li>
              <Typography token="font-normal/text/base" colorWeight="700">
                Not a good fit? Choose &quot;not a good fit&quot; and we&apos;ll remove this opportunity.
              </Typography>
            </li>
          </ul>
          <div className="p-4 bg-feedback-info-50 rounded-md border border-feedback-info-200 flex space-x-3">
            <InformationCircleIcon className="w-6 h-6 text-feedback-info-600" />
            <Typography as="p" token="font-medium/text/sm" colorWeight="700" color="info">
              Visit our{' '}
              <a
                href="https://support.beehiiv.com/hc/en-us/articles/17507491038231-Ad-Network-FAQ"
                className="text-feedback-info-500 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>{' '}
              to learn more about running ads through the beehiiv ad network.
            </Typography>
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default ExitIntent;
