export enum DROP {
  INSIDE = 'INSIDE',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}
export interface DragItemPage {
  path: string[];
  id: string;
}

export const DndItemTypes = {
  PAGE: 'page',
};
